import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { getBlogIdSuccess } from '../blogSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getBlogIdSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/blog/id`);
	if (res.status === 200) {
		yield put(actionSpreader(getBlogIdSuccess.type, { blogId: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
