import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getAdminMentors, getAdmins, getUserCertificates, grantUserSubscription } from './actions/user.actions';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		users: [],
		mentors: [],
		admins: [],
		profiles: [],
		certificates: [],
		isSubmitting: false,
		userDetails: {},
		pageNo: 1,
		certificatePageNo: 1,
		total: 0,
		certificateTotal: 0,
		loading: false,
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		getUsers: (state) => {
			state.loading = true;
		},
		getUsersSuccess: (state, action) => {
			state.loading = false;
			state.users = action.payload.users;
			state.pageNo = action.payload.pageNo;
			state.total = action.payload.total;
		},
		getUserProfiles: (state) => {
			state.loading = true;
		},
		getUserProfilesSuccess: (state, action) => {
			state.loading = false;
			state.profiles = action.payload.profiles;
		},
		getUser: (state) => {
			state.loading = true;
		},
		getUserSuccess: (state, action) => {
			state.loading = false;
			state.userDetails = action.payload.userDetails;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit !== undefined && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && action.payload.skip !== null && { skip: action.payload.skip }),
				...(typeof action.payload.query !== 'undefined' && { query: action.payload.query }),
			};
		},
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getAdminMentors.fulfilled as any]: (state, action) => {
			if (!action.payload) return state;
			const {
				payload: { mentors },
			} = action;
			state.mentors = mentors;
		},
		[getAdmins.fulfilled as any]: (state, action) => {
			if (!action.payload) return state;
			const {
				payload: { admins },
			} = action;
			state.admins = admins;
		},
		[grantUserSubscription.fulfilled as any]: (state, action) => {
			if (!action.payload) return state;
			const {
				payload: { userId, premiumValidTill },
			} = action;
			state.users.find((user) => user.id === userId).premiumValidTill = premiumValidTill;
		},
		[getUserCertificates.fulfilled as any]: (state, action) => {
			if (!action.payload) return state;
			const {
				payload: { certificates, total },
			} = action;
			state.certificates = certificates;
			state.certificatePageNo = state.apiParams.skip / state.apiParams.limit + 1;
			state.certificateTotal = total;
		},
	},
});

export const {
	getUsers,
	getUsersSuccess,
	getUserProfiles,
	getUserProfilesSuccess,
	getUser,
	getUserSuccess,
	setApiParams,
	toggleLoading,
	toggleIsSubmitting,
} = userSlice.actions;

export default userSlice.reducer;
