import { takeLatest } from 'redux-saga/effects';

import { getBatches, setBatchStatus, getBatch } from './batchSlice';
import { getBatchesSaga } from './sagas/getBatchesSaga';
import { getBatchSaga } from './sagas/getBatchSaga';
import { setBatchStatusSaga } from './sagas/setBatchStatusSaga';

export default function* batchSaga() {
	yield takeLatest(getBatches.type, getBatchesSaga);
	yield takeLatest(getBatch.type, getBatchSaga);
	yield takeLatest(setBatchStatus.type, setBatchStatusSaga);
}
