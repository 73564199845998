import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { FieldArray, reduxForm } from 'redux-form';

import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField } from 'reusableComponents/HookForm/Select';
import { compose } from 'redux';
import { isNotEmptyArray, isNotEmptyObject, showToast } from 'utils/commonHelpers';
import { required } from 'reusableComponents/Form/fieldValidators';
import theme from 'utils/theme';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';
import { fetchWrapper } from 'utils/fetchWrapper';
import { createQuestion, getQuestionDetails, updateQuestion } from './actions/question.action';

const fetchTagsOption = async ({ query = '' }: { query: string }) => {
	const reponse = await fetchWrapper('/v1/admin/quiz/get-question-tags', {
		method: 'GET',
	});
	const { data } = reponse;

	const haveQueryInExistingTags = data.find((tag: { label: string }) => tag.label.includes(query));

	if (!haveQueryInExistingTags) {
		const queryValue = query.trim().replaceAll(' ', '_').toUpperCase();
		if (queryValue) data.push({ label: query, value: queryValue });
	}

	return data;
};

const fetchQuestionsTopics = async ({ query = '' }) => {
	const response = await fetchWrapper('/v1/admin/quiz/get-quiz-question-topics', {
		method: 'POST',
		body: {
			searchKey: query,
			limit: 20,
		},
	});
	return response.data.map((questionTopic) => {
		return { value: questionTopic.id, label: questionTopic.name };
	});
};

const fetchMediaOptions = async ({ query = '' }) => {
	try {
		const response = await fetchWrapper('/v1/admin/quiz/get-activity-medias', {
			method: 'POST',
			body: { limit: 20, searchKey: query, sortKey: 'createdAt', sortOrder: -1, status: 'ACTIVE' },
		});

		return response.data.quizMedias.map((m: { id: string; mediaTitle: string; url: string; type: string }) => ({
			label: m.mediaTitle,
			value: m.id,
			url: m.url,
			type: m.type,
		}));
	} catch {
		return [];
	}
};

const quizQuestionTypeOptions = [
	{ label: 'IMAGE', value: 'IMAGE' },
	{ label: 'VIDEO', value: 'VIDEO' },
	{ label: 'AUDIO', value: 'AUDIO' },
];

const renderedOptions = ({ fields, initialValues, question, control }) => {
	useEffect(() => {
		if (!isNotEmptyObject(question)) {
			initialValues.forEach((option: any) => {
				fields.push(option);
			});
		} else {
			fields.removeAll();
			question?.options?.forEach((option: { isRightAnswer: boolean }) => {
				fields.push(option);
			});
		}
	}, [question]);

	return (
		<Box>
			<Row>
				<Col sm="6">
					{fields.map((_: never, optionIndex: number) => {
						return (
							<Box pos="relative" key={optionIndex.toString()}>
								<Input
									name={`options[${optionIndex}]`}
									type="text"
									label={`Option ${optionIndex + 1}`}
									required
									validate={required}
									defaultValue={fields.get(optionIndex).answerText}
								/>
								<Box
									onClick={() => {
										if (fields.length > 2) {
											fields.remove(optionIndex);
										} else {
											showToast('error', '✋ At least two options required!');
										}
									}}
									cursor="pointer"
									pos="absolute"
									t="0"
									r="0"
								>
									<span className="lnr lnr-cross" />
								</Box>
							</Box>
						);
					})}
				</Col>
				<Col sm="6">
					<RadioGroup
						label="Answer"
						name="answerIndex"
						fields={fields.map((_: never, fieldIdx: number) => {
							if (fields.get(fieldIdx).isRightAnswer) {
								control.setValue('answerIndex', `${fieldIdx}`);
							}
							return {
								label: `Option ${fieldIdx + 1}`,
								value: fieldIdx,
							};
						})}
						required
					/>
				</Col>
			</Row>
			<Row>
				<Col sm="6">
					<Box d="flex" jc="center">
						<Box
							w="2rem"
							h="2rem"
							c={theme.clrs.cWhite}
							fs="1.4rem"
							bRad="50%"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => fields.push({})}
							mb="1rem"
						>
							+
						</Box>
					</Box>
				</Col>
			</Row>
		</Box>
	);
};

const modifyQuestionBeforeInitilize = ({ question }) => {
	if (!isNotEmptyObject(question)) {
		return {};
	}

	const answer = question?.options?.findIndex((option) => option.isRightAnswer);

	return {
		title: question.title,
		description: question.description,
		timeToAnswer: question.timeToAnswer,
		timePostAnswer: question.timePostAnswer,
		earnableXp: question.earnableXp,
		tags: question.tags,
		newTopicName: question.newTopicName,
		questionType: { label: question.questionType, value: question.questionType },
		mediaUrl: {
			label: question.mediaTitle,
			value: question.mediaId,
			url: question.mediaUrl,
			type: question.questionType,
		},
		answerIndex: answer?.toString(),
		topic: { label: question.topic?.name, value: question.topic?.id },
	};
};

const QuestionAddEditForm = () => {
	const hookFormMethods = useForm<any>();
	const { handleSubmit, control, register, reset } = hookFormMethods;

	const dispatch = useDispatch();

	const questionsState = useSelector(({ questions }) => ({
		questions: questions.questions,
	}));

	const { search } = useLocation();
	const query = new URLSearchParams(search);

	const questionId = query.get('questionId');
	const [question, setQuestion] = React.useState<any>({});

	const handleFormSubmit = (data: any) => {
		const {
			questionType,
			timeToAnswer,
			timePostAnswer,
			earnableXp,
			answerIndex,
			mediaUrl: mediaUrlSelectedOption,
			tags,
			options,
			answerRank,
			topic,
			newTopicName,
			...rest
		} = data;

		const { value: mediaId, url: mediaUrl, type: mediaType } = mediaUrlSelectedOption || {};

		const constructedOptions = options.map((option: string, index: number) => {
			return {
				answerText: option,
				answerRank: index + 1,
				isRightAnswer: Number(answerIndex) === index,
			};
		});

		if (!newTopicName && !topic?.value) {
			showToast('error', `Topic Cannot be Empty, either select from topic list or enter a new topic name`);
			return;
		}

		if (questionId) {
			return dispatch(
				updateQuestion({
					...rest,
					id: questionId,
					mediaUrl,
					mediaId,
					questionType: mediaType || 'TEXT',
					timeToAnswer: Number(timeToAnswer),
					timePostAnswer: Number(timePostAnswer),
					earnableXp: Number(earnableXp),
					answerIndex: Number(answerIndex),
					tags: tags || [],
					options: constructedOptions,
					...(newTopicName ? { newTopicName } : { topic: topic?.value }),
				})
			);
		}

		dispatch(
			createQuestion({
				...rest,
				mediaUrl,
				mediaId,
				questionType: mediaType || 'TEXT',
				timeToAnswer: Number(timeToAnswer),
				timePostAnswer: Number(timePostAnswer),
				earnableXp: Number(earnableXp),
				answerIndex: Number(answerIndex),
				options: constructedOptions,
				tags,
				...(newTopicName ? { newTopicName } : { topic: topic?.value }),
			})
		);
	};

	useEffect(() => {
		if (questionId) {
			dispatch(getQuestionDetails({ questionId }));
		}
	}, []);

	useEffect(() => {
		const question = questionsState.questions.find((question: { id?: string }) => question?.id === questionId);
		setQuestion(question);
		reset(modifyQuestionBeforeInitilize({ question }));
	}, [questionsState.questions]);

	return (
		<Card>
			<CardBody>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={handleSubmit(handleFormSubmit)}>
						<Box w="100%">
							<Row>
								<Col sm="4">
									<h2 className="mb-4">{questionId ? 'Edit' : 'Add'} Question</h2>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Input label="Title" name="title" required />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Input label="Description" name="description" />
								</Col>
							</Row>
							<Row>
								<Col sm="9">
									<AsyncSelectField name="mediaUrl" label="Media url" isClearable fetchOptions={fetchMediaOptions} />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<h3 style={{ marginBottom: '1rem' }}>Options</h3>
								</Col>
								<Col sm="12">
									<FieldArray
										name="Options"
										component={renderedOptions}
										question={question}
										initialValues={[{}, {}]}
										control={control}
										register={register}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<Input type="number" label="Time to Answer" name="timeToAnswer" required min={0} defaultValue="30" />
								</Col>
								<Col sm="6">
									<Input type="number" label="Time Post Answer" name="timePostAnswer" min={0} defaultValue="10" />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<Input type="number" label="Earnable XP" required name="earnableXp" min={0} />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<AsyncSelectField
										name="tags"
										label="Tags"
										isMulti
										isClearable
										fetchOptions={fetchTagsOption}
										{...(isNotEmptyArray(question?.tags) ? { defaultValue: [...question?.tags] } : {})}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<AsyncSelectField
										name="topic"
										label="Topic"
										isClearable
										fetchOptions={(args) => fetchQuestionsTopics(args)}
										{...(isNotEmptyObject(question?.topic)
											? { defaultValue: [{ label: question.topic.name, value: question.topic.id }] }
											: {})}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Input label="New Topic ( If no topics found in the above list, enter a new topic name )" name="newTopicName" />
								</Col>
							</Row>
							<Button color="success">Submit</Button>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_questions', enableReinitialize: true });

const QuestionReduxForm = compose(withReduxForm)(QuestionAddEditForm);

export default QuestionReduxForm;
