/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const Radio = React.forwardRef(({ disabled, className, label, ...restProps }: any, ref: any) => {
	return (
		<label className={`radio-btn${disabled ? ' disabled' : ''}${className ? ` radio-btn--${className}` : ''}`}>
			<input ref={ref} className="radio-btn__radio" type="radio" {...restProps} />
			<span className="radio-btn__radio-custom" />
			{className === 'button' ? (
				<span className="radio-btn__label-svg">
					<CheckIcon className="radio-btn__label-check" />
					<CloseIcon className="radio-btn__label-uncheck" />
				</span>
			) : (
				''
			)}
			<span className="radio-btn__label">{label}</span>
		</label>
	);
});

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	className?: string;
	value?: any;
}

export const RadioNew = ({ name, label, disabled, required, className, value }: Props) => {
	const { register } = useFormContext();
	return (
		<label className={`radio-btn${disabled ? ' disabled' : ''}${className ? ` radio-btn--${className}` : ''}`}>
			<input ref={register({ required })} className="radio-btn__radio" type="radio" name={name} value={value} />
			<span className="radio-btn__radio-custom" style={{ backgroundColor: 'white' }} />
			{className === 'button' ? (
				<span className="radio-btn__label-svg">
					<CheckIcon className="radio-btn__label-check" />
					<CloseIcon className="radio-btn__label-uncheck" />
				</span>
			) : (
				''
			)}
			<span className="radio-btn__label">{label}</span>
		</label>
	);
};

export default Radio;
