import React, { useState, useRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchMentors } from 'utils/fetchHelpers';
import { DropzoneNew as Dropzone } from 'reusableComponents/HookForm/Dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { ObjectMaybe, parseQueryParams, pickWrapper } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import history from 'containers/App/history';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { languageList } from 'utils/constants';
import { InputNew as Input } from '../../../reusableComponents/HookForm/Input';
import { TextAreaNew as TextArea } from '../../../reusableComponents/HookForm/TextArea';

import DropboxModal from './DropboxModal';
import Box from '../../../reusableComponents/Box';
import { videoTypeOptions } from './VideoTable';
import { createVideo, updateVideo } from '../actions/video.actions';

const modifyVideoBeforeInitialize = (values) => {
	return {
		...values,
		...(values.mentorId && { mentorId: { label: values.mentorDetails.fullName, value: values.mentorDetails._id } }),
		videoType: videoTypeOptions.find((video) => video.value === values.videoType),
		language: languageList.find((option) => option.value === values.language),
		classOutputUrls: values.classOutputUrls ? (getPreviewImages(values.classOutputUrls, true) as any) : undefined,
	};
};

const VideoForm = (props) => {
	const dispatch = useDispatch();
	const { isSubmitting } = useSelector((state) => ({
		isSubmitting: state.video.isSubmitting,
	}));

	const { id: videoId } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!videoId;

	const { register, handleSubmit, errors, setValue, ...hookFormMethods } = useForm();
	const intervalId = useRef(null);
	const [showDbxModal, setShowDbxModal] = useState(false);

	const { watch } = hookFormMethods;
	const watchVideoType = watch('videoType');

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/video-details?id=${videoId}`, { method: 'GET' }).then((res) => {
				hookFormMethods.reset(modifyVideoBeforeInitialize(res.data as any));
			});
		}
		setValue('isRelaunchable', true);
		setValue('isEnergyMeterEnabled', false);
		return clearInterval(intervalId.current);
	}, []);

	const onSubmit = async (values) => {
		const filteredValues = isEditForm
			? { _id: videoId, ...pickWrapper(['classOutputUrls', 'isRelaunchable', 'isEnergyMeterEnabled'], values) }
			: {
					...values,
					videoType: values.videoType.value,
					language: values.language?.value ?? null,
					videoDesc: values.videoDesc ? values.videoDesc : undefined,
					...(values.videoType.value === 'CLASS' && { mentorId: values.mentorId.value }),
			  };

		if (isEditForm) dispatch(updateVideo(filteredValues));
		else dispatch(createVideo(filteredValues));
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Video...">
			<FormProvider {...{ register, handleSubmit, errors, setValue, ...hookFormMethods }}>
				<div>
					{showDbxModal && (
						<DropboxModal
							handleSubmit={(dropboxPath) => {
								setValue('dropboxPath', dropboxPath, { shouldValidate: true });
								setShowDbxModal(false);
							}}
							closeModal={() => {
								setShowDbxModal(false);
							}}
						/>
					)}
					<Card>
						<CardBody>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'View' : 'Add'} Video</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<form onSubmit={handleSubmit(onSubmit)} className="form ltr-support">
									<Input label="Video Title" name="videoName" disabled={isEditForm} required />
									<Box d="flex" ai="center" w="100%">
										<Col sm="12" md="9" style={{ paddingLeft: 0 }}>
											<Input label="Dropbox Path" name="dropboxPath" disabled={isEditForm} required />
										</Col>
										{!isEditForm && (
											<Col sm="6" md="3" style={{ paddingRight: 0 }}>
												<Button size="sm" style={{ margin: 0 }} onClick={() => setShowDbxModal(true)}>
													Choose from Dropbox
												</Button>
											</Col>
										)}
									</Box>
									<Col sm="12">
										<Dropzone
											label="Class Output Photos"
											name="classOutputUrls"
											isMulti
											withCaption
											noOfFiles={20}
											isClickDisabled={!(isEditForm ? watchVideoType?.value === 'CLASS' : true)}
										/>
									</Col>
									<Col sm="6" style={{ paddingLeft: 0 }}>
										<SelectField
											label="Video Type"
											name="videoType"
											options={videoTypeOptions}
											isClearable
											isDisabled={isEditForm}
											required
										/>
									</Col>
									<Col sm="6" style={{ paddingLeft: 0 }}>
										<SelectField label="Video Language" name="language" options={languageList} isClearable isDisabled={isEditForm} />
									</Col>
									<Col sm="6">
										<Checkbox label="Is Relaunchable?" name="isRelaunchable" />
									</Col>
									<Col sm="6">
										<Checkbox label="Enable Energy Meter?" name="isEnergyMeterEnabled" />
									</Col>
									{watchVideoType?.value === 'CLASS' && (
										<Col sm="6" style={{ paddingLeft: 0 }}>
											<AsyncSelectField
												label="Mentor"
												name="mentorId"
												fetchOptions={fetchMentors}
												isDisabled={isEditForm}
												isClearable
												required
											/>
										</Col>
									)}
									<TextArea rows={5} label="Video Description" name="videoDesc" disabled={isEditForm} />
									{(isEditForm ? watchVideoType?.value === 'CLASS' : true) && (
										<Button type="submit" color="primary" disabled={isSubmitting}>
											Submit
										</Button>
									)}
									<Button size="sm" color="success" type="button" onClick={() => history.push('/video/list')}>
										Cancel
									</Button>
								</form>
							</Box>
						</CardBody>
					</Card>
				</div>
			</FormProvider>
		</LoadingOverlay>
	);
};

export default VideoForm;
