import { createAsyncThunk } from '@reduxjs/toolkit';
import history from 'containers/App/history';
import { omitWrapper, showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
// eslint-disable-next-line import/no-cycle
import { setError } from 'containers/App/globalSlice';
import { toggleIsSubmitting } from '../videoSlice';

export const createVideo = createAsyncThunk('video/createVideo', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	const { data: videoId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

	const entityType = 'CLASS_OUTPUT';
	const entityId = videoId;

	const imagesKeys = [{ key: 'classOutputUrls', isMulti: true, withCaption: true, entityId, entityType }];

	try {
		const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		const valuesToSubmit = {
			_id: videoId,
			...omitWrapper(['classOutputUrls'], data),
			...imagesUploadData,
		};

		const res = await fetchWrapper('/v1/admin/video', { method: 'POST', body: valuesToSubmit });

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			showToast('success', `👍️ Video upload started successfully!!`);
			history.push('/video/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateVideo = createAsyncThunk('video/updateVideo', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const entityType = 'CLASS_OUTPUT';
	const entityId = data._id;

	const imagesKeys = [{ key: 'classOutputUrls', isMulti: true, withCaption: true, entityId, entityType }];

	try {
		const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		const valuesToSubmit = {
			...omitWrapper(['classOutputUrls'], data),
			...imagesUploadData,
		};

		await fetchWrapper(`/v1/admin/video`, { method: 'PUT', body: valuesToSubmit });

		history.push('/video/list');
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
