import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { fetchWrapper } from 'utils/fetchWrapper';
import { omitWrapper, showToast } from 'utils/commonHelpers';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { IClass } from 'types/Class';
import { ClassCard } from 'components/ClassCard';
import { updateProgram, updateProgramClasses } from '../../actions/program.actions';

interface IClassWithEditInfo extends IClass {
	isEditable: boolean;
}

function getIndexToModuleMap(modules = []) {
	let currentIndex = 0;
	const indexToModuleMapping = {};
	for (let i = 0; i < modules.length; i += 1) {
		const currentModule = modules[i];
		for (let j = 0; j < currentModule.noOfClasses; j += 1) {
			indexToModuleMapping[currentIndex] = currentModule.index;
			currentIndex += 1;
		}
	}
	return indexToModuleMapping;
}

function modifyModulesBeforeSave({ modules, selectedClasses, indexToModuleMapping }) {
	return modules.map((currModule) => {
		// Find all the classes for this module
		const classes = selectedClasses
			.filter((_, i) => {
				const classModule = indexToModuleMapping[i];
				return classModule === currModule.index;
			})
			.map((classDetail, i) => ({ index: i + 1, classId: classDetail.id }));

		return {
			...currModule,
			classes,
		};
	});
}

function modifyModulesBeforeInitialze({ modules, classes }) {
	const selectedClasses = [];
	let unselectedClasses = [];

	for (let i = 0; i < modules.length; i += 1) {
		const currentModule = modules[i];
		for (let j = 0; j < currentModule.classes.length; j += 1) {
			const currClass = currentModule.classes[j];
			const currClassDetail = classes.find((c) => c.id === currClass.classId);
			if (currClassDetail) {
				selectedClasses.push(currClassDetail);
			}
		}
	}

	unselectedClasses = classes.filter((c) => {
		return !selectedClasses.find((sc) => sc.id === c.id);
	});

	return { selectedClasses, unselectedClasses };
}

function getUpdatedProgramModuleWithClasses({
	selectedClasses,
	moduleTitle,
}: {
	selectedClasses: IClassWithEditInfo[];
	moduleTitle: string;
}) {
	return [
		{
			index: 1,
			title: moduleTitle,
			noOfClasses: selectedClasses.length,
			classes: selectedClasses.map((classDetail, i) => ({ index: i + 1, classId: classDetail.id })),
		},
	];
}

function getUpdatedClassesWitEditInfo({ selectedClasses }: { selectedClasses: IClassWithEditInfo[] }) {
	return selectedClasses.map((classDetail, i) => ({
		index: i + 1,
		classId: classDetail.id,
		isEditable: classDetail.isEditable,
	}));
}

interface Module {
	index: number;
	title: string;
	noOfClasses: number;
	classes: Array<{ index: number; classId: string }>;
}

interface Props {
	programId: string;
	continuity: 'FIXED' | 'CONTINUOUS';
	modules: Module[];
	programEditStatus: 'NO_EDIT' | 'CLASS_EDIT' | 'ALL_EDIT';
	initialValues: any;
}

export const ModuleToClassMappingForm = ({ programId, modules, programEditStatus, continuity, initialValues }: Props) => {
	const dispatch = useDispatch();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.program.isSubmitting,
	}));

	const [selectedClasses, setSelectedClasses] = useState<IClassWithEditInfo[]>([]);
	const [unselectedClassses, setUnselectedClassses] = useState<IClassWithEditInfo[]>([]);

	const indexToModuleMapping = getIndexToModuleMap(modules);
	const totalNumberOfClasses = (modules ?? []).reduce((acc, curr) => acc + curr.noOfClasses, 0);

	useEffect(() => {
		// fetch classes
		fetchWrapper(`/v1/admin/program-classes?programId=${programId}`, { method: 'GET' }).then((res) => {
			if (res.status === 200) {
				const modfiedValue = modifyModulesBeforeInitialze({ modules, classes: res.data.classes });
				setSelectedClasses(modfiedValue.selectedClasses as IClassWithEditInfo[]);
				setUnselectedClassses(modfiedValue.unselectedClasses as IClassWithEditInfo[]);
			} else {
				showToast('error', 'Something went wrong while fetching the class details');
			}
		});
	}, []);

	function addClass(classDetail: IClassWithEditInfo) {
		if (continuity === 'FIXED' && selectedClasses.length === totalNumberOfClasses) {
			showToast('error', `You cannot add more classes because total number of classes in the program is ${totalNumberOfClasses}`);
		} else {
			insertClassAt({ index: selectedClasses.length, classDetail });
		}
	}

	function removeClass(classDetail: IClassWithEditInfo) {
		setSelectedClasses((selected) => selected.filter((c) => c.id !== classDetail.id));
		setUnselectedClassses((unselected) => [...unselected, classDetail]);
	}

	function insertClassAt({ index, classDetail }: { index: number; classDetail: IClassWithEditInfo }) {
		setSelectedClasses((selected) => {
			const selectedClassesCopy = [...selected];
			selectedClassesCopy.splice(index, 0, classDetail);
			return selectedClassesCopy;
		});

		// Remove from unselected
		setUnselectedClassses((unselected) => unselected.filter((c) => c.id !== classDetail.id));
	}

	function moveClassUp(classDetail: IClassWithEditInfo) {
		const currentIndex = selectedClasses.findIndex((c) => c.id === classDetail.id);
		if (currentIndex === 0) return;
		setSelectedClasses((selected) => selected.filter((c) => c.id !== classDetail.id));
		insertClassAt({ index: currentIndex - 1, classDetail });
	}

	function moveClassDown(classDetail: IClassWithEditInfo) {
		const currentIndex = selectedClasses.findIndex((c) => c.id === classDetail.id);
		if (currentIndex === selectedClasses.length - 1) return;
		setSelectedClasses((selected) => selected.filter((c) => c.id !== classDetail.id));
		insertClassAt({ index: currentIndex + 1, classDetail });
	}

	function saveClasses() {
		if (continuity === 'FIXED') {
			if (selectedClasses.length !== totalNumberOfClasses) {
				return showToast('error', `There are total ${totalNumberOfClasses} in program but you have added ${selectedClasses.length}.`);
			}

			dispatch(
				updateProgram({
					...omitWrapper(['certificate'], initialValues),
					isCertificateEnabled: initialValues?.certificate?.isEnabled,
					certificateImage: initialValues?.certificate?.image,
					_id: programId,
					updatedBy: loggedInUser.id,
					modules: modifyModulesBeforeSave({ modules, selectedClasses, indexToModuleMapping }),
				})
			);
		} else if (continuity === 'CONTINUOUS') {
			dispatch(
				updateProgramClasses({
					...omitWrapper(['certificate'], initialValues),
					certificateImage: initialValues?.certificate?.image,
					_id: programId,
					updatedBy: loggedInUser.id,
					modules: getUpdatedProgramModuleWithClasses({ selectedClasses, moduleTitle: modules?.[0]?.title }),
					classes: getUpdatedClassesWitEditInfo({ selectedClasses }),
				})
			);
		}
	}

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Saving Classes...">
			<Box>
				<h4>Selected Classes</h4>
				<div className="table">
					<table>
						<thead>
							<tr>
								<th>Class</th>
								<th>Module</th>
								<th>Remove</th>
								<th>UP</th>
								<th>Down</th>
							</tr>
						</thead>
						<tbody>
							{selectedClasses.map((classDetail, i) => (
								<tr key={classDetail.id}>
									<td>
										<ClassCard {...classDetail} />
									</td>
									<td>
										<Box ml="1rem">{indexToModuleMapping[i]}</Box>
									</td>
									<td>
										<Button color="danger" size="sm" onClick={() => removeClass(classDetail)} disabled={!classDetail.isEditable}>
											Remove
										</Button>
									</td>
									<td>
										<Button
											color="secondary"
											size="sm"
											onClick={() => moveClassUp(classDetail)}
											disabled={i === 0 || !classDetail.isEditable}
										>
											UP
										</Button>
									</td>
									<td>
										<Button
											color="secondary"
											size="sm"
											onClick={() => moveClassDown(classDetail)}
											disabled={i === selectedClasses.length - 1 || !classDetail.isEditable}
										>
											Down
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<Box className="mt-4">
						<Button size="sm" color="primary" onClick={saveClasses}>
							Save
						</Button>
					</Box>
				</div>

				<Box h="2rem" />

				<h4>Unselected Classes</h4>
				<div className="table">
					<table>
						<thead>
							<tr>
								<th>Class</th>
								<th>Add</th>
							</tr>
						</thead>
						<tbody>
							{unselectedClassses.map((classDetail) => (
								<tr key={classDetail.id}>
									<td>
										<ClassCard {...classDetail} />
									</td>
									<td>
										<Button color="success" size="sm" onClick={() => addClass(classDetail)}>
											Add
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Box>
		</LoadingOverlay>
	);
};
