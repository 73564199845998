/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import Filter from 'reusableComponents/Filter';
import { format } from 'date-fns';
import { SelectField } from 'reusableComponents/Form/Select';
import { hlsStreamStatusValues, languageList } from '../../../utils/constants';
import { getVideos, getVideoStatus, setApiParams } from '../videoSlice';
import { isNotEmptyArray, accessDeepObject, pickWrapper, stringifyQueryParams } from '../../../utils/commonHelpers';
import history from '../../App/history';

import DataPaginationTable from '../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../reusableComponents/Pagination/Pagination';

export const videoTypeOptions = [
	{ label: 'Class', value: 'CLASS' },
	{ label: 'Class Highlight', value: 'CLASS_HIGHLIGHT' },
	{ label: 'Mentor', value: 'MENTOR' },
	{ label: 'Program', value: 'PROGRAM' },
	{ label: 'Challenge', value: 'CHALLENGE' },
	{ label: 'Quiz', value: 'QUIZ' },
	{ label: 'Interaction Exercise', value: 'INTERACTION_EXERCISE' },
];

const Actions = ({ id, dropboxPath, isConvertComplete, isRefreshing = {} }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/video/add-edit${stringifyQueryParams({ id })}`)}>Edit</DropdownItem>
				<DropdownItem
					onClick={() => {
						// @ts-ignore
						dispatch(getVideoStatus({ dropboxPath }));
					}}
				>
					{isRefreshing[dropboxPath] ? 'Refreshing...' : 'Refresh'}
				</DropdownItem>
				<DropdownItem
					disabled={!isConvertComplete}
					onClick={() => {
						history.push(`/class/video/${id}`);
					}}
				>
					Watch Video
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ videos, isRefreshing = {} }) {
	return videos.map(
		({
			hlsStreamStatus,
			id,
			videoName,
			dropboxToS3UploadProgress,
			hlsStreamConvertProgress,
			dropboxPath,
			createdAt,
			videoUrl,
			videoType,
		}) => {
			const isConvertComplete = hlsStreamStatus === 4;
			return {
				id,
				videoName,
				videoType,
				status: hlsStreamStatusValues[hlsStreamStatus],
				createdAt: format(new Date(createdAt), 'dd-MM-yyyy HH:mm'),
				uploadProgress: !isConvertComplete ? <span>{dropboxToS3UploadProgress}</span> : '',
				convertProgress: !isConvertComplete ? <span>{hlsStreamConvertProgress}</span> : '',
				actions: <Actions {...{ id, dropboxPath, isConvertComplete, videoUrl, isRefreshing }} />,
			};
		}
	);
}

const heads = [
	{ accessor: 'videoName', Header: 'Title' },
	{ accessor: 'videoType', Header: 'Video Type' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'uploadProgress', Header: 'Upload Progress' },
	{ accessor: 'convertProgress', Header: 'Convert Progress' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const VideoTable = ({ history }) => {
	const videoState = useSelector(({ video }) => ({
		loading: video.loading,
		videos: video.videos,
		pageNo: video.pageNo,
		total: video.total,
		apiParams: video.apiParams,
		isRefreshing: video.isRefreshing,
	}));

	const dispatch = useDispatch();

	const [videoRows, setVideoRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [searchTerm, setSearchTerm] = useState(videoState.apiParams.videoName);

	useEffect(() => {
		dispatch(getVideos());
	}, []);

	useEffect(() => {
		setPageOfItems(videoState.pageNo);
	}, [videoState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(videoState.videos)) {
			const structuredRows = createRows({
				...pickWrapper(['videos', 'isRefreshing'], videoState),
				dispatch,
			});
			setVideoRows(structuredRows);
		} else {
			setVideoRows([]);
		}
	}, [videoState.videos, videoState.isRefreshing]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = videoState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					...apiParams,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getVideos());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					...videoState.apiParams,
					sortKey: sortColumn,
					sortOrder,
				})
			);
			dispatch(getVideos());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				videoName: searchTerm,
				limit: videoState.apiParams.limit,
				skip: 0,
			})
		);
		dispatch(getVideos());
	};

	const onFilterChange = (filterName, val) => {
		dispatch(setApiParams({ ...videoState.apiParams, skip: 0, [filterName]: val }));
		dispatch(getVideos());
	};

	return (
		<>
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={9} className="mb-3">
								<h3>Videos Listing</h3>
							</Col>
							<Col sm={4}>
								<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch} placeholder="Search by Title" />
							</Col>
							<Col sm={3}>
								<SelectField
									clearable
									placeholder="Video Type"
									name="videoType"
									options={videoTypeOptions}
									onChange={(val) => onFilterChange('videoType', val)}
								/>
							</Col>
							<Col style={{ textAlign: 'right' }} sm={{ size: 2, offset: 1 }}>
								<Button
									style={{
										marginBottom: 0,
									}}
									color="primary"
									onClick={() => history.push(`/video/add-edit`)}
								>
									Add
								</Button>
							</Col>
							<Col sm={3}>
								<SelectField
									clearable
									placeholder="Video Language"
									name="language"
									options={languageList}
									onChange={(val) => onFilterChange('language', val)}
								/>
							</Col>
						</Row>
						{isNotEmptyArray(videoRows) && (
							<>
								<DataPaginationTable heads={heads} rows={videoRows} onSort={onSort} />
								<Pagination
									itemsCount={videoState.total}
									itemsToShow={accessDeepObject('apiParams.limit', videoState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{videoState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};
