import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import isArray from 'lodash/isArray';
import { ArrayMaybe, omitWrapper, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../programSlice';

export const createProgram = createAsyncThunk('program/createProgram', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	const { data: programId } = await fetchWrapper('/v1/admin/program/id', { method: 'GET' });

	const entityType = 'PROGRAM';
	const entityId = programId;

	const imagesKeys = [
		{ key: 'coverPicture', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'portraitPic', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'photos', isMulti: true, withCaption: true, entityId, entityType: 'PROGRAM_GALLERY' },
		{ key: 'mobileCoverPic', isMulti: false, withCaption: true, entityId, entityType },
		{ key: 'featuredCoverUrl', isMulti: false, withCaption: true, entityId, entityType },
		{
			key: 'certificateImage',
			isMulti: false,
			withCaption: false,
			entityId,
			entityType: 'PROGRAM_CERTIFICATE',
		},
		...ArrayMaybe(data.learningOutcomes).map((_, index) => ({
			key: `learningOutcomes[${index}].photo`,
			isMulti: false,
			withCaption: false,
			entityId,
			entityType,
		})),
	];

	const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

	const valuesToSubmit = {
		...omitWrapper(['certificate', 'learningOutcomes'], data),
		_id: programId,
		...omitWrapper(
			ArrayMaybe(data.learningOutcomes).map((_, index) => `learningOutcomes[${index}].photo`),
			imagesUploadData
		),
		learningOutcomes: ArrayMaybe(data.learningOutcomes).map((outcome, index) => ({
			...outcome,
			photo: imagesUploadData[`learningOutcomes[${index}].photo`],
		})),
		certificate: {
			isEnabled: !!data.isCertificateEnabled,
			image: imagesUploadData.certificateImage,
		},
	};

	try {
		const res = await fetchWrapper('/v1/admin/program', {
			method: 'POST',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Program created succcessfully!!`);
			history.push('/program/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateProgram = createAsyncThunk('program/updateProgram', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const entityType = 'PROGRAM';
	const entityId = data._id;

	const imagesKeys = [
		{ key: 'coverPicture', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'portraitPic', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'photos', isMulti: true, withCaption: true, entityId, entityType: 'PROGRAM_GALLERY' },
		{ key: 'mobileCoverPic', isMulti: false, withCaption: true, entityId, entityType },
		{ key: 'featuredCoverUrl', isMulti: false, withCaption: true, entityId, entityType },
		{
			key: 'certificateImage',
			isMulti: false,
			withCaption: false,
			entityId,
			entityType: 'PROGRAM_CERTIFICATE',
		},
		...ArrayMaybe(data.learningOutcomes).map((_, index) => ({
			key: `learningOutcomes[${index}].photo`,
			isMulti: false,
			withCaption: false,
			entityId,
			entityType,
		})),
	];

	const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

	const valuesToSubmit = {
		...omitWrapper(['certificate', 'learningOutcomes'], data),
		...omitWrapper(
			ArrayMaybe(data.learningOutcomes).map((_, index) => `learningOutcomes[${index}].photo`),
			imagesUploadData
		),
		...(isArray(data.learningOutcomes) && {
			learningOutcomes: ArrayMaybe(data.learningOutcomes).map((outcome, index) => ({
				...outcome,
				photo: imagesUploadData[`learningOutcomes[${index}].photo`],
			})),
		}),
		certificate: {
			isEnabled: !!data.isCertificateEnabled,
			image: imagesUploadData.certificateImage,
		},
	};

	try {
		const res = await fetchWrapper('/v1/admin/program', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Program updated succcessfully!!`);
			history.push('/program/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateProgramAndUpdateList = createAsyncThunk(
	'program/updateProgramAndUpdateList',
	async ({ programId, programIndex, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			_id: programId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/program', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.data } });
			} else {
				showToast('success', `👍️ Program updated succcessfully!!`);
				return {
					programIndex,
					programData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);

export const fetchPrograms = createAsyncThunk(
	'program/fetchPrograms',
	async ({ sortKey, sortOrder, limit, skip, query, status, programType, continuity, progression, language }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip, query, status, programType, continuity, progression, language });
		const res = await fetchWrapper(`/v1/admin/programs${qp}`, { method: 'GET' });

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.data } });
			} else {
				return {
					programs: res.data.programs,
					total: res.data.total,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const fetchProgramFeedbacks = createAsyncThunk(
	'program/fetchProgramFeedbacks',
	async ({ sort, limit, skip, entityId }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const data = { sort, limit, skip, entityType: 'PROGRAM', entityId };
		const res = await fetchWrapper(`/v1/admin/get-program-feedbacks`, { method: 'POST', body: data });
		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.data } });
			} else {
				return {
					programFeedbacks: res.data?.feedbacks,
					total: res.data?.total,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const updateProgramClasses = createAsyncThunk('program/updateProgramClasses', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/program-classes', {
			method: 'PUT',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Classes added to program succcessfully!!`);
			history.push('/program/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
