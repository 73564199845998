import { takeLatest } from 'redux-saga/effects';

import { getStories, getStory, getStoryId, setStoryStatus, setStoryFeatured, storyOnSubmit } from './successStorySlice';
import { getSuccessStoriesSaga } from './sagas/getSuccessStoriesSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { getSuccessStoryIdSaga } from './sagas/getSuccessStoryIdSaga';
import { setSuccessStoryStatusSaga } from './sagas/setSuccessStoryStatusSaga';
import { getSuccessStorySaga } from './sagas/getSuccessStorySaga';
import { setSuccessStoryFeaturedSaga } from './sagas/setSuccessStoryFeaturedSaga';

export default function* successStorySaga() {
	yield takeLatest(getStories.type, getSuccessStoriesSaga);
	yield takeLatest(storyOnSubmit.type, onSubmitSaga);
	yield takeLatest(getStoryId.type, getSuccessStoryIdSaga);
	yield takeLatest(setStoryStatus.type, setSuccessStoryStatusSaga);
	yield takeLatest(getStory.type, getSuccessStorySaga);
	yield takeLatest(setStoryFeatured.type, setSuccessStoryFeaturedSaga);
}
