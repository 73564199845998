import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import Papa from 'papaparse';
import { omitWrapper, showToast } from 'utils/commonHelpers';
import { uploadCertificate } from '../actions/certificate.actions';

const fetchTemplates = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/html-templates?query=${query}&limit=10&sortKey=createdAt&sortOrder=-1`, { method: 'GET' });
		return res.data.templates.map((t) => ({ label: t.templateName, value: t.id }));
	} catch {
		return [];
	}
};

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface CertificateFormValues {
	templateId: { label: string; value: string };
	csvFile: File;
}

interface ModifyCertificateBeforeCreateArgs extends CertificateFormValues, CsvFileValues {}

interface CsvFileValues {
	batchIds: string[];
	profileIds: string[];
}

export const modifyCertificateBeforeCreate = (values: ModifyCertificateBeforeCreateArgs) => {
	return {
		...omitWrapper(['csvFile'], values),
		templateId: values.templateId.value,
	};
};

const validateCsvData = (csvData: [{ batchId: string; profileId: string }]): { isValid: boolean; message?: string } => {
	if (!csvData[0].batchId || !csvData[0].profileId) return { isValid: false, message: 'Wrong header row in CSV file' };
	if (csvData.length > 1000) return { isValid: false, message: 'Only 1000 rows allowed in CSV File' };
	return { isValid: true };
};

const parseCsvFile = (file: File): Promise<CsvFileValues> => {
	return new Promise((resolve, reject) =>
		Papa.parse(file, {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: true,
			complete: async (result) => {
				const { isValid, message } = validateCsvData(result.data);

				if (isValid)
					resolve({
						batchIds: result.data.map((item) => item.batchId),
						profileIds: result.data.map((item) => item.profileId),
					});
				else {
					showToast('error', message);
					reject();
				}
			},
			error: reject,
		})
	);
};

export const UploadCertificateForm = () => {
	const dispatch = useDispatch();
	const { isSubmitting } = useSelector((state) => ({
		isSubmitting: state.certificate.isSubmitting,
	}));
	const hookFormMethods = useForm<CertificateFormValues>();

	const { handleSubmit } = hookFormMethods;

	const onSubmit = (values: CertificateFormValues) => {
		parseCsvFile(values.csvFile[0])
			.then((results: CsvFileValues) => {
				const modifiedValues = modifyCertificateBeforeCreate({
					...values,
					...results,
				});

				dispatch(uploadCertificate(modifiedValues));
			})
			.catch((err) => {
				showToast('error', err);
			});
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting CSV...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>Upload Certificates</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<AsyncSelectField label="Template" fetchOptions={fetchTemplates} name="templateId" isClearable required />
									</Col>
									<Col sm="12">
										<Input label="CSV File" name="csvFile" type="file" accept=".csv" required />
									</Col>
								</Row>

								<Box mt="1rem">
									<Button size="sm" color="primary" type="submit" disabled={isSubmitting}>
										Upload
									</Button>
								</Box>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
