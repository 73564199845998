import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyObject, accessDeepObject, pickWrapper } from '../../../utils/commonHelpers';
import { loginUser } from '../authSlice';

import LogInForm from './components/LogInForm';

const LogIn = () => {
	const authState = useSelector(({ auth, global }) => ({
		isLoading: auth.loading,
		errors: global.errors,
	}));
	const formState = useSelector(({ form }) => ({ login: form.login_form }));

	const dispatch = useDispatch();

	const handleLogin = useCallback(() => {
		const values = accessDeepObject('login.values', formState);
		if (isNotEmptyObject(values)) {
			dispatch(loginUser(values));
		}
	}, [dispatch, formState]);

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<h3 className="account__title">
							Welcome to
							<span className="account__logo">
								{' '}
								<span className="account__logo-accent">Yellow Class</span>
							</span>
						</h3>
						<h4 className="account__subhead subhead">Admin Panel</h4>
					</div>
					<LogInForm onSubmit={handleLogin} {...pickWrapper(['errors', 'isLoading'], authState)} />
				</div>
			</div>
		</div>
	);
};

export default LogIn;
