import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { TLearningActivitiesState, TLearningActivity } from '../utils/types';

export const fetchLearningActivities = createAsyncThunk(
	'/learningActivities/fetchLearningActivities',
	async (apiParams: TLearningActivitiesState['apiParams'], { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/learning-activities${stringifyQueryParams(apiParams)}`, {
				method: 'GET',
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data as {
				activities: TLearningActivity[];
				total: number;
			};
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const syncLearningActivities = createAsyncThunk(
	'/learningActivities/syncLearningActivities',
	async (
		apiParams: {
			startRowIndex?: number;
			endRowIndex?: number;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/learning-activities/sync-from-sheet', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			showToast('success', 'Activities successfully synced');
			return data as {
				status: boolean;
				msg: string;
			};
		} catch (error) {
			showToast('error', error.message);
		}
	}
);
