import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider, useFormContext, useFieldArray } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe, showToast, isNotEmptyObject, isNotEmptyArray } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import If from 'reusableComponents/If';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { find, flatten, isEqual, isObject } from 'lodash';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';
import { createExperiment, updateExperiment } from '../actions/experiment.actions';
import { experimentTypesList } from './ExperimentTable';

type IExperimentStatus = 'ACTIVE' | 'INACTIVE' | 'COMPLETE' | 'DRAFT';
type IDevice = 'MWEB' | 'WEB' | 'APP' | 'TV' | 'TAB_WEB' | 'TABLET';
type IExperimentType = 'DETERMINISTIC' | 'NON_DETERMINISTIC';
type ISelectOption = { label: string; value: string };

const statusOptions = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
	{ label: 'Draft', value: 'DRAFT' },
	{ label: 'Complete', value: 'COMPLETE' },
	{ label: 'Closed', value: 'CLOSED' },
];

const deviceOptions = [
	{ label: 'Mobile Web', value: 'MWEB' },
	{ label: 'Web', value: 'WEB' },
	{ label: 'App', value: 'APP' },
	{ label: 'TV', value: 'TV' },
	{ label: 'Tab Web', value: 'TAB_WEB' },
	{ label: 'Tablet', value: 'TABLET' },
];

const variantSelectorOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'].map((val) => ({
	label: val,
	value: val,
}));

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface ExperimentFormValues {
	name: string;
	description: string;
	experimentKey: string;
	sourcePages: string;
	experimentType: IExperimentType;
	device: ISelectOption[];
	status: ISelectOption;
	variations: Array<{
		name: string;
		variationKey: string;
		percent?: number;
		selector?: ISelectOption[];
		isDefault: boolean;
		isWinner: boolean;
	}>;
}

type ExperimentInitialValues = ExperimentFormValues & { id: string; createdBy: string; updatedBy: string };

export type ExperimentValuesFromDatabase = Omit<ExperimentInitialValues, 'device' | 'variations'> & {
	name: string;
	description: string;
	experimentKey: string;
	sourcePages: string;
	experimentType: IExperimentType;
	device: IDevice[];
	status: IExperimentStatus;
	variations: Array<{
		name: string;
		variationKey: string;
		percent?: number;
		selector?: string[];
		isDefault: boolean;
		isWinner: boolean;
	}>;
};

interface ModifyExperimentBeforeCreateOrUpdateArgs extends ExperimentFormValues {
	id?: string;
	createdBy: string;
	updatedBy: string;
}

export const modifyExperimentBeforeCreateOrUpdate = (values: ModifyExperimentBeforeCreateOrUpdateArgs) => ({
	...values,
	...(values.experimentType === 'DETERMINISTIC'
		? {
				variations: values.variations.map((variation) => ({
					...variation,
					selector: variation.selector.map((s) => s.value),
				})),
		  }
		: {}),
	status: values.status.value,
	device: values.device.map((item) => item.value),
});

export const modifyExperimentBeforeInitialize = (values: ExperimentValuesFromDatabase): ExperimentInitialValues => {
	// @ts-expect-error
	return {
		...values,
		...(values.experimentType === 'DETERMINISTIC'
			? {
					variations: values.variations.map((variation) => ({
						...variation,
						selector: variation.selector.map((val) => ({
							label: val,
							value: val,
						})),
					})),
			  }
			: {}),
		status: statusOptions.find((status) => status.value === values.status),
		device: values.device.map((item) => deviceOptions.find((elem) => elem.value === item)),
	};
};

interface ExperimentAddEditFormProps {
	location: { search: { id?: string } };
}

export const ExperimentAddEditForm = (props: ExperimentAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<ExperimentInitialValues>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.experiment.isSubmitting,
	}));
	const hookFormMethods = useForm<ExperimentFormValues>();
	const { id } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!id;
	const isEditDisabled = isEditForm && initialValues?.experimentType === 'DETERMINISTIC';

	const { handleSubmit, watch } = hookFormMethods;

	const formValues = watch(['name', 'status', 'experimentType']);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/experiment?id=${id}`, { method: 'GET' }).then((res) => {
				const modifiedValues = modifyExperimentBeforeInitialize(res.data as any);
				setInitialValues(modifiedValues);
				hookFormMethods.reset(modifiedValues);
			});
		}
	}, []);

	useEffect(() => {
		if (formValues.name) hookFormMethods.setValue('experimentKey', formValues.name.toLowerCase().split(' ').join('-'));
	}, [formValues.name]);

	const validateForm = (values: ExperimentFormValues): boolean => {
		const defaultValues = values.variations.map(({ isDefault }) => isDefault);
		const winnerValues = values.variations.map(({ isWinner }) => isWinner);

		if (values.experimentType === 'NON_DETERMINISTIC') {
			const totalPercent = values.variations.map(({ percent }) => +percent).reduce((percent, acc) => acc + percent, 0);
			if (totalPercent !== 100) {
				showToast('error', 'Total variation percent must be 100');
				return false;
			}
		} else {
			const totalSelectors = values.variations.map(({ selector }) => selector);

			if (!variantSelectorOptions.every((v) => find(flatten(totalSelectors), v))) {
				showToast('error', 'All the selectors need to be added across variations');
				return false;
			}
		}

		if (!defaultValues.includes(true)) {
			showToast('error', 'Please mark a variant as default');
			return false;
		}
		if (values.status.value === 'COMPLETE' && !winnerValues.includes(true)) {
			showToast('error', 'Please mark a variant as winner');
			return false;
		}

		return true;
	};

	const onSubmit = (values: ExperimentFormValues) => {
		if (validateForm(values)) {
			const modifiedValues = modifyExperimentBeforeCreateOrUpdate({
				...values,
				id: isEditForm ? initialValues.id : undefined,
				createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
				updatedBy: loggedInUser.id,
			});

			if (isEditForm) {
				dispatch(updateExperiment(modifiedValues));
			} else if (modifiedValues.experimentType === 'DETERMINISTIC') {
				// eslint-disable-next-line no-alert
				const shouldCreateDeterministicExperiment = window.confirm(
					"You won't be able to edit deterministic experiment after creation. Do you still want to continue?"
				);
				if (shouldCreateDeterministicExperiment) dispatch(createExperiment(modifiedValues));
			} else dispatch(createExperiment(modifiedValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Experiment...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Experiment</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<Input label="Experiment Name" name="name" disabled={isEditDisabled} required />
									</Col>
									<Col sm="6">
										<Input label="Experiment Key" name="experimentKey" disabled={isEditDisabled} required />
									</Col>
									<Col sm="6">
										<Input label="Description" name="description" disabled={isEditDisabled} required />
									</Col>
									<Col sm="6">
										<Input
											label="Experiment will run on these pages"
											name="sourcePages"
											disabled={isEditDisabled}
											required
											placeholder="/,/explore,/dashboard"
										/>
									</Col>
									<Col sm="6">
										<RadioGroup
											label="Experiment Type"
											name="experimentType"
											helpText="Deterministic Experiment ensures that user will see the same variant across sessions and devices. So, deterministic experiment cannot be edited after creation."
											fields={experimentTypesList}
											required
											disabled={isEditDisabled}
										/>
									</Col>
									<Col sm="6">
										<SelectField isMulti label="Device" name="device" options={deviceOptions} isDisabled={isEditDisabled} required />
									</Col>
									<Col sm="6">
										<SelectField
											label="Status"
											name="status"
											options={statusOptions}
											required={isEditForm}
											isDisabled={!isEditForm}
											helpText={!isEditForm ? 'Experiment will be marked as DRAFT on creation. You can change status from listing.' : ''}
										/>
									</Col>
									{formValues.experimentType && (
										<VariationsForm
											isEditForm={isEditForm}
											disabled={isEditDisabled}
											name="variations"
											label="Variations"
											status={formValues.status?.value}
											miniumNumberOfFields={2}
											experimentType={formValues.experimentType}
										/>
									)}
								</Row>

								<Col sm="12" className="mt-4">
									<Button disabled={!formValues.experimentType} size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/experiment/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

const VariationsForm = ({
	name,
	status,
	label,
	miniumNumberOfFields = 2,
	maximumNumberOfFields = 100,
	isEditForm,
	experimentType,
	disabled,
}) => {
	const [defaultValues, setDefaultValues] = useState([]);
	const [winnerValues, setWinnerValues] = useState([]);
	const [variationNames, setVariationNames] = useState([]);
	const [selectorOptions, setSelectorOptions] = useState(variantSelectorOptions);
	const { control, watch, setValue, getValues } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const currentVariantNames = fields.map((_, index) => getValues(`${name}.${index}.name`));
	const variantSelectors = fields.map((_, index) => watch(`${name}.${index}.selector`));

	useEffect(() => {
		if (variantSelectors.filter((val) => isNotEmptyArray(val)).length) {
			const selectedOptions = flatten(variantSelectors);

			const unselectedOptions = variantSelectorOptions.filter((option) => {
				return !find(selectedOptions, option);
			});

			if (!isEqual(unselectedOptions, selectorOptions)) setSelectorOptions(unselectedOptions);
		}
	}, [variantSelectors]);

	useEffect(() => {
		if (!isEditForm) {
			const indices = variationNames.map((name, index) => {
				if (currentVariantNames[index] !== undefined && name !== currentVariantNames[index]) return index;
				return -1;
			});

			if (indices.findIndex((val) => val > -1) !== -1 || variationNames.length !== currentVariantNames.length)
				setVariationNames(currentVariantNames);

			fields.forEach((_, index) => {
				if (indices.includes(index))
					setValue(`${name}.${index}.variationKey`, getValues(`${name}.${index}.name`).toLowerCase().split(' ').join('-'));
			});
		}
	}, [currentVariantNames]);

	useEffect(() => {
		if (status !== 'COMPLETE')
			fields.forEach((_, index) => {
				setValue(`${name}.${index}.isWinner`, false);
			});
	}, [status]);

	useEffect(() => {
		defaultValues.forEach((item, index) => {
			setValue(`${name}.${index}.isDefault`, item);
		});
	}, [defaultValues]);

	useEffect(() => {
		winnerValues.forEach((item, index) => {
			setValue(`${name}.${index}.isWinner`, item);
		});
	}, [winnerValues]);

	useEffect(() => {
		if (miniumNumberOfFields && fields.length === 0) {
			for (let i = 0; i < miniumNumberOfFields; i += 1) {
				append({ name: '', variationKey: '', percent: 0, selector: null, isDefault: false, isWinner: false });
			}
		}

		setDefaultValues(
			fields
				.map((item, index) => {
					return isNotEmptyObject(item) ? item.isDefault : watch([`${name}.${index}.isDefault`]);
				})
				.map((item) => {
					return isObject(item) ? Object.values(item)[0] : item;
				})
		);

		setWinnerValues(
			fields
				.map((item, index) => {
					return isNotEmptyObject(item) ? item.isWinner : watch([`${name}.${index}.isWinner`]);
				})
				.map((item) => {
					return isObject(item) ? Object.values(item)[0] : item;
				})
		);

		if (!isEditForm) {
			setVariationNames(
				fields
					.map((_, index) => {
						return watch([`${name}.${index}.name`]);
					})
					.map((item) => {
						return Object.values(item)[0];
					})
			);
		}
	}, [fields]);

	return (
		<Col sm="12">
			{fields.map((item, index) => {
				return (
					<Box key={item.id} pos="relative" bor="1px solid grey" p="1rem" mb="1rem">
						<Row>
							<Col sm="4">
								<Input
									readOnly={disabled}
									name={`${name}.${index}.name`}
									label={` ${label} ${index + 1} Name`}
									defaultValue={item.name}
									required
								/>
							</Col>
							<Col sm="4">
								<Input
									name={`${name}.${index}.variationKey`}
									label={` ${label} ${index + 1} Key`}
									defaultValue={item.variationKey}
									required
									readOnly={disabled}
								/>
							</Col>
							<Col sm="4">
								{experimentType === 'DETERMINISTIC' ? (
									<SelectField
										isMulti
										label={` ${label} ${index + 1} Selector`}
										defaultValue={item.selector}
										name={`${name}.${index}.selector`}
										options={selectorOptions}
										required
										isDisabled={disabled}
									/>
								) : (
									<Input
										type="number"
										min={0}
										max={100}
										pattern={{ value: /[0-9]/, message: `${label} ${index + 1} should be an integer` }}
										name={`${name}.${index}.percent`}
										label={` ${label} ${index + 1} Percent`}
										defaultValue={item.percent}
										required
										readOnly={disabled}
									/>
								)}
							</Col>
							<Col sm="1">
								<Checkbox
									label="Is Default?"
									name={`${name}.${index}.isDefault`}
									disabled={
										disabled ||
										![-1, index].includes(
											fields
												?.map((_, index) => {
													return watch([`${name}.${index}.isDefault`]);
												})
												?.map((item) => {
													return Object.values(item)[0];
												})
												?.findIndex((value) => value)
										)
									}
								/>
							</Col>
							<Col sm="1">
								<Checkbox
									label="Is Winner?"
									name={`${name}.${index}.isWinner`}
									disabled={
										status !== 'COMPLETE' ||
										![-1, index].includes(
											fields
												?.map((_, index) => {
													return watch([`${name}.${index}.isWinner`]);
												})
												?.map((item) => {
													return Object.values(item)[0];
												})
												?.findIndex((value) => value)
										)
									}
								/>
							</Col>
						</Row>
						<If
							condition={index + 1 > miniumNumberOfFields && !disabled}
							then={
								<Box onClick={() => remove(index)} cursor="pointer" pos="absolute" t="0" r="0">
									<span className="lnr lnr-cross" />
								</Box>
							}
						/>
					</Box>
				);
			})}

			<If
				condition={(!maximumNumberOfFields || fields.length < maximumNumberOfFields) && !disabled}
				then={
					<Button
						size="sm"
						onClick={() => append({ name: '', variationKey: '', percent: 0, selector: null, isDefault: false, isWinner: false })}
					>
						Add Variation
					</Button>
				}
			/>
		</Col>
	);
};
