import { put, call } from 'redux-saga/effects';
import { uploadMedias, fetchWrapper } from 'utils/fetchWrapper';
import { actionSpreader, showToast, omitWrapper, isNotEmptyArray } from 'utils/commonHelpers';
import { toggleIsSubmitting } from '../classSlice';
import { mutateSaga } from '../../../shared/saga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';

const getQuestionImage = ({ questionImage, entityId }) => {
	if (questionImage?.url) {
		return questionImage;
	}
	if (isNotEmptyArray(questionImage)) {
		return uploadMedias({
			medias: [{ file: questionImage[0] }],
			entityId,
			entityType: 'POLL_QUESTION_IMAGE',
		});
	}
	return null;
};

const getOptionImage = ({ optionImage, entityId }) => {
	if (optionImage?.url) {
		return optionImage;
	}
	if (isNotEmptyArray(optionImage)) {
		return uploadMedias({
			medias: [{ file: optionImage[0] }],
			entityId,
			entityType: 'POLL_OPTION_IMAGE',
		});
	}
	return null;
};

const uploadPollImages = async ({ questionImage, options }) => {
	const { data } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });
	const entityId = data;
	const [questionImageUploadData, ...optionsImageUploadData] = await Promise.all([
		getQuestionImage({ questionImage, entityId }),
		...options.map((option) => getOptionImage({ optionImage: option.optionImage, entityId })),
	]);
	return {
		// if we are not updating image then we will take previously uploaded image url otherwise we first upload the image and then take url from the response
		questionImageUrl: questionImageUploadData?.url || questionImageUploadData?.[0]?.data?.url,
		optionsImageUrls: optionsImageUploadData?.map((option) => option?.url || option?.[0]?.data?.url),
	};
};

const getModifiedPollOptions = ({ options, optionsImageUrls }) => {
	return options.map((option, index) => {
		return {
			...omitWrapper(['optionImage'], option),
			...(optionsImageUrls[index] ? { optionImage: { url: optionsImageUrls[index] } } : {}),
		};
	});
};

const getModifiedPollsData = async ({ polls }) => {
	return Promise.all(
		polls.map(async (poll) => {
			const uploadedImages = await uploadPollImages({ questionImage: poll.questionImage, options: poll.options });
			return {
				...omitWrapper(['questionImage'], poll),
				questionImage: { url: uploadedImages.questionImageUrl },
				options: getModifiedPollOptions({ options: poll.options, optionsImageUrls: uploadedImages.optionsImageUrls }),
			};
		})
	);
};

export function* submitPollsSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedPollsData = yield call(getModifiedPollsData, { polls: payload.polls });
	const modifiedData = { ...omitWrapper(['isEdit'], { ...payload, polls: modifiedPollsData }) };
	const pollsRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: true,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/poll`,
	});
	if (pollsRes.data) {
		showToast('success', `👍️ Polls updated succcessfully!!`);
		history.push('/class/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: 'Some Error Occurred! Please Try Again' }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
