import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { getOpeningIdSuccess } from '../careerSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getOpeningIdSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/careers/id`);
	if (res.status === 200) {
		yield put(actionSpreader(getOpeningIdSuccess.type, { openingId: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
