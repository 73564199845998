import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { SelectField } from 'reusableComponents/Form/Select';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyArray } from 'utils/commonHelpers';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import history from '../App/history';
import { setApiParams, setMembershipStatus, setPage } from './membershipSlice';
import { fetchMembershipData } from './membership.actions';

const CANCELLATION_STATUS = [
	{ label: 'PENDING', value: 'PENDING' },
	{ label: 'SUCCESS', value: 'SUCCESS' },
	{ label: 'REJECTED', value: 'REJECTED' },
];

const heads = [
	{
		accessor: 'name',
		Header: 'Name',
	},
	{
		accessor: 'mobile',
		Header: 'Mobile',
	},
	{
		accessor: 'status',
		Header: 'Status',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const createNewRows = ({ data, page, rowsCount, actionData }) => {
	let initialCount = rowsCount * (page - 1);
	initialCount += 1;
	return data.map((userMembershipData, index) => {
		return {
			id: initialCount + index,
			name: userMembershipData.profileDetails.fullName,
			mobile: userMembershipData.userDetails.mobile,
			status: userMembershipData.cancellationStatus,
			actions: (
				<>
					<Button
						color="success"
						onClick={() => {
							history.push(`/membership/cancellation-form?orderId=${userMembershipData.id}`);
						}}
					>
						{actionData.name}
					</Button>
				</>
			),
		};
	});
};

const getRowsData = ({ membershipState }) => {
	const status = membershipState?.status;
	switch (status) {
		case 'SUCCESS': {
			if (isNotEmptyArray(membershipState.successData)) {
				return createNewRows({
					data: membershipState.successData,
					page: membershipState.page,
					rowsCount: membershipState.apiParams.limit,
					actionData: {
						name: 'View Form',
					},
				});
			}
			return [];
		}
		case 'PENDING': {
			if (isNotEmptyArray(membershipState.pendingData)) {
				return createNewRows({
					data: membershipState.pendingData,
					page: membershipState.page,
					rowsCount: membershipState.apiParams.limit,
					actionData: {
						name: 'Fill Form',
					},
				});
			}
			return [];
		}
		case 'REJECTED': {
			if (isNotEmptyArray(membershipState.rejectedData)) {
				return createNewRows({
					data: membershipState.rejectedData,
					page: membershipState.page,
					rowsCount: membershipState.apiParams.limit,
					actionData: {
						name: 'View Form',
					},
				});
			}
			return [];
		}
		default:
			return [];
	}
};

const MembershipList = () => {
	const dispatch = useDispatch();
	const [membershipRows, setMembershipRows] = useState([]);

	const membershipState = useSelector(({ membership }) => ({
		successData: membership?.successData,
		rejectedData: membership?.rejectedData,
		pendingData: membership?.pendingData,
		total: membership?.total,
		apiParams: membership?.apiParams,
		page: membership?.page,
		status: membership.status,
	}));

	useEffect(() => {
		const structuredRows = getRowsData({ membershipState });
		setMembershipRows(structuredRows);
	}, [membershipState.successData, membershipState.rejectedData, membershipState.pendingData]);

	useEffect(() => {
		dispatch(fetchMembershipData({ ...membershipState.apiParams, status: membershipState.status, skip: 0 }));
		dispatch(setPage(1));
	}, [membershipState.status]);

	const onStatusChange = (val) => {
		dispatch(setMembershipStatus(val));
		dispatch(
			setApiParams({
				...membershipState.apiParams,
				skip: 0,
			})
		);
	};

	const onChangePage = (itemsPage) => {
		const { apiParams } = membershipState;
		const skip = apiParams.limit * (itemsPage - 1);

		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
			dispatch(fetchMembershipData({ ...membershipState?.apiParams, skip, status: membershipState.status }));
		}
	};

	return (
		<Card>
			<CardBody>
				<FormGroup>
					<Box w="100%">
						<Row style={{ marginBottom: '1rem' }}>
							<Col sm="12">
								<h3>Membership Listing</h3>
							</Col>
						</Row>
						<Row style={{ marginBottom: '1rem' }}>
							<Col sm="3">
								<SelectField
									name="cancellationStatus"
									label="Cancellation Status"
									options={CANCELLATION_STATUS}
									onChange={(val) => onStatusChange(val)}
									defaultValue={{ label: membershipState.status, value: membershipState.status }}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								{isNotEmptyArray(membershipRows) && (
									<>
										<DataPaginationTable
											excludedSortColumns={['mobile', 'actions', 'status', 'name']}
											heads={heads}
											rows={membershipRows}
										/>
										<Pagination
											itemsCount={membershipState.total}
											itemsToShow={membershipState.apiParams.limit}
											pageOfItems={membershipState.page}
											onChangePage={onChangePage}
										/>
									</>
								)}
								{membershipState.total === 0 && <div className="">No data</div>}
							</Col>
						</Row>
					</Box>
				</FormGroup>
			</CardBody>
		</Card>
	);
};

export default MembershipList;
