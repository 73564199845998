import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import differenceInHours from 'date-fns/differenceInHours';
import { useDispatch, useSelector } from 'react-redux';

import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import { showToast } from 'utils/commonHelpers';
import { useHistory, useLocation } from 'react-router';
import { sendMessage, updateThreadAssignee, updateThreadStatus } from 'containers/WhatsappChats/whatsappchat.actions';
import { getQueryParams, updateFilters, assigneesList } from 'containers/WhatsappChats/helpers';

const hasUserRepliedFn = (messages) => messages.filter((msg) => msg.sentBy === 'USER').length > 0;

const getHoursSinceLastUserMessage = (messages) => {
	const userMessages = messages.filter((msg) => msg.sentBy === 'USER');
	const lastUserMessage = userMessages.pop();

	if (!lastUserMessage) return 24; // won't be needed because hasUserReplied will be checked first.

	return Math.abs(differenceInHours(new Date(), new Date(lastUserMessage.createdAt)));
};

const isSendDisabled = ({ hasUserReplied, waitingForSentConfirmation, hoursSinceLastUserMessage }) => {
	if (!hasUserReplied) {
		return {
			status: true,
			message: 'Send button is disabled because user has not replied yet.',
		};
	}
	if (waitingForSentConfirmation) {
		return {
			status: true,
			message: 'Send button is disabled until previous messsage is sent successfully',
		};
	}
	if (hoursSinceLastUserMessage >= 24) {
		return {
			status: true,
			message: 'Send button is disabled because user has not sent any message in last 24 hours.',
		};
	}

	return {
		status: false,
	};
};

// A valid message has length greater than 0 after removing all whitespaces
const isValidMessage = (msg) => msg && msg.replace(/\s/g, '');

const ChatField = ({ userId, mobile, waitingForSentConfirmation, shouldShowAssignButtons }) => {
	const { messages, conversations, loggedInUser } = useSelector(({ whatsappChat, auth }) => ({ ...whatsappChat, loggedInUser: auth.user }));
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [text, setText] = useState('');

	const hasUserReplied = hasUserRepliedFn(messages);
	const hoursSinceLastUserMessage = getHoursSinceLastUserMessage(messages);
	const userMessages = messages.filter((msg) => msg.sentBy === 'USER');
	const { assignedTo, threadStatus } = conversations.find((c) => c.id === mobile) || {};
	const lastUserMessageId = userMessages[userMessages.length - 1]?.id;
	const queryParams = getQueryParams(location, history);

	const messageForDeadStatus = 'Thread is in DEAD state';

	const onUpdateThreadSuccess = () => {
		updateFilters({
			history,
			threadStatus: queryParams.threadStatus,
			assignedTo: queryParams.assignedTo,
		});
	};

	const handleSendMessage = ({ event, shouldCloseThread }) => {
		event.preventDefault();
		if (!mobile) {
			showToast('error', 'Failed to send message. mobile number is required');
		}

		if (threadStatus === 'DEAD') {
			return showToast('warn', 'Thread is in dead state');
		}

		if (!isValidMessage(text)) return null;

		const onSendMessageSuccess = () => {
			setText('');
			if (!shouldCloseThread) {
				if ((threadStatus === 'OPEN' && !assignedTo) || threadStatus === 'ASSIGNED_TO_BOT' || threadStatus === 'UNASSIGNED_BY_BOT')
					return dispatch(
						updateThreadStatus({
							mobile,
							lastUserMessageId,
							status: 'OPEN',
							assignedTo: loggedInUser.email[0],
							onSuccess: onUpdateThreadSuccess,
						})
					);
				return null;
			}

			if (threadStatus === 'CLOSED') {
				return showToast('warn', 'Thread is already closed');
			}

			dispatch(updateThreadStatus({ mobile, lastUserMessageId, status: 'CLOSED', onSuccess: onUpdateThreadSuccess }));
		};

		dispatch(sendMessage({ userId, text, mobile, onSuccess: onSendMessageSuccess, adminUserId: loggedInUser.id }));
	};

	const handleCloseThread = () => {
		dispatch(updateThreadStatus({ mobile, lastUserMessageId, status: 'CLOSED', onSuccess: onUpdateThreadSuccess }));
	};

	const handleThreadAssign = ({ assignTo }) => {
		dispatch(updateThreadAssignee({ mobile, assignTo, onSuccess: onUpdateThreadSuccess }));
	};

	const { status: isSendDisabledStatus, message } = isSendDisabled({
		hasUserReplied,
		waitingForSentConfirmation,
		hoursSinceLastUserMessage,
	});

	return (
		<Box>
			<If
				condition={threadStatus === 'DEAD'}
				then={
					<Box className="chat__text-field" d="flex" ai="center">
						<p style={{ margin: '0 0.5rem', fontSize: '0.8rem', fontWeight: 'bold' }}>{messageForDeadStatus}</p>
					</Box>
				}
				else={
					<Box className="chat__text-field" d="flex" ai="center">
						<If
							condition={isSendDisabledStatus}
							then={
								<Box>
									<Box p="0 0.5rem">
										<p style={{ marginTop: '1rem', fontSize: '0.8rem', fontWeight: 'bold', width: '100%' }}>{message}</p>
									</Box>
									<If
										condition={threadStatus === 'OPEN'}
										then={
											<Box>
												<Button
													size="sm"
													style={{ marginTop: '0rem', marginLeft: '0.5rem', fontSize: '0.6rem', padding: '5px 10px', height: '36px' }}
													onClick={handleCloseThread}
												>
													Close
												</Button>
											</Box>
										}
									/>
								</Box>
							}
							else={
								<form className="chat__form" onSubmit={(event) => handleSendMessage({ event, shouldCloseThread: true })}>
									<Input
										className="chat__field-input"
										style={{ marginBottom: '0.25rem' }}
										name="chatField"
										placeholder="Type here…"
										value={text}
										onChange={(e) => setText(e.target.value)}
									/>
									<Button
										size="sm"
										className="chat__icon-button"
										style={{ marginTop: '0rem', fontSize: '0.6rem', padding: '5px 10px' }}
										type="submit"
										disabled={!isValidMessage(text)}
									>
										Send & Close
									</Button>
									<Button
										size="sm"
										style={{ marginTop: '0rem', fontSize: '0.6rem', padding: '5px 10px', height: '36px' }}
										onClick={(event) => {
											if (isValidMessage(text)) {
												handleSendMessage({ event, shouldCloseThread: false });
											} else {
												if (threadStatus === 'CLOSED') {
													return showToast('warn', 'Thread is already closed');
												}
												handleCloseThread();
											}
										}}
									>
										{isValidMessage(text) ? 'Send' : 'Close'}
									</Button>
								</form>
							}
						/>
					</Box>
				}
			/>
			<If
				condition={threadStatus !== 'DEAD' && shouldShowAssignButtons}
				then={
					<Box d="flex" mr="1rem" ai="center" p="0 0.5rem">
						<Box style={{ fontWeight: 'bold' }}>Assign To: </Box>
						<Box d="flex" ai="center">
							{assigneesList.map(({ name, email }) => (
								<If
									condition={email !== assignedTo || threadStatus === 'CLOSED'}
									then={
										<Button
											key={name}
											size="sm"
											style={{ fontSize: '0.7rem', padding: '5px 10px', margin: '0 0.5rem' }}
											onClick={() => handleThreadAssign({ assignTo: email })}
										>
											{name}
										</Button>
									}
								/>
							))}
						</Box>
					</Box>
				}
			/>
		</Box>
	);
};

export default ChatField;
