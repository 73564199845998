import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import Box from '../../../../reusableComponents/Box';
import DropZoneField from '../../../../reusableComponents/Form/DropZone';
import { required } from '../../../../reusableComponents/Form/fieldValidators';
import { renderCheckBoxButton, renderCKEditor, renderTextField } from '../../../../reusableComponents/Form/formComponents';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import {
	getSanitizedImageUrl,
	isNotEmptyArray,
	isNotEmptyObject,
	omitWrapper,
	parseQueryParams,
	pickWrapper,
	showToast,
} from '../../../../utils/commonHelpers';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';
import { getTeamMate, getTeamMateId, getTeamMateSuccess, teamMateOnSubmit } from '../../aboutSlice';

const AboutAddEdit = (props) => {
	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} Team Mate</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="6">
						<Field label="Name" isRequired validate={required} type="text" component={renderTextField} name="fullName" />
					</Col>
					<Col sm="6">
						<Field label="Designation" isRequired validate={required} type="text" component={renderTextField} name="designation" />
					</Col>
					<Col sm="12">
						<Field label="LinkedIn URL" isRequired validate={required} type="text" component={renderTextField} name="linkedInUrl" />
					</Col>
					<Col sm="12">
						<Field label="About" isRequired validate={required} type="text" component={renderTextField} name="about" />
					</Col>
					<Col sm="6">
						<Field label="Order" isRequired validate={required} type="number" component={renderTextField} name="order" />
					</Col>
					<Col sm="3" className="mt-3">
						<Field label="Is Active?" name="isActive" component={renderCheckBoxButton} />
					</Col>
					<Col sm="12">
						<span className="form__form-group-label">
							Photo <span className="form__form_group-label--required">&#42;</span>
						</span>
						<DropZoneField
							withCaption
							value={
								props.profilePic ? [{ photo: props.profilePic[0].photo, caption: props.profilePic ? props.profilePic[0].caption : '' }] : []
							}
							onChange={(v) => {
								props.setProfilePic(v.length ? [{ photo: v[0].photo, caption: v[0].caption }] : null);
							}}
						/>
					</Col>
					<Col sm="12" className="mt-4">
						<Button size="sm" color="primary" type="submit">
							{isNotEmptyObject(props.initialValues) ? 'Save' : 'Create'}
						</Button>
						<Button size="sm" color="success" type="button" onClick={() => history.push('/about-us/list')}>
							Cancel
						</Button>
					</Col>
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_about', enableReinitialize: true });

const CareerReduxForm = compose(withReduxForm)(AboutAddEdit);

const AboutForm = (props) => {
	const [profilePic, setProfilePic] = useState(null);

	const aboutState = useSelector(({ about }) => ({
		loading: about.loading,
		teamMate: about.teamMate,
		isSubmitting: about.isSubmitting,
		teamMateId: about.teamMateId,
	}));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getTeamMateId());
		if (props.location.search) {
			dispatch(getTeamMate(pickWrapper(['id'], parseQueryParams(props.location.search))));
		}
		return () => {
			dispatch(getTeamMateSuccess({}));
		};
	}, [props.location.search]);

	useEffect(() => {
		if (isNotEmptyObject(aboutState.teamMate)) {
			if (isNotEmptyObject(aboutState.teamMate.photo)) {
				setProfilePic([
					{
						photo: [
							{
								name: aboutState.teamMate.photo.id,
								id: aboutState.teamMate.photo.id,
								url: aboutState.teamMate.photo.url,
								preview: getSanitizedImageUrl(aboutState.teamMate.photo.url),
							},
						],
						caption: aboutState.teamMate.photo.caption,
					},
				]);
			}
		}
	}, [aboutState.teamMate]);

	const handleSubmit = async (params) => {
		if (!profilePic) {
			showToast('error', '🚫️ Photo is empty!');
			return;
		}

		const modifiedParams = { ...params };

		if (isNotEmptyArray(profilePic)) {
			const [pc] = profilePic;
			if (pc && pc.id) {
				modifiedParams.photo = { ...aboutState.teamMate.photo.photo[0], caption: pc.caption };
			} else {
				modifiedParams.photo = pc;
			}
		}

		dispatch(
			teamMateOnSubmit(
				omitWrapper(['isActive'], {
					...modifiedParams,
					status: params.isActive ? STATUS_ACTIVE : STATUS_INACTIVE,
					isEdit: !!props.location.search,
				})
			)
		);
	};

	if (aboutState.loading) {
		return <div>Loading...</div>;
	}

	return (
		<LoadingOverlay isLoading={aboutState.isSubmitting} msg="Submitting Team Mate...">
			<Card>
				<CardBody>
					<CareerReduxForm
						initialValues={
							isNotEmptyObject(aboutState.teamMate)
								? { ...aboutState.teamMate, isActive: aboutState.teamMate.status === STATUS_ACTIVE }
								: null
						}
						onSubmit={handleSubmit}
						teamMateId={parseQueryParams(props.location.search).id || aboutState.teamMateId}
						profilePic={profilePic}
						setProfilePic={(pic) => setProfilePic(pic)}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default AboutForm;
