import React, { useEffect, useState } from 'react';

export const MatchTheColumnMobilePreview = ({ itemList }) => {
	const [items, setItems] = useState(itemList);

	useEffect(() => {
		setItems(itemList);
	}, [itemList]);

	const RenderColumn = ({ column }) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{items &&
					items
						.filter((item) => item.column === column)
						.map((item) => (
							<div
								key={item.id}
								style={{
									width: '100%',
									height: '100%',
									border: '1px solid #28C9A2',
									padding: '5px',
									margin: '5px',
									borderRadius: '5px',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									boxShadow: '0px 3px 0px 1px #28C9A2',
									backgroundColor: '#28c9a214',
								}}
							>
								{item.value.image && item.value.image.length > 0 && (
									<img src={item.value.image[0].preview} alt={item.name} style={{ width: '50%', height: '50%' }} />
								)}
								<p
									style={{
										textAlign: 'center',
										fontWeight: 600,
										color: 'rgb(120 118 126)',
									}}
								>
									{item.value.text}
								</p>
							</div>
						))}
			</div>
		);
	};

	return (
		<div
			style={{
				width: '20rem',
				height: '33rem',
				border: '20px solid #fff',
				borderRadius: '10px',
				padding: '30px 15px ',
				backgroundColor: 'rgb(26 221 255 / 9%)',
				boxShadow: 'inset 0px 0px 12px 2px #bababa66, 0px 0px 18px 2px #bababa99',
				position: 'sticky',
				top: '10rem',
				overflowY: 'scroll',
			}}
		>
			<h3 style={{ textAlign: 'center' }}>Match The Following</h3>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: '80%',
				}}
			>
				<div style={{ width: '45%' }}>
					<RenderColumn column="1" />
				</div>

				<div style={{ width: '45%' }}>
					<RenderColumn column="2" />
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<button
					type="button"
					style={{
						width: '90%',
						height: '40px',
						border: 'none',
						borderRadius: '100px',
						marginTop: 'auto',
						color: '#fff',
						backgroundColor: '#28C9A2',
						boxShadow: 'inset 0px 0px 12px 2px #bababa66, 0px 0px 18px 2px #bababa99',
					}}
				>
					Continue
				</button>
			</div>
		</div>
	);
};
