import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { VideoTable } from './components/VideoTable';
import VideoForm from './components/VideoForm';

const Video = () => {
	return (
		<div>
			<Switch>
				<Route path="/video/list" component={VideoTable} />
				<Route path="/video/add-edit" component={VideoForm} />
			</Switch>
		</div>
	);
};

export default Video;
