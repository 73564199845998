/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import EscapeKeyOverlay from './escapeKeyOverlay';
import { isNotEmptyObject } from '../../utils/commonHelpers';

const StyledModal = styled.div`
	position: fixed;
	${(props) =>
		!props.customPosition &&
		css`
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		`}
	z-index: ${(props) => props.modalIdx + 1000 || 1001};
	background: white;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
`;

const ModalComponent = ({ customStyle, className, modalIdx, customPosition, children }) => (
	<StyledModal
		style={{
			...customStyle,
		}}
		className={className}
		modalIdx={modalIdx}
		customPosition={customPosition}
	>
		{children}
	</StyledModal>
);

const CustomModal = ({ closeModal, children, style, transitionStyles, overrideBg, className, escapeOverlay, modalIdx, customPosition }) => [
	isNotEmptyObject(transitionStyles) ? (
		<Transition in timeout={0}>
			{(state) => (
				<ModalComponent
					customStyle={{
						...style,
						...transitionStyles[state],
					}}
					className={className}
					modalIdx={modalIdx}
					customPosition={customPosition}
				>
					{children}
				</ModalComponent>
			)}
		</Transition>
	) : (
		<ModalComponent
			customStyle={{
				...style,
			}}
			className={className}
			modalIdx={modalIdx}
			customPosition={customPosition}
		>
			{children}
		</ModalComponent>
	),
	<EscapeKeyOverlay
		bg
		z={modalIdx + 999 || 1000}
		overrideBg={overrideBg}
		{...(escapeOverlay === false
			? {}
			: {
					overlayClick: () => closeModal(),
					escapePressed: () => closeModal(),
			  })}
	/>,
];

export default CustomModal;
