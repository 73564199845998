import { put, call } from 'redux-saga/effects';
import { actionSpreader, stringifyQueryParams } from '../../../utils/commonHelpers';
import { dropboxFoldersSuccess, toggleLoading, toggleFoldersLoading } from '../videoSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getDropboxFoldersSaga({
	payload,
}: ReturnType<() => { type: string; payload: { path: string; cursor: string; shouldConcatenate: boolean } }>) {
	const qp = stringifyQueryParams(payload);
	if (payload.shouldConcatenate) yield put(actionSpreader(toggleFoldersLoading.type, { loading: true }));
	else yield put(actionSpreader(toggleLoading.type, { loading: true }));
	const {
		data: { status, data, msg },
	} = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/dropbox/list${qp}`);
	if (status !== 200 || data.error) {
		yield put(actionSpreader(setError.type, { errors: `🚫️ ${msg || data.error}` }));
		if (payload.shouldConcatenate) yield put(actionSpreader(toggleFoldersLoading.type, { loading: false }));
		else yield put(actionSpreader(toggleLoading.type, { loading: false }));
	} else {
		yield put(actionSpreader(dropboxFoldersSuccess.type, { ...data, shouldConcatenate: payload.shouldConcatenate }));
		if (payload.shouldConcatenate) yield put(actionSpreader(toggleFoldersLoading.type, { loading: false }));
		else yield put(actionSpreader(toggleLoading.type, { loading: false }));
	}
}
