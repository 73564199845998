import { createSlice } from '@reduxjs/toolkit';

const gallerySlice = createSlice({
	name: 'gallery',
	initialState: {
		loading: false,
		galleryImages: [],
		images: [],
		isSubmitting: false,
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		getGalleryImages: () => {},
		getGalleryImagesSuccess: (state, action) => {
			state.galleryImages = action.payload;
		},
		getClassImages: () => {},
		getClassImagesSuccess: (state, action) => {
			state.images = action.payload;
		},
		galleryOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const {
	toggleLoading,
	getGalleryImages,
	getGalleryImagesSuccess,
	getClassImages,
	getClassImagesSuccess,
	galleryOnSubmit,
	toggleIsSubmitting,
} = gallerySlice.actions;

export default gallerySlice.reducer;
