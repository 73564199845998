import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import theme from '../../../../utils/theme';
import { showToast } from '../../../../utils/commonHelpers';

import CustomModal from '../../../../reusableComponents/CustomModal';
import Box from '../../../../reusableComponents/Box';

const StyledModal = styled.div`
	height: auto;
	width: 30rem;
	padding: 1rem;
`;

const btnStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const CategoryModal = ({ initialValue, handleSubmit, closeModal }) => {
	const [title, setTitle] = useState('');
	const [displayTitle, setDisplayTitle] = useState('');

	React.useEffect(() => {
		if (initialValue) {
			setTitle(initialValue.title);
			setDisplayTitle(initialValue.displayTitle);
		}
		return () => {
			setTitle('');
			setDisplayTitle('');
		};
	}, [initialValue]);

	const submitHandler = () => {
		if (!title) {
			showToast('error', `Please fill Category Title`);
		} else if (!displayTitle) {
			showToast('error', `Please fill Category Display Title`);
		} else {
			const updatedParams = {
				title,
				displayTitle,
				isEdit: !!initialValue,
				...(initialValue ? { id: initialValue.id } : {}),
			};
			handleSubmit(updatedParams);
		}
	};

	return (
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					<Box w="85%" ellipse fs="1.4rem">
						{initialValue ? 'Edit' : 'Add'} Category
					</Box>
					<Box onClick={() => closeModal()} cursor="pointer">
						<span className="sidebar__link-icon lnr lnr-cross" />
					</Box>
				</Box>
				<form
					className="form"
					onSubmit={(e) => {
						e.preventDefault();
						submitHandler();
					}}
				>
					<div className="form__form-group">
						<span className="form__form-group-label">
							Title <span className="form__form_group-label--required">&#42;</span>
						</span>
						<div className="form__form-group-input-wrap">
							<input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
						</div>
					</div>
					<div className="form__form-group">
						<span className="form__form-group-label">
							Display Title <span className="form__form_group-label--required">&#42;</span>
						</span>
						<div className="form__form-group-input-wrap">
							<input type="text" value={displayTitle} onChange={(e) => setDisplayTitle(e.target.value)} />
						</div>
					</div>
					<Box tl="right" w="100%">
						<Button color="secondary" size="md" style={btnStyles} onClick={() => closeModal()}>
							Cancel
						</Button>
						<Button color="primary" size="md" style={btnStyles} type="submit">
							{initialValue ? 'Edit' : 'Add'} Category
						</Button>
					</Box>
				</form>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(CategoryModal);
