import { isNotEmptyArray } from '../../utils/commonHelpers';

export const modifyBeforeSaveDiy = (params, diy) => {
	const res = { ...params };
	if (isNotEmptyArray(params.ageGroup)) {
		res.ageGroup = {
			min: params.ageGroup[0],
			max: params.ageGroup[1],
		};
	}
	res.photos = params.photos.reduce((acc, curr) => {
		if (curr.id) {
			const dd = diy.photos.find((photo) => photo.id === curr.id);
			return [...acc, dd];
		}
		return [...acc, curr];
	}, []);
	return res;
};
