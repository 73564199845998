import { takeLatest } from 'redux-saga/effects';

import { getGalleryImages, galleryOnSubmit, getClassImages } from './gallerySlice';
import { getGalleryImagesSaga } from './sagas/getGalleryImagesSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { getClassImagesSaga } from './sagas/getClassImagesSaga';

export default function* programSaga() {
	yield takeLatest(getGalleryImages.type, getGalleryImagesSaga);
	yield takeLatest(getClassImages.type, getClassImagesSaga);
	yield takeLatest(galleryOnSubmit.type, onSubmitSaga);
}
