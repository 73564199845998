import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { getTeamMateIdSuccess } from '../aboutSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getTeamMateIdSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/create-mongo-id`);
	if (res.status === 200) {
		yield put(actionSpreader(getTeamMateIdSuccess.type, { teamMateId: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
