import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../gallerySlice';
import { mutateSaga } from '../../../shared/saga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const galleryRes = yield call(mutateSaga, {
		payload,
		isEdit: true,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/gallery`,
	});
	if (galleryRes.status === 200) {
		showToast('success', `👍️ Gallery updated succcessfully!!`);
		history.push('/gallery/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: galleryRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
