import { createSlice } from '@reduxjs/toolkit';
import { fetchQuizes, getQuizDetails, updateQuiz, deleteQuiz } from './actions/quiz.action';

// create slice for quiz.
const quizSlice = createSlice({
	name: 'quizes',
	initialState: {
		quizes: [],
		total: 0,
		page: 1,
		quizesLoading: false,
		quizesError: '',
		apiParams: {
			sortKey: 'createdAt',
			searchKey: '',
			sortOrder: -1,
			limit: 20,
			skip: 0,
		},
	},
	reducers: {
		// set quiz list.
		setQuizes: (state, action) => {
			state.quizes = action.payload;
		},
		// set quiz list loading.
		setQuizesLoading: (state, action) => {
			state.quizesLoading = action.payload;
		},
		// set quiz list error.
		setQuizesError: (state, action) => {
			state.quizesError = action.payload;
		},
		// set quiz list api params.
		setApiParams: (state, action) => {
			state.apiParams = action.payload;
		},
	},
	extraReducers: {
		[fetchQuizes.pending as any]: (state) => {
			state.quizesLoading = true;
		},
		[fetchQuizes.fulfilled as any]: (state, action) => {
			state.quizesLoading = false;
			state.quizes = action.payload.quizes;
			state.total = action.payload.total;
		},
		[fetchQuizes.rejected as any]: (state, action) => {
			state.quizesLoading = false;
			state.quizesError = action.payload;
		},
		[getQuizDetails.fulfilled as any]: (state, { payload }) => {
			state.quizes.push(payload);
		},
		[deleteQuiz.fulfilled as any]: (state, { meta }) => {
			const { quizId } = meta.arg;
			state.quizes = state.quizes.filter((quiz) => quiz.id !== quizId);
		},
		[updateQuiz.fulfilled as any]: (state, { meta, payload }) => {
			const { quizId } = meta.arg;
			const index = state.quizes.findIndex((quiz) => quiz.id === quizId);
			state.quizes[index] = payload;
		},
	},
});

export const { setApiParams } = quizSlice.actions;

export default quizSlice.reducer;
