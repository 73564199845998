import { useRef, useState, useCallback } from 'react';

/**
 *
 * A custom hook implementation for useRef. This hook returns a
 * variable isRefInitialized which can be used to trigger any effect
 * once the ref has been initialized
 *
 * @param {any} initialValue of the ref
 * @returns {ref, setRef, isRefInitialzed}
 * ref => ref Object
 * setRef => a callback function for initialzing the ref
 * isRefInitialzed => a boolean which shows if the ref is initialized or not
 */
const useRefCallback = (initialValue?: any) => {
	const ref = useRef(initialValue);
	const [isRefInitialized, setIsRefInitialzed] = useState(false);
	const setRef = useCallback((node) => {
		setIsRefInitialzed(!!node);
		ref.current = node;
	}, []);

	return { ref, setRef, isRefInitialized };
};

export default useRefCallback;
