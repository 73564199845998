import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../userPostSlice';

export const getUserPosts = createAsyncThunk(
	'userPost/getPosts',
	async ({ sortKey, sortOrder, limit, skip, status, handledBy, showOnFeed, mediaType, source, userQuery }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const res = await fetchWrapper(`/v1/admin/user-posts`, {
			method: 'POST',
			body: {
				sortKey,
				sortOrder,
				limit,
				skip,
				status,
				showOnFeed: showOnFeed === 'NOT_DECIDED' ? null : showOnFeed,
				handledBy,
				userQuery,
				mediaType,
				source: source === 'NONE' ? null : source,
			},
		});

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				return {
					posts: res.data.posts.length > limit ? res.data.posts.slice(0, -1) : res.data.posts,
					total: res.data.posts.length,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const getPendingPost = createAsyncThunk('userPost/getPendingPost', async ({ adminUserId, isAdminAMentor }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const qp = stringifyQueryParams({
		adminUserId,
		isAdminAMentor,
	});

	const res = await fetchWrapper(`/v1/admin/user-post/pending${qp}`, { method: 'GET' });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				pendingPost: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const updateUserPost = createAsyncThunk('userPost/updatePost', async ({ onSuccess, ...valuesToUpdate }: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/user-post', {
			method: 'PUT',
			body: valuesToUpdate,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else if (onSuccess) {
			// Execute callback function on successful update
			onSuccess();
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateUserPostAndUpdateList = createAsyncThunk(
	'userPost/updatePostAndUpdateList',
	async ({ postId, postIndex, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: postId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/user-post', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Post updated succcessfully!!`);
				return {
					postIndex,
					postData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);

export const getUserPostAnalytics = createAsyncThunk('userPost/userPostAnalytics', async ({ adminUserId }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	try {
		const res = await fetchWrapper(`/v1/admin/user-post/analytics?adminUserId=${adminUserId}`, {
			method: 'GET',
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				postAnalytics: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const getPickedPostAnalytics = createAsyncThunk('userPost/pickedPostAnalytics', async (data, { dispatch }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/user-post/picked-analytics`, {
			method: 'GET',
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				postAnalytics: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});
