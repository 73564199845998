import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { showToast } from '../../utils/commonHelpers';

class DropZoneMultipleField extends Component {
	constructor() {
		super();
		this.state = {};
		this.onDrop = this.onDrop.bind(this);
	}

	onDrop(files) {
		const { onChange } = this.props;
		onChange(
			this.props.withCaption
				? files.map((fl) => ({
						photo: Object.assign(fl.photo[0], {
							preview: !fl.photo[0].preview ? URL.createObjectURL(fl.photo[0]) : fl.photo[0].preview,
						}),
						caption: fl.caption,
				  }))
				: files.map((fl) =>
						Object.assign(fl, {
							preview: !fl.preview ? URL.createObjectURL(fl) : fl.preview,
						})
				  )
		);
	}

	removeFile = (index, e) => {
		const { value, onChange } = this.props;
		e.preventDefault();
		onChange(value.filter((val, i) => i !== index));
	};

	render() {
		const { name, value, numberOfFiles, customCaptionLabel } = this.props;
		const files = value;

		return (
			<div>
				{this.props.withCaption && files && Array.isArray(files) && (
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{files.map((file, i) => (
							<div key={file.photo.name} className="dropzone__imgs-wrapper" style={{ width: 'auto' }}>
								<div className="dropzone__img" style={{ backgroundImage: `url(${file.photo.preview})`, width: '100%' }}>
									{file.photo.type === 'application/pdf' && (
										<object style={{ width: '100%' }} data={file.photo.preview} type={file.photo.type}>
											<p>
												Preview PDF: <a href={file.photo.preview}>here</a>
											</p>
										</object>
									)}
									<p className="dropzone__img-name">{file.photo.name}</p>
									<button className="dropzone__img-button dropzone__img-delete" type="button" onClick={(e) => this.removeFile(i, e)}>
										Remove
									</button>
								</div>
								{this.props.withCaption && (
									<div className="form__form-group-input-wrap mt-1 mb-3">
										<span className="form__form-group-label">{customCaptionLabel ?? 'Caption'}</span>
										<input
											placeholder={customCaptionLabel ?? 'Max 1000 characters'}
											maxLength={1000}
											value={file ? file.caption : ''}
											onChange={(e) => {
												files[i].caption = e.target.value;
												this.props.onChange([...files]);
											}}
										/>
										{file.photo.name && (
											<div style={{ paddingTop: '10px' }}>
												Filename: <b>{file.photo.name}</b>
											</div>
										)}
									</div>
								)}
							</div>
						))}
					</div>
				)}

				<div className="dropzone dropzone--multiple">
					<Dropzone
						className="dropzone__input"
						accept={this.props.accept ? this.props.accept : 'image/jpeg, image/png'}
						name={name}
						onDrop={(filesToUpload) => {
							const updatedFiles = !this.props.withCaption
								? value
									? [...value, ...filesToUpload]
									: filesToUpload
								: value
								? [
										...value.map((v) => ({ photo: [v.photo], caption: v.caption })),
										...filesToUpload.map((file) => ({ photo: [file], caption: '' })),
								  ]
								: filesToUpload.map((file) => ({ photo: [file], caption: '' }));
							if (updatedFiles.length > numberOfFiles) {
								showToast(
									'error',
									`You can only select ${numberOfFiles} images. ${value.length > 0 ? 'Remove  existing images to select more.' : ''}  `
								);
							} else {
								this.onDrop(updatedFiles);
							}
						}}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()} className="dropzone__input">
								{(!files || files.length === 0 || this.props.withCaption) && (
									<div className="dropzone__drop-here">
										<span className="lnr lnr-upload" /> Drop file here to upload
									</div>
								)}
								<input {...getInputProps()} />
							</div>
						)}
					</Dropzone>
					{!this.props.withCaption && files && Array.isArray(files) && (
						<div className="dropzone__imgs-wrapper">
							{files.map((file, i) => (
								<div className="dropzone__img" key={file.name} style={{ backgroundImage: `url(${file.preview})` }}>
									<p className="dropzone__img-name">{file.name}</p>
									<button className="dropzone__img-button dropzone__img-delete" type="button" onClick={(e) => this.removeFile(i, e)}>
										Remove
									</button>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default DropZoneMultipleField;
