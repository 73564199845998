import { createSlice } from '@reduxjs/toolkit';
import { deleteQuestion, fetchQuestions, getQuestionDetails, updateQuestion } from './actions/question.action';

const questionsSlice = createSlice({
	name: 'questions',
	initialState: {
		questions: [],
		loading: false,
		error: null,
		pageNo: 1,
		total: 0,
		apiParams: {
			sortKey: 'title',
			sortOrder: -1,
			limit: 20,
			skip: 0,
			searchKey: '',
		},
	},
	reducers: {
		questionsLoading: (state) => {
			state.loading = true;
		},
		questionsLoaded: (state) => {
			state.loading = false;
		},
		questionsError: (state, action) => {
			state.error = action.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ searchKey: action.payload.searchKey },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
	},
	extraReducers: {
		[fetchQuestions.pending as any]: (state) => {
			state.loading = true;
		},
		[fetchQuestions.fulfilled as any]: (state, action) => {
			state.loading = false;
			state.total = action.payload.total;
			state.questions = action.payload.questions;
		},
		[getQuestionDetails.fulfilled as any]: (state, action) => {
			state.questions.push(action.payload);
		},
		[deleteQuestion.fulfilled as any]: (state, action) => {
			state.questions = state.questions.filter((question) => question.id !== action.meta.arg.questionId);
		},
		[updateQuestion.fulfilled as any]: (state, action) => {
			const index = state.questions.findIndex((question) => question.id === action.payload.questionId);
			if (action.payload.status) state.questions[index].status = action.payload.status;
		},
	},
});

export const { questionsLoading, questionsLoaded, setApiParams } = questionsSlice.actions;

export default questionsSlice.reducer;
