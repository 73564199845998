import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { accessDeepObject, isNotEmptyArray } from 'utils/commonHelpers';
import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import PaginationNew from 'reusableComponents/Pagination/PaginationNew';
import { format } from 'date-fns';
import { getCoaches } from '../actions/coach.actions';
import { setApiParams } from '../coachSlice';
import history from '../../App/history';

const excludedSortColumns = ['status', 'name', 'actions', 'mobileNumber'];

const getCoachProfileStatus = (status) => {
	switch (status) {
		case 12:
			return 'Pending';
		case 13:
			return 'Approved';
		case 14:
			return 'Rejected';
		default:
			return 'Pending';
	}
};
const Actions = ({ coachProfileId, mobileNumber }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/coach/add-edit?coachProfileId=${coachProfileId}`)}>Edit Coach</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ coachesList }) {
	return coachesList.map((coach) => {
		return {
			name: coach.fullName,
			createdAt: format(new Date(coach.createdAt), 'dd-MM-yyyy HH:mm'),
			mobileNumber: coach.userDetails.mobile,
			status: getCoachProfileStatus(coach.status),
			actions: <Actions {...{ coachProfileId: coach.id, mobileNumber: coach.userDetails.mobile }} />,
		};
	});
}

export const CoachTable = () => {
	const coachListingState = useSelector(({ coach }) => ({
		loading: coach.loading,
		coaches: coach.coaches,
		pageNo: coach.pageNo,
		total: coach.total,
		apiParams: coach.apiParams,
	}));

	const dispatch = useDispatch();
	const [coachList, setcoachList] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	const [heads, setHeads] = useState([
		{ accessor: 'name', Header: 'Coach Name' },
		{ accessor: 'createdAt', Header: 'Profile Creation date' },
		{ accessor: 'mobileNumber', Header: 'Mobile Number' },
		{ accessor: 'status', Header: 'Status' },
		{ accessor: 'actions', Header: 'Action' },
	]);

	useEffect(() => {
		dispatch(getCoaches(coachListingState.apiParams));
	}, [coachListingState.apiParams]);

	useEffect(() => {
		setPageOfItems(coachListingState.pageNo);
	}, [coachListingState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(coachListingState.coaches)) {
			const structuredRows = createRows({
				coachesList: coachListingState.coaches,
			});
			setcoachList(structuredRows);
		} else {
			setcoachList([]);
		}
	}, [coachListingState.coaches]);

	const onChangePage = (skip) => {
		const { apiParams } = coachListingState;

		const modifiedValue = {
			...apiParams,
			skip,
		};
		dispatch(setApiParams(modifiedValue));
	};

	const onSort = (sortColumn, sortDirection) => {
		if (!excludedSortColumns.includes(sortColumn) && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', coachListingState),
					skip: accessDeepObject('apiParams.limit', coachListingState) * (pageOfItems - 1),
				})
			);
			dispatch(getCoaches(coachListingState.apiParams));
		}
	};

	return (
		<>
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={12} className="mb-3">
								<h3>Coach Listing</h3>
							</Col>
						</Row>
						{isNotEmptyArray(coachList) && (
							<>
								<DataPaginationTable heads={heads} rows={coachList} onSort={onSort} excludedSortColumns={excludedSortColumns} />
								<PaginationNew
									showPrevious={coachListingState.apiParams.skip}
									showNext={coachListingState.total > coachListingState.apiParams.limit}
									goBack={() => onChangePage(coachListingState.apiParams.skip - coachListingState.apiParams.limit)}
									goForward={() => onChangePage(coachListingState.apiParams.skip + coachListingState.apiParams.limit)}
								/>
							</>
						)}
						{coachListingState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};
