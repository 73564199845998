import { isNotEmptyArray, omitWrapper } from '../../utils/commonHelpers';

export const modifyBeforeSetMentor = (params) => {
	return omitWrapper(['video'], params);
};

export const modifyBeforeSaveMentor = (params, mentor) => {
	const res = { ...params };
	if (isNotEmptyArray(params.profilePic)) {
		const [pc] = params.profilePic;
		if (pc && pc.id) {
			res.profilePic = { ...mentor.profilePic.photo[0], caption: pc.caption };
		} else {
			res.profilePic = pc;
		}
	}

	if (isNotEmptyArray(params.cutoutPic)) {
		const [pc] = params.cutoutPic;
		if (pc && pc.id) {
			res.cutoutPic = { ...mentor.cutoutPic.photo[0], caption: pc.caption };
		} else {
			res.cutoutPic = pc;
		}
	}

	if (isNotEmptyArray(params.mobileCoverPic)) {
		const [mc] = params.mobileCoverPic;
		if (mc && mc.id) {
			res.mobileCoverPic = { ...mentor.mobileCoverPic.photo[0], caption: mc.caption };
		} else {
			res.mobileCoverPic = mc;
		}
	}

	if (isNotEmptyArray(params.webCoverPic)) {
		const [wc] = params.webCoverPic;
		if (wc && wc.id) {
			res.webCoverPic = { ...mentor.webCoverPic.photo[0], caption: wc.caption };
		} else {
			res.webCoverPic = wc;
		}
	}

	if (isNotEmptyArray(params.videoCoverPicture)) {
		const [vc] = params.videoCoverPicture;
		if (vc && vc.id) {
			res.videoCoverPicture = { ...mentor.videoCoverPicture.photo[0], caption: vc.caption };
		} else {
			res.videoCoverPicture = vc;
		}
	}

	return res;
};
