import React from 'react';
import { useLocation } from 'react-router';
import Box from 'reusableComponents/Box';
import { getSanitizedImageUrl } from 'utils/commonHelpers';

const ImageViewer = () => {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const imageSrc = queryParams.get('imageSrc');

	return (
		<Box customStyle={{ display: 'flex', justifyContent: 'center', position: 'absolute', top: '0' }}>
			<img style={{ maxHeight: '800px' }} alt={imageSrc} src={getSanitizedImageUrl(imageSrc)} />
		</Box>
	);
};

export default ImageViewer;
