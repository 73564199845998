import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import { accessDeepObject } from 'utils/commonHelpers';
import { getAnswerPercent, getAnswerStatus, getOptionsState, RESULT_TEXT, OptionFeedbackText } from './helpers';

import { StyledPoll, StyledResult, StyledOption, StyledOptionFeedback, StyledOptionInner } from './styledComponents';

const Poll = ({ options, showResult, onPollSubmit, timeOut, selectedAnswerId, questionType, pollResult, isShortsPoll }) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const correctAnswerId = accessDeepObject('correctAnswerId', pollResult);

	useEffect(() => {
		// Case when the user has either not selected any of the options
		// or has selected an option but hasn't pressed the submit button
		if (timeOut) onPollSubmit(selectedOption || '');
	}, [timeOut]);

	const answerStatus = getAnswerStatus({
		answerId: correctAnswerId,
		userAnswerId: selectedAnswerId,
	});

	return (
		<StyledPoll>
			{/* Only show answer when the question type is ASSESSMENT */}
			<If condition={!!pollResult} then={<StyledResult answerStatus={answerStatus}>{RESULT_TEXT[answerStatus]}</StyledResult>} />
			{options.map((item, index) => {
				const answerPercent = getAnswerPercent(options, index);
				const optionState = getOptionsState({
					id: item.id,
					pollResult,
					selectedOption,
					selectedAnswerId,
					questionType,
				});
				return (
					<StyledOption key={item.option} optionState={optionState} answerPercent={answerPercent}>
						<StyledOptionInner
							onClick={() => {
								if (selectedOption === item.id) setSelectedOption(null);
								else setSelectedOption(item.id);
							}}
						>
							<div>{item.option}</div>
							{showResult && !isShortsPoll && <div>{answerPercent}</div>}
						</StyledOptionInner>

						<If
							condition={!!OptionFeedbackText[optionState]}
							then={<StyledOptionFeedback>{OptionFeedbackText[optionState]}</StyledOptionFeedback>}
						/>
					</StyledOption>
				);
			})}
			<Box d="flex" jc="flex-end">
				<Button
					disabled={isSubmitting || !selectedOption}
					onClick={() => {
						setIsSubmitting(true);
						onPollSubmit(selectedOption);
					}}
					style={{ margin: '1rem 0 0 0', display: !showResult ? 'block' : 'none' }}
				>
					Submit
				</Button>
			</Box>
			{/* <StyledEmojiContainer isVisible={isCorrectAnswer && badge?.image?.url}>
  <StyledEmoji src={badge?.image?.url} alt={badge?.image?.name} />
</StyledEmojiContainer> */}
		</StyledPoll>
	);
};

export default Poll;
