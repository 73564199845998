import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import Input from 'reusableComponents/HookForm/Input';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { isNotEmptyObject } from 'utils/commonHelpers';
import TextArea from 'reusableComponents/HookForm/TextArea';
import { getSchoolDetailById, updateSchoolDetails } from 'containers/School/actions';
import { fetchWrapper, uploadMedias } from 'utils/fetchWrapper';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { validateAspectRatio } from 'utils/imageHelpers';
import { setSchoolLoading } from './SchoolSlice';
import history from '../App/history';

interface ISchoolData {
	name: string;
	description: string;
	displayImage: File;
	address?: string;
}

const schoolDetailUpdateHandler = async ({ data, dispatch, schoolId }) => {
	const file = data.displayImage[0];
	let url: string;
	if (file) {
		const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

		dispatch(setSchoolLoading());

		if (!file?.id) {
			const uploadedMediaResponse = await uploadMedias({
				entityId,
				entityType: 'SCHOOL_DISPLAY_IMAGE',
				medias: [{ file }],
			});

			const uploadedMediaResponseData = uploadedMediaResponse?.[0] as any;
			url = uploadedMediaResponseData?.data?.rawUrl;
		} else url = file.url;
	}

	dispatch(
		updateSchoolDetails({
			displayPicture: { url },
			name: data.name,
			description: data.description,
			id: schoolId,
		})
	);
};

const SchoolEditForm = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm<ISchoolData>();
	const { handleSubmit, control, register, setValue } = hookFormMethods;

	const schoolState = useSelector(({ school }) => ({
		schools: school?.schools,
		loading: school.schoolLoading,
	}));

	const location = useLocation();
	const queryParam = new URLSearchParams(location.search);
	const schoolId = queryParam.get('schoolId');

	useEffect(() => {
		const school = schoolState?.schools?.find((school) => school.id === schoolId);
		if (isNotEmptyObject(school)) {
			const { name, description, displayPicture, address } = school as any;
			const displayImagePreview = getPreviewImages(
				{
					url: displayPicture?.url,
					id: school.id,
					mediaType: 'IMAGE',
				},
				false
			);
			setValue('name', name);
			setValue('description', description);
			setValue('displayImage', displayImagePreview);
			setValue('address', address);
		} else {
			dispatch(getSchoolDetailById({ schoolId }));
		}
	}, [schoolState?.schools]);

	const handleFormSubmit = (data) => {
		schoolDetailUpdateHandler({ data, dispatch, schoolId });
	};

	return (
		<LoadingOverlay isLoading={schoolState.loading} msg="Updating School details....">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(handleFormSubmit)}>
							<Box w="100%">
								<Row>
									<Col sm="12">
										<Input label="Name" name="name" autocomplete="off" isRequired ref={register({ required: true })} />
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<TextArea label="Description" name="description" autocomplete="off" ref={register({})} />
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Input label="Address" name="address" disabled ref={register()} />
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<DropzoneNew
											label="Diaplay Picture (1:1 Aspect Ratio)"
											name="displayImage"
											validate={async (val) => {
												if (val && val.length && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({ aspectRatio: 1, file: val[0] as Blob });
													return isValid ? true : 'Please upload photo with 1:1 Aspect Ratio';
												}
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="3">
										<Button color="primary">Submit</Button>
										<Button color="success" onClick={() => history.push('/school/list')}>
											Cancel
										</Button>
									</Col>
								</Row>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default SchoolEditForm;
