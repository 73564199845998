import React from 'react';
import { useFormContext } from 'react-hook-form';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';

import { ErrorMessage } from './ErrorMessage';

interface Props {
	name: string;
	label: string;
	className?: string;
	disabled?: boolean;
	required?: boolean;
	helpText?: string;
	checked?: boolean;
}

export const Checkbox = ({ name, label, className, disabled, required, helpText, checked }: Props) => {
	const { register } = useFormContext();
	return (
		<div className="form__form-group">
			<span className="form__form-group-label" />
			<div className="form__form-group-field">
				<label className={`checkbox-btn${disabled ? ' disabled' : ''} ${className ? ` checkbox-btn--${className}` : ''}`} htmlFor={name}>
					<input
						className="checkbox-btn__checkbox"
						type="checkbox"
						id={name}
						name={name}
						disabled={disabled}
						ref={register({ required })}
						{...(checked && { defaultChecked: checked })}
					/>
					<span className="checkbox-btn__checkbox-custom" style={{ backgroundColor: 'white' }}>
						<CheckIcon />
					</span>
					{className === 'button' ? (
						<span className="checkbox-btn__label-svg">
							<CheckIcon className="checkbox-btn__label-check" />
							<CloseIcon className="checkbox-btn__label-uncheck" />
						</span>
					) : (
						''
					)}
					<span className="checkbox-btn__label">{label}</span>
				</label>
			</div>

			{helpText && <span className="form__form-group-help-text">{helpText}</span>}

			<ErrorMessage
				{...{ label, name }}
				render={(message) => {
					return <span className="form__form-group-error">{message}</span>;
				}}
			/>
		</div>
	);
};
