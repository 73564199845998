import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast } from '../../../utils/commonHelpers';
import { dropboxFilesSuccess, toggleIsSubmitting } from '../whatsappSlice';
import { mutateSaga } from '../../../shared/saga';
import { setError } from '../../App/globalSlice';

export function* uploadDropboxFilesSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));

	const {
		data: { status, msg, data },
	} = yield call(mutateSaga, { payload, url: `${process.env.REACT_APP_API_URL}/v1/admin/dropbox` });
	if (status === 200) {
		showToast('success', `👍️ ${msg}`);
		yield put(actionSpreader(dropboxFilesSuccess.type, data));
	} else {
		yield put(actionSpreader(setError.type, { errors: `🚫️ ${msg}` }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
