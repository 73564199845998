import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';
import { isNotEmptyArray } from 'utils/commonHelpers';
import CustomModal from 'reusableComponents/CustomModal';
import Box from 'reusableComponents/Box';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { getPickedPostAnalytics } from '../actions/userPost.actions';

const StyledModal = styled.div`
	max-height: 40vh;
	overflow-y: scroll;
	width: 25rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const PickedPostModal = ({ closeModal }) => {
	const dispatch = useDispatch();

	const [pickedPosts, setPickedPosts] = useState([]);

	const userPostState = useSelector(({ userPost }) => ({
		postAnalytics: userPost.postAnalytics,
	}));

	useEffect(() => {
		dispatch(getPickedPostAnalytics());
	}, []);

	useEffect(() => {
		if (isNotEmptyArray(userPostState?.postAnalytics?.pickedPosts)) {
			setPickedPosts(sortBy(userPostState?.postAnalytics?.pickedPosts, ['count']).reverse());
		}
	}, [userPostState?.postAnalytics?.pickedPosts]);

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					<Box w="85%" ellipse fs="1.4rem">
						Picked Posts Count
					</Box>
					<Box onClick={closeModal} cursor="pointer">
						<span className="sidebar__link-icon lnr lnr-cross" />
					</Box>
				</Box>
				<Box d="flex" ai="center" jc="space-between" mb="1rem">
					<Box>Admin Name</Box>
					<Box>Post Count</Box>
				</Box>
				{isNotEmptyArray(pickedPosts) ? (
					pickedPosts.map((value) => (
						<Box d="flex" ai="center" jc="space-between" mb="0.5rem">
							<Box>{value.fullName}</Box>
							<Box>{value.count}</Box>
						</Box>
					))
				) : (
					<div className="">No data</div>
				)}
			</StyledModal>
		</CustomModal>
	);
};

export default PickedPostModal;
