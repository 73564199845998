import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper } from '../../../utils/commonHelpers';
import { toggleIsSubmitting, skillOnSubmitSuccess } from '../skillSlice';
import { setError } from '../../App/globalSlice';
import { mutateSaga } from '../../../shared/saga';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit', 'pos'], payload) };
	const skillRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: payload.isEdit,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/skill`,
	});
	if (skillRes.status === 200) {
		showToast('success', `👍️ Skill ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(actionSpreader(skillOnSubmitSuccess.type, { skill: skillRes.data, isEdit: payload.isEdit }));
		history.push('/skill/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: skillRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
