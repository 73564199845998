import { takeLatest } from 'redux-saga/effects';

import {
	getClass,
	getClasses,
	classOnSubmit,
	getClassId,
	deleteClass,
	getPolls,
	submitPolls,
	fetchBadges,
	getPoll,
	deleteAllPolls,
	fetchVideos,
	fetchVideo,
} from './classSlice';
import { getClassSaga } from './sagas/getClassSaga';
import { getClassesSaga } from './sagas/getClassesSaga';
import { getClassIdSaga } from './sagas/getClassIdSaga';
import { deleteClassSaga } from './sagas/deleteClassSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { getPollsSaga } from './sagas/getPollsSaga';
import { submitPollsSaga } from './sagas/submitPolls';
import { fetchBadgesSaga } from './sagas/fetchBadgesSaga';
import { getPollSaga } from './sagas/getPollSaga';
import { deleteAllPollsSaga } from './sagas/deleteAllPollsSaga';
import { fetchVideosSaga } from './sagas/fetchVideosSaga';
import { fetchVideoSaga } from './sagas/fetchVideoSaga';

export default function* classSaga() {
	yield takeLatest(getClass.type, getClassSaga);
	yield takeLatest(getClasses.type, getClassesSaga);
	yield takeLatest(getClassId.type, getClassIdSaga);
	yield takeLatest(deleteClass.type, deleteClassSaga);
	yield takeLatest(classOnSubmit.type, onSubmitSaga);
	yield takeLatest(getPolls.type, getPollsSaga);
	yield takeLatest(getPoll.type, getPollSaga);
	yield takeLatest(submitPolls.type, submitPollsSaga);
	yield takeLatest(deleteAllPolls.type, deleteAllPollsSaga);
	yield takeLatest(fetchBadges.type, fetchBadgesSaga);
	yield takeLatest(fetchVideos.type, fetchVideosSaga);
	yield takeLatest(fetchVideo.type, fetchVideoSaga);
}
