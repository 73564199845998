import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getPendingReport, getReports, updateReportAndUpdateList } from './actions/report.actions';

const reportSlice = createSlice({
	name: 'report',
	initialState: {
		loading: false,
		reports: [],
		pendingReport: {
			id: undefined,
		},
		reportedPost: {
			caption: undefined,
			blogContent: undefined,
			media: [],
		},
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			status: null,
			query: null,
			profileQuery: null,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.status !== undefined && { status: action.payload.status }),
				...(action.payload.query !== undefined && { query: action.payload.query }),
				...(action.payload.profileQuery !== undefined && { profileQuery: action.payload.profileQuery }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getReports.fulfilled as any]: (state, action) => {
			const {
				payload: { reports, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.reports = reports;
			state.total = total;
			state.pageNo = page + 1;
		},
		[getPendingReport.fulfilled as any]: (state, action) => {
			const {
				payload: { pendingReport, reportedPost },
			} = action;
			state.pendingReport = pendingReport;
			state.reportedPost = reportedPost;
		},
		[updateReportAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { reportIndex, reportData },
			} = action;
			state.reports[reportIndex] = {
				...reportData.report,
				postData: { media: state.reports[reportIndex]?.postData?.media, ...reportData.post },
			};
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting } = reportSlice.actions;

export default reportSlice.reducer;
