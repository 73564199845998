import React, { useEffect, useState } from 'react';
import formatDate from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import { createRefCode, updateRefCode } from '../actions/refCode.actions';

const statusOptions = [
	{ label: 'ACTIVE', value: 'ACTIVE' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
	{ label: 'DELETED', value: 'DELETED' },
];

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface ReferralCodeFormValues {
	refCode: string;
	status: { label: string; value: string };
	expiryAt: string;
	usageLimit: string;
}

export type ReferralCodeValuesFromDatabase = ReferralCodeFormValues & {
	id: string;
	refCode: string;
	expiryAt: string;
	status: string;
	usageLimit: number;
	createdBy: string;
	updatedBy: string;
};

interface ModifyReferralCodeBeforeCreateOrUpdateArgs extends ReferralCodeFormValues {
	id?: string;
	createdBy: string;
	updatedBy: string;
}

export const modifyReferralCodeBeforeCreateOrUpdate = (values: ModifyReferralCodeBeforeCreateOrUpdateArgs) => {
	const expiryAt = new Date(values.expiryAt);
	expiryAt.setDate(expiryAt.getDate());
	expiryAt.setHours(0, 0, 0, 0);

	return {
		...values,
		expiryAt: new Date(expiryAt),
		status: values.status.value,
		usageLimit: parseInt(values.usageLimit, 10),
		refCodeType: 'MARKETING',
	};
};

export const modifyReferralCodeBeforeInitialize = (values: ReferralCodeValuesFromDatabase): ReferralCodeFormValues => {
	return {
		...values,
		expiryAt: values.expiryAt ? formatDate(new Date(values.expiryAt), 'yyyy-MM-dd') : '',
		status: statusOptions.find((option) => option.value === values.status),
	};
};

interface ReferralCodeAddEditFormProps {
	location: { search: { refCode?: string } };
}

export const ReferralCodeAddEditForm = (props: ReferralCodeAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<ReferralCodeValuesFromDatabase>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.referralCode.isSubmitting,
	}));
	const hookFormMethods = useForm<ReferralCodeFormValues>();
	const { refCode } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!refCode;

	const { handleSubmit } = hookFormMethods;

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/ref-code?refCode=${refCode}`, { method: 'GET' }).then((res) => {
				setInitialValues(res.data as ReferralCodeValuesFromDatabase);
				hookFormMethods.reset(modifyReferralCodeBeforeInitialize(res.data as any));
			});
		}
	}, []);

	const onSubmit = (values: ReferralCodeFormValues) => {
		const modifiedValues = modifyReferralCodeBeforeCreateOrUpdate({
			...values,
			id: isEditForm ? initialValues.id : undefined,
			createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
			updatedBy: loggedInUser.id,
		});

		if (isEditForm) {
			dispatch(updateRefCode(modifiedValues));
		} else {
			dispatch(createRefCode(modifiedValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Referral Code...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Referral Code</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<Input
											label="Referral Code"
											name="refCode"
											disabled={isEditForm}
											required
											maxLength={10}
											pattern={{ value: /^[A-Z][A-Z0-9]+$/, message: 'Only uppercase letters & digits allowed' }}
											helpText="Maximum length of 10 characters"
										/>
									</Col>
									<Col sm="6">
										<SelectField label="Status" name="status" options={statusOptions} required />
									</Col>
									<Col sm="6">
										<Input
											type="date"
											name="expiryAt"
											label="Expiry Date"
											min={formatDate(new Date(), 'yyyy-MM-dd')}
											helpText="Click on calendar icon to open calendar"
										/>
									</Col>
									<Col sm="6">
										<Input
											type="number"
											name="usageLimit"
											label="Usage Limit"
											min={0}
											required
											helpText="Maximum number of times this code can be used"
										/>
									</Col>
									{/* <Col sm="6">
										<Input type="number" name="numDays" label="Number of Days" min={0} required />
									</Col> */}
								</Row>

								<Col sm="12" className="mt-4">
									<Button size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/ref-code/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
