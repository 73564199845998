import * as loadImage from 'blueimp-load-image';

const RESIZED_IMAGE_WIDTH = 1080;

export const resizeImage = (file) =>
	new Promise((resolve) => {
		const img = document.createElement('img');
		const reader = new FileReader();
		reader.onload = (e) => {
			img.src = e.target.result;
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);
				let { width } = img;
				let { height } = img;

				if (width > RESIZED_IMAGE_WIDTH) {
					height *= RESIZED_IMAGE_WIDTH / width;
					width = RESIZED_IMAGE_WIDTH;
				} else {
					return resolve(null);
				}

				canvas.width = width;
				canvas.height = height;

				const newCtx = canvas.getContext('2d');
				newCtx.drawImage(img, 0, 0, width, height);
				// console.log(newCtx);
				canvas.toBlob((blob) => {
					resolve(blob);
				}, 'image/jpeg');
			};
		};
		reader.readAsDataURL(file);
	});

export const blobToFile = (theBlob, fileName) => {
	theBlob.lastModifiedDate = new Date();
	theBlob.name = fileName;
	// console.log('blobToFile', theBlob, fileName);
	return theBlob;
};

export const promisifiedLoadImage = (file, options = {}) =>
	new Promise((resolve) => {
		loadImage(
			file,
			(img) => resolve(img),
			options // Options
		);
	});

export const getBlobURL = async (images) => {
	const blobPromises = await images.map(
		(img) =>
			new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(img);
				reader.onload = (e) => {
					resolve(e.target.result);
				};
			})
	);
	return Promise.all(blobPromises).then((values) => values);
};

export const getBlobURLForImageCrop = async (images) => {
	const blobPromises = images.map((img) => promisifiedLoadImage(img, { canvas: true, orientation: true, crossOrigin: 'anonymous' }));
	return Promise.all(blobPromises).then((values) => {
		return values.map((v) => ({
			cropAspectRatio: v.width > v.height ? 4 / 3 : 3 / 4,
			url: v.toDataURL(),
		}));
	});
};

// Returns width and height of after resizing the image.
// It maintains the aspect ratio
export const getResizedImageDimensions = ({ width, height, maxWidth, maxHeight }) => {
	let ratio = 0;
	let resizedWidth = null;
	let resizedHeight = null;

	if (height <= maxHeight && width <= maxWidth) {
		resizedWidth = width;
		resizedHeight = height;
	} else if (width > maxWidth && width >= height) {
		ratio = width / height;
		if (maxWidth / ratio > maxHeight) {
			ratio = height / width;
			resizedWidth = maxHeight / ratio;
			resizedHeight = maxHeight;
		} else {
			resizedHeight = maxWidth / ratio;
			resizedWidth = maxWidth;
		}
	} else if (height > maxHeight && height > width) {
		ratio = height / width;
		if (maxHeight / ratio > maxWidth) {
			ratio = width / height;
			resizedHeight = maxWidth / ratio;
			resizedWidth = maxWidth;
		} else {
			resizedWidth = maxHeight / ratio;
			resizedHeight = maxHeight;
		}
	} else {
		// eslint-disable-next-line no-lonely-if
		if (height > maxHeight && width < maxWidth) {
			ratio = width / height;
			resizedWidth = maxHeight * ratio;
			resizedHeight = maxHeight;
		} else {
			ratio = height / width;
			resizedWidth = width;
			resizedHeight = height * ratio;
		}
	}

	return {
		resizedWidth,
		resizedHeight,
	};
};

export const validateAspectRatio = ({ aspectRatio, file }) => {
	const ERROR_ALLOWED = 0.05;

	const reader = new FileReader();
	reader.readAsDataURL(file);

	let isValid = false;

	return new Promise((resolve) => {
		reader.onload = (e) => {
			const img = new Image();
			img.src = e.target.result;
			/* eslint-disable-next-line */
			img.onload = function () {
				const { width, height } = this;
				const imageAspectRatio = width / height;
				if (Math.abs(imageAspectRatio - aspectRatio) <= ERROR_ALLOWED) isValid = true;
				resolve(isValid);
			};
		};
	});
};
