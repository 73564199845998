import Breadcrumbs from 'components/Breadcrumbs';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { actionSpreader, isNotEmptyArray, stringifyQueryParams } from 'utils/commonHelpers';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { TCourses } from '../utils/types';
import { setApiParams, setPage } from '../ExercisesSlice';
import { getAllCourses } from '../actions/exercises.actions';
import history from '../../App/history';

const heads = [
	{
		accessor: 'name',
		Header: 'Name',
	},
	{
		accessor: 'description',
		Header: 'Description',
	},
	{
		accessor: 'need',
		Header: 'Need',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({ courseId }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => {
						history.push(`/exercise/courses/add-edit${stringifyQueryParams({ mode: 'edit', courseId })}`);
					}}
					disabled={false}
				>
					Edit Course
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({ courses }: { courses: Array<TCourses> }) => {
	return courses.map((course) => {
		return { ...course, actions: <Actions courseId={course.id} /> };
	});
};

const CoursesList = () => {
	const dispatch = useDispatch();
	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const coursesState = useSelector(({ exercises }) => ({
		courses: exercises.courses,
		coursesLoading: exercises.isLoading,
		isSubmitting: exercises.isSubmitting,
		apiParams: exercises.apiParams,
		total: exercises.total,
		page: exercises.page,
	}));

	const [coursesRow, setCoursesRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	useEffect(() => {
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Courses',
						url: '/exercise/courses/list',
					},
				],
			})
		);
		dispatch(getAllCourses());
	}, []);

	useEffect(() => {
		if (isNotEmptyArray(coursesState.courses)) {
			const structuredRows = createNewRows({
				courses: coursesState.courses,
			});
			setCoursesRow(structuredRows);
		}
	}, [coursesState.courses]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = coursesState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={8}>
						<h3>Course Listing</h3>
					</Col>
					<Col sm={3}>
						<Button
							onClick={() =>
								window.open(
									`/exercise/courses/add-edit${stringifyQueryParams({
										mode: 'add',
									})}`,
									'_blank'
								)
							}
						>
							{' '}
							Add Course{' '}
						</Button>
					</Col>
				</Row>
				{isNotEmptyArray(coursesRow) && (
					<>
						<DataPaginationTable heads={heads} rows={coursesRow} />
						<Pagination
							itemsCount={coursesState.total}
							itemsToShow={coursesState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{coursesState.total === 0 && <div className="">No Courses</div>}
			</CardBody>
		</Card>
	);
};

export default CoursesList;
