import { createSlice } from '@reduxjs/toolkit';

const blogSlice = createSlice({
	name: 'blog',
	initialState: {
		loading: false,
		blogs: [],
		blogTags: [],
		blog: {},
		blogId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
		},
	},
	reducers: {
		setLoading: (state) => {
			state.loading = true;
		},
		resetLoading: (state) => {
			state.loading = false;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getBlogs: () => {},
		getBlogsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.blogs = action.payload.blogs;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getBlog: () => {},
		getBlogSuccess: (state, action) => {
			state.blog = action.payload.blog;
		},
		getBlogTags: () => {},
		getBlogTagsSuccess: (state, action) => {
			state.blogTags = action.payload.blogTags;
		},
		createBlogTag: () => {},
		createBlogTagSuccess: (state, action) => {
			state.blogTags = [...state.blogTags, action.payload.blogTag];
		},
		getBlogId: () => {},
		getBlogIdSuccess: (state, action) => {
			state.blogId = action.payload.blogId;
		},
		onSubmit: () => {},
		setIsSubmitting: (state) => {
			state.isSubmitting = true;
		},
		resetIsSubmitting: (state) => {
			state.isSubmitting = false;
		},
	},
});

export const {
	setLoading,
	resetLoading,
	setApiParams,
	getBlogs,
	getBlogsSuccess,
	getBlog,
	getBlogSuccess,
	getBlogTags,
	getBlogTagsSuccess,
	createBlogTag,
	createBlogTagSuccess,
	getBlogId,
	getBlogIdSuccess,
	onSubmit,
	setIsSubmitting,
	resetIsSubmitting,
} = blogSlice.actions;

export default blogSlice.reducer;
