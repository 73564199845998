import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';

export const fetchSchools = createAsyncThunk<any, any>('/school/fetchSchools', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper('/v1/admin/school/get-schools', {
			method: 'POST',
			body: apiParams,
		});
		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}
		return data;
	} catch (error) {
		showToast('error', 'Something went wrong');
	}
});

export const getSchoolDetailById = createAsyncThunk<any, any>('/school/getSchoolDetailById', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/v1/admin/school/get-school-details?schoolId=${apiParams.schoolId}`, {
			method: 'GET',
		});
		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}
		return data;
	} catch (error) {
		showToast('error', 'Something went wrong');
	}
});

export const updateSchoolDetails = createAsyncThunk<any, any>('/school/updateSchoolDetail', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper('/v1/admin/school/update-school', {
			method: 'PUT',
			body: apiParams,
		});
		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}
		if (data.status) {
			showToast('success', data.msg);
			history.push('/school/list');
		} else {
			showToast('error', data.msg);
		}
		const { id, name, description, displayPicture } = apiParams;
		return {
			updatedSchool: {
				id,
				description,
				name,
				displayPicture,
			},
			response: data,
		};
	} catch (error) {
		showToast('error', 'Something went wrong');
	}
});
