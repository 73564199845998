import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, Card, Col, Row, Button } from 'reactstrap';
import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import Pagination from 'reusableComponents/Pagination/Pagination';

import { accessDeepObject, isNotEmptyArray, stringifyQueryParams } from 'utils/commonHelpers';
import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import Filter from 'reusableComponents/Filter';
import { TableCoverPicture } from 'components/TableCoverPicture';
import { setApiParams } from './QuizSlice';
import { deleteQuiz, fetchQuizes } from './actions/quiz.action';
import history from '../App/history';

const Actions = ({ quizId, quizTitle }) => {
	const [dropdownOpen, setDropdownOpen] = React.useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const dispatch = useDispatch();

	const onDeleteQuestion = () => {
		dispatch(deleteQuiz({ quizId }));
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/activity/add-edit${stringifyQueryParams({ quizId, quizTitle })}`)} disabled={false}>
					Edit Activity
				</DropdownItem>
				<DropdownItem onClick={onDeleteQuestion} disabled={false}>
					Delete Activity
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const heads: TableHeads<any> = [
	{ accessor: 'coverImageUrl', Header: 'Cover' },
	{ accessor: 'title', Header: 'Title' },
	{ accessor: 'priority', Header: 'Priority' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

const createRows = ({ quizes }) => {
	return quizes.map(({ title, id, quizType, priority, status, coverImageUrl }) => ({
		coverImageUrl: <TableCoverPicture {...{ coverPicture: { url: coverImageUrl, id: '', mediaType: 'QUIZ_COVER_IMAGE' }, title }} />,
		title,
		quizType,
		priority,
		status,
		actions: <Actions quizId={id} quizTitle={title} />,
	}));
};

const QuizList = () => {
	const [quizes, setQuizes] = React.useState([]);
	const [searchTerm, setSearchTerm] = React.useState('');

	const quizesState = useSelector(({ quizes }) => ({
		quizes: quizes.quizes,
		page: quizes.page,
		apiParams: quizes.apiParams,
		total: quizes.total,
	}));

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(fetchQuizes({ ...quizesState.apiParams }));
	}, []);

	React.useEffect(() => {
		if (isNotEmptyArray(quizesState.quizes)) {
			const quizes = createRows({ quizes: quizesState.quizes });
			setQuizes(quizes);
		}
	}, [quizesState.quizes]);

	const onSearch = (e: SyntheticEvent) => {
		dispatch(setApiParams({ searchKey: searchTerm }));
		e?.preventDefault?.();
		dispatch(fetchQuizes({ ...quizesState.apiParams, searchKey: searchTerm }));
	};

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = quizesState;
		if (itemsPage) {
			const skip = (itemsPage - 1) * apiParams.limit;
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip,
				})
			);
			dispatch(fetchQuizes({ ...apiParams, skip }));
		}
	};

	const onSort = (sortColumn: string, sortDirection: string) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE' && sortColumn !== 'coverImageUrl') {
			const sortOrder = sortDirection === 'ASC' ? 1 : -1;
			const limit = quizesState?.apiParams?.limit;
			const skip = quizesState?.apiParams?.skip * (quizesState.page - 1);

			dispatch(setApiParams({ ...quizesState.apiParams, sortKey: sortColumn, limit, skip, sortOrder }));
			dispatch(fetchQuizes({ ...quizesState.apiParams, sortKey: sortColumn, limit, skip, sortOrder }));
		}
	};

	return (
		<Card>
			<CardBody>
				<Row>
					<Col sm="3" className="mb-4">
						<h2>Activity Listing</h2>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search by quiz title" onSearch={onSearch} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
						<Button size="sm" color="primary" onClick={() => history.push('/activity/add-edit')}>
							Add
						</Button>
					</Col>
				</Row>
				<Box mt="2rem">
					<If
						condition={isNotEmptyArray(quizes)}
						then={
							<>
								<DataPaginationTable heads={heads} rows={quizes} onSort={onSort} />
								<Pagination
									itemsCount={quizesState.total}
									itemsToShow={accessDeepObject('apiParams.limit', quizesState)}
									pageOfItems={quizesState.page}
									onChangePage={onChangePage}
								/>
							</>
						}
						else={quizesState.total === 0 && <div>No data</div>}
					/>
				</Box>
			</CardBody>
		</Card>
	);
};

export default QuizList;
