/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getDiys, setApiParams, setDiyStatus } from '../../diySlice';
import { isNotEmptyArray, accessDeepObject } from '../../../../utils/commonHelpers';
import history from '../../../App/history';

import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (diys, pageNo, rowsCount, submitHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < diys.length; i += 1) {
		r.push({
			id: initialCount,
			title: diys[i].title,
			ageGroup: `${diys[i].ageGroup ? diys[i].ageGroup.min : '2'}-${diys[i].ageGroup ? diys[i].ageGroup.max : '12'}yrs`,
			status:
				diys[i].status === STATUS_ACTIVE ? (
					<div className="badge badge-success">Active</div>
				) : (
					<div className="badge badge-warning">Inactive</div>
				),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/diy/add-edit?id=${diys[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							if (diys[i].status === STATUS_ACTIVE) {
								submitHandler({ id: diys[i].id, status: STATUS_INACTIVE, pos: i });
							} else {
								submitHandler({ id: diys[i].id, status: STATUS_ACTIVE, pos: i });
							}
						}}
					>
						Mark {diys[i].status === STATUS_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const DiyTable = ({ history }) => {
	const diyState = useSelector(({ diy }) => ({
		loading: diy.loading,
		diys: diy.diys,
		pageNo: diy.pageNo,
		total: diy.total,
		apiParams: diy.apiParams,
	}));

	const dispatch = useDispatch();

	const [diyRows, setDiyRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	React.useEffect(() => {
		dispatch(getDiys());
	}, []);

	React.useEffect(() => {
		setPageOfItems(diyState.pageNo);
	}, [diyState.pageNo]);

	const heads = [
		{
			accessor: 'title',
			Header: 'Title',
		},
		{
			accessor: 'ageGroup',
			Header: 'Age Group',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			dispatch(setDiyStatus({ ...params, isEdit: true }));
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(diyState.diys)) {
			const structuredRows = createRows(diyState.diys, diyState.pageNo, accessDeepObject('apiParams.limit', diyState), submitHandler);
			setDiyRows(structuredRows);
		} else {
			setDiyRows([]);
		}
	}, [diyState.diys]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = diyState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getDiys());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', diyState),
					skip: accessDeepObject('apiParams.limit', diyState) * (pageOfItems - 1),
				})
			);
			dispatch(getDiys());
		}
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8}>
							<h3>DIYs Listing</h3>
						</Col>
						<Col sm={{ size: 3, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/diy/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(diyRows) && (
						<>
							<DataPaginationTable heads={heads} rows={diyRows} onSort={onSort} />
							<Pagination
								itemsCount={diyState.total}
								itemsToShow={accessDeepObject('apiParams.limit', diyState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{diyState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default DiyTable;
