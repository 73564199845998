import { put, call, all } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../successStorySlice';
import { setError } from '../../App/globalSlice';
import { mutateSaga, uploadImageSaga } from '../../../shared/saga';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	try {
		yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
		const allImgs = [...payload.screenshot[0].photo, ...payload.photos.map((file) => file.photo)];
		allImgs.forEach((img, index) => {
			if (index === 0) {
				payload.screenshot = {
					...(!payload.screenshot[0].photo[0].path && {
						id: payload.screenshot[0].photo[0].id,
						url: payload.screenshot[0].photo[0].preview,
					}),
					...(payload.screenshot[0].caption ? { caption: payload.screenshot[0].caption } : {}),
				};
			} else {
				payload.photos = allImgs.slice(1, allImgs.length).map((val, index) => ({
					...(!val.path && { id: val.id, url: val.preview }),
					...(payload.photos[index].caption ? { caption: payload.photos[index].caption } : {}),
				}));
			}
		});
		const imgsToBeUploaded = allImgs.filter((img) => !!img.path);

		// Upload allImgs
		let imgs = yield all(
			imgsToBeUploaded.map((img) =>
				call(uploadImageSaga, {
					payload: { images: [img], entityId: payload.id, entityType: 'SUCCESS_STORY' },
				})
			)
		);
		if (imgs instanceof Error) {
			showToast('error', 'OOPS!! Something went wrong, please try again!');
		} else if (allImgs[0].path) {
			const screenshot = imgs[0];
			payload.screenshot = { id: screenshot[0].id, url: screenshot[0].url, ...payload.screenshot };
			imgs = imgs.slice(1);
			payload.photos = payload.photos.map((img) => {
				if (img.url) return img;
				const uploadedImg = imgs.shift();
				return { id: uploadedImg[0].id, url: uploadedImg[0].url, ...img };
			});
		} else {
			payload.photos = payload.photos.map((img) => {
				if (img.url) return img;
				const uploadedImg = imgs.shift();
				return { id: uploadedImg[0].id, url: uploadedImg[0].url, ...img };
			});
		}

		const modifiedData = { ...omitWrapper(['isEdit'], payload) };
		const storyRes = yield call(mutateSaga, {
			payload: modifiedData,
			isEdit: payload.isEdit,
			url: `${process.env.REACT_APP_API_URL}/v1/admin/success-story`,
		});
		if (storyRes.status === 200) {
			showToast('success', `👍️ Success Story ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
			yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
			history.push('/success-stories/list');
		} else {
			yield put(actionSpreader(setError.type, { errors: storyRes.data }));
		}
	} catch (err) {
		yield put(actionSpreader(setError.type, { errors: err }));
	} finally {
		yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
	}
}
