import React from 'react';
import { compose } from 'redux';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Col, Row, Button, Card, CardBody } from 'reactstrap';

import { isNotEmptyObject, isNotEmptyArray, ArrayMaybe, ObjectMaybe } from 'utils/commonHelpers';
import history from 'containers/App/history';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { renderPolls } from './PollForm';

const modifyBeforeSetPolls = (params) => {
	const polls = params.polls.map((poll) => {
		const answer = poll.options.findIndex((option) => option.id === poll.answer);
		const triggerTime = { min: Math.floor(poll.triggerTime / 60), sec: poll.triggerTime % 60 };
		return {
			...poll,
			answer,
			triggerTime,
		};
	});
	return { polls };
};

const PollsAddEdit = (props) => {
	const selector = formValueSelector('add_edit_poll');
	const hasPolls = !!ArrayMaybe(props.initialValues.polls)[0]?.id;
	const { isShortsPoll } = props;

	return (
		<Box pos="relative">
			<form className="form ltr-support" onSubmit={props.handleSubmit}>
				<Box w="100%">
					<Row>
						<Col md="9" style={{ display: 'flex' }}>
							<h3 style={{ marginRight: '1.5rem' }}>{hasPolls ? 'Edit' : 'Create'} Polls</h3>
							{hasPolls && (
								<Button
									size="sm"
									color="primary"
									onClick={() => {
										window.open(props.testPollUrl);
									}}
									disabled={!hasPolls}
								>
									Test Polls
								</Button>
							)}
							{!isShortsPoll && hasPolls && (
								<Button size="sm" color="danger" onClick={props.onDeletePolls}>
									Remove All Polls
								</Button>
							)}
						</Col>
					</Row>
				</Box>
				<Box w="100%" mt="1rem">
					<Row>
						<Col sm="12">
							<FieldArray
								name="polls"
								component={renderPolls}
								fetchBadgesByQuery={props.fetchBadgesByQuery}
								badges={props.badges}
								videoUrl={props.videoUrl}
								changeHandler={props.change}
								formSelector={selector}
								isShortsPoll={isShortsPoll}
							/>
						</Col>
						<Col sm="12" className="mt-4">
							<Button size="sm" color="primary" type="submit">
								{hasPolls ? 'Save' : 'Create'}
							</Button>
							<Button size="sm" color="success" type="button" onClick={() => history.push(props.redirectUrl)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Box>
			</form>
		</Box>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_poll', enableReinitialize: true });

const PollReduxForm = compose(withReduxForm)(PollsAddEdit);

const PollsForm = (props) => {
	return (
		<LoadingOverlay isLoading={props.isSubmitting} msg="Submitting Polls...">
			<Card>
				<CardBody>
					{isNotEmptyObject(props.selectedEntity) ? (
						<PollReduxForm
							initialValues={
								isNotEmptyArray(props.polls) ? modifyBeforeSetPolls({ polls: props.polls }) : { polls: [{ options: [{}, {}] }] }
							}
							testPollUrl={props.testPollUrl}
							redirectUrl={props.redirectUrl}
							onSubmit={props.handleSubmit}
							onDeletePolls={props.onDeletePolls}
							badges={props.badges}
							videoUrl={ObjectMaybe(props.selectedEntity.videoDetails).videoUrl}
							fetchBadgesByQuery={props.fetchBadgesByQuery}
							isShortsPoll={props.isShortsPoll}
						/>
					) : (
						<div>Loading...</div>
					)}
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default PollsForm;
