import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Col, Row } from 'reactstrap';
import theme from 'utils/theme';
import { isNotEmptyObject, showToast } from 'utils/commonHelpers';
import CustomModal from 'reusableComponents/CustomModal';
import Box from 'reusableComponents/Box';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { PostFeedbackFormValues } from 'containers/UserPost/components/UserPostApproval';

const StyledModal = styled.div`
	height: auto;
	width: 30rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const starRatingOptions = [
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5', value: 5 },
];

const FeedbackModal = ({ initialValues, handleSubmit, closeModal }) => {
	const hookFormMethods = useForm<PostFeedbackFormValues>({ mode: 'onChange' });
	const { getValues, errors } = hookFormMethods;

	useEffect(() => {
		hookFormMethods.reset({
			starRating: initialValues.starRating
				? (starRatingOptions.find((rating) => rating.value === +initialValues.starRating).label as any)
				: null,
			feedbackText: initialValues.feedbackText,
		});
	}, []);

	const submitHandler = () => {
		const { feedbackText, starRating } = getValues(['feedbackText', 'starRating']);
		if (isNotEmptyObject(errors)) showToast('error', 'Please resolve form errors');
		else
			handleSubmit({
				...(feedbackText ? { feedbackText } : {}),
				...(starRating ? { starRating } : {}),
				postId: initialValues.postId,
				postIndex: initialValues.postIndex,
			});
	};

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					<Box w="85%" ellipse fs="1.4rem">
						{isNotEmptyObject(initialValues) ? 'Edit' : 'Add'} Feedback
					</Box>
					<Box onClick={closeModal} cursor="pointer">
						<span className="sidebar__link-icon lnr lnr-cross" />
					</Box>
				</Box>
				<Box style={{ fontWeight: 'bold', marginBottom: '5px' }}>Child Name</Box>
				<Box>{initialValues?.childName}</Box>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support">
						<Box w="100%" mt="1rem">
							<Row>
								<Col sm="12">
									<RadioGroup
										label="Star Rating (1 - Negative Feedback, 5 - Positive Feedback)"
										name="starRating"
										fields={starRatingOptions}
										direction="row"
									/>
								</Col>
								<Col sm="12">
									<Input
										label="Feedback Text"
										disabled={!(getValues('starRating') || initialValues.starRating)}
										helpText="Add child name to personalise the feedback text, Apply star rating to enter feedback"
										name="feedbackText"
									/>
								</Col>
							</Row>
						</Box>
						<Box tl="right" w="100%">
							<Button color="primary" type="button" size="sm" onClick={submitHandler}>
								Submit
							</Button>
							<Button color="secondary" type="button" size="sm" onClick={closeModal}>
								Cancel
							</Button>
							<Button size="sm" type="button" onClick={() => hookFormMethods.reset({ starRating: null, feedbackText: '' })}>
								Clear Feedback
							</Button>
						</Box>
					</form>
				</FormProvider>
			</StyledModal>
		</CustomModal>
	);
};

export default FeedbackModal;
