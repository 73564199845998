type RangeofAspectRatio = {
	minimumAspectRatio: number;
	maximumAspectRatio: number;
};

export const validateImage = (uploadedData: any, rangeofAspectRatio: RangeofAspectRatio) => {
	if (uploadedData && uploadedData?.length > 0) {
		const image = uploadedData[0];
		const reader = new FileReader();

		return new Promise((resolve, reject) => {
			reader.onerror = () => {
				reader.abort();
				reject(new DOMException('Problem parsing input file.'));
			};

			if ('Blob' in window && image instanceof Blob) reader.readAsDataURL(image);
			else resolve(true);

			reader.onload = () => {
				const img: any = new Image();
				img.src = reader.result;
				img.onload = () => {
					const uploadedImgAspectRatio = img.width / img.height;
					if (
						uploadedImgAspectRatio < rangeofAspectRatio.minimumAspectRatio ||
						uploadedImgAspectRatio > rangeofAspectRatio.maximumAspectRatio
					) {
						resolve(false);
					} else {
						resolve(true);
					}
				};
			};
		});
	}

	return new Promise((resolve, reject) => {
		resolve(true);
	});
};
