import { createAsyncThunk } from '@reduxjs/toolkit';
import history from 'containers/App/history';
import { setError } from 'containers/App/globalSlice';
import { omitWrapper, showToast } from 'utils/commonHelpers';
import { fetchWrapper, uploadMedias } from 'utils/fetchWrapper';

export const fetchMedia = createAsyncThunk<any, any>('media/fetchMedia', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper('/v1/admin/quiz/get-activity-medias', {
			method: 'POST',
			body: apiParams,
		});

		const { data } = response;

		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}
		return data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const createMedia = createAsyncThunk<any, any>('media/createMedia', async (params, { dispatch }) => {
	try {
		let entityId;
		if (params.type === 'IMAGE') {
			const { data } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });
			entityId = data;
			await uploadMedias({
				medias: params.type === 'IMAGE' ? [{ file: params.quizImage[0] }] : [{ file: params.quizAudio[0] }],
				entityId,
				entityType: 'QUIZ_MEDIA',
			});
		}

		const createActivityResponse = await fetchWrapper('/v1/admin/quiz/create-activity-media', {
			method: 'POST',
			body: omitWrapper(['quizAudio', 'quizImage', 'quizVideo'], {
				...params,
				...(params.type === 'IMAGE' ? { entityId } : {}),
				...(params.type === 'VIDEO'
					? {
							videoId: params.quizVideo?.id,
							url: params.quizVideo?.value,
							title: params.quizVideo?.label,
					  }
					: {}),
			}),
		});

		if (!createActivityResponse?.data?.status) {
			dispatch({ type: setError.type, payload: { errors: createActivityResponse.data } });
		} else {
			showToast('success', `👍️ Quiz Media created succcessfully!!`);
			history.push('/activity/media/list');
		}

		return createActivityResponse?.data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const updateMedia = createAsyncThunk<any, any>('media/updateMedia', async (params, { dispatch }) => {
	const isUploadingNewMedia =
		(params.quizImage && Array.isArray(params.quizImage) && typeof params.quizImage[0].url !== 'string') ||
		(params.quizAudio && Array.isArray(params.quizAudio) && typeof params.quizAudio[0].id !== 'string');

	const { quizImage, quizAudio, quizVideo, ...rest } = params;

	try {
		let entityId: string;
		const url = !isUploadingNewMedia ? params.url : undefined;
		if (params.type === 'IMAGE' && isUploadingNewMedia) {
			const newlyCreatedEntityIdResponse = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });
			entityId = newlyCreatedEntityIdResponse.data;
			await uploadMedias({
				medias: params.type === 'IMAGE' ? [{ file: params.quizImage[0] }] : [{ file: params.quizAudio[0] }],
				entityId,
				entityType: 'QUIZ_MEDIA',
			});
		}

		const response = await fetchWrapper('/v1/admin/quiz/update-activity-media', {
			method: 'PUT',
			body: {
				...rest,
				...(params.type === 'IMAGE' && !!entityId ? { entityId } : { url }),
				...(params.type === 'VIDEO'
					? {
							videoId: params.quizVideo?.id,
							url: params.quizVideo?.value,
							title: params.quizVideo?.label,
					  }
					: {}),
			},
		});

		const { data } = response;

		if (!data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			showToast('success', `👍️ Quiz Media updated succcessfully!!`);
			history.push('/activity/media/list');
		}

		return {
			...data,
			mediaId: params.id,
			status: params.status,
		};
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const getMedia = createAsyncThunk<any, any>('media/getMedia', async ({ mediaId }, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/v1/admin/quiz/get-activity-media-details?mediaId=${mediaId}`, {
			method: 'GET',
		});

		const { data } = response;

		if (!data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}
		return data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});
