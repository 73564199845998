import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { RadioGroupNew } from 'reusableComponents/HookForm/RadioGroup';
import If from 'reusableComponents/If';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import Input from 'reusableComponents/HookForm/Input';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyObject } from 'utils/commonHelpers';
import { fetchUserMembershipDetails, submitMembershipCancellationForm } from './membership.actions';
import history from '../App/history';

const REASON_FOR_CANCELLATION_OPTIONS = [
	{ label: 'The Plan is expensive', value: 'The Plan is expensive' },
	{ label: 'My friend got it for a lower cost', value: 'My friend got it for a lower cost' },
	{ label: 'I/My kids have no time to attend the classes', value: 'I/My kids have no time to attend the classes' },
	{ label: 'Could not get the courses I was looking for', value: 'Could not get the courses I was looking for' },
	{ label: 'Could not understand how to attend classes', value: 'Could not understand how to attend classes' },
	{ label: 'I had different expectations of the product', value: 'I had different expectations of the product' },
	{ label: 'Other', value: 'Other' },
];

const getPrefillData = ({ membershipDetails, orderId }) => {
	return membershipDetails.find((data) => data.id === orderId);
};

const Cancellationform = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm<any>();
	const membershipState = useSelector(({ membership }) => ({
		successData: membership?.successData,
		rejectedData: membership?.rejectedData,
		status: membership.status,
	}));
	const { register, control, handleSubmit, watch, setValue } = hookFormMethods;
	const [userCancellationReason, setUserCancellationReason] = useState('');
	const reasonForCancellation = watch('reasonForCancellation');
	const [membershipCancellationFormValues, setMembershipCancellationFormValues] = useState({} as any);
	const location = useLocation();
	const queryParam = new URLSearchParams(location.search);
	const orderId = queryParam.get('orderId');
	const isEditDisabled = membershipState.status !== 'PENDING';

	const handleFormSubmit = (data) => {
		dispatch(submitMembershipCancellationForm({ ...data, orderId }));
	};

	useEffect(() => {
		let data;
		data = getPrefillData({ membershipDetails: membershipState.successData, orderId });
		if (!data) {
			data = getPrefillData({ membershipDetails: membershipState.rejectedData, orderId });
		}
		if (isNotEmptyObject(data)) {
			const {
				didYouEnjoyed,
				reasonForCancellation,
				otherReasonForCancellation,
				willTakeAgain,
				suggestions,
				optFor,
			} = data.cancellationForm;
			const { userCancellationReason } = data;
			control.setValue('didYouEnjoyed', didYouEnjoyed);
			control.setValue('reasonForCancellation', reasonForCancellation);
			control.setValue('otherReasonForCancellation', otherReasonForCancellation);
			control.setValue('willTakeAgain', willTakeAgain);
			control.setValue('suggestions', suggestions);
			control.setValue('optFor', optFor);
			setMembershipCancellationFormValues(data);
			setUserCancellationReason(userCancellationReason);
		} else {
			dispatch(fetchUserMembershipDetails({ orderId }));
		}
	}, [membershipState.successData, membershipState.rejectedData]);

	return (
		<LoadingOverlay isLoading={false}>
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(handleFormSubmit)}>
							<Box w="100%">
								<Row>
									<Col sm={9} className="mb-3">
										<h3>Membership Cancellation Form</h3>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Label>
											<span style={{ fontWeight: 'bold', fontSize: '1rem' }}>User Cancellation Reason: </span> {userCancellationReason}
										</Label>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Label>
											1. Did you enjoy the Pro Plan? <span style={{ color: 'red' }}>&#42;</span>
										</Label>
										<RadioGroupNew
											name="didYouEnjoyed"
											label=""
											disabled={isEditDisabled}
											fields={[
												{ label: 'Yes', value: 'Yes' },
												{ label: 'No', value: 'No' },
											]}
											required
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Label>
											2. Select one of the following reasons for cancellation: <span style={{ color: 'red' }}>&#42;</span>
										</Label>
										<RadioGroupNew
											name="reasonForCancellation"
											label=""
											required
											disabled={isEditDisabled}
											fields={REASON_FOR_CANCELLATION_OPTIONS}
										/>
									</Col>
									<Col sm="6">
										<If
											condition={reasonForCancellation === 'Other'}
											then={
												<Input
													name="otherReasonForCancellation"
													label="Other Reason For Cancellation"
													required
													disabled={isEditDisabled}
													ref={register({ required: true })}
													value={membershipCancellationFormValues?.cancellationForm?.otherReasonForCancellation}
												/>
											}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Label>
											3. Would you take the membership again if the above reason is fixed? <span style={{ color: 'red' }}>&#42;</span>
										</Label>
										<RadioGroupNew
											name="willTakeAgain"
											label=""
											disabled={isEditDisabled}
											required
											fields={[
												{ label: 'Yes', value: 'Yes' },
												{ label: 'No', value: 'No' },
											]}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Input
											name="suggestions"
											disabled={isEditDisabled}
											label="4. Any suggestions to improve Yellow Class?"
											ref={register({ required: false })}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Label>
											5. Opt for <span style={{ color: 'red' }}>&#42;</span>
										</Label>
										<RadioGroupNew
											name="optFor"
											label=""
											disabled={isEditDisabled}
											required
											fields={[
												{ label: 'Cancel Membership', value: 'CANCEL' },
												{ label: 'Resume Membership', value: 'RESUME' },
											]}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="3">
										<Button disabled={isEditDisabled} color="primary">
											Submit
										</Button>
										<Button
											onClick={() => {
												history.push('/membership/list');
											}}
											color="success"
										>
											Cancel
										</Button>
									</Col>
								</Row>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default Cancellationform;
