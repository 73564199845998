import styled from 'styled-components';
import theme from 'utils/theme';

const getStrokeColor = (elapsedPercentage) => {
	if (elapsedPercentage > 67) return theme.clrs.new.cGreen;
	if (elapsedPercentage > 33) return theme.clrs.new.cYellow;
	return theme.clrs.new.cRed;
};

export const CountdownContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: auto;
`;

export const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: rotateY(-180deg) rotateZ(-90deg);
	overflow: visible;
`;
export const AnimatedSVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: rotateY(-180deg) rotateZ(-90deg);
	overflow: visible;

	circle {
		stroke: ${(props) => getStrokeColor(props.elapsedPercentage)};
		transition: stroke 3s linear;
	}
`;
