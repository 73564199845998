import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from 'utils/commonHelpers';
// eslint-disable-next-line import/no-cycle
import { uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { toggleIsSubmitting } from '../slice';

export const createS3ImageUpload = createAsyncThunk('devSettings/createS3ImageUpload', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const imagesKeys = [{ key: 'imageList', isMulti: true, withCaption: true }];

	try {
		await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data, hasCustomImageUploadLocation: true });
		showToast('success', `👍️ Images uploaded to S3 successfully!!`);
	} catch (error) {
		showToast('error', `Something went wrong. Images could not be uploaded`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
