import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { deleteRefCode, updateRefCodeAndUpdateList } from './actions/refCode.actions';

const referralCodeSlice = createSlice({
	name: 'referralCode',
	initialState: {
		loading: false,
		referralCodes: [],
		code: {},
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.status !== undefined && { status: action.payload.status }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getReferralCodes: () => {},
		getReferralCodesSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.referralCodes = action.payload.referralCodes;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getReferralCode: () => {},
		getReferralCodeSuccess: (state, action) => {
			state.code = action.payload.code;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[updateRefCodeAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { refCodeIndex, refCodeData },
			} = action;
			state.referralCodes[refCodeIndex] = refCodeData;
		},
		[deleteRefCode.fulfilled as any]: (state, action) => {
			const {
				payload: { refCodeId },
			} = action;
			state.referralCodes = state.referralCodes.filter((refCode) => refCode.id !== refCodeId);
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getReferralCodes,
	getReferralCodesSuccess,
	getReferralCode,
	getReferralCodeSuccess,
	toggleIsSubmitting,
} = referralCodeSlice.actions;

export default referralCodeSlice.reducer;
