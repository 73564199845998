/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect } from 'react';
import MinusIcon from 'mdi-react/MinusIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import { isUndefined } from 'lodash';

type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface CheckBoxFieldProps extends Omit<HTMLInputProps, 'onChange'> {
	label?: string;
	indeterminate?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => any;
}

export const Checkbox: React.FC<CheckBoxFieldProps> = (props) => {
	const {
		checked,
		className,
		color,
		defaultChecked = false,
		disabled = false,
		id = 'form__checkbox',
		indeterminate = false,
		label = '',
		name,
		onChange,
		...rest
	} = props;

	useEffect(() => {
		if (defaultChecked && isUndefined(checked)) {
			onChange(null, defaultChecked);
		}
	}, []);

	return (
		<label className={`checkbox-btn${disabled ? ' disabled' : ''} ${className ? ` checkbox-btn--${className}` : ''}`} htmlFor={name}>
			<input
				className="checkbox-btn__checkbox"
				checked={checked || indeterminate}
				disabled={disabled}
				id={id}
				name={name}
				onChange={(e) => onChange(e, e.target.checked)}
				type="checkbox"
				{...rest}
			/>
			<span className="checkbox-btn__checkbox-custom" style={color ? { background: color, borderColor: color } : {}}>
				{indeterminate && !checked ? <MinusIcon /> : <CheckIcon />}
			</span>
			{className === 'button' ? (
				<span className="checkbox-btn__label-svg">
					<CheckIcon className="checkbox-btn__label-check" />
					<CloseIcon className="checkbox-btn__label-uncheck" />
				</span>
			) : (
				''
			)}
			<span className="checkbox-btn__label">{label}</span>
		</label>
	);
};
