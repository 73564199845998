/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams, isAllowedEmail } from 'utils/commonHelpers';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import Filter from 'reusableComponents/Filter';
import { format } from 'date-fns';
import { SelectField } from 'reusableComponents/Form/Select';
import history from '../../App/history';
import { getReferralCodes, setApiParams } from '../referralCodeSlice';
import { deleteRefCode, updateRefCodeAndUpdateList } from '../actions/refCode.actions';

const statusList = [
	{
		label: 'Active',
		value: 'ACTIVE',
	},
	{
		label: 'Inactive',
		value: 'INACTIVE',
	},
	{
		label: 'Deleted',
		value: 'DELETED',
	},
];

const Actions = ({ id, status, index, refCode }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown disabled={status === 'DELETED'} isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/ref-code/add-edit${stringifyQueryParams({ refCode })}`)}>Edit Code</DropdownItem>
				<DropdownItem
					onClick={() =>
						dispatch(
							updateRefCodeAndUpdateList({
								refCodeId: id,
								refCodeIndex: index,
								status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
							})
						)
					}
				>
					{status === 'ACTIVE' ? 'Mark as Inactive' : 'Mark as Active'}
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						// eslint-disable-next-line no-alert
						const shouldDeleteRefCode = window.confirm('Are you sure you want to delete this referral code?');
						if (shouldDeleteRefCode) {
							dispatch(
								deleteRefCode({
									id,
								})
							);
						}
					}}
				>
					Delete Code
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ referralCodes }) {
	return referralCodes.map(
		({ id, refCode, refCodeType, usageLimit, usedCount, numDays, createdAt, createdBy, expiryAt, status }, index) => ({
			id,
			refCode,
			usageLimit,
			usedCount,
			numDays: numDays || '-',
			refCodeType,
			createdAt: createdAt ? format(new Date(createdAt), 'dd-MM-yyyy HH:mm') : '---',
			expiryAt: expiryAt ? format(new Date(expiryAt), 'dd-MM-yyyy HH:mm') : '---',
			createdBy,
			status,
			actions: <Actions {...{ id, status, index, refCode }} />,
		})
	);
}

const heads = [
	{ accessor: 'refCode', Header: 'Referral Code' },
	{ accessor: 'usageLimit', Header: 'Usage Limit' },
	{ accessor: 'usedCount', Header: 'Used Count' },
	{ accessor: 'numDays', Header: 'Number of Days' },
	{ accessor: 'refCodeType', Header: 'RefCode Type' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'expiryAt', Header: 'Expiry At' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const ReferralCodeTable = () => {
	const referralCodeState = useSelector(({ referralCode }) => ({
		loading: referralCode.loading,
		referralCodes: referralCode.referralCodes,
		code: referralCode.code,
		pageNo: referralCode.pageNo,
		total: referralCode.total,
		apiParams: referralCode.apiParams,
	}));

	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	const dispatch = useDispatch();

	const [refCodeRows, setRefCodeRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState(referralCodeState.apiParams.query);

	React.useEffect(() => {
		dispatch(getReferralCodes());
	}, []);

	React.useEffect(() => {
		setPageOfItems(referralCodeState.pageNo);
	}, [referralCodeState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(referralCodeState.referralCodes)) {
			const structuredRows = createRows({ referralCodes: referralCodeState.referralCodes });
			setRefCodeRows(structuredRows);
		} else {
			setRefCodeRows([]);
		}
	}, [referralCodeState.referralCodes]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = referralCodeState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getReferralCodes());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', referralCodeState),
					skip: accessDeepObject('apiParams.limit', referralCodeState) * (pageOfItems - 1),
				})
			);
			dispatch(getReferralCodes());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getReferralCodes());
	};

	const onStatusChanged = (val) => {
		dispatch(setApiParams({ query: searchTerm, status: val, limit: 20, skip: 0 }));
		dispatch(getReferralCodes());
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={9} className="mb-3">
							<h3>Referral Codes Listing</h3>
						</Col>
						<Col sm={4}>
							<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch} placeholder="Search by Code" />
						</Col>
						<Col sm={3}>
							<SelectField clearable placeholder="Status" name="status" options={statusList} onChange={(val) => onStatusChanged(val)} />
						</Col>
						{isAllowedEmail('REACT_APP_CREATE_REF_CODES_ADMINS', loggedInUser.email[0]) && (
							<Col style={{ textAlign: 'right' }} sm={{ size: 2, offset: 1 }}>
								<Button
									style={{ marginBottom: 0 }}
									size="sm"
									color="primary"
									onClick={() => {
										history.push('/ref-code/add-edit');
									}}
								>
									Add
								</Button>
							</Col>
						)}
					</Row>
					{isNotEmptyArray(refCodeRows) && (
						<>
							<DataPaginationTable heads={heads} rows={refCodeRows} onSort={onSort} />
							<Pagination
								itemsCount={referralCodeState.total}
								itemsToShow={accessDeepObject('apiParams.limit', referralCodeState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{referralCodeState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default ReferralCodeTable;
