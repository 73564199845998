import React, { useEffect, useState } from 'react';
import formatDate from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import { IProgram } from 'types/Program';
import If from 'reusableComponents/If';
import { createBatch, updateBatch } from '../actions/batch.actions';
import { BatchClassList } from './BatchClassList';

const batchStatusOptions = [
	{ label: 'ACTIVE', value: 'ACTIVE' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
	{ label: 'DELETED', value: 'DELETED' },
	{ label: 'COMPLETED', value: 'COMPLETED' },
];

const batchFrequencyOptions = [
	{ label: 'Weekly', value: 'WEEKLY' },
	{ label: 'Monthly', value: 'MONTHLY' },
];

const weeklyTimelineOptions = [
	{ label: 'Sunday', value: 1 },
	{ label: 'Monday', value: 2 },
	{ label: 'Tuesday', value: 3 },
	{ label: 'Wednesday', value: 4 },
	{ label: 'Thursday', value: 5 },
	{ label: 'Friday', value: 6 },
	{ label: 'Saturday', value: 7 },
];

const monthlyTimelineOptions = [...((Array(28).keys() as unknown) as [number])].map((day) => ({ label: day + 1, value: day + 1 }));

function getMaximumEnrollmentStartTime({ enrollmentEndTime }: { enrollmentEndTime: string }): string {
	if (!enrollmentEndTime) return undefined;
	return formatDate(new Date(enrollmentEndTime), "yyyy-MM-dd'T'HH:mm");
}

function getMinimumEnrollmentEndTime({ enrollmentStartTime }: { enrollmentStartTime: string }): string {
	if (!enrollmentStartTime) return formatDate(new Date(), "yyyy-MM-dd'T'HH:mm");
	return formatDate(new Date(enrollmentStartTime), "yyyy-MM-dd'T'HH:mm");
}

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface BatchFormValues {
	id: string;
	title: string;
	programId: { label: string; value: string };
	frequency: { label: string; value: string };
	weeklyTimeline?: { label: string; value: number }[];
	monthlyTimeline?: { label: number; value: number }[];
	startDate: string;
	enrollmentStartTime?: string;
	enrollmentEndTime?: string;
	time: { hours: string; minutes: string };
	status: { label: string; value: string };
}

export type BatchValuesFromDatabase = Omit<BatchFormValues, 'weeklyTimeline' | 'monthlyTimeline'> & {
	id: string;
	title: string;
	frequency: 'WEEKLY' | 'MONTHLY';
	weeklyTimeline?: number[];
	monthlyTimeline?: number[];
	startDate: string;
	enrollmentStartTime?: string;
	enrollmentEndTime?: string;
	time: { hours: number; minutes: number };
	program: IProgram;
	status: string;
	createdBy: string;
	updatedby: string;
};

interface ModifyBatchBeforeCreateOrUpdateArgs extends BatchFormValues {
	_id?: string;
	createdBy: string;
	updatedBy: string;
}

const getBatchStartDateWithTime = (startDate: string, time: { hours: string; minutes: string }) => {
	const batchStartDate = new Date(startDate);
	batchStartDate.setHours(+time.hours);
	batchStartDate.setMinutes(+time.minutes);
	return batchStartDate;
};

export const modifyBatchBeforeCreateOrUpdate = (values: ModifyBatchBeforeCreateOrUpdateArgs) => {
	return {
		...values,
		...(values.enrollmentStartTime && { enrollmentStartTime: new Date(values.enrollmentStartTime) }),
		enrollmentEndTime: values.enrollmentEndTime ? new Date(values.enrollmentEndTime) : null,
		status: values.status.value,
		weeklyTimeline: values.weeklyTimeline ? values.weeklyTimeline.map((item) => item.value).sort() : undefined,
		monthlyTimeline: values.monthlyTimeline ? values.monthlyTimeline.map((item) => item.value).sort() : undefined,
		startDate: getBatchStartDateWithTime(values.startDate, values.time),
		frequency: values.frequency.value,
		programId: values.programId.value,
	};
};

export const modifyBatchBeforeInitialize = (values: BatchValuesFromDatabase): BatchFormValues => {
	return {
		...values,
		...(values.enrollmentStartTime && { enrollmentStartTime: formatDate(new Date(values.enrollmentStartTime), "yyyy-MM-dd'T'HH:mm") }),
		...(values.enrollmentEndTime && { enrollmentEndTime: formatDate(new Date(values.enrollmentEndTime), "yyyy-MM-dd'T'HH:mm") }),
		startDate: formatDate(new Date(values.startDate), 'yyyy-MM-dd'),
		status: batchStatusOptions.find((option) => option.value === values.status),
		programId: { label: values.program.title, value: values.program.id },
		frequency: batchFrequencyOptions.find((option) => option.value === values.frequency),
		weeklyTimeline: values.weeklyTimeline.map((item) => weeklyTimelineOptions.find((elem) => elem.value === item)),
		monthlyTimeline: values.monthlyTimeline.map((item) => monthlyTimelineOptions.find((elem) => elem.value === item)),
	};
};

interface BatchAddEditFormProps {
	location: { search: { batchId?: string; programId?: string; programTitle?: string } };
}

export const BatchAddEditForm = (props: BatchAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<BatchValuesFromDatabase>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.batch.isSubmitting,
	}));
	const hookFormMethods = useForm<BatchFormValues>();
	const { batchId, programId, programTitle, continuity } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!batchId;

	const { watch, handleSubmit, setValue } = hookFormMethods;

	const formValues = watch(['frequency', 'startDate', 'enrollmentStartTime', 'enrollmentEndTime']);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/batch?batchId=${batchId}`, { method: 'GET' }).then((res) => {
				setInitialValues(res.data as BatchValuesFromDatabase);
				hookFormMethods.reset(modifyBatchBeforeInitialize(res.data as any));
			});
		} else {
			setInitialValues({ programId } as any);
			hookFormMethods.reset({ programId: { label: programTitle, value: programId } });
		}
	}, []);

	const onSubmit = (values: BatchFormValues) => {
		const modifiedValues = modifyBatchBeforeCreateOrUpdate({
			...values,
			_id: isEditForm ? initialValues.id : undefined,
			createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
			updatedBy: loggedInUser.id,
		});

		if (isEditForm) {
			dispatch(updateBatch(modifiedValues));
		} else {
			dispatch(createBatch(modifiedValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Batch...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Batch</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<Input label="Title" name="title" required disabled={isEditForm} />
									</Col>
									<Col sm="6">
										<SelectField
											label="Frequency"
											name="frequency"
											options={batchFrequencyOptions}
											isClearable
											required
											isDisabled={isEditForm}
										/>
									</Col>
									<Col sm="6">
										<SelectField label="Status" name="status" options={batchStatusOptions} required />
									</Col>
									<Col sm="6">
										<AsyncSelectField label="Program" name="programId" isDisabled required />
									</Col>
									<Col sm="6">
										<SelectField
											isMulti
											label="Weekly Timeline"
											name="weeklyTimeline"
											options={weeklyTimelineOptions}
											required={formValues.frequency?.value === 'WEEKLY'}
											isDisabled={isEditForm || formValues.frequency?.value === 'MONTHLY'}
										/>
									</Col>
									<Col sm="6">
										<SelectField
											isMulti
											label="Monthly Timeline"
											name="monthlyTimeline"
											options={monthlyTimelineOptions}
											required={formValues.frequency?.value === 'MONTHLY'}
											isDisabled={isEditForm || formValues.frequency?.value === 'WEEKLY'}
										/>
									</Col>
									<Col sm="6">
										<Input
											type="date"
											name="startDate"
											label="Start Date"
											required
											min={!isEditForm && formatDate(new Date(), 'yyyy-MM-dd')}
											disabled={isEditForm}
											helpText="Click on calendar icon to open calendar"
										/>
									</Col>
									<If
										condition={continuity === 'FIXED'}
										then={
											<Col sm="6">
												<Row>
													<Col sm="6">
														<Input
															type="datetime-local"
															name="enrollmentStartTime"
															label="Enrollment Start Time"
															helpText="Please select batch start date first"
															required={!!formValues.startDate}
															disabled={!formValues.startDate}
															max={getMaximumEnrollmentStartTime({
																enrollmentEndTime: formValues.enrollmentEndTime,
															})}
														/>
													</Col>
													<Col sm="6">
														<Input
															type="datetime-local"
															name="enrollmentEndTime"
															label="Enrollment End Time"
															helpText="Please select batch enrollment start time first"
															disabled={!formValues.enrollmentStartTime}
															min={getMinimumEnrollmentEndTime({ enrollmentStartTime: formValues.enrollmentStartTime })}
															showIcon="cross"
															iconHandler={() => setValue('enrollmentEndTime', '')}
														/>
													</Col>
												</Row>
											</Col>
										}
										else={
											<Col sm="6">
												<div />
											</Col>
										}
									/>
									<If
										condition={continuity === 'FIXED'}
										then={
											<Col sm="6">
												<Input
													type="number"
													defaultValue="-1"
													name="enrollmentLimit"
													label="Enrollment Limit"
													min={-1}
													required
													helpText="Put -1 for unlimited enrollments, any positive number for that many enrollments"
												/>
											</Col>
										}
									/>

									<Col sm="12">
										<p>Time</p>
									</Col>
									<Col sm="6">
										<Input
											type="number"
											label="Hours"
											name="time.hours"
											pattern={{ value: /\b([0-9]|1[0-9]|2[0-3])\b/, message: `Hours should be in range 0 - 23` }}
											min={0}
											required
											disabled={isEditForm}
											helpText="24 hours format. Example (17 means 5 PM)"
										/>
									</Col>
									<Col sm="6">
										<Input
											type="number"
											label="Minutes"
											name="time.minutes"
											required
											pattern={{ value: /\b([0-9]|[1-5][0-9])\b/, message: `Minutes be should be in range 0 - 59` }}
											min={0}
											disabled={isEditForm}
										/>
									</Col>
								</Row>

								<Col sm="12" className="mt-4">
									<Button size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/batch/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>

					<Box h="2rem">
						<If condition={isEditForm} then={<BatchClassList batchId={batchId} />} />
					</Box>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
