import React, { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { accessDeepObject, getSanitizedImageUrl } from 'utils/commonHelpers';

import Loader from 'components/Loader';
import CustomModal from 'reusableComponents/CustomModal';
import theme from 'utils/theme';
import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import {
	StyledContainer,
	StyledSubContainer,
	StyledInfoText,
	StyledQuestionContainer,
	StyledQuestion,
	StyledTimer,
	StyledModal,
	StyledBadge,
} from './styledComponents';
import Timer from '../Timer';
import Poll from '../Poll';

const getQuestionType = (questionType) => {
	switch (questionType) {
		case 'ASSESSMENT':
			return 'Assessment';
		case 'FUN':
			return 'Fun';
		case 'FEEDBACK':
			return 'Feeback';
		default:
			return 'Other';
	}
};

const GatheringResponseMessage = () => (
	<Box d="flex" direction="column" jc="center" ai="center" mh="30rem" p="2rem">
		<Box fs="1.6rem" fw="bold">
			Aggregating Responses from other participants...
		</Box>
		<Box m="2rem" tl="center">
			<Loader />
		</Box>
	</Box>
);

const Badge = ({ badge, isCorrectAnswer }) => (
	<If
		condition={Boolean(accessDeepObject('image.url', badge)) && isCorrectAnswer}
		then={
			<StyledBadge>
				<img src={getSanitizedImageUrl(accessDeepObject('image.url', badge))} alt="" />
			</StyledBadge>
		}
	/>
);

const PollModal = ({ closeModal, poll, isShortsPoll }) => {
	const [pollResult, setPollResult] = useState(null);
	const [pollOptions, setPollOptions] = useState(poll.options);
	const [timeOut, setTimeOut] = useState(false);
	const [pollDuration, setPollDuration] = useState(poll.duration);
	const [isPollTimer, setIsPollTimer] = useState(true);
	const [gatheringResponses, setGatheringResponses] = useState(false);
	const [selectedAnswerId, setSelectedAnswerId] = useState(null);
	const timerRef = React.createRef(null);

	const { question, badge, duration, waitDuration = 0, showResultDuration, questionType, answer } = poll;

	const onWaitDurationComplete = () => {
		setGatheringResponses(false);
		/** GENERATE POLL RESULT HERE */
		setPollOptions(pollOptions.map((option) => ({ ...option, answerCount: isShortsPoll ? 0 : Math.floor(Math.random() * 100) })));
	};

	useEffect(() => {
		setTimeout(onWaitDurationComplete, (duration + waitDuration) * 1000);
		return () => localStorage.removeItem('testPollAnswer');
	}, []);

	useEffect(() => {
		if (!isUndefined(pollOptions[0].answerCount)) {
			setPollResult({ correctAnswerId: answer, options: pollOptions });
			setTimeout(closeModal, showResultDuration * 1000);
		}
	}, [pollOptions]);

	const onPollSubmit = (answerId) => {
		setSelectedAnswerId(answerId);
		if (answerId) {
			/** SUBMIT POLL ANSWER HERE */
			localStorage.setItem('testPollAnswer', answerId);
		}
		setTimeOut(false);
		setPollDuration(showResultDuration);
		setIsPollTimer(false);
		if (!isShortsPoll) setGatheringResponses(true);
		else onWaitDurationComplete();
	};

	const isCorrectAnswer =
		accessDeepObject('correctAnswerId', pollResult) && accessDeepObject('correctAnswerId', pollResult) === selectedAnswerId;

	return (
		<CustomModal closeModal={closeModal} escapeOverlay={false}>
			<StyledModal>
				<If
					condition={gatheringResponses}
					then={<GatheringResponseMessage />}
					else={
						<StyledContainer>
							<StyledSubContainer>
								<Box d="flex" jc="space-between">
									<StyledInfoText>{getQuestionType(questionType)} Poll</StyledInfoText>
									<If condition={isCorrectAnswer} then={<StyledInfoText>Reward</StyledInfoText>} />
								</Box>
								<StyledQuestionContainer>
									<StyledQuestion>{question}</StyledQuestion>
									<Box d={selectedAnswerId ? 'none' : 'block'}>
										<StyledTimer>
											<If
												condition={!pollResult}
												then={
													<Timer
														seconds={pollDuration}
														size={60}
														strokeBgColor={theme.clrs.cWhite}
														strokeWidth={5}
														onTimeout={setTimeOut}
														closeModal={closeModal}
														ref={timerRef}
														isPollTimer={isPollTimer}
													/>
												}
												else={<Badge badge={badge} isCorrectAnswer={isCorrectAnswer} />}
											/>
										</StyledTimer>
									</Box>
								</StyledQuestionContainer>
							</StyledSubContainer>
							<Poll
								options={(pollResult && pollResult.options) || pollOptions}
								showResult={!!pollResult}
								answerId={pollResult && pollResult.correctAnswerId}
								onPollSubmit={onPollSubmit}
								questionType={questionType}
								// badge={badge}
								timeOut={timeOut}
								selectedAnswerId={selectedAnswerId}
								pollResult={pollResult}
								isShortsPoll={isShortsPoll}
							/>
						</StyledContainer>
					}
				/>
			</StyledModal>
		</CustomModal>
	);
};

export default PollModal;
