import React from 'react';

import { accessDeepObject, getSanitizedImageUrl, isNotEmptyObject } from '../../utils/commonHelpers';
import { dateTimeConverter } from '../../utils/dateHelper';
import theme from '../../utils/theme';

import Box from '../../reusableComponents/Box';

import ProfilePic from '../../shared/img/default-profile/user.svg';

const ChatUser = ({ contact, active, lastMessageTime, lastMessageText }) => (
	<div className={`chat__contact${active ? ' chat__contact--active' : ''}`}>
		{isNotEmptyObject(contact) && (
			<>
				<div className="chat__contact-avatar">
					<img src={getSanitizedImageUrl(accessDeepObject('coverPicture.url', contact)) || ProfilePic} alt={contact.mobile} />
				</div>
				<div className="chat__contact-preview">
					<p className="chat__contact-name">{`${contact.mobile} ${contact.author === 'ADMIN' ? ', Admin created user' : ''}`}</p>
					<Box d="flex" jc="space-between" mt="0.3rem">
						<Box ellipse fb="50%">
							{lastMessageText}
						</Box>
						<Box c={theme.clrs.cSmoke} fb="50%" ellipse>
							{dateTimeConverter(lastMessageTime, 'format', 'd MMM p')}
						</Box>
					</Box>
				</div>
			</>
		)}
	</div>
);

export default ChatUser;
