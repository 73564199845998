import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pickWrapper, isNotEmptyObject, accessDeepObject } from '../../../utils/commonHelpers';
import { forgotPassword } from '../authSlice';

import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPassword = () => {
	const authState = useSelector(({ auth }) => ({ isLoading: auth.loading, errors: auth.errors }));
	const formState = useSelector(({ form }) => ({ forgot: form.forgot_password_form }));

	const dispatch = useDispatch();

	const handleForgotPassword = useCallback(() => {
		const values = accessDeepObject('forgot.values', formState);

		if (isNotEmptyObject(values)) {
			dispatch(forgotPassword(values));
		}
	}, [dispatch, formState]);

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<h3 className="account__title">
							Welcome to
							<span className="account__logo">
								{' '}
								Ivy
								<span className="account__logo-accent">Pods</span>
							</span>
						</h3>
						<h4 className="account__subhead subhead">Admin Panel</h4>
					</div>
					<ForgotPasswordForm onSubmit={handleForgotPassword} {...pickWrapper(['errors', 'isLoading'], authState)} />
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
