import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Col, Row, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';

import { isNull, isUndefined } from 'lodash';
import { renderTextField, renderCheckBoxButton } from '../../../../reusableComponents/Form/formComponents';
import { required, selectFieldRequired } from '../../../../reusableComponents/Form/fieldValidators';
import { pickWrapper, parseQueryParams, isNotEmptyObject, ArrayMaybe, omitWrapper } from '../../../../utils/commonHelpers';

import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import Box from '../../../../reusableComponents/Box';
import renderSelectField from '../../../../reusableComponents/Form/Select';
import { getWhatsappGroup, getWhatsappGroupId, getWhatsappGroupSuccess, whatsappGroupOnSubmit } from '../../whatsappSlice';
import history from '../../../App/history';

// List of acceptable countries
const countryOptions = [
	{ label: 'India', value: 'in' },
	{ label: 'United Arab Emirates', value: 'ae' },
];

const groupStatuses = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
];

const groupTypes = [
	{ label: 'Kids', value: 'KIDS' },
	{ label: 'Referral', value: 'REFERRAL' },
	{ label: 'Partner', value: 'PARTNER' },
	{ label: 'KG', value: 'GRADE0' },
	{ label: 'Grade 1', value: 'GRADE1' },
	{ label: 'Grade 2', value: 'GRADE2' },
	{ label: 'Grade 3', value: 'GRADE3' },
	{ label: 'Grade 4', value: 'GRADE4' },
	{ label: 'Grade 5', value: 'GRADE5' },
	{ label: 'Ambassador', value: 'AMBASSADOR' },
	{ label: 'Paid Customer', value: 'PAID_CUSTOMER' },
];

const WhatsappAddEdit = (props) => {
	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{!isUndefined(props.initialValues.id) ? 'Edit' : 'Create'} Whatsapp Group</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="12">
						<Field
							label="Name"
							isDisabled={!isUndefined(props.initialValues.id)}
							isRequired
							validate={required}
							type="text"
							component={renderTextField}
							name="name"
						/>
					</Col>
					<Col sm="12">
						<Field
							label="Link"
							isDisabled={!isUndefined(props.initialValues.id)}
							isRequired
							validate={required}
							type="text"
							component={renderTextField}
							name="link"
						/>
					</Col>

					<Col sm="6">
						<Field label="Member Count" isRequired validate={required} type="number" component={renderTextField} name="count" />
					</Col>
					<Col sm="6">
						<Field
							label="Maximum Member Count"
							onChange={() => props.dispatch(change('add_edit_whatsapp', 'count', 0))}
							isRequired
							validate={required}
							type="number"
							component={renderTextField}
							name="maxCount"
						/>
					</Col>

					<Col sm="12" md="6">
						<Field
							label="Country"
							isDisabled={!isUndefined(props.initialValues.id)}
							isRequired
							validate={selectFieldRequired}
							name="country"
							options={ArrayMaybe(props.countryOptions).map((country) => ({
								label: country.label,
								value: country.value,
							}))}
							component={renderSelectField}
						/>
					</Col>
					<Col sm="3" className="mt-4">
						<Field label="Type" isRequired validate={selectFieldRequired} name="type" options={groupTypes} component={renderSelectField} />
					</Col>
					<Col sm="3" className="mt-4">
						<Field
							label="Status"
							isRequired
							validate={selectFieldRequired}
							name="status"
							options={groupStatuses}
							component={renderSelectField}
						/>
					</Col>
					<ButtonToolbar className="form__button-toolbar">
						<Button type="button" onClick={() => history.push('/whatsapp/list')}>
							Cancel
						</Button>
						<Button color="primary" type="submit">
							Submit
						</Button>
					</ButtonToolbar>
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_whatsapp', enableReinitialize: true });

const WhatsappReduxForm = compose(withReduxForm)(WhatsappAddEdit);

const WhatsappForm = (props) => {
	const whatsappState = useSelector(({ whatsapp }) => ({
		loading: whatsapp.loading,
		group: whatsapp.group,
		groupId: whatsapp.groupId,
		isSubmitting: whatsapp.isSubmitting,
	}));

	const [count, setCount] = useState(null);
	const [maxCount, setMaxCount] = useState(null);
	const [country, setCountry] = useState(null);

	const prevMaxCountRef = useRef();
	useEffect(() => {
		prevMaxCountRef.current = maxCount;
	});
	const prevMaxCount = prevMaxCountRef.current;

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.search) {
			dispatch(getWhatsappGroup(pickWrapper(['id'], parseQueryParams(props.location.search))));
		} else {
			dispatch(getWhatsappGroupId());
		}
		return () => {
			dispatch(getWhatsappGroupSuccess({}));
		};
	}, [props.location.search]);

	useEffect(() => {
		if (props.location.search) {
			if (isNotEmptyObject(whatsappState.group)) {
				setCountry(countryOptions.find((c) => c.value === whatsappState.group.country));
				setCount(whatsappState.group.count);
				setMaxCount(whatsappState.group.maxCount);
			}
		} else {
			setCount(0);
			setMaxCount(400);
		}
	}, [whatsappState.group]);

	useEffect(() => {
		if (!isNull(prevMaxCount) && !isUndefined(prevMaxCount)) setCount(0);
	}, [maxCount]);

	const handleSubmit = async (params) => {
		const processedValues = {
			...omitWrapper(['country', 'updatedAt', 'createdAt'], params),
			country: isNotEmptyObject(whatsappState.group) ? params.country.value : params.country,
			isEdit: isNotEmptyObject(whatsappState.group),
			count: +params.count,
			maxCount: +params.maxCount,
		};
		dispatch(whatsappGroupOnSubmit(processedValues));
	};
	return (
		<LoadingOverlay isLoading={whatsappState.isSubmitting} msg="Submitting Whatsapp Group...">
			<Card>
				<CardBody>
					<WhatsappReduxForm
						initialValues={
							props.location.search ? { ...whatsappState.group, country, count, maxCount } : { isActive: true, count, maxCount }
						}
						onSubmit={(args) => handleSubmit(args)}
						countryOptions={countryOptions}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default WhatsappForm;
