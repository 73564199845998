import React from 'react';
import { Switch, Route } from 'react-router-dom';
import QuestionList from './Question/QuestionList';
import AddEditQuizMedia from './Media/addEditQuizMedia';
import AddEditQuiz from './AddEditQuiz';
import QuestionAddEditForm from './Question/QuestionAddEditForm';
import ActivityInstanceAddEditForm from './QuizInstance/ActivityInstanceAddEditForm';
import ActivityInstnaceList from './QuizInstance/ActivityInstanceList';
import QuizMediaList from './Media/QuizMediaList';
import QuizList from './QuizList';

const Quiz = () => {
	return (
		<div>
			<Switch>
				<Route path="/activity/add-edit/" component={AddEditQuiz} />
				<Route path="/activity/list/" component={QuizList} />

				<Route path="/activity/activity-instance/add-edit" component={ActivityInstanceAddEditForm} />
				<Route path="/activity/activity-instance/list" component={ActivityInstnaceList} />

				<Route path="/activity/question/list" component={QuestionList} />
				<Route path="/activity/question/add-edit" component={QuestionAddEditForm} />

				<Route path="/activity/media/add-edit" component={AddEditQuizMedia} />
				<Route path="/activity/media/list" component={QuizMediaList} />
			</Switch>
		</div>
	);
};

export default Quiz;
