import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';

import CloseThickIcon from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { toast } from 'react-toastify';

const RankModifier = ({ onSubmit, uniqueId, rank, additionalParams }) => {
	const [isEditable, setIsEditable] = useState(false);
	const [rankNumber, setRankNumber] = useState(rank);
	const [invalidInput, setInvalidInput] = useState(true);
	const handleSubmit = async (e) => {
		e.preventDefault();

		const rank = e.target[0].value;
		if (parseFloat(rank) > 0 && rank !== rankNumber) {
			setInvalidInput(true);
			setIsEditable(false);
			await onSubmit(uniqueId, rank, additionalParams);
		}
	};
	const handleChange = (e) => {
		if (parseFloat(e.target.value) > 0 && e.target.value !== rankNumber && parseFloat(e.target.value) < 10000) {
			setInvalidInput(false);
		} else {
			setInvalidInput(true);
		}
	};
	useEffect(() => {
		setRankNumber(rank);
	}, [rank]);
	return (
		<div style={{ minWidth: '60px', maxWidth: '250px' }}>
			{!isEditable ? (
				<div style={{ display: 'flex' }}>
					<h4 style={{ marginRight: '10px', padding: '5px 0' }}>{rankNumber ?? '---'}</h4>
					<Button className="btn" onClick={() => setIsEditable(true)} type="button" size="sm" outline>
						<PencilIcon />
					</Button>
				</div>
			) : (
				<Form onSubmit={handleSubmit} style={{ display: 'flex' }}>
					<Input
						placeholder="Enter the Rank Number"
						name="Rank Number"
						type="number"
						size="sm"
						invalid={invalidInput}
						className="sm"
						style={{ width: '100px', marginRight: '20px' }}
						defaultValue={rankNumber}
						step="0.001"
						onChange={handleChange}
					/>
					<Button type="submit" size="sm" disabled={invalidInput} style={{ marginBottom: 0 }}>
						{' '}
						<CheckIcon />
					</Button>
					<Button type="button" size="sm" onClick={() => setIsEditable(false)} style={{ marginBottom: 0 }}>
						<CloseThickIcon />
					</Button>
				</Form>
			)}
		</div>
	);
};

export default RankModifier;
