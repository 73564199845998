import React from 'react';
import { Col, Card, CardBody, Button, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';
import { renderCheckBoxButton } from '../../../../reusableComponents/Form/formComponents';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import { isNotEmptyArray, getSanitizedImageUrl, showToast } from '../../../../utils/commonHelpers';
import ImageCard from '../ImageCard';
import Box from '../../../../reusableComponents/Box';
import { getClassImages, galleryOnSubmit } from '../../gallerySlice';

const customStylesMargin = {
	marginBottom: '0px',
};

const GALLERY_IMAGE_COUNT = 8;

const GalleryEdit = ({ handleSubmit, images }) => {
	return (
		<form className="form ltr-support" onSubmit={handleSubmit}>
			<Row className="mt-1 mb-4 rounded w-100">
				<Col sm={9}>
					<h3>Class Images</h3>
				</Col>
				<Col sm={{ size: 2, offset: 1 }}>
					<Button style={customStylesMargin} size="sm" color="primary" type="submit">
						Save
					</Button>
				</Col>
			</Row>
			<Box w="100%" d="flex" wrap="wrap" ai="center">
				{isNotEmptyArray(images) &&
					images.map((image) => (
						<Box d="flex" m="0 0.5rem">
							<Col style={{ padding: 0 }} className="mt-3">
								<Field name={image.id} component={renderCheckBoxButton} />
							</Col>
							<ImageCard src={getSanitizedImageUrl(image.url, { w: 200, crop: 'faces,top,left' })} alt="Class Gallery" />
						</Box>
					))}
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'edit_gallery', enableReinitialize: true });
const GalleryReduxForm = compose(withReduxForm)(GalleryEdit);

const GalleryForm = ({ isSubmitting }) => {
	const galleryState = useSelector(({ gallery }) => ({
		loading: gallery.loading,
		images: gallery.images,
		isSubmitting: gallery.isSubmitting,
	}));

	const dispatch = useDispatch();

	const [images, setImages] = React.useState(null);
	const [initialValues, setInitialValues] = React.useState({});

	React.useEffect(() => {
		dispatch(getClassImages());
	}, []);

	React.useEffect(() => {
		if (isNotEmptyArray(galleryState.images)) {
			galleryState.images.forEach((image) => {
				initialValues[image.id] = !isUndefined(image.galleryStatus);
			});
			setInitialValues({ ...initialValues });
			setImages(galleryState.images);
		} else {
			setImages([]);
		}
	}, [galleryState.images]);

	const handleSubmit = (values) => {
		const updatedPhotos = [];

		Object.entries(values).forEach((item) => {
			if (item[1]) {
				updatedPhotos.push(item[0]);
			}
		});

		if (updatedPhotos.length > GALLERY_IMAGE_COUNT) {
			showToast('error', '✋ Only 8 images can be picked!');
		} else if (updatedPhotos.length < GALLERY_IMAGE_COUNT) {
			showToast('error', '✋ Pick atleast 8 images!');
		} else {
			dispatch(galleryOnSubmit({ updatedPhotos }));
		}
	};

	if (galleryState.loading) {
		return <div>Loading...</div>;
	}

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Gallery...">
			<Card>
				<CardBody>
					<GalleryReduxForm
						initialValues={isNotEmptyArray(images) ? initialValues : null}
						images={images}
						onSubmit={(args) => handleSubmit(args)}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default GalleryForm;
