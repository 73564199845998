import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getEnrollments, uploadCertificate } from './actions/enrollments.actions';

const enrollmentsSlice = createSlice({
	name: 'enrollments',
	initialState: {
		loading: false,
		enrollments: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: null,
			batchId: null,
			numClassAttended: 0,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip && { skip: action.payload.skip }),
				...(action.payload.query && { query: action.payload.query }),
				...(action.payload.batchId && { batchId: action.payload.batchId }),
				...(action.payload.numClassAttended && { numClassAttended: action.payload.numClassAttended }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getEnrollments.fulfilled as any]: (state, action) => {
			const { enrollments, total } = action.payload;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.enrollments = enrollments;
			state.total = total;
			state.pageNo = page + 1;
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting } = enrollmentsSlice.actions;

export default enrollmentsSlice.reducer;
