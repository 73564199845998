import { takeLatest } from 'redux-saga/effects';

import { getCategories, categoryOnSubmit, setCategoryStatus } from './categorySlice';
import { getCategoriesSaga } from './sagas/getCategoriesSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { setCategoryStatusSaga } from './sagas/setCategoryStatusSaga';

export default function* categorySaga() {
	yield takeLatest(getCategories.type, getCategoriesSaga);
	yield takeLatest(categoryOnSubmit.type, onSubmitSaga);
	yield takeLatest(setCategoryStatus.type, setCategoryStatusSaga);
}
