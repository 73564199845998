import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import subDays from 'date-fns/subDays';
import PropTypes from 'prop-types';
import { ObjectMaybe } from '../../utils/commonHelpers';

class DatePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
	};

	constructor() {
		super();
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		const { onChange } = this.props;
		onChange(date);
	}

	render() {
		return (
			<div className="date-picker">
				<DatePicker
					className="form__form-group-datepicker"
					showTimeSelect={ObjectMaybe(this.props.timeParams).showTime}
					showTimeSelectOnly={ObjectMaybe(this.props.timeParams).showTimeOnly}
					selected={this.props.value}
					onChange={this.handleChange}
					dateFormat={
						ObjectMaybe(this.props.timeParams).showTime ? ObjectMaybe(this.props.timeParams).format || 'MMMM d, yyyy h:mm aa' : 'yyyy/MM/dd'
					}
					dropDownMode="select"
					timeFormat="HH:mm"
					minDate={this.props.fromPresent && subDays(new Date(), 0)}
					placeholderText={this.props.placeholder}
					timeIntervals={ObjectMaybe(this.props.timeParams).interval}
					timeCaption="Time"
					maxDate={this.props.disableFutureDate ? new Date() : undefined}
				/>
			</div>
		);
	}
}

const renderDatePickerField = (props) => {
	const { input, timeParams, fromPresent, disableFutureDate } = props;
	return <DatePickerField disableFutureDate={disableFutureDate} {...input} timeParams={timeParams} fromPresent={fromPresent} />;
};

renderDatePickerField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string,
	}).isRequired,
};

export default renderDatePickerField;
