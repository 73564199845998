import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { InputNew } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams } from 'utils/commonHelpers';
import { capitalize } from 'lodash';
import { addModule, fetchModule, updateModule } from '../actions/exercises.actions';

const ModuleAddEditForm = (props) => {
	const { moduleEnum, mode, curriculumId } = parseQueryParams(props.location.search) ?? {};
	const hookFormMethods = useForm();
	const dispatch = useDispatch();
	const { modules, isLoading } = useSelector(({ exercises: { modules, isLoading } }) => ({ modules, isLoading }));
	const user = useSelector(({ auth }) => auth.user);
	const module = modules?.find((module) => module.moduleEnum === moduleEnum);
	const onSubmit = async (data) => {
		const payload = { curriculumId, ...(moduleEnum ? { moduleEnum } : {}), ...data, updatedBy: user.id };
		if (mode === 'edit') {
			dispatch(updateModule(payload));
		} else {
			dispatch(addModule(payload));
		}
	};

	useEffect(() => {
		if (mode === 'edit') {
			dispatch(fetchModule({ moduleEnum, curriculumId }));
		}
	}, [moduleEnum, curriculumId]);
	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />

			<CardBody>
				<h3>{mode ? capitalize(mode) : ''} Module</h3>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<InputNew label="Title" name="title" defaultValue={module?.displayName ?? ''} required />
						<InputNew label="Description" name="description" defaultValue={module?.description ?? ''} />
						<Button type="submit">{mode === 'edit' ? 'Update' : 'Add'}</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default ModuleAddEditForm;
