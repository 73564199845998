import { call } from 'redux-saga/effects';
import { requestSagaImage, requestSaga, requestImage } from '../../utils/requestSaga';

export function* uploadImageSaga({ payload: { images, entityId, entityType } }) {
	const imageUploadData = yield call(
		requestSagaImage,
		'POST',
		`${process.env.REACT_APP_API_URL}/v1/admin/upload-image?entityId=${entityId}&entityType=${entityType}`,
		images
	);

	return imageUploadData;
}

export function* mutateSaga({ payload, isEdit, url }) {
	const res = yield call(requestSaga, isEdit ? 'PUT' : 'POST', url, {
		body: JSON.stringify(payload),
	});
	return res;
}

export function* uploadVideoSaga({ payload }) {
	const formData = new FormData();
	Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
	const res = yield call(requestImage, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/upload-video`, { body: formData });
	return res;
}
