import { call, put } from 'redux-saga/effects';

import { toggleLoading, deleteAllPollsSuccess } from '../classSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader, showToast } from '../../../utils/commonHelpers';
import { requestSaga } from '../../../utils/requestSaga';

export function* deleteAllPollsSaga({ payload }) {
	const res = yield call(requestSaga, 'DELETE', `${process.env.REACT_APP_API_URL}/v1/admin/poll?classId=${payload.classId}`);
	if (res.status === 200) {
		yield put(actionSpreader(deleteAllPollsSuccess.type));
		showToast('success', `👍️ Polls Successfully Deleted!`);
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
