/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isUndefined } from 'lodash';
import { accessDeepObject, isNotEmptyArray } from '../../../../utils/commonHelpers';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import history from '../../../App/history';
import { getStories, setApiParams, setStoryFeatured, setStoryStatus, storyOnSubmit } from '../../successStorySlice';
import { STATUS_SUCCESS_STORY_ACTIVE, STATUS_SUCCESS_STORY_INACTIVE } from '../../../../utils/constants';
import Filter from '../../../../reusableComponents/Filter';
import { SelectField } from '../../../../reusableComponents/Form/Select';
import { getPrograms, setApiParams as setProgramApiParams } from '../../../Program/programSlice';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (stories, pageNo, rowsCount, submitHandler, submitFeaturedStoryHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < stories.length; i += 1) {
		r.push({
			id: initialCount,
			fullName: stories[i].fullName,
			childName: stories[i].childName,
			relationship: stories[i].relationship,
			program: stories[i].program ? stories[i].program.title : '---',
			status:
				stories[i].status === STATUS_SUCCESS_STORY_INACTIVE ? (
					<div className="badge badge-warning">Inactive</div>
				) : (
					<div className="badge badge-success">Active</div>
				),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/success-stories/add-edit?id=${stories[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color={stories[i].status === STATUS_SUCCESS_STORY_INACTIVE ? 'danger' : 'primary'}
						size="md"
						style={customStyles}
						onClick={() => {
							if (stories[i].status === STATUS_SUCCESS_STORY_ACTIVE) {
								submitHandler({ id: stories[i].id, status: STATUS_SUCCESS_STORY_INACTIVE, pos: i });
							} else {
								submitHandler({ id: stories[i].id, status: STATUS_SUCCESS_STORY_ACTIVE, pos: i });
							}
						}}
					>
						Mark {stories[i].status === STATUS_SUCCESS_STORY_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
					<Button
						color={stories[i].isFeatured ? 'primary' : 'danger'}
						size="md"
						style={customStyles}
						onClick={() => {
							if (stories[i].isFeatured) {
								submitFeaturedStoryHandler({ id: stories[i].id, isFeatured: false, pos: i });
							} else {
								submitFeaturedStoryHandler({ id: stories[i].id, isFeatured: true, pos: i });
							}
						}}
					>
						{stories[i].isFeatured ? 'Remove as Featured' : 'Mark as Featured'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const SuccessStoryTable = () => {
	const successStoryState = useSelector(({ successStory }) => ({
		loading: successStory.loading,
		stories: successStory.stories,
		pageNo: successStory.pageNo,
		total: successStory.total,
		apiParams: successStory.apiParams,
	}));
	const programState = useSelector(({ program }) => ({ programs: program.programs }));

	const dispatch = useDispatch();

	const [storyRows, setStoryRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [searchTerm, setSearchTerm] = useState(successStoryState.apiParams.query);
	const [programId, setProgramId] = useState(null);

	useEffect(() => {
		dispatch(getStories());
	}, []);

	useEffect(() => {
		setPageOfItems(successStoryState.pageNo);
	}, [successStoryState.pageNo]);

	const heads = [
		{
			accessor: 'fullName',
			Header: 'Parent Name',
		},
		{
			accessor: 'childName',
			Header: 'Name of Child',
		},
		{
			accessor: 'relationship',
			Header: 'Relationship',
		},
		{
			accessor: 'program',
			Header: 'Program',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		const dd = window.confirm('Are you sure?');
		if (dd) {
			if (!isUndefined(params.pos)) {
				dispatch(setStoryStatus({ ...params }));
			} else {
				dispatch(storyOnSubmit(params));
			}
		}
	};

	const submitFeaturedStoryHandler = (params) => {
		console.log(params);
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			dispatch(setStoryFeatured({ ...params, isEdit: true }));
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(successStoryState.stories)) {
			const structuredRows = createRows(
				successStoryState.stories,
				successStoryState.pageNo,
				accessDeepObject('apiParams.limit', successStoryState),
				submitHandler,
				submitFeaturedStoryHandler
			);
			setStoryRows(structuredRows);
		} else {
			setStoryRows([]);
		}
	}, [successStoryState.stories]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = successStoryState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getStories());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', successStoryState),
					skip: accessDeepObject('apiParams.limit', successStoryState) * (pageOfItems - 1),
				})
			);
			dispatch(getStories());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getStories({ ...(programId && { programId }) }));
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8} md={12} className="mb-3">
							<h3>Success Stories Listing</h3>
						</Col>
						<Col sm={4}>
							<Filter
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								onSearch={onSearch}
								placeholder="Search by Name or Child Name"
							/>
						</Col>
						<Col sm={4}>
							<SelectField
								clearable
								placeholder="Choose Program"
								options={programState.programs.map((dd) => ({ label: dd.title, value: dd.id }))}
								onChange={setProgramId}
								inputChangeHandler={(val) => {
									dispatch(setProgramApiParams({ limit: null, query: val }));
									dispatch(getPrograms());
								}}
							/>
						</Col>
						<Col style={{ textAlign: 'left' }} sm={{ size: 3, offset: 1 }}>
							<Button style={customStylesMargin} size="sm" color="primary" onClick={onSearch}>
								Filter
							</Button>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push(`/success-stories/add-edit`);
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(storyRows) && (
						<>
							<DataPaginationTable heads={heads} rows={storyRows} onSort={onSort} />
							<Pagination
								itemsCount={successStoryState.total}
								itemsToShow={accessDeepObject('apiParams.limit', successStoryState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{successStoryState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default SuccessStoryTable;
