import { stringifyQueryParams } from 'utils/commonHelpers';

import queryString from 'query-string';
import { History, Location } from 'history';

export const assigneesList = JSON.parse(process.env.REACT_APP_ADMIN_MENTORS);

export function updateFilters({
	history,
	threadStatus,
	assignedTo,
	conversationId,
	updatedAt,
}: {
	history: History;
	threadStatus?: 'OPEN' | 'CLOSED' | 'DEAD';
	assignedTo?: string;
	conversationId?: string;
	updatedAt?: number;
}) {
	history.push(
		`/whatsapp-chats${stringifyQueryParams({
			threadStatus: threadStatus || undefined,
			assignedTo,
			updatedAt: updatedAt || 1,
			conversationId,
		})}`
	);
}

export function getQueryParams(
	location: Location,
	history: History
): {
	conversationId?: string;
	threadStatus?: 'OPEN' | 'CLOSED' | 'DEAD';
	updatedAt?: number;
	assignedTo?: string;
} {
	const { conversationId, threadStatus, updatedAt, assignedTo } = queryString.parse(location.search);

	if (!conversationId && !threadStatus && Number.isNaN(+updatedAt)) {
		updateFilters({ history, threadStatus: 'OPEN', updatedAt: +updatedAt });
		return {};
	}

	return {
		conversationId,
		threadStatus,
		updatedAt: +updatedAt,
		assignedTo,
	} as any;
}
