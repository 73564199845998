import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../reportSlice';

export const getReports = createAsyncThunk(
	'report/getReports',
	async ({ sortKey, sortOrder, limit, skip, status, query, profileQuery }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip, status, query, profileQuery });
		const res = await fetchWrapper(`/v1/admin/reports${qp}`, { method: 'GET' });

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				return {
					reports: res.data.reports,
					total: res.data.total,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const getPendingReport = createAsyncThunk('report/getPendingReport', async ({ adminUserId }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const res = await fetchWrapper(`/v1/admin/report/pending?adminUserId=${adminUserId}`, { method: 'GET' });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return { pendingReport: res.data?.report, reportedPost: res.data?.post };
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const updateReport = createAsyncThunk('report/updateReport', async ({ onSuccess, ...valuesToUpdate }: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/report', {
			method: 'PUT',
			body: valuesToUpdate,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			// Execute callback function on successful update
			onSuccess();
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateReportAndUpdateList = createAsyncThunk(
	'report/updateReportAndUpdateList',
	async ({ reportId, reportIndex, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: reportId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/report', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Report updated succcessfully!!`);
				return {
					reportIndex,
					reportData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
