import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getCoaches } from './actions/coach.actions';

const date = new Date(new Date().setHours(0, 0, 0, 0));

const coachSlice = createSlice({
	name: 'coach',
	initialState: {
		loading: false,
		coaches: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 10,
			skip: 0,
			status: 12,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.status && { batchId: action.payload.status }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getCoaches.fulfilled as any]: (state, action) => {
			const {
				payload: { coachList, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.coaches = coachList;
			state.total = total;
			state.pageNo = page + 1;
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting } = coachSlice.actions;

export default coachSlice.reducer;
