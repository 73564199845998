import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import ToggleButtonField from 'shared/components/form/ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import { getSanitizedImageUrl, isNotEmptyArray, isNotEmptyObject, showToast } from 'utils/commonHelpers';
import VideoJsPlayer from 'reusableComponents/VideoPlayer/VideoJsPlayer';
import { FormProvider, useForm } from 'react-hook-form';
import { TextAreaNew as TextArea } from 'reusableComponents/HookForm/TextArea';
import { getAdminMentors } from 'containers/Users/actions/user.actions';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';
import { PostMedia } from 'components/PostMedia';
import If from 'reusableComponents/If';
import Radio from 'reusableComponents/HookForm/Radio';
import { getSanitizedVideoUrl } from 'containers/Class/helpers';
import { getPendingPost, updateUserPost } from '../actions/userPost.actions';

export interface PostFeedbackFormValues {
	feedbackText: string;
	starRating: number;
}

enum PostStatusEnum {
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
	Assigned = 'ASSIGNED',
}

const starRatingOptions = [
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5', value: 5 },
];

const PostApprovalMediaView = ({ media }) => {
	const copyToClipboard = async (url) => {
		navigator.clipboard.writeText(url).then(
			function copySuccess() {
				showToast('success', 'URL copied to clipboard');
			},
			function copyFailed() {
				showToast('error', 'Failed to copy URL');
			}
		);
	};
	return (
		<Box d="flex" ai="center" mb="2rem">
			<img style={{ width: 'calc(20% - 20px)', marginRight: '2rem' }} src={getSanitizedImageUrl(media?.url, { w: 20 })} alt="User Post" />
			<Box>
				<If
					condition={media.genericLabels}
					then={
						<Box mb="2rem">
							<Table bordered>
								<thead>
									<tr>
										<th>Label</th>
										<th>Score</th>
									</tr>
								</thead>
								<tbody>
									{media.genericLabels?.Labels?.slice(0, 5)?.map((label) => (
										<tr>
											<td>{label.Name}</td>
											<td>{label?.Confidence?.toFixed(2)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Box>
					}
				/>
				<Button size="sm" type="button" onClick={() => copyToClipboard(getSanitizedImageUrl(media.url))}>
					Copy URL
				</Button>
			</Box>
		</Box>
	);
};

export const UserPostApproval = () => {
	const dispatch = useDispatch();

	const [keepFetching, setKeepFetching] = useState(true);
	const [isPostResolved, setIsPostResolved] = useState(null);
	const [isPendingPostFinished, setIsPendingPostFinished] = useState(false);
	const [videoThumbnailInfo, setVideoThumbnailInfo] = useState<Record<string, string>>({}); // [{ video.entityId: selectedThumbnailUrl }]
	const ref = useRef(null);

	const userPostState = useSelector(({ userPost }) => ({
		loading: userPost.loading,
		pendingPost: userPost.pendingPost,
	}));
	const loggedInUser = useSelector(({ auth }) => auth.user);
	const mentors = useSelector(({ user }) => user.mentors);

	const hookFormMethods = useForm<PostFeedbackFormValues>({ mode: 'onChange' });
	const { getValues, errors } = hookFormMethods;

	useEffect(() => {
		// @ts-expect-error
		dispatch(getAdminMentors());
	}, []);

	useEffect(() => {
		if (isNotEmptyArray(mentors)) getNextPost();
	}, [mentors]);

	useEffect(() => {
		if (!userPostState.pendingPost) setIsPendingPostFinished(true);
		else if (isNotEmptyObject(userPostState.pendingPost)) {
			setIsPostResolved(false);
			ref.current = { ...ref?.current, id: userPostState.pendingPost.id };
		}

		hookFormMethods.reset({ feedbackText: '' });
	}, [userPostState.pendingPost]);

	useEffect(() => {
		ref.current = { ...ref?.current, keepFetching, isPostResolved, errors, adminUserId: loggedInUser.id };
	}, [keepFetching, isPostResolved, errors, loggedInUser]);

	const getNextPost = () => {
		dispatch(
			getPendingPost({
				adminUserId: loggedInUser.id,
				isAdminAMentor: mentors.map((am) => am.id).includes(loggedInUser.id),
			})
		);
	};

	const onPostAction = ({
		status,
		assigneeName,
		assigneeId,
		feedbackText,
		starRating,
	}: {
		status: PostStatusEnum;
		assigneeName?: string;
		assigneeId?: string;
		feedbackText?: string;
		starRating?: number;
	}): void => {
		const { id, keepFetching, isPostResolved, errors, adminUserId } = ref.current;
		const selectedThumbnails = Object.keys(videoThumbnailInfo).map((entityId) => ({
			entityId,
			thumbnailUrl: videoThumbnailInfo[entityId],
		}));
		if (isNotEmptyObject(errors) && status === PostStatusEnum.Approved) showToast('error', 'Please resolve form errors');
		else if (isPostResolved === false) {
			if (status === PostStatusEnum.Assigned)
				dispatch(
					updateUserPost({
						status: 'PICKED',
						statusUpdatedAt: new Date().toISOString(),
						assignedBy: loggedInUser.id,
						pickedBy: assigneeId,
						id,
						onSuccess: keepFetching
							? () => {
									showToast('success', `Successfully assigned to ${assigneeName}!`);
									setIsPostResolved(true);
									getNextPost();
							  }
							: () => {
									showToast('success', `Successfully assigned to ${assigneeName}!`);
									setIsPostResolved(true);
							  },
					})
				);
			else
				dispatch(
					updateUserPost({
						status,
						statusUpdatedAt: new Date().toISOString(),
						...(status === PostStatusEnum.Approved && feedbackText ? { feedbackText } : {}),
						...(status === PostStatusEnum.Approved && starRating ? { starRating } : {}),
						selectedThumbnails,
						id,
						adminUserId,
						onSuccess: keepFetching
							? () => {
									showToast(
										status === PostStatusEnum.Approved ? 'success' : 'error',
										`Successfully ${status === PostStatusEnum.Approved ? 'Approved' : 'Rejected'} the user post!`
									);
									setIsPostResolved(true);
									getNextPost();
							  }
							: () => {
									showToast(
										status === PostStatusEnum.Approved ? 'success' : 'error',
										`Successfully ${status === PostStatusEnum.Approved ? 'Approved' : 'Rejected'} the user post!`
									);
									setIsPostResolved(true);
							  },
					})
				);
		}
	};

	return (
		<LoadingOverlay isLoading={userPostState.loading} msg="Fetching Post...">
			<Card>
				<CardBody>
					<Box w="100%">
						<Box d="flex" ai="center" jc="space-between">
							<h3>User Post Approval</h3>

							<Box>
								<Box d="flex" ai="center">
									<Box d="flex" ai="center" mr="0.4rem">
										Keep Fetching
									</Box>
									<Box d="flex" ai="center">
										<ToggleButtonField
											name="keepFetching"
											value={keepFetching}
											onChange={(val) => {
												setKeepFetching(val);
											}}
											defaultChecked
										/>
									</Box>
								</Box>
								{userPostState.pendingPost?.pendingPostOfAdminCount && (
									<Box mt="1rem">
										<p>Your Pending Post Count: {userPostState.pendingPost.pendingPostOfAdminCount}</p>
									</Box>
								)}
							</Box>
						</Box>
					</Box>

					{isPendingPostFinished ? (
						<Box mt="2rem" tl="center">
							All Pending Posts have been resolved for now. Please check later.
						</Box>
					) : (
						<Box w="100%" mt="2rem">
							{isPostResolved ? (
								'Please pick next post using the button below, or enable Keep Fetching to fetch more posts.'
							) : (
								<>
									<Box mh="200px">
										{isNotEmptyObject(userPostState.pendingPost) && (
											<>
												<Box w="100%" d="flex" ai="flex-start" jc="start">
													<Box w="50%" mr="3rem">
														<h4 style={{ marginTop: '2rem', marginBottom: '1rem', fontWeight: 'bold' }}>Recently Approved Post of User</h4>
														{userPostState.pendingPost.recentApprovedPostsByUser.map((post) => (
															<Box mb="1rem">
																<p>
																	<span style={{ fontWeight: 'bold' }}>Caption</span>: {post.caption}
																</p>
																{post.blogContent && (
																	<div>
																		<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Blog Content : </p>
																		<ReactMarkdown>{post.blogContent}</ReactMarkdown>
																	</div>
																)}
																<p style={{ fontWeight: 'bold' }}>Media:</p>
																{post.media.length ? <PostMedia media={post.media} /> : <p>---</p>}
															</Box>
														))}
													</Box>
													<Box w="50%" mr="2rem">
														<h4 style={{ marginTop: '2rem', marginBottom: '1rem', fontWeight: 'bold' }}>Recently Rejected Post of User</h4>
														{userPostState.pendingPost.recentRejectedPostsByUser.map((post) => (
															<Box mb="1rem">
																<p>
																	<span style={{ fontWeight: 'bold' }}>Caption</span>: {post.caption}
																</p>
																{post.blogContent && (
																	<div>
																		<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Blog Content : </p>
																		<ReactMarkdown>{post.blogContent}</ReactMarkdown>
																	</div>
																)}
																<p style={{ fontWeight: 'bold' }}>Media:</p>
																{post.media.length ? <PostMedia media={post.media} /> : <p>---</p>}
															</Box>
														))}
													</Box>
												</Box>
												<h4 style={{ marginTop: '2rem', marginBottom: '1rem', fontWeight: 'bold' }}>Pending Post</h4>
												<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Child Name(s)</p>
												<p>{userPostState.pendingPost?.profileNames.join(', ')}</p>
												<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Post Caption</p>
												<p>{userPostState.pendingPost.caption}</p>
												{userPostState.pendingPost.blogContent && (
													<div>
														<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Blog Content : </p>
														<ReactMarkdown>{userPostState.pendingPost.blogContent}</ReactMarkdown>
													</div>
												)}
												<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Medias</p>
												{userPostState.pendingPost.media.length > 0 ? (
													<Box d="flex" wrap="wrap" direction="column">
														{userPostState.pendingPost.media.map((media) =>
															media.mediaType === 'IMAGE' ? (
																<PostApprovalMediaView media={media} />
															) : (
																<>
																	<VideoJsPlayer videoUrl={media?.url} />
																	{media.generatedThumbnails ? (
																		<Box d="flex" className="ltr-support">
																			<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Select Thumbnail</p>
																			{media.generatedThumbnails.map((thumbnailUrl, index) => (
																				<Radio
																					name={`generated-thumbnail-${index}`}
																					checked={!!videoThumbnailInfo[media.entityId]}
																					onClick={() => setVideoThumbnailInfo((data) => ({ ...data, [media.entityId]: thumbnailUrl }))}
																					label={
																						<img
																							style={{ width: 'calc(20% - 20px)', marginRight: '2rem' }}
																							src={getSanitizedVideoUrl(thumbnailUrl)}
																							alt="User Post Video Thumbnail"
																						/>
																					}
																				/>
																			))}
																		</Box>
																	) : null}
																</>
															)
														)}
													</Box>
												) : (
													<p>---</p>
												)}
											</>
										)}
									</Box>
									<p style={{ fontWeight: 'bold', marginTop: '20px' }}>
										NOTE: You have picked the above post, so it won't be visible to other admins. Please resolve it before closing.
									</p>
								</>
							)}

							{isPostResolved !== false ? null : (
								<>
									<h4 style={{ marginTop: '2rem', marginBottom: '1rem', fontWeight: 'bold' }}>Admin Actions</h4>

									<FormProvider {...hookFormMethods}>
										<form className="form ltr-support">
											<Box w="100%" mt="1rem">
												<Row>
													<Col sm="6">
														<RadioGroup
															label="Star Rating (1 - Negative Feedback, 5 - Positive Feedback)"
															name="starRating"
															fields={starRatingOptions}
															direction="row"
														/>
													</Col>
													<Col sm="6">
														<TextArea
															label="Feedback Text"
															disabled={!getValues('starRating')}
															helpText="Add child name to personalise the feedback text. Apply star rating first to enter feedback"
															name="feedbackText"
														/>
													</Col>
												</Row>
											</Box>
										</form>
									</FormProvider>
								</>
							)}

							<Box mt="1rem" d="flex" ai="center" jc="space-between">
								<Box>
									<Button
										size="sm"
										color="primary"
										type="button"
										disabled={isPostResolved !== false}
										onClick={() =>
											onPostAction({
												status: PostStatusEnum.Approved,
												feedbackText: getValues('feedbackText'),
												starRating: getValues('starRating'),
											})
										}
									>
										Approve
									</Button>
									<Button
										size="sm"
										color="danger"
										type="button"
										disabled={isPostResolved !== false}
										onClick={() => onPostAction({ status: PostStatusEnum.Rejected })}
									>
										Reject
									</Button>
									<Button size="sm" type="button" onClick={() => hookFormMethods.reset({ starRating: null, feedbackText: '' })}>
										Clear Feedback
									</Button>
									{isPostResolved && (
										<Button size="sm" color="primary" type="button" onClick={getNextPost}>
											Pick Next
										</Button>
									)}
								</Box>
								{isPostResolved !== false ? null : (
									<>
										<Box d="flex" mr="1rem" mb="20px" ai="center" p="0 0.5rem">
											<Box>
												<Box style={{ fontWeight: 'bold', margin: '0 0.5rem' }}>Assign To: </Box>
												<Box d="flex" ai="center">
													{mentors.map(({ fullName, id }) => (
														<Button
															disabled={id === loggedInUser.id}
															key={fullName}
															size="sm"
															style={{ fontSize: '0.7rem', padding: '5px 10px', margin: '0 0.5rem' }}
															onClick={() => onPostAction({ status: PostStatusEnum.Assigned, assigneeName: fullName, assigneeId: id })}
														>
															{fullName}
														</Button>
													))}
												</Box>
											</Box>
										</Box>
									</>
								)}
							</Box>
						</Box>
					)}
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
