import { CloseModalAction, OpenModalAction } from 'connectors/ModalController/actions';
import { ModalTypes } from 'connectors/ModalController/types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { getBlobURLForImageCrop } from 'utils/imageHelpers';

interface Props {
	name: string;
	label: string;
	aspectRatio: number;
	control: any;
	required?: boolean;
}

const DropzoneWithImageCrop = ({ name, label, aspectRatio, control, required }: Props) => {
	const dispatch = useDispatch();

	const initialCrop = {
		aspect: aspectRatio,
		width: 50,
		unit: '%',
	};

	const onCropComplete = (croppedFile) => {
		control.setValue(name, croppedFile);
		dispatch(CloseModalAction(ModalTypes.IMAGE_CROP_MODAL));
	};

	const onDrop = async (file) => {
		const imagesBlobUrls = await getBlobURLForImageCrop(file);
		const imagesBlobUrlsWithId = file.map(({ id }, index) => ({
			id,
			url: imagesBlobUrls[index].url,
			cropAspectRatio: imagesBlobUrls[index].cropAspectRatio,
		}));

		dispatch(
			OpenModalAction(ModalTypes.IMAGE_CROP_MODAL, {
				blobUrls: imagesBlobUrlsWithId,
				initialCrop,
				onCropComplete,
			})
		);
	};

	return (
		<DropzoneNew
			name={name}
			label={label}
			onDrop={(val) => onDrop(val)}
			validate={async (val) => {
				if (required && (!val || !val.length)) return 'Required';
			}}
			required={required}
		/>
	);
};

export default DropzoneWithImageCrop;
