import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pickWrapper, isNotEmptyObject, accessDeepObject } from '../../../utils/commonHelpers';
import { resetPassword } from '../authSlice';

import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPassword = (props) => {
	const authState = useSelector(({ auth }) => ({ isLoading: auth.loading, errors: auth.errors }));
	const formState = useSelector(({ form }) => ({ reset: form.reset_password_form }));

	const dispatch = useDispatch();

	const handleResetPassword = useCallback(() => {
		const values = accessDeepObject('reset.values', formState);

		if (isNotEmptyObject(values)) {
			dispatch(
				resetPassword({
					...values,
					token: props.match.params.token,
				})
			);
		}
	}, [dispatch, formState]);

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<h3 className="account__title">
							Welcome to
							<span className="account__logo">
								{' '}
								Ivy
								<span className="account__logo-accent">Pods</span>
							</span>
						</h3>
						<h4 className="account__subhead subhead">Admin Panel</h4>
					</div>
					<ResetPasswordForm onSubmit={handleResetPassword} {...pickWrapper(['errors', 'isLoading'], authState)} />
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
