import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

let timeout;

export class SelectField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		onMenuOpen: PropTypes.func,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		clearable: PropTypes.bool,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			})
		),
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			}),
		]),
	};

	static defaultProps = {
		placeholder: '',
		options: [],
		clearable: false,
	};

	state = {
		inputValue: '',
	};

	componentDidMount = () => {
		const { onChange, value } = this.props;
		// If passed value is an object then call the onChange with
		// value.value initally. This needs to be done to maintain compatibility with handlChange
		// implementation
		if (value !== null && typeof value === 'object') {
			onChange(value.value);
		}
	};

	handleChange = (selectedOption) => {
		const { onChange } = this.props;
		onChange(selectedOption ? selectedOption.value : '');
	};

	handleInputChange = (newValue) => {
		if (newValue !== this.state.inputValue) {
			this.setState({ inputValue: newValue });

			if (this.props.inputChangeHandler) {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					this.props.inputChangeHandler(newValue);
				}, 500);
			}
		}
	};

	render() {
		const { value, name, placeholder, options, clearable, filterOption, isDisabled, loading = false, defaultValue } = this.props;

		// If an object is passed of the form { label, value } then set it as value
		// otherwise if its string find it from options
		const selectFieldValue = typeof value === 'object' ? value : value === '' ? value : options.find((v) => v.value === value);

		return (
			<Select
				name={name}
				defaultValue={defaultValue}
				isLoading={loading}
				isDisabled={isDisabled}
				value={selectFieldValue}
				filterOption={filterOption}
				onChange={this.handleChange}
				onMenuOpen={this.props.onMenuOpen}
				inputValue={this.state.inputValue}
				onInputChange={(val) => {
					this.handleInputChange(val);
				}}
				options={options}
				isClearable={clearable}
				className="react-select"
				placeholder={placeholder}
				classNamePrefix="react-select"
			/>
		);
	}
}

export const renderSelectField = (props) => {
	const {
		input,
		label,
		isDisabled,
		isRequired,
		meta,
		options,
		placeholder,
		className,
		onInputChange,
		loading,
		filterOption = null,
	} = props;
	return (
		<div className={`form__form-group-input-wrap ${className}`}>
			<span className="form__form-group-label">
				{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
			</span>
			<SelectField
				{...input}
				loading={loading}
				isDisabled={isDisabled}
				inputChangeHandler={onInputChange}
				options={options}
				placeholder={placeholder}
				filterOption={filterOption}
			/>
			{meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
		</div>
	);
};

renderSelectField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string,
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})
	),
	placeholder: PropTypes.string,
	className: PropTypes.string,
};

renderSelectField.defaultProps = {
	meta: null,
	options: [],
	placeholder: '',
	className: '',
};

export default renderSelectField;
