import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import styled from 'styled-components';

import { isNotEmptyArray } from '@bit/yellow_class.utils.common-helpers';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import If from 'reusableComponents/If';
import { fetchWrapper } from 'utils/fetchWrapper';
import { dateTimeConverter } from 'utils/dateHelper';
import { showToast } from '../../../utils/commonHelpers';
import theme from '../../../utils/theme';

import Box from '../../../reusableComponents/Box';
import CustomModal from '../../../reusableComponents/CustomModal';
import {
	createOrUpdateProfileHelper,
	getFilteredProfileDetails,
	getSelectedPackageAmount,
	getValidCartItems,
	updateProfileForGrades,
} from '../helper';
import { ProfileSubjectSelectionCards } from './ProfileSubjectSelectionCards';

const StyledModal = styled.div`
	width: 60vw;
	height: 90vh;
	min-height: 500px;
	max-height: 90vh;

	padding: 1rem;

	input {
		border: 1px solid #dddddd;
	}
	overflow-y: scroll;
`;

const customStyles = `
	width: 80vh;

	@media ${theme.device.md} {
		width: 80vh;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const paymentSourceOptions = [
	{ label: 'BOOKMYSHOW', value: 'BOOKMYSHOW' },
	{ label: 'QR_CODE', value: 'QR_CODE' },
	{ label: 'PAYTM_INSIDER', value: 'PAYTM_INSIDER' },
	{ label: 'ADMIN_GRANT', value: 'ADMIN_GRANT' },
	{ label: 'NEFT', value: 'NEFT' },
	{ label: 'TEST', value: 'TEST' },
];

interface GrantSubscriptionModalValues {
	amount: number;
	paymentSource: {
		label: string;
		value: string;
	};
	createdBy: string;
	adminGrantReason?: string;
	selectedGrade: {
		label: string;
		value: string;
	};
	paymentReferenceId: string;
	selectedPaymentReferenceId: {
		label: string;
		value: string;
	};
	profileName: string;
}

const GrantSubscriptionModal = ({ userDetails, loggedInUser, closeModal }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [subscriptionGranted, setSubscriptionGranted] = useState(false);
	const [grades, setGrades] = useState([]);
	const [profiles, setProfiles] = useState([]);
	const [referenceIds, setReferenceIds] = useState([]);
	const [packages, setPackages] = useState([]);

	const { id: userId, mobile } = userDetails;

	const hookFormMethods = useForm<GrantSubscriptionModalValues>({
		defaultValues: {
			createdBy: loggedInUser?.email,
		},
	});
	const { handleSubmit, watch, setValue, getValues } = hookFormMethods;
	const formValues = watch(['paymentSource', 'selectedGrade', 'selectedPaymentReferenceId']);

	const isEmptyReferenceIds = !isNotEmptyArray(referenceIds);
	const isDisabled = isSubmitting || subscriptionGranted;

	const loadGradesAndProfiles = useCallback(async () => {
		try {
			const [gradesResponse, profilesResponse] = await Promise.all([
				fetchWrapper('/v1/admin/get-grades', { method: 'GET' }),
				fetchWrapper(`/v1/admin/profiles?parentId=${userId}`, { method: 'GET' }),
			]);
			const grades = gradesResponse.data;
			if (grades) {
				setGrades(grades);
			}
			const profiles = profilesResponse.data;
			if (profiles) {
				setProfiles(profiles);
			}
			if (gradesResponse.status !== 200 || profilesResponse.status !== 200) {
				throw new Error('Failed to load grades and profiles');
			}
		} catch (error) {
			showToast('error', 'Failed to load grades and profiles');
		}
	}, []);

	useEffect(() => {
		loadGradesAndProfiles();
	}, []);

	const { activeProfiles, gradesToShowForCreateProfile } = useMemo(() => {
		return getFilteredProfileDetails(profiles, grades, null, false);
	}, [profiles, grades, formValues]);

	const loadPackagesAndPrevRefIds = useCallback(async () => {
		try {
			const [packagesResponse, paymentRefResponse] = await Promise.all([
				fetchWrapper(`/v1/admin/membership/get-packages-by-grade?&entityType=PACKAGE`, {
					method: 'GET',
				}),
				fetchWrapper(`/v2/admin/get-payment-ref-ids`, {
					method: 'POST',
					body: { userId },
				}),
			]);

			const packages = packagesResponse.data;
			if (packages) {
				setPackages(packages);
			}
			const refIds = paymentRefResponse.data?.map((ref) => ({
				label: `${ref.refId} -- ${dateTimeConverter(ref.createdAt, 'format', 'dd MMM yyyy (k:mm)')} -- ₹${(ref.amount / 100).toFixed(2)}`,
				value: ref.refId,
			}));
			if (isNotEmptyArray(refIds)) {
				refIds.push({
					label: 'Other',
					value: 'other',
				});
				setReferenceIds(refIds);
			}
			if (packagesResponse.status !== 200 || paymentRefResponse.status !== 200) {
				throw new Error('Failed to load packages and reference ids');
			}
		} catch (error) {
			showToast('error', 'Failed to load packages and reference ids');
		}
	}, []);

	useEffect(() => {
		loadPackagesAndPrevRefIds();
	}, []);

	const createProfile = useCallback(async () => {
		const grade = getValues('selectedGrade');
		const name = getValues('profileName');
		const profile = await createOrUpdateProfileHelper(name, Number(grade.value), userId, mobile);
		if (profile) {
			setProfiles((preFetchedProfiles) => [profile, ...preFetchedProfiles]);
			setValue('selectedGrade', '');
			setValue('profileName', '');
			setValue('amount', 0);
		}
	}, []);

	const onSubmit = useCallback(
		async (values: GrantSubscriptionModalValues) => {
			try {
				setIsSubmitting(true);
				await updateProfileForGrades(values, userId);
				const paymentReferenceId =
					(values.selectedPaymentReferenceId && values.selectedPaymentReferenceId?.value === 'other'
						? values.paymentReferenceId
						: values.selectedPaymentReferenceId?.value) ?? values.paymentReferenceId;
				if (!paymentReferenceId) {
					showToast('error', 'Payment Reference Id is required');
					return;
				}
				const validCartItems = getValidCartItems(activeProfiles, values, packages);
				if (!isNotEmptyArray(validCartItems)) {
					return;
				}
				const res = await fetchWrapper(`/v1/admin/grant-package-via-admin`, {
					method: 'POST',
					body: {
						amount: values.amount,
						createdBy: values.createdBy,
						adminGrantReason: values.adminGrantReason,
						userId,
						paymentReferenceId,
						paymentSource: values.paymentSource?.value,
						cart: validCartItems,
					},
				});
				if (res.status === 200) {
					showToast('success', 'Subscription granted successfully!');
					setSubscriptionGranted(true);
				} else throw res.errors?.error;
			} catch (err) {
				// eslint-disable-next-line no-console
				const error = err ?? err?.message;
				console.log('err', err);
				if (typeof error === 'string') showToast('error', error);
				else showToast('error', 'Failed to grant subscription. Please try again!');
			} finally {
				setIsSubmitting(false);
			}
		},
		[activeProfiles, packages]
	);

	useEffect(() => {
		if (formValues.paymentSource?.value === 'ADMIN_GRANT') {
			setValue('amount', 0);
		} else {
			setValue('amount', getSelectedPackageAmount(activeProfiles, getValues()));
		}
	}, [formValues.paymentSource]);

	const isNotEmptyActiveProfiles = isNotEmptyArray(activeProfiles);

	return (
		// @ts-ignore
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Card>
					<CardBody>
						<Box d="flex" ai="center" jc="space-between">
							<Box as="h3" mb="1rem">
								Grant Subscription
							</Box>
							<Box
								mb="1rem"
								cursor="pointer"
								fs="bold"
								pl="0.6rem"
								pr="0.6rem"
								pt="0.4rem"
								pb="0.4rem"
								bor="1px solid grey"
								onClick={() => closeModal()}
							>
								X
							</Box>
						</Box>

						<FormProvider {...hookFormMethods}>
							<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
								<Box w="100%" mt="1rem">
									{isNotEmptyArray(activeProfiles) && (
										<>
											<Row style={{ padding: '1rem 0rem' }}>
												<Col>
													Select subject(s) for below profile(s)<span style={{ color: 'red' }}> *</span>
												</Col>
											</Row>
											{activeProfiles.map((profile) => (
												<ProfileSubjectSelectionCards profile={profile} grades={gradesToShowForCreateProfile} isDisabled={isDisabled} />
											))}
										</>
									)}
									{isNotEmptyArray(gradesToShowForCreateProfile) && (
										<div
											style={{
												padding: '1rem 1rem 0 1rem',
												width: '100%',
												borderRadius: '0.5rem',
												marginBottom: '1rem',
												backgroundColor: '#f5f5f5',
											}}
										>
											<Row>
												<Col>
													<p style={{ color: 'red' }}>* Create profile, if profile for particular grade not exists.</p>
													<SelectField
														label="Select Grade"
														name="selectedGrade"
														options={gradesToShowForCreateProfile}
														isClearable
														isDisabled={isDisabled}
													/>

													<Input label="Enter name" name="profileName" />
													<Button size="sm" color="primary" onClick={createProfile}>
														Create Profile
													</Button>
												</Col>
											</Row>
										</div>
									)}

									{isNotEmptyActiveProfiles && (
										<Box>
											<Row>
												<Col sm="6">
													<SelectField
														label="Payment Source"
														name="paymentSource"
														options={paymentSourceOptions}
														isClearable
														required
														isDisabled={isDisabled}
													/>
												</Col>
												<Col sm="6">
													{!isEmptyReferenceIds && (
														<SelectField
															label="Payment Reference ID"
															required
															name="selectedPaymentReferenceId"
															options={referenceIds}
															isClearable
															isDisabled={isDisabled}
														/>
													)}
													{(formValues.selectedPaymentReferenceId?.value === 'other' || isEmptyReferenceIds) && (
														<Input
															label="Payment Reference ID"
															name="paymentReferenceId"
															required
															placeholder="Enter Reference ID / Transaction ID"
															disabled={isDisabled}
														/>
													)}
												</Col>
											</Row>
											<Row>
												<Col sm="6">
													<Input label="Amount (Rupees)" name="amount" type="number" required disabled={isDisabled} />
												</Col>
											</Row>
											<Row>
												<Col sm="6">
													<Input
														label="Sale Attributed To"
														placeholder="Email of the sales person"
														name="createdBy"
														type="email"
														required
														disabled={isDisabled}
													/>
												</Col>
											</Row>

											<If
												condition={formValues.paymentSource?.value === 'ADMIN_GRANT'}
												then={
													<Row>
														<Col sm="12">
															<Input
																label="Admin Grant Reason"
																placeholder="Reason of directly granting membership or package benefits"
																name="adminGrantReason"
																disabled={isDisabled}
															/>
														</Col>
													</Row>
												}
											/>

											<If
												condition={!subscriptionGranted}
												then={
													<Col sm="12" className="mt-4">
														<Button size="sm" color="primary" type="submit" disabled={isSubmitting}>
															{isSubmitting ? 'Granting Subscription' : 'Grant'}
														</Button>
														<Button size="sm" color="success" type="button" onClick={() => closeModal()}>
															Cancel
														</Button>
													</Col>
												}
												else={
													<Box d="flex" w="100%" ai="center">
														<Box customStyle={{ fontWeight: 'bold' }}>
															Subscription benefits was granted granted to the mobile no: {mobile}
														</Box>
													</Box>
												}
											/>
											<If
												condition={!subscriptionGranted}
												then={
													<Box customStyle={{ fontWeight: 'bold' }} mt="1rem" fs="bold">
														NOTE: Subscription benefits will be granted to the mobile no: {mobile}
													</Box>
												}
											/>
										</Box>
									)}
								</Box>
							</form>
						</FormProvider>
					</CardBody>
				</Card>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(GrantSubscriptionModal);
