/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { isNotEmptyObject } from '../../utils/commonHelpers';

import DropZoneField from '../Form/DropZone';
import DropZoneMultipleField from '../Form/DropZoneMultiple';
import { ErrorMessage } from './ErrorMessage';

const Dropzone = ({ label, isRequired, validate, name, error, control, noOfFiles = 1, isMulti = false, withCaption }) => {
	return (
		<div className="form__form-group">
			{label && (
				<span className="form__form-group-label">
					{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<Controller
				as={isMulti ? <DropZoneMultipleField /> : <DropZoneField />}
				name={name}
				control={control}
				defaultValue=""
				rules={{ required: isRequired, validate }}
				numberOfFiles={noOfFiles}
				withCaption={withCaption}
			/>
			{isNotEmptyObject(error) ? (
				error.type === 'required' ? (
					<span className="form__form-group-error">Required</span>
				) : (
					<span className="form__form-group-error">{error.message}</span>
				)
			) : null}
		</div>
	);
};

interface Props {
	name: string;
	label: string;
	required?: boolean;
	validate?: (val: File[]) => Promise<string | boolean> | string | boolean;
	noOfFiles?: number;
	defaultValue?: any;
	isMulti?: boolean;
	withCaption?: boolean;
	isClickDisabled?: boolean;
	accept?: string;
	onDrop?: any;
	customCaptionLabel?: string;
	height?: string;
	width?: string;
}

export const DropzoneNew = ({
	label,
	name,
	required,
	validate,
	noOfFiles = 1,
	isMulti = false,
	withCaption,
	isClickDisabled,
	defaultValue,
	accept = 'image/*',
	onDrop,
	customCaptionLabel,
	height,
	width,
}: Props) => {
	const { control } = useFormContext();

	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<Controller
				as={isMulti ? <DropZoneMultipleField /> : <DropZoneField {...(height && { height })} {...(width && { width })} />}
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={{ required, validate }}
				numberOfFiles={noOfFiles}
				accept={accept}
				withCaption={withCaption}
				onDrop={onDrop}
				customCaptionLabel={customCaptionLabel}
			/>
			<ErrorMessage
				{...{ label, name }}
				render={(message) => {
					return <span className="form__form-group-error">{message}</span>;
				}}
			/>
		</div>
	);
};

export default Dropzone;
