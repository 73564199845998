import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PollsForm from 'containers/Poll/PollsForm';
import { accessDeepObject, ArrayMaybe, isNotEmptyObject, parseQueryParams, pickWrapper, showToast } from 'utils/commonHelpers';
import { doPollsOverlap, getPlayerInstance, getSanitizedVideoUrl } from 'containers/Class/helpers';
import { deleteShortPolls, getShort, submitShortPolls } from '../actions/shorts.actions';

interface ShortsPollProps {
	location: { search: { shortId?: string } };
}

const modifyBeforeSavePolls = (params, originalPolls) => {
	const polls = params.polls.map((poll) => {
		const mins = parseInt(poll.triggerTime.min, 10) * 60;
		const triggerTime = mins + parseInt(poll.triggerTime.sec, 10);

		return {
			...poll,
			triggerTime,
			answer: poll.questionType === 'ASSESSMENT' ? accessDeepObject(`options.${poll.answer}.option`, poll) : undefined,
		};
	});
	const deletedPolls = originalPolls.reduce((acc, curr) => {
		if (curr.id) {
			if (polls.findIndex((poll) => poll.id === curr.id) === -1) {
				return [...acc, { ...curr, isDelete: true }];
			}
			return acc;
		}
		return [...acc, curr];
	}, []);
	return { polls: [...polls, ...deletedPolls], shortId: params.shortId };
};

export const ShortsPoll = (props: ShortsPollProps) => {
	const dispatch = useDispatch();
	const [shortDuration, setShortDuration] = React.useState(null);

	const { shortId, videoUrl: shortVideoUrl } = pickWrapper(['shortId', 'videoUrl'], parseQueryParams(props?.location?.search));

	let player;

	const shortPollProps = useSelector(({ shorts }) => ({
		shorts: shorts.shorts,
		selectedShort: shorts.short,
		polls: shorts.short.polls,
		loading: shorts.loading,
		isSubmitting: shorts.isSubmitting,
	}));

	useEffect(() => {
		player = getPlayerInstance({ videoTagId: 'my-player' });
		player.on('loadedmetadata', () => {
			const videoDuration = player.duration();
			setShortDuration(videoDuration);
		});
	}, []);

	useEffect(() => {
		if (props?.location?.search) {
			// NOTE: Using the shorts in Short Table to filter out which short polls are being created/edited for
			const short = shortPollProps.shorts.find((shortItem) => shortItem.id === shortId);
			if (player && isNotEmptyObject(short)) {
				player.src({
					type: 'application/x-mpegURL',
					src: getSanitizedVideoUrl(short?.videoUrl || shortVideoUrl),
				});
			}
			dispatch(getShort({ shortId }));
		}
	}, [props?.location?.search]);

	const handleSubmit = async (params) => {
		if (isNotEmptyObject(ArrayMaybe(params.polls).find((poll) => poll.answer >= poll.options.length))) {
			showToast('error', '✋ Please Choose a Correct Answer');
		} else if (doPollsOverlap(params.polls, shortDuration) !== false) {
			const errorMessage = doPollsOverlap(params.polls, shortDuration);
			showToast('error', `✋  ${errorMessage}`);
		} else {
			const processedValues = {
				...modifyBeforeSavePolls({ ...params, shortId }, shortPollProps.polls),
			};
			if (isNotEmptyObject(processedValues)) {
				dispatch(submitShortPolls(processedValues));
			} else {
				showToast('error', '🚫️ Please select at least one field!!');
			}
		}
	};

	const onDeletePolls = () => {
		// eslint-disable-next-line no-restricted-globals, no-alert
		const shouldRemovePolls = confirm('Are you sure you want to remove all polls?');
		if (shouldRemovePolls) {
			dispatch(deleteShortPolls({ shortId }));
		}
	};

	return (
		<PollsForm
			{...shortPollProps}
			selectedEntity={{
				...shortPollProps.selectedShort,
				videoDetails: {
					...shortPollProps.selectedShort.videoDetails,
					videoUrl: shortPollProps?.selectedShort?.videoDetails?.videoUrl || shortVideoUrl,
				},
			}}
			testPollUrl={`/shorts/polls/test?shortId=${shortPollProps.selectedShort.id}`}
			redirectUrl="/shorts/list"
			handleSubmit={handleSubmit}
			onDeletePolls={onDeletePolls}
			isShortsPoll
		/>
	);
};
