/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams, parseQueryParams } from 'utils/commonHelpers';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import Filter from 'reusableComponents/Filter';
import { format } from 'date-fns';
import { AsyncSelectField } from 'reusableComponents/HookForm/Select';
import { fetchMentors } from 'utils/fetchHelpers';
import { FormProvider, useForm } from 'react-hook-form';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import history from '../../App/history';
import { getBatches, setApiParams } from '../batchSlice';
import { updateBatchAndUpdateList } from '../actions/batch.actions';

const Actions = ({ batchId, programId, programTitle, index, status, enrollmentStartTime, continuity }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => history.push(`/batch/add-edit${stringifyQueryParams({ programId, programTitle, batchId, continuity })}`)}
					disabled={false}
				>
					Edit Batch
				</DropdownItem>
				<DropdownItem
					disabled={enrollmentStartTime && new Date() >= new Date(enrollmentStartTime)}
					onClick={() =>
						dispatch(
							updateBatchAndUpdateList({
								batchId,
								batchIndex: index,
								status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
							})
						)
					}
				>
					{status === 'ACTIVE' ? 'Mark as Inactive' : 'Mark as Active'}
				</DropdownItem>
				<DropdownItem
					disabled
					onClick={() => {
						//
					}}
				>
					Delete Batch
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ batches }) {
	return batches.map(
		(
			{
				id,
				frequency,
				endDate,
				enrollmentStartTime,
				startDate,
				createdAt,
				program: { id: programId, title: programTitle, continuity, programType },
				title,
				status,
			},
			index
		) => ({
			id,
			title,
			frequency,
			programTitle,
			programType: continuity,
			createdAt: format(new Date(createdAt), 'dd-MM-yyyy HH:mm'),
			startDate: format(new Date(startDate), 'dd-MM-yyyy HH:mm'),
			endDate: endDate ? format(new Date(endDate), 'dd-MM-yyyy HH:mm') : 'NA',
			actions: (
				<Actions {...{ batchId: id, programId, enrollmentStartTime, index, programTitle, status, endDate, continuity, programType }} />
			),
		})
	);
}

const heads = [
	{ accessor: 'title', Header: 'Title' },
	{ accessor: 'programTitle', Header: 'Program Title' },
	{ accessor: 'programType', Header: 'Program Type' },
	{ accessor: 'frequency', Header: 'Frequency' },
	{ accessor: 'startDate', Header: 'Start Date' },
	{ accessor: 'endDate', Header: 'End Date' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'actions', Header: 'Actions' },
];

const BatchTable = (props) => {
	const batchState = useSelector(({ batch }) => ({
		loading: batch.loading,
		batches: batch.batches,
		pageNo: batch.pageNo,
		total: batch.total,
		apiParams: batch.apiParams,
	}));

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
	const { handleSubmit, watch, setValue } = hookFormMethods;
	const formValues = watch(['startDate', 'endDate', 'mentorId']);

	const [batchRows, setBatchRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState(batchState.apiParams.query);

	React.useEffect(() => {
		const query = parseQueryParams(props.location.search)?.query;
		setSearchTerm(query);
		dispatch(setApiParams({ query }));
	}, []);

	React.useEffect(() => {
		dispatch(getBatches());
	}, [batchState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(batchState.pageNo);
	}, [batchState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(batchState.batches)) {
			const structuredRows = createRows({ batches: batchState.batches });
			setBatchRows(structuredRows);
		} else {
			setBatchRows([]);
		}
	}, [batchState.batches]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = batchState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					...apiParams,
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					...batchState.apiParams,
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', batchState),
					skip: accessDeepObject('apiParams.limit', batchState) * (pageOfItems - 1),
				})
			);
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				...batchState.apiParams,
				query: searchTerm,
				limit: 20,
				skip: 0,
				sortKey: 'startDate',
				sortOrder: '-1',
			})
		);
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-2 rounded">
						<Col sm={9} className="mb-3">
							<h3>Batches Listing</h3>
						</Col>
						<Col sm={6}>
							<Filter
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								onSearch={onSearch}
								placeholder="Search by Batch Title or Program ID, Title"
							/>
						</Col>
					</Row>

					<FormProvider {...hookFormMethods}>
						<form
							style={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
							onSubmit={handleSubmit(() => {
								const date = new Date(formValues.endDate);
								dispatch(
									setApiParams({
										...batchState.apiParams,
										sortKey: 'startDate',
										sortOrder: '-1',
										limit: 20,
										skip: 0,
										mentorId: formValues?.mentorId?.value,
										startDate: formValues.startDate ? new Date(formValues.startDate).toISOString() : '',
										endDate: formValues.endDate ? new Date(date.setDate(date.getDate() + 1)).toISOString() : '',
									})
								);
							})}
						>
							{/* <Row> */}
							<Col sm="2">
								<Input type="date" name="startDate" label="Start Date" showIcon="cross" iconHandler={() => setValue('startDate', '')} />
							</Col>
							<Col sm="2">
								<Input type="date" name="endDate" label="End Date" showIcon="cross" iconHandler={() => setValue('endDate', '')} />
							</Col>
							<Col sm="3">
								<AsyncSelectField label="Mentor" name="mentorId" fetchOptions={fetchMentors} isClearable />
							</Col>
							<Button type="submit" style={{ marginBottom: '0px' }} size="sm" color="primary">
								Filter
							</Button>
							{/* </Row> */}
						</form>
					</FormProvider>

					{isNotEmptyArray(batchRows) && (
						<>
							<DataPaginationTable heads={heads} rows={batchRows} onSort={onSort} />
							<Pagination
								itemsCount={batchState.total}
								itemsToShow={accessDeepObject('apiParams.limit', batchState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{batchState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default BatchTable;
