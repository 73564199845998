import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

// const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
	constructor() {
		super();
		this.state = {
			collapse: false,
		};
	}

	toggle = () => {
		this.setState((prevState) => ({ collapse: !prevState.collapse }));
	};

	render() {
		const { collapse } = this.state;
		const { user, logout } = this.props;

		return (
			<div className="topbar__profile">
				<button type="button" className="topbar__avatar" onClick={this.toggle}>
					{/* <img className='topbar__avatar-img' src={Ava} alt='avatar' /> */}
					<p className="topbar__avatar-name">{user.fullName}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						<TopbarMenuLink title="Users" icon="users" path="/users/list" />
						<div className="topbar__menu-divider" />
						<a role="button" className="topbar__link" onClick={logout}>
							<span className="topbar__link-icon lnr lnr-exit" />
							<p className="topbar__link-title">Log Out</p>
						</a>
					</div>
				</Collapse>
			</div>
		);
	}
}
