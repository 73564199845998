import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader, ArrayMaybe, stringifyQueryParams } from '../../../utils/commonHelpers';
import { fetchVideoSuccess, toggleLoading } from '../classSlice';
import { setError } from '../../App/globalSlice';

export function* fetchVideoSaga({ payload }) {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/video${stringifyQueryParams(payload)}`);
	if (res.status === 200) {
		yield put(actionSpreader(fetchVideoSuccess.type, { video: ArrayMaybe(res.data.videos)[0] }));
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
