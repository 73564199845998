import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'utils/fetchWrapper';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { setError } from 'containers/App/globalSlice';
import history from '../../../App/history';

export const fetchQuestions = createAsyncThunk<any, any>('question/fetchQuestion', async (apiParamData, { dispatch }) => {
	const response = await fetchWrapper(`/v1/admin/quiz/get-quiz-questions`, { method: 'POST', body: apiParamData });

	try {
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			return {
				questions: response.data.questions,
				total: response.data.total,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const createQuestion = createAsyncThunk<any, any>('question/createQuestion', async (params, { dispatch }) => {
	const response = await fetchWrapper(`/v1/admin/quiz/create-quiz-question`, { method: 'POST', body: params });

	try {
		if (response.status !== 200 || !response.data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			if (!response.data.status) {
				return dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			showToast('success', `Question created successfully`);
			history.push('/activity/question/list');
			return response.data;
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const getQuestionDetails = createAsyncThunk<any, any>('question/getQuestionDetails', async ({ questionId }, { dispatch }) => {
	const qp = stringifyQueryParams({ questionId });
	const response = await fetchWrapper(`/v1/admin/quiz/get-quiz-question-details${qp}`, { method: 'GET' });

	try {
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			return response.data;
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const updateQuestion = createAsyncThunk<any, any>('question/updateQuestion', async (params, { dispatch }) => {
	const response = await fetchWrapper(`/v1/admin/quiz/update-quiz-question`, { method: 'PUT', body: params });

	try {
		if (response.status !== 200 || !response.data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			showToast('success', `Question updated successfully`);
			history.push('/activity/question/list');
			return {
				...response.data,
				questionId: params.id,
				status: params.status,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const deleteQuestion = createAsyncThunk<any, any>('question/deleteQuestion', async ({ questionId }, { dispatch }) => {
	const qp = stringifyQueryParams({ questionId });
	const response = await fetchWrapper(`/v1/admin/quiz/delete-quiz-question${qp}`, { method: 'DELETE' });

	try {
		if (response.status !== 200 || !response.data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			history.push('/activity/question/list');
			showToast('success', `Question deleted successfully`);
			return response.data;
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});
