/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { useForm, FormProvider } from 'react-hook-form';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { useSelector, useDispatch } from 'react-redux';
import { accessDeepObject, ArrayMaybe, isNotEmptyArray } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import Box from 'reusableComponents/Box';
import { AsyncSelectField } from 'reusableComponents/HookForm/Select';
import history from 'containers/App/history';
import { getEnrollments, uploadCertificate, deleteCertificate } from '../actions/enrollments.actions';
import { setApiParams } from '../enrollmentsSlice';

const fetchTemplates = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/html-templates?query=${query}&type=PROGRAM&status=ACTIVE`, { method: 'GET' });
		return res?.data?.templates;
	} catch {
		return [];
	}
};

const fetchActiveBatches = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/get-active-batches?query=${query}&sortKey=createdAt&sortOrder=-1`, { method: 'GET' });
		return res?.data?.batches?.map((b) => ({ label: `${b.program.title} (${b.title})`, value: b.id }));
	} catch {
		return [];
	}
};

const Actions = ({ batchId, profile, certificates }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const certificate = ArrayMaybe(certificates)[0];

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const generateCertificate = async () => {
		const templates = await fetchTemplates({ query: '' });
		// Note: This only works for now because we have one template
		// TODO:
		const template = templates?.[0];
		if (!template) {
			console.log('Template not found');
			return;
		}
		const data = {
			batchIds: [batchId],
			profileIds: [profile?.id],
			templateId: template.id,
		};
		dispatch(uploadCertificate(data));
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem disabled={!certificate} onClick={() => history.push(`/certificates/${certificate.id}`)}>
					View Certificate
				</DropdownItem>
				<DropdownItem disabled={certificate} onClick={generateCertificate}>
					Generate Certificate
				</DropdownItem>
				<DropdownItem disabled={!certificate} onClick={() => dispatch(deleteCertificate({ id: certificate.id }))}>
					Delete Certificate
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ enrollments }) {
	return enrollments.map(({ id, profile, batch, user, program, totalClasses, totalClassesAttended, certificates }) => ({
		id,
		profileName: profile?.fullName,
		mobile: user?.mobile,
		programTitle: program?.title,
		batchTitle: batch?.title,
		totalClasses,
		totalClassesAttended,
		actions: <Actions {...{ batchId: batch?.id, profile, certificates }} />,
	}));
}

const heads = [
	{ accessor: 'profileName', Header: 'Profile name' },
	{ accessor: 'mobile', Header: 'Phone number' },
	{ accessor: 'programTitle', Header: 'Program title' },
	{ accessor: 'batchTitle', Header: 'Batch title' },
	{ accessor: 'totalClasses', Header: 'Total classes' },
	{ accessor: 'totalClassesAttended', Header: 'Total attended classes' },
	{ accessor: 'actions', Header: 'Actions' },
];

interface BatchFormValues {
	data: { label: string; value: string };
	query: string;
	numClassAttended: string;
}

const EnrollmentsTable = () => {
	const enrollmentsState = useSelector(({ enrollments }) => ({
		loading: enrollments.loading,
		enrollments: enrollments.enrollments,
		pageNo: enrollments.pageNo,
		total: enrollments.total,
		apiParams: enrollments.apiParams,
	}));

	const dispatch = useDispatch();

	const [postRows, setPostRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	const hookFormMethods = useForm<BatchFormValues>();

	const { handleSubmit, watch } = hookFormMethods;
	const dataWatch = watch('data');

	const onSubmit = (values: BatchFormValues) => {
		const newFilter = cleanFilters({ query: values.query, numClassAttended: values.numClassAttended || 0, batchId: values.data.value });
		dispatch(setApiParams(newFilter));
		dispatch(getEnrollments(newFilter));
	};

	React.useEffect(() => {
		setPageOfItems(enrollmentsState.pageNo);
	}, [enrollmentsState.pageNo]);

	React.useEffect(() => {
		if (dataWatch) {
			const { apiParams } = enrollmentsState;
			const newFilter = cleanFilters({
				query: apiParams.query,
				numClassAttended: apiParams.numClassAttended || 0,
				batchId: dataWatch?.value,
			});
			dispatch(setApiParams(newFilter));
			dispatch(getEnrollments(newFilter));
		}
	}, [dataWatch]);

	React.useEffect(() => {
		if (isNotEmptyArray(enrollmentsState.enrollments)) {
			const structuredRows = createRows({ enrollments: enrollmentsState.enrollments });
			setPostRows(structuredRows);
		} else {
			setPostRows([]);
		}
	}, [enrollmentsState.enrollments]);

	// remove null/undefined key
	const cleanFilters = (obj) => omitBy(obj, isNil);

	const onChangePage = (itemsPage) => {
		const { apiParams } = enrollmentsState;
		if (itemsPage) {
			const newParams = {
				query: apiParams.query,
				numClassAttended: apiParams.numClassAttended,
				sortKey: apiParams.sortKey,
				sortOrder: apiParams.sortOrder,
				batchId: apiParams.batchId,
				limit: apiParams.limit,
				skip: apiParams.limit * (itemsPage - 1),
			};
			dispatch(setApiParams(newParams));
			dispatch(getEnrollments(newParams));
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			const { apiParams } = enrollmentsState;
			const newParams = {
				query: apiParams.query,
				numClassAttended: apiParams.numClassAttended,
				sortKey: sortColumn,
				sortOrder,
				batchId: apiParams.batchId,
				limit: apiParams.limit,
				skip: apiParams.limit * (pageOfItems - 1),
			};
			dispatch(setApiParams(newParams));
			dispatch(getEnrollments(newParams));
		}
	};

	return (
		<LoadingOverlay isLoading={enrollmentsState.loading} msg="Getting data...">
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<FormProvider {...hookFormMethods}>
							<form className="form" onSubmit={handleSubmit(onSubmit)}>
								<Box w="100%">
									<Row className="mt-1 mb-4 rounded">
										<Col sm={8} className="mb-3">
											<h3>Enrollments Listing</h3>
										</Col>
										<Col sm={4} className="mb-3">
											<AsyncSelectField label="Select a batch" fetchOptions={fetchActiveBatches} name="data" isClearable required />
										</Col>
										<Col>
											<Input
												placeholder="Search by profile name & phone number"
												label="Search by profile name & phone number"
												name="query"
											/>
										</Col>
										<Col sm={2} className="mb-3">
											<Input placeholder="Num class attended" label="Num class attended" type="number" name="numClassAttended" />
										</Col>
										<Col sm={2} className="mb-3">
											<Button size="sm" color="primary" type="submit">
												Apply Filters
											</Button>
										</Col>
									</Row>
								</Box>
								{isNotEmptyArray(postRows) && (
									<>
										<DataPaginationTable heads={heads} rows={postRows} onSort={onSort} />
										<Pagination
											itemsCount={enrollmentsState.total}
											itemsToShow={accessDeepObject('apiParams.limit', enrollmentsState)}
											pageOfItems={pageOfItems}
											onChangePage={onChangePage}
										/>
									</>
								)}
								{enrollmentsState.total === 0 && <div className="">No data</div>}
							</form>
						</FormProvider>
					</CardBody>
				</Card>
			</Col>
		</LoadingOverlay>
	);
};

export default EnrollmentsTable;
