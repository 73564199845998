import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import difference from 'lodash/difference';
import { omitWrapper, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper, fetchWrapperCreateMedia, updateMedia, uploadMedias } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../featuredPostSlice';

export const getPosts = createAsyncThunk(
	'featuredPost/getPosts',
	async ({ sortKey, sortOrder, limit, skip, status, query }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip, status: status || 'APPROVED', query });
		const res = await fetchWrapper(`/v1/admin/posts${qp}`, { method: 'GET' });

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				return {
					posts: res.data.posts,
					total: res.data.total,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const createPost = createAsyncThunk('featuredPost/createPost', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const { data: postId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

		const isChallenge = data.entity?.type === 'CHALLENGE';
		const isShorts = data.entity?.type === 'SHORTS';

		const hasVideo = isShorts || isChallenge;

		const valuesToSubmit = {
			...omitWrapper(['media'], data),
			id: postId,
			hasVideo,
			entity: {
				...data.entity,
				id: isChallenge ? postId : data.entity?.id,
			},
		};

		if (hasVideo) {
			await fetchWrapperCreateMedia({
				entityId: postId,
				entityType: 'POST',
				mediaType: 'VIDEO',
				format: 'HLS',
				rawFormat: 'HLS',
				url: valuesToSubmit.entity?.classNum,
				rawUrl: valuesToSubmit.entity?.classNum,
				order: 0,
				status: 'ACTIVE',
			});
		} else {
			await uploadMedias({
				medias: data.media,
				entityType: 'POST',
				entityId: postId,
			});
		}

		const res = await fetchWrapper('/v1/admin/post', {
			method: 'POST',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Post created succcessfully!!`);
			history.push('/featured-post/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updatePost = createAsyncThunk('featuredPost/updatePost', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const isChallenge = data.entity?.type === 'CHALLENGE';
	const isShorts = data.entity?.type === 'SHORTS';

	const hasVideo = isShorts || isChallenge;

	const valuesToSubmit = {
		...omitWrapper(['media'], data),
		hasVideo,
		entity: {
			...data.entity,
			id: isChallenge ? data.id : data.entity?.id,
		},
	};

	try {
		const res = await fetchWrapper('/v1/admin/post', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (hasVideo) {
			await updateMedia({
				entityId: res.data.id,
				entityType: 'POST',
				mediaType: 'VIDEO',
				format: 'HLS',
				rawFormat: 'HLS',
				url: valuesToSubmit.entity?.classNum,
				rawUrl: valuesToSubmit.entity?.classNum,
				order: 0,
				status: 'ACTIVE',
			});
		} else {
			const mediasToBeUploaded = data.media.filter((item) => !item.id);

			const alreadyUploadedMedias = difference(data.media, mediasToBeUploaded);
			await updateMedia({ entityId: res.data.id, status: 'INACTIVE' });
			await Promise.all(alreadyUploadedMedias.map((media: any) => updateMedia({ id: media.id, status: 'ACTIVE', caption: media.caption })));

			if (mediasToBeUploaded.length)
				await uploadMedias({
					medias: mediasToBeUploaded,
					entityType: 'POST',
					entityId: res.data.id,
				});
		}
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Post updated succcessfully!!`);
			history.push('/featured-post/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const deletePost = createAsyncThunk('featuredPost/deletePost', async ({ postId }: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const qp = stringifyQueryParams({ postId });

	try {
		const res = await fetchWrapper(`/v1/admin/post${qp}`, {
			method: 'DELETE',
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Post deleted succcessfully!!`);

			return {
				postId,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updatePostAndUpdateList = createAsyncThunk(
	'featuredPost/updatePostAndUpdateList',
	async ({ postId, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: postId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/post', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Post updated successfully!!`);
				return {
					postId,
					postData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
