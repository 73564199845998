import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ExperimentTable } from './components/ExperimentTable';
import { ExperimentAddEditForm } from './components/ExperimentAddEdit';

const Experiment = () => {
	return (
		<Switch>
			<Route path="/experiment/list" component={ExperimentTable} />
			<Route path="/experiment/add-edit" component={ExperimentAddEditForm} />
		</Switch>
	);
};

export default Experiment;
