/* eslint-disable indent */
import styled, { css } from 'styled-components';

const Box = styled.div.attrs((props) => ({
	className: props.className,
	style: props.customStyle || {},
	...(props.title ? { title: props.title } : {}),
}))`
	${(props) =>
		props.ellipse &&
		css`
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		`}

	${(props) =>
		props.d &&
		css`
			display: ${props.d};
		`}

    ${(props) =>
		props.wrap &&
		css`
			flex-wrap: ${props.wrap};
		`}

    ${(props) =>
		props.direction &&
		css`
			flex-direction: ${props.direction};
		`}

    ${(props) =>
		props.dir &&
		css`
			flex-direction: ${props.dir};
		`}

    ${(props) =>
		props.jc &&
		css`
			justify-content: ${props.jc};
		`}

    ${(props) =>
		props.ai &&
		css`
			align-items: ${props.ai};
		`}

    ${(props) =>
		props.as &&
		css`
			align-self: ${props.as};
		`}

    ${(props) =>
		props.stretch &&
		css`
			flex: ${props.stretch};
		`}

    ${(props) =>
		props.fg &&
		css`
			flex-grow: ${props.fg};
		`}

    ${(props) =>
		props.fb &&
		css`
			flex-basis: ${props.fb};
		`}

    ${(props) =>
		props.cursor &&
		css`
			cursor: ${props.cursor};
		`}

    ${(props) =>
		props.h &&
		css`
			height: ${props.h};
		`}

    ${(props) =>
		props.mh &&
		css`
			min-height: ${props.mh};
		`}

    ${(props) =>
		props.mxh &&
		css`
			max-height: ${props.mxh};
		`}

    ${(props) =>
		props.mw &&
		css`
			min-width: ${props.mw};
		`}

    ${(props) =>
		props.mxw &&
		css`
			max-width: ${props.mxw};
		`}

    ${(props) =>
		props.w &&
		css`
			width: ${props.w};
		`}

    ${(props) =>
		props.pos &&
		css`
			position: ${props.pos};
		`}

    ${(props) =>
		props.r &&
		css`
			right: ${props.r};
		`}

    ${(props) =>
		props.l &&
		css`
			left: ${props.l};
		`}

    ${(props) =>
		props.t &&
		css`
			top: ${props.t};
		`}

    ${(props) =>
		props.b &&
		css`
			bottom: ${props.b};
		`}

    ${(props) =>
		props.p &&
		css`
			padding: ${props.p};
		`}
    ${(props) =>
		props.pt &&
		css`
			padding-top: ${props.pt};
		`}
    ${(props) =>
		props.pr &&
		css`
			padding-right: ${props.pr};
		`}
    ${(props) =>
		props.pb &&
		css`
			padding-bottom: ${props.pb};
		`}
    ${(props) =>
		props.pl &&
		css`
			padding-left: ${props.pl};
		`}

    ${(props) =>
		props.m &&
		css`
			margin: ${props.m};
		`}
    ${(props) =>
		props.mt &&
		css`
			margin-top: ${props.mt};
		`}
    ${(props) =>
		props.mr &&
		css`
			margin-right: ${props.mr};
		`}
    ${(props) =>
		props.mb &&
		css`
			margin-bottom: ${props.mb};
		`}
    ${(props) =>
		props.ml &&
		css`
			margin-left: ${props.ml};
		`}

    ${(props) =>
		props.bg &&
		css`
			background-color: ${props.bg};
		`}

    ${(props) =>
		props.c &&
		css`
			color: ${props.c};
		`}

    ${(props) =>
		props.tl &&
		css`
			text-align: ${props.tl};
		`}

    ${(props) =>
		props.fs &&
		css`
			font-size: ${props.fs};
		`}

    ${(props) =>
		props.tt &&
		css`
			text-transform: ${props.tt};
		`}

    ${(props) =>
		props.bRad &&
		css`
			border-radius: ${props.bRad};
		`}

    ${(props) =>
		props.bor &&
		css`
			border: ${props.bor};
		`}

    ${(props) =>
		props.bl &&
		css`
			border-left: ${props.bl};
		`}

    ${(props) =>
		props.br &&
		css`
			border-right: ${props.br};
		`}

    ${(props) =>
		props.bb &&
		css`
			border-bottom: ${props.bb};
		`}

    ${(props) =>
		props.bt &&
		css`
			border-top: ${props.bt};
		`}

    ${(props) =>
		props.ws &&
		css`
			white-space: ${props.ws};
		`}

    ${(props) =>
		props.o &&
		css`
			overflow: ${props.o};
		`}

    ${(props) =>
		props.ox &&
		css`
			overflow-x: ${props.ox};
		`}

    ${(props) =>
		props.oy &&
		css`
			overflow-y: ${props.oy};
		`}

    ${(props) =>
		props.zi &&
		css`
			z-index: ${props.zi};
		`}
`;

export default Box;
