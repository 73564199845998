import Loader from 'components/Loader';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import {
	getSanitizedImageUrl,
	isNotEmptyObject,
	ObjectMaybe,
	parseQueryParams,
	showToast,
	stringifyQueryParams,
} from 'utils/commonHelpers';
import { useLocation } from 'react-router';
import { deleteExerciseQuestion } from '../actions/exercises.actions';
import { getExerciseQuestion } from '../utils/helpers';
import { MediaTypeEnum, QuestionTypesEnum, TExerciseQuestion } from '../utils/types';

const QuestionPreview = React.forwardRef((props, ref) => {
	const [popupData, setPopupData] = React.useState({ isOpen: false, questionId: null });
	const { questionId, isOpen } = popupData;

	const show = (questionId: string) => {
		if (questionId) setPopupData({ isOpen: true, questionId });
	};
	const close = () => {
		setPopupData({ isOpen: false, questionId: null });
	};

	React.useImperativeHandle(ref, () => ({
		show,
		close,
	}));
	const dispatch = useDispatch();

	const location = useLocation();
	const qp = ObjectMaybe(parseQueryParams(location.search));

	if (!questionId) return null;
	return (
		<Modal centered isOpen={isOpen} size="xl">
			<ModalBody>
				<Question questionId={questionId} />
				<Button
					onClick={() => {
						window.open(`/exercise/questions/add-edit${stringifyQueryParams({ questionId, mode: 'edit' })}`, '_blank');
						close();
					}}
				>
					Edit Question
				</Button>
				<Button
					onClick={() => {
						// eslint-disable-next-line no-restricted-globals, no-alert
						const shouldRemoveQuestion = confirm('Are you sure you want to delete this question?');
						if (shouldRemoveQuestion) {
							dispatch(deleteExerciseQuestion({ questionId, exerciseId: qp.exerciseId }));
							close();
						}
					}}
				>
					Remove question from exercise
				</Button>
				<Button onClick={close}>Close</Button>
			</ModalBody>
		</Modal>
	);
});

const LabelValue = ({ label, value, suffix }: { label: string; value: any; suffix?: any }) => (
	<h4 style={{ marginBottom: '1rem' }}>
		<span style={{ fontSize: 18, color: 'grey' }}>{label} </span>
		<p style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>{value}</p>
		<span>{suffix}</span>
	</h4>
);

const LabelImage = ({ label, src, suffix }: { label: string; src: string; suffix?: any }) => (
	<div style={{ marginBottom: '1rem' }}>
		<p style={{ fontSize: 18, color: 'grey' }}>
			{label}
			<span>{suffix}</span>
		</p>
		<img
			src={getSanitizedImageUrl(src)}
			style={{ objectFit: 'contain', borderRadius: '6px', marginBottom: '1rem', height: 125, width: '100%' }}
			title={label}
			alt={label}
		/>
	</div>
);

const Question = ({ questionId }: { questionId: string }) => {
	const [questionData, setQuestionData] = React.useState<{ exerciseQuestion?: TExerciseQuestion; error?: unknown }>(null);

	const fetchQuestionData = async (questionId: string) => {
		try {
			const data = await getExerciseQuestion({ apiParams: { questionId } });
			setQuestionData(data);
		} catch (e) {
			showToast('error', 'Error fetching question data');
		}
	};

	React.useEffect(() => {
		fetchQuestionData(questionId);
	}, [questionId]);

	if (!isNotEmptyObject(questionData?.exerciseQuestion)) return <Loader />;
	const question = questionData.exerciseQuestion;
	return (
		<>
			<LabelValue label="Question Code: " value={question.adminCode} />
			<LabelValue label="Question Id: " value={question.id} />
			<LabelValue label="Title: " value={question.title} />
			<LabelValue label="Type: " value={question.type} />
			{question.passageTitle && <LabelValue label="Passage Title: " value={question.passageTitle} />}
			{question.passageText && <LabelValue label="Passage Text: " value={question.passageText} />}
			<LabelValue label="Question Status: " value={question.status} />

			{question.descriptionImage && <LabelImage label="Description Image" src={question.descriptionImage?.mediaPath} />}
			{question.exampleImage && <LabelImage label="Example Image" src={question.exampleImage?.mediaPath} />}
			{question.descriptionVideo && <LabelValue label="Description Video Path: " value={question.descriptionVideo?.mediaPath} />}
			{question.type === QuestionTypesEnum.MCQ && (
				<>
					{question.options.map((option, index) => {
						return (
							<div>
								<LabelValue
									label={`Option: ${index + 1}`}
									value={option.text}
									suffix={option.isCorrectAnswer ? <span style={{ marginLeft: '0.6rem' }}>✅</span> : null}
								/>
								{option.media && option.media.mediaType === MediaTypeEnum.IMAGE && <LabelImage label="" src={option.media?.mediaPath} />}
							</div>
						);
					})}
				</>
			)}
		</>
	);
};

export default QuestionPreview;
