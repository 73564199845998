import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CategoryTable from './components/CategoryTable';

const Category = () => {
	return (
		<div>
			<Switch>
				<Route path="/category/list" component={CategoryTable} />
			</Switch>
		</div>
	);
};

export default Category;
