import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';
import { isNotEmptyArray } from 'utils/commonHelpers';
import CustomModal from 'reusableComponents/CustomModal';
import Box from 'reusableComponents/Box';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import VideoJsPlayer from 'reusableComponents/VideoPlayer/VideoJsPlayer';
import { getPickedPostAnalytics } from '../actions/userPost.actions';

const customStyles = `
	width: 30rem;
	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}
	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const PickedPostModal = ({ initialValues, closeModal }) => {
	const dispatch = useDispatch();

	const [pickedPosts, setPickedPosts] = useState([]);

	const userPostState = useSelector(({ userPost }) => ({
		postAnalytics: userPost.postAnalytics,
	}));

	useEffect(() => {
		dispatch(getPickedPostAnalytics());
	}, []);

	useEffect(() => {
		if (isNotEmptyArray(userPostState?.postAnalytics?.pickedPosts)) {
			setPickedPosts(sortBy(userPostState?.postAnalytics?.pickedPosts, ['count']).reverse());
		}
	}, [userPostState?.postAnalytics?.pickedPosts]);

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<Box cursor="pointer" tl="right" p="5px">
				<span
					onClick={closeModal}
					className="sidebar__link-icon lnr lnr-cross"
					style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
				/>
			</Box>
			<VideoJsPlayer videoUrl={initialValues} />
		</CustomModal>
	);
};

export default PickedPostModal;
