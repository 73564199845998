/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

const Sidebar = ({ changeMobileSidebarVisibility, sidebar }) => {
	return (
		<div className={`sidebar${sidebar.show ? ' sidebar--show' : ''}${sidebar.collapse ? ' sidebar--collapse' : ''}`}>
			<button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
			<Scrollbar className="sidebar__scroll scroll">
				<div className="sidebar__wrapper sidebar__wrapper--desktop">
					<SidebarContent onClick={() => null} />
				</div>
				<div className="sidebar__wrapper sidebar__wrapper--mobile">
					<SidebarContent onClick={changeMobileSidebarVisibility} />
				</div>
			</Scrollbar>
		</div>
	);
};

Sidebar.propTypes = {
	sidebar: SidebarProps.isRequired,
	changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
