import { createSlice } from '@reduxjs/toolkit';

const faqSlice = createSlice({
	name: 'faq',
	initialState: {
		loading: false,
		faqs: [],
		faqTypes: [],
		faq: {},
		faqId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
		},
	},
	reducers: {
		setLoading: (state) => {
			state.loading = true;
		},
		resetLoading: (state) => {
			state.loading = false;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getFaqs: () => {},
		getFaqsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.faqs = action.payload.faqs;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getFaq: () => {},
		getFaqSuccess: (state, action) => {
			state.faq = action.payload.faq;
		},
		getFaqTypes: () => {},
		getFaqTypesSuccess: (state, action) => {
			state.faqTypes = action.payload.faqTypes;
		},
		createFaqType: () => {},
		createFaqTypeSuccess: (state, action) => {
			state.faqTypes = [...state.faqTypes, action.payload.faqType];
		},
		getFaqId: () => {},
		getFaqIdSuccess: (state, action) => {
			state.faqId = action.payload.faqId;
		},
		faqOnSubmit: () => {},
		faqOnSubmitSuccess: (state, action) => {
			state.faqs[action.payload.pos] = action.payload.faq;
		},
		setIsSubmitting: (state) => {
			state.isSubmitting = true;
		},
		resetIsSubmitting: (state) => {
			state.isSubmitting = false;
		},
	},
});

export const {
	setLoading,
	resetLoading,
	setApiParams,
	getFaqs,
	getFaqsSuccess,
	getFaq,
	getFaqSuccess,
	getFaqTypes,
	getFaqTypesSuccess,
	createFaqType,
	createFaqTypeSuccess,
	getFaqId,
	getFaqIdSuccess,
	faqOnSubmit,
	faqOnSubmitSuccess,
	setIsSubmitting,
	resetIsSubmitting,
} = faqSlice.actions;

export default faqSlice.reducer;
