import React from 'react';
import { getSanitizedImageUrl, showToast } from 'utils/commonHelpers';
import style from './styles.module.scss';
import { QuestionTypesEnum } from './types';

export const questionDisplayFormatter = (question: any) => {
	try {
		switch (question.type) {
			case QuestionTypesEnum.MCQ:
				return formatMCQQuestion(question);
			case QuestionTypesEnum.ANAGRAM:
				return formatAnagramQuestion(question);
			case QuestionTypesEnum.CONVERSATION:
			case QuestionTypesEnum.CONTENT_ONLY:
			case QuestionTypesEnum.READ_ALONG:
				return (
					<>
						<p className={style.questionMainTitle}>{question.title}</p>
					</>
				);
			default:
				return <p className={style.questionMainTitle}>{question.title}</p>;
		}
	} catch (error) {
		showToast('error', error.message);
		return question.title;
	}
};

const ImageHook = ({ imageUrl }) => {
	return (
		<div className={style.imageHookContainer}>
			<img src={imageUrl} alt="hook" />
		</div>
	);
};

const formatMCQQuestion = (question) => {
	return (
		<>
			<p className={style.questionMainTitle}>{question.title}</p>

			<br />
			<div className={style.flexContainer}>
				{question.options.map((option: any) => {
					return (
						<div
							style={{
								backgroundColor: option.isCorrectAnswer ? '#dfffc2' : '#fff',
							}}
							className={style.optionContainer}
							key={option.text}
						>
							<p>{option.text}</p>
						</div>
					);
				})}
			</div>
		</>
	);
};

const formatAnagramQuestion = (question) => {
	return (
		<>
			<p className={style.questionMainTitle}>{question.title}</p>

			<br />
			<div className={style.flexContainer}>
				{question.blocks.map((block: any) => {
					return <div className={style.blockContainer}>{block.text}</div>;
				})}
			</div>
		</>
	);
};
