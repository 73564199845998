import { createSlice } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import { isNotEmptyArray } from 'utils/commonHelpers';
import { assigneesList } from './helpers';
/* eslint-disable import/no-cycle */
import { updateThreadStatus, updateThreadAssignee, getMessages, sendMessage, getAllWhatsappChatAssignees } from './whatsappchat.actions';

const staticAssigneeList = [
	{ label: 'Open - Unassigned', value: { threadStatus: 'OPEN' } },
	{ label: 'Open - BOT', value: { threadStatus: 'ASSIGNED_TO_BOT' } },
	{ label: 'Closed', value: { threadStatus: 'CLOSED' } },
	{ label: 'Closed by BOT', value: { threadStatus: 'CLOSED_BY_BOT' } },
	{ label: 'Dead', value: { threadStatus: 'DEAD' } },
	...assigneesList.map(({ name, email }) => ({ label: `Open - ${name}`, value: { threadStatus: 'OPEN', assignedTo: email } })),
];

const whatsappChatSlice = createSlice({
	name: 'whatsappChat',
	initialState: {
		isConversationsLoading: false,
		isMessagesLoading: false,
		conversations: [],
		messages: [],
		assignees: [],
		initialMessageLoad: null,
		totalConversations: 0,
		showLoadMoreConversations: true,
		showLoadMoreMoreMessages: true,
		disableSend: false,
	},
	reducers: {
		setConversationsLoading: (state, action) => {
			state.isConversationsLoading = action.payload.isConversationsLoading;
		},
		getAllWhatsappConversations: () => {},
		getAllWhatsappConversationsSuccess: (state, action) => {
			state.isConversationsLoading = false;
			state.conversations = action.payload.isLoadMoreCall
				? [...state.conversations, ...action.payload.conversations]
				: action.payload.conversations;
			state.totalConversations = action.payload.totalConversations;
			state.showLoadMoreConversations = action.payload.totalConversations > state.conversations.length;
		},
		setMessagesLoading: (state, action) => {
			state.isMessagesLoading = action.payload.isMessagesLoading;
		},
		resetMessages: (state) => {
			state.isMessagesLoading = true;
			state.messages = [];
			state.disableSend = false;
		},
		addMessage: (state) => {
			state.disableSend = true;
		},
	},
	extraReducers: {
		[updateThreadStatus.fulfilled]: (state, action) => {
			if (!action.payload) return;
			const {
				payload: { mobile },
			} = action;
			state.conversations = state.conversations.filter((conv) => conv.mobile !== mobile);
		},
		[updateThreadAssignee.fulfilled]: (state, action) => {
			const {
				payload: { mobile },
			} = action;
			state.conversations = state.conversations.filter((conv) => conv.mobile !== mobile);
		},
		[getMessages.fulfilled]: (state, action) => {
			state.isMessagesLoading = false;
			if (action?.payload?.messages) {
				state.messages = [...action.payload.messages.reverse(), ...state.messages];
				state.showLoadMoreMoreMessages = action.payload.messages.length >= action.payload.limit;
				state.initialMessageLoad = action.payload.initialMessageLoad;
			}
		},
		[getAllWhatsappChatAssignees.fulfilled]: (state, action) => {
			state.assignees = staticAssigneeList;
			if (isNotEmptyArray(action?.payload.assignees)) {
				const newAssignees = difference(
					action.payload.assignees.map(({ adminDetails: { email } }) => email[0]),
					assigneesList.map(({ email }) => email)
				);

				state.assignees = [
					...state.assignees,
					...action.payload.assignees
						.filter(({ adminDetails: { email } }) => newAssignees.includes(email[0]))
						.map(({ adminDetails: { fullName, email } }) => ({
							label: `Open - ${fullName}`,
							value: { threadStatus: 'OPEN', assignedTo: email[0] },
						})),
				];
			}
		},
		[sendMessage.fulfilled]: (state, action) => {
			state.disableSend = false;
			if (action?.payload?.message) state.messages = [...state.messages, action.payload.message];
		},
	},
});

export const {
	setConversationsLoading,
	getAllWhatsappConversations,
	getAllWhatsappConversationsSuccess,
	setMessagesLoading,
	resetMessages,
	addMessage,
} = whatsappChatSlice.actions;

export default whatsappChatSlice.reducer;
