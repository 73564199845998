import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams } from 'utils/commonHelpers';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { TableCoverPicture } from 'components/TableCoverPicture';
import history from '../../App/history';
import { setApiParams } from '../dashboardSlice';
import { getBanners, updateBannerAndUpdateList } from '../actions/dashboard.actions';

const Actions = ({ id, priority, status }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() =>
						dispatch(
							updateBannerAndUpdateList({
								bannerId: id,
								priority: priority + 1,
							})
						)
					}
				>
					Move Up Priority
				</DropdownItem>
				<DropdownItem
					onClick={() =>
						dispatch(
							updateBannerAndUpdateList({
								bannerId: id,
								priority: priority - 1,
							})
						)
					}
				>
					Move Down Priority
				</DropdownItem>
				<DropdownItem onClick={() => history.push(`/dashboard/add-edit${stringifyQueryParams({ id })}`)}>Edit Banner</DropdownItem>
				<DropdownItem
					onClick={() =>
						dispatch(
							updateBannerAndUpdateList({
								bannerId: id,
								status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
							})
						)
					}
				>
					Mark {status === 'ACTIVE' ? 'Inactive' : 'Active'}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ banners }) {
	return banners.map(({ id, status, priority, showOn, bannerTitle, expiryAt, bannerThumbnail }) => ({
		id,
		/* eslint-disable no-nested-ternary */
		backgroundColor: status === 'ACTIVE' ? (expiryAt ? (new Date() <= new Date(expiryAt) ? 'white' : 'lightgrey') : 'white') : 'lightgrey',
		bannerThumbnail: <TableCoverPicture {...{ coverPicture: bannerThumbnail, title: bannerTitle }} />,
		bannerTitle,
		showOn: isNotEmptyArray(showOn) ? showOn.join(', ') : '---',
		priority,
		expiryAt: expiryAt ? format(new Date(expiryAt), 'dd-MM-yyyy HH:mm') : '---',
		/* eslint-disable no-nested-ternary */
		status: expiryAt ? (new Date() <= new Date(expiryAt) ? status : 'EXPIRED') : status,
		actions: (
			<Actions
				{...{
					id,
					priority,
					status,
				}}
			/>
		),
	}));
}

const heads = [
	{ accessor: 'bannerThumbnail', Header: 'Thumbnail' },
	{ accessor: 'bannerTitle', Header: 'Title' },
	{ accessor: 'showOn', Header: 'Show On' },
	{ accessor: 'expiryAt', Header: 'Expiry At' },
	{ accessor: 'priority', Header: 'Priority' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const DashboardTable = () => {
	const dashboardState = useSelector(({ dashboard }) => ({
		loading: dashboard.loading,
		banners: dashboard.banners,
		banner: dashboard.banner,
		pageNo: dashboard.pageNo,
		total: dashboard.total,
		apiParams: dashboard.apiParams,
	}));

	const dispatch = useDispatch();

	const [dashboardRows, setDashboardRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	React.useEffect(() => {
		dispatch(getBanners(dashboardState.apiParams));
	}, []);

	React.useEffect(() => {
		setPageOfItems(dashboardState.pageNo);
	}, [dashboardState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(dashboardState.banners)) {
			const structuredRows = createRows({ banners: dashboardState.banners });
			setDashboardRows(structuredRows);
		} else {
			setDashboardRows([]);
		}
	}, [dashboardState.banners]);

	React.useEffect(() => {
		dispatch(getBanners({ ...dashboardState.apiParams }));
	}, [dashboardState.apiParams]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = dashboardState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', dashboardState),
					skip: accessDeepObject('apiParams.limit', dashboardState) * (pageOfItems - 1),
				})
			);
		}
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8} className="mb-3">
							<h3>Dashboard Banners Listing</h3>
						</Col>
						<Col style={{ textAlign: 'left' }} sm={{ size: 3, offset: 1 }}>
							<Button
								style={{ marginBottom: 0 }}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/dashboard/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(dashboardRows) && (
						<>
							<DataPaginationTable heads={heads} rows={dashboardRows} onSort={onSort} />
							<Pagination
								itemsCount={dashboardState.total}
								itemsToShow={accessDeepObject('apiParams.limit', dashboardState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{dashboardState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default DashboardTable;
