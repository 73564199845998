import React, { useState } from 'react';
import { Card, CardBody, Col, Button, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, showToast, stringifyQueryParams, ArrayMaybe } from 'utils/commonHelpers';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import If from 'reusableComponents/If';
import Filter from 'reusableComponents/Filter';
import { SelectField } from 'reusableComponents/Form/Select';
import { TableCoverPicture } from 'components/TableCoverPicture';
import { format } from 'date-fns';
import { languageList } from 'utils/constants';
import history from '../../../App/history';
import { setApiParams } from '../../programSlice';
import { updateProgramAndUpdateList, fetchPrograms } from '../../actions/program.actions';
import { canProgramBeMarkedActive } from '../../helpers';

export const statusList = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
	{ label: 'Draft', value: 'DRAFT' },
	{ label: 'Completed', value: 'COMPLETED' },
	{ label: 'Discontinued', value: 'DISCONTINUED' },
];

export const programTypeList = [
	{ label: 'DIY', value: 'DIY' },
	{ label: 'Storytime', value: 'STORYTIME' },
	{ label: 'Regular', value: 'REGULAR' },
	{ label: 'Podcast', value: 'PODCAST' },
	{ label: 'Competition', value: 'COMPETITION' },
];

export const continuityTypeList = [
	{ label: 'Continuous', value: 'CONTINUOUS' },
	{ label: 'Fixed', value: 'FIXED' },
];

const progressionTypeList = [
	{ label: 'Progressive', value: 'PROGRESSIVE' },
	{ label: 'Non-Progressive', value: 'NON_PROGRESSIVE' },
];

const customStylesMargin = {
	marginBottom: '0px',
};

function handleBatchCreate({ status, programId, programTitle, modules = [], continuity }) {
	if (status !== 'ACTIVE') {
		return showToast('error', 'Program status must be active to create batches');
	}

	const numberOfClassesInProgram = modules.reduce((acc, curr) => acc + curr.classes.length, 0);

	if (numberOfClassesInProgram < 1) return showToast('error', 'Program must have at least one class to be able to create batches');

	// eslint-disable-next-line no-alert
	const shouldRedirectToCreateBatch = window.confirm(
		"You won't be able to edit program after batch creation. Do you still want to create a batch?"
	);
	if (shouldRedirectToCreateBatch) {
		history.push(`/batch/add-edit${stringifyQueryParams({ programId, programTitle, continuity })}`);
	}
}

const Actions = ({
	programId,
	programTitle,
	isFeatured,
	mobileCoverPic,
	featuredCoverUrl,
	isHot,
	index,
	status,
	editStatus,
	modules,
	programType,
	continuity,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem disabled={editStatus === 'NO_EDIT'} onClick={() => history.push(`/program/add-edit?programId=${programId}`)}>
					Edit Program
				</DropdownItem>
				<DropdownItem onClick={() => history.push(`/batch/list?query=${programId}`)}>View all Batches</DropdownItem>
				<DropdownItem onClick={() => history.push(`/class/list?programId=${programId}&programTitle=${programTitle}`)}>
					View all Classes
				</DropdownItem>
				<DropdownItem
					disabled={!(editStatus === 'ALL_EDIT')}
					onClick={() => {
						if (canProgramBeMarkedActive(modules, programType, editStatus)) {
							dispatch(updateProgramAndUpdateList({ programId, programIndex: index, status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }));
						} else {
							showToast('error', 'The number of classes in module and the number of classes created do not match');
						}
					}}
				>
					{status === 'ACTIVE' ? 'Mark as Inactive' : 'Mark as Active'}
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						if (!(mobileCoverPic && featuredCoverUrl) && !isFeatured)
							showToast('error', 'Upload mobileCoverPic & featured banner to mark program as Featured');
						else dispatch(updateProgramAndUpdateList({ programId, programIndex: index, isFeatured: !isFeatured }));
					}}
				>
					{isFeatured ? 'Remove from featured' : 'Mark as Featured'}
				</DropdownItem>
				<DropdownItem onClick={() => dispatch(updateProgramAndUpdateList({ programId, programIndex: index, isHot: !isHot }))}>
					{isHot ? 'Remove from Hot' : 'Mark as Hot'}
				</DropdownItem>
				<DropdownItem divider />
				<DropdownItem onClick={() => history.push(`/class/add-edit?programId=${programId}&programTitle=${programTitle}`)} disabled={false}>
					Add Class
				</DropdownItem>
				<DropdownItem
					onClick={() => handleBatchCreate({ status, programId, programTitle, modules, continuity })}
					disabled={status === 'COMPLETED'}
				>
					Create Batch
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ programs }) {
	return programs.map(
		(
			{
				id,
				coverPicture,
				title,
				programType,
				mobileCoverPic,
				featuredCoverUrl,
				progression,
				continuity,
				ageRange,
				mentor,
				skills,
				isFeatured,
				isHot,
				status,
				programEditStatus,
				modules,
				createdAt,
			},
			index
		) => ({
			id,
			coverPicture: <TableCoverPicture {...{ coverPicture, title }} />,
			title,
			type: `${programType} | ${progression} | ${continuity}`,
			ageRange: `${ageRange?.min}-${ageRange?.max} yrs`,
			mentor: mentor?.fullName ?? '------',
			skills:
				ArrayMaybe(skills)
					.map((skill) => skill.title)
					.join(', ') ?? '------',
			status,
			createdAt: createdAt ? format(new Date(createdAt), 'dd-MM-yyyy HH:mm') : '---',
			actions: (
				<Actions
					{...{
						programId: id,
						mobileCoverPic,
						featuredCoverUrl,
						isFeatured,
						isHot,
						index,
						programTitle: title,
						status,
						editStatus: programEditStatus,
						modules,
						programType,
						continuity,
					}}
				/>
			),
		})
	);
}

const heads = [
	{ accessor: 'coverPicture', Header: 'Cover' },
	{ accessor: 'title', Header: 'Title' },
	{ accessor: 'type', Header: 'Type' },
	{ accessor: 'ageRange', Header: 'Age Range' },
	{ accessor: 'mentor', Header: 'Mentor' },
	{ accessor: 'skills', Header: 'Skills' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const ProgramTable = ({ history }) => {
	const programState = useSelector(({ program }) => ({
		loading: program.loading,
		programs: program.programs,
		pageNo: program.pageNo,
		total: program.total,
		apiParams: program.apiParams,
		isSubmitting: program.isSubmitting,
	}));

	const { programs, isSubmitting, apiParams } = programState;
	const { sortKey, sortOrder, limit, skip, query, status, programType, continuity, progression, language } = apiParams;

	const dispatch = useDispatch();

	const [programRows, setProgramRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState(programState.apiParams.query);

	React.useEffect(() => {
		dispatch(setApiParams({ query: searchTerm, status: 'ACTIVE', limit: 20, skip: 0, programType, continuity, progression, language }));
		dispatch(
			fetchPrograms({
				sortKey,
				sortOrder,
				limit,
				skip,
				query: searchTerm,
				status: 'ACTIVE',
				programType,
				continuity,
				progression,
				language,
			})
		);
	}, []);

	React.useEffect(() => {
		setPageOfItems(programState.pageNo);
	}, [programState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(programs)) {
			const structuredRows = createRows({ programs });
			setProgramRows(structuredRows);
		} else {
			setProgramRows([]);
		}
	}, [programs]);

	const onChangePage = (itemsPage) => {
		const {
			apiParams: { sortKey, sortOrder, limit },
		} = programState;
		const skip = limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setApiParams({ sortKey, sortOrder, limit, skip, query, status, programType, continuity, progression, language }));
			dispatch(fetchPrograms({ sortKey, sortOrder, limit, skip, query, status, programType, continuity, progression, language }));
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			const sortOrder = sortDirection === 'ASC' ? '1' : '-1';
			const limit = programState?.apiParams?.limit;
			const skip = programState?.apiParams?.skip * (pageOfItems - 1);

			dispatch(
				setApiParams({ query, sortKey: sortColumn, sortOrder, limit, skip, status, programType, continuity, progression, language })
			);
			dispatch(
				fetchPrograms({ sortKey: sortColumn, sortOrder, limit, skip, query, status, programType, continuity, progression, language })
			);
		}
	};

	const onSearch = (e) => {
		e?.preventDefault?.();
		dispatch(setApiParams({ query: searchTerm, status, programType, continuity, progression, language, limit: 20, skip: 0 }));
		dispatch(
			fetchPrograms({ query: searchTerm, sortKey, sortOrder, status, programType, continuity, progression, language, limit: 20, skip: 0 })
		);
	};

	const onFilterChange = (filterName, val) => {
		dispatch(
			setApiParams({
				...{ programType, continuity, status, progression, language },
				...{ query: searchTerm, limit: 20, skip: 0, [filterName]: filterName === 'status' ? val || 'ACTIVE' : val },
			})
		);
		dispatch(
			fetchPrograms({
				...{ programType, continuity, status, progression, language },
				...{ sortKey, sortOrder, limit, skip, query: searchTerm, [filterName]: filterName === 'status' ? val || 'ACTIVE' : val },
			})
		);
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Updating Program...">
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-2 rounded">
							<Col sm={9} className="mb-3">
								<h3>Programs Listing</h3>
							</Col>
							<Col sm={4}>
								<Filter
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onSearch={onSearch}
									placeholder="Search by Program ID, Title & Mentor Name"
								/>
							</Col>
							<Col sm={3}>
								<SelectField
									clearable
									defaultValue={{ label: 'Active', value: 'ACTIVE' }}
									placeholder="Status"
									name="status"
									options={statusList}
									onChange={(val) => onFilterChange('status', val)}
								/>
							</Col>
							<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
								<Button
									style={customStylesMargin}
									size="sm"
									color="primary"
									onClick={() => {
										history.push('/program/add-edit');
									}}
								>
									Add
								</Button>
							</Col>
						</Row>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={2}>
								<SelectField
									clearable
									placeholder="Program Type"
									name="programType"
									options={programTypeList}
									onChange={(val) => onFilterChange('programType', val)}
								/>
							</Col>
							<Col sm={2}>
								<SelectField
									clearable
									placeholder="Continuity"
									name="continuity"
									options={continuityTypeList}
									onChange={(val) => onFilterChange('continuity', val)}
								/>
							</Col>
							<Col sm={2}>
								<SelectField
									clearable
									placeholder="Progression"
									name="progression"
									options={progressionTypeList}
									onChange={(val) => onFilterChange('progression', val)}
								/>
							</Col>
							<Col sm={2}>
								<SelectField
									clearable
									placeholder="Language"
									name="language"
									options={languageList}
									onChange={(val) => onFilterChange('language', val)}
								/>
							</Col>
						</Row>
						<If
							condition={isNotEmptyArray(programRows)}
							then={
								<>
									<DataPaginationTable heads={heads} rows={programRows} onSort={onSort} />
									<Pagination
										itemsCount={programState.total}
										itemsToShow={accessDeepObject('apiParams.limit', programState)}
										pageOfItems={pageOfItems}
										onChangePage={onChangePage}
									/>
								</>
							}
							else={<div className="">No data</div>}
						/>
					</CardBody>
				</Card>
			</Col>
		</LoadingOverlay>
	);
};
