import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SuccessStoryTable from './components/SuccessStoryTable';
import SuccessStoryAddEdit from './components/SuccessStoryAddEdit';

const SuccessStory = () => {
	return (
		<div>
			<Switch>
				<Route path="/success-stories/list" component={SuccessStoryTable} />
				<Route path="/success-stories/add-edit" component={SuccessStoryAddEdit} />
			</Switch>
		</div>
	);
};

export default SuccessStory;
