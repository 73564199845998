import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { toggleLoading, getBatchSuccess } from '../batchSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';
import { BatchValuesFromDatabase } from '../components/BatchAddEditForm';

export function* getBatchSaga({ payload }: ReturnType<() => { type: string; payload: BatchValuesFromDatabase }>) {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/batch?id=${payload.id}`);
	if (res.status === 200) {
		yield put(actionSpreader(getBatchSuccess.type, { batch: res.data }));
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
