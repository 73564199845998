/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';

import { getSanitizedVideoUrl } from 'containers/Class/helpers';
import Box from 'reusableComponents/Box';
import { getPlayerInstance } from 'reusableComponents/VideoPlayer/helpers';
import { ArrayMaybe } from 'utils/commonHelpers';
import PollModal from '../PollModal';
import useRefCallback from '../PollsForm/hooks/useRefCallback';

let player;

const pollFetchThreshold = 5; // 5 seconds
let fetchPollTimeout;
let showPollTimeout;

const TestPolls = (props) => {
	const { ref: videoRef, setRef: setVideoRef, isRefInitialized } = useRefCallback();
	const [showPollModal, setShowPollModal] = useState(false);

	const setPlaybackPosition = async (poll) => {
		try {
			player.currentTime(poll.triggerTime - pollFetchThreshold);
			const isPaused = player.paused();
			if (isPaused) player.play();
			if (props.getPoll) {
				// fetch poll
				props.getPoll(poll.id);
			}
			// show poll modal after pollFetchThreshold seconds
			showPollTimeout = setTimeout(() => {
				setShowPollModal(true);
			}, pollFetchThreshold * 1000);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (videoRef.current && props.videoUrl) {
			const { videoUrl } = props;
			player = getPlayerInstance({ videoTagId: 'my-player' });
			player.src({
				type: 'application/x-mpegURL',
				src: getSanitizedVideoUrl(videoUrl),
			});
		}
	}, [isRefInitialized, props?.videoUrl]);

	return (
		<>
			{showPollModal && <PollModal closeModal={() => setShowPollModal(false)} poll={props.poll} isShortsPoll={props.isShortsPoll} />}
			<Card>
				<CardBody>
					<Box w="100%">
						<Row>
							<Col md="9">
								<h3>Test Polls</h3>
							</Col>
						</Row>
					</Box>
				</CardBody>
				<Box pos="relative" t="50px" l="50%" w="50%" zi="500" customStyle={{ transform: 'translateX(-50%)' }}>
					<video className="video-js" style={{ width: 600, height: 400 }} id="my-player" ref={setVideoRef} playsInline />
					{ArrayMaybe(props.polls).map((poll, index) => (
						<Button
							onClick={() => {
								clearTimeout(fetchPollTimeout);
								clearTimeout(showPollTimeout);
								setPlaybackPosition(poll);
							}}
						>
							Poll {index + 1}
						</Button>
					))}
				</Box>
			</Card>
		</>
	);
};

export default TestPolls;
