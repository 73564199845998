import { select, call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader, stringifyQueryParams } from '../../../utils/commonHelpers';
import { getFaqsSuccess, setLoading, resetLoading } from '../faqSlice';
import { setError } from '../../App/globalSlice';

export function* getFaqsSaga({ payload }) {
	yield put(actionSpreader(setLoading.type));

	const apiParams = yield select((state) => state.faq.apiParams);

	const qp = stringifyQueryParams({ ...payload, ...apiParams });

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/faqs${qp}`);
	if (res.status === 200) {
		yield put(actionSpreader(getFaqsSuccess.type, { faqs: res.data.faqs, total: res.data.total }));
		yield put(actionSpreader(resetLoading.type));
	} else {
		yield put(actionSpreader(resetLoading.type));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
