import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AboutTable from './components/AboutTable';
import AboutAddEdit from './components/AboutAddEdit';

const About = () => {
	return (
		<div>
			<Switch>
				<Route path="/about-us/list" component={AboutTable} />
				<Route path="/about-us/add-edit" component={AboutAddEdit} />
			</Switch>
		</div>
	);
};

export default About;
