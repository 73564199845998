import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GalleryList from './components/GalleryList';
import GalleryEdit from './components/GalleryEdit';

const Gallery = () => {
	return (
		<div>
			<Switch>
				<Route path="/gallery/list" component={GalleryList} />
				<Route path="/gallery/edit" component={GalleryEdit} />
			</Switch>
		</div>
	);
};

export default Gallery;
