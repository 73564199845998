import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

export default class DropZoneField extends PureComponent {
	// static propTypes = {
	// 	name: PropTypes.string.isRequired,
	// 	onChange: PropTypes.func.isRequired,
	// 	customHeight: PropTypes.bool,
	// 	withCaption: PropTypes.bool,
	// 	value: PropTypes.oneOfType([
	// 		PropTypes.string,
	// 		PropTypes.arrayOf(
	// 			PropTypes.shape({
	// 				name: PropTypes.string,
	// 			})
	// 		),
	// 	]).isRequired,
	// };

	static defaultProps = {
		customHeight: false,
	};

	constructor() {
		super();
		this.state = {};
		this.onDrop = this.onDrop.bind(this);
	}

	onDrop(file) {
		const { onDrop, onChange } = this.props;

		if (onDrop) onDrop(file);
		else
			onChange(
				this.props.withCaption
					? [
							{
								photo: file.map((fl) =>
									Object.assign(fl, {
										preview: URL.createObjectURL(fl),
									})
								),
								caption: '',
							},
					  ]
					: file.map((fl) =>
							Object.assign(fl, {
								preview: URL.createObjectURL(fl),
							})
					  )
			);
	}

	removeFile(index, e) {
		const { onChange, value } = this.props;
		e.preventDefault();
		onChange(value.filter((val, i) => i !== index));
	}

	render() {
		const { value, customHeight, name, accept, customCaptionLabel, label } = this.props;
		const files = value;
		return (
			<div>
				{label && <span className="form__form-group-label">{label}</span>}
				<div
					className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}
					style={{
						...(this.props.height && { height: this.props.height }),
						...(this.props.width && { width: this.props.width }),
					}}
				>
					<Dropzone
						accept={accept ?? 'image/jpeg, image/png'}
						name={name}
						multiple={false}
						onDrop={(fileToUpload) => {
							this.onDrop(fileToUpload);
						}}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()} className="dropzone__input">
								{(!files || files.length === 0) && (
									<div className="dropzone__drop-here">
										<span className="lnr lnr-upload" /> Drop file here to upload
									</div>
								)}
								<input {...getInputProps()} />
							</div>
						)}
					</Dropzone>
					{files && Array.isArray(files) && files.length > 0 && (
						<aside className="dropzone__img">
							{this.props.withCaption ? (
								<RenderPreviewDropZoneField {...(files[0]?.photo ?? {})} type={files[0]?.photo?.type} />
							) : (
								<RenderPreviewDropZoneField {...files[0]} type={files[0]?.type} />
							)}
							<p className="dropzone__img-name">{files[0]?.name}</p>
							<button className="dropzone__img-button dropzone__img-delete" type="button" onClick={(e) => this.removeFile(0, e)}>
								Remove
							</button>
						</aside>
					)}
				</div>
				{this.props.withCaption && (
					<div className="form__form-group-input-wrap mt-1 mb-3">
						<span className="form__form-group-label">{customCaptionLabel ?? 'Caption'}</span>
						<input
							placeholder="Max 1000 characters"
							maxLength={1000}
							value={files && files[0] ? files[0].caption : ''}
							onChange={(e) => this.props.onChange([{ ...files[0], caption: e.target.value }])}
						/>
					</div>
				)}
			</div>
		);
	}
}

export const renderDropZoneField = (props) => {
	const { input, customHeight } = props;
	return <DropZoneField {...input} customHeight={customHeight} />;
};

export const RenderPreviewDropZoneField = (props) => {
	return props.type === 'application/pdf' ? (
		<object style={{ width: '100%', height: '100%' }} data={props.preview} type={props.type}>
			<p>
				Preview PDF: <a href={props.preview}>here</a>
			</p>
		</object>
	) : (
		<img src={props.preview} alt="drop-img" style={{ maxHeight: '100%', height: 'auto', maxWidth: '100%' }} />
	);
};

renderDropZoneField.propTypes = {
	input: PropTypes.shape({
		name: PropTypes.string,
		onChange: PropTypes.func,
	}).isRequired,
	customHeight: PropTypes.bool,
};

renderDropZoneField.defaultProps = {
	customHeight: false,
};
