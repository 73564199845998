import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import theme from '../../../../utils/theme';

import CustomModal from '../../../../reusableComponents/CustomModal';
import Box from '../../../../reusableComponents/Box';

const StyledModal = styled.div`
	height: auto;
	width: 30rem;
	padding: 1rem;
`;

const btnStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const CsvModal = ({ handleSubmit, closeModal }) => {
	const [csvFiles, setCsvFiles] = useState([]);

	return (
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					<Box w="85%" ellipse fs="1.4rem">
						Upload CSV Files
					</Box>
					<Box onClick={() => closeModal()} cursor="pointer">
						<span className="sidebar__link-icon lnr lnr-cross" />
					</Box>
				</Box>
				<Box>
					<input
						className="my-2"
						type="file"
						id="files"
						name="files"
						accept=".csv"
						multiple
						onChange={(e) => setCsvFiles(Array.from(e.target.files))}
					/>
					{csvFiles.length > 0 && (
						<Box>
							<p className="font-weight-bold">Selected Files:</p>
							{csvFiles.map((file) => (
								<p>{file.name}</p>
							))}
						</Box>
					)}

					<Box tl="right" w="100%">
						<Button color="secondary" size="md" style={btnStyles} onClick={() => closeModal()}>
							Cancel
						</Button>
						<Button
							color="primary"
							size="md"
							style={btnStyles}
							onClick={() => handleSubmit(csvFiles)}
							disabled={!csvFiles.length}
							type="button"
						>
							Upload
						</Button>
					</Box>
				</Box>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(CsvModal);
