import { createSlice } from '@reduxjs/toolkit';

const mentorSlice = createSlice({
	name: 'mentor',
	initialState: {
		loading: false,
		mentors: [],
		mentor: {},
		mentorId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getMentors: () => {},
		getMentorsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.mentors = action.payload.mentors;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getMentor: () => {},
		getMentorSuccess: (state, action) => {
			state.mentor = action.payload.mentor;
		},
		getMentorId: () => {},
		getMentorIdSuccess: (state, action) => {
			state.mentorId = action.payload.mentorId;
		},
		mentorOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		setMentorStatus: () => {},
		setMentorStatusSuccess: (state, action) => {
			state.mentors[action.payload.pos].status = action.payload.status;
		},
		setMentorFeatured: () => {},
		setMentorFeaturedSuccess: (state, action) => {
			state.mentors[action.payload.pos].isFeatured = action.payload.isFeatured;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getMentors,
	getMentorsSuccess,
	getMentor,
	getMentorSuccess,
	getMentorIdSuccess,
	getMentorId,
	mentorOnSubmit,
	toggleIsSubmitting,
	setMentorStatus,
	setMentorStatusSuccess,
	setMentorFeatured,
	setMentorFeaturedSuccess,
} = mentorSlice.actions;

export default mentorSlice.reducer;
