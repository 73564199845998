import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper, uploadMedias } from 'utils/fetchWrapper';
import history from '../../App/history';

export const fetchQuizes = createAsyncThunk<any, any>('quiz/fetchQuizes', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper('/v1/admin/quiz/get-quizes', { method: 'POST', body: apiParams });

		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return response.data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const createQuiz = createAsyncThunk<any, any>('quiz/createQuiz', async (params, { dispatch }) => {
	const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

	try {
		const [uploadedMediaResponse] = await uploadMedias({
			medias: [{ file: params.coverImageUrl[0] }],
			entityId,
			entityType: 'QUIZ_COVER_IMAGE',
		});

		if (uploadedMediaResponse?.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: uploadedMediaResponse?.data } });
		}

		const {
			data: { id: mediaId, rawUrl: coverImageUrl },
		} = uploadedMediaResponse;

		const response = await fetchWrapper('/v1/admin/quiz/create-quiz', { method: 'POST', body: { ...params, mediaId, coverImageUrl } });

		if (response.status !== 200 || !response.data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			showToast('success', `👍️ Quiz created succcessfully!!`);
			history.push('/activity/list');
		}

		return response.data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const updateQuiz = createAsyncThunk<any, any>('quiz/updateQuiz', async (params, { dispatch }) => {
	const isUploadingNewMedia = params.coverImageUrl && Array.isArray(params.coverImageUrl) && typeof params.coverImageUrl[0].id !== 'string';

	try {
		let mediaId: string;
		let coverImageUrl: any = !isUploadingNewMedia ? params.coverImageUrl[0]?.url : null;

		if (isUploadingNewMedia) {
			const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });
			const [uploadedMediaResponse] = await uploadMedias({
				medias: [{ file: params.coverImageUrl[0] }],
				entityId,
				entityType: 'QUIZ_COVER_IMAGE',
			});

			if (uploadedMediaResponse?.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: uploadedMediaResponse?.data } });
			}

			const { data } = uploadedMediaResponse;

			mediaId = data.id;
			coverImageUrl = data.rawUrl;
		}

		const response = await fetchWrapper('/v1/admin/quiz/update-quiz', {
			method: 'PUT',
			body: { ...params, ...(isUploadingNewMedia ? { mediaId, coverImageUrl } : { coverImageUrl }) },
		});

		if (response.status !== 200 || !response.data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			showToast('success', `👍️ Quiz updated succcessfully!!`);
		}

		history.push('/activity/list');
		return response.data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const deleteQuiz = createAsyncThunk<any, any>('quiz/deleteQuiz', async ({ quizId }, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/v1/admin/quiz/delete-quiz?quizId=${quizId}`, { method: 'DELETE' });

		if (response.status !== 200 || !response.data.status) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		} else {
			showToast('success', `👍️ Quiz deleted succcessfully!!`);
		}

		return response.data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const getQuizDetails = createAsyncThunk<any, any>('quiz/getQuizDetails', async ({ quizId }, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/v1/admin/quiz/get-quiz-details?quizId=${quizId}`, { method: 'GET' });

		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return response.data;
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});
