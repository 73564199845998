import React, { useEffect, useState } from 'react';
import history from 'containers/App/history';
import { getPlayerInstance, convertTime } from 'containers/Class/helpers';

import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew } from 'reusableComponents/HookForm/Select';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import {
	ArrayMaybe,
	getSanitizedVideoUrl,
	isNotEmptyObject,
	parseQueryParams,
	showToast,
	stringifyQueryParams,
	wait,
} from 'utils/commonHelpers';

import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { fetchVideos } from 'utils/fetchHelpers';
import { changeExerciseStatus } from '../utils/changeExerciseStatus';
import { fetchExercise, fetchExerciseQuestions, setExerciseStatus, updateExercise } from '../actions/exercises.actions';
import { ContentDocumentStatusEnum, ExerciseTypeEnum, MediaTypeEnum, PlayUiTypeEnum } from '../utils/types';
import { setIsLoading } from '../ExercisesSlice';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
	borderRadius: '50%',
	width: '2rem',
	height: '2rem',
};

const btnStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const questionTypes = [
	{ label: 'Fun', value: 'FUN' },
	{ label: 'Feedback', value: 'FEEDBACK' },
	{ label: 'Assessment', value: 'ASSESSMENT' },
	{ label: 'Other', value: 'OTHER' },
];
export const ExerciseTypeFields = [
	{
		label: 'Mandatory',
		value: ExerciseTypeEnum.MANDATORY,
	},
	{
		label: 'Bonus',
		value: ExerciseTypeEnum.BONUS,
	},
];

const PlayUiTypeFields = [
	{
		label: 'LISTEN (Interaction)',
		value: PlayUiTypeEnum.LISTEN,
	},
	{
		label: 'SPEAK (Conversation)',
		value: PlayUiTypeEnum.SPEAK,
	},
	{
		label: 'PLAY (Simulation)',
		value: PlayUiTypeEnum.PLAY,
	},
	{
		label: 'PRACTICE (Standard)',
		value: PlayUiTypeEnum.PRACTICE,
	},
	{
		label: 'ASSESS (Standard)',
		value: PlayUiTypeEnum.ASSESS,
	},
];

const modifyExerciseBeforeInitialize = (exercise, questions) => {
	const interactiveQuestions = questions
		.filter((x) => (!x.questionStatus || x.questionStatus === 'ACTIVE') && x.status === 'ACTIVE')
		.map((q) => {
			const qData = exercise.questions.find((x) => x.id.toString() === q.id.toString());

			return {
				id: q.id,
				adminCode: q.adminCode,
				title: q.title,
				triggerTime: { min: Math.floor(qData.triggerTime / 60), sec: qData.triggerTime % 60 },
				duration: qData.duration,
			};
		});
	return {
		title: exercise.title,
		interactiveQuestions,
		certificateEnabled: exercise.certificateEnabled,
		leaderboardEnabled: exercise.leaderboardEnabled,
		type: ExerciseTypeFields.find((type) => type.value === exercise.type),
		playUiType: PlayUiTypeFields.find((type) => type.value === exercise.playUiType),
		supplementaryExercise: exercise.supplementaryExercise?.mediaPath
			? getPreviewImages({ id: exercise.id, mediaType: 'image', url: exercise.supplementaryExercise?.mediaPath }, false)
			: null,
		supplementaryExerciseSolution: exercise.supplementaryExerciseSolution?.mediaPath
			? getPreviewImages({ id: exercise.id, mediaType: 'image', url: exercise.supplementaryExerciseSolution?.mediaPath }, false)
			: null,
		// eslint-disable-next-line no-nested-ternary
		video: isNotEmptyObject(exercise.videoDetails)
			? {
					label: exercise.videoDetails.videoName,
					value: exercise.videoDetails.videoUrl || exercise.videoDetails.hlsVideoUrl,
					id: exercise.videoDetails.id,
			  }
			: isNotEmptyObject(exercise.video)
			? {
					id: exercise.video.id,
					label: exercise.video.label,
					value: exercise.video.value,
			  }
			: null,
	};
};

const ExerciseAddEditForm = (props) => {
	const hookFormMethods = useForm();
	const { exerciseId, subtopicId, mode } = parseQueryParams(props.location.search) ?? {};
	const dispatch = useDispatch();
	const [isVideoLoaded, setVideoLoaded] = useState(false);
	const [currentVideoTime, setCurrentVideoTime] = useState({
		min: 0,
		sec: 0,
	});

	const {
		exercises: { exercises, questions, isLoading },
		userId,
	} = useSelector(({ exercises, auth }) => ({ exercises, userId: auth?.user?.id }));

	const exercise = exercises?.find((exercise) => exercise.id === exerciseId);

	useEffect(() => {
		if (isNotEmptyObject(exercise)) {
			hookFormMethods.reset(modifyExerciseBeforeInitialize(exercise, questions));
		} else {
			// eslint-disable-next-line no-lonely-if
			if (mode !== 'add') {
				dispatch(fetchExercise({ exerciseId }));
				dispatch(fetchExerciseQuestions({ limit: 50, query: '', skip: 0, sortKey: 'id', sortOrder: '1', exerciseId }));
			}
		}
	}, [exercise, questions, exerciseId]);

	const watchPlayUIType = hookFormMethods.watch('playUiType');

	const onSubmit = async (data) => {
		if (!data.title?.trim() || !Number(data.title)) {
			return showToast('error', 'Please enter valid exercise title');
		}
		if (!data.type) {
			return showToast('error', 'Please select exercise type');
		}

		// validate interactive questions
		if (data.interactiveQuestions) {
			// get length of video
			if (!player) {
				return showToast('error', `The video is not loaded`);
			}
			const videoLength = player.duration(); // replace by code later

			let lastQuestionEndTime = -1;

			for (let idx = 0; idx < data.interactiveQuestions.length; idx += 1) {
				const question = data.interactiveQuestions[idx];
				const questionTriggerTime = Number(question.triggerTime.min) * 60 + Number(question.triggerTime.sec);
				const questionDuration = Number(question.waitDuration);

				// validate if trigger time and duration stays within video length
				if (questionTriggerTime >= videoLength) {
					return showToast('error', `The question number ${idx + 1} trigger time should be under video length`);
				}

				if (questionTriggerTime + questionDuration > videoLength) {
					return showToast('error', `The question number ${idx + 1} duration should be under video length`);
				}

				// validate if all questions are non-overlapping
				if (questionTriggerTime < lastQuestionEndTime) {
					return showToast('error', `The question number ${idx + 1} is overlapping with it's previous question`);
				}
				lastQuestionEndTime = questionTriggerTime + questionDuration;
			}
		}

		dispatch(setIsLoading());
		const entityType = 'EXERCISE';

		const imagesKeys = [
			{ key: 'supplementaryExercise', isMulti: false, withCaption: false, entityId: exerciseId ?? subtopicId, entityType },
			{ key: 'supplementaryExerciseSolution', isMulti: false, withCaption: false, entityId: exerciseId ?? subtopicId, entityType },
			{ key: 'coverImage', isMulti: false, withCaption: false, entityId: exerciseId ?? subtopicId, entityType },
		];

		const uploadFileData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		dispatch(
			updateExercise({
				userId,
				subtopicId,
				exerciseId,
				mode,
				takeLeaderboardSnapshot:
					exercise &&
					exercise.leaderboardEnabled === true && // OLD VALUE
					data.leaderboardEnabled === false && // NEW VALUE
					data.leaderboardEnabled !== exercise.leaderboardEnabled, // OLD !== NEW VALUE
				updatedExercise: {
					adminCode: exercise?.adminCode,
					title: data.title.trim(),
					type: data.type.value,
					playUiType: data.playUiType.value,
					certificateEnabled: data.certificateEnabled,
					leaderboardEnabled: data.leaderboardEnabled,
					video: isNotEmptyObject(data.video)
						? {
								id: data.video.id,
								label: data.video.label,
								value: data.video.value,
						  }
						: null,
					...(watchPlayUIType &&
						watchPlayUIType.value === PlayUiTypeEnum.LISTEN &&
						data.interactiveQuestions && {
							interactiveQuestions: data.interactiveQuestions.map((x) => {
								const quesData = questions.find((y) => y.adminCode === x.adminCode);
								const mins = parseInt(x.triggerTime.min, 10) * 60;
								const durationInSecs = mins + parseInt(x.triggerTime.sec, 10);
								return {
									id: quesData && quesData.id,
									triggerTime: durationInSecs,
									duration: parseInt(x.waitDuration, 10),
								};
							}),
						}),
					...(uploadFileData?.supplementaryExercise?.url
						? {
								supplementaryExercise: {
									mediaId: uploadFileData?.supplementaryExercise?.id,
									mediaType: MediaTypeEnum.IMAGE, // change it as per the file type,
									mediaPath: uploadFileData?.supplementaryExercise?.url ?? '',
								},
						  }
						: { supplementaryExercise: null }),
					...(uploadFileData?.supplementaryExerciseSolution?.url
						? {
								supplementaryExerciseSolution: {
									mediaId: uploadFileData?.supplementaryExerciseSolution?.id,
									mediaType: MediaTypeEnum.IMAGE, // change it as per the file type,
									mediaPath: uploadFileData?.supplementaryExerciseSolution?.url ?? '',
								},
						  }
						: { supplementaryExerciseSolution: null }),
					...(uploadFileData?.coverImage?.url
						? {
								coverImage: {
									mediaId: uploadFileData?.coverImage?.id,
									mediaType: MediaTypeEnum.IMAGE,
									mediaPath: uploadFileData?.coverImage?.url ?? '',
								},
						  }
						: { coverImage: null }),
				},
			})
		);
	};

	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />
			<CardBody>
				<Row>
					<Col>
						<h3>{mode === 'edit' ? 'Exercise Edit' : 'Create Exercise'}</h3>
					</Col>
					<Col>
						{mode === 'edit' && (
							<Button
								color="success"
								onClick={() => {
									if (exercise?.status === 'ACTIVE') {
										changeExerciseStatus({
											exerciseId,
											status: ContentDocumentStatusEnum.INACTIVE,
											playUiType: watchPlayUIType.value,
											questions,
											video: exercise.video,
											dispatch,
										});
									} else {
										changeExerciseStatus({
											exerciseId,
											status: ContentDocumentStatusEnum.ACTIVE,
											playUiType: watchPlayUIType.value,
											questions,
											video: exercise.video,
											dispatch,
										});
									}
								}}
							>
								{exercise?.status === ContentDocumentStatusEnum.ACTIVE ? 'Set Inactive' : 'Set Active'}
							</Button>
						)}
					</Col>
				</Row>

				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col style={{ maxWidth: '250px' }}>
									<SelectFieldNew
										label="Select Exercise Type"
										name="type"
										options={ExerciseTypeFields}
										defaultValue={ExerciseTypeFields[0]}
										isDisabled
										required
									/>
								</Col>
								<Col style={{ maxWidth: '250px' }}>
									<SelectFieldNew
										label="Select Play UI Type"
										name="playUiType"
										options={PlayUiTypeFields}
										// defaultValue={PlayUiTypeFields[0]}
										required
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<InputNew label="Title" name="title" defaultValue="" required type="number" min={0.001} max={10000} step={0.001} />
								</Col>
							</Row>
							{mode === 'edit' && (
								<Row>
									<Col>
										<Button
											onClick={() =>
												window.open(`/exercise/questions/add-edit${stringifyQueryParams({ exerciseId, mode: 'add' })}`, '_blank')
											}
										>
											Add a new Question
										</Button>
										<Button onClick={() => history.push(`/exercise/questions/list${stringifyQueryParams({ exerciseId })}`)}>
											Show all questions
										</Button>
									</Col>
								</Row>
							)}
							{/* <Row>
								<Col>
									<Checkbox label="Is certificate enabled?" name="certificateEnabled" />
								</Col>
							</Row> */}
							<Row>
								<Col>
									<Checkbox label="Is leaderboard enabled?" name="leaderboardEnabled" />
								</Col>
							</Row>

							<Row
								style={{
									visibility: watchPlayUIType && watchPlayUIType.value === PlayUiTypeEnum.LISTEN ? 'visible' : 'hidden',
									height: watchPlayUIType && watchPlayUIType.value === PlayUiTypeEnum.LISTEN ? 'auto' : '0',
								}}
							>
								<Col sm="12">
									<VideoSelectField
										setVideoLoaded={setVideoLoaded}
										setCurrentVideoTime={setCurrentVideoTime}
										watchPlayUIType={watchPlayUIType}
									/>
								</Col>
							</Row>

							{/* <Row>
								<Col>
									<DropzoneNew
										label="Supplementary Exercise"
										name="supplementaryExercise"
										accept="image/jpeg, image/png, image/webp, image/svg+xml, application/pdf"
									/>
								</Col>
								<Col>
									<DropzoneNew
										label="Supplementary Exercise Solution"
										name="supplementaryExerciseSolution"
										accept="image/jpeg, image/png, image/webp, image/svg+xml, application/pdf"
									/>
								</Col>
								<Col>
									<DropzoneNew
										label="Image, When this exercise is next logical exercise"
										name="coverImage"
										accept="image/jpeg, image/png, image/webp, image/svg+xml"
										defaultValue={getPreviewImages(
											{ id: exercise?.coverImage?.mediaId, mediaType: 'image', url: exercise?.coverImage?.mediaPath },
											false
										)}
									/>
								</Col>
							</Row> */}
							<Row
								style={{
									display: watchPlayUIType && watchPlayUIType.value === PlayUiTypeEnum.LISTEN ? 'flex' : 'none',
									visibility: isVideoLoaded ? 'visible' : 'hidden',
									position: 'sticky',
									top: '4rem',
									right: '1rem',
									height: isVideoLoaded ? '1rem' : '0',
									zIndex: 500,
									justifyContent: 'end',
									marginLeft: 'auto',
									width: 'fit-content',
								}}
							>
								<video className="video-js" style={{ width: '20rem', height: 500 }} id="my-player" playsInline />
								<p
									style={{
										position: 'absolute',
										top: '0px',
										right: '0px',
										backgroundColor: '#000',
										color: '#fff',
										padding: '2px 10px',
										fontSize: '1rem',
										margin: '0',
									}}
								>
									{currentVideoTime?.min} : {currentVideoTime?.sec}
								</p>
							</Row>
							{watchPlayUIType && watchPlayUIType.value === PlayUiTypeEnum.LISTEN && (
								<>
									<Row>
										<Col>
											<h3>{mode === 'edit' ? 'Enter the Timestamps' : ''}</h3>
										</Col>
									</Row>

									<Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
										<InteractiveQuestionItem {...hookFormMethods} fieldName="interactiveQuestions" isVideoLoaded={isVideoLoaded} />
									</Row>
								</>
							)}
						</Box>
						<Button type="submit">{mode === 'edit' ? 'Update' : 'Add'}</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

let player;

function VideoSelectField({ setVideoLoaded, setCurrentVideoTime, watchPlayUIType }) {
	const { setValue, watch } = useFormContext();

	const formValues = watch(['video']);

	useEffect(() => {
		player = getPlayerInstance({ videoTagId: 'my-player' });
		player.on('loadedmetadata', () => {
			const videoDuration = player.duration();

			if (!Number.isNaN(videoDuration) && videoDuration !== 0) {
				const minutes = Math.floor(videoDuration / 60);
				const seconds = videoDuration - minutes * 60;
				setValue('duration.mins', minutes);
				setValue('duration.secs', seconds);
			}
		});
		player.on('timeupdate', () => {
			const totalSeconds = player.currentTime();
			const minutes = Math.floor(totalSeconds / 60);
			const seconds = Math.floor(totalSeconds % 60);

			setCurrentVideoTime({
				min: minutes,
				sec: seconds,
			});
		});
		return () => {
			player.dispose();
		};
	}, []);

	useEffect(() => {
		if (!isNotEmptyObject(formValues.video)) {
			setValue('duration.mins', null);
			setValue('duration.secs', null);
			setVideoLoaded(false);
		} else if (player && formValues.video) {
			player.src({
				type: 'application/x-mpegURL',
				src: getSanitizedVideoUrl(formValues.video.value),
			});
			setVideoLoaded(true);
		}
	}, [formValues.video]);

	return (
		<AsyncSelectField
			required={!!(watchPlayUIType && watchPlayUIType.value === PlayUiTypeEnum.LISTEN)}
			label="Video"
			name="video"
			fetchOptions={(params) => fetchVideos({ ...params, videoType: 'INTERACTION_EXERCISE' })}
			isClearable
		/>
	);
}

const InteractiveQuestionItem = ({ control, fieldName = 'block', setValue, getValues, isVideoLoaded }) => {
	const interactiveQuestionItemFieldArray = useFieldArray({
		control,
		name: fieldName,
	});

	return (
		<Box style={{ width: isVideoLoaded ? '60%' : '95%' }}>
			{interactiveQuestionItemFieldArray.fields.map((field, index) => {
				return (
					<Row key={index.toString()} style={{ borderTop: '1px solid #3a3a3a75', margin: '0', paddingTop: '2rem', position: 'relative' }}>
						<Col sm="12" lg="2">
							<InputNew
								name={`${fieldName}[${index}].adminCode`}
								type="text"
								required
								label={`Question Admin Code ${index + 1}`}
								defaultValue={field?.adminCode}
								readOnly
							/>
						</Col>
						<Col sm="12" lg="10">
							<InputNew
								name={`${fieldName}[${index}].title`}
								type="text"
								required
								label={`Question Title ${index + 1}`}
								defaultValue={field?.title}
								readOnly
							/>
						</Col>
						<Col sm="6">
							<span className="form__form-group-label mb-3">
								Trigger Time <span className="form__form_group-label--required">&#42;</span>
							</span>
							<Row>
								<Col sm="3">
									<InputNew
										name={`${fieldName}[${index}].triggerTime.min`}
										type="number"
										label="Min"
										min={0}
										required
										defaultValue={field?.triggerTime?.min}
									/>
								</Col>
								:
								<Col sm="3">
									<InputNew
										name={`${fieldName}[${index}].triggerTime.sec`}
										type="number"
										label="Sec"
										min={0}
										max={59}
										required
										defaultValue={field?.triggerTime?.sec}
									/>
								</Col>
								<Box d="flex" ai="center">
									<Button
										color="secondary"
										size="md"
										style={btnStyles}
										onClick={() => {
											const currTime = player.currentTime();
											const mins = Math.floor(currTime / 60);
											const secs = Math.floor(currTime % 60);
											setValue(`${fieldName}[${index}].triggerTime.min`, mins);
											setValue(`${fieldName}[${index}].triggerTime.sec`, secs);
										}}
									>
										Fill Current Time
									</Button>
								</Box>
							</Row>
						</Col>
						<Col sm="6">
							<Row>
								<Col sm="9">
									<InputNew
										name={`${fieldName}[${index}].waitDuration`}
										type="number"
										min={1}
										label="Duration(in secs)"
										required
										defaultValue={field?.duration}
									/>
								</Col>
								<Box d="flex" ai="center">
									<Button
										color="secondary"
										size="md"
										style={btnStyles}
										onClick={() => {
											const triggerTime = {
												min: getValues(`${fieldName}[${index}].triggerTime.min`),
												sec: getValues(`${fieldName}[${index}].triggerTime.sec`),
											};
											const triggerTimeInSec = convertTime(triggerTime);
											const currentTime = player.currentTime();
											const waitDuration = Math.floor(currentTime) - triggerTimeInSec;
											if (waitDuration > 0) {
												setValue(`${fieldName}[${index}].waitDuration`, waitDuration);
											} else {
												showToast('error', '🚫️ Invalid Wait Duration');
											}
										}}
									>
										Fill Current Time
									</Button>
								</Box>
							</Row>
						</Col>
						{/* <Box
							style={{
								backgroundColor: 'tomato',
								borderRadius: '100px',
								width: '20px',
								height: '20px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: '#fff',
							}}
							onClick={() => {
								interactiveQuestionItemFieldArray.remove(index);
							}}
							cursor="pointer"
							pos="absolute"
							t="10px"
							r="10px"
						>
							<span className="lnr lnr-cross" />
						</Box> */}
					</Row>
				);
			})}
			{/* <Row>
				<Col sm="12">
					<Box d="flex" jc="center">
						<Box
							c={theme.clrs.cWhite}
							fs="1rem"
							bRad="6px"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => interactiveQuestionItemFieldArray.append({})}
							mb="1rem"
							p=".5rem"
						>
							+ Add Interactive Question
						</Box>
					</Box>
				</Col>
			</Row> */}
		</Box>
	);
};

export default ExerciseAddEditForm;
