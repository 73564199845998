import React from 'react';
import './styles.scss';

export default function Loader(props) {
	return (
		<div className="loader-container" {...props}>
			<div className="lds-ring">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
