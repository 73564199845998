import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EnrollmentsTable from './components/EnrollmentsTable';

const Enrollments = () => {
	return (
		<div>
			<Switch>
				<Route path="/enrollments" component={EnrollmentsTable} />
			</Switch>
		</div>
	);
};

export default Enrollments;
