import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, CardBody, Col, Table } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';

import { fetchWrapper } from 'utils/fetchWrapper';
import { getAllCourses } from 'containers/Exercise/utils/helpers';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import theme from '../../../utils/theme';
import { showToast } from '../../../utils/commonHelpers';

import CustomModal from '../../../reusableComponents/CustomModal';
import Box from '../../../reusableComponents/Box';
import { updateProfileInfo } from '../helper';

const StyledModal = styled.div`
	height: auto;
	width: 40rem;
	padding: 1rem;
`;

const customStyles = `
	width: 60rem;

	@media ${theme.device.md} {
		width: 60rem;
		height: auto;
	}
	
	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const ViewCoursesModal = ({ userId, closeModal }) => {
	const [courses, setCourses] = useState([]);
	const [profileData, setprofileData] = useState({ data: [] });
	useEffect(() => {
		const fetchAllCoursesAndProfileData = async () => {
			try {
				const [allCourses, profileInfo] = await Promise.all([
					getAllCourses({ academicClass: 7 }),
					fetchWrapper(`/v1/admin/profiles?parentId=${userId}&academicClass=7`, { method: 'GET' }),
				]);
				if (allCourses?.data) {
					const mappedCourses = allCourses.data.map((course, _id) => ({
						label: course.name,
						value: course._id,
					}));
					setCourses(mappedCourses);
				}
				if (profileInfo?.data) {
					setprofileData(profileInfo);
				}
			} catch (error) {
				console.error('Error fetching courses:', error);
			}
		};

		fetchAllCoursesAndProfileData();
	}, []);
	return (
		// @ts-ignore
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Card>
					<CardBody>
						<Box as="h3" mb="1rem">
							View Courses
						</Box>
						<Box>
							<ProfileCourse optionsArray={courses} response={profileData} />
						</Box>
					</CardBody>
				</Card>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(ViewCoursesModal);

function ProfileCourse({ optionsArray, response }: { optionsArray: any[]; response: { data: any[] } }) {
	const [profilesCourses, setProfilesCourses] = useState([]);
	useEffect(() => {
		const { data } = response;
		const allotedCourses = data.map((item) => {
			if (item.courses && item.courses.length > 0) {
				const selectedCourses = item.courses.map(({ id, status }) => {
					const course = optionsArray.find((option) => option.value === id);
					return { name: course.label, id, status };
				});
				return {
					fullName: item.fullName,
					profileId: item.id,
					courses: [...selectedCourses],
				};
			}
			return {
				fullName: item.fullName,
				profileId: item.id,
				courses: [],
			};
		});
		setProfilesCourses(allotedCourses);
	}, [optionsArray, response]);

	const updateCoursesforProfile = async ({ profileId, currentCourse }: { profileId: string; currentCourse: any }) => {
		try {
			const profileCourses = profilesCourses.find((profileWithCourse) => profileWithCourse.profileId === profileId);
			const courseIndex = profileCourses.courses.findIndex((course) => course.id === currentCourse.id);
			const updatedStatus =
				courseIndex !== -1 ? (profileCourses.courses[courseIndex].status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE') : 'ACTIVE';
			const currentProfileCourses = [...(profilesCourses.find((details) => details.profileId === profileId).courses ?? [])];
			const activeCount = currentProfileCourses.filter((profile) => profile.status === 'ACTIVE').length;
			if (updatedStatus === 'ACTIVE' && activeCount >= 2) {
				return showToast('error', 'cannot add more than two active courses');
			}
			if (courseIndex !== -1) {
				profileCourses.courses[courseIndex].status = updatedStatus;
			}

			const result = await updateProfileInfo({ profileId, courses: profileCourses.courses });

			if (result.status === 200) {
				const profileIndex = profilesCourses.findIndex((profileWithCourse) => profileWithCourse.profileId === profileId);
				const courseIndex = profilesCourses[profileIndex].courses.find((course) => course.id === currentCourse.id);
				setProfilesCourses([...profilesCourses]);
				showToast('success', 'Course Status Updated Successfully');
				return result.data;
			}
			showToast('error', 'Failed to Update Course Status');
			return null;
		} catch (err) {
			const error = err ?? err?.message;
			if (typeof error === 'string') showToast('error', error);
			else showToast('error', 'Failed to Update Course Status. Please try again');
		}
	};
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const onSubmit = async ({ data, profileId }) => {
		const updatedProfileCourses = data[`courses_${profileId}`];
		const currentProfileCourses = [...(profilesCourses.find((details) => details.profileId === profileId).courses ?? [])];
		if (currentProfileCourses.filter((profile) => profile.status === 'ACTIVE').length > 2) {
			return showToast('error', 'Cannot add more than 2 Active Courses');
		}
		if (!updatedProfileCourses) {
			return showToast('error', 'There is no selected course');
		}

		for (let i = 0; i < updatedProfileCourses.length; i += 1) {
			const currentId = updatedProfileCourses[i].value;
			const courseName = updatedProfileCourses[i].label;
			const isExits = currentProfileCourses.find((d) => d.id === currentId);
			if (!isExits) {
				currentProfileCourses.push({ name: courseName, id: currentId, status: 'ACTIVE' });
			}
		}
		const activeProfileCount = currentProfileCourses.filter((profile) => profile.status === 'ACTIVE').length;

		if (activeProfileCount > 2) {
			return showToast('error', 'Cannot add more than 2 Active Courses');
		}
		const result = await updateProfileInfo({ profileId, courses: currentProfileCourses });
		const targetIndex = profilesCourses.findIndex((profile) => profile.profileId === profileId);
		if (targetIndex !== -1) {
			profilesCourses[targetIndex].courses = currentProfileCourses;
		}

		if (result.status === 200) {
			showToast('success', 'Course Status Updated Successfully');
			return result.data;
		}
	};

	return (
		<Box
			style={{
				maxHeight: '70vh',
				overflowX: 'scroll',
			}}
		>
			{profilesCourses.map((profile, index) => (
				<div key={profile.profileId}>
					<div style={{ fontSize: '18px' }}>
						<span style={{ fontSize: '18px' }}>Learner {index + 1}</span> <br />
						<span style={{ fontSize: '14px' }}>Name:</span> {profile.fullName}{' '}
						<span style={{ fontSize: '12px' }}>({profile.profileId})</span>
					</div>
					<Table bordered hover>
						<thead>
							<tr>
								<th>Courses</th>
								<th>Status</th>
								<th>Cancel</th>
							</tr>
						</thead>
						<tbody>
							{profile.courses.map((course) => (
								<tr key={course.id}>
									{' '}
									<td key={course.name}>{course.name}</td>
									<td key={course.status}>{course.status}</td>
									<td>
										<div
											onClick={() => updateCoursesforProfile({ profileId: profile.profileId, currentCourse: course })}
											style={{ color: 'blue', cursor: 'pointer' }}
										>
											{course.status === 'INACTIVE' ? 'Grant Course' : 'Revoke Course'}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
						<form
							className="form ltr-support"
							onSubmit={handleSubmit((data) =>
								onSubmit({
									data,
									profileId: profile.profileId,
								})
							)}
						>
							<Col sm="12">
								<SelectFieldNew label="Select course" name={`courses_${profile.profileId}`} options={optionsArray} isMulti />
							</Col>
							<Col sm="12" className="mt-4">
								<Button size="sm" color="primary" type="submit">
									Add courses for profile {index + 1}
								</Button>
							</Col>
						</form>
					</FormProvider>
				</div>
			))}
		</Box>
	);
}
