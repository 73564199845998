import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams, StringMaybe, ObjectMaybe, parseQueryParams } from 'utils/commonHelpers';
import { dateTimeConverter } from 'utils/dateHelper';

import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import Filter from 'reusableComponents/Filter';
import { TableCoverPicture } from 'components/TableCoverPicture';
import If from 'reusableComponents/If';
import { Checkbox } from 'reusableComponents/Form/Checkbox';
import { RouteComponentProps } from 'react-router';
import Box from 'reusableComponents/Box';
import theme from 'utils/theme';
import { IProgram } from 'types/Program';
import history from '../../../App/history';
import { getClasses, setApiParams, clearClasses } from '../../classSlice';
import { cloneClassesToProgram, updateClassAndUpdateList } from '../../actions/class.actions';
import CloneClassesModal from './CloneClassesModal';
import MarkClassAsLiveModal from './MarkClassAsLiveModal';

const convertDurationToMin = (duration: number) => `${Math.floor(duration / 60)}:${duration % 60}`;

interface ClassTableHeads<T = any> {
	coverPicture: T;
	checkbox: T;
	title: T;
	duration: T;
	programTitle: T;
	status: T;
	actions: T;
}

const Actions = ({
	programId,
	programTitle,
	classId,
	index,
	status,
	videoId,
	liveClassStartDate,
	liveClassEndDate,
	isLiveClassByAdmin,
	openMarkAsLiveModal,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => history.push(`/class/add-edit${stringifyQueryParams({ programId, programTitle, classId })}`)}
					disabled={false}
				>
					Edit Class
				</DropdownItem>
				<DropdownItem
					onClick={() =>
						dispatch(
							updateClassAndUpdateList({ classId, classIndex: index, status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE', source: 'LIST' })
						)
					}
				>
					{status === 'ACTIVE' ? 'Mark as Inactive' : 'Mark as Active'}
				</DropdownItem>
				<DropdownItem onClick={() => history.push(`/class/polls?classId=${classId}`)}>Create/Manage Polls</DropdownItem>
				{videoId && <DropdownItem onClick={() => history.push(`/class/video/${videoId}`)}>Watch Video</DropdownItem>}
				<DropdownItem
					onClick={() => {
						openMarkAsLiveModal({ classId, classIndex: index, liveClassStartDate, liveClassEndDate, isLiveClassByAdmin });
					}}
				>
					{' '}
					Mark As live{' '}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

type CreateRowsParams = {
	classes: any[];
	selectedClasses: Set<string>;
	onCheckClass: (id: string, checked: boolean) => void;
	openMarkAsLiveModal: (params: {
		classId: string;
		classIndex: number;
		liveClassStartDate: string;
		liveClassEndDate: string;
		isLiveClassByAdmin: boolean;
	}) => void;
};

function createRows({ classes, selectedClasses, onCheckClass, openMarkAsLiveModal }: CreateRowsParams) {
	return classes.map(
		(
			{
				id,
				coverPicture,
				title,
				dateTime,
				duration,
				status,
				video,
				programDetails: { id: programId, title: programTitle },
				liveClassStartDate,
				liveClassEndDate,
				isLiveClassByAdmin,
			},
			index: number
		) =>
			({
				checkbox: <Checkbox checked={selectedClasses.has(id)} onChange={(e, checked) => onCheckClass(id, checked)} />,
				coverPicture: <TableCoverPicture {...{ coverPicture, title }} />,
				title,
				dateTime: dateTimeConverter(dateTime, 'format', 'dd MMM yyyy (k:mm)'),
				duration: convertDurationToMin(duration),
				programTitle,
				status,
				actions: (
					<Actions
						{...{
							programId,
							classId: id,
							index,
							programTitle,
							status,
							videoId: video?.id,
							liveClassStartDate,
							liveClassEndDate,
							isLiveClassByAdmin,
							openMarkAsLiveModal,
						}}
					/>
				),
			} as ClassTableHeads<React.ReactNode>)
	);
}

export const ClassTable: React.FC<RouteComponentProps> = (props) => {
	const classState = useSelector(({ classes }) => ({
		loading: classes.loading,
		classes: classes.classes,
		pageNo: classes.pageNo,
		total: classes.total,
		apiParams: classes.apiParams,
	}));

	const dispatch = useDispatch();

	const heads: TableHeads<ClassTableHeads> = [
		{ accessor: 'coverPicture', Header: 'Cover Picture' },
		{ accessor: 'title', Header: 'Title' },
		{ accessor: 'duration', Header: 'Duration' },
		{ accessor: 'programTitle', Header: 'Program Title' },
		{ accessor: 'status', Header: 'Status' },
		{ accessor: 'actions', Header: 'Actions' },
	];

	const [classesRows, setClassesRow] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [selectedClasses, setSelectedClasses] = React.useState(new Set<string>());
	const [isCloneClassesModalOpen, setIsCloneClassesModalOpen] = React.useState(false);
	const [markAsLiveModalParams, setMarkAsLiveModalParams] = React.useState({ isOpen: false, params: null });
	const [searchTerm, setSearchTerm] = React.useState(classState.apiParams.query);

	React.useEffect(() => {
		const programId = ObjectMaybe(parseQueryParams(props.location.search)).programId || StringMaybe(classState.apiParams.query);
		if (programId) {
			dispatch(setApiParams({ query: programId, limit: 20, skip: 0 }));
			setSearchTerm(programId);
			dispatch(getClasses());
		}
	}, [props.location]);

	React.useEffect(() => {
		setPageOfItems(classState.pageNo);
	}, [classState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(classState.classes)) {
			const structuredRows = createRows({ classes: classState.classes, selectedClasses, onCheckClass, openMarkAsLiveModal });
			setClassesRow(structuredRows);
		} else {
			setClassesRow([]);
		}
	}, [classState.classes, selectedClasses]);

	const closeMarkAsLiveModal = () => {
		setMarkAsLiveModalParams({ isOpen: false, params: markAsLiveModalParams.params });
	};

	const openMarkAsLiveModal = ({ classId, classIndex, liveClassStartDate, liveClassEndDate, isLiveClassByAdmin }) => {
		setMarkAsLiveModalParams({ isOpen: true, params: { classId, classIndex, liveClassStartDate, liveClassEndDate, isLiveClassByAdmin } });
	};

	const onSubmitMarkAsLiveModal = (params) => {
		dispatch(
			updateClassAndUpdateList({
				classId: markAsLiveModalParams.params.classId,
				classIndex: markAsLiveModalParams.params.classIndex,
				...params,
			})
		);
		closeMarkAsLiveModal();
	};

	const onChangePage = (itemsPage: number) => {
		const {
			apiParams: { sortKey, sortOrder, limit, query },
		} = classState;
		const skip = limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setApiParams({ sortKey, sortOrder, limit, skip, query }));
			dispatch(getClasses());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', classState),
					skip: accessDeepObject('apiParams.limit', classState) * (pageOfItems - 1),
					query: classState.apiParams.query,
				})
			);
			dispatch(getClasses());
		}
	};

	const onSearch = (e: React.FormEvent) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		if (searchTerm) dispatch(getClasses());
		else dispatch(clearClasses());
	};

	const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (checked) {
			setSelectedClasses((classes) => {
				const tempSelectedClasses = new Set(classes);
				classState.classes.forEach((classInfo) => {
					tempSelectedClasses.add(classInfo.id);
				});
				return tempSelectedClasses;
			});
		} else {
			setSelectedClasses((classes) => {
				const tempSelectedClasses = new Set(classes);
				classState.classes.forEach((classInfo) => {
					tempSelectedClasses.delete(classInfo.id);
				});
				return tempSelectedClasses;
			});
		}
	};

	const onCheckClass = (id: string, checked: boolean) => {
		if (checked) {
			setSelectedClasses((classes) => {
				const tempSelectedClasses = new Set(classes);
				tempSelectedClasses.add(id);
				return tempSelectedClasses;
			});
		} else {
			setSelectedClasses((classes) => {
				const tempSelectedClasses = new Set(classes);
				tempSelectedClasses.delete(id);
				return tempSelectedClasses;
			});
		}
	};

	let isAnySelected = false;
	let isAllSelected = true;

	(classState.classes as any[]).forEach((currentClass) => {
		if (selectedClasses.has(currentClass.id)) {
			isAnySelected = true;
			return;
		}
		isAllSelected = false;
	});

	heads.unshift({
		accessor: 'checkbox',
		disableSort: true,
		Header: <Checkbox checked={isAllSelected} indeterminate={!isAllSelected && isAnySelected} value="select-all" onChange={onSelectAll} />,
	});

	const onClickCloneClasses = () => {
		setIsCloneClassesModalOpen(true);
	};
	const onCloseCloneClasses = (clearSelected = false) => {
		setIsCloneClassesModalOpen(false);
		if (clearSelected) clearSelectedClasses();
	};
	const clearSelectedClasses = () => {
		setSelectedClasses(new Set());
	};

	const onConfirmCloneClasses = (program: IProgram) => {
		onCloseCloneClasses(true);
		const classIds = Array.from(selectedClasses.values());
		dispatch(cloneClassesToProgram({ classIds, program }));
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={12} className="mb-3">
							<h3>Classes Listing</h3>
						</Col>
						<Col>
							<Row className="d-flex justify-content-between">
								<Col sm={12} lg={6}>
									<Filter
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										onSearch={onSearch}
										placeholder="Search by Class title, ID or Program ID"
									/>
									<Box style={{ height: '20px' }}>
										<If
											condition={!!selectedClasses.size}
											then={
												<>
													{selectedClasses.size} class{selectedClasses.size > 1 ? 'es' : ''} selected
													<span
														className="ml-2 c-pointer"
														style={{ color: theme.clrs.cSkyBlue, cursor: 'pointer' }}
														onClick={clearSelectedClasses}
													>
														Clear
													</span>
												</>
											}
										/>
									</Box>
								</Col>
								<If
									condition={!!selectedClasses.size}
									then={
										<Col className="d-flex justify-content-end" sm={12} lg={3}>
											<Button className="mb-0 w-100" style={{ height: '32px' }} size="sm" color="primary" onClick={onClickCloneClasses}>
												Clone Class{selectedClasses.size > 1 ? 'es' : ''}
											</Button>
										</Col>
									}
								/>
							</Row>
						</Col>
					</Row>
					<If
						condition={isNotEmptyArray(classesRows)}
						then={
							<>
								<DataPaginationTable heads={heads} rows={classesRows} onSort={onSort} />
								<Pagination
									itemsCount={classState.total}
									itemsToShow={accessDeepObject('apiParams.limit', classState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						}
						else={classState.total === 0 && <div className="">No data</div>}
					/>
				</CardBody>
			</Card>
			<If
				condition={isCloneClassesModalOpen}
				then={<CloneClassesModal onConfirm={onConfirmCloneClasses} closeModal={() => onCloseCloneClasses(false)} />}
			/>
			<If
				condition={markAsLiveModalParams.isOpen}
				then={
					<MarkClassAsLiveModal
						closeModal={closeMarkAsLiveModal}
						onConfirm={onSubmitMarkAsLiveModal}
						initialValues={markAsLiveModalParams.params}
					/>
				}
			/>
		</Col>
	);
};
