import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import theme from '../../../utils/theme';
import { accessDeepObject, isNotEmptyArray, StringMaybe } from '../../../utils/commonHelpers';

import CustomModal from '../../../reusableComponents/CustomModal';
import Box from '../../../reusableComponents/Box';
import { setApiParams } from '../userSlice';
import { getUserCertificates } from '../actions/user.actions';

const StyledModal = styled.div`
	max-height: 60vh;
	overflow-y: scroll;
	width: 60rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const Actions = ({ imageUrl, pdfUrl }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => {
						window.location = imageUrl;
					}}
				>
					View Image
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						window.open(pdfUrl);
					}}
				>
					View PDF
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ certificates }) {
	return certificates.map(({ id, profile, batch, mentor, imageUrl, pdfUrl, template }) => ({
		id,
		template: template.templateName,
		fullName: profile.fullName,
		programTitle: batch.program.title,
		programDifficulty: StringMaybe(batch.program?.difficulty),
		mentor: mentor.fullName,
		actions: (
			<Actions
				{...{
					imageUrl,
					pdfUrl,
				}}
			/>
		),
	}));
}

const CertificatesModal = ({ userId, closeModal }) => {
	const [certificateRows, setCertificateRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	const userState = useSelector(({ user }) => ({
		loading: user.loading,
		certificates: user.certificates,
		pageNo: user.certificatePageNo,
		total: user.certificateTotal,
		apiParams: user.apiParams,
	}));
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserCertificates({ userId }));
	}, []);

	useEffect(() => {
		setPageOfItems(userState.pageNo);
	}, [userState.pageNo]);

	useEffect(() => {
		if (isNotEmptyArray(userState.certificates)) {
			const structuredRows = createRows({ certificates: userState.certificates });
			setCertificateRows(structuredRows);
		} else {
			setCertificateRows([]);
		}
	}, [userState.certificates]);

	const heads = [
		{ accessor: 'template', Header: 'Certificate Template' },
		{ accessor: 'fullName', Header: 'Name' },
		{ accessor: 'programTitle', Header: 'Program Name' },
		{ accessor: 'programDifficulty', Header: 'Program Difficulty' },
		{ accessor: 'mentor', Header: 'Mentor' },
		{ accessor: 'actions', Header: 'Actions' },
	];

	const onChangePage = (itemsPage) => {
		const { apiParams } = userState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getUserCertificates({ userId, limit: apiParams.limit, skip: apiParams.limit * (itemsPage - 1) }));
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', userState),
					skip: accessDeepObject('apiParams.limit', userState) * (pageOfItems - 1),
				})
			);
			dispatch(getUserCertificates({ userId }));
		}
	};

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box w="85%" ellipse fs="1.4rem" mb="1rem">
					User Certificates
				</Box>
				<Box pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					{isNotEmptyArray(certificateRows) ? (
						<>
							<DataPaginationTable heads={heads} rows={certificateRows} onSort={onSort} />
							<Pagination
								itemsCount={userState.total}
								itemsToShow={accessDeepObject('apiParams.limit', userState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					) : null}
				</Box>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(CertificatesModal);
