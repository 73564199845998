import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe, omitWrapper, isNotEmptyArray, isNotEmptyObject } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import formatDate from 'date-fns/format';
import { DropzoneNew as Dropzone } from 'reusableComponents/HookForm/Dropzone';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import { validateExternalWebAddress } from 'reusableComponents/Form/fieldValidators';
import { IPhotoDatabase } from 'types/Photo';
import { WEBSITE_URL } from 'utils/constants';
import differenceBy from 'lodash/differenceBy';
import { fetchClasses, fetchMentors, fetchPrograms } from 'utils/fetchHelpers';
import { createBanner, updateBanner } from '../actions/dashboard.actions';

const imageSizes = {
	BANNER_WIDTH: 360,
	BANNER_WIDTH_WEB: 800,
	BANNER_WIDTH_3_BY_2: 900,
	BANNER_HEIGHT: 450,
	BANNER_HEIGHT_WEB: 288,
	BANNER_HEIGHT_3_BY_2: 600,
	THUMBNAIL_WIDTH: 60,
	THUMBNAIL_HEIGHT: 60,
	DASHBOARD_BANNER_FEATURED_PROGRAM_WIDTH: 300,
	DASHBOARD_BANNER_FEATURED_PROGRAM_HEIGHT: 459,
};

const statusOptions = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
];

const showOnOptions = [
	{ label: 'Mobile Web', value: 'MWEB' },
	{ label: 'Web', value: 'WEB' },
	{ label: 'App', value: 'APP' },
	{ label: 'TV', value: 'TV' },
	{ label: 'Tab Web', value: 'TAB_WEB' },
	{ label: 'Tablet', value: 'TABLET' },
];

const clickActionOptions = [
	{ label: 'External Link', value: 'ExternalLink' },
	// { label: 'Product Page', value: 'ProductPage' },
	{ label: 'App Link', value: 'AppLink' },
];

const entityTypeOptions = [
	{ label: 'CLASS', value: 'CLASS' },
	{ label: 'MENTOR', value: 'MENTOR' },
	{ label: 'PROGRAM', value: 'PROGRAM' },
];

const getFormattedMedia = (photo) => {
	const media = { file: photo[0], caption: photo[0]?.caption || '', id: photo[0]?.id };
	return media;
};

const getEntityFieldProps = (entityType: string) => {
	switch (entityType) {
		case 'CLASS': {
			return { label: 'Class', fetchOptions: fetchClasses };
		}
		case 'MENTOR': {
			return { label: 'Mentor', fetchOptions: fetchMentors };
		}
		case 'PROGRAM': {
			return { label: 'Program', fetchOptions: fetchPrograms };
		}
		default:
	}
};

const validateAspectRatio = ({ aspectRatio, file }: { aspectRatio: number; file: Blob }): Promise<boolean> => {
	const reader = new FileReader();
	reader.readAsDataURL(file);

	let isValid = false;

	return new Promise((resolve) => {
		reader.onload = (e) => {
			const img = new Image();
			img.src = e.target.result as any;
			img.onload = function () {
				/* eslint-disable-next-line */
				const { width, height } = this as any;
				const imageAspectRatio = width / height;
				if (+imageAspectRatio.toFixed(2) === +aspectRatio.toFixed(2)) isValid = true;
				resolve(isValid);
			};
		};
	});
};

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface DashboardBannerFormValues {
	bannerTitle: string;
	status: { label: string; value: string };
	showOn: { label: string; value: string }[];
	priority: number;
	clickActionType: { label: string; value: string };
	clickActionEntityType: { label: string; value: string };
	clickActionEntityId: { label: string; value: string };
	clickActionUrl: string;
	expiryAt?: string;
	bannerThumbnail: IPhotoDatabase;
	bannerImage: IPhotoDatabase;
	bannerImageWeb: IPhotoDatabase;
	bannerImage3By2: IPhotoDatabase;
	bannerFeaturedProgram: IPhotoDatabase;
}

type DashboardBannerInitialValues = DashboardBannerFormValues & { id: string; createdBy: string; updatedBy: string };

export type DashboardBannerValuesFromDatabase = Omit<DashboardBannerFormValues, 'showOn'> & {
	id: string;
	bannerTitle?: string;
	status: 'ACTIVE' | 'INACTIVE';
	showOn: string[];
	clickActionType?: 'ProductPage' | 'AppLink' | 'ExternalLink';
	clickActionUrl?: string;
	clickActionEntityType?: 'CLASS' | 'PROGRAM' | 'MENTOR';
	clickActionEntityId?: string;
	clickActionEntityTitle?: string;
	priority: number;
	expiryAt?: string;
	createdBy: string;
	updatedBy: string;
	bannerThumbnail: IPhotoDatabase;
	bannerImage: IPhotoDatabase;
	bannerImageWeb: IPhotoDatabase;
	bannerImage3By2: IPhotoDatabase;
	bannerFeaturedProgram: IPhotoDatabase;
};

interface ModifyDashboardBannerBeforeCreateOrUpdateArgs extends DashboardBannerFormValues {
	id?: string;
	createdBy: string;
	updatedBy: string;
}

export const modifyDashboardBannerBeforeCreateOrUpdate = (
	values: ModifyDashboardBannerBeforeCreateOrUpdateArgs & { initialMedias: any }
) => {
	const medias = [
		values.bannerImage?.[0],
		values.bannerThumbnail?.[0],
		values.bannerImageWeb?.[0],
		values.bannerImage3By2?.[0],
		values.bannerFeaturedProgram?.[0],
	];

	return {
		...omitWrapper(['bannerThumbnail', 'bannerImage', 'bannerImageWeb', 'bannerImage3By2', 'priority', 'bannerFeaturedProgram'], values),
		status: values.status.value,
		showOn: values.showOn.map((item) => item.value),
		// clickActionEntityType: values.clickActionEntityType.value,
		// clickActionEntityId: values.clickActionEntityId.value,
		// clickActionEntityTitle: values.clickActionEntityTitle.label,
		clickActionUrl: values.clickActionType.value === 'AppLink' ? `${WEBSITE_URL}/${values.clickActionUrl}` : values.clickActionUrl,
		clickActionType: values.clickActionType.value,
		expiryAt: values.expiryAt ? new Date(values.expiryAt) : null,
		...(isNotEmptyArray(values.bannerImage) ? { bannerImage: getFormattedMedia(values.bannerImage) } : {}),
		...(isNotEmptyArray(values.bannerImageWeb) ? { bannerImageWeb: getFormattedMedia(values.bannerImageWeb) } : {}),
		...(isNotEmptyArray(values.bannerImage3By2) ? { bannerImage3By2: getFormattedMedia(values.bannerImage3By2) } : {}),
		...(isNotEmptyArray(values.bannerThumbnail) ? { bannerThumbnail: getFormattedMedia(values.bannerThumbnail) } : {}),
		...(isNotEmptyArray(values.bannerFeaturedProgram) ? { bannerFeaturedProgram: getFormattedMedia(values.bannerFeaturedProgram) } : {}),
		inactiveMedias: differenceBy(values.initialMedias, medias, (media) => media?.id),
	};
};

export const modifyDashboardBannerBeforeInitialize = (values: DashboardBannerValuesFromDatabase): DashboardBannerInitialValues => {
	return {
		...values,
		...(values.expiryAt && {
			expiryAt: formatDate(new Date(values.expiryAt), "yyyy-MM-dd'T'HH:mm"),
		}),
		status: statusOptions.find((status) => status.value === values.status),
		clickActionType: clickActionOptions.find((clickActionType) => clickActionType.value === values.clickActionType),
		clickActionUrl: values.clickActionType === 'AppLink' ? values.clickActionUrl.replace(`${WEBSITE_URL}/`, '') : values.clickActionUrl,
		// clickActionType: clickActionOptions.find((clickActionType) => clickActionType.value === values.clickActionType),
		// clickActionEntityType: entityTypeOptions.find((clickActionEntityType) => clickActionEntityType.value===values.clickActionEntityType),
		// clickActionEntityId: { label: values.clickActionEntityTitle, value: values.clickActionEntityId },
		showOn: values.showOn.map((item) => showOnOptions.find((elem) => elem.value === item)),
		...(isNotEmptyObject(values.bannerImage) ? { bannerImage: getPreviewImages(values.bannerImage, false) as any } : {}),
		...(isNotEmptyObject(values.bannerImageWeb) ? { bannerImageWeb: getPreviewImages(values.bannerImageWeb, false) as any } : {}),
		...(isNotEmptyObject(values.bannerImage3By2) ? { bannerImage3By2: getPreviewImages(values.bannerImage3By2, false) as any } : {}),
		...(isNotEmptyObject(values.bannerThumbnail) ? { bannerThumbnail: getPreviewImages(values.bannerThumbnail, false) as any } : {}),
		...(isNotEmptyObject(values.bannerFeaturedProgram)
			? { bannerFeaturedProgram: getPreviewImages(values.bannerFeaturedProgram, false) as any }
			: {}),
	};
};

interface DashboardBannerAddEditFormProps {
	location: { search: { id?: string } };
}

export const DashboardAddEditForm = (props: DashboardBannerAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<DashboardBannerInitialValues>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.dashboard.isSubmitting,
	}));
	const hookFormMethods = useForm<DashboardBannerFormValues>();
	const { id } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!id;

	const { handleSubmit, watch, setValue } = hookFormMethods;
	const watchFields = watch(['clickActionType', 'clickActionEntityType']);

	useEffect(() => {
		if (
			watchFields?.clickActionEntityType?.value &&
			initialValues?.clickActionEntityType?.value !== watchFields?.clickActionEntityType?.value
		)
			setValue('clickActionEntityId', '');
	}, [watchFields.clickActionEntityType]);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/dashboard/banner?id=${id}`, { method: 'GET' }).then((res) => {
				const modifiedValues = modifyDashboardBannerBeforeInitialize(res.data as any);
				setInitialValues(modifiedValues);
				hookFormMethods.reset(modifiedValues);
			});
		}
	}, []);

	const onSubmit = (values: DashboardBannerFormValues) => {
		const modifiedValues = modifyDashboardBannerBeforeCreateOrUpdate({
			...values,
			id: isEditForm ? initialValues.id : undefined,
			createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
			updatedBy: loggedInUser.id,
			initialMedias: isEditForm
				? [
						...(initialValues.bannerImage?.[0] ? [initialValues.bannerImage?.[0]] : []),
						...(initialValues.bannerThumbnail?.[0] ? [initialValues.bannerThumbnail?.[0]] : []),
						...(initialValues.bannerImageWeb?.[0] ? [initialValues.bannerImageWeb?.[0]] : []),
						...(initialValues.bannerImage3By2?.[0] ? [initialValues.bannerImage3By2?.[0]] : []),
						...(initialValues.bannerFeaturedProgram?.[0] ? [initialValues.bannerFeaturedProgram?.[0]] : []),
				  ]
				: [],
		});

		if (isEditForm) {
			dispatch(updateBanner(modifiedValues));
		} else {
			dispatch(createBanner(modifiedValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Banner...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Dashboard Banner</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<Input label="Banner Title" name="bannerTitle" required maxLength={50} helpText="Max 50 characters allowed" />
									</Col>
									<Col sm="6">
										<SelectField label="Status" name="status" options={statusOptions} required />
									</Col>
									<Col sm="6">
										<SelectField isMulti label="Show On" name="showOn" options={showOnOptions} required />
									</Col>
									<Col sm="6">
										<SelectField label="Click Action Type" name="clickActionType" options={clickActionOptions} required />
									</Col>
									{/* {(watchFields?.clickActionType?.value === 'ExternalLink' ||
									watchFields?.clickActionType?.value === 'AppLink') && ( */}

									<Box w="100%" d="flex" ai="center" jc="flex-start">
										{watchFields?.clickActionType?.value === 'AppLink' && (
											<p style={{ paddingLeft: '15px', marginBottom: '15px' }}>{WEBSITE_URL}/</p>
										)}
										<Col sm="6">
											<Input
												label="Click Action URL"
												name="clickActionUrl"
												required
												validate={(val) => {
													if (watchFields?.clickActionType?.value === 'ExternalLink') return validateExternalWebAddress(val) || true;
													return true;
												}}
											/>
										</Col>
									</Box>
									{/* )} */}
									{watchFields?.clickActionType?.value === 'ProductPage' && (
										<>
											<Col sm="6">
												<SelectField label="Click Action Entity Type" name="clickActionEntityType" options={entityTypeOptions} required />
											</Col>
											<Col sm="6">
												<AsyncSelectField
													label="Click Action Entity ID"
													{...getEntityFieldProps(watchFields?.clickActionEntityType?.value)}
													name="clickActionEntityId"
													isClearable
													required
												/>
											</Col>
										</>
									)}
									<Col sm="6">
										<Input
											type="datetime-local"
											name="expiryAt"
											label="Expiry Date"
											min={formatDate(new Date(), 'yyyy-MM-dd')}
											helpText="Click on calendar icon to open calendar"
										/>
									</Col>
									<Col sm="6">
										<Input
											type="number"
											name="priority"
											label="Priority"
											disabled
											helpText="The priority will be set to maximum value automatically. You can adjust it from list view"
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Thumbnail (60x60 px image)"
											name="bannerThumbnail"
											validate={async (val) => {
												// if (!val || !val.length) return 'Required';
												if (val && val.length && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({
														aspectRatio: imageSizes['THUMBNAIL_WIDTH'] / imageSizes['THUMBNAIL_HEIGHT'],
														file: val[0] as Blob,
													});
													return isValid
														? true
														: `Please upload ${imageSizes['THUMBNAIL_WIDTH']}x${imageSizes['THUMBNAIL_HEIGHT']}px aspect ratio image`;
												}
											}}
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Banner Image (360x450 px Aspect Ratio image)"
											name="bannerImage"
											validate={async (val) => {
												// if (!val || !val.length) return 'Required';
												if (val && val.length && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({
														aspectRatio: imageSizes['BANNER_WIDTH'] / imageSizes['BANNER_HEIGHT'],
														file: val[0] as Blob,
													});
													return isValid
														? true
														: `Please upload ${imageSizes['BANNER_WIDTH']}x${imageSizes['BANNER_HEIGHT']}px aspect ratio image`;
												}
											}}
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Banner Image Web (800x288 px Aspect Ratio image)"
											name="bannerImageWeb"
											validate={async (val) => {
												// if (!val || !val.length) return 'Required';
												if (val && val.length && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({
														aspectRatio: imageSizes['BANNER_WIDTH_WEB'] / imageSizes['BANNER_HEIGHT_WEB'],
														file: val[0] as Blob,
													});
													return isValid
														? true
														: `Please upload ${imageSizes['BANNER_WIDTH_WEB']}x${imageSizes['BANNER_HEIGHT_WEB']}px aspect ratio image`;
												}
											}}
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Banner Image (900x600 px Aspect Ratio image)"
											name="bannerImage3By2"
											validate={async (val) => {
												// if (!val || !val.length) return 'Required';
												if (val && val.length && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({
														aspectRatio: imageSizes['BANNER_WIDTH_3_BY_2'] / imageSizes['BANNER_HEIGHT_3_BY_2'],
														file: val[0] as Blob,
													});
													return isValid
														? true
														: `Please upload ${imageSizes['BANNER_WIDTH_3_BY_2']}x${imageSizes['BANNER_HEIGHT_3_BY_2']}px aspect ratio image`;
												}
											}}
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Banner for Featured Program (300x459 px Aspect Ratio image)"
											name="bannerFeaturedProgram"
											validate={async (val) => {
												// if (!isNotEmptyArray(val)) return 'Required';
												if (isNotEmptyArray(val) && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({
														aspectRatio:
															imageSizes['DASHBOARD_BANNER_FEATURED_PROGRAM_WIDTH'] /
															imageSizes['DASHBOARD_BANNER_FEATURED_PROGRAM_HEIGHT'],
														file: val[0] as Blob,
													});
													return isValid
														? true
														: `Please upload ${imageSizes['DASHBOARD_BANNER_FEATURED_PROGRAM_WIDTH']}x${imageSizes['DASHBOARD_BANNER_FEATURED_PROGRAM_HEIGHT']}px aspect ratio image`;
												}
											}}
										/>
									</Col>
								</Row>

								<Col sm="12" className="mt-4">
									<Button size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/dashboard/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
