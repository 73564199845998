import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { accessDeepObject, isNotEmptyArray, stringifyQueryParams } from 'utils/commonHelpers';
import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import Filter from 'reusableComponents/Filter';
import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import { setApiParams } from './QuizMediaSlice';
import { fetchMedia, updateMedia } from './actions/media.action';
import history from '../../App/history';

const Actions = ({ mediaId, mediaTitle, status }) => {
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const statusUpdateHandler = () => {
		dispatch(
			updateMedia({
				id: mediaId,
				status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
			})
		);
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => history.push(`/activity/media/add-edit${stringifyQueryParams({ mediaId, mediaTitle })}`)}
					disabled={false}
				>
					Edit Activity Media
				</DropdownItem>
				<DropdownItem onClick={statusUpdateHandler}>Mark as {status === 'ACTIVE' ? 'Inactive' : 'Active'}</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createRows = ({ quizMedia }) => {
	return quizMedia.map(({ id, mediaTitle, status, type }) => ({
		mediaId: id,
		mediaTitle,
		status,
		type,
		backgroundColor: status === 'ACTIVE' ? 'white' : 'lightgrey',
		actions: <Actions mediaId={id} mediaTitle={mediaTitle} status={status} />,
	}));
};

const heads: TableHeads<any> = [
	{ accessor: 'mediaTitle', Header: 'Title' },
	{ accessor: 'type', Header: 'Media Type' },
	{ accessor: 'status', Header: 'status' },
	{ accessor: 'actions', Header: 'Actions' },
];

const QuizMediaList = () => {
	const dispatch = useDispatch();

	const [searchTerm, setSearchTerm] = React.useState('');
	const [quizMedia, setQuizMedia] = React.useState([]);

	const onSearch = (e) => {
		dispatch(setApiParams({ searchKey: searchTerm }));
		e?.preventDefault?.();
		dispatch(fetchMedia({ ...quizMediaState.apiParams, searchKey: searchTerm }));
	};

	const onSort = (sortKey, sortDirection) => {
		if (sortKey === 'action' && sortDirection === 'NONE') return;

		const sortOrder = sortDirection === 'ASC' ? 1 : -1;
		const limit = quizMediaState?.apiParams?.limit;
		const pageOfItems = quizMediaState?.page;
		const skip = quizMediaState?.apiParams?.skip * (pageOfItems - 1);

		dispatch(setApiParams({ ...quizMediaState?.apiParams, limit, sortOrder, skip, sortKey }));
		dispatch(fetchMedia({ ...quizMediaState?.apiParams, limit, sortOrder, skip, sortKey }));
	};

	const onChangePage = (itemsPage) => {
		const { apiParams } = quizMediaState;
		if (itemsPage) {
			const skip = (itemsPage - 1) * apiParams.limit;
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip,
				})
			);
			dispatch(fetchMedia({ ...apiParams, skip }));
		}
	};

	const quizMediaState = useSelector(({ quizMedia }) => ({
		quizMedia: quizMedia.quizMedia,
		apiParams: quizMedia.apiParams,
		total: quizMedia.total,
		page: quizMedia.page,
	}));

	React.useEffect(() => {
		dispatch(fetchMedia({ ...quizMediaState?.apiParams }));
	}, []);

	React.useEffect(() => {
		if (isNotEmptyArray(quizMediaState?.quizMedia)) {
			const quizMedia = createRows({ quizMedia: quizMediaState?.quizMedia });
			setQuizMedia(quizMedia);
		}
	}, [quizMediaState?.quizMedia]);

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={7} className="mb-3">
						<h3>Activity Media Listing</h3>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search by media title" onSearch={onSearch} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
						<Button size="sm" color="primary" onClick={() => history.push('/activity/media/add-edit')}>
							Add
						</Button>
					</Col>
				</Row>
				<Box mt="2rem">
					<If
						condition={isNotEmptyArray(quizMedia)}
						then={
							<>
								<DataPaginationTable heads={heads} rows={quizMedia} onSort={onSort} />
								<Pagination
									itemsCount={quizMediaState.total}
									itemsToShow={accessDeepObject('apiParams.limit', quizMediaState)}
									pageOfItems={quizMediaState.page}
									onChangePage={onChangePage}
								/>
							</>
						}
						else={quizMediaState.total === 0 && <div>No data</div>}
					/>
				</Box>
			</CardBody>
		</Card>
	);
};

export default QuizMediaList;
