import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getExerciseQuestion } from '../utils/helpers';
import { ContentDocumentStatusEnum, RankLevelEnum, TExercise, TExerciseQuestion, TExercisesState, TSubtopic } from '../utils/types';

export const fetchExerciseQuestions = createAsyncThunk(
	'/exercises/fetchExerciseQuestions',
	async (
		apiParams: TExercisesState['apiParams'] & { exerciseId?: string; subtopicId?: string; questionType?: string } & {
			parentId?: string;
			siblingId?: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-exercise-questions`, {
				method: 'POST',
				body: apiParams,
			});

			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchSimilarQuestions = createAsyncThunk(
	'/exercises/fetchSimilarQuestions',
	async (apiParams: TExercisesState['apiParams'] & { parentId?: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-similar-questions`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const reorderExerciseQuestions = createAsyncThunk(
	'/exercises/reorderExerciseQuestions',
	async ({ currentIdx, move, exerciseId }: { currentIdx: number; move: string; exerciseId?: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/reorder-exercise-questions`, {
				method: 'POST',
				body: { currentIdx, move, exerciseId },
			});
			const { data } = response;
			dispatch(
				fetchExerciseQuestions({
					limit: 50,
					skip: 0,
					query: '',
					sortKey: 'score',
					sortOrder: '-1',
					exerciseId,
				})
			);
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchExercises = createAsyncThunk(
	'/exercises/fetchExercises',
	async (apiParams: TExercisesState['apiParams'] & { subtopicId: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-exercises`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateExerciseRank = createAsyncThunk(
	'/exercises/updateExerciseRank',
	async (payload: { subtopicId: string; rank: number; exerciseId: string; updatedBy: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper('/v1/admin/update-exercise-rank', {
				method: 'PUT',
				body: payload,
			});
			const { data } = response;
			if (data.status !== 'success') {
				throw new Error(data.message);
			} else {
				showToast('success', 'Exercise successfully updated');
				return payload;
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);
export const fetchCurriculums = createAsyncThunk(
	'/exercises/fetchCurriculums',
	async (apiParams: TExercisesState['apiParams'], { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-curriculums`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchModules = createAsyncThunk(
	'/exercises/fetchModules',
	async (apiParams: TExercisesState['apiParams'] & { curriculumId: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-modules`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateModuleRank = createAsyncThunk(
	'/exercises/updateModuleRank',
	async (payload: { adminCode: string; rank: number; curriculumId: string; rankLevel: RankLevelEnum; updatedBy: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper('/v1/admin/update-module-rank', {
				method: 'PUT',
				body: payload,
			});
			const { data } = response;
			if (data.status !== 'success') {
				throw new Error(data.message);
			} else {
				showToast('success', 'Module successfully updated');
				return payload;
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchModule = createAsyncThunk(
	'/exercises/fetchModule',
	async (apiParams: { curriculumId: string; moduleEnum: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-module`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateModule = createAsyncThunk(
	'/exercises/updateModule',
	async (
		apiParams: {
			curriculumId: string;
			moduleEnum: string;
			title?: string;
			description?: string;
			status?: ContentDocumentStatusEnum;
			updatedBy: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper(`/v1/admin/update-module`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			showToast('success', 'Module successfully updated');
			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateModuleStatus = createAsyncThunk(
	'/exercises/updateModuleStatus',
	async (
		apiParams: { curriculumId: string; moduleEnum: string; title?: string; description?: string; status?: ContentDocumentStatusEnum },
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper(`/v1/admin/update-module`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			showToast('success', 'Module successfully updated');
			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);
export const addModule = createAsyncThunk(
	'/exercises/addModule',
	async (apiParams: { curriculumId: string; moduleEnum: string; title: string; description?: string; updatedBy: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/add-module`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			showToast('success', 'Module successfully updated');
			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);
export const fetchSubtopic = createAsyncThunk(
	'/exercises/fetchSubtopic',
	async (
		apiParams: {
			subtopicId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/get-subtopic', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			return { subtopic: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchSubtopics = createAsyncThunk(
	'/exercises/fetchSubtopics',
	async (apiParams: TExercisesState['apiParams'] & { moduleEnum: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/get-subtopics`, {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);
export const updateSubtopicRank = createAsyncThunk(
	'/exercises/updateSubtopicRank',
	async (payload: { moduleAdminCode: string; rank: number; subtopicId: string; updatedBy: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper('/v1/admin/update-subtopic-rank', {
				method: 'PUT',
				body: payload,
			});
			const { data } = response;
			if (data.status !== 'success') {
				throw new Error(data.message);
			} else {
				showToast('success', 'Subtopic successfully updated');
				return payload;
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const syncExercises = createAsyncThunk(
	'/exercises/syncExercises',
	async (
		apiParams: {
			startRowIndex?: number;
			endRowIndex?: number;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/exercises/sync-from-sheet', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			showToast('success', 'Activities successfully synced');
			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const setExerciseStatus = createAsyncThunk(
	'/exercises/setExerciseStatus',
	async (
		apiParams: {
			exerciseId: string;
			status: ContentDocumentStatusEnum;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/set-exercise-status', {
				method: 'PUT',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}

			showToast('success', 'Exercise status successfully updated');
			if (data) return { isUpdated: data.isUpdated, status: data.isUpdated ? apiParams.status : null };
			return null;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const setExerciseQuestionStatus = createAsyncThunk(
	'/exercises/setExerciseQuestionStatus',
	async (
		apiParams: {
			questionId: string;
			status: ContentDocumentStatusEnum;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/set-exercise-question-status', {
				method: 'PUT',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}

			showToast('success', 'Exercise question status successfully updated');
			if (data) return { isUpdated: data.isUpdated, status: data.isUpdated ? apiParams.status : null };
			return null;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchExerciseQuestion = createAsyncThunk(
	'/exercises/fetchExerciseQuestion',
	async (
		apiParams: {
			questionId?: string;
			questionAdminCode?: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await getExerciseQuestion({ apiParams });
			const { error, exerciseQuestion } = response;
			if (response.error) {
				dispatch({ type: setError.type, payload: { errors: error } });
				return null;
			}
			return { exerciseQuestion };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchExercise = createAsyncThunk(
	'/exercises/fetchExercise',
	async (
		apiParams: {
			exerciseId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/get-exercise', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			return { exercise: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateExerciseQuestion = createAsyncThunk(
	'/exercises/updateExerciseQuestion',
	async (
		apiParams: {
			questionId: string;
			exerciseId: string;
			updatedQuestion: Partial<TExerciseQuestion>;
			mode: string;
			userId: string;
		},
		{ dispatch }
	) => {
		try {
			const isEditMode = apiParams.mode === 'edit';
			delete apiParams.mode;
			const response = await fetchWrapper('/v1/admin/add-or-update-exercise-question', {
				method: 'PUT',
				body: {
					...apiParams,
					edit: isEditMode,
				},
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			showToast('success', 'Exercise question successfully updated');
			if (!isEditMode) history.replace(`/exercise/questions/list${stringifyQueryParams({ exerciseId: apiParams.exerciseId })}`, '_self');
			return { updatedQuestion: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateExercise = createAsyncThunk(
	'/exercises/updateExercise',
	async (
		apiParams: {
			subtopicId: string;
			exerciseId: string;
			updatedExercise: Partial<TExercise>;
			mode: string;
			userId: string;
			takeLeaderboardSnapshot: boolean;
		},
		{ dispatch }
	) => {
		try {
			const isEditMode = apiParams.mode === 'edit';
			delete apiParams.mode;
			const response = await fetchWrapper('/v1/admin/add-or-update-exercise', {
				method: 'PUT',
				body: {
					...apiParams,
					edit: isEditMode,
				},
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			showToast('success', 'Exercise successfully updated');
			if (!isEditMode) history.replace(`/exercise/list${stringifyQueryParams({ subtopicId: apiParams.subtopicId })}`, '_self');
			return { updatedExercise: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateSubtopic = createAsyncThunk(
	'/exercises/updateSubtopic',
	async (
		apiParams: {
			subtopicId: string;
			updatedSubtopic: Partial<TSubtopic>;
			userId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/update-subtopic', {
				method: 'PUT',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			showToast('success', 'Subtopic successfully updated');
			return { updatedSubtopic: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const toggleExerciseQuestionStatus = createAsyncThunk(
	'/exercises/toggleExerciseQuestionStatus',
	async (
		apiParams: {
			questionId: string;
			exerciseId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/toggle-exercise-question-status', {
				method: 'PUT',
				body: apiParams,
			});
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return false;
			}
			showToast('success', 'Exercise question successfully uupdated');
			// need to reload the window, when there is change in number of questions
			window.location.reload();
			return true;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const deleteExerciseQuestion = createAsyncThunk(
	'/exercises/deleteExerciseQuestion',
	async (
		apiParams: {
			questionId: string;
			exerciseId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/v1/admin/delete-exercise-question', {
				method: 'DELETE',
				body: apiParams,
			});
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return false;
			}
			showToast('success', 'Exercise question successfully deleted');
			// need to reload the window, when there is change in number of questions
			window.location.reload();
			return true;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const addQuestionToExercise = createAsyncThunk(
	'/exercises/addQuestionToExercise',
	async (apiParams: { questionId?: string; questionAdminCode?: string; exerciseId: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper('/v1/admin/add-question-to-exercise', {
				method: 'PUT',
				body: apiParams,
			});
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return false;
			}
			showToast('success', 'Exercise question successfully added');
			// need to reload the window, when there is change in number of questions
			window.location.reload();
			return true;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

// eslint-disable-next-line no-empty-pattern
export const getAllCourses = createAsyncThunk('/exercises/getAllCourses', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/v1/admin/get-courses`, {
			method: 'GET',
		});
		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});
