import React, { useEffect, useState } from 'react';
import formatDate from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe, omitWrapper } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { fetchWrapper } from 'utils/fetchWrapper';
import { WEBSITE_URL, clickActionOptions, CLICK_ACTION_OPTIONS } from 'utils/constants';
import { validateExternalWebAddress } from 'reusableComponents/Form/fieldValidators';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { IProgramType, programTypes } from 'types/Program';
import If from 'reusableComponents/If';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { updateBatchToClass } from '../actions/batchtoclass.actions';

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface BatchFormValues {
	id: string;
	classNum: string;
	dateTime: string;
	clickActionType?: { label: string; value: string };
	clickActionText?: string;
	clickActionUrl?: string;
	isStaticButtonEnabled?: boolean;
}

export type BatchClassValuesFromDatabase = {
	id: string;
	classNum: string;
	dateTime: string;
	staticButton?: {
		buttonCTA: {
			ctaType: 'APP_LINK' | 'EXTERNAL_LINK' | 'WEBVIEW';
			ctaUrl: string;
		};
		buttonText: string;
	};
	createdBy: string;
	updatedby: string;
};

interface ModifyBatchBeforeCreateOrUpdateArgs extends BatchFormValues {
	_id?: string;
	updatedBy: string;
}

export const modifyBatchBeforeCreateOrUpdate = (values: ModifyBatchBeforeCreateOrUpdateArgs) => {
	const staticButton = values.clickActionType
		? {
				buttonCTA: {
					ctaType: values.clickActionType?.value,
					ctaUrl: values.clickActionType?.value === 'APP_LINK' ? `${WEBSITE_URL}/${values.clickActionUrl}` : values.clickActionUrl,
				},
				buttonText: values.clickActionText,
		  }
		: null;
	return {
		...omitWrapper(['clickActionType', 'clickActionUrl', 'clickActionText', 'isStaticButtonEnabled'], values),
		dateTime: new Date(values.dateTime).toISOString(),
		...(staticButton && values.isStaticButtonEnabled ? { staticButton } : { staticButton: null }),
	};
};

export const modifyBatchBeforeInitialize = (values: BatchClassValuesFromDatabase): BatchFormValues => {
	const dateString = formatDate(new Date(values.dateTime), 'yyyy-MM-dd');
	const timeString = formatDate(new Date(values.dateTime), 'HH:mm');
	const ctaUrl = values.staticButton?.buttonCTA?.ctaUrl;
	const clickActionType = clickActionOptions.find((actionType) => actionType.value === values.staticButton?.buttonCTA?.ctaType);
	const clickActionText = values.staticButton?.buttonText;
	const clickActionUrl = values.staticButton?.buttonCTA?.ctaType === 'APP_LINK' ? ctaUrl?.split('/')?.pop() : ctaUrl;

	const dateTime = `${dateString}T${timeString}`;
	return {
		...omitWrapper(['staticButton'], values),
		dateTime,
		...(values.staticButton
			? {
					isStaticButtonEnabled: true,
					clickActionText,
					clickActionType,
					clickActionUrl,
			  }
			: { isStaticButtonEnabled: false }),
	};
};

interface BatchClassEditFormProps {
	location: { search: string };
}

export const BatchClassEdit = (props: BatchClassEditFormProps) => {
	const { programType, batchToClassId }: { batchToClassId?: string; programType?: IProgramType } = parseQueryParams(props.location.search);
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<BatchClassValuesFromDatabase>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.batchClass.isSubmitting,
	}));
	const hookFormMethods = useForm<BatchFormValues>();
	const { handleSubmit, watch } = hookFormMethods;
	const watchFields = watch(['clickActionType', 'isStaticButtonEnabled']);

	useEffect(() => {
		fetchWrapper(`/v1/admin/batchtoclass?batchToClassId=${batchToClassId}`, { method: 'GET' }).then((res) => {
			const btcData = res?.data || {};
			const isPastDatedClass = new Date() > new Date(btcData.dateTime);
			if (isPastDatedClass && programType !== 'COMPETITION') history.push('/batch-class/list');

			setInitialValues(btcData as BatchClassValuesFromDatabase);
			hookFormMethods.reset(modifyBatchBeforeInitialize(btcData as any));
		});
	}, []);

	const onSubmit = (values: BatchFormValues) => {
		const modifiedValues = modifyBatchBeforeCreateOrUpdate({
			...values,
			_id: initialValues.id,
			updatedBy: loggedInUser.id,
		});

		dispatch(updateBatchToClass(modifiedValues));
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Batch Class...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>Edit Batch Class</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<Input label="classNum" name="classNum" required isClickDisabled />
									</Col>
									<Col sm="6">
										<Input
											type="datetime-local"
											label="Date Time"
											name="dateTime"
											min={
												programType !== 'COMPETITION' ? `${formatDate(new Date(), 'yyyy-MM-dd')}T${formatDate(new Date(), 'HH:mm')}` : null
											}
											helpText="Click on calendar icon to open the calendar"
											required
										/>
									</Col>
								</Row>

								<If
									condition={programType === 'COMPETITION'}
									then={
										<>
											<Checkbox label="Custom CTA" name="isStaticButtonEnabled" />
											<If
												condition={watchFields.isStaticButtonEnabled}
												then={
													<>
														<Row>
															<Col sm="6">
																<Input label="Static CTA Text" name="clickActionText" required />
															</Col>
															<Col sm="6">
																<SelectField label="Click Action Type" name="clickActionType" options={clickActionOptions} required />
															</Col>
														</Row>

														<Row>
															<Box w="100%" d="flex" ai="center" jc="flex-start">
																<If
																	condition={watchFields?.clickActionType?.value === CLICK_ACTION_OPTIONS.APP_LINK}
																	then={<p style={{ paddingLeft: '15px', marginBottom: '15px' }}>{WEBSITE_URL}/</p>}
																/>
																<Col sm="6">
																	<Input
																		label="Click Action URL"
																		name="clickActionUrl"
																		required
																		validate={(val) => {
																			if (watchFields?.clickActionType?.value === CLICK_ACTION_OPTIONS.EXTERNAL_LINK)
																				return validateExternalWebAddress(val) || true;
																			return true;
																		}}
																	/>
																</Col>
															</Box>
														</Row>
													</>
												}
											/>
										</>
									}
								/>

								<Col sm="12" className="mt-4">
									<Button size="sm" color="primary" type="submit">
										Save
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/batch/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
