import styled from 'styled-components';
import theme from 'utils/theme';

export const StyledModal = styled.div`
	padding: 0;
	width: 480px;
	border-radius: 10px;

	@media (max-width: ${theme.size.xs}) {
		width: 95vw;
		overflow-y: auto;
	}

	@media (max-height: ${theme.size.xxs}) and (orientation: landscape) {
		width: 90vw;
		height: 90vh;
		overflow-y: auto;
	}
`;

export const StyledContainer = styled.div`
	padding: 4rem 3.2rem;
	min-height: 100%;
	display: flex;
	flex-direction: column;

	@media ${({ theme }) => theme.device.xs} {
		padding: 1.6rem 2.4rem;
	}

	@media (max-height: ${({ theme }) => theme.size.xxs}) and (orientation: landscape) {
		flex-direction: row;
	}
`;

export const StyledSubContainer = styled.div`
	@media (max-height: ${({ theme }) => theme.size.xxs}) and (orientation: landscape) {
		display: flex;
		flex-direction: column;
		width: 40%;
		margin-top: 9rem;
		position: relative;
	}
`;

export const StyledInfoText = styled.div`
	font-size: 1.6rem;
	font-weight: 500;
	opacity: 0.5;
	color: ${(props) => props.theme.clrs.new.cPrimaryText};
	margin-bottom: 0.8rem;
`;

export const StyledQuestionContainer = styled.div`
	display: flex;
	padding-bottom: 1rem;

	@media (max-height: ${({ theme }) => theme.size.xxs}) and (orientation: landscape) {
		flex-direction: column;
	}
`;

export const StyledQuestion = styled.div`
	font-size: 1.6rem;
	font-weight: 600;
	color: ${(props) => props.theme.clrs.new.cPrimaryText};
	padding-right: 3.2rem;
	flex: 1;
`;

export const StyledTimer = styled.div`
	@media (max-height: ${({ theme }) => theme.size.xxs}) and (orientation: landscape) {
		position: absolute;
		top: -45px;
		left: 33%;
		transform: translate(-50%, -50%);
	}
`;

export const StyledBadge = styled.div`
	padding: 0 0.5rem;
	width: 4.8rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
