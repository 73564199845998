import videojs from 'video.js';

import { STATUS_SESSION_SCHEDULED } from '../../utils/constants';
import { omitWrapper, accessDeepObject, isNotEmptyObject, StringMaybe } from '../../utils/commonHelpers';
import { CLASS_COLOURS } from './constants';

const DEFAULT_POLL_DURATION = 30;
const DEFAULT_POLL_TIME_DURATION = 10;

export const modifyBeforeSaveClass = (params, cls) => {
	const mins = parseInt(params.duration.min, 10) * 60;
	const durationInSecs = mins + parseInt(params.duration.sec, 10);
	const modifiedDescription = StringMaybe(params.description).replaceAll('<a href', "<a target='_blank' href");
	// NOTE: Preferring Video URL if both Video URL and Video File are filled
	return {
		...omitWrapper(
			[
				'program',
				'videoDetails',
				...(cls && params.dateTime.toISOString() === cls.dateTime ? ['dateTime'] : []),
				...(cls && params.topic === cls.topic ? ['topic'] : []),
				...(cls && params.duration === cls.duration ? ['duration'] : []),
			],
			params
		),
		...(params.description && { description: modifiedDescription }),
		duration: durationInSecs,
		photos: params.photos.map((dd) => (dd.id ? omitWrapper(['name', 'preview'], dd) : dd)),
		/* eslint-disable no-nested-ternary */
		coverPicture: isNotEmptyObject(params.coverPicture)
			? params.coverPicture.url
				? omitWrapper(['name', 'preview'], params.coverPicture)
				: { file: params.coverPicture }
			: null,
		programId: params.program,
		coverColor: CLASS_COLOURS[Math.floor(Math.random() * CLASS_COLOURS.length)],
		status: params.status || STATUS_SESSION_SCHEDULED,
	};
};

export const modifyBeforeSetClass = (params) => {
	return {
		...omitWrapper(['dateTime'], params),
		program: {
			label: `${params.program.title} (${
				isNotEmptyObject(params.program.ageRange) ? `${params.program.ageRange.min}-${params.program.ageRange.max}yrs` : ''
			})`,
			value: params.program.id,
		},
		duration: { min: Math.floor(params.duration / 60), sec: params.duration % 60 },
		...(params.dateTime && { dateTime: new Date(params.dateTime) }),
		...(params.videoDetails && {
			video: {
				...params.videoDetails,
				...(!params.videoDetails.adminUploaded && {
					url: `https://player.vimeo.com/video/${params.videoDetails.videoId}`,
				}),
			},
		}),
	};
};

export const modifyBeforeSavePolls = (params, originalPolls) => {
	const polls = params.polls.map((poll) => {
		const mins = parseInt(poll.triggerTime.min, 10) * 60;
		const triggerTime = mins + parseInt(poll.triggerTime.sec, 10);

		return {
			...poll,
			triggerTime,
			answer: poll.questionType === 'ASSESSMENT' ? accessDeepObject(`options.${poll.answer}.option`, poll) : undefined,
			classId: params.classId,
			// if badgeId is a string then direclty save it
			// else save the poll.badgeId.value
			badgeId: typeof poll.badgeId === 'string' ? poll.badgeId : accessDeepObject('badgeId.value', poll),
		};
	});
	const deletedPolls = originalPolls.reduce((acc, curr) => {
		if (curr.id) {
			if (polls.findIndex((poll) => poll.id === curr.id) === -1) {
				return [...acc, { ...curr, isDelete: true }];
			}
			return acc;
		}
		return [...acc, curr];
	}, []);
	return { polls: [...polls, ...deletedPolls] };
};

/**
 * Get Time in Secs
 * @param {object} time {min, sec}
 */
export const convertTime = (time) => {
	const triggerMinInSecs = parseInt(time.min, 10) * 60;
	return triggerMinInSecs + parseInt(time.sec, 10);
};

const POLL_OVERLAP_THRESHOLD = 1;

/**
 * Function to check if polls overlap
 * @param {object} params { polls }
 * @param {int} videoDuration
 * @returns {boolean | string } describing whether polls overlap with proper error message
 */
export const doPollsOverlap = (polls, videoDuration) => {
	if (polls.length <= 1) return false;

	// eslint-disable-next-line no-plusplus
	for (let i = 1; i < polls.length; i++) {
		const currentPoll = polls[i];
		const prevPoll = polls[i - 1];

		const prevPollEndTimeInSecs =
			convertTime(prevPoll.triggerTime) +
			(parseInt(prevPoll.duration, 10) || DEFAULT_POLL_DURATION) +
			(parseInt(prevPoll.waitDuration, 10) || DEFAULT_POLL_TIME_DURATION) +
			(parseInt(prevPoll.showResultDuration, 10) || DEFAULT_POLL_TIME_DURATION);

		const currentPollTriggerTimeInSecs = convertTime(currentPoll.triggerTime);
		const diffBetweenPollsInSecs = currentPollTriggerTimeInSecs - prevPollEndTimeInSecs;
		// 15 seconds
		if (diffBetweenPollsInSecs < POLL_OVERLAP_THRESHOLD) {
			return `Poll ${i} and Poll ${i + 1} should have an interval of ${POLL_OVERLAP_THRESHOLD} seconds`;
		}

		//  check whether the current poll end time is greater than classDuration
		const currentPollEndTimeInSecs =
			convertTime(currentPoll.triggerTime) +
			(parseInt(currentPoll.duration, 10) || DEFAULT_POLL_DURATION) +
			(parseInt(currentPoll.waitDuration, 10) || DEFAULT_POLL_TIME_DURATION) +
			(parseInt(currentPoll.showResultDuration, 10) || DEFAULT_POLL_TIME_DURATION);

		if (currentPollEndTimeInSecs > videoDuration) {
			return `Poll ${i + 1} total duration exceeds the video duration`;
		}
	}

	return false;
};

/**
 *
 * @param videoTagId The id of the video tag element rendered in dom
 * @returns VideoJsPlayer instance
 */
export const getPlayerInstance = ({ videoTagId = 'my-player' }) =>
	videojs(videoTagId, {
		autoplay: false,
		controls: true,
		loop: false,
		preload: 'auto', // Starts downloading the video as soon as its ready
	});

export const getSanitizedVideoUrl = (relativePath) => {
	// Fix for corrupt data
	if (!relativePath) return undefined;

	const containsLeadingSlash = relativePath.startsWith('/');
	const cloudFrontPrefix = containsLeadingSlash
		? process.env.REACT_APP_CLOUDFRONT_VIDEO_PREFIX
		: `${process.env.REACT_APP_CLOUDFRONT_VIDEO_PREFIX}/`;

	return relativePath.match(/^https?/) ? relativePath : `${cloudFrontPrefix}${relativePath}`;
};
