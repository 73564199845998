import { takeLatest } from 'redux-saga/effects';

import { getMentors, getMentor, getMentorId, mentorOnSubmit, setMentorStatus, setMentorFeatured } from './mentorSlice';
import { getMentorsSaga } from './sagas/getMentorsSaga';
import { getMentorSaga } from './sagas/getMentorSaga';
import { getMentorIdSaga } from './sagas/getMentorIdSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { setMentorStatusSaga } from './sagas/setMentorStatusSaga';
import { setMentorFeaturedSaga } from './sagas/setMentorFeaturedSaga';

export default function* programSaga() {
	yield takeLatest(getMentors.type, getMentorsSaga);
	yield takeLatest(getMentor.type, getMentorSaga);
	yield takeLatest(getMentorId.type, getMentorIdSaga);
	yield takeLatest(mentorOnSubmit.type, onSubmitSaga);
	yield takeLatest(setMentorStatus.type, setMentorStatusSaga);
	yield takeLatest(setMentorFeatured.type, setMentorFeaturedSaga);
}
