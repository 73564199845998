import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomModal from 'reusableComponents/CustomModal';
import theme from 'utils/theme';
import Box from 'reusableComponents/Box';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { SelectField } from 'reusableComponents/Form/Select';
import formatDate from 'date-fns/format';
import { toast } from 'react-toastify';

interface MarkClassAsLiveModal {
	closeModal: any;
	onConfirm: any;
	initialValues: any;
}

const StyledModal = styled.div`
	max-height: 50vh;
	width: 25rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const isLiveClassByAdminList = [
	{ label: 'True', value: true },
	{ label: 'False', value: false },
];

export default function MarkClassAsLiveModal({ closeModal, onConfirm, initialValues }) {
	const [isLiveClassByAdmin, setIsLiveClassByAdmin] = useState(initialValues.isLiveClassByAdmin);
	const [liveClassEndDate, setliveClassEndDate] = useState(initialValues.liveClassEndDate);
	const [liveClassStartDate, setliveClassStartDate] = useState(initialValues.liveClassStartDate);

	const onSubmit = () => {
		if (liveClassStartDate && liveClassEndDate) {
			if (new Date(liveClassStartDate) > new Date(liveClassEndDate)) {
				toast.error('Start date must be less than end date');
				return;
			}
		}
		onConfirm({ isLiveClassByAdmin, liveClassStartDate, liveClassEndDate });
	};

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					<Box w="100%" ellipse fs="1.4rem">
						Mark Class As Live
					</Box>
				</Box>
				<Row className="mb-2">
					<Col sm={12}>
						<Label>Set Class As Live</Label>
						<SelectField
							placeholder="Mark Class As Live"
							name="isLiveClassByAdmin"
							options={isLiveClassByAdminList}
							value={isLiveClassByAdmin}
							onChange={(val) => setIsLiveClassByAdmin(val)}
						/>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col sm={12}>
						<Label>Live class Start Time</Label>
						<Input
							type="datetime-local"
							name="liveClassStartDate"
							label="Enrollment Start Time"
							required
							defaultValue={liveClassStartDate && formatDate(new Date(liveClassStartDate), `yyyy-MM-dd'T'HH:mm`)}
							helpText="Click on calendar icon to open calendar"
							onChange={(val) => {
								setliveClassStartDate(new Date(val.target.value).toISOString());
							}}
						/>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col sm={12}>
						<Label>Live Class End Time</Label>
						<Input
							type="datetime-local"
							name="liveClassEndDate"
							required
							defaultValue={liveClassEndDate && formatDate(new Date(liveClassEndDate), `yyyy-MM-dd'T'HH:mm`)}
							helpText="Click on calendar icon to open calendar"
							onChange={(val) => setliveClassEndDate(new Date(val.target.value).toISOString())}
						/>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col sm={6}>
						<Button className="mb-1 w-100" size="sm" color="primary" onClick={closeModal}>
							Cancel
						</Button>
					</Col>
					<Col sm={6}>
						<Button className="mb-1 w-100" size="sm" color="primary" onClick={onSubmit}>
							Confirm
						</Button>
					</Col>
				</Row>
			</StyledModal>
		</CustomModal>
	);
}
