import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { getClassIdSuccess } from '../classSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getClassIdSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/class/id`);
	if (res.status === 200) {
		yield put(actionSpreader(getClassIdSuccess.type, { classId: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
