import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { toggleLoading, getMentorSuccess } from '../mentorSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getMentorSaga({ payload }) {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/mentor/${payload.id}`);
	if (res.status === 200) {
		yield put(actionSpreader(getMentorSuccess.type, { mentor: res.data }));
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
