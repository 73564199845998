import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import { Col, Row, Button, Card, CardBody, ButtonToolbar, Badge } from 'reactstrap';

import { fetchWrapper } from 'utils/fetchWrapper';
import { SelectField } from 'reusableComponents/Form/Select';
import { renderTextField, renderTextAreaField, renderRadioButtonGroup } from '../../../../reusableComponents/Form/formComponents';
import { isMP4VideoURL, maxLength, required } from '../../../../reusableComponents/Form/fieldValidators';
import { getMentor, getMentorId, mentorOnSubmit, getMentorSuccess } from '../../mentorSlice';
import { STATUS_ACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';

import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import Box from '../../../../reusableComponents/Box';
import {
	pickWrapper,
	parseQueryParams,
	isNotEmptyObject,
	showToast,
	getSanitizedImageUrl,
	isNotEmptyArray,
} from '../../../../utils/commonHelpers';
import DropZoneField from '../../../../reusableComponents/Form/DropZone';
import { modifyBeforeSaveMentor, modifyBeforeSetMentor } from '../../helpers';
import { renderFieldArray } from '../../../Program/helpers';

const maxAboutValidation = (val) => maxLength(250)(val);
const slugValidation = (val) => {
	const pattern = new RegExp(/^[a-z0-9-]*$/);
	const isValid = pattern.test(val) && !required(val);

	return isValid ? undefined : 'Invalid Slug URL';
};
const GENDER_FIELDS = [
	{
		label: 'Male',
		value: 'MALE',
	},
	{
		label: 'Female',
		value: 'FEMALE',
	},
];

const HLS_STREAM_CONVERT_COMPLETE = 4;

const fetchVideos = async () => {
	try {
		const res = await fetchWrapper(
			`/v1/admin/video?videoType=MENTOR&hlsStreamStatus=${HLS_STREAM_CONVERT_COMPLETE}&sortKey=createdAt&sortOrder=-1`,
			{
				method: 'GET',
			}
		);
		return res.data.videos.map((v) => ({ label: v.videoName, value: v.videoUrl }));
	} catch {
		return [];
	}
};

const MentorAddEdit = (props) => {
	const isActive = isNotEmptyObject(props.initialValues) ? props.initialValues.status === STATUS_ACTIVE : false;
	const [mentorVideos, setMentorVideos] = useState([]);

	useEffect(() => {
		fetchVideos().then(setMentorVideos);
	}, []);

	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="12">
						<h3>
							{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} Mentor &nbsp;
							<Badge color={isActive ? 'primary' : 'danger'}>{isActive ? 'Active' : 'Inactive'}</Badge>
						</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="12">
						<Field label="Name" isRequired validate={required} type="text" component={renderTextField} name="fullName" />
					</Col>
					<Col sm="12">
						<Field
							label="Slug"
							isRequired
							validate={slugValidation}
							type="text"
							component={renderTextField}
							name="slug"
							value={props.slug}
						/>
					</Col>
					<Col sm="12">
						<Field
							label="Tagline"
							isRequired
							validate={required}
							placeholder="Max 52 characters"
							type="text"
							component={renderTextField}
							name="tagline"
						/>
					</Col>
					<Col sm="12">
						<Field
							label="Gender"
							labelName="gender"
							name="gender"
							fields={GENDER_FIELDS}
							component={renderRadioButtonGroup}
							isRequired
							validate={required}
						/>
					</Col>
					<Col sm="12">
						<Field
							label="About"
							// isRequired
							validate={maxAboutValidation}
							type="text"
							component={renderTextAreaField}
							name="about"
							placeholder="Maximum 250 characters allowed"
						/>
					</Col>
					<Col sm="6" className="mb-3">
						<span className="form__form-group-label">
							Specializes In <span className="form__form_group-label--required">&#42;</span>
						</span>
						<FieldArray name="specialities" buttonText="Add New Skill Set Tag" label="Speciality" component={renderFieldArray} />
					</Col>
					<Col sm="6" className="mb-4">
						<span className="form__form-group-label">
							Key Higlights <span className="form__form_group-label--required">&#42;</span>
						</span>
						<FieldArray name="keyHighlights" buttonText="Add New Highlight" label="Highlight" component={renderFieldArray} />
					</Col>
					<Col sm="12">
						<span className="form__form-group-label">
							Profile Picture <span className="form__form_group-label--required">&#42;</span>
						</span>
						<DropZoneField
							withCaption
							value={
								props.profilePic
									? [
											{
												photo: props.profilePic[0].photo,
												caption: props.profilePic ? props.profilePic[0].caption : '',
											},
									  ]
									: []
							}
							onChange={(v) => {
								props.setProfilePic(v.length ? [{ photo: v[0].photo, caption: v[0].caption }] : null);
							}}
						/>
					</Col>
					<Col sm="12">
						<span className="form__form-group-label">
							Mentor Cutout Picture <span className="form__form_group-label--required">&#42;</span>
						</span>
						<DropZoneField
							withCaption
							value={
								props.cutoutPic
									? [
											{
												photo: props.cutoutPic[0].photo,
												caption: props.cutoutPic ? props.cutoutPic[0].caption : '',
											},
									  ]
									: []
							}
							onChange={(v) => {
								props.setCutoutPic(v.length ? [{ photo: v[0].photo, caption: v[0].caption }] : null);
							}}
						/>
					</Col>
					<Col sm="12" className="mt-3">
						<Row>
							<Col sm="6">
								<Field label="Video URL" type="text" component={renderTextField} name="videoUrl" validate={isMP4VideoURL} />
							</Col>
							<Col sm="6">
								<span className="form__form-group-label">HLS Video URL</span>
								<SelectField value={props.hlsVideoUrl} onChange={props.setHlsVideoUrl} options={mentorVideos} name="hlsVideoUrl" />
							</Col>
							<Col sm="12">
								<span className="form__form-group-label">Video Cover Image</span>
								<DropZoneField
									withCaption
									value={
										props.videoCoverPicture
											? [
													{
														photo: props.videoCoverPicture[0].photo,
														caption: props.videoCoverPicture ? props.videoCoverPicture[0].caption : '',
													},
											  ]
											: []
									}
									onChange={(v) => {
										props.setVideoCoverPicture(v.length ? [{ photo: v[0].photo, caption: v[0].caption }] : null);
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col md="12" className="pt-4 pb-4">
						<h3>Featured Mentor</h3>
					</Col>
					<Col sm="4">
						<span className="form__form-group-label">Mobile Cover</span>
						<DropZoneField
							withCaption
							value={
								props.mobileCoverPic
									? [
											{
												photo: props.mobileCoverPic[0].photo,
												caption: props.mobileCoverPic ? props.mobileCoverPic[0].caption : '',
											},
									  ]
									: []
							}
							onChange={(v) => {
								props.setMobileCoverPic(v.length ? [{ photo: v[0].photo, caption: v[0].caption }] : null);
							}}
						/>
					</Col>
					<Col sm="8">
						<span className="form__form-group-label">Web Cover</span>
						<DropZoneField
							withCaption
							value={
								props.webCoverPic
									? [
											{
												photo: props.webCoverPic[0].photo,
												caption: props.webCoverPic ? props.webCoverPic[0].caption : '',
											},
									  ]
									: []
							}
							onChange={(v) => {
								props.setWebCoverPic(v.length ? [{ photo: v[0].photo, caption: v[0].caption }] : null);
							}}
						/>
					</Col>
					<ButtonToolbar className="form__button-toolbar">
						<Button type="button" onClick={() => history.push('/mentor/list')}>
							Cancel
						</Button>
						<Button color="primary" type="submit">
							Submit
						</Button>
					</ButtonToolbar>
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_mentor', enableReinitialize: true });

const MentorReduxForm = compose(withReduxForm)(MentorAddEdit);
const selector = formValueSelector('add_edit_mentor');

const MentorForm = (props) => {
	const [profilePic, setProfilePic] = useState(null);
	const [cutoutPic, setCutoutPic] = useState(null);
	const [mobileCoverPic, setMobileCoverPic] = useState(null);
	const [webCoverPic, setWebCoverPic] = useState(null);
	const [videoCoverPicture, setVideoCoverPicture] = useState(null);
	const [hlsVideoUrl, setHlsVideoUrl] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.search) {
			dispatch(getMentor(pickWrapper(['id'], parseQueryParams(props.location.search))));
		} else {
			dispatch(getMentorId());
		}
		return () => {
			dispatch(getMentorSuccess({}));
		};
	}, [props.location.search]);

	useEffect(() => {
		if (!props.location.search && props.mentorFullName)
			dispatch(change('add_edit_mentor', 'slug', props.mentorFullName?.toLowerCase().split(' ').join('-')));
	}, [props.mentorFullName]);

	useEffect(() => {
		if (isNotEmptyObject(props.mentor)) {
			setHlsVideoUrl(props.mentor.hlsVideoUrl);

			if (isNotEmptyObject(props.mentor.profilePic)) {
				setProfilePic([
					{
						photo: [
							{
								name: props.mentor.profilePic.id,
								id: props.mentor.profilePic.id,
								url: props.mentor.profilePic.url,
								preview: getSanitizedImageUrl(props.mentor.profilePic.url),
							},
						],
						caption: props.mentor.profilePic.caption,
					},
				]);
			}
			if (isNotEmptyObject(props.mentor.cutoutPic)) {
				setCutoutPic([
					{
						photo: [
							{
								name: props.mentor.cutoutPic.id,
								id: props.mentor.cutoutPic.id,
								url: props.mentor.cutoutPic.url,
								preview: getSanitizedImageUrl(props.mentor.cutoutPic.url),
							},
						],
						caption: props.mentor.cutoutPic.caption,
					},
				]);
			}
			if (isNotEmptyObject(props.mentor.mobileCoverPic)) {
				setMobileCoverPic([
					{
						photo: [
							{
								name: props.mentor.mobileCoverPic.id,
								id: props.mentor.mobileCoverPic.id,
								url: props.mentor.mobileCoverPic.url,
								preview: getSanitizedImageUrl(props.mentor.mobileCoverPic.url),
							},
						],
						caption: props.mentor.mobileCoverPic.caption,
					},
				]);
			}
			if (isNotEmptyObject(props.mentor.webCoverPic)) {
				setWebCoverPic([
					{
						photo: [
							{
								name: props.mentor.webCoverPic.id,
								id: props.mentor.webCoverPic.id,
								url: props.mentor.webCoverPic.url,
								preview: getSanitizedImageUrl(props.mentor.webCoverPic.url),
							},
						],
						caption: props.mentor.webCoverPic.caption,
					},
				]);
			}
			if (isNotEmptyObject(props.mentor.videoCoverPicture)) {
				setVideoCoverPicture([
					{
						photo: [
							{
								name: props.mentor.videoCoverPicture.id,
								id: props.mentor.videoCoverPicture.id,
								url: props.mentor.videoCoverPicture.url,
								preview: getSanitizedImageUrl(props.mentor.videoCoverPicture.url),
							},
						],
						caption: props.mentor.videoCoverPicture.caption,
					},
				]);
			}
		}
	}, [props.mentor]);

	const handleSubmit = async (params) => {
		if (!isNotEmptyArray(params.specialities)) {
			showToast('error', `🚫️ Specialities is required`);
			return;
		}

		if (!isNotEmptyArray(params.keyHighlights)) {
			showToast('error', `🚫️ Key highlight is required`);
			return;
		}

		if (!profilePic) {
			showToast('error', '🚫️ Profile Picture is empty!');
			return;
		}
		if (!cutoutPic) {
			showToast('error', '🚫️ Cutout Picture is empty!');
			return;
		}
		if ((!webCoverPic || !mobileCoverPic) && props?.mentor?.isFeatured) {
			showToast('error', `🚫️ Mentor is already marked Featured. Please upload webCoverPic & mobileCoverPic`);
			return;
		}
		const modifiedParams = {
			...params,
			profilePic,
			cutoutPic,
			mobileCoverPic,
			webCoverPic,
			videoCoverPicture,
			hlsVideoUrl,
		};

		const processedValues = modifyBeforeSaveMentor(modifiedParams, props.mentor);
		if (isNotEmptyObject(processedValues)) {
			if (isNotEmptyObject(props.mentor)) {
				processedValues.isEdit = true;
			} else {
				processedValues.isEdit = false;
				processedValues._id = props.mentorId;
				processedValues.status = STATUS_ACTIVE;
			}
			dispatch(mentorOnSubmit(processedValues));
		} else {
			showToast('error', '🚫️ Please select at least one field!!');
		}
	};

	return (
		<LoadingOverlay isLoading={props.isSubmitting} msg="Submitting Mentor Details...">
			<Card>
				<CardBody>
					<MentorReduxForm
						initialValues={modifyBeforeSetMentor(props.mentor)}
						onSubmit={(args) => handleSubmit(args)}
						mentorId={props.location.search ? parseQueryParams(props.location.search).id : props.mentorId}
						profilePic={profilePic}
						cutoutPic={cutoutPic}
						setProfilePic={(pic) => setProfilePic(pic)}
						setCutoutPic={(pic) => setCutoutPic(pic)}
						mobileCoverPic={mobileCoverPic}
						setMobileCoverPic={(pic) => setMobileCoverPic(pic)}
						webCoverPic={webCoverPic}
						setWebCoverPic={(pic) => setWebCoverPic(pic)}
						videoCoverPicture={videoCoverPicture}
						hlsVideoUrl={hlsVideoUrl}
						setHlsVideoUrl={setHlsVideoUrl}
						setVideoCoverPicture={(pic) => setVideoCoverPicture(pic)}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

const withConnect = connect((state) => ({
	mentor: state.mentor.mentor,
	mentorId: state.mentor.mentorId,
	loading: state.mentor.loading,
	isSubmitting: state.mentor.isSubmitting,
	mentorFullName: selector(state, 'fullName'),
}));

export default compose(withConnect)(MentorForm);
