import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { FormProvider, useForm } from 'react-hook-form';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { required } from 'reusableComponents/Form/fieldValidators';
import Box from 'reusableComponents/Box';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { compose } from 'redux';
import { isNotEmptyArray, isNotEmptyObject } from 'utils/commonHelpers';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import DropzoneWithImageCrop from 'components/DropzoneWithImageCrop';
import { createQuiz, getQuizDetails, updateQuiz } from './actions/quiz.action';

const quizStatus = [
	{ label: 'ACTIVE', value: 'ACTIVE' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
];

const AddEditQuiz = () => {
	const hookFormMethods = useForm();
	const { handleSubmit, reset, watch, control } = hookFormMethods;

	const [quiz, setQuiz] = React.useState<any>();
	const dispatch = useDispatch();
	const quizesState = useSelector(({ quizes }) => ({
		quizes: quizes.quizes,
	}));

	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const quizId = queryParams.get('quizId');

	const prefillData = React.useCallback(
		({ title, status, agePreference, playingCost, challengingCost, playingReward, challengingReward, priority, coverImageUrl, id }) => {
			reset({
				title,
				status: {
					label: status,
					value: status,
				},
				ageStartFrom: agePreference.min,
				ageEndFrom: agePreference.max,
				priority,
				playingCostinBolt: playingCost.bolts,
				playingCostInXp: playingCost.xp,
				challengingCostInBolt: challengingCost.bolts,
				challengingCostInXp: challengingCost.xp,
				playingRewardInBolt: playingReward.bolts,
				playingRewardInXp: playingReward.xp,
				challengingRewardInBolt: challengingReward.bolts,
				challengingRewardInXp: challengingReward.xp,
				coverImageUrl: getPreviewImages({ id, mediaType: 'QUIZ_IMAGE', url: coverImageUrl }, false),
			});
		},
		[reset]
	);

	const onSubmit = (data: any) => {
		const {
			ageStartFrom,
			ageEndFrom,
			priority,
			challengingCostInXp,
			challengingCostInBolt,
			playingRewardInXp,
			playingRewardInBolt,
			challengingRewardInXp,
			challengingRewardInBolt,
			playingCostInXp,
			playingCostInBolt,
			status,
			...rest
		} = data;

		const agePreference = {
			min: Number(ageStartFrom),
			max: Number(ageEndFrom),
		};

		const playingCost = {
			xp: Number(playingCostInXp),
			bolts: Number(playingCostInBolt),
		};

		const challengingCost = {
			bolts: Number(challengingCostInBolt),
			xp: Number(challengingCostInXp),
		};

		const playingReward = {
			bolts: Number(playingRewardInBolt),
			xp: Number(playingRewardInXp),
		};

		const challengingReward = {
			bolts: Number(challengingRewardInBolt),
			xp: Number(challengingRewardInXp),
		};

		if (quizId) {
			return dispatch(
				updateQuiz({
					id: quizId,
					agePreference,
					priority: Number(priority),
					playingCost,
					challengingCost,
					playingReward,
					challengingReward,
					status: status.value,
					...rest,
				})
			);
		}

		dispatch(
			createQuiz({
				agePreference,
				priority: Number(priority),
				playingCost,
				challengingCost,
				playingReward,
				challengingReward,
				status: status.value,
				...rest,
			})
		);
	};

	React.useEffect(() => {
		if (quizId) {
			const quiz = quizesState.quizes.find((quiz: { id: string }) => quiz?.id === quizId);

			if (isNotEmptyObject(quiz)) {
				setQuiz(quiz);
				return prefillData(quiz);
			}

			dispatch(getQuizDetails({ quizId }));
		}
	}, []);

	React.useEffect(() => {
		if (isNotEmptyArray(quizesState.quizes)) {
			const quiz = quizesState.quizes.find((quiz: { id: string }) => quiz?.id === quizId);

			if (isNotEmptyObject(quiz)) {
				setQuiz(quiz);
				prefillData(quiz);
			}
		}
	}, [quizesState.quizes]);

	return (
		<Card>
			<CardBody>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
						<Box w="100%">
							<Row>
								<Col>
									<h2 className="mb-4">Activity Editing</h2>
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									<Input name="title" type="text" label="Title" required />
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									<Input name="ageStartFrom" type="number" label="Age Start From" min="0" validate={required} required />
								</Col>
								<Col sm={6}>
									<Input name="ageEndFrom" type="number" label="Age End From" min="0" validate={required} required />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<Input name="priority" type="number" label="Priority" required min="0" />
								</Col>
								<Col sm="6">
									<SelectField name="status" label="Status" options={quizStatus} required />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<p className="f-3">Playing Cost</p>
								</Col>
								<Col sm="6">
									<Input name="playingCostInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="playingCostInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<p className="f-3">Challenging Cost</p>
								</Col>
								<Col sm="6">
									<Input name="challengingCostInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="challengingCostInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<p className="f-3">Playing Reward</p>
								</Col>
								<Col sm="6">
									<Input name="playingRewardInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="playingRewardInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<p className="f-3">Challenging Reward</p>
								</Col>
								<Col sm="6">
									<Input name="challengingRewardInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="challengingRewardInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<DropzoneWithImageCrop
										label="Cover Picture (5:2 Aspect Ratio)"
										name="coverImageUrl"
										required
										aspectRatio={2.5}
										control={control}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Button type="submit" className="btn btn-primary">
										Submit
									</Button>
								</Col>
							</Row>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_quiz', enableReinitialize: true });

const AddEditQuizReduxForm = compose(withReduxForm)(AddEditQuiz);

export default AddEditQuizReduxForm;
