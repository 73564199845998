import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';

import { renderTextField } from '../formComponents';

const MetaTagsForm = () => {
	return (
		<Row>
			<Col sm="12">
				<h5 className="bold-text mb-3 mt-3">Meta tags</h5>
			</Col>
			<Col sm="6">
				<Field label="Title" name="metaTags.title" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="description" name="metaTags.description" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="keywords" name="metaTags.keywords" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="canonicalUrl" name="metaTags.canonicalUrl" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogTitle" name="metaTags.ogTitle" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogDescription" name="metaTags.ogDescription" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogType" name="metaTags.ogType" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogImage" name="metaTags.ogImage" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogImageWidth" name="metaTags.ogImageWidth" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogImageHeight" name="metaTags.ogImageHeight" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogUrl" name="metaTags.ogUrl" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="ogSiteName" name="metaTags.ogSiteName" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="fbAppId" name="metaTags.fbAppId" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="twitterCard" name="metaTags.twitterCard" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="twitterSite" name="metaTags.twitterSite" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="twitterTitle" name="metaTags.twitterTitle" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="twitterDescription" name="metaTags.twitterDescription" component={renderTextField} />
			</Col>
			<Col sm="6">
				<Field label="twitterImage" name="metaTags.twitterImage" component={renderTextField} />
			</Col>
		</Row>
	);
};

export default MetaTagsForm;
