import { createSlice } from '@reduxjs/toolkit';

const categorySlice = createSlice({
	name: 'category',
	initialState: {
		loading: false,
		categories: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getCategories: () => {},
		getCategoriesSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.categories = action.payload.categories;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getCategory: () => {},
		getCategorySuccess: (state, action) => {
			state.category = action.payload.category;
		},
		categoryOnSubmit: () => {},
		categoryOnSubmitSuccess: (state, action) => {
			state.categories = !action.payload.isEdit
				? [action.payload.category, ...state.categories]
				: state.categories.map((category) => {
						if (category.id === action.payload.category.id) {
							return action.payload.category;
						}
						return category;
				  });
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		setCategoryStatus: () => {},
		setCategoryStatusSuccess: (state, action) => {
			state.categories[action.payload.pos].status = action.payload.status;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getCategories,
	getCategoriesSuccess,
	getCategory,
	getCategorySuccess,
	categoryOnSubmit,
	categoryOnSubmitSuccess,
	toggleIsSubmitting,
	setCategoryStatus,
	setCategoryStatusSuccess,
} = categorySlice.actions;

export default categorySlice.reducer;
