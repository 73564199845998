import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AllWhatsappChats from './components/AllWhatsappChats';

const WhatsappChats = () => {
	return (
		<div>
			<Switch>
				<Route path="/whatsapp-chats" component={AllWhatsappChats} />
			</Switch>
		</div>
	);
};

export default WhatsappChats;
