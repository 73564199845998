import { ArrayMaybe, stringifyQueryParams } from './commonHelpers';
import { fetchWrapper } from './fetchWrapper';

const HLS_STREAM_CONVERT_COMPLETE = 4;

export const fetchMentors = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/mentors?query=${query}&limit=10&sortKey=createdAt&sortOrder=-1`, { method: 'GET' });
		return res.data.mentors.map((m) => ({ label: m.fullName, value: m.id }));
	} catch {
		return [];
	}
};

export const fetchPlansAndPackages = async () => {
	try {
		const res = await fetchWrapper(`/v1/admin/membership/get-active-plan-and-packages`, { method: 'GET' });
		const finalData = res.data.map((x) => ({
			label: x.label,
			value: x.key,
		}));
		finalData.unshift({ label: 'FREE', value: 'FREE' }, { label: 'PRO', value: 'PRO' }, { label: 'PRO_PLUS', value: 'PRO_PLUS' });
		return finalData;
	} catch {
		return [];
	}
};

export const fetchClasses = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/batch-class`, {
			method: 'POST',
			body: {
				query,
				limit: 10,
				sortKey: 'createdAt',
				sortOrder: -1,
			},
		});
		return res.data.classes.map((c) => ({ label: c.classDetails.title, value: c.classNum, mentorDetails: c.mentorDetails, btcId: c.id }));
	} catch {
		return [];
	}
};

export const fetchPrograms = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/programs?query=${query}&limit=10&sortKey=createdAt&sortOrder=-1`, { method: 'GET' });
		return res.data.programs.map((p) => ({ label: p.title, value: p.id }));
	} catch {
		return [];
	}
};

export const fetchSkills = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/skills?query=${query}&limit=10&sortKey=createdAt&sortOrder=-1`, { method: 'GET' });
		return res.data.skills.map((s) => ({ label: s.title, value: s.id }));
	} catch {
		return [];
	}
};

export const fetchCategories = async ({ query = '' }) => {
	try {
		const res = await fetchWrapper(`/v1/admin/categories?query=${query}&limit=10&sortKey=createdAt&sortOrder=-1`, { method: 'GET' });
		return res.data.categories.map((s) => ({ label: s.title, value: s.id }));
	} catch {
		return [];
	}
};

export const fetchVideos = async ({ query = '', videoType = '' }) => {
	try {
		const res = await fetchWrapper(
			`/v1/admin/video?videoName=${query}&videoType=${videoType}&hlsStreamStatus=${HLS_STREAM_CONVERT_COMPLETE}&limit=10&sortKey=createdAt&sortOrder=-1`,
			{
				method: 'GET',
			}
		);
		return res.data.videos.map((v) => ({ label: v.videoName, value: v.videoUrl || v.hlsVideoUrl, id: v.id }));
	} catch {
		return [];
	}
};

export const fetchShorts = async ({ query = '', status }) => {
	try {
		const qp = stringifyQueryParams({
			caption: query,
			status,
			limit: 10,
			sortKey: 'createdAt',
			sortOrder: -1,
			withMentorDetails: true,
			hlsStreamStatus: HLS_STREAM_CONVERT_COMPLETE,
		});
		const res = await fetchWrapper(`/v1/admin/shorts${qp}`, { method: 'GET' });

		return res.data.shorts.map((s) => ({
			label: s.caption,
			mentorDetails: s.mentorDetails,
			value: s.videoUrl,
			id: s.id,
		}));
	} catch {
		return [];
	}
};

export const parsePlanAndPacakgesOptions = async (currentValues) => {
	const data = await fetchPlansAndPackages();
	const finalData = ArrayMaybe(currentValues).map((x) => ({
		label: data.find((y) => y.value === x)?.label || x,
		value: x,
	}));
	return finalData;
};
