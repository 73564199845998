import React, { SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { accessDeepObject, isNotEmptyArray, stringifyQueryParams } from 'utils/commonHelpers';
import If from 'reusableComponents/If';
import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import Filter from 'reusableComponents/Filter';
import { TableCoverPicture } from 'components/TableCoverPicture';
import Box from 'reusableComponents/Box';
import { format } from 'date-fns';
import { deleteActivityInstance, fetchActivityInstances } from './actions/activityInstance.action';
import { setApiParams } from './ActivityInstanceSlice';
import history from '../../App/history';

const dateTimeFormat = 'dd/MM/yyyy HH:mm';

const Actions = ({ activityInstanceId, activityInstanceTitle }) => {
	const [dropdownOpen, setDropdownOpen] = React.useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const dispatch = useDispatch();

	const onDeleteQuestion = () => {
		dispatch(deleteActivityInstance({ activityInstanceId }));
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() =>
						history.push(`/activity/activity-instance/add-edit${stringifyQueryParams({ activityInstanceId, activityInstanceTitle })}`)
					}
					disabled={false}
				>
					Edit Activity Instance
				</DropdownItem>
				<DropdownItem onClick={onDeleteQuestion} disabled={false}>
					Delete Activity Instance
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const heads: TableHeads<any> = [
	{ accessor: 'coverPicture', Header: 'Cover Picture' },
	{ accessor: 'title', Header: 'Title' },
	{ accessor: 'categoryTag', Header: 'Category Tag' },
	{ accessor: 'agePreference', Header: 'Age Preference' },
	{ accessor: 'startTime', Header: 'Start Time' },
	{ accessor: 'endTime', Header: 'End Time' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

const createRows = ({ activityInstances }) => {
	return activityInstances.map((activityInstance: any) => {
		const { title, status, id, coverImageUrl, categoryTag, createdAt, agePreference, startTime, endTime } = activityInstance;
		return {
			coverPicture: <TableCoverPicture {...{ coverPicture: { url: coverImageUrl, id: '', mediaType: 'QUIZ_COVER_IMAGE' }, title }} />,
			title,
			status,
			categoryTag,
			agePreference: `${agePreference.min}-${agePreference.max}`,
			startTime: format(new Date(startTime), dateTimeFormat),
			endTime: format(new Date(endTime), dateTimeFormat),
			createdAt: format(new Date(createdAt), dateTimeFormat),
			actions: <Actions activityInstanceId={id} activityInstanceTitle={title} />,
		};
	});
};

const ActivityInstanceList = () => {
	const dispatch = useDispatch();

	const activityInstanceState = useSelector(({ activityInstances }) => ({
		activityInstances: activityInstances.activityInstances,
		page: activityInstances.page,
		total: activityInstances.total,
		apiParams: activityInstances.apiParams,
	}));

	const [searchTerm, setSearchTerm] = React.useState('');
	const [activityInstances, setActivityInstances] = React.useState<any[]>([]);
	const [pageOfItems] = React.useState(activityInstanceState.page);

	React.useEffect(() => {
		dispatch(fetchActivityInstances({ ...activityInstanceState.apiParams }));
	}, []);

	React.useEffect(() => {
		if (!isNotEmptyArray(activityInstanceState.activityInstances)) return;

		const activityInstances = createRows({ activityInstances: activityInstanceState.activityInstances });
		setActivityInstances(activityInstances);
	}, [activityInstanceState.activityInstances]);

	const onSearch = (e: SyntheticEvent) => {
		dispatch(setApiParams({ searchKey: searchTerm }));
		e?.preventDefault();
		dispatch(fetchActivityInstances({ ...activityInstanceState.apiParams, searchKey: searchTerm }));
	};

	const onSort = (sortColumn: string, sortDirection: string) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE' && sortColumn !== 'coverPicture') {
			const sortOrder = sortDirection === 'ASC' ? 1 : -1;
			const limit = activityInstanceState?.apiParams?.limit;
			const skip = activityInstanceState?.apiParams?.skip * (pageOfItems - 1);

			dispatch(setApiParams({ ...activityInstanceState.apiParams, sortKey: sortColumn, limit, skip, sortOrder }));
			dispatch(fetchActivityInstances({ ...activityInstanceState.apiParams, sortKey: sortColumn, limit, skip, sortOrder }));
		}
	};

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = activityInstanceState;
		if (itemsPage) {
			const skip = (itemsPage - 1) * apiParams.limit;
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip,
				})
			);
			dispatch(fetchActivityInstances({ ...apiParams, skip }));
		}
	};

	return (
		<Card>
			<CardBody>
				<Row>
					<Col sm="4">
						<h2 className="mb-3">Activity Instances Listing</h2>
					</Col>
				</Row>
				<Row>
					<Col sm="6" className="mt-4">
						<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch} placeholder="Searcy by title" />
					</Col>
					<Col sm={{ size: 2, offset: 1 }} className="mt-4">
						<Button color="primary" className="float-right" onClick={() => history.push('/activity/activity-instance/add-edit')}>
							Add
						</Button>
					</Col>
				</Row>
				<Box mt="2rem">
					<If
						condition={isNotEmptyArray(activityInstances)}
						then={
							<>
								<DataPaginationTable heads={heads} rows={activityInstances} onSort={onSort} />
								<Pagination
									itemsCount={activityInstanceState.total}
									itemsToShow={accessDeepObject('apiParams.limit', activityInstanceState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						}
						else={activityInstanceState.total === 0 && <div>No data</div>}
					/>
				</Box>
			</CardBody>
		</Card>
	);
};

export default ActivityInstanceList;
