import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { showToast } from 'utils/commonHelpers';

import { fetchWrapper } from 'utils/fetchWrapper';
import Box from 'reusableComponents/Box';

const CfCacheInvalidate = () => {
	const [response, setResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, setValue, ...hookFormMethods } = useForm();

	const onSubmit = async (values) => {
		setResponse(null);
		if (!values.paths) {
			return null;
		}
		setLoading(true);
		const res = await fetchWrapper('/v1/admin/invalidate-cloudfront-cache', {
			method: 'POST',
			body: values,
		});

		setResponse(res);
		if (res.status !== 200) {
			// console.log('status !== 200', res.status);
			setLoading(false);
		} else {
			showToast('success', 'Request submitted successfully!', 1000);
			setLoading(false);
		}
	};

	return (
		<LoadingOverlay isLoading={loading} msg="Submitting your request...">
			<FormProvider {...{ register, handleSubmit, errors, setValue, ...hookFormMethods }}>
				<div>
					<Card>
						<CardBody>
							<Box w="100%">
								<Row>
									<Col sm="12">
										<h3>Cloudfront Cache Invalidate</h3>
									</Col>
									<Col sm="12" style={{ paddingTop: '10px' }}>
										<h5>Write the list of comma separated paths in the input field</h5>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<form onSubmit={handleSubmit(onSubmit)} className="form ltr-support">
									<Col sm="12">
										<Input
											label="Comma separated Paths (Can contain * also)"
											name="paths"
											placeholder="/assets/test/*, /assets/mini-youtube/test.png"
										/>
									</Col>
									<Col sm="12">
										<Button size="sm" color="primary" type="submit">
											Submit
										</Button>
									</Col>
								</form>
							</Box>
							<Box>
								<Col sm="12">{response && <code>{JSON.stringify(response)}</code>}</Col>
							</Box>
						</CardBody>
					</Card>
				</div>
			</FormProvider>
		</LoadingOverlay>
	);
};

export default CfCacheInvalidate;
