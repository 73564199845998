import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card, CardBody, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import If from 'reusableComponents/If';
import { fetchWrapper } from 'utils/fetchWrapper';
import theme from '../../../utils/theme';
import { ObjectMaybe, isAllowedEmail, isNotEmptyArray, showToast } from '../../../utils/commonHelpers';

import CustomModal from '../../../reusableComponents/CustomModal';
import Box from '../../../reusableComponents/Box';

const StyledModal = styled.div`
	height: auto;
	width: 40rem;
	padding: 1rem;
`;

const customStyles = `
	width: 60rem;

	@media ${theme.device.md} {
		width: 60rem;
		height: auto;
	}
	
	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const SubscriptionModal = ({ userId, subscriptionDetails, closeModal, loggedInUser }) => {
	const { subscriptionValidTill } = ObjectMaybe(subscriptionDetails);
	const [orders, setOrders] = useState([]);
	const [subscriptions, setSubscriptions] = useState([]);

	const isUserAllowedToRevoke = useMemo(() => {
		return isAllowedEmail('REACT_APP_REVOKE_SUBSCRIPTION_ADMINS', loggedInUser?.email?.[0]);
	}, []);

	const isPremiumSubscriptionValid = subscriptionValidTill && new Date(subscriptionValidTill) > new Date();

	const fetchOrders = async () => {
		try {
			const response = await fetchWrapper('/v2/admin/get-all-package-grants', {
				method: 'POST',
				body: {
					userId,
				},
			});
			if (response.status !== 200) {
				throw new Error('Failed to fetch orders!');
			}
			setOrders(response.data.sort((a, b) => b._id.toString().localeCompare(a._id.toString())));
		} catch (error) {
			showToast('error', error?.toString() ?? 'Failed to fetch orders!');
		}
	};

	const fetchSubscriptions = async () => {
		try {
			if (!Array.isArray(orders) || orders.length === 0) {
				throw new Error('No orders available!');
			}

			const subscriptions = await Promise.all(
				orders.map(async (order) => {
					if (!order.profileId || !order.packageId) {
						throw new Error('Invalid order data!');
					}

					const response = await fetchWrapper('/v2/admin/get-all-subscriptions-via-packageId', {
						method: 'POST',
						body: {
							profileId: (order?.profileId as unknown) as string,
							packageId: (order?.packageId as unknown) as string,
						},
					});

					if (response.status !== 200) {
						throw new Error('Failed to fetch subscriptions!');
					}

					return response.data;
				})
			);

			setSubscriptions(subscriptions.flat());
		} catch (error) {
			showToast('error', error?.toString() ?? 'Failed to fetch subscriptions!');
		}
	};

	useEffect(() => {
		fetchOrders();
	}, []);
	useEffect(() => {
		if (orders.length > 0) {
			fetchSubscriptions();
		}
	}, [orders]);

	const onReject = async ({ orderId, profileId, packageId }: { orderId: string; profileId: string; packageId: string }) => {
		try {
			if (window.confirm('Are you sure you want to revoke this package?')) {
				const res = await fetchWrapper('/v2/admin/revoke-package-grant-via-admin', {
					method: 'POST',
					body: {
						userId,
						profileId,
						packageId,
						orderId,
					},
				});

				if (res.status !== 200 || !res?.data?.status) {
					throw new Error('Failed to revoke package!');
				}
				showToast('success', 'Package revoked successfully!');
				setOrders(
					orders.map((order) => {
						if (profileId.toString() === order.profileId.toString() && packageId.toString() === order.packageId.toString()) {
							return {
								...order,
								validTill: new Date(),
							};
						}
						return order;
					})
				);
			}
		} catch (e) {
			showToast('error', 'Failed to revoke package!');
		}
	};

	const onCancel = async ({
		orderId,
		subscriptionId,
		profileId,
		setClickLock,
	}: {
		orderId: string;
		subscriptionId: string;
		profileId: string;
		setClickLock;
	}) => {
		setClickLock(true);
		try {
			if (window.confirm('Are you sure you want to cancel this package?')) {
				const cancelSubsResponse = await fetchWrapper('/v2/admin/cancel-subscription-via-admin', {
					method: 'POST',
					body: {
						userId,
						profileId,
						subscriptionId,
						orderId,
					},
				});

				if (cancelSubsResponse.data.status) {
					showToast('success', 'Successfully cancelled subscription');
				} else {
					showToast('error', 'Some error with cancelling subscription');
				}

				setClickLock(false);
			}
		} catch (e) {
			setClickLock(false);
			showToast('error', 'Failed to cancel subscription!');
		}
	};
	return (
		// @ts-ignore
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Card>
					<CardBody>
						<Box as="h3" mb="1rem">
							Subscription Details
						</Box>
						<If
							condition={isPremiumSubscriptionValid || isNotEmptyArray(orders)}
							then={
								<UserPlanPackages
									orders={orders}
									subscriptions={subscriptions}
									onReject={onReject}
									onCancel={onCancel}
									isUserAllowedToRevoke={isUserAllowedToRevoke}
								/>
							}
							else={<Box fs="1.2rem">User has not subscribed to any plan/packages!</Box>}
						/>
					</CardBody>
				</Card>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(SubscriptionModal);

function UserPlanPackages({
	orders,
	subscriptions,
	onReject,
	onCancel,
	isUserAllowedToRevoke,
}: {
	orders?: any[];
	subscriptions?: any[];
	onReject: ({ orderId, profileId, packageId }: { orderId: string; profileId: string; packageId: string }) => void;
	onCancel: ({
		orderId,
		subscriptionId,
		userId,
		profileId,
		setClickLock,
	}: {
		orderId: string;
		subscriptionId: string;
		userId: string;
		profileId: string;
		setClickLock;
	}) => void;
	isUserAllowedToRevoke: boolean;
}) {
	const [clickLock, setClickLock] = useState(false);
	const currISODate = new Date();
	const ordersGroupByProfileId: {
		profiles: string[];
		groupByProfileIdOrders: any[];
	} = useMemo(() => {
		const res = orders.reduce((acc, order) => {
			if (isNotEmptyArray(acc[order.profileId]?.orders)) {
				acc[order.profileId].orders.push(order);
			} else {
				acc[order.profileId] = { orders: [order], profileName: order.profileName };
			}
			return acc;
		}, {});
		return {
			profiles: Object.keys(res),
			groupByProfileIdOrders: res,
		};
	}, [orders]);

	return (
		<Box
			style={{
				maxHeight: '70vh',
				overflowX: 'scroll',
			}}
		>
			<Box as="div" fs="1.2rem" fw="bold" mb="1rem">
				User Plan/Packages
			</Box>

			{ordersGroupByProfileId.profiles.map((profileId) => {
				const { orders, profileName } = ordersGroupByProfileId.groupByProfileIdOrders[profileId];
				if (!isNotEmptyArray(orders)) return null;
				return (
					<>
						<div style={{ fontSize: '18px' }}>
							<span style={{ fontSize: '14px' }}>Name:</span> {profileName} <span style={{ fontSize: '12px' }}>({profileId})</span>
						</div>
						<Table bordered hover>
							<thead>
								<tr>
									<th>Package Key</th>
									<th>Valid Till</th>
									<th>Action</th>
									<th>Subscriptions</th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order) => {
									const isExpired = new Date(order.validTill) <= currISODate;
									const shouldShowCancelButton =
										order.subscriptionId &&
										order?.cancelRequestStatus !== 'CANCEL_IN_PROGRESS' &&
										order?.cancelRequestStatus !== 'CANCELLED' &&
										order?.subscriptionStatus !== 'CANCELLED';
									return (
										<tr key={order._id} style={{ backgroundColor: isExpired ? '#ED695F' : 'white', color: isExpired ? 'white' : 'black' }}>
											<td>{order.packageKey}</td>
											<td>{new Date(order.validTill).toLocaleString()}</td>
											{isExpired ? (
												<td>Expired</td>
											) : (
												<td>
													{isUserAllowedToRevoke && (
														<div
															style={{ color: 'blue', cursor: 'pointer' }}
															onClick={() => onReject({ orderId: order.orderId, profileId, packageId: order.packageId })}
														>
															Revoke
														</div>
													)}
												</td>
											)}
											{subscriptions && subscriptions.length > 0
												? subscriptions.map((subscription) => (
														<tr key={subscription.id} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
															{(subscription.subscriptionStatus === 'ACTIVE' ||
																subscription.subscriptionStatus === 'AUTHENTICATED' ||
																subscription.subscriptionStatus === 'PAUSED') &&
															subscription?.cancelRequestStatus !== 'CANCEL_IN_PROGRESS' &&
															subscription?.cancelRequestStatus !== 'CANCELLED' &&
															subscription?.subscriptionStatus !== 'CANCELLED' &&
															!clickLock &&
															isUserAllowedToRevoke ? (
																<>
																	<td style={{ margin: '10px' }}>{subscription.pgSubscriptionId}</td>
																	<td>
																		<div
																			style={{
																				backgroundColor: '#FFCDD2',
																				color: 'red',
																				cursor: 'pointer',
																				padding: '5px 5px',
																				borderRadius: '5px',
																				margin: '5px',
																			}}
																			onClick={() =>
																				onCancel({
																					subscriptionId: subscription._id,
																					userId: order.userId,
																					profileId: order.profileId,
																					setClickLock,
																					orderId: order.orderId,
																				})
																			}
																		>
																			Cancel
																		</div>
																	</td>
																</>
															) : (
																<>
																	<td>{subscription.pgSubscriptionId}</td>
																	<td>{subscription.subscriptionStatus}</td>
																</>
															)}
														</tr>
												  ))
												: null}
										</tr>
									);
								})}
							</tbody>
						</Table>
					</>
				);
			})}
		</Box>
	);
}
