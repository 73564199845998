import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { isNotEmptyArray, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper, uploadMedias } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { setIsRefreshing, toggleIsSubmitting, toggleLoading } from '../shortSlice';

export const getShortVideoStatus = createAsyncThunk('shorts/getVideoStatus', async ({ dropboxPath, isShortsVideo }: any, { dispatch }) => {
	dispatch(setIsRefreshing({ dropboxPath, value: true }));

	const res = await fetchWrapper(`/v1/admin/get-video-status`, { method: 'POST', body: { dropboxPath, isShortsVideo } });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			dispatch(setIsRefreshing({ dropboxPath, value: false }));
			return res.data;
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const getShorts = createAsyncThunk(
	'shorts/getShorts',
	async ({ sortKey, sortOrder, limit, skip, status, searchTerm }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip, status, searchTerm });
		const res = await fetchWrapper(`/v1/admin/shorts${qp}`, { method: 'GET' });

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.message } });
			} else {
				return res.data;
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const getShort = createAsyncThunk('shorts/getShort', async ({ shortId }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const qp = stringifyQueryParams({ id: shortId });
	const res = await fetchWrapper(`/v1/admin/short${qp}`, { method: 'GET' });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			return res.data;
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const submitShortPolls = createAsyncThunk('shorts/submitPolls', async (data: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const res = await fetchWrapper(`/v1/admin/short-polls`, { method: 'POST', body: data });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			showToast('success', `👍️ Poll added to Short successfully!!`);
			history.push('/shorts/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const deleteShortPolls = createAsyncThunk('shorts/deletePolls', async ({ shortId }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const res = await fetchWrapper(`/v1/admin/short-polls`, { method: 'DELETE', body: { shortId } });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			return res.data;
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const createShort = createAsyncThunk('shorts/createShort', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const file = data?.thumbnailImage?.[0];
		let shortThumbnailImage;
		if (data?.thumbnailImage) {
			const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });
			const thumbnailImageDetails = (await uploadMedias({
				medias: [{ file }],
				entityId,
				entityType: 'SHORT_THUMBNAIL_IMAGE',
			})) as any;
			shortThumbnailImage = {
				url: thumbnailImageDetails?.[0]?.data?.rawUrl,
			};
		}

		const res = await fetchWrapper('/v1/admin/short', {
			method: 'POST',
			body: {
				...data,
				shortThumbnailImage,
			},
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			showToast('success', `👍️ Short upload started successfully!!`);
			history.push('/shorts/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateShort = createAsyncThunk('shorts/updateShort', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const isUploadingNewThumbnail = isNotEmptyArray(data.thumbnailImage) && !data?.thumbnailImage?.[0]?.id;
		let thumbnailImage;
		if (isUploadingNewThumbnail) {
			const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });
			const file = data.thumbnailImage[0];
			const thumbnailImageDetails = (await uploadMedias({
				medias: [{ file }],
				entityId,
				entityType: 'SHORT_THUMBNAIL_IMAGE',
			})) as any;
			thumbnailImage = {
				url: thumbnailImageDetails?.[0]?.data?.rawUrl,
			};
		} else {
			thumbnailImage = {
				url: data?.thumbnailImage?.[0]?.url,
			};
		}
		const res = await fetchWrapper('/v1/admin/short', {
			method: 'PUT',
			body: { ...data, thumbnailImage },
		});
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.message } });
		} else {
			showToast('success', `👍️ Short updated successfully!!`);
			history.push('/shorts/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateShortAndUpdateList = createAsyncThunk(
	'shorts/updateShortAndUpdateList',
	async ({ shortId, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: shortId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/short', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.message } });
			} else {
				showToast('success', `👍️ Short updated successfully!!`);
				return {
					shortId,
					shortData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
