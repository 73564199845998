import { put, call, all } from 'redux-saga/effects';
import { actionSpreader, showToast, pickWrapper, omitWrapper, isNotEmptyArray } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../diySlice';
import { uploadImageSaga, mutateSaga } from '../../../shared/saga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit'], payload) };
	const allImgs = modifiedData.photos.filter((img) => !img.id);
	if (isNotEmptyArray(allImgs)) {
		let imgs = yield all(
			allImgs.map((img) =>
				call(uploadImageSaga, {
					payload: { images: [img], entityId: payload.id, entityType: 'DIY' },
				})
			)
		);
		if (imgs instanceof Error) {
			showToast('error', 'OOPS!! Something went wrong, please try again!');
		} else {
			imgs = imgs.map((img) => img[0]);
			const uploadedPhotos = modifiedData.photos.filter((img) => img.id);
			modifiedData.photos = [...uploadedPhotos, ...imgs.map((img) => pickWrapper(['id', 'url'], img))];
		}
	}
	const diyRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: payload.isEdit,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/diy`,
	});
	if (diyRes.status === 200) {
		showToast('success', `👍️ DIY ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		history.push('/diy/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: diyRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
