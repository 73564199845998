import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import { IClass } from 'types/Class';
import { getSanitizedImageUrl } from 'utils/commonHelpers';

export const ClassCard = ({ id, title, description, coverPicture }: IClass) => (
	<div>
		<Card>
			<CardImg top width="100%" height="100px" src={getSanitizedImageUrl(coverPicture?.url, { w: 150 })} alt="" />
			<CardBody>
				<CardTitle tag="h5">{title}</CardTitle>
				<CardText dangerouslySetInnerHTML={{ __html: description }} />
			</CardBody>
		</Card>
	</div>
);
