import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import If from 'reusableComponents/If';

const Filter = ({ hideSearchIcon = false, searchTerm, setSearchTerm, onSearch, placeholder, helpText = '', ...rest }) => {
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSearch(e);
			}}
			className="form"
		>
			<div className="form__form-group mb-2" style={{ display: 'flex' }}>
				<div className="form__form-group-field">
					<input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder={placeholder} {...rest} />
					<If
						condition={!hideSearchIcon}
						then={
							<button type="submit" className="form__form-group-button">
								<MagnifyIcon />
							</button>
						}
					/>
				</div>
			</div>
			{helpText && <span className="form__form-group-help-text">{helpText}</span>}
		</form>
	);
};

export default Filter;
