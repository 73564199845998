import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getExperiments, updateExperimentAndUpdateList } from './actions/experiment.actions';

const experimentSlice = createSlice({
	name: 'experiment',
	initialState: {
		loading: false,
		experiment: {},
		experiments: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			status: '',
			experimentType: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.status !== undefined && { status: action.payload.status }),
				...(action.payload.experimentType !== undefined && { experimentType: action.payload.experimentType }),
			};
		},
	},
	extraReducers: {
		[getExperiments.fulfilled as any]: (state, action) => {
			const {
				payload: { experiments, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.experiments = experiments;
			state.total = total;
			state.pageNo = page + 1;
		},
		[updateExperimentAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { experimentId, experimentData },
			} = action;
			const { experiments } = state;
			experiments[state.experiments.findIndex((experiment) => experiment.id === experimentId)] = experimentData;
			state.experiments = [...experiments];
		},
	},
});

export const { toggleIsSubmitting, toggleLoading, setApiParams } = experimentSlice.actions;

export default experimentSlice.reducer;
