import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';
import { isNotEmptyObject } from 'utils/commonHelpers';
import CustomModal from 'reusableComponents/CustomModal';
import Box from 'reusableComponents/Box';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import { Table } from 'reactstrap';
import { getUserPostAnalytics } from '../actions/userPost.actions';

const StyledModal = styled.div`
	max-height: 70vh;
	overflow-y: scroll;
	width: 30rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const PostsCountModal = ({ closeModal }) => {
	const dispatch = useDispatch();
	const { postAnalytics } = useSelector(({ userPost }) => ({ postAnalytics: userPost.postAnalytics }));
	const {
		totalImagePostsCreatedToday,
		totalVideoPostsCreatedToday,
		postsApprovedTodayByAdmin,
		postsRejectedTodayByAdmin,
		imagePostsWithShowOnFeedMarkedYesToday,
		imagePostsWithShowOnFeedMarkedNoToday,
		videoPostsWithShowOnFeedMarkedYesToday,
		videoPostsWithShowOnFeedMarkedNoToday,
	} = postAnalytics;

	const { loggedInUser, loading } = useSelector((state) => ({ loggedInUser: state.auth.user, loading: state.userPost.loading }));

	React.useEffect(() => {
		dispatch(getUserPostAnalytics({ adminUserId: loggedInUser.id }));
	}, []);

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				{loading ? (
					<Loader />
				) : (
					<>
						<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
							<Box w="85%" ellipse fs="1.4rem">
								Posts Analysis Data
							</Box>
							<Box onClick={closeModal} cursor="pointer">
								<span className="sidebar__link-icon lnr lnr-cross" />
							</Box>
						</Box>
						{isNotEmptyObject(postAnalytics) ? (
							<Table>
								<thead>
									<tr>
										<th>Query</th>
										<th>Data</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Total Image Post Created Today</td>
										<td>{totalImagePostsCreatedToday}</td>
									</tr>
									<tr>
										<td>Total Video Post Created Today</td>
										<td>{totalVideoPostsCreatedToday}</td>
									</tr>
									<tr>
										<td>Today posts Approved - Admin</td>
										<td>{postsApprovedTodayByAdmin}</td>
									</tr>
									<tr>
										<td>Today posts Rejected - Admin</td>
										<td>{postsRejectedTodayByAdmin}</td>
									</tr>
									<tr>
										<td>TODAY Photo Posts Show on Feed = YES</td>
										<td>{imagePostsWithShowOnFeedMarkedYesToday}</td>
									</tr>
									<tr>
										<td>TODAY Photo Posts Show on Feed = NO</td>
										<td>{imagePostsWithShowOnFeedMarkedNoToday}</td>
									</tr>
									<tr>
										<td>TODAY Video Posts Show on Feed = YES</td>
										<td>{videoPostsWithShowOnFeedMarkedYesToday}</td>
									</tr>
									<tr>
										<td>TODAY Video Posts Show on Feed = NO</td>
										<td>{videoPostsWithShowOnFeedMarkedNoToday}</td>
									</tr>
								</tbody>
							</Table>
						) : (
							<div className="">No data</div>
						)}
					</>
				)}
			</StyledModal>
		</CustomModal>
	);
};

export default PostsCountModal;
