import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

const RegisterForm = (props) => {
	const [showPassword, setShowPassword] = useState(false);

	const { handleSubmit, errors = {}, isLoading } = props;

	return (
		<form className="form" onSubmit={handleSubmit}>
			<div className="form__form-group">
				<span className="form__form-group-label">Full Name</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<div className="form__form-group-input-wrap">
						<Field name="fullName" component="input" type="text" placeholder="Name" />
						{errors.fullName && <span className="form__form-group-error">{errors.fullName}</span>}
					</div>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Email</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<div className="form__form-group-input-wrap">
						<Field name="email" component="input" type="email" placeholder="Email" />
						{errors.email && <span className="form__form-group-error">{errors.email}</span>}
					</div>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Password</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<KeyVariantIcon />
					</div>

					<div className="form__form-group-input-wrap">
						<Field name="password" component="input" type={showPassword ? 'text' : 'password'} placeholder="Password" />
						{errors.password && <span className="form__form-group-error">{errors.password}</span>}
					</div>
					<button
						className={`form__form-group-button${showPassword ? ' active' : ''}`}
						onClick={() => setShowPassword(!showPassword)}
						type="button"
					>
						<EyeIcon />
					</button>
				</div>
			</div>
			<div className="form__form-group" />
			<button type="submit" className="btn btn-primary account__btn account__btn--small">
				{isLoading ? 'Registering...' : 'Register'}
			</button>
			<Link className="btn btn-outline-primary account__btn account__btn--small" to="/login">
				Login
			</Link>
		</form>
	);
};

RegisterForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object,
	isLoading: PropTypes.bool,
};

export default reduxForm({
	form: 'register_form',
})(RegisterForm);
