import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PollsForm from 'containers/Poll/PollsForm';
import { ArrayMaybe, isNotEmptyObject, parseQueryParams, pickWrapper, showToast } from 'utils/commonHelpers';
import { deleteAllPolls, fetchBadges, getClass, getPolls, submitPolls } from '../classSlice';
import { doPollsOverlap, modifyBeforeSavePolls } from '../helpers';

interface ClassPollsProps {
	location: { search: { classId?: string } };
}

const ClassPolls = (props: ClassPollsProps) => {
	const dispatch = useDispatch();
	const [classDuration, setClassDuration] = React.useState(null);

	const classPollProps = useSelector((state) => ({
		classes: state.classes.classes,
		selectedClass: state.classes.class,
		polls: state.classes.polls,
		loading: state.classes.loading,
		isSubmitting: state.classes.isSubmitting,
		badges: state.classes.badges,
	}));

	const fetchBadgesByQuery = (query = '') => {
		// @ts-expect-error
		dispatch(fetchBadges({ query, limit: 20, sortKey: 'createdAt', sortOrder: '-1' }));
	};

	useEffect(() => {
		if (props?.location?.search) {
			// NOTE: Using the classes got in Class Table to filter out the class for which polls are being created/edited
			const { classId } = pickWrapper(['classId'], parseQueryParams(props.location.search));
			const selectedClass = classPollProps.classes.find((x) => x.id === classId);
			setClassDuration(selectedClass && selectedClass.duration);
			// @ts-expect-error
			dispatch(getPolls({ classId, sortKey: 'triggerTime', sortOrder: 1 }));
			// @ts-expect-error
			dispatch(getClass({ id: classId }));
			fetchBadgesByQuery();
		}
	}, [props?.location?.search]);

	const handleSubmit = async (params) => {
		if (isNotEmptyObject(ArrayMaybe(params.polls).find((poll) => poll.answer >= poll.options.length))) {
			showToast('error', '✋ Please Choose a Correct Answer');
		} else if (doPollsOverlap(params.polls, classDuration) !== false) {
			const errorMessage = doPollsOverlap(params.polls, classDuration);
			showToast('error', `✋  ${errorMessage}`);
		} else {
			const processedValues = {
				...modifyBeforeSavePolls({ ...params, ...pickWrapper(['classId'], parseQueryParams(props.location.search)) }, classPollProps.polls),
			};
			if (isNotEmptyObject(processedValues)) {
				// @ts-expect-error
				dispatch(submitPolls(processedValues));
			} else {
				showToast('error', '🚫️ Please select at least one field!!');
			}
		}
	};

	const onDeletePolls = () => {
		// eslint-disable-next-line no-restricted-globals, no-alert
		const removePollsConfirmation = confirm('Are you sure you want to remove all polls?');
		if (removePollsConfirmation) {
			const { classId } = pickWrapper(['classId'], parseQueryParams(props.location.search));
			// @ts-expect-error
			dispatch(deleteAllPolls({ classId }));
		}
	};

	return (
		<PollsForm
			{...classPollProps}
			selectedEntity={classPollProps.selectedClass}
			testPollUrl={`/class/polls/test?classId=${classPollProps.selectedClass.id}`}
			redirectUrl="/class/list"
			handleSubmit={handleSubmit}
			onDeletePolls={onDeletePolls}
			fetchBadgesByQuery={fetchBadgesByQuery}
		/>
	);
};

export default ClassPolls;
