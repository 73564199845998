import { createSlice } from '@reduxjs/toolkit';
import { fetchMedia, createMedia, updateMedia, getMedia } from './actions/media.action';

// create the slice for the media with api params.
const quizMediaSlice = createSlice({
	name: 'quizMedia',
	initialState: {
		quizMedia: [],
		quizMediaLoading: false,
		quizMediaError: null,
		page: 1,
		total: 10,
		apiParams: {
			limit: 20,
			searchKey: '',
			sortKey: 'tilte',
			sortOrder: -1,
			skip: 0,
		},
	},
	reducers: {
		// set the loading state to true.
		setQuizMediaLoading: (state) => {
			state.quizMediaLoading = true;
		},
		// set the loading state to false.
		setQuizMediaLoaded: (state) => {
			state.quizMediaLoading = false;
		},
		// set the error state to the error.
		setQuizMediaError: (state, action) => {
			state.quizMediaError = action.payload;
		},
		// set the quiz media to the payload.
		setQuizMedia: (state, action) => {
			state.quizMedia = action.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ searchKey: action.payload.searchKey },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
	},
	extraReducers: {
		[fetchMedia.pending as any]: (state) => {
			state.quizMediaLoading = true;
		},
		[fetchMedia.fulfilled as any]: (state, { payload }) => {
			state.quizMedia = payload.quizMedias;
			state.quizMediaLoading = false;
			state.total = payload.total;
		},
		[createMedia.fulfilled as any]: (state, { payload }) => {
			state.quizMedia.push(payload);
		},
		[getMedia.fulfilled as any]: (state, { payload }) => {
			state.quizMedia.push(payload);
		},
		[updateMedia.fulfilled as any]: (state, { payload }) => {
			const index = state.quizMedia.findIndex((media) => media.id === payload.mediaId);
			if (payload.status) state.quizMedia[index].status = payload.status;
		},
	},
});

export const { setApiParams } = quizMediaSlice.actions;

export default quizMediaSlice.reducer;
