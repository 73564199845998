import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

const AsyncCreatableSelectField = ({ defaultOptions, isMulti = true, value, onCreateOption, changeHandler }) => {
	return (
		<AsyncCreatableSelect
			className="react-select"
			classNamePrefix="react-select"
			isMulti={isMulti}
			cacheOptions
			defaultOptions={defaultOptions || true}
			onChange={(opt) => changeHandler(opt)}
			onCreateOption={(opt) => onCreateOption(opt)}
			value={value}
		/>
	);
};

export default AsyncCreatableSelectField;
