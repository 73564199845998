/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { getSanitizedImageUrl, showToast } from 'utils/commonHelpers';
import { Timer } from 'utils/timer';
import { midjourneyImagine, midjourneyUpscale } from './apiCalls';
import './midjourney.scss';

export const MidjourneyImageGenerator = ({ acceptImage, width, height }) => {
	const [isWindowVisible, setIsWindowVisible] = useState(false);
	const [prompt, setPrompt] = useState('');

	const [imagineResponse, setImagineResponse] = useState(null);
	const [isImageGenerationUnderProgress, setImageGenerationUnderProgress] = useState(false);

	const [upscaleResponse, setUpscaleResponse] = useState(null);
	const [isUpscalingUnderProgress, setUpscaleUnderProgress] = useState(false);

	const generateImage = async () => {
		setImageGenerationUnderProgress(true);
		setImagineResponse({});
		setUpscaleResponse({});

		const response = await midjourneyImagine({
			prompt,
		});

		if (response?.imagineResponse) setImagineResponse(response.imagineResponse);

		setImageGenerationUnderProgress(false);
	};

	const upscaleImage = async (selectedOption) => {
		if (!(imagineResponse && imagineResponse?.uri)) showToast('error', 'Unable to upscale image');

		setUpscaleUnderProgress(true);
		const response = await midjourneyUpscale({
			selectedOption,
			imagineResponse,
		});

		if (response?.upscaleResponse) setUpscaleResponse(response.upscaleResponse);

		setUpscaleUnderProgress(false);
	};

	return (
		<>
			<div className="midContainer">
				<div
					onClick={() => setIsWindowVisible(true)}
					className="tooltipContainer"
					style={{
						display: isWindowVisible ? 'none' : 'block',
						width,
						height,
					}}
				>
					<img src={getSanitizedImageUrl('assets/loader/fireMid.gif')} alt="midjourney support" />
					<span className="tooltiptext">Generate image with AI 😎</span>
				</div>

				{isWindowVisible && (
					<div className="window">
						<div
							className="overlay"
							onClick={() => {
								if (isImageGenerationUnderProgress) return;
								setIsWindowVisible(false);
							}}
						/>

						<div className="promptWindow">
							<textarea className="promptInput" value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="Enter prompt" />

							{isImageGenerationUnderProgress || isUpscalingUnderProgress ? (
								<div className="imageContainer">
									<h4>Hang there till we build your image...</h4>
									<Timer initialMinute={3} initialSeconds={0} />
									<div>
										<img src={getSanitizedImageUrl('assets/loader/jugglingLoader.gif')} alt="midjourney ai generated" />
									</div>
								</div>
							) : upscaleResponse && upscaleResponse?.uri ? (
								<div className="imageContainer">
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignContent: 'center',
										}}
									>
										<img src={upscaleResponse.uri} alt="midjourney ai generated" />
									</div>
								</div>
							) : (
								imagineResponse &&
								imagineResponse?.uri && (
									<div className="imageContainer">
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignContent: 'center',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'space-between',
													alignContent: 'center',
												}}
											>
												<Button
													disabled={isUpscalingUnderProgress}
													color="primary"
													className="upscaleButton"
													onClick={() => upscaleImage(1)}
												>
													U1
												</Button>
												<Button
													disabled={isUpscalingUnderProgress}
													color="primary"
													className="upscaleButton"
													onClick={() => upscaleImage(3)}
												>
													U3
												</Button>
											</div>
											<img src={imagineResponse.uri} alt="midjourney ai generated" />

											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'space-between',
													alignContent: 'center',
												}}
											>
												<Button
													disabled={isUpscalingUnderProgress}
													color="primary"
													className="upscaleButton"
													onClick={() => upscaleImage(2)}
												>
													U2
												</Button>
												<Button
													disabled={isUpscalingUnderProgress}
													color="primary"
													className="upscaleButton"
													onClick={() => upscaleImage(4)}
												>
													U4
												</Button>
											</div>
										</div>
									</div>
								)
							)}
							<div className="buttonsContainer">
								{upscaleResponse && upscaleResponse?.uri && (
									<Button
										color="primary"
										className="acceptButton"
										onClick={() => {
											acceptImage({ imageUrl: upscaleResponse.uri });
											setIsWindowVisible(false);
										}}
									>
										Accept
									</Button>
								)}

								<Button
									disabled={isImageGenerationUnderProgress || isUpscalingUnderProgress}
									color="primary"
									className="generateButton"
									onClick={generateImage}
								>
									{imagineResponse && imagineResponse?.uri ? 'Retry' : 'Generate'}
								</Button>

								{((imagineResponse && imagineResponse?.uri) || (upscaleResponse && upscaleResponse?.uri)) && (
									<Button
										color="secondary"
										className="previewImage"
										onClick={async () => {
											window.open(upscaleResponse.uri || imagineResponse.uri, '_blank');
										}}
									>
										Preview Image
									</Button>
								)}
							</div>
							<div
								className="closeIcon"
								onClick={() => {
									if (isImageGenerationUnderProgress) return;
									setIsWindowVisible(false);
								}}
							>
								✕
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
