import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { showToast } from 'utils/commonHelpers';
import { addQuestionToExercise } from '../actions/exercises.actions';
import { getExerciseQuestion } from '../utils/helpers';
import { TExerciseQuestion } from '../utils/types';

const AddExistingQuestionPopup = React.forwardRef((_, ref) => {
	const [popupData, setPopupData] = React.useState({ isOpen: false, exerciseId: null });
	const { exerciseId, isOpen } = popupData;

	const show = (exerciseId: string) => {
		if (exerciseId) setPopupData({ isOpen: true, exerciseId });
	};
	const close = () => {
		setPopupData({ isOpen: false, exerciseId: null });
	};

	React.useImperativeHandle(ref, () => ({
		show,
		close,
	}));

	if (!exerciseId) return null;
	return (
		<Modal centered isOpen={isOpen}>
			<ModalHeader>Add a Existing Question</ModalHeader>
			<ModalBody>
				<FormComponent exerciseId={exerciseId} />
				<Button onClick={close}>Cancel</Button>
			</ModalBody>
		</Modal>
	);
});

export default AddExistingQuestionPopup;

const FormComponent = ({ exerciseId }: { exerciseId: string }) => {
	const hookFormMethods = useForm();
	const dispatch = useDispatch();
	const [questionData, setQuestionData] = React.useState<{ exerciseQuestion?: TExerciseQuestion; error?: unknown }>(null);

	const onSubmit = (data) => {
		if (data.questionAdminCode)
			dispatch(
				addQuestionToExercise({
					exerciseId,
					...(questionData?.exerciseQuestion?.id
						? { questionId: questionData?.exerciseQuestion?.id }
						: { questionAdminCode: data?.questionAdminCode }),
				})
			);
		else showToast('error', 'Please enter a question admin code');
	};

	const questionAdminCode = hookFormMethods.watch('questionAdminCode');

	const fetchQuestionData = async () => {
		if (questionAdminCode) {
			const data = await getExerciseQuestion({ apiParams: { questionAdminCode } });
			setQuestionData(data);
		}
	};

	React.useEffect(() => {
		if (questionAdminCode?.length === 0) {
			setQuestionData(null);
		}
	}, [questionAdminCode]);

	return (
		<FormProvider {...hookFormMethods}>
			<form className="form" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
				<div>
					<InputNew label="Enter Question code" name="questionAdminCode" required />
					<Button type="submit">Add</Button>
					<Button onClick={fetchQuestionData}>Check question's existence</Button>
				</div>
				{questionAdminCode?.length > 0 && (
					<div style={{ marginBottom: 20 }}>
						{questionData?.error && (
							<h4 style={{ color: 'red', fontWeight: 'bolder' }}>
								Error: <span style={{ color: 'red', fontWeight: 'normal' }}>{questionData.error}</span>
							</h4>
						)}
						{questionData?.exerciseQuestion.id ? (
							<>
								<h4 style={{ fontWeight: 'bolder' }}>
									Question Title: <span style={{ color: 'grey', fontWeight: 'normal' }}>{questionData.exerciseQuestion.title}</span>
								</h4>
								<h4 style={{ fontWeight: 'bolder' }}>
									Question Id: <span style={{ color: 'grey', fontWeight: 'normal' }}>{questionData.exerciseQuestion.id}</span>
								</h4>
							</>
						) : (
							questionData?.exerciseQuestion && (
								<h4 style={{ color: 'red', fontWeight: 'bolder' }}>
									Error: <span style={{ color: 'red', fontWeight: 'normal' }}>Question not exists</span>
								</h4>
							)
						)}
					</div>
				)}
			</form>
		</FormProvider>
	);
};
