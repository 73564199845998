import { takeLatest } from 'redux-saga/effects';

import {
	getWhatsappGroups,
	whatsappGroupOnSubmit,
	getWhatsappGroup,
	getWhatsappGroupId,
	setWhatsappGroupStatus,
	whatsappCsvOnSubmit,
	getWhatsappUserAnalytics,
	getWhatsappUserAnalyticsByDate,
	getDropboxFiles,
	dropboxFilesOnUpload,
} from './whatsappSlice';
import { getWhatsappGroupsSaga } from './sagas/getWhatsappGroupsSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { getWhatsappGroupSaga } from './sagas/getWhatsappGroupSaga';
import { getWhatsappGroupIdSaga } from './sagas/getWhatsappGroupIdSaga';
import { setWhatsappGroupStatusSaga } from './sagas/setWhatsappGroupStatusSaga';
import { onCsvSubmitSaga } from './sagas/onCsvSubmitSaga';
import { getWhatsappUserAnalyticsSaga } from './sagas/getWhatsappUserAnalyticsSaga';
import { getWhatsappUserAnalyticsByDateSaga } from './sagas/getWhatsappUserAnalyticsByDateSaga';
import { getDropboxFilesSaga } from './sagas/getDropboxFilesSaga';
import { uploadDropboxFilesSaga } from './sagas/uploadDropboxFilesSaga';

export default function* programSaga() {
	yield takeLatest(whatsappGroupOnSubmit.type, onSubmitSaga);
	yield takeLatest(whatsappCsvOnSubmit.type, onCsvSubmitSaga);
	yield takeLatest(getDropboxFiles.type, getDropboxFilesSaga);
	yield takeLatest(dropboxFilesOnUpload.type, uploadDropboxFilesSaga);
	yield takeLatest(getWhatsappGroups.type, getWhatsappGroupsSaga);
	yield takeLatest(getWhatsappUserAnalytics.type, getWhatsappUserAnalyticsSaga);
	yield takeLatest(getWhatsappUserAnalyticsByDate.type, getWhatsappUserAnalyticsByDateSaga);
	yield takeLatest(getWhatsappGroup.type, getWhatsappGroupSaga);
	yield takeLatest(getWhatsappGroupId.type, getWhatsappGroupIdSaga);
	yield takeLatest(setWhatsappGroupStatus.type, setWhatsappGroupStatusSaga);
}
