import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import S3FileUploadForm from './components/S3FileUploadForm';
import RedisCli from './components/RedisCli';
import CfCacheInvalidate from './components/CfCacheInvalidate';
import PaymentService from './components/PaymentService';
import { userHasDevSettingAccess } from './utils/helpers';

const DevSettings = () => {
	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));
	return (
		<div>
			<Switch>
				<Route path="/dev-settings/s3-file-upload" component={S3FileUploadForm} />
				<Route path="/dev-settings/payment-services" component={() => <PaymentService loggedInUser={loggedInUser} />} />
				{userHasDevSettingAccess(loggedInUser.email[0]) && (
					<>
						<Route path="/dev-settings/redis-cli" component={RedisCli} />
						<Route path="/dev-settings/cf-cache-invalidate" component={CfCacheInvalidate} />
					</>
				)}
			</Switch>
		</div>
	);
};

export default DevSettings;
