import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { difference } from 'lodash';
import { omitWrapper, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper, updateMedia, uploadMedias } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../dashboardSlice';

export const getBanners = createAsyncThunk('dashboard/getBanners', async ({ sortKey, sortOrder, limit, skip }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip });
	const res = await fetchWrapper(`/v1/admin/dashboard/banners${qp}`, { method: 'GET' });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			const activeBanners = res.data.banners.filter(
				(banner) => banner.status === 'ACTIVE' && (banner.expiryAt ? new Date() <= new Date(banner.expiryAt) : true)
			);
			const inactiveBanners = difference(res.data.banners, activeBanners);

			return {
				banners: [...activeBanners, ...inactiveBanners],
				total: res.data.total,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});

export const createBanner = createAsyncThunk('dashboard/createBanner', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const { data: bannerId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

		const valuesToSubmit = {
			...omitWrapper(['bannerImage', 'bannerImageWeb', 'bannerThumbnail', 'bannerFeaturedProgram', 'bannerImage3By2'], data),
			_id: bannerId,
		};

		const res = await fetchWrapper('/v1/admin/dashboard/banner', {
			method: 'POST',
			body: valuesToSubmit,
		});

		await Promise.all([
			...(data.bannerImage
				? [
						uploadMedias({
							medias: [data.bannerImage],
							entityType: 'DASHBOARD_BANNER',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data.bannerImageWeb
				? [
						uploadMedias({
							medias: [data.bannerImageWeb],
							entityType: 'DASHBOARD_BANNER_WEB',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data.bannerImage3By2
				? [
						uploadMedias({
							medias: [data.bannerImage3By2],
							entityType: 'DASHBOARD_BANNER_3_BY_2',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data.bannerThumbnail
				? [
						uploadMedias({
							medias: [data.bannerThumbnail],
							entityType: 'DASHBOARD_BANNER_THUMBNAIL',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data.bannerFeaturedProgram
				? [
						uploadMedias({
							medias: [data.bannerFeaturedProgram],
							entityType: 'DASHBOARD_BANNER_FEATURED_PROGRAM',
							entityId: res.data.id,
						}),
				  ]
				: []),
		]);

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Banner created succcessfully!!`);
			history.push('/dashboard/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong. Banners could not be created.`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateBanner = createAsyncThunk('dashboard/updateBanner', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const valuesToSubmit = omitWrapper(['bannerImage', 'bannerThumbnail', 'bannerImageWeb', 'inactiveMedias', 'bannerFeaturedProgram'], data);

	try {
		const res = await fetchWrapper('/v1/admin/dashboard/banner', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		const inactiveMediaPromises = data.inactiveMedias.map((media) => updateMedia({ id: media.id, status: 'INACTIVE' }));

		const mediaUploadPromises = [
			...(data?.bannerImage && !data?.bannerImage?.id
				? [
						uploadMedias({
							medias: [data.bannerImage],
							entityType: 'DASHBOARD_BANNER',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data?.bannerImageWeb && !data?.bannerImageWeb?.id
				? [
						uploadMedias({
							medias: [data.bannerImageWeb],
							entityType: 'DASHBOARD_BANNER_WEB',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data?.bannerImage3By2 && !data?.bannerImage3By2?.id
				? [
						uploadMedias({
							medias: [data.bannerImage3By2],
							entityType: 'DASHBOARD_BANNER_3_BY_2',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data?.bannerThumbnail && !data?.bannerThumbnail?.id
				? [
						uploadMedias({
							medias: [data.bannerThumbnail],
							entityType: 'DASHBOARD_BANNER_THUMBNAIL',
							entityId: res.data.id,
						}),
				  ]
				: []),
			...(data?.bannerFeaturedProgram && !data?.bannerFeaturedProgram?.id
				? [
						uploadMedias({
							medias: [data.bannerFeaturedProgram],
							entityType: 'DASHBOARD_BANNER_FEATURED_PROGRAM',
							entityId: res.data.id,
						}),
				  ]
				: []),
		];

		await Promise.all([...inactiveMediaPromises, ...mediaUploadPromises]);

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Banner updated succcessfully!!`);
			history.push('/dashboard/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateBannerAndUpdateList = createAsyncThunk(
	'dashboard/updateBannerAndUpdateList',
	async ({ bannerId, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: bannerId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/dashboard/banner', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				if (res.data?.isErrorOnUpdate) {
					showToast('error', 'Cannot Update banner having no thumbnail Url');
					return {
						bannerId,
						bannerData: res.data,
					};
				}
				showToast('success', `👍️ Banner updated successfully!!`);
				return {
					bannerId,
					bannerData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong. Banners could not be updated.`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
