import { put, call, all } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper, isNotEmptyObject, isNotEmptyArray, pickWrapper } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../aboutSlice';
import { setError } from '../../App/globalSlice';
import { mutateSaga, uploadImageSaga } from '../../../shared/saga';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit'], payload) };

	const allImgs = [...(isNotEmptyObject(payload.photo) ? [payload.photo.photo[0]] : [])].filter((img) => !img.id);

	if (isNotEmptyArray(allImgs)) {
		let imgs = yield all(
			allImgs.map((img) => call(uploadImageSaga, { payload: { images: [img], entityId: payload.id, entityType: 'TEAMMATE' } }))
		);
		if (imgs instanceof Error) {
			showToast('error', 'OOPS!! Something went wrong, please try again!');
		} else {
			imgs = imgs.map((img) => img[0]);

			if (modifiedData.photo && !modifiedData.photo.photo[0].id) {
				modifiedData.photo = { photo: [pickWrapper(['id', 'url'], imgs[0])] };
			}
		}
	}
	modifiedData.photo = { ...modifiedData.photo.photo[0], caption: payload.photo.caption };

	const teamMateRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: payload.isEdit,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/teammate`,
	});
	if (teamMateRes.status === 200) {
		showToast('success', `👍️ Team Mate ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
		history.push('/about-us/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: teamMateRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
