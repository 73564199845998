import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../careerSlice';
import { setError } from '../../App/globalSlice';
import { mutateSaga } from '../../../shared/saga';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit'], payload) };
	const openingRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: payload.isEdit,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/careers`,
	});
	if (openingRes.status === 200) {
		showToast('success', `👍️ Opening ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
		history.push('/careers/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: openingRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
