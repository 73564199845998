import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Row } from 'reactstrap';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { showToast } from 'utils/commonHelpers';
import { generateBlog, uploadBlogs } from '../actions/websiteBlog.actions';
import './generateBlog.scss';

const CenteredText = styled.h3`
	text-align: center;
`;

const CancelButton = ({ onClick }) => (
	<Button color="danger" onClick={onClick}>
		Cancel
	</Button>
);

const SubmitButton = () => (
	<Button color="primary" type="submit">
		Submit
	</Button>
);

const GenerateBlogForm = () => {
	const hookFormMethods = useForm();
	const { watch, handleSubmit } = hookFormMethods;

	const watchedFields = watch(['topic', 'description']);
	const [blog, setBlog] = useState({ title: '', sections: [] });
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [requestStatus, setRequestStatus] = useState(true);

	const handleCancel = () => {
		setLoading(false);
		setRequestStatus(false);
	};

	const onFormSubmit = async (data) => {
		setLoading(true);
		try {
			const res = await generateBlog(data);
			const parsedData = res.data;

			if (parsedData.title && parsedData.sections.length > 0) {
				setBlog({ title: parsedData.title, sections: parsedData.sections });
			} else {
				setSubmitted(true);
			}
		} catch (error) {
			showToast('error', 'Error generating blog');
		} finally {
			setLoading(false);
		}
	};

	const handleUpload = async (blogTitle, blogDescription, savedBlog) => {
		try {
			await uploadBlogs({ topic: blogTitle, description: blogDescription, blog: savedBlog });
			setBlog({ title: '', sections: [] });
		} catch (err) {
			showToast('error', 'Error uploading blog');
		}
	};

	return (
		<Card>
			<CardBody>
				<h3>Create Blog</h3>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={handleSubmit(onFormSubmit)}>
						<InputNew label="Topic" name="topic" required />
						<InputNew label="Description" name="description" required />
						{loading ? <CancelButton onClick={handleCancel} /> : <SubmitButton />}
					</form>
				</FormProvider>
				{loading && (
					<>
						<div className="centered">
							<CenteredText>Please wait, the blog is being generated. It can take up to 10 minutes.</CenteredText>
						</div>
						<Loader />
					</>
				)}
				{blog && requestStatus && blog.sections.length > 0 && (
					<div className="blogContainer">
						<h2>Generated blog</h2>
						<h2 className="blogTitle">{blog.title}</h2>
						{blog.sections.map((section) => (
							<div key={section}>
								<Markdown>{section}</Markdown>
							</div>
						))}
					</div>
				)}
				{submitted && !blog.sections.length && <div>No blog found.</div>}
				{blog.sections.length > 0 && (
					<Row>
						<Button color="green" onClick={() => handleUpload(watchedFields.topic, watchedFields.description, blog)}>
							Upload
						</Button>

						<Button color="danger" onClick={() => setBlog({ title: '', sections: [] })}>
							Reset
						</Button>
					</Row>
				)}
			</CardBody>
		</Card>
	);
};

export default GenerateBlogForm;
