import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe, omitWrapper } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import formatDate from 'date-fns/format';
import { DropzoneNew as Dropzone } from 'reusableComponents/HookForm/Dropzone';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import { fetchClasses, fetchMentors, fetchPrograms, fetchShorts, fetchVideos } from 'utils/fetchHelpers';
import { createPost, updatePost } from '../actions/featuredPost.actions';
import { IPhoto } from '../../../types/Photo';

const postTypeOptions = [
	{ label: 'ADMIN', value: 'ADMIN' },
	{ label: 'ADCARD', value: 'ADCARD' },
] as const;

const commonEntityTypes = [
	{ label: 'CLASS', value: 'CLASS' },
	{ label: 'MENTOR', value: 'MENTOR' },
	{ label: 'PROGRAM', value: 'PROGRAM' },
] as const;
const adminOnlyEntityTypes = [{ label: 'SHORTS', value: 'SHORTS' }] as const;
const adCardOnlyEntityTypes = [{ label: 'CHALLENGE', value: 'CHALLENGE' }] as const;

type TEntityTypes = typeof commonEntityTypes[number] | typeof adminOnlyEntityTypes[number] | typeof adCardOnlyEntityTypes[number];
type TPostTypes = typeof postTypeOptions[number] | { label: 'USER'; value: 'USER' };

const getFormattedMedias = ({ photos, videos, postType }) => {
	let media = [];

	if (postType === 'ADCARD') {
		if (photos[0]) {
			media.push({ caption: photos[0]?.caption, file: photos[0]?.photo[0], id: photos[0]?.photo[0]?.id });
		}
	} else {
		media = [...media, ...photos.map((item) => ({ caption: item.caption, file: item.photo, id: item.photo?.id }))];
		media = [...media, ...(videos as File[]).map((video) => ({ caption: '', file: video, id: (video as any)?.id }))];
	}

	return media;
};

const getInitialFormattedMedias = ({ media, postType }) => {
	return {
		photos: getPreviewImages(
			postType === 'ADCARD'
				? { caption: media[0]?.caption, id: media[0]?.id, url: media[0]?.url }
				: media
						.filter((media) => media?.mediaType === 'IMAGE')
						.map((media) => ({ caption: media?.caption, id: media?.id, url: media?.url })),
			true
		) as IPostPhoto[],
		videoUrls: media.filter((media) => media?.mediaType === 'VIDEO').map((media) => ({ id: media?.id, url: media?.url })),
	};
};

const getFormattedDate = (date: string): Date => {
	const validTill = new Date(date);
	validTill.setHours(23, 59, 0, 0);

	return new Date(validTill);
};

const getEntityTypeOptions = (postType: TPostTypes['value']) => {
	switch (postType) {
		case 'ADMIN':
			return [...commonEntityTypes, ...adminOnlyEntityTypes];
		case 'ADCARD':
			return [...commonEntityTypes, ...adCardOnlyEntityTypes];
		default:
			return commonEntityTypes;
	}
};

const getEntityFieldProps = (entityType: TEntityTypes['value']) => {
	switch (entityType) {
		case 'CLASS': {
			return { label: 'Class', fetchOptions: fetchClasses };
		}
		case 'MENTOR': {
			return { label: 'Mentor', fetchOptions: fetchMentors };
		}
		case 'PROGRAM': {
			return { label: 'Program', fetchOptions: fetchPrograms };
		}
		case 'SHORTS': {
			return { label: 'Shorts', fetchOptions: fetchShorts };
		}
		case 'CHALLENGE': {
			return {
				label: 'Challenge',
				fetchOptions: (params) => fetchVideos({ ...params, videoType: 'CHALLENGE' }),
			};
		}
		default:
	}
};

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface IPostPhoto {
	caption: string;
	photo: IPhoto | IPhoto[];
}
interface IPostVideo {
	id: string;
	url: string;
}

interface IMentor {
	fullName: string;
	gender: 'MALE' | 'FEMALE';
	id: string;
	profilePic: IPhoto;
}

interface FeaturedPostFormValues {
	caption: string;
	postType: { label: TPostTypes['label']; value: TPostTypes['value'] };
	validTill?: string;
	entityType?: { label: TEntityTypes['label']; value: TEntityTypes['value'] };
	entityId?: {
		label: string;
		value: string;
		mentorDetails: IMentor;
		btcId: string;
		classNum?: string;
	};
	ctaText?: string;
	photos: IPostPhoto[];
	videos: (File | IPostVideo)[];
	includeMentor?: boolean;
}

type FeaturedPostInitialValues = FeaturedPostFormValues & { videoUrls: IPostVideo[]; id: string; createdBy: string; updatedBy: string };

interface IMedia {
	id: string;
	caption: string;
	mediaType: 'IMAGE' | 'VIDEO';
	url: string;
}

export type FeaturedPostValuesFromDatabase = FeaturedPostFormValues & {
	id: string;
	createdBy: string;
	updatedBy: string;
	caption: string;
	priority: string;
	status: 'PENDING' | 'APPROVED' | 'DELETED';
	media: IMedia[];
	postType: TPostTypes['value'];
	validTill?: string;
	entity?: {
		type: TEntityTypes['value'];
		ctaText: string;
		id: string;
		title?: string;
		batchToClassId?: string;
		classNum?: string;
		mentor?: IMentor;
	};
	entityId?: string;
};

interface ModifyFeaturedPostBeforeCreateOrUpdateArgs extends FeaturedPostFormValues {
	id?: string;
	createdBy: string;
	updatedBy: string;
}

export const modifyFeaturedPostBeforeCreateOrUpdate = (values: ModifyFeaturedPostBeforeCreateOrUpdateArgs) => {
	const includeMentor = values.entityType.value === 'SHORTS' ? values.includeMentor : true;

	return {
		...omitWrapper(['photos', 'videos', 'entityId', 'entityType', 'ctaText', 'includeMentor'], values),
		postType: values.postType.value,
		media: getFormattedMedias({ photos: values.photos, videos: values.videos, postType: values.postType.value }),
		entity: {
			id: values.entityId.value,
			classNum: values.entityId.classNum ?? values.entityId.value,
			batchToClassId: values.entityId.btcId,
			mentor: includeMentor ? values.entityId.mentorDetails : null,
			type: values.entityType.value,
			ctaText: values.ctaText,
			title: values.entityId.label,
		},
		...(values.postType.value === 'ADCARD' && {
			validTill: getFormattedDate(values.validTill),
		}),
	};
};

export const modifyFeaturedPostBeforeInitialize = (values: FeaturedPostValuesFromDatabase): FeaturedPostInitialValues => {
	return {
		...values,
		postType: { label: values.postType, value: values.postType },
		...getInitialFormattedMedias({ media: values.media, postType: values.postType }),
		validTill: values.validTill ? formatDate(new Date(values.validTill), 'yyyy-MM-dd') : '',
		entityType: [...commonEntityTypes, ...adminOnlyEntityTypes, ...adCardOnlyEntityTypes].find(
			(option) => option.value === values?.entity?.type
		),
		entityId: {
			label: values?.entity?.title,
			value: values?.entity?.id,
			btcId: values?.entity?.batchToClassId,
			mentorDetails: values?.entity?.mentor,
			classNum: values?.entity?.classNum,
		},
		ctaText: values?.entity?.ctaText,
	};
};

interface FeaturedPostAddEditFormProps {
	location: { search: { id?: string } };
}

export const FeaturedPostAddEditForm = (props: FeaturedPostAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<FeaturedPostInitialValues>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.featuredPost.isSubmitting,
	}));
	const hookFormMethods = useForm<FeaturedPostFormValues>({
		defaultValues: { includeMentor: true },
	});
	const { id } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!id;

	const { handleSubmit } = hookFormMethods;
	const { watch, setValue } = hookFormMethods;
	const watchFields = watch(['postType', 'entityType']);

	useEffect(() => {
		if (watchFields?.postType?.value && initialValues?.postType?.value !== watchFields?.postType?.value) setValue('entityType', '');
	}, [watchFields.postType]);

	useEffect(() => {
		if (watchFields?.entityType?.value && initialValues?.entityType?.value !== watchFields?.entityType?.value) setValue('entityId', '');
	}, [watchFields.entityType]);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/post?id=${id}`, { method: 'GET' }).then((res) => {
				const modifiedValues = modifyFeaturedPostBeforeInitialize(res.data as any);
				setInitialValues(modifiedValues);
				hookFormMethods.reset(modifiedValues);
			});
		}
	}, []);

	const onSubmit = (values: FeaturedPostFormValues) => {
		const modifiedValues = modifyFeaturedPostBeforeCreateOrUpdate({
			...values,
			photos: values.photos || [],
			videos: [...(initialValues?.videoUrls || []), ...Array.from((values.videos as File[]) || [])],
			id: isEditForm ? initialValues.id : undefined,
			createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
			updatedBy: loggedInUser.id,
		});

		if (isEditForm) {
			dispatch(updatePost(modifiedValues));
		} else {
			dispatch(createPost(modifiedValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Post...">
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Featured Post</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="6">
										<Input label="Post Caption" name="caption" required />
									</Col>
									<Col sm="6">
										<SelectField label="Post Type" name="postType" isDisabled={isEditForm} options={postTypeOptions} required />
									</Col>
									<Col sm="6">
										<SelectField
											label="Entity"
											name="entityType"
											isDisabled={isEditForm}
											options={getEntityTypeOptions(watchFields?.postType?.value)}
											required
										/>
									</Col>
									<Col sm="6">
										{watchFields?.entityType?.value && (
											<AsyncSelectField {...getEntityFieldProps(watchFields?.entityType?.value)} name="entityId" isClearable required />
										)}
									</Col>
									{watchFields?.postType?.value === 'ADCARD' && (
										<>
											<Col sm="6">
												<Input label="CTA Text" name="ctaText" required />
											</Col>

											<Col sm="6">
												<Input
													type="number"
													name="priority"
													label="Priority"
													disabled
													helpText="The priority will be set to maximum value automatically. You can adjust it from list view"
												/>
											</Col>
											<Col sm="6">
												<Input
													type="date"
													name="validTill"
													label="Expiry Date"
													min={formatDate(new Date(), 'yyyy-MM-dd')}
													helpText="Click on calendar icon to open calendar"
													required
												/>
											</Col>
										</>
									)}
									{watchFields?.entityType?.value === 'SHORTS' && (
										<Col sm="6">
											<Checkbox label="Include mentor details" name="includeMentor" />
										</Col>
									)}
									{watchFields?.entityType?.value !== 'SHORTS' && watchFields?.entityType?.value !== 'CHALLENGE' && (
										<Col sm="12">
											<Dropzone
												label="Photo"
												name="photos"
												withCaption
												isMulti={watchFields?.postType?.value === 'ADMIN'}
												noOfFiles={watchFields?.postType?.value === 'ADMIN' ? 5 : 1}
												required={watchFields?.postType?.value === 'ADCARD'}
												validate={(val) => {
													if (watchFields?.postType?.value === 'ADCARD') {
														return !val || !val.length ? 'Photo is required' : true;
													}
													return true;
												}}
											/>
										</Col>
									)}
									{isEditForm && initialValues?.videoUrls?.length > 0 && (
										<Col sm="12">
											<Box d="flex" ai="center" jc="start">
												{initialValues.videoUrls.map((video) => (
													<video src={video?.url} style={{ marginRight: '20px', width: '20%' }} controls />
												))}
											</Box>
										</Col>
									)}
									{/* <Col sm="12">
												<Input
													label="Videos"
													validate={(val) => {
														const { photos } = hookFormMethods.getValues();
														return (initialValues?.videoUrls?.length || 0) + val.length + photos.length <= 5
															? true
															: 'Maximum 5 media elements allowed only!';
													}}
													name="videos"
													type="file"
													accept="video/*"
													multiple
												/>
											</Col> */}
								</Row>

								<Col sm="12" className="mt-4">
									<Button size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/featured-post/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
