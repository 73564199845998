/* eslint-disable */

import React, { Component } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: ${(props) => props.z || 999};
	background: ${(props) => (props.bg ? (props.overrideBg ? props.overrideBg : 'rgba(0, 0, 0, 0.5)') : 'none')};
`;

class EscapeKeyOverlay extends Component {
	componentDidMount() {
		document.addEventListener('keydown', (e) => e.key === 'Escape' && this.props.escapePressed && this.props.escapePressed());
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', (e) => e.key === 'Escape' && this.props.escapePressed && this.props.escapePressed());
	}

	render() {
		return this.props.noElement ? null : (
			<Overlay role="presentation" onClick={() => this.props.overlayClick && this.props.overlayClick()} {...this.props} />
		);
	}
}

export default EscapeKeyOverlay;
