import React from 'react';
import { Switch, Route } from 'react-router-dom';

import WhatsappTable from './components/WhatsappTable';
import AddEditForm from './components/AddEdit';
import DropboxUpload from './components/DropboxUpload';
import Analytics from './components/Analytics';

const Whatsapp = () => {
	return (
		<div>
			<Switch>
				<Route path="/whatsapp/list" component={WhatsappTable} />
				<Route path="/whatsapp/add-edit" component={AddEditForm} />
				<Route path="/whatsapp/dropbox-upload" component={DropboxUpload} />
				<Route path="/whatsapp/analytics" component={Analytics} />
			</Switch>
		</div>
	);
};

export default Whatsapp;
