/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import { useTable, Column } from 'react-table';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	min-height: 60vh;
	overflow: auto;
	table {
		width: 100%;
	}
`;

interface CustomColKeys {
	disableSort?: boolean;
}

export type SortOrder = 'ASC' | 'DESC';
export type TableHeads<T extends object = {}> = Array<Column<T> & CustomColKeys>;
export type TableRows<T extends object = {}> = Array<Record<keyof T, React.ReactNode>>;

export interface DataPaginationTableProps<T extends object = {}> {
	heads: TableHeads<T>;
	rows: TableRows<T>;
	onSort?: (sortColumn: keyof T, sortDirection: SortOrder) => any;
	excludedSortColumns?: string[];
}

const DataPaginationTable: React.FC<DataPaginationTableProps<Record<string, any>>> = ({
	heads: columns,
	rows: data,
	onSort,
	excludedSortColumns = [],
}) => {
	const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
	const [sortedCol, setSortedCol] = useState('');

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

	const sortCol = (col) => {
		if (onSort && !excludedSortColumns.includes(col)) {
			onSort(col, sortOrder);
			setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
			setSortedCol(col);
		}
	};

	return (
		<Wrapper>
			<div className="table">
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column: any) => (
									<th
										{...column.getHeaderProps()}
										onClick={() => {
											if (!column.disableSort) sortCol(column.getHeaderProps().key.toString().replace('header_', ''));
										}}
									>
										{column.render('Header')}
										{sortedCol === column.getHeaderProps().key.toString().replace('header_', '') && (
											<span>{sortOrder === 'ASC' ? ' \u2191' : ' \u2193'}</span>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									style={{
										backgroundColor: row.original.backgroundColor || 'white',
										cursor: row.original?.onClick ? 'pointer' : 'default',
									}}
									{...row.getRowProps()}
									key={i.toString()}
									onClick={row.original?.onClick}
								>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Wrapper>
	);
};

export default DataPaginationTable;
