import React, { useEffect, useCallback, useState } from 'react';

import { Card, CardBody, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { isNotEmptyArray } from 'utils/commonHelpers';
import Filter from 'reusableComponents/Filter';
import WhatsappChat from 'components/WhatsappChat';
import ToggleButtonField from 'shared/components/form/ToggleButton';
import { SelectField } from 'reusableComponents/Form/Select';
import Box from 'reusableComponents/Box';
import { History, Location } from 'history';

import { updateFilters, getQueryParams } from 'containers/WhatsappChats/helpers';
import { getAllWhatsappConversations, resetMessages } from 'containers/WhatsappChats/whatsappChatSlice';
import { getAllWhatsappChatAssignees, getMessages, updateThreadStatus } from 'containers/WhatsappChats/whatsappchat.actions';

import differenceInHours from 'date-fns/differenceInHours';

function shouldShowAssignButtons({ loggedInUserEmail }: { loggedInUserEmail: string }): boolean {
	const isAssignee = ['kashyapi@ivypods.com', 'nadia@ivypods.com', 'shweta@ivypods.com'].includes(loggedInUserEmail);
	return !isAssignee;
}

const CONVERSATIONS_PER_PAGE = 20;

const getHoursSinceLastUserMessage = (messages) => {
	const userMessages = messages.filter((msg) => msg.sentBy === 'USER');
	const lastUserMessage = userMessages.pop();

	if (!lastUserMessage) return 24;

	return Math.abs(differenceInHours(new Date(), new Date(lastUserMessage.createdAt)));
};

const AllWhatsappChats = ({ location, history }: { location: Location; history: History }) => {
	const { conversationId, threadStatus, updatedAt, assignedTo } = getQueryParams(location, history);

	if (!conversationId && Number.isNaN(updatedAt) && !threadStatus) return null;

	const dispatch = useDispatch();
	const [skip, setSkip] = useState(CONVERSATIONS_PER_PAGE);
	const [searchTerm, setSearchTerm] = useState('');

	const {
		conversations,
		messages,
		initialMessageLoad,
		assignees,
		showLoadMoreConversations,
		showLoadMoreMoreMessages,
		disableSend,
		loggedInUserEmail,
	} = useSelector(({ whatsappChat, auth }) => ({ ...whatsappChat, loggedInUserEmail: auth.user.email[0] }));

	const fetchAllWhatsappConversations = ({ limit, skip, updatedAt, threadStatus, isLoadMoreCall = null }) => {
		dispatch(resetMessages());
		dispatch(
			// @ts-expect-error
			getAllWhatsappConversations({
				limit,
				skip,
				updatedAt,
				threadStatus,
				assignedTo,
				...(isLoadMoreCall ? { isLoadMoreCall } : {}),
			})
		);
	};

	const onUpdateThreadSuccess = () => {
		updateFilters({
			history,
			threadStatus,
			assignedTo,
			updatedAt,
		});
	};

	useEffect(() => {
		fetchAllWhatsappConversations({ limit: CONVERSATIONS_PER_PAGE, skip: 0, threadStatus, updatedAt });
	}, [threadStatus, assignedTo, updatedAt]);

	useEffect(() => {
		dispatch(resetMessages());
		if (conversationId) {
			dispatch(getMessages({ mobile: conversationId, limit: 20, sortKey: 'createdAt', sortOrder: -1, initialMessageLoad: true }));
		}
	}, [conversationId]);

	useEffect(() => {
		const userMessages = messages.filter((msg) => msg.sentBy === 'USER');
		const lastUserMessage = userMessages[userMessages.length - 1];
		const isLastMessageAUserMessage = messages[messages.length - 1] === lastUserMessage;

		if (conversationId === lastUserMessage?.mobile) {
			const currentCoversation = conversations.filter((conversation) => conversation.id === conversationId);
			const currentConversationThreadStatus = currentCoversation?.[0]?.threadStatus;
			const hoursSinceLastUserMessage = getHoursSinceLastUserMessage(messages);
			let shouldThreadBeMarkedDead = false;
			if (currentConversationThreadStatus) {
				shouldThreadBeMarkedDead =
					hoursSinceLastUserMessage >= 24 && currentConversationThreadStatus === 'OPEN' && isLastMessageAUserMessage;
			}
			if (shouldThreadBeMarkedDead) {
				dispatch(
					updateThreadStatus({
						mobile: conversationId,
						lastUserMessageId: lastUserMessage.id,
						status: 'DEAD',
						onSuccess: onUpdateThreadSuccess,
					})
				);
			}
		}
	}, [conversationId, messages]);

	const loadMoreConversation = useCallback(() => {
		if (showLoadMoreConversations) {
			fetchAllWhatsappConversations({
				limit: CONVERSATIONS_PER_PAGE,
				skip,
				updatedAt,
				threadStatus,
				isLoadMoreCall: true,
			});
			setSkip(skip + CONVERSATIONS_PER_PAGE);
		}
	}, [showLoadMoreConversations, skip, threadStatus, updatedAt]);

	const loadMoreMessages = useCallback(() => {
		if (isNotEmptyArray(messages) && showLoadMoreMoreMessages) {
			dispatch(
				getMessages({
					mobile: conversationId,
					limit: 20,
					sortKey: 'createdAt',
					sortOrder: -1,
					operator: 'lt',
					lastMessageId: messages[0].id,
				})
			);
		}
	}, [messages, conversationId, showLoadMoreMoreMessages]);

	const onRefresh = useCallback(() => {
		dispatch(resetMessages());
		dispatch(getMessages({ mobile: conversationId, limit: 20, sortKey: 'createdAt', sortOrder: -1 }));
	}, [conversationId]);

	const handleConversationItemClick = useCallback(
		(contact, e) => {
			const mobile = `${contact.mobile}`.replace('+', '').replace('-', '');
			e.preventDefault();
			updateFilters({ history, conversationId: mobile, threadStatus, assignedTo, updatedAt });
		},
		[threadStatus, assignedTo, updatedAt]
	);

	return (
		<Card className="ltr-support">
			<CardBody>
				<Row className="mt-1 mb-2 rounded">
					<Col sm={12} className="mb-3">
						<h3>Whatsapp Chats</h3>
					</Col>
					<Col sm={3}>
						<SelectField
							clearable
							defaultValue={assignees.find(({ value }) => value.threadStatus === threadStatus && (value as any).assignedTo === assignedTo)}
							placeholder="Status"
							name="Thread Status"
							options={assignees}
							onMenuOpen={() =>
								// @ts-expect-error
								dispatch(getAllWhatsappChatAssignees())
							}
							onChange={(updatedFilter) => {
								updateFilters({ history, threadStatus: updatedFilter.threadStatus, assignedTo: updatedFilter.assignedTo, updatedAt });
							}}
						/>
					</Col>
					<Col sm={3}>
						<Box d="flex" ai="center" jc="flex-start">
							<Box mr="1rem">LIFO (Latest First)</Box>
							<Box style={{ transform: 'translateY(-5px)' }}>
								<ToggleButtonField
									name="updatedAt"
									value={updatedAt !== -1}
									onChange={(val) => {
										updateFilters({ history, threadStatus, updatedAt: val ? 1 : -1 });
									}}
								/>
							</Box>
							<Box ml="1rem">FIFO (Oldest First)</Box>
						</Box>
					</Col>
					<Col sm={3}>
						<Filter
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							onSearch={() => updateFilters({ history, conversationId: searchTerm })}
							placeholder="Search by mobile number (Include 91)"
						/>
					</Col>
				</Row>
				<WhatsappChat
					showLoadMoreConversations={showLoadMoreConversations}
					conversations={conversations}
					handleConversationItemClick={handleConversationItemClick}
					conversationId={conversationId}
					messages={messages}
					initialMessageLoad={initialMessageLoad}
					showLoadMoreMoreMessages={showLoadMoreMoreMessages}
					onRefresh={onRefresh}
					loadMoreMessages={loadMoreMessages}
					loadMoreConversation={loadMoreConversation}
					waitingForSentConfirmation={disableSend}
					shouldShowAssignButtons={shouldShowAssignButtons({ loggedInUserEmail })}
				/>
			</CardBody>
		</Card>
	);
};

export default AllWhatsappChats;
