import React, { useState } from 'react';
import { isNotEmptyArray, ObjectMaybe, stringifyQueryParams } from '@bit/yellow_class.utils.common-helpers';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import { actionSpreader, parseQueryParams, showToast } from 'utils/commonHelpers';
import Breadcrumbs from 'components/Breadcrumbs';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import RankModifier from 'reusableComponents/rankModifier';
import { SelectField } from 'reusableComponents/Form/Select';
import history from '../../App/history';
import { fetchModules, updateModuleRank, updateModuleStatus } from '../actions/exercises.actions';
import { setApiParams, setPage } from '../ExercisesSlice';
import { statusList } from '../utils/helpers';
import { ContentDocumentStatusEnum, RankLevelEnum } from '../utils/types';

const heads = [
	{
		accessor: 'displayName',
		Header: 'Title',
	},

	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'beginnerRank',
		Header: 'Beginner Rank',
	},
	{
		accessor: 'intermediateRank',
		Header: 'Intermediate Rank',
	},
	{
		accessor: 'advanceRank',
		Header: 'Advance Rank',
	},
	{
		accessor: 'rank',
		Header: 'Rank',
	},
	{
		accessor: 'status',
		Header: 'Status',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({ moduleEnum, curriculumId, status }: { moduleEnum: string; curriculumId: string; status: ContentDocumentStatusEnum }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const handleChangeModuleStatus = () => {
		const payload = {
			curriculumId,
			moduleEnum,
			status: status === ContentDocumentStatusEnum.ACTIVE ? ContentDocumentStatusEnum.INACTIVE : ContentDocumentStatusEnum.ACTIVE,
		};

		dispatch(updateModuleStatus(payload));
	};
	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => {
						history.push(`/exercise/subtopic/list${stringifyQueryParams({ moduleEnum })}`);
					}}
					disabled={false}
				>
					Show Sub topics
				</DropdownItem>
				<DropdownItem
					onClick={() =>
						window.open(`/exercise/module/add-edit${stringifyQueryParams({ curriculumId, moduleEnum, mode: 'edit' })}`, '_blank')
					}
					disabled={false}
				>
					Edit Module
				</DropdownItem>
				<DropdownItem onClick={handleChangeModuleStatus} disabled={false}>
					{status === ContentDocumentStatusEnum.ACTIVE
						? `set ${ContentDocumentStatusEnum.INACTIVE}`
						: `set ${ContentDocumentStatusEnum.ACTIVE}`}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({ modules, handleModuleRankChange, curriculumId }) => {
	return modules.map((curr) => {
		const { moduleEnum, displayName, adminCode, status, ranks, rank } = curr;

		return {
			moduleEnum,
			displayName,
			status,
			adminCode,
			actions: <Actions moduleEnum={moduleEnum} curriculumId={curriculumId} status={status} />,
			// rank: <RankModifier onSubmit={handleModuleRankChange} uniqueId={adminCode} rank={rank} />,
			beginnerRank: (
				<RankModifier
					onSubmit={handleModuleRankChange}
					uniqueId={adminCode}
					rank={ranks?.[RankLevelEnum.BEGINNER]?.rank ?? 0}
					additionalParams={{
						rankLevel: RankLevelEnum.BEGINNER,
					}}
				/>
			),
			intermediateRank: (
				<RankModifier
					onSubmit={handleModuleRankChange}
					uniqueId={adminCode}
					rank={ranks?.[RankLevelEnum.INTERMEDIATE]?.rank ?? 0}
					additionalParams={{
						rankLevel: RankLevelEnum.INTERMEDIATE,
					}}
				/>
			),
			advanceRank: (
				<RankModifier
					onSubmit={handleModuleRankChange}
					uniqueId={adminCode}
					rank={ranks?.[RankLevelEnum.ADVANCED]?.rank ?? 0}
					additionalParams={{
						rankLevel: RankLevelEnum.ADVANCED,
					}}
				/>
			),
			rank: <RankModifier onSubmit={handleModuleRankChange} uniqueId={adminCode} rank={rank ?? 0} additionalParams={{}} />,
		};
	});
};

const ModuleList = (props) => {
	const dispatch = useDispatch();

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const modulesState = useSelector(({ exercises }) => ({
		modules: exercises.modules,
		isLoading: exercises.isLoading,
		isSubmitting: exercises.isSubmitting,
		apiParams: exercises.apiParams,
		total: exercises.total,
		page: exercises.page,
		additionalData: exercises.additionalData,
	}));

	const user = useSelector(({ auth }) => auth.user);

	const [curriculumId, setCurriculumId] = useState(null);

	const [modulesRow, setModulesRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	React.useEffect(() => {
		const data = ObjectMaybe(parseQueryParams(props.location.search));
		setCurriculumId(data.curriculumId);
		dispatch(
			fetchModules({
				...modulesState.apiParams,
				curriculumId: data.curriculumId,
			})
		);
	}, [modulesState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(modulesState.page);
	}, [modulesState.page]);
	const handleModuleRankChange = async (adminCode, rank, additionalParams) => {
		dispatch(updateModuleRank({ adminCode, rank, curriculumId, rankLevel: additionalParams?.rankLevel, updatedBy: user.id }));
	};
	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Curriculum',
						url: '/exercise/curriculum/list',
					},
					{
						text: 'Module',
						url: `/exercise/module/list${stringifyQueryParams({ curriculumId: qp.curriculumId })}`,
					},
				],
			})
		);
		if (isNotEmptyArray(modulesState.modules)) {
			const structuredRows = createNewRows({
				modules: modulesState.modules,
				handleModuleRankChange,
				curriculumId: qp.curriculumId,
			});
			setModulesRow(structuredRows);
		} else {
			setModulesRow([]);
		}
	}, [modulesState.modules]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = modulesState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};
	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			const sortOrder = sortDirection === 'ASC' ? '1' : '-1';

			dispatch(setApiParams({ ...modulesState.apiParams, sortKey: sortColumn, sortOrder }));
		}
	};
	const onFilterChange = (fieldName, val) => {
		dispatch(
			setApiParams({
				...modulesState.apiParams,
				query: val,
			})
		);
	};
	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={6}>
						<h3>Modules Listing</h3>
					</Col>
					<Col sm={3}>
						<SelectField
							clearable
							placeholder="Status"
							name="status"
							options={statusList}
							onChange={(val) => onFilterChange('status', val)}
						/>
					</Col>
					<Col sm={3}>
						<Button
							onClick={() => window.open(`/exercise/module/add-edit${stringifyQueryParams({ curriculumId, mode: 'add' })}`, '_blank')}
						>
							{' '}
							Add Module
						</Button>
					</Col>
				</Row>
				<Row>
					<Col sm={12} className="mt-1 mb-4 rounded">
						{modulesState.additionalData?.curriculum?.displayName && (
							<h4>Curriculum Name : {modulesState.additionalData?.curriculum?.displayName}</h4>
						)}
						{modulesState.additionalData?.curriculum?.adminCode && (
							<h4>Curriculum AdminCode : {modulesState.additionalData?.curriculum?.adminCode}</h4>
						)}
					</Col>
				</Row>
				{isNotEmptyArray(modulesRow) && (
					<>
						<DataPaginationTable heads={heads} rows={modulesRow} onSort={onSort} />
						<Pagination
							itemsCount={modulesState.total}
							itemsToShow={modulesState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{modulesState.total === 0 && <div className="">No curriculums</div>}
			</CardBody>
		</Card>
	);
};

export default ModuleList;
