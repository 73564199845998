import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, ObjectMaybe, isNotEmptyObject, isNotEmptyArray, omitWrapper, ArrayMaybe } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { InputNew as Input, InputNew } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { DropzoneNew as Dropzone } from 'reusableComponents/HookForm/Dropzone';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import { IPhotoDatabase, IPhoto } from 'types/Photo';
import { getHtmlMarkdownConverter, RichTextArea } from 'reusableComponents/HookForm/RichTextArea';
import { fetchPlansAndPackages, fetchVideos, parsePlanAndPacakgesOptions } from 'utils/fetchHelpers';
import { programTypes } from 'types/Program';
import { validateAspectRatio } from 'utils/imageHelpers';
import { languageList, textStyleOptions, bottomStripAlignmentOptions } from 'utils/constants';
import If from 'reusableComponents/If';
import { SmartButtonCTAFieldArray } from 'components/SmartButtonCTAFieldArray';
import { IMetaTags } from 'types/MetaTags';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { createClass, updateClass } from '../actions/class.actions';
import { getPlayerInstance, getSanitizedVideoUrl } from '../helpers';

const HtmlMarkdownConverter = getHtmlMarkdownConverter({ openLinksInNewWindow: true });

const classStatusOptions = [
	{ label: 'ACTIVE', value: 'ACTIVE' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
	{ label: 'DELETED', value: 'DELETED' },
];

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface ClassFormValues {
	title: string;
	slug: string;
	programId: { label: string; value: string };
	featuredCoverUrl?: IPhoto[];
	coverPicture?: IPhoto[];
	coverPicture2By1?: IPhoto[];
	coverPicture3By2?: IPhoto[];
	coverPicture4By3?: IPhoto[];
	status: { label: string; value: string };
	language?: { label: string; value: string };
	duration: {
		mins: number;
		secs: number;
	};
	video: { label: string; value: string; id: string };
	highlightVideo: { label: string; value: string; id: string };
	photos?: [{ photo: IPhoto | File; caption: string }];
	plansAndPackages: { label: string; value: string }[];
	description?: string;
	postPaymentDescription?: string;
	materials?: string;
	isLocked: boolean;
	isFeatured: boolean;
	isStaticButtonEnabled: boolean;
	button: any;
	isBottomStripEnabled: boolean;
	bottomStrip: any;
	isPostPaymentStaticButtonEnabled: boolean;
	postPaymentButton: any;
	isPostPaymentBottomStripEnabled: boolean;
	postPaymentBottomStrip: any;
	metaTags: IMetaTags;
}

type ClassValuesFromDatabase = Omit<ClassFormValues, 'plansAndPackages'> & {
	id: string;
	programId: string;
	featuredCoverUrl?: IPhotoDatabase;
	coverPicture?: IPhotoDatabase;
	coverPicture2By1?: IPhotoDatabase;
	coverPicture3By2?: IPhotoDatabase;
	coverPicture4By3?: IPhotoDatabase;
	status: string;
	language?: string;
	duration: number;
	video: { id: string };
	highlightVideo: { id: string };
	videoDetails: {
		id: string;
		videoName: string;
		videoUrl: string;
		hlsVideoUrl?: string;
	};
	highlightVideoDetails: {
		id: string;
		videoName: string;
		videoUrl: string;
		hlsVideoUrl?: string;
	};
	programDetails: {
		id: string;
		title: string;
		programType: typeof programTypes[number];
	};
	photos?: IPhotoDatabase[];
	plansAndPackages: string[];
	description?: string;
	postPaymentDescription?: string;
	materials?: string;
	isLocked: boolean;
	isFeatured: boolean;
	createdBy: string;
	updatedby: string;
	buttons: any;
	bottomStrip: any;
	postPaymentButtons: any;
	postPaymentBottomStrip: any;
	metaTags: IMetaTags;
};

interface ModifyClassBeforeCreateOrUpdateArgs extends ClassFormValues {
	_id?: string;
	createdBy: string;
	updatedBy: string;
}

export const modifyClassBeforeCreateOrUpdate = (values: ModifyClassBeforeCreateOrUpdateArgs) => {
	return {
		...omitWrapper(['button', 'bottomStrip', 'postPaymentButton', 'postPaymentBottomStrip'], values),
		description: values.description ? HtmlMarkdownConverter.makeHtml(values.description) : null,
		postPaymentDescription: values.postPaymentDescription ? HtmlMarkdownConverter.makeHtml(values.postPaymentDescription) : null,
		materials: values.materials ? HtmlMarkdownConverter.makeHtml(values.materials) : null,
		programId: values.programId.value,
		video: values.video ? { id: values.video.id } : null,
		/* eslint-disable no-nested-ternary */
		highlightVideo: isNotEmptyObject(values.highlightVideo)
			? values.highlightVideo?.id
				? { id: values.highlightVideo.id }
				: undefined
			: null,
		status: values.status.value,
		language: values.language?.value ?? null,
		plansAndPackages: uniq(values.plansAndPackages ? values.plansAndPackages.map((x) => x.value) : null),
		duration: parseInt(values.duration.mins as any, 10) * 60 + parseInt(values.duration.secs as any, 10),
		button: values?.isStaticButtonEnabled ? values?.button : null,
		postPaymentButton: values?.isPostPaymentStaticButtonEnabled ? values?.postPaymentButton : null,
		bottomStrip: values?.isBottomStripEnabled ? values?.bottomStrip : null,
		postPaymentBottomStrip: values?.isPostPaymentBottomStripEnabled ? values?.postPaymentBottomStrip : null,
	};
};

export const modifyClassBeforeInitialize = (values: ClassValuesFromDatabase, plansAndPackages): ClassFormValues => {
	return {
		...(omitWrapper(['bottomStrip'], values) as ClassValuesFromDatabase),
		plansAndPackages,
		description: values.description ? HtmlMarkdownConverter.makeMarkdown(values.description) : '',
		postPaymentDescription: values.postPaymentDescription ? HtmlMarkdownConverter.makeMarkdown(values.postPaymentDescription) : '',
		materials: values.materials ? HtmlMarkdownConverter.makeMarkdown(values.materials) : '',
		duration: { mins: Math.floor(values.duration / 60), secs: values.duration % 60 },
		programId: { label: values.programDetails.title, value: values.programDetails.id },
		// @ts-expect-error
		video: isNotEmptyObject(values.videoDetails)
			? {
					label: values.videoDetails.videoName,
					value: values.videoDetails.videoUrl || values.videoDetails.hlsVideoUrl,
					id: values.videoDetails.id,
			  }
			: '',
		// @ts-expect-error
		highlightVideo: isNotEmptyObject(values.highlightVideoDetails)
			? {
					label: values.highlightVideoDetails.videoName,
					value: values.highlightVideoDetails.videoUrl || values.highlightVideoDetails.hlsVideoUrl,
					id: values.highlightVideoDetails.id,
			  }
			: '',
		status: classStatusOptions.find((option) => option.value === values.status),
		language: languageList.find((option) => option.value === values.language),
		featuredCoverUrl: values.featuredCoverUrl ? (getPreviewImages(values.featuredCoverUrl, false) as any) : undefined,
		coverPicture: values.coverPicture ? (getPreviewImages(values.coverPicture, false) as any) : undefined,
		coverPicture2By1: values.coverPicture2By1 ? (getPreviewImages(values.coverPicture2By1, false) as any) : undefined,
		coverPicture3By2: values.coverPicture3By2 ? (getPreviewImages(values.coverPicture3By2, false) as any) : undefined,
		coverPicture4By3: values.coverPicture4By3 ? (getPreviewImages(values.coverPicture4By3, false) as any) : undefined,
		photos: values.photos ? (getPreviewImages(values.photos, true) as any) : undefined,
		isStaticButtonEnabled: isNotEmptyArray(values?.buttons),
		isBottomStripEnabled: isNotEmptyObject(values?.bottomStrip),
		isPostPaymentStaticButtonEnabled: isNotEmptyArray(values?.postPaymentButtons),
		isPostPaymentBottomStripEnabled: isNotEmptyObject(values?.postPaymentBottomStrip),
	};
};

interface ClassAddEditFormProps {
	location: { search: { programId?: string; programTitle?: string; classId?: string } };
}

export const ClassAddEditForm = (props: ClassAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<Partial<ClassValuesFromDatabase>>();
	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.classes.isSubmitting,
	}));
	const hookFormMethods = useForm<ClassFormValues>();
	const { programId, programTitle, classId } = ObjectMaybe(parseQueryParams(props.location.search));

	const isEditForm = !!classId;

	const { handleSubmit, watch } = hookFormMethods;

	const formValues = watch([
		'featuredCoverUrl',
		'title',
		'isStaticButtonEnabled',
		'isBottomStripEnabled',
		'isPostPaymentStaticButtonEnabled',
		'isPostPaymentBottomStripEnabled',
	]);

	useEffect(() => {
		if (!isEditForm && formValues.title) hookFormMethods.setValue('slug', formValues.title.toLowerCase().split(' ').join('-'));
	}, [formValues.title]);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/class?id=${classId}`, { method: 'GET' }).then(async (res) => {
				setInitialValues(res.data as ClassValuesFromDatabase);
				const plansAndPackagesData = await parsePlanAndPacakgesOptions(res.data.plansAndPackages);
				hookFormMethods.reset(modifyClassBeforeInitialize(res.data as any, plansAndPackagesData));
			});
		} else {
			fetchWrapper(`/v1/admin/program?id=${programId}`, { method: 'GET' }).then((res) => {
				setInitialValues({ programId, programDetails: { ...initialValues?.programDetails, programType: res.data.programType } } as any);
				hookFormMethods.reset({ programId: { label: programTitle, value: programId } });
			});
		}
	}, []);

	useEffect(() => {
		if (!(formValues.featuredCoverUrl && formValues.featuredCoverUrl.length)) hookFormMethods.setValue('isFeatured', false);
	}, [formValues.featuredCoverUrl]);

	const onSubmit = (values: ClassFormValues) => {
		const modifiedValues = modifyClassBeforeCreateOrUpdate({
			...values,
			_id: isEditForm ? initialValues.id : undefined,
			createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
			updatedBy: loggedInUser.id,
		});

		if (modifiedValues.isLocked) {
			// eslint-disable-next-line
			const shouldProceed = confirm('This class is marked as Locked. Is this correct?');
			if (!shouldProceed) return null;
		}

		if (isEditForm) {
			dispatch(updateClass(modifiedValues));
		} else {
			dispatch(createClass(modifiedValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Class...">
			<Card>
				<CardBody>
					{/* Needed for video duration auto fill */}
					<video className="video-js" style={{ display: 'none' }} id="my-player" playsInline />
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Class</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<Row>
									<Col sm="12">
										<Input label="Title" name="title" />
									</Col>
									<Col sm="6">
										<Input label="Slug" name="slug" required pattern={{ value: /^[a-z0-9-]*$/, message: 'Invalid Slug URL' }} />
									</Col>
									<Col sm="6">
										<SelectField label="Status" name="status" options={classStatusOptions} required />
									</Col>
									<Col sm="6">
										<AsyncSelectField label="Program" name="programId" isDisabled required />
									</Col>
									<Col sm="6">
										<SelectField isClearable label="Language" name="language" options={languageList} />
									</Col>
									<Col sm="12">
										<RichTextArea label="Description" name="description" />
									</Col>
									<Col sm="12">
										<AsyncSelectField
											isMulti
											label="Packages and Plans for this class"
											name="plansAndPackages"
											fetchOptions={fetchPlansAndPackages}
										/>
									</Col>
									<Col sm="12">
										<RichTextArea label="Post payment Description (optional)" name="postPaymentDescription" />
									</Col>
									<Col sm="12">
										<RichTextArea label="Materials Required" name="materials" />
									</Col>
									<Col sm="6">
										<Checkbox label="Is Locked?" name="isLocked" helpText="Checking this box will lock the class on the main site" />
									</Col>
									<Col sm="6">
										<VideoSelectField />
									</Col>
									<Col sm="6">
										<AsyncSelectField
											label="Highlight Video"
											name="highlightVideo"
											fetchOptions={(params) => fetchVideos({ ...params, videoType: 'CLASS_HIGHLIGHT' })}
											isClearable
										/>
									</Col>
									<Col sm="12">
										<p>Duration</p>
									</Col>
									<Col sm="6">
										<Input
											type="number"
											label="Minutes"
											name="duration.mins"
											isClickDisabled
											pattern={{ value: /[0-9]/, message: `Minutes should be an integer` }}
											min={0}
											helpText="This will be autofilled after video is selected"
										/>
									</Col>
									<Col sm="6">
										<Input
											type="number"
											label="Seconds"
											name="duration.secs"
											isClickDisabled
											pattern={{ value: /[0-9]/, message: `Seconds should be an integer` }}
											min={0}
											helpText="This will be autofilled after video is selected"
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Cover Picture (3:2 Aspect Ratio)"
											name="coverPicture3By2"
											validate={async (val) => {
												if (val && val.length && !(val[0] as any).id) {
													const isValid = await validateAspectRatio({ aspectRatio: 1.5, file: val[0] as Blob });
													return isValid ? true : 'Please upload photo with 3:2 Aspect Ratio';
												}
												return true;
											}}
										/>
									</Col>
									{initialValues?.programDetails?.programType === 'COMPETITION' && (
										<Col sm="12">
											<Dropzone
												label="Cover Picture (4:3 Aspect Ratio)"
												name="coverPicture4By3"
												validate={async (val) => {
													if (val && val.length && !(val[0] as any).id) {
														const isValid = await validateAspectRatio({ aspectRatio: 1.33, file: val[0] as Blob });
														return isValid ? true : 'Please upload photo with 4:3 Aspect Ratio';
													}
													return true;
												}}
											/>
										</Col>
									)}
									<Col sm="12">
										<Dropzone label="Photos" name="photos" withCaption isMulti noOfFiles={20} />
									</Col>
									<Col sm="6">
										<Checkbox label="Custom CTA" name="isStaticButtonEnabled" />
									</Col>
									<Col sm="12">
										<If
											condition={formValues.isStaticButtonEnabled}
											then={
												<Box>
													<h2 style={{ marginBottom: '1rem' }}>Buttons</h2>
													<SmartButtonCTAFieldArray {...hookFormMethods} buttonsData={initialValues?.buttons} fieldName="button" />
												</Box>
											}
										/>
									</Col>
									<Col sm="12">
										<Checkbox label="Bottom Strip" name="isBottomStripEnabled" />
										<If
											condition={formValues.isBottomStripEnabled}
											then={
												<Box>
													<h4 style={{ marginBottom: '1rem' }}>Bottom Strip</h4>
													<Row>
														<Col sm="6">
															<SelectField
																label="text style"
																name="bottomStrip.textStyle"
																options={textStyleOptions}
																defaultValue={textStyleOptions.find((option) => option.value === initialValues?.bottomStrip?.title?.tStyle)}
															/>
														</Col>
														<Col sm="6">
															<SelectField
																label="Alignment style"
																name="bottomStrip.alignment"
																options={bottomStripAlignmentOptions}
																defaultValue={bottomStripAlignmentOptions.find(
																	(option) => option.value === initialValues?.bottomStrip?.alignment
																)}
															/>
														</Col>
													</Row>
													<Row>
														<Col sm="4">
															<InputNew
																name="bottomStrip.backgroundColor"
																label="background color"
																defaultValue={initialValues?.bottomStrip?.backgroundColor}
															/>
														</Col>
														<Col sm="4">
															<InputNew
																name="bottomStrip.textColor"
																label="text color"
																defaultValue={initialValues?.bottomStrip?.title.textColor}
															/>
														</Col>
														<Col sm="4">
															<InputNew name="bottomStrip.text" label="text" defaultValue={initialValues?.bottomStrip?.title.text} />
														</Col>
													</Row>
												</Box>
											}
										/>
									</Col>
									<Col sm="6">
										<Checkbox label="Post Payment Custom CTA" name="isPostPaymentStaticButtonEnabled" />
									</Col>
									<Col sm="12">
										<If
											condition={formValues.isPostPaymentStaticButtonEnabled}
											then={
												<Box>
													<h2 style={{ marginBottom: '1rem' }}>Post Payment Buttons</h2>
													<SmartButtonCTAFieldArray
														{...hookFormMethods}
														buttonsData={initialValues?.postPaymentButtons}
														fieldName="postPaymentButton"
													/>
												</Box>
											}
										/>
									</Col>
									<Col sm="12">
										<Checkbox label="Post Payment Bottom Strip" name="isPostPaymentBottomStripEnabled" />
										<If
											condition={formValues.isPostPaymentBottomStripEnabled}
											then={
												<Box>
													<h4 style={{ marginBottom: '1rem' }}>Post Payment Bottom Strip</h4>
													<Row>
														<Col sm="6">
															<SelectField
																label="text style"
																name="postPaymentBottomStrip.textStyle"
																options={textStyleOptions}
																defaultValue={textStyleOptions.find(
																	(option) => option.value === initialValues?.postPaymentBottomStrip?.title?.tStyle
																)}
															/>
														</Col>
														<Col sm="6">
															<SelectField
																label="Alignment style"
																name="postPaymentBottomStrip.alignment"
																options={bottomStripAlignmentOptions}
																defaultValue={bottomStripAlignmentOptions.find(
																	(option) => option.value === initialValues?.postPaymentBottomStrip?.alignment
																)}
															/>
														</Col>
													</Row>
													<Row>
														<Col sm="4">
															<InputNew
																name="postPaymentBottomStrip.backgroundColor"
																label="background color"
																defaultValue={initialValues?.postPaymentBottomStrip?.backgroundColor}
															/>
														</Col>
														<Col sm="4">
															<InputNew
																name="postPaymentBottomStrip.textColor"
																label="text color"
																defaultValue={initialValues?.postPaymentBottomStrip?.title.textColor}
															/>
														</Col>
														<Col sm="4">
															<InputNew
																name="postPaymentBottomStrip.text"
																label="text"
																defaultValue={initialValues?.postPaymentBottomStrip?.title.text}
															/>
														</Col>
													</Row>
												</Box>
											}
										/>
									</Col>
									<Box d="flex" ai="center" w="100%">
										<Col sm="6">
											<Input label="SEO Title" name="metaTags.title" />
										</Col>
										<Col sm="6">
											<TextAreaNew label="SEO Description" name="metaTags.description" />
										</Col>
									</Box>
									<Box d="flex" ai="center" w="100%">
										<Col sm="6">
											<Input label="SEO Image Alt Text" name="metaTags.imgAltText" />
										</Col>
									</Box>
								</Row>

								<Col sm="12" className="mt-4">
									<Button size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/class/list')}>
										Cancel
									</Button>
								</Col>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

let player;

function VideoSelectField() {
	const { setValue, watch } = useFormContext();

	const formValues = watch(['video']);

	useEffect(() => {
		player = getPlayerInstance({ videoTagId: 'my-player' });
		player.on('loadedmetadata', () => {
			const videoDuration = player.duration();

			if (!Number.isNaN(videoDuration) && videoDuration !== 0) {
				const minutes = Math.floor(videoDuration / 60);
				const seconds = videoDuration - minutes * 60;
				setValue('duration.mins', minutes);
				setValue('duration.secs', seconds);
			}
		});
		return () => {
			player.dispose();
		};
	}, []);

	useEffect(() => {
		if (!isNotEmptyObject(formValues.video)) {
			setValue('duration.mins', null);
			setValue('duration.secs', null);
		} else if (player && formValues.video) {
			player.src({
				type: 'application/x-mpegURL',
				src: getSanitizedVideoUrl(formValues.video.value),
			});
		}
	}, [formValues.video]);

	return (
		<AsyncSelectField label="Video" name="video" fetchOptions={(params) => fetchVideos({ ...params, videoType: 'CLASS' })} isClearable />
	);
}
