import { reducer as reduxFormReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import sidebarReducer from '../../redux/reducers/sidebarReducer';
import modalReducer from '../../connectors/ModalController/reducer';
import authReducer from '../Authentication/authSlice';
import blogReducer from '../Blog/blogSlice';
import experimentReducer from '../Experiment/experimentSlice';
import categoryReducer from '../Category/categorySlice';
import diyReducer from '../DIY/diySlice';
import faqReducer from '../FAQ/faqSlice';
import userReducer from '../Users/userSlice';
import classReducer from '../Class/classSlice';
import skillReducer from '../Skill/skillSlice';
import programReducer from '../Program/programSlice';
import batchReducer from '../Batch/batchSlice';
import batchClassReducer from '../BatchClass/batchClassSlice';
import referralCodeReducer from '../ReferralCode/referralCodeSlice';
import galleryReducer from '../Gallery/gallerySlice';
import videoReducer from '../Video/videoSlice';
import devSettingsReducer from '../DevSettings/slice';
import whatsappReducer from '../Whatsapp/whatsappSlice';
import mentorReducer from '../Mentor/mentorSlice';
import whatsappChatReducer from '../WhatsappChats/whatsappChatSlice';
import careerReducer from '../Careers/careerSlice';
import successStoryReducer from '../SuccessStory/successStorySlice';
import aboutReducer from '../About/aboutSlice';
import schoolReducer from '../School/SchoolSlice';
import userPostReducer from '../UserPost/userPostSlice';
import reportReducer from '../Report/reportSlice';
import featuredPostReducer from '../FeaturedPost/featuredPostSlice';
import certificateReducer from '../Certificate/certificateSlice';
import dashboardReducer from '../Dashboard/dashboardSlice';
import globalReducer from './globalSlice';
import enrollmentReducer from '../Enrollment/enrollmentsSlice';
import shortReducer from '../Shorts/shortSlice';
import membershipReducer from '../Membership/membershipSlice';
import coachReducer from '../Coach/coachSlice';
import QuestionReducer from '../Quiz/Question/QuestionSlice';
import QuizMediaReducer from '../Quiz/Media/QuizMediaSlice';
import QuizReducer from '../Quiz/QuizSlice';
import ActivityInstancesReducer from '../Quiz/QuizInstance/ActivityInstanceSlice';
import LearningActivitiesReducer from '../LearningActivities/LearningActivitiesSlice';
import ExercisesReducer from '../Exercise/ExercisesSlice';

import allSagas from '../../redux/allSagas';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
	sidebar: sidebarReducer,
	form: reduxFormReducer,
	modals: modalReducer,
	auth: authReducer,
	blog: blogReducer,
	category: categoryReducer,
	diy: diyReducer,
	faq: faqReducer,
	classes: classReducer,
	skill: skillReducer,
	school: schoolReducer,
	program: programReducer,
	batch: batchReducer,
	batchClass: batchClassReducer,
	experiment: experimentReducer,
	gallery: galleryReducer,
	video: videoReducer,
	devSettings: devSettingsReducer,
	whatsapp: whatsappReducer,
	careers: careerReducer,
	successStory: successStoryReducer,
	about: aboutReducer,
	mentor: mentorReducer,
	user: userReducer,
	whatsappChat: whatsappChatReducer,
	userPost: userPostReducer,
	report: reportReducer,
	featuredPost: featuredPostReducer,
	certificate: certificateReducer,
	global: globalReducer,
	referralCode: referralCodeReducer,
	enrollments: enrollmentReducer,
	dashboard: dashboardReducer,
	shorts: shortReducer,
	membership: membershipReducer,
	coach: coachReducer,
	questions: QuestionReducer,
	quizMedia: QuizMediaReducer,
	quizes: QuizReducer,
	activityInstances: ActivityInstancesReducer,
	learningActivities: LearningActivitiesReducer,
	exercises: ExercisesReducer,
});

const store = configureStore({
	reducer,
	middleware: [sagaMiddleware, thunkMiddleware],
	devTools: true,
});

allSagas.forEach((x) => sagaMiddleware.run(x));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
