import React, { useEffect, useRef, useState } from 'react';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { extractWordsWithIndices, getUniqueAlphabets } from './helpers';
import style from './wordMatrix.module.scss';

const WordMatrix = (props) => {
	const letterGrid = props.watch('letterGrid');
	const [dimension, setDimension] = useState(10);
	const [grid, setGrid] = useState(Array.from({ length: dimension }, () => Array(dimension).fill('')));
	const [extractedWords, setExtractedWords] = useState([]);
	const [uniqueAlphabets, setUniqueAlphabets] = useState([]);

	const inputRefs = useRef([]);

	const handleKeyDown = (e, rowIndex, colIndex) => {
		const currentIndex = rowIndex * grid[0].length + colIndex;

		switch (e.key) {
			case 'ArrowRight':
				if (colIndex < grid[0].length - 1) {
					inputRefs.current[currentIndex + 1].focus();
				}
				break;
			case 'ArrowLeft':
				if (colIndex > 0) {
					inputRefs.current[currentIndex - 1].focus();
				}
				break;
			case 'ArrowDown':
				if (rowIndex < grid.length - 1) {
					inputRefs.current[currentIndex + grid[0].length].focus();
				}
				break;
			case 'ArrowUp':
				if (rowIndex > 0) {
					inputRefs.current[currentIndex - grid[0].length].focus();
				}
				break;
			case 'Backspace':
				e.preventDefault();
				updateGrid(rowIndex, colIndex, '');
				if (colIndex > 0) {
					const prevIndex = currentIndex - 1;
					inputRefs.current[prevIndex].focus();
				} else if (rowIndex > 0) {
					const prevIndex = currentIndex - grid[0].length;
					inputRefs.current[prevIndex].focus();
				}
				break;
			default:
				break;
		}
	};

	const updateGrid = (rowIndex, colIndex, value) => {
		const updatedGrid = grid.map((rowArr, row) => rowArr.map((cell, col) => (rowIndex === row && colIndex === col ? value : cell)));
		setGrid(updatedGrid);
	};

	const handleInputChange = (rowIndex, colIndex, value) => {
		const uppercaseValue = value.toUpperCase();

		updateGrid(rowIndex, colIndex, uppercaseValue);

		const currentIndex = rowIndex * grid[0].length + colIndex;

		if (uppercaseValue && colIndex < grid[0].length - 1) {
			inputRefs.current[currentIndex + 1].focus();
		}
	};

	const validateWords = async (words) => {
		const wordsWithValidation = await Promise.all(
			words.map(async (word) => {
				return {
					...word,
					isValid: await checkWord(word.word),
				};
			})
		);
		setExtractedWords(wordsWithValidation);
	};

	const checkWord = async (inputWord) => {
		const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${inputWord}`;

		try {
			const response = await fetch(url);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			await response.json();
			return true;
		} catch (error) {
			return false;
		}
	};

	useEffect(() => {
		if (letterGrid) {
			setGrid(letterGrid);
			setDimension(letterGrid.length);
		}
	}, []);

	useEffect(() => {
		inputRefs.current = inputRefs.current.slice(0, grid.length * grid[0].length);
	}, [dimension]);

	useEffect(() => {
		const matrix = grid.map((row) => [...row]);
		const words = extractWordsWithIndices(matrix);

		validateWords(words);

		setUniqueAlphabets(getUniqueAlphabets(words.map((item) => item.word)));
		props.setValue('letterGrid', JSON.stringify(matrix));
	}, [grid]);

	return (
		<>
			<div
				style={{
					visibility: 'hidden',
					width: 0,
					height: 0,
				}}
			>
				<TextAreaNew name="letterGrid" label="letterGrid" />
			</div>
			<div className={style.dimensionSelect}>
				<label htmlFor="rows-select">
					Dimension:
					<select
						id="rows-select"
						value={dimension}
						onChange={(e) => {
							setDimension(Number(e.target.value));
							setGrid(Array.from({ length: Number(e.target.value) }, () => Array(Number(e.target.value)).fill('')));
						}}
					>
						{Array.from({ length: 10 }, (_, i) => i + 1)
							.slice(2)
							.map((num) => (
								<option key={num} value={num}>
									{num}
								</option>
							))}
					</select>
				</label>
			</div>

			<div
				style={{
					display: 'flex',
				}}
			>
				<div className={style.matrixContainer}>
					{grid.map((row, rowIndex) => (
						<div className={style.matrixRow} key={rowIndex}>
							{row.map((cell, colIndex) => (
								<input
									key={`${rowIndex}-${colIndex}`}
									ref={(el) => {
										inputRefs.current[rowIndex * grid[0].length + colIndex] = el;
									}}
									type="text"
									value={cell}
									onKeyDown={(e) => {
										if (/^[A-Z]$/.test(e.key.toUpperCase())) {
											handleInputChange(rowIndex, colIndex, e.key.toUpperCase());
										}
										handleKeyDown(e, rowIndex, colIndex);
									}}
									className={`${cell ? style.matrixCellFilled : style.matrixCell}`}
								/>
							))}
						</div>
					))}
				</div>
				{extractedWords.length > 0 && (
					<div className={style.extractedWords}>
						<h3>Extracted Words</h3>
						<ul>
							{extractedWords.map((word, index) => (
								<li
									key={word}
									style={{
										borderRadius: '10px',
										backgroundColor: word.isValid ? '#bdffbf' : '#ffb0b2',
										margin: '2px',
										padding: '3px 10px',
									}}
								>
									{word.word}
								</li>
							))}
						</ul>
					</div>
				)}
				{uniqueAlphabets.length > 0 && (
					<div className={style.extractedWords}>
						<h4>Unique Alphabets</h4>
						<ul>
							{uniqueAlphabets.map((alphabet, index) => (
								<li>{alphabet}</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</>
	);
};

export default WordMatrix;
