import { createSlice } from '@reduxjs/toolkit';

const date = new Date(new Date().setHours(0, 0, 0, 0));

const batchClassSlice = createSlice({
	name: 'batchClass',
	initialState: {
		loading: false,
		batchClasses: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'dateTime',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			batchId: '',
			query: '',
			minDate: new Date(new Date().setHours(0, 0, 0, 0)),
			maxDate: new Date(date.setDate(date.getDate() + 1)),
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.query !== undefined && { query: action.payload.query }),
				...(action.payload.batchId && { batchId: action.payload.batchId }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getBatchClasses: () => {},
		getBatchClassesSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.batchClasses = action.payload.batchClasses;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const { toggleLoading, setApiParams, getBatchClasses, getBatchClassesSuccess, toggleIsSubmitting } = batchClassSlice.actions;

export default batchClassSlice.reducer;
