import { createSlice } from '@reduxjs/toolkit';

const successStorySlice = createSlice({
	name: 'successStory',
	initialState: {
		loading: false,
		stories: [],
		story: {},
		storyId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.limit !== undefined && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getStories: () => {},
		getStoriesSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.stories = action.payload.stories;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getStory: () => {},
		getStorySuccess: (state, action) => {
			state.story = action.payload.story;
		},
		getStoryId: () => {},
		getStoryIdSuccess: (state, action) => {
			state.storyId = action.payload.storyId;
		},
		setStoryStatus: () => {},
		setStoryStatusSuccess: (state, action) => {
			state.stories[action.payload.pos].status = action.payload.status;
		},
		setStoryFeatured: () => {},
		setStoryFeaturedSuccess: (state, action) => {
			state.stories[action.payload.pos].isFeatured = action.payload.isFeatured;
		},
		storyOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getStories,
	getStoriesSuccess,
	getStory,
	getStorySuccess,
	getStoryId,
	getStoryIdSuccess,
	setStoryStatus,
	setStoryStatusSuccess,
	storyOnSubmit,
	toggleIsSubmitting,
	setStoryFeatured,
	setStoryFeaturedSuccess,
} = successStorySlice.actions;

export default successStorySlice.reducer;
