import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MentorTable from './components/MentorTable';
import AddEditMentor from './components/AddEdit';

const Mentor = () => {
	return (
		<div>
			<Switch>
				<Route path="/mentor/list" component={MentorTable} />
				<Route path="/mentor/add-edit" component={AddEditMentor} />
			</Switch>
		</div>
	);
};

export default Mentor;
