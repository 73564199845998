import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
	accessDeepObject,
	copyToClipBoard,
	isAllowedEmail,
	isNotEmptyArray,
	isNotEmptyObject,
	parseQueryParams,
	showToast,
} from 'utils/commonHelpers';
import Filter from 'reusableComponents/Filter';
import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { format } from 'date-fns';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { FormProvider, useForm } from 'react-hook-form';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { fetchMentors } from 'utils/fetchHelpers';
import { isNumber } from 'lodash';
import { Checkbox } from 'reusableComponents/Form/Checkbox';
import Box from 'reusableComponents/Box';
import history from '../../App/history';
import { getBatchClasses, setApiParams } from '../batchClassSlice';
import { ageRangeOptions } from '../../../utils/constants';
import CoachSelectModal from './CoachSelectModal';
import { removeCoachBtc, updateCoachBtc } from '../actions/batchtoclass.actions';

interface BTCTableHeads<T = any> {
	'classDetails.title': T;
	'programDetails.title': T;
	coachName: T;
	mentor: T;
	checkbox: T;
	ageRange: T;
	coachPassword: T;
	classNum: T;
	dateTime: T;
	viewerCount: T;
	attendanceCount: T;
	status: T;
	actions: T;
}

const excludedSortColumns = ['status', 'actions', 'mentor', 'ageRange'];

const coaches = JSON.parse(process.env.REACT_APP_COACHES || '[]');

const coachesList = [...coaches.map((coach) => ({ label: coach.name, value: coach.email })), { label: 'Unassigned', value: 'Unassigned' }];

const workingHoursList = [
	{ label: '10 AM', value: 10 },
	{ label: '11 AM', value: 11 },
	{ label: '12 PM', value: 12 },
	{ label: '1 PM', value: 13 },
	{ label: '2 PM', value: 14 },
	{ label: '3 PM', value: 15 },
	{ label: '4 PM', value: 16 },
	{ label: '5 PM', value: 17 },
	{ label: '6 PM', value: 18 },
	{ label: '7 PM', value: 19 },
	{ label: '8 PM', value: 20 },
	{ label: '9 PM', value: 21 },
];

const statusList = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
];

const customStylesMargin = {
	marginBottom: '0px',
};

const timelineList = [
	{ label: 'All', value: 'ALL' },
	{ label: 'Future', value: 'FUTURE' },
	{ label: 'Past', value: 'PAST' },
];

const programTypeList = [
	{ label: 'DIY', value: 'DIY' },
	{ label: 'Storytime', value: 'STORYTIME' },
	{ label: 'Regular', value: 'REGULAR' },
	{ label: 'Podcast', value: 'PODCAST' },
	{ label: 'Competition', value: 'COMPETITION' },
];

const continuityTypeList = [
	{ label: 'Continuous', value: 'CONTINUOUS' },
	{ label: 'Fixed', value: 'FIXED' },
];

function copyClassLinkToClipboard(classNum: string) {
	copyToClipBoard(`https://yellowclass.com/live-class/${classNum}`);
	showToast('success', 'Link Copied successfully!');
}

function StatusBadge({ status }: { status: 'ACTIVE' | 'INACTIVE' | 'DELETED' }) {
	switch (status) {
		case 'ACTIVE':
			return <div className="badge badge-success">Active</div>;
		case 'INACTIVE':
			return <div className="badge badge-warning">Inactive</div>;
		default:
			<div>Invalid Status</div>;
	}
}

const Actions = ({ coachEmail, classNum, batchToClassId, dateTime, setModalParams, programType }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const dispatch = useDispatch();
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const isPastDatedClass = new Date() > new Date(dateTime);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					disabled={isPastDatedClass && programType !== 'COMPETITION'}
					onClick={() => history.push(`/batch-class/edit?batchToClassId=${batchToClassId}&programType=${programType}`)}
				>
					Edit
				</DropdownItem>
				<DropdownItem onClick={() => copyClassLinkToClipboard(classNum)}>Copy Class Link</DropdownItem>
				<DropdownItem
					onClick={() =>
						setModalParams({
							isOpen: true,
							initialValue: {
								id: batchToClassId,
								coachEmail: coachEmail || null,
							},
						})
					}
				>
					{coachEmail ? 'Edit' : 'Assign'} Coach
				</DropdownItem>
				{coachEmail ? <DropdownItem onClick={() => dispatch(removeCoachBtc({ batchToClassId }))}>Remove Coach</DropdownItem> : null}
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ batchToClasses, selectedBtcs, onCheckBtc, adminEmail, setModalParams }) {
	return batchToClasses.map(
		({
			id,
			coachEmail,
			programType,
			classDetails,
			programDetails,
			classNum,
			dateTime,
			viewerCount,
			attendanceCount,
			status,
			coachPassword,
			mentorDetails,
		}) => {
			return {
				id,
				checkbox: <Checkbox checked={selectedBtcs.has(id)} onChange={(e, checked) => onCheckBtc(id, checked)} />,
				classDetails: { title: classDetails?.title },
				programDetails: { title: programDetails?.title },
				dateTime: format(new Date(dateTime), 'dd-MM-yyyy HH:mm'),
				classNum,
				viewerCount: isNumber(viewerCount) ? viewerCount : '---',
				attendanceCount: isNumber(attendanceCount) ? attendanceCount : '---',
				mentor: mentorDetails?.fullName,
				ageRange: `${programDetails?.ageRange?.min}-${programDetails?.ageRange?.max} yrs`,
				coachName: coachEmail ? coaches.find((coach) => coach.email === coachEmail).name.split(' ')[0] : '---',
				coachPassword: isAllowedEmail('REACT_APP_COACH_PASSWORD_ADMINS', adminEmail) ? coachPassword : '*****',
				status: <StatusBadge status={status} />,
				actions: <Actions {...{ classNum, dateTime, batchToClassId: id, setModalParams, coachEmail, programType }} />,
			};
		}
	);
}

export const BatchClassTable = () => {
	const batchClassState = useSelector(({ batchClass }) => ({
		loading: batchClass.loading,
		batchClasses: batchClass.batchClasses,
		pageNo: batchClass.pageNo,
		total: batchClass.total,
		apiParams: batchClass.apiParams,
	}));

	const loggedInUser = useSelector(({ auth }) => auth.user);

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
	const { handleSubmit, watch } = hookFormMethods;
	const formValues = watch([
		'minDate',
		'maxDate',
		'coach',
		'status',
		'timeline',
		'programType',
		'continuity',
		'ageRangeMin',
		'ageRangeMax',
		'mentorId',
		'coachStartHour',
		'coachEndHour',
	]);

	const [batchClassRows, setBatchClassRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [searchTerm, setSearchTerm] = useState();
	const [modalParams, setModalParams] = useState({ isOpen: false, initialValue: null });
	const [selectedCoach, setSelectedCoach] = useState(null);
	const [selectedBtcs, setSelectedBtcs] = React.useState(new Set<string>());
	const [heads, setHeads] = useState<TableHeads<BTCTableHeads>>([
		{ accessor: 'classDetails.title', Header: 'Class Title' },
		{ accessor: 'programDetails.title', Header: 'Program Title' },
		{ accessor: 'mentor', Header: 'Mentor' },
		{ accessor: 'ageRange', Header: 'Age Range' },
		{ accessor: 'coachName', Header: 'Coach' },
		{ accessor: 'coachPassword', Header: 'Coach Password' },
		{ accessor: 'classNum', Header: 'Class No.' },
		{ accessor: 'dateTime', Header: 'Class Time' },
		{ accessor: 'viewerCount', Header: 'View Count' },
		{ accessor: 'attendanceCount', Header: 'Attendance Count' },
		{ accessor: 'status', Header: 'Status' },
		{ accessor: 'actions', Header: 'Actions' },
	]);

	let isAnySelected = false;
	let isAllSelected = true;

	(batchClassState.batchClasses as any[]).forEach((btc) => {
		if (selectedBtcs.has(btc.id)) {
			isAnySelected = true;
			return;
		}
		isAllSelected = false;
	});

	useEffect(() => {
		const searchQP = parseQueryParams(window.location.search);
		if (!isNotEmptyObject(searchQP)) {
			getBatchesWithFilters();
		} else {
			const { batchId } = searchQP;
			dispatch(
				setApiParams({
					batchId,
					query: searchTerm || '',
					limit: 20,
					skip: 0,
				})
			);
			getBatchesWithFilters();
		}
	}, []);

	useEffect(() => {
		setPageOfItems(batchClassState.pageNo);
	}, [batchClassState.pageNo]);

	useEffect(() => {
		if (isNotEmptyObject(selectedCoach))
			setHeads([
				{
					accessor: 'checkbox',
					disableSort: true,
					Header: (
						<Checkbox checked={isAllSelected} indeterminate={!isAllSelected && isAnySelected} value="select-all" onChange={onSelectAll} />
					),
				},
				...heads.filter((head) => head.accessor !== 'checkbox'),
			]);
		else setHeads(heads.filter((head) => head.accessor !== 'checkbox'));
	}, [selectedCoach, isAllSelected, isAnySelected]);

	React.useEffect(() => {
		if (isNotEmptyArray(batchClassState.batchClasses)) {
			const structuredRows = createRows({
				batchToClasses: batchClassState.batchClasses,
				adminEmail: loggedInUser.email[0],
				setModalParams,
				onCheckBtc,
				selectedBtcs,
			});
			setBatchClassRows(structuredRows);
		} else {
			setBatchClassRows([]);
		}
	}, [batchClassState.batchClasses, selectedBtcs]);

	const getBatchesWithFilters = () => {
		dispatch(
			// @ts-ignore
			getBatchClasses({
				...(formValues.minDate && {
					minDate: new Date(new Date(formValues.minDate || format(new Date(), 'yyyy-MM-dd')).setHours(0, 0, 0, 0)),
				}),
				...(formValues.maxDate && {
					maxDate: new Date(new Date(formValues.maxDate || format(new Date(), 'yyyy-MM-dd')).setHours(0, 0, 0, 0)),
				}),
				...(isNotEmptyObject(formValues.coach) && { coachEmail: formValues.coach.value }),
				...(isNotEmptyObject(formValues.status) && { status: formValues.status.value }),
				...(isNotEmptyObject(formValues.programType) && {
					programType: formValues.programType.value,
				}),
				...(isNotEmptyObject(formValues.continuity) && {
					continuity: formValues.continuity.value,
				}),
				...(isNotEmptyObject(formValues.coachStartHour) && {
					coachStartHour: formValues.coachStartHour.value,
				}),
				...(isNotEmptyObject(formValues.coachEndHour) && {
					coachEndHour: formValues.coachEndHour.value,
				}),
				...(isNotEmptyObject(formValues.ageRangeMin) &&
					isNotEmptyObject(formValues.ageRangeMax) && {
						ageRange: JSON.stringify({
							min: formValues.ageRangeMin.value,
							max: formValues.ageRangeMax.value,
						}),
					}),
				...(isNotEmptyObject(formValues.mentorId) && {
					mentorId: formValues.mentorId.value,
				}),
				/* eslint-disable no-nested-ternary */
				...(isNotEmptyObject(formValues.timeline)
					? formValues.timeline.value === 'FUTURE'
						? {
								minDate: new Date(),
						  }
						: formValues.timeline.value === 'PAST'
						? {
								maxDate: new Date(),
						  }
						: {}
					: {}),
			})
		);
	};

	const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (checked) {
			setSelectedBtcs((btcs) => {
				const tempSelectedClasses = new Set(btcs);
				batchClassState.batchClasses.forEach((btc) => {
					tempSelectedClasses.add(btc.id);
				});
				return tempSelectedClasses;
			});
		} else {
			setSelectedBtcs((btcs) => {
				const tempSelectedClasses = new Set(btcs);
				batchClassState.batchClasses.forEach((btc) => {
					tempSelectedClasses.delete(btc.id);
				});

				return tempSelectedClasses;
			});
		}
	};

	const onCheckBtc = (id: string, checked: boolean) => {
		if (checked) {
			setSelectedBtcs((btcs) => {
				const tempSelectedClasses = new Set(btcs);
				tempSelectedClasses.add(id);
				return tempSelectedClasses;
			});
		} else {
			setSelectedBtcs((btcs) => {
				const tempSelectedClasses = new Set(btcs);
				tempSelectedClasses.delete(id);
				return tempSelectedClasses;
			});
		}
	};

	const onChangePage = (itemsPage) => {
		const { apiParams } = batchClassState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			getBatchesWithFilters();
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (!excludedSortColumns.includes(sortColumn) && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', batchClassState),
					skip: accessDeepObject('apiParams.limit', batchClassState) * (pageOfItems - 1),
				})
			);
			getBatchesWithFilters();
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		getBatchesWithFilters();
	};

	const handleCoachSelect = (value) => {
		setSelectedCoach(value);
		setModalParams({ isOpen: false, initialValue: null });
	};

	const handleCoachBtcSubmit = (values) => {
		dispatch(updateCoachBtc(values));
		setSelectedBtcs(new Set<string>());
		setSelectedCoach(null);
	};

	return (
		<>
			{modalParams.isOpen ? (
				<CoachSelectModal
					initialValue={modalParams.initialValue}
					handleSubmit={handleCoachSelect}
					handleCoachUpdate={handleCoachBtcSubmit}
					closeModal={() => {
						setModalParams({ isOpen: false, initialValue: null });
					}}
				/>
			) : null}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={12} className="mb-3">
								<h3>Batch Classes Listing</h3>
							</Col>
							<Col sm={6}>
								<Filter
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onSearch={onSearch}
									placeholder="Search by Class Title, Class Number"
								/>
							</Col>
							<FormProvider {...hookFormMethods}>
								<form
									style={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
									onSubmit={handleSubmit(() => {
										const minAge = formValues.ageRangeMin?.value;
										const maxAge = formValues.ageRangeMax?.value;
										if ((!maxAge && minAge) || (!minAge && maxAge)) {
											return showToast('error', 'select both min & max age range');
										}
										if (maxAge <= minAge) {
											return showToast('error', 'ageRangeMax should be greater than ageRangeMin');
										}
										dispatch(
											setApiParams({
												...batchClassState.apiParams,
												query: searchTerm,
												sortKey: 'dateTime',
												sortOrder: '-1',
												limit: 20,
												skip: 0,
											})
										);
										getBatchesWithFilters();
									})}
								>
									<Col sm="2">
										<Input
											type="date"
											name="minDate"
											defaultValue={format(batchClassState.apiParams.minDate, 'yyyy-MM-dd')}
											label="Min Date"
										/>
									</Col>
									<Col sm="2">
										<Input
											type="date"
											name="maxDate"
											defaultValue={format(batchClassState.apiParams.maxDate, 'yyyy-MM-dd')}
											label="Max Date"
										/>
									</Col>

									<Box w="100%" d="flex">
										<Col sm="3">
											<SelectField isClearable label="Coach" name="coach" options={coachesList} />
										</Col>
										<Col sm="3">
											<SelectField
												isClearable
												label="Coach Start Hour"
												name="coachStartHour"
												options={
													isNotEmptyObject(formValues.coachEndHour)
														? workingHoursList.filter((hour) => hour.value <= formValues.coachEndHour.value)
														: workingHoursList
												}
											/>
										</Col>
										<Col sm="3">
											<SelectField
												isClearable
												label="Coach End Hour"
												name="coachEndHour"
												options={
													isNotEmptyObject(formValues.coachStartHour)
														? workingHoursList.filter((hour) => hour.value >= formValues.coachStartHour.value)
														: workingHoursList
												}
											/>
										</Col>
									</Box>

									<Col sm="3">
										<SelectField isClearable label="Status" name="status" options={statusList} />
									</Col>
									<Col sm="3">
										<SelectField isClearable label="Timeline" name="timeline" options={timelineList} />
									</Col>
									<Col sm="3">
										<SelectField label="Program Type" isClearable name="programType" options={programTypeList} />
									</Col>
									<Col sm="3">
										<SelectField isClearable label="Continuity" name="continuity" options={continuityTypeList} />
									</Col>
									<Col sm="3">
										<SelectField label="Age Range Min" name="ageRangeMin" options={ageRangeOptions} isClearable />
									</Col>
									<Col sm="3">
										<SelectField label="Age Range Max" name="ageRangeMax" options={ageRangeOptions} isClearable />
									</Col>
									<Col sm="3">
										<AsyncSelectField label="Mentor" name="mentorId" fetchOptions={fetchMentors} isClearable />
									</Col>
									<Button type="submit" style={customStylesMargin} size="sm" color="primary">
										Filter
									</Button>
								</form>
							</FormProvider>
						</Row>
						{isNotEmptyObject(selectedCoach) ? (
							<Row>
								<p className="mb-2">
									Currently Selected Coach: <h4 style={{ display: 'inline-block', marginLeft: '0.5rem' }}>{selectedCoach.name}</h4>
								</p>
								<Button
									type="button"
									onClick={() => handleCoachBtcSubmit({ batchToClasses: selectedBtcs, coach: selectedCoach })}
									style={{ marginLeft: '1rem', marginBottom: '1rem' }}
									size="sm"
									color="primary"
									disabled={!selectedBtcs.size}
								>
									Assign Coach
								</Button>
							</Row>
						) : null}
						{isNotEmptyArray(batchClassRows) && (
							<>
								<DataPaginationTable heads={heads} rows={batchClassRows} onSort={onSort} excludedSortColumns={excludedSortColumns} />
								<Pagination
									itemsCount={batchClassState.total}
									itemsToShow={accessDeepObject('apiParams.limit', batchClassState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{batchClassState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};
