import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Showdown from 'showdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { ErrorMessage } from './ErrorMessage';

export const getHtmlMarkdownConverter = ({ openLinksInNewWindow }: { openLinksInNewWindow?: boolean }) => {
	return new Showdown.Converter({
		tables: true,
		simplifiedAutoLink: true,
		strikethrough: true,
		tasklists: true,
		openLinksInNewWindow,
	});
};

const HtmlMarkdownConverter = getHtmlMarkdownConverter({ openLinksInNewWindow: true });

interface Props {
	name: string;
	label: string;
	required?: boolean;
	isClickDisabled?: boolean;
	disabled?: boolean;
	minLength?: number;
	maxLength?: number;
}

export const RichTextArea = ({ name, label, required, minLength, maxLength, disabled, isClickDisabled }: Props) => {
	const { errors, control } = useFormContext();
	const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>('write');

	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<div className="form__form-group-field">
				<div className="form__form-group-input-wrap">
					<Controller
						as={<ReactMde />}
						name={name}
						control={control}
						defaultValue=""
						selectedTab={selectedTab}
						onTabChange={setSelectedTab}
						disabled={disabled}
						rules={{ required, minLength, maxLength }}
						generateMarkdownPreview={(markdown) => Promise.resolve(HtmlMarkdownConverter.makeHtml(markdown))}
					/>
					<ErrorMessage
						{...{ errors, label, name, minLength, maxLength }}
						render={(message) => {
							return <span className="form__form-group-error">{message}</span>;
						}}
					/>
				</div>
			</div>
		</div>
	);
};
