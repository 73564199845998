import { createSlice } from '@reduxjs/toolkit';

const aboutSlice = createSlice({
	name: 'about',
	initialState: {
		loading: false,
		teamMates: [],
		teamMate: {},
		teamMateId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'order',
			sortOrder: '1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getTeamMates: () => {},
		getTeamMatesSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.teamMates = action.payload.teamMates;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getTeamMate: () => {},
		getTeamMateSuccess: (state, action) => {
			state.teamMate = action.payload.teamMate;
		},
		getTeamMateId: () => {},
		getTeamMateIdSuccess: (state, action) => {
			state.teamMateId = action.payload.teamMateId;
		},
		setTeamMateStatus: () => {},
		setTeamMateStatusSuccess: (state, action) => {
			state.teamMates[action.payload.pos].status = action.payload.status;
		},
		teamMateOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getTeamMates,
	getTeamMate,
	toggleIsSubmitting,
	getTeamMateId,
	getTeamMateIdSuccess,
	getTeamMateSuccess,
	getTeamMatesSuccess,
	setTeamMateStatus,
	setTeamMateStatusSuccess,
	teamMateOnSubmit,
} = aboutSlice.actions;

export default aboutSlice.reducer;
