import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomModal from 'reusableComponents/CustomModal';
import theme from 'utils/theme';
import Box from 'reusableComponents/Box';
import { Button, Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { SelectField } from 'reusableComponents/Form/Select';
import { continuityTypeList, programTypeList } from 'containers/Program/components/ProgramTable/ProgramTable';
import { setApiParams } from 'containers/Program/programSlice';
import { fetchPrograms } from 'containers/Program/actions/program.actions';
import { ArrayMaybe } from 'utils/commonHelpers';
import { IProgram } from 'types/Program';

interface CloneClassesModalProps {
	closeModal: any;
	onConfirm: (program: IProgram) => any;
}

const StyledModal = styled.div`
	max-height: 40vh;
	width: 25rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

export default function CloneClassesModal(props: CloneClassesModalProps) {
	const { closeModal } = props;
	const programState = useSelector(({ program }) => ({
		loading: program.loading,
		programs: program.programs,
		pageNo: program.pageNo,
		total: program.total,
		apiParams: program.apiParams,
		isSubmitting: program.isSubmitting,
	}));

	const { programs, apiParams } = programState;
	const { sortKey, sortOrder, limit, skip, status, programType, continuity, progression, language } = apiParams;

	const dispatch = useDispatch();
	const [selectedProgram, setSelectedProgram] = useState(undefined);
	const [searchTerm, setSearchTerm] = React.useState('');
	const programList = ArrayMaybe(programs).map((p) => {
		const ageRangeStr = p.ageRange ? `(${p.ageRange.min} - ${p.ageRange.max} yrs)` : '';
		return { label: `${p.title} ${ageRangeStr}`, value: p.id };
	});

	useEffect(() => {
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
				programType: 'REGULAR',
				continuity: 'CONTINUOUS',
				progression,
				language,
			})
		);
		dispatch(
			fetchPrograms({
				query: searchTerm,
				sortKey,
				sortOrder,
				programType: 'REGULAR',
				continuity: 'CONTINUOUS',
				progression,
				language,
				limit: 20,
				skip: 0,
			})
		);
		return () => {
			setSearchTerm('');
			setSelectedProgram(null);
			dispatch(
				setApiParams({
					query: '',
					limit: 20,
					skip: 0,
					programType: null,
					continuity: null,
					progression,
					language,
				})
			);
		};
	}, []);

	const onSearch = (val) => {
		dispatch(setApiParams({ query: val, status, programType, continuity, progression, language, limit: 20, skip: 0 }));
		dispatch(fetchPrograms({ query: val, sortKey, sortOrder, status, programType, continuity, progression, language, limit: 20, skip: 0 }));
		setSearchTerm(val);
	};

	const onFilterChange = (filterName, val) => {
		setSelectedProgram(null);
		dispatch(
			setApiParams({
				...{ programType, continuity, status, progression, language },
				...{ query: searchTerm, limit: 20, skip: 0, [filterName]: filterName === 'status' ? val || 'ACTIVE' : val },
			})
		);
		dispatch(
			fetchPrograms({
				...{ programType, continuity, status, progression, language },
				...{ sortKey, sortOrder, limit, skip, query: searchTerm, [filterName]: filterName === 'status' ? val || 'ACTIVE' : val },
			})
		);
	};

	const onConfirm = () => {
		props.onConfirm(ArrayMaybe(programs).find((p) => p.id === selectedProgram));
	};

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Box d="flex" ai="center" jc="space-between" pos="relative" w="100%" p="0 0 1rem 0" bsz="border-box">
					<Box w="100%" ellipse fs="1.4rem">
						Clone Clases
					</Box>
				</Box>
				<Row className="mb-2">
					<Col sm={12}>
						<SelectField
							placeholder="Program Type"
							name="programType"
							options={programTypeList}
							value={programType}
							onChange={(val) => onFilterChange('programType', val)}
						/>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col sm={12}>
						<SelectField
							placeholder="Continuity"
							name="continuity"
							value={continuity}
							options={continuityTypeList}
							onChange={(val) => onFilterChange('continuity', val)}
						/>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col sm={12}>
						<SelectField
							clearable
							placeholder="Search By Program ID, Title & Mentor Name"
							name="search"
							options={programList}
							value={selectedProgram}
							inputChangeHandler={onSearch}
							onChange={setSelectedProgram}
						/>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col sm={6}>
						<Button className="mb-1 w-100" size="sm" color="primary" onClick={closeModal}>
							Cancel
						</Button>
					</Col>
					<Col sm={6}>
						<Button className="mb-1 w-100" size="sm" color="primary" disabled={!selectedProgram} onClick={onConfirm}>
							Confirm
						</Button>
					</Col>
				</Row>
			</StyledModal>
		</CustomModal>
	);
}
