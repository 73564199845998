import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { isNotEmptyObject, parseQueryParams, showToast } from 'utils/commonHelpers';
import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { capitalize } from 'lodash';
import { MidjourneyImageGenerator } from 'reusableComponents/MidjourneyImageGenerator';
import { fetchSubtopic, updateSubtopic } from '../actions/exercises.actions';
import { setIsLoading } from '../ExercisesSlice';
import { MediaTypeEnum, TSubtopic } from '../utils/types';
import { convertFileToWebpBlob, createSubtopic, getImageFileFromUrl } from '../utils/helpers';

const SubtopicAddEditForm = (props) => {
	const hookFormMethods = useForm();
	const { mode, moduleEnum } = parseQueryParams(props.location.search) ?? {};
	let { subtopicId } = parseQueryParams(props.location.search) ?? {};
	const dispatch = useDispatch();

	const {
		exercises: { subtopics, isLoading },
		userId,
	} = useSelector(({ exercises, auth }) => ({ exercises, userId: auth?.user?.id }));

	let subtopic = subtopics?.find((subtopic) => subtopic.id === subtopicId);

	useEffect(() => {
		if (isNotEmptyObject(subtopic)) {
			hookFormMethods.reset({
				title: subtopic.displayName,
				description: subtopic.description,
				video: subtopic.video?.mediaPath,
				coverImage: getPreviewImages({ url: subtopic.coverImage?.mediaPath, id: subtopic.coverImage?.mediaId, mediaType: 'image' }, false),
				iterator: subtopic?.iterator,
			});
		} else if (subtopicId) {
			// eslint-disable-next-line no-lonely-if

			dispatch(fetchSubtopic({ subtopicId }));
		}
	}, [subtopic, subtopicId]);

	const onSubmit = async (data) => {
		// validate title
		if (!data.title?.trim()) return showToast('error', 'Please enter subtopic title');
		if (mode === 'add') {
			if (!moduleEnum) return showToast('error', 'Please select a module');
			const payload = {
				moduleEnum,
				title: data.title.trim(),
				description: data.description.trim(),
				userId,
				// eslint-disable-next-line radix
				iterator: parseInt(data.iterator),
			};
			const { data: createdSubtopic } = await createSubtopic(payload);

			if (isNotEmptyObject(createdSubtopic) && createdSubtopic.id) {
				subtopicId = createdSubtopic.id;
				subtopic = { ...createdSubtopic, id: createdSubtopic.id };
				subtopic.adminCode = createdSubtopic.adminCode;
			} else {
				showToast('error', 'Something went wrong');
				// return;
			}
		}
		let { video } = data;
		if (video) {
			// validate url
			// if user gives a link
			if (video.search('http') !== -1 || video.search('https') !== -1) {
				const UrlReg = /[(http)(https)]:\/\/.*\/.*/;
				// check if link is in valid format
				if (!UrlReg.test(video)) return showToast('error', 'Please enter a valid url');
				// trim file path out of url
				video = new URL(video).pathname;
			}
			// if / not at front then append to string
			if (video[0] !== '/') video = `/${video}`;
		}

		dispatch(setIsLoading());

		const imagesKeys = [
			{ key: 'coverImage', isMulti: false, withCaption: false, entityId: subtopicId, entityType: 'SUBTOPIC_OUTCOME_IMAGE' },
		];

		const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		// eslint-disable-next-line radix
		dispatch(
			updateSubtopic({
				subtopicId,
				updatedSubtopic: {
					adminCode: subtopic?.adminCode,
					displayName: data.title.trim() ?? '',
					description: data.description.trim() ?? '',
					video: video ? { mediaPath: video, mediaType: MediaTypeEnum.VIDEO } : null,
					...(imagesUploadData?.coverImage
						? {
								coverImage: {
									mediaId: imagesUploadData.coverImage.id,
									mediaPath: imagesUploadData.coverImage.url,
									mediaType: MediaTypeEnum.IMAGE,
								},
						  }
						: { coverImage: null }),
					// eslint-disable-next-line radix
					iterator: parseInt(data.iterator),
				} as Partial<TSubtopic>,
				userId,
			})
		);
	};

	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />
			<CardBody>
				<h3>Subtopic {capitalize(mode)}</h3>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col>
									<InputNew label="Title" name="title" defaultValue="" required />
								</Col>
							</Row>
							<Row>
								<Col>
									<InputNew label="Description" name="description" />
								</Col>
							</Row>
							<Row>
								<Col>
									<InputNew type="number" label="Should be visible from" defaultValue="1" name="iterator" />
								</Col>
							</Row>
						</Box>
						{/* Not required these fields based on Ticket YT-10767 */}
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col>
									<DropzoneNew label="Associated Image (1:1)" name="coverImage" />
									<MidjourneyImageGenerator
										width="3rem"
										height="3rem"
										acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
											const imageName = `coverImage`;

											const file = await getImageFileFromUrl({
												imageUrl,
												imageName: `${imageName}.png`,
											});

											const convertedFile = await getImageFileFromUrl({
												imageUrl: (await convertFileToWebpBlob({
													file,
												})) as string,
												imageName: `${imageName}.webp`,
											});

											hookFormMethods.setValue('coverImage', [convertedFile]);
										}}
									/>
								</Col>
							</Row>
						</Box>

						<Button type="submit">{mode === 'edit' ? 'Update' : capitalize(mode)}</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default SubtopicAddEditForm;
