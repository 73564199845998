import styled from 'styled-components';

export const CropModalWrapper = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	.close {
		align-self: flex-end;
		margin-right: 2rem;
	}
	button {
		align-self: flex-center;
		margin-top: 3rem;
	}
	.crop-modal {
		&__title {
			margin: 1.5rem 0;
			text-align: center;
			font-size: 1.6rem;
		}
	}
	.image-crop {
		div:first-child {
			height: 100%;
		}
		img {
			image-orientation: from-image;
		}
	}
`;
