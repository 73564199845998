import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GenerateBlogForm from './components/generateBlog';

const WebsiteBlog = () => {
	return (
		<div>
			<Switch>
				<Route path="/website-blog/generate" component={GenerateBlogForm} />
			</Switch>
		</div>
	);
};
export default WebsiteBlog;
