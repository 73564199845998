import React from 'react';
import MenuIcon from 'mdi-react/MenuIcon';
import ReloadIcon from 'mdi-react/ReloadIcon';

import Box from 'reusableComponents/Box';
import ProfilePic from '../../shared/img/default-profile/user.svg';

const ChatTopbar = ({ onClick, mobile, onRefresh }) => {
	return (
		<div className="chat__topbar">
			<button className="chat__topbar-button chat__topbar-button--menu" type="button" onClick={onClick}>
				<MenuIcon className="chat__topbar-button-icon" />
			</button>

			{mobile && (
				<>
					<div className="chat__contact">
						<div className="chat__topbar-contact">
							<div className="chat__topbar-avatar">
								<img src={ProfilePic} alt="" />
							</div>
							<p className="chat__topbar-contact-name">{mobile}</p>
						</div>
					</div>
					<Box className="chat__topbar-right" d="flex" ai="center">
						<button className="chat__topbar-button" type="button" onClick={onRefresh}>
							<ReloadIcon />
						</button>
					</Box>
				</>
			)}
		</div>
	);
};

export default ChatTopbar;
