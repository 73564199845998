import React from 'react';
import { ArrayMaybe } from 'utils/commonHelpers';
import { TableCoverPicture } from './TableCoverPicture';
import { UserPostTableCoverPicture } from './UserPostTableCoverPicture';

export const PostMedia = ({
	media,
	setIsVideoPreviewModalOpen,
	isUserPostListing,
}: {
	media: any;
	setIsVideoPreviewModalOpen?: any;
	isUserPostListing?: boolean;
}) => {
	const imageMedia = ArrayMaybe(media)
		.filter((m) => m.mediaType === 'IMAGE' || m.mediaType === 'VIDEO')
		.map((m) => (
			<div style={{ marginRight: '5px', float: 'left' }}>
				{isUserPostListing ? (
					<UserPostTableCoverPicture {...{ coverPicture: m, title: '', setIsVideoPreviewModalOpen }} />
				) : (
					<TableCoverPicture {...{ coverPicture: m, title: '', setIsVideoPreviewModalOpen }} />
				)}
			</div>
		));
	return <div style={{ display: 'table', width: 150 }}>{imageMedia}</div>;
};
