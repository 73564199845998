import { createSlice } from '@reduxjs/toolkit';

import { showToast } from '../../utils/commonHelpers';

const globalSlice = createSlice({
	name: 'global',
	initialState: {
		errors: {},
		breadcrumbs: [],
	},
	reducers: {
		setBreadcrumbs: (state, action) => {
			state.breadcrumbs = action.payload.breadcrumbs;
		},
		setError: (state, action) => {
			if (typeof action.payload.errors === 'string') {
				state.errors = { message: action.payload.errors };
				showToast('error', action.payload.errors);
			} else if (typeof action.payload.errors === 'object') {
				state.errors = action.payload.errors;
				if (action.payload.errors?.msg) {
					showToast('error', `🚫️ ${action.payload.errors.msg}`);
				} else if (action.payload.errors?.error) {
					showToast('error', action.payload.errors?.error?.toString());
				} else {
					Object.keys(action.payload.errors).forEach((key) => {
						showToast('error', `${key}: ${action.payload.errors[key]}`);
					});
				}
			}
		},
	},
});

export const { setError, setBreadcrumbs } = globalSlice.actions;

export default globalSlice.reducer;
