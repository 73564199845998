import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DashboardAddEditForm } from './components/DashboardAddEditForm';
import { DashboardTable } from './components/DashboardTable';

const Dashboard = () => {
	return (
		<div>
			<Switch>
				<Route path="/dashboard/list" component={DashboardTable} />
				<Route path="/dashboard/add-edit" component={DashboardAddEditForm} />
			</Switch>
		</div>
	);
};

export default Dashboard;
