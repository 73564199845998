import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { getFaqTypesSuccess } from '../faqSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader } from '../../../utils/commonHelpers';

export function* getFaqTypesSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/faq/types`);
	if (res.status === 200) {
		yield put(actionSpreader(getFaqTypesSuccess.type, { faqTypes: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
