import { call, put } from 'redux-saga/effects';

import { toggleLoading, setWhatsappGroupStatusSuccess } from '../whatsappSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader, showToast, pickWrapper } from '../../../utils/commonHelpers';
import { requestSaga } from '../../../utils/requestSaga';

export function* setWhatsappGroupStatusSaga({ payload }) {
	const modifiedParams = pickWrapper(['id', 'isActive'], payload);
	const res = yield call(requestSaga, 'PUT', `${process.env.REACT_APP_API_URL}/v1/admin/whatsapp/status`, {
		body: JSON.stringify(modifiedParams),
	});
	if (res.status === 200 && res.data) {
		yield put(
			actionSpreader(setWhatsappGroupStatusSuccess.type, {
				pos: payload.pos,
				isActive: payload.isActive,
			})
		);
		showToast('success', `👍️ Whatsapp Group Status Successfully Changed!`);
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
