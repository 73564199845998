import { createSlice } from '@reduxjs/toolkit';

const careerSlice = createSlice({
	name: 'careers',
	initialState: {
		loading: false,
		openings: [],
		opening: {},
		openingId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getOpenings: () => {},
		getOpeningsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.openings = action.payload.openings;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getOpening: () => {},
		getOpeningSuccess: (state, action) => {
			state.opening = action.payload.opening;
		},
		getOpeningId: () => {},
		getOpeningIdSuccess: (state, action) => {
			state.openingId = action.payload.openingId;
		},
		setOpeningStatus: () => {},
		setOpeningStatusSuccess: (state, action) => {
			state.openings[action.payload.pos].status = action.payload.status;
		},
		openingOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getOpenings,
	getOpeningsSuccess,
	getOpening,
	getOpeningSuccess,
	getOpeningId,
	getOpeningIdSuccess,
	setOpeningStatus,
	setOpeningStatusSuccess,
	openingOnSubmit,
	toggleIsSubmitting,
} = careerSlice.actions;

export default careerSlice.reducer;
