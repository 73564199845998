import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omitWrapper } from 'utils/commonHelpers';
import { isEmpty } from 'lodash';
import {
	addQuestionToExercise,
	deleteExerciseQuestion,
	fetchCurriculums,
	fetchExercise,
	fetchExerciseQuestion,
	fetchExerciseQuestions,
	fetchExercises,
	fetchModules,
	fetchModule,
	fetchSubtopic,
	fetchSubtopics,
	reorderExerciseQuestions,
	setExerciseQuestionStatus,
	setExerciseStatus,
	syncExercises,
	updateExercise,
	updateExerciseQuestion,
	updateSubtopic,
	updateModule,
	addModule,
	updateModuleStatus,
	updateModuleRank,
	updateSubtopicRank,
	updateExerciseRank,
	fetchSimilarQuestions,
	getAllCourses,
} from './actions/exercises.actions';
import { TExercisesState } from './utils/types';

type TExerciseReducerType<T> = CaseReducer<TExercisesState, PayloadAction<T>>;

type TExerciseReducers = {
	setIsLoading: TExerciseReducerType<void>;
	setIsLoaded: TExerciseReducerType<void>;
	setError: TExerciseReducerType<string>;
	setApiParams: TExerciseReducerType<TExercisesState['apiParams']>;
	setPage: TExerciseReducerType<number>;
	toggleIsSubmitting: TExerciseReducerType<{ isSubmitting: boolean }>;
};

const exercisesSlice = createSlice<TExercisesState, TExerciseReducers>({
	name: 'exercises',
	initialState: {
		curriculums: [],
		modules: [],
		subtopics: [],
		exercises: [],
		questions: [],
		courses: [],
		additionalData: {},
		isLoading: false,
		error: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 50,
			skip: 0,
			query: '',
			sortKey: '_id',
			sortOrder: '-1',
		},
	},
	reducers: {
		setIsLoading: (state) => {
			state.isLoading = true;
		},
		setIsLoaded: (state) => {
			state.isLoading = false;
		},
		setError: (state, action) => {
			state.error = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.query !== 'undefined' && { query: action.payload.query }),
				...(action.payload.filters && { filters: action.payload.filters }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		// Fetch Curriculums
		builder
			.addCase(fetchCurriculums.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchCurriculums.fulfilled, (state, { payload }) => {
				if (payload?.curriculums && Array.isArray(payload.curriculums)) {
					state.curriculums = payload.curriculums;
					state.additionalData = omitWrapper(['curriculums', 'total'], payload);
					state.total = payload.total;
					state.isLoading = false;
				}
			});

		// Fetch Modules
		builder
			.addCase(fetchModules.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchModules.fulfilled, (state, { payload }) => {
				if (payload?.modules && Array.isArray(payload.modules)) {
					state.modules = payload.modules;
					state.additionalData = omitWrapper(['modules', 'total'], payload);
					state.total = payload.total;
					state.isLoading = false;
				}
			});
		// Fetch Module
		builder
			.addCase(fetchModule.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchModule.fulfilled, (state, { payload }) => {
				if (payload?.modules && Array.isArray(payload.modules)) {
					state.modules = payload.modules;
					state.additionalData = omitWrapper(['modules'], payload);
					state.isLoading = false;
				}
			});
		// Update Module
		builder
			.addCase(updateModule.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateModule.fulfilled, (state, { payload }) => {
				if (payload?.modules && Array.isArray(payload.modules)) {
					state.modules = payload.modules;
					state.additionalData = omitWrapper(['modules'], payload);
					state.isLoading = false;
				}
			});
		// Update Module Status
		builder
			.addCase(updateModuleStatus.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateModuleStatus.fulfilled, (state, { payload }) => {
				if (payload?.modules && Array.isArray(payload.modules)) {
					const index = state.modules.findIndex((module) => module.moduleEnum === payload.modules[0].moduleEnum);
					state.modules[index].displayName = payload.modules[0].displayName;
					state.modules[index].status = payload.modules[0].status;
					state.modules[index].description = payload.modules[0].description;
					state.additionalData = omitWrapper(['modules'], payload);
					state.isLoading = false;
				}
			});
		// Add Module
		builder
			.addCase(addModule.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(addModule.fulfilled, (state, { payload }) => {
				if (payload?.modules && Array.isArray(payload.modules)) {
					state.modules = payload.modules;
					state.additionalData = omitWrapper(['modules'], payload);
					state.isLoading = false;
				}
			});
		// Update Module of Rank
		builder
			.addCase(updateModuleRank.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateModuleRank.fulfilled, (state, { payload }) => {
				if (!isEmpty(payload)) {
					const { adminCode, rank, rankLevel } = payload;

					const index = state.modules.findIndex((module) => module.adminCode === adminCode);

					if (index !== -1) {
						if (rankLevel) {
							if (!state.modules[index]?.ranks) {
								state.modules[index].ranks = {
									[rankLevel]: {
										rank,
									},
								};
							} else {
								state.modules[index].ranks = {
									...state.modules[index].ranks,
									[rankLevel]: {
										rank,
									},
								};
							}
						} else {
							state.modules[index].rank = rank;
						}
					}
				}
				state.isLoading = false;
			});
		// Fetch subtopic by id
		builder
			.addCase(fetchSubtopic.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchSubtopic.fulfilled, (state, { payload }) => {
				const { subtopic } = payload ?? {};
				if (subtopic) {
					state.subtopics = [subtopic, ...state.subtopics];
				} else {
					window.open('/404', '_self');
				}
				state.isLoading = false;
			});

		// Fetch Subtopics
		builder
			.addCase(fetchSubtopics.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchSubtopics.fulfilled, (state, { payload }) => {
				if (payload?.subtopics && Array.isArray(payload.subtopics)) {
					state.subtopics = payload.subtopics;
					state.additionalData = omitWrapper(['subtopics', 'total'], payload);
					state.total = payload.total;
					state.isLoading = false;
				}
			});
		// update subtopic
		builder
			.addCase(updateSubtopic.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateSubtopic.fulfilled, (state, { payload }) => {
				const { updatedSubtopic } = payload ?? {};
				if (!isEmpty(updatedSubtopic)) {
					const index = state.subtopics.findIndex((subtopic) => subtopic.id === updatedSubtopic.id);
					if (index !== -1) {
						const subtopics = [...state.subtopics];
						subtopics[index] = updatedSubtopic;
						state.subtopics = subtopics;
					} else {
						state.subtopics = [updatedSubtopic, ...state.subtopics];
					}
				}
				state.isLoading = false;
			});

		// update subtopic rank
		builder
			.addCase(updateSubtopicRank.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateSubtopicRank.fulfilled, (state, { payload }) => {
				if (!isEmpty(payload)) {
					const { rank, subtopicId } = payload;
					const index = state.subtopics.findIndex((subtopic) => subtopic.id === subtopicId);
					state.subtopics[index].rank = rank;
				}
				state.isLoading = false;
			});
		// Fetch Exercises
		builder
			.addCase(fetchExercises.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchExercises.fulfilled, (state, { payload }) => {
				if (payload?.exercises && Array.isArray(payload.exercises)) {
					state.exercises = payload.exercises;
					state.additionalData = omitWrapper(['exercises', 'total'], payload);
					state.total = payload.total;
					state.isLoading = false;
				}
			});
		// update exercise rank
		builder
			.addCase(updateExerciseRank.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateExerciseRank.fulfilled, (state, { payload }) => {
				if (!isEmpty(payload)) {
					const { exerciseId, rank } = payload;
					const index = state.exercises.findIndex((exercise) => exercise.id === exerciseId);
					state.exercises[index].rank = rank;
					state.exercises[index].rankNumber = parseInt(rank.toString().split('.')[0], 10);
					state.exercises[index].rankDecimal = parseInt(rank.toString().split('.')[1], 10);
				}
				state.isLoading = false;
			});
		// Fetch Exercise Questions
		builder
			.addCase(fetchExerciseQuestions.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchExerciseQuestions.fulfilled, (state, { payload }) => {
				if (payload?.questions && Array.isArray(payload.questions)) {
					state.questions = payload.questions;
					state.additionalData = omitWrapper(['questions', 'total'], payload);
					state.total = payload.total;
					state.isLoading = false;
				}
			});
		// Fetch Similar Questions
		builder
			.addCase(fetchSimilarQuestions.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchSimilarQuestions.fulfilled, (state, { payload }) => {
				state.questions = payload.questions;
				state.additionalData = omitWrapper(['questions', 'total'], payload);
				state.total = payload.total;
				state.isLoading = false;
			});
		// Sync Exercises
		builder
			.addCase(syncExercises.pending, (state) => {
				state.isSubmitting = true;
			})
			.addCase(syncExercises.fulfilled, (state) => {
				state.isSubmitting = false;
			});

		// Set Exercise Status
		builder
			.addCase(setExerciseStatus.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(
				setExerciseStatus.fulfilled,
				(
					state,
					{
						meta: {
							arg: { exerciseId },
						},
						payload,
					}
				) => {
					const { isUpdated, status } = payload ?? {};
					if (isUpdated) {
						const index = state.exercises.findIndex((exercise) => exercise.id === exerciseId);
						const exercises = [...state.exercises];
						exercises[index].status = status;
					}
					state.isLoading = false;
				}
			);
		// Set ExerciseQuestion Status
		builder
			.addCase(setExerciseQuestionStatus.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(
				setExerciseQuestionStatus.fulfilled,
				(
					state,
					{
						meta: {
							arg: { questionId },
						},
						payload,
					}
				) => {
					const { isUpdated, status } = payload ?? {};
					if (isUpdated) {
						const index = state.questions.findIndex((question) => question.id === questionId);
						const questions = [...state.questions];
						questions[index].status = status;
					}
					state.isLoading = false;
				}
			);

		builder
			.addCase(fetchExerciseQuestion.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchExerciseQuestion.fulfilled, (state, { payload }) => {
				const { exerciseQuestion } = payload ?? {};
				if (exerciseQuestion) {
					state.questions = [exerciseQuestion, ...state.questions];
				} else {
					window.open('/404', '_self');
				}
				state.isLoading = false;
			});

		builder
			.addCase(fetchExercise.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchExercise.fulfilled, (state, { payload }) => {
				const { exercise } = payload ?? {};
				if (exercise) {
					state.exercises = [exercise, ...state.exercises];
				} else {
					window.open('/404', '_self');
				}
				state.isLoading = false;
			});

		builder
			.addCase(updateExerciseQuestion.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateExerciseQuestion.fulfilled, (state, { payload }) => {
				const { updatedQuestion } = payload ?? {};
				if (updatedQuestion) {
					const index = state.questions.findIndex((question) => question.id === updatedQuestion.id);
					if (index) {
						const questions = [...state.questions];
						questions[index] = updatedQuestion;
						state.questions = questions;
					} else {
						state.questions = [updatedQuestion, ...state.questions];
					}
				}
				state.isLoading = false;
			});

		builder
			.addCase(updateExercise.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateExercise.fulfilled, (state, { payload }) => {
				const { updatedExercise } = payload ?? {};
				if (updatedExercise) {
					const index = state.exercises.findIndex((exercise) => exercise.id === updatedExercise.id);
					if (index) {
						const exercises = [...state.exercises];
						exercises[index] = updatedExercise;
						state.exercises = exercises;
					} else {
						state.exercises = [updatedExercise, ...state.exercises];
					}
				}
				state.isLoading = false;
			});

		/** Courses  */
		builder
			.addCase(getAllCourses.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAllCourses.fulfilled, (state, { payload }) => {
				state.courses = payload;
				state.isLoading = false;
			});
		// * Please don't remove below commented code, as it might be useful in future
		// builder
		// 	.addCase(deleteExerciseQuestion.pending, (state) => {
		// 		state.isLoading = true;
		// 	})
		// 	.addCase(
		// 		deleteExerciseQuestion.fulfilled,
		// 		(
		// 			state,
		// 			{
		// 				meta: {
		// 					arg: { questionId },
		// 				},
		// 			}
		// 		) => {
		// const index = state.questions.findIndex((question) => question.id === questionId);
		// if (index !== -1) {
		// 	const questions = [...state.questions];
		// 	questions.splice(index, 1);
		// 	state.questions = questions;
		// }
		// state.isLoading = false;
		// 		}
		// 	);

		// builder
		// 	.addCase(addQuestionToExercise.pending, (state) => {
		// 		state.isLoading = true;
		// 	})
		// 	.addCase(addQuestionToExercise.fulfilled, (state, { payload, meta: { arg } }) => {
		// 		if (payload) {
		// 			window.open(`/exercise/questions/list?exerciseId=${arg.exerciseId}`, '_self');
		// 		}
		// 	});
	},
});

export const { setApiParams, setIsLoading, setIsLoaded, setPage, toggleIsSubmitting } = exercisesSlice.actions;

export default exercisesSlice.reducer;
