import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { InputNew } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { parseQueryParams, showToast } from 'utils/commonHelpers';
import { capitalize } from 'lodash';
import { getAllCourses } from '../actions/exercises.actions';
import { addOrUpdateCourse } from '../utils/helpers';

const CourseAddEditForm = (props) => {
	const { mode, courseId } = parseQueryParams(props.location.search) ?? {};
	const hookFormMethods = useForm();
	const dispatch = useDispatch();
	const { courses, isLoading } = useSelector(({ exercises: { courses, isLoading } }) => ({ courses, isLoading }));
	const course = courses?.find((course) => course.id === courseId);
	const onSubmit = async (data) => {
		const { name, description, need } = data;
		const payload = { name, description, need, id: courseId };
		const response = await addOrUpdateCourse(payload);
		if (response.status === 200) {
			showToast('success', `Course ${mode === 'edit' ? 'updated' : 'added'} successfully`);
		} else {
			showToast('error', 'Error adding course');
		}
	};

	useEffect(() => {
		if (mode === 'edit') {
			dispatch(getAllCourses());
		}
	}, [courseId]);
	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />

			<CardBody>
				<h3>{mode ? capitalize(mode) : ''} Module</h3>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<InputNew label="Name" name="name" defaultValue={course?.name ?? ''} required />
						<InputNew label="Description" name="description" defaultValue={course?.description ?? ''} />
						<InputNew label="Need" name="need" defaultValue={course?.need ?? ''} />
						<Button type="submit">{mode === 'edit' ? 'Update' : 'Add'}</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default CourseAddEditForm;
