import authSaga from '../containers/Authentication/saga';
import blogSaga from '../containers/Blog/saga';
import categorySaga from '../containers/Category/saga';
import classSaga from '../containers/Class/saga';
import programSaga from '../containers/Program/saga';
import batchSaga from '../containers/Batch/saga';
import referralCodeSaga from '../containers/ReferralCode/saga';
import batchClassSaga from '../containers/BatchClass/saga';
import gallerySaga from '../containers/Gallery/saga';
import whatsappSaga from '../containers/Whatsapp/saga';
import diySaga from '../containers/DIY/saga';
import faqSaga from '../containers/FAQ/saga';
import mentorSaga from '../containers/Mentor/saga';
import usersSaga from '../containers/Users/saga';
import videoSaga from '../containers/Video/saga';
import skillSaga from '../containers/Skill/saga';
import whatsappChatSaga from '../containers/WhatsappChats/saga';
import careerSaga from '../containers/Careers/saga';
import successStorySaga from '../containers/SuccessStory/saga';
import aboutSaga from '../containers/About/saga';

export default [
	authSaga,
	blogSaga,
	categorySaga,
	classSaga,
	programSaga,
	batchSaga,
	batchClassSaga,
	diySaga,
	faqSaga,
	mentorSaga,
	usersSaga,
	gallerySaga,
	videoSaga,
	whatsappSaga,
	careerSaga,
	skillSaga,
	whatsappChatSaga,
	successStorySaga,
	aboutSaga,
	referralCodeSaga,
];
