import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import { AsyncPaginate } from 'react-select-async-paginate';
import { ErrorMessage } from './ErrorMessage';
import { isNotEmptyArray, isNotEmptyObject } from '../../utils/commonHelpers';

let timeout;

const SelectField = ({ name, label, control, options, isRequired = false, error, placeholder, onInputChangeCallback }) => {
	return (
		<div className="form__form-group">
			{label && (
				<span className="form__form-group-label">
					{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<Controller
				as={<Select />}
				placeholder={placeholder}
				name={name}
				control={control}
				onInputChange={(val) => {
					if (onInputChangeCallback) {
						clearTimeout(timeout);
						timeout = setTimeout(() => {
							if (val) onInputChangeCallback(val);
						}, 500);
					}
				}}
				options={options}
				classNamePrefix="react-select"
				className="react-select"
				rules={{ required: isRequired }}
			/>
			{
				/* eslint-disable no-nested-ternary */
				isNotEmptyObject(error) ? (
					error.type === 'required' ? (
						<span className="form__form-group-error">Required</span>
					) : (
						<span className="form__form-group-error">{error.message}</span>
					)
				) : null
			}
		</div>
	);
};

interface Props {
	name: string;
	label: string;
	options: readonly any[];
	placeholder?: string;
	required?: boolean;
	isClearable?: boolean;
	isDisabled?: boolean;
	isClickDisabled?: boolean;
	isMulti?: boolean;
	helpText?: string;
	defaultValue?: Array<any> | { label: any; value: any };
}

export const SelectFieldNew = ({
	name,
	label,
	options,
	required,
	placeholder,
	isClearable,
	isDisabled,
	isMulti = false,
	isClickDisabled,
	helpText,
	defaultValue,
}: Props) => {
	const { control } = useFormContext();

	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<Controller
				as={<Select />}
				placeholder={placeholder}
				name={name}
				control={control}
				defaultValue={isNotEmptyArray(defaultValue) || isNotEmptyObject(defaultValue) ? defaultValue : ''}
				options={options}
				classNamePrefix="select"
				className={`${isMulti ? 'basic-multi-select' : 'react-select'} mb-2`}
				isClearable={isClearable}
				isDisabled={isDisabled}
				isMulti={isMulti}
				rules={{ required }}
			/>
			{helpText && <span className="form__form-group-help-text">{helpText}</span>}
			<ErrorMessage
				{...{ label, name }}
				render={(message) => {
					return <span className="form__form-group-error">{message}</span>;
				}}
			/>
		</div>
	);
};

interface AsyncSelectFieldProps {
	name: string;
	label: string;
	placeholder?: string;
	required?: boolean;
	isClearable?: boolean;
	isMulti?: boolean;
	isDisabled?: boolean;
	isClickDisabled?: boolean;
	fetchOptions?: ({ query, limit, skip }: { query: string; limit?: number; skip?: number }) => Promise<Array<any>>;
	defaultValue?: any[];
	[key: string]: any;
}

export const AsyncSelectField = ({
	name,
	label,
	fetchOptions,
	required = false,
	placeholder,
	isClearable,
	isDisabled,
	isMulti = false,
	isClickDisabled,
	onSelect,
	filterOption,
	defaultValue,
}: AsyncSelectFieldProps) => {
	const [selectOptions, setSelectOptions] = useState([]);
	const { control } = useFormContext();

	useEffect(() => {
		if (fetchOptions) setSelectOptions([]);
	}, [fetchOptions]);

	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<Controller
				as={<Select />}
				placeholder={placeholder}
				filterOption={filterOption}
				name={name}
				control={control}
				onSelect={onSelect}
				{...(isNotEmptyObject(defaultValue) && { defaultValue })}
				onMenuOpen={() => {
					if (fetchOptions) fetchOptions({ query: '' }).then((options) => setSelectOptions(options));
				}}
				onInputChange={(query) => {
					if (fetchOptions) fetchOptions({ query }).then((options) => setSelectOptions(options));
				}}
				options={selectOptions}
				classNamePrefix="select"
				className={`${isMulti ? 'basic-multi-select' : 'react-select'}`}
				isClearable={isClearable}
				isDisabled={isDisabled}
				isMulti={isMulti}
				rules={{ required }}
			/>
			<ErrorMessage
				{...{ label, name }}
				render={(message) => {
					return <span className="form__form-group-error">{message}</span>;
				}}
			/>
		</div>
	);
};

const SINGLE_RENDER_COUNT = 10;

export const AsyncPaginateSelectField = ({
	name,
	label,
	fetchOptions,
	required = false,
	placeholder,
	isClearable,
	isDisabled,
	isMulti = false,
	isClickDisabled,
}: AsyncSelectFieldProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [options, setOptions] = useState([]);

	async function loadOptions(search, loadedOptions, { page }) {
		const newOptions = await fetchOptions({ query: search, skip: (page - 1) * SINGLE_RENDER_COUNT, limit: SINGLE_RENDER_COUNT });
		setOptions((options) => [...options, newOptions]);

		return {
			options: newOptions,
			hasMore: newOptions.length === SINGLE_RENDER_COUNT,
			additional: {
				page: page + 1,
			},
		};
	}

	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<Controller
				as={<AsyncPaginate />}
				loadOptions={loadOptions}
				placeholder={placeholder}
				name={name}
				isClearable={isClearable}
				isDisabled={isDisabled}
				isMulti={isMulti}
				classNamePrefix="react-select"
				additional={{
					page: 1,
				}}
				rules={{ required }}
			/>
			<ErrorMessage
				{...{ label, name }}
				render={(message) => {
					return <span className="form__form-group-error">{message}</span>;
				}}
			/>
		</div>
	);
};

export default SelectField;
