import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { createFaqTypeSuccess } from '../faqSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader } from '../../../utils/commonHelpers';

export function* createFaqTypeSaga({ payload }) {
	console.log('payload', payload);
	const res = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/faq/type`, { body: JSON.stringify(payload) });
	if (res.status === 200) {
		yield put(actionSpreader(createFaqTypeSuccess.type, { faqType: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
