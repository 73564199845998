import { put, call } from 'redux-saga/effects';
import { actionSpreader, stringifyQueryParams } from '../../../utils/commonHelpers';
import { dropboxFilesSuccess, toggleLoading } from '../whatsappSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getDropboxFilesSaga({ payload }) {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));

	const qp = stringifyQueryParams(payload);

	const {
		data: { status, data, msg },
	} = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/dropbox${qp}`);
	if (status !== 200) {
		yield put(actionSpreader(setError.type, { errors: `🚫️ ${msg}` }));
	} else {
		yield put(actionSpreader(dropboxFilesSuccess.type, data));
	}
	yield put(actionSpreader(toggleLoading.type, { loading: false }));
}
