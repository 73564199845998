import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ClassTable } from './components/ClassTable/ClassTable';
import { ClassAddEditForm } from './components/ClassAddEditForm';
import WatchVideo from './components/WatchVideo';
import ClassPolls from './components/ClassPolls';
import ClassTestPolls from './components/ClassTestPolls';

const Class = () => {
	return (
		<div>
			<Switch>
				<Route path="/class/add-edit" component={ClassAddEditForm} />
				<Route path="/class/list" component={ClassTable} />
				<Route path="/class/polls" exact component={ClassPolls} />
				<Route path="/class/polls/test" exact component={ClassTestPolls} />
				<Route path="/class/video/:videoId" exact component={WatchVideo} />
			</Switch>
		</div>
	);
};

export default Class;
