import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';

import CustomModal from '../../../reusableComponents/CustomModal';
import { CropModalWrapper } from './styledComponents';
import { isNotEmptyArray, isNotEmptyObject } from '../../../utils/commonHelpers';
import { blobToFile, getResizedImageDimensions } from '../../../utils/imageHelpers';

import ImageCrop from '../ImageCrop';
import Box from '../../../reusableComponents/Box';

// returns value in Pixels
const InPx = (value) => `${value}px`;

const StyledModal = styled.div`
	height: 100vh;
	width: 100vw;
`;

const getCroppedImg = (image, crop, fileName) => {
	const canvas = document.createElement('canvas');
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	canvas.width = Math.ceil(crop.width * scaleX);
	canvas.height = Math.ceil(crop.height * scaleY);
	const ctx = canvas.getContext('2d');
	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width * scaleX,
		crop.height * scaleY
	);

	let fileUrl = null;

	return new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob) => {
				if (!blob) {
					reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				fileUrl = window.URL.createObjectURL(blob);
				resolve(fileUrl);
			},
			'image/jpeg',
			1
		);
	});
};

const ImageCropModal = ({ closeModal, blobUrls, initialCrop, onCropComplete, hideCloseButton = false }) => {
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [imgs, setImgs] = React.useState([]);
	const [currentImg, setCurrentImg] = React.useState(null);
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const initalCropValue = isNotEmptyObject(initialCrop)
		? initialCrop
		: {
				aspect: blobUrls[0].cropAspectRatio,
				width: 100,
				height: 100,
				unit: '%',
		  };
	const [crop, setCrop] = React.useState(initalCropValue);

	React.useEffect(() => {
		setImgs(
			blobUrls.map(({ id, url, cropAspectRatio }) => ({
				id,
				url,
				crop: {
					aspect: cropAspectRatio,
					width: 100,
					height: 100,
					unit: '%',
				},
				croppedImgUrl: '',
			}))
		);
	}, [blobUrls]);

	const nextHandler = async () => {
		const croppedImgUrl = await getCroppedImg(currentImg, crop, `${currentIndex}.png`);
		let curImg = imgs[currentIndex];
		const processed = cloneDeep(imgs);
		processed.splice(currentIndex, 1);
		curImg = { ...curImg, crop, croppedImgUrl };
		processed.splice(currentIndex, 0, curImg);
		setImgs(processed);
		if (currentIndex === imgs.length - 1) {
			setIsSubmitting(true);
			const imageFiles = await processed.map(async (img, i) => {
				const blob = await fetch(img.croppedImgUrl).then((res) => res.blob());
				const blobFile = blobToFile(blob, `image${i}`);
				const file = new File([blobFile], `image${i}.png`, {
					lastModified: new Date(),
					type: 'image/png',
				});
				file.id = img.id;
				file.preview = URL.createObjectURL(file);
				return file;
			});
			Promise.all(imageFiles).then((values) => {
				onCropComplete(values);
			});
		} else {
			setCurrentIndex(currentIndex + 1);
			setCrop(imgs[currentIndex + 1].crop);
		}
	};

	const imageLoadHandler = (image) => {
		const { naturalWidth: width, naturalHeight: height } = image;

		const img = document.querySelector('.ReactCrop__image');

		const maxWidth = window.innerWidth; // Max width for the image
		const maxHeight = window.innerHeight - 280; // Max height for the image. Do not change the number 280

		const { resizedWidth, resizedHeight } = getResizedImageDimensions({
			width,
			height,
			maxWidth,
			maxHeight,
		});

		img.style.width = InPx(resizedWidth);
		img.style.height = InPx(resizedHeight);

		setCurrentImg(image);
	};

	if (!isNotEmptyArray(imgs)) {
		return <p>Loading..</p>;
	}

	return (
		<CustomModal closeModal={closeModal} escapeOverlay={false}>
			<StyledModal>
				<CropModalWrapper>
					<div className="crop-modal">
						{imgs.length !== 1 && (
							<div className="crop-modal__title">
								Cropping {currentIndex + 1} of {imgs.length} Images
							</div>
						)}
					</div>
					<ImageCrop
						imageLoadHandler={imageLoadHandler}
						keepSelection
						imgSrc={imgs[currentIndex].url}
						crop={crop}
						setCropHandler={(crop) => setCrop(crop)}
					/>
					<Box>
						<Button color="primary" type="button" onClick={nextHandler} loading={isSubmitting}>
							Crop
						</Button>
						<Button color="success" type="button" onClick={closeModal}>
							Close
						</Button>
					</Box>
				</CropModalWrapper>
			</StyledModal>
		</CustomModal>
	);
};

export default ImageCropModal;
