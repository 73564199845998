import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';

import { isNotEmptyArray, isNotEmptyObject } from '../../../../utils/commonHelpers';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import {
	clearWhatsappUserAnalyticsByDate,
	getWhatsappUserAnalytics,
	getWhatsappUserAnalyticsByDate,
	setApiParams,
} from '../../whatsappSlice';
import { dateTimeConverter } from '../../../../utils/dateHelper';
import renderDatePickerField from '../../../../reusableComponents/Form/DatePicker';
import Box from '../../../../reusableComponents/Box';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';

const createRows = (analytics, pageNo, rowsCount) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < analytics.length; i += 1) {
		r.push({
			id: initialCount,
			date: dateTimeConverter(analytics[i].createdAt, 'custom'),
			newUserCount: analytics[i].newUserCount,
			userLeftCount: analytics[i].userLeftCount,
			multipleUserCount: analytics[i].multipleUserCount,
		});
		initialCount += 1;
	}
	return r;
};

const createGroupRows = (groupAnalytics, pageNo, rowsCount) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < groupAnalytics.length; i += 1) {
		r.push({
			id: initialCount,
			group: groupAnalytics[i].group,
			newUserCount: groupAnalytics[i].newUserCount,
			userLeftCount: groupAnalytics[i].userLeftCount,
		});
		initialCount += 1;
	}
	return r;
};

const AnalyticsFilter = (props) => (
	<form className="form ltr-support" onSubmit={props.handleSubmit}>
		<Box d="flex" ai="center" mb="1.5rem" w="100%">
			<Col md="3" sm="12">
				<span className="form__form-group-label">Date</span>
				<Field label="Date" type="text" timeParams={{ showTime: false }} disableFutureDate component={renderDatePickerField} name="date" />
			</Col>
			<Button style={{ marginBottom: 0 }} color="primary" size="sm" type="submit">
				Submit
			</Button>
		</Box>
	</form>
);

const withReduxForm = reduxForm({ form: 'analytics_filters', enableReinitialize: true });
const AnalyticsFilterForm = compose(withReduxForm)(AnalyticsFilter);

const Analytics = () => {
	const [analyticsRows, setAnalyticsRows] = useState(null);
	const dispatch = useDispatch();

	const whatsappState = useSelector(({ whatsapp }) => ({
		loading: whatsapp.loading,
		analyticsObj: whatsapp.analyticsObj,
		analyticsByDate: whatsapp.analyticsByDate,
	}));

	let groupStats;
	let overallStat;
	if (isNotEmptyObject(whatsappState.analyticsByDate)) {
		groupStats = whatsappState.analyticsByDate.totalData;
	}
	if (isNotEmptyObject(whatsappState.analyticsObj)) {
		overallStat = whatsappState.analyticsObj.overallStats;
	}

	useEffect(() => {
		dispatch(getWhatsappUserAnalytics());

		return () => {
			dispatch(clearWhatsappUserAnalyticsByDate());
		};
	}, []);

	const heads = [
		{
			accessor: 'date',
			Header: 'Date',
		},
		{
			accessor: 'newUserCount',
			Header: 'New Users',
		},
		{
			accessor: 'userLeftCount',
			Header: 'Users Left',
		},
		{
			accessor: 'multipleUserCount',
			Header: 'Users in Multiple Groups',
		},
	];

	const groupHeads = [
		{
			accessor: 'group',
			Header: 'Group',
		},
		{
			accessor: 'newUserCount',
			Header: 'New Users',
		},
		{
			accessor: 'userLeftCount',
			Header: 'Users Left',
		},
	];

	React.useEffect(() => {
		if (isNotEmptyObject(whatsappState.analyticsByDate)) {
			const structuredRows = createGroupRows(
				whatsappState.analyticsByDate.groupWiseData,
				1,
				whatsappState.analyticsByDate.groupWiseData.length
			);
			setAnalyticsRows(structuredRows);
		} else if (isNotEmptyObject(whatsappState.analyticsObj) && isNotEmptyArray(whatsappState.analyticsObj.analytics)) {
			const structuredRows = createRows(whatsappState.analyticsObj.analytics, 1, whatsappState.analyticsObj.analytics.length);
			setAnalyticsRows(structuredRows);
		} else {
			setAnalyticsRows([]);
		}
	}, [whatsappState.analyticsObj, whatsappState.analyticsByDate]);

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
				})
			);
			dispatch(getWhatsappUserAnalytics());
		}
	};

	return (
		<LoadingOverlay isLoading={whatsappState.isSubmitting || whatsappState.loading}>
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={6}>
								<h3>Whatsapp Analytics</h3>
							</Col>
						</Row>

						<AnalyticsFilterForm onSubmit={(val) => dispatch(getWhatsappUserAnalyticsByDate(val))} />
						{isNotEmptyObject(whatsappState.analyticsByDate) ? (
							<Box d="flex" ai="center" jc="flex-start">
								<p style={{ margin: '1rem' }}>
									<span style={{ fontWeight: 'bold' }}>Users Joined:</span> {groupStats.newUserCount}
								</p>
								<p style={{ margin: '1rem' }}>
									<span style={{ fontWeight: 'bold' }}>Users Left:</span> {groupStats.userLeftCount}
								</p>
								<p style={{ margin: '1rem' }}>
									<span style={{ fontWeight: 'bold' }}>Users in Multiple Groups:</span> {groupStats.multipleUserCount}
								</p>
							</Box>
						) : (
							isNotEmptyObject(whatsappState.analyticsObj) && (
								<Box d="flex" ai="center" jc="flex-start">
									<p style={{ margin: '1rem' }}>
										<span style={{ fontWeight: 'bold' }}>Total Unique Users:</span> {overallStat.totalUserCount}
									</p>
									<p style={{ margin: '1rem' }}>
										<span style={{ fontWeight: 'bold' }}>Total Active Unique Users:</span> {overallStat.totalActiveUserCount}
									</p>
									<p style={{ margin: '1rem' }}>
										<span style={{ fontWeight: 'bold' }}>Total Inactive Unique Users:</span> {overallStat.totalInactiveUserCount}
									</p>
								</Box>
							)
						)}
						{isNotEmptyArray(analyticsRows) ? (
							<>
								<DataPaginationTable
									heads={isNotEmptyObject(whatsappState.analyticsByDate) ? groupHeads : heads}
									rows={analyticsRows}
									onSort={onSort}
								/>
							</>
						) : (
							<div className=""> No data</div>
						)}
					</CardBody>
				</Card>
			</Col>
		</LoadingOverlay>
	);
};

const withConnect = connect();

export default compose(withConnect)(Analytics);
