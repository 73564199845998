/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getBlogs, setApiParams, onSubmit } from '../../blogSlice';
import { isNotEmptyArray, accessDeepObject, checkRequiredEntitiesPresence, showToast } from '../../../../utils/commonHelpers';
import { dateTimeConverter } from '../../../../utils/dateHelper';
import { STATUS_ENTITY_DRAFT, STATUS_ENTITY_UNPUBLISHED, STATUS_ENTITY_PUBLISHED, WEBSITE_URL } from '../../../../utils/constants';
import history from '../../../App/history';

import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (blogs, pageNo, rowsCount, submitHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < blogs.length; i += 1) {
		r.push({
			id: initialCount,
			title: blogs[i].title,
			status: blogs[i].status === STATUS_ENTITY_DRAFT ? 'DRAFT' : blogs[i].status === STATUS_ENTITY_PUBLISHED ? 'PUBLISHED' : 'UNPUBLISHED',
			createdAt: dateTimeConverter(blogs[i].createdAt, 'format', 'dd MMM yyyy (k:mm)'),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/blogs/add-edit?id=${blogs[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							const status =
								blogs[i].status === STATUS_ENTITY_DRAFT || blogs[i].status === STATUS_ENTITY_UNPUBLISHED
									? STATUS_ENTITY_PUBLISHED
									: STATUS_ENTITY_UNPUBLISHED;
							if (status === STATUS_ENTITY_PUBLISHED) {
								let dd = checkRequiredEntitiesPresence(blogs[i], ['title', 'content', 'slug', 'coverPicture', 'preview']);
								if (blogs[i].content === '<p></p>') {
									dd = [...dd, 'content'];
								}
								if (isNotEmptyArray(dd)) {
									showToast(
										'error',
										`🚫 ${dd.join(', ')} ${dd.length === 1 ? 'is' : 'are'} required and must be filled before publishing.`
									);
									return;
								}
							}
							const updatedParams = { ...blogs[i], status };
							submitHandler(updatedParams);
							window.location.reload();
						}}
					>
						{blogs[i].status === STATUS_ENTITY_DRAFT || blogs[i].status === STATUS_ENTITY_UNPUBLISHED ? 'Publish' : 'Unpublish'}
					</Button>
					<Button
						color="success"
						size="md"
						style={customStyles}
						onClick={() => {
							window.open(`${WEBSITE_URL}/blog/${blogs[i].id}?preview=1`);
						}}
					>
						Preview
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const BlogTable = ({ history }) => {
	const blogState = useSelector(({ blog }) => ({
		loading: blog.loading,
		blogs: blog.blogs,
		pageNo: blog.pageNo,
		total: blog.total,
		apiParams: blog.apiParams,
	}));

	const dispatch = useDispatch();

	const [blogRows, setBlogRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	React.useEffect(() => {
		dispatch(getBlogs());
	}, []);

	React.useEffect(() => {
		setPageOfItems(blogState.pageNo);
	}, [blogState.pageNo]);

	const heads = [
		{
			accessor: 'title',
			Header: 'Title',
		},
		{
			accessor: 'createdAt',
			Header: 'Created At',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		dispatch(onSubmit({ ...params, isEdit: true }));
	};

	React.useEffect(() => {
		if (isNotEmptyArray(blogState.blogs)) {
			const structuredRows = createRows(blogState.blogs, blogState.pageNo, accessDeepObject('apiParams.limit', blogState), submitHandler);
			setBlogRows(structuredRows);
		} else {
			setBlogRows([]);
		}
	}, [blogState.blogs]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = blogState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getBlogs());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', blogState),
					skip: accessDeepObject('apiParams.limit', blogState) * (pageOfItems - 1),
				})
			);
			dispatch(getBlogs());
		}
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8}>
							<h3>Blogs Listing</h3>
						</Col>
						<Col sm={{ size: 3, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/blogs/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(blogRows) && (
						<>
							<DataPaginationTable heads={heads} rows={blogRows} onSort={onSort} />
							<Pagination
								itemsCount={blogState.total}
								itemsToShow={accessDeepObject('apiParams.limit', blogState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{blogState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default BlogTable;
