import { IMAGE_URL } from '../../utils/constants';

export const modifyBeforeSaveBlogDetails = (params) => {
	if (params.content) {
		const srcRegEx = new RegExp(IMAGE_URL, 'g');
		const content = params.content.replace(srcRegEx, '');
		return { ...params, content };
	}
	return params;
};

export const modifyBeforeSetBlogDetails = (params) => {
	if (params.content) {
		const srcRegEx = new RegExp('upload-local/BLOG', 'g');
		const content = params.content.replace(srcRegEx, `${IMAGE_URL}upload-local/BLOG`);
		return { ...params, content };
	}
	return params;
};
