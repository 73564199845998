import { isNotEmptyArray, showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { QuestionTypesEnum } from './types';

export const statusList = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
	{ label: 'Draft', value: 'DRAFT' },
];

export const QuestionTypeFields = [
	{
		label: 'MCQ',
		value: QuestionTypesEnum.MCQ,
	},
	{
		label: 'Media',
		value: QuestionTypesEnum.MEDIA,
	},
	{
		label: 'Read Along',
		value: QuestionTypesEnum.READ_ALONG,
	},
	{
		label: 'Crossword',
		value: QuestionTypesEnum.CROSSWORD,
	},
	{
		label: 'Content Only',
		value: QuestionTypesEnum.CONTENT_ONLY,
	},
	{
		label: 'Conversation',
		value: QuestionTypesEnum.CONVERSATION,
	},
	{
		label: 'Anagram',
		value: QuestionTypesEnum.ANAGRAM,
	},
	{
		label: 'Match the column',
		value: QuestionTypesEnum.MATCH_COLUMN,
	},
	{
		label: 'Teleprompter',
		value: QuestionTypesEnum.TELEPROMPTER,
	},
];

export const getExerciseQuestion = async ({ apiParams }: { apiParams: { questionId?: string; questionAdminCode?: string } }) => {
	const response = await fetchWrapper('/v1/admin/get-exercise-question', {
		method: 'POST',
		body: apiParams,
	});
	const { data } = response;
	if (response.status !== 200) {
		return { error: response.data };
	}
	return { exerciseQuestion: data };
};

export const getOptionType = (option) => {
	const isImageNotEmpty = isNotEmptyArray(option?.image);
	if (option?.value && isImageNotEmpty) {
		return null;
	}
	return option?.value ? 'text' : isImageNotEmpty ? 'image' : null;
};

export const createSubtopic = async (payload) => {
	const response = await fetchWrapper('/v1/admin/add-subtopic', {
		method: 'POST',
		body: payload,
	});

	return response;
};

export const getImageFileFromUrl = async ({ imageUrl, imageName }: { imageUrl: string; imageName: string }) => {
	const response = await fetch(imageUrl);
	const blob = await response.blob();
	const file = new File([blob], imageName, { type: blob.type });
	(file as any).path = imageName;
	(file as any).preview = imageUrl;
	return file;
};

export const convertFileToWebpBlob = async ({ file }) => {
	try {
		if (!file) {
			return;
		}

		// Load the data into an image
		const rawImage = (await new Promise(function (resolve, reject) {
			const rawImage = new Image();

			rawImage.addEventListener('load', function () {
				resolve(rawImage);
			});

			rawImage.src = URL.createObjectURL(file);
		})) as any;

		// Convert image to webp ObjectURL via a canvas blob
		const convertedBlob = await new Promise(function (resolve, reject) {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			canvas.width = rawImage.width;
			canvas.height = rawImage.height;
			ctx.drawImage(rawImage, 0, 0);

			canvas.toBlob(function (blob) {
				resolve(URL.createObjectURL(blob));
			}, 'image/webp');
		});

		return convertedBlob;
	} catch (err) {
		showToast('error', `Unable to convert file to webp ${err.messsage}`);
	}
};

export const addOrUpdateCourse = async (payload) => {
	const response = await fetchWrapper('/v1/admin/add-or-update-course', {
		method: 'POST',
		body: payload,
	});

	return response;
};

export const getAllCourses = async ({ academicClass }: { academicClass?: number }) => {
	const response = await fetchWrapper('/v1/admin/get-all-courses', {
		method: 'POST',
		body: { academicClass },
	});
	return response;
};
