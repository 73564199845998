/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React from 'react';
import { Col, Row } from 'reactstrap';
import { useFormContext } from 'react-hook-form';

import Box from 'reusableComponents/Box';
import { isNotEmptyObject } from '../../utils/commonHelpers';
import { ErrorMessage } from './ErrorMessage';
import Radio from './Radio';

const RadioGroup = React.forwardRef((props: any, ref: any) => {
	return (
		<div className="form__form-group">
			{props.label && (
				<span className="form__form-group-label">
					{props.label} {props.isRequired && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<div className="form__form-group-field">
				<Row>
					{props.fields.map((field, i) => (
						<Col md="12" lg="12" key={i}>
							<Radio key={i} ref={ref} name={props.labelName} label={field.label} value={field.value} disabled={props.disabled} />
						</Col>
					))}
				</Row>
			</div>
			{isNotEmptyObject(props.error) ? (
				props.error.type === 'required' ? (
					<span className="form__form-group-error">Required</span>
				) : (
					<span className="form__form-group-error">{props.error.message}</span>
				)
			) : null}
		</div>
	);
});

interface Props {
	name: string;
	label: string;
	direction?: 'row' | 'column';
	required?: boolean;
	isClickDisabled?: boolean;
	fields: Array<{ label: string; value: string | number }>;
	helpText?: string;
	disabled?: boolean;
}

export const RadioGroupNew = ({
	name,
	label,
	required,
	isClickDisabled,
	fields,
	direction = 'column',
	helpText = '',
	disabled = false,
}: Props) => {
	const { register } = useFormContext();
	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<div className="form__form-group-field">
				<Box d="flex" direction={direction}>
					{fields.map((field) => (
						<Col key={field.value}>
							<Radio disabled={disabled} ref={register({ required })} name={name} label={field.label} value={field.value} />
						</Col>
					))}
				</Box>
			</div>
			{helpText && <span className="form__form-group-help-text">{helpText}</span>}
			<ErrorMessage
				{...{ label, name }}
				render={(message) => {
					return <span className="form__form-group-error">{message}</span>;
				}}
			/>
		</div>
	);
};

export default RadioGroup;
