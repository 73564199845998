import React, { memo, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { RadioNew } from 'reusableComponents/HookForm/Radio';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { PlanToPriceItems } from '../constants';
import { profileLabel } from '../helper';

const PlanDropdown = memo(({ isDisabled, name }: { isDisabled: boolean; name: string }) => {
	return (
		<div>
			<SelectFieldNew
				label="Select plan duration"
				name={name}
				options={PlanToPriceItems}
				defaultValue={null}
				isClearable
				isDisabled={isDisabled}
			/>
		</div>
	);
});

const ProfileSubjectCheckboxRow = ({ isDisabled, profileId, subject }: { isDisabled: boolean; profileId: string; subject: string }) => {
	const { watch, setValue, getValues } = useFormContext();
	const selectedPlan = watch(`${profileId}.${subject}.selectedPlanDuration`);
	const subjectSelected = watch(`${profileId}.${subject}.isSelected`);
	const validSubjectSelected = typeof subjectSelected === 'object' ? subjectSelected[0] === 'on' : subjectSelected;

	const addedAmount = useRef<number>(0);

	useEffect(() => {
		if (!validSubjectSelected) {
			setValue(`${profileId}.${subject}.selectedPlanDuration`, null);
		}
	}, [validSubjectSelected]);

	useEffect(() => {
		const preInsertedAmount = parseInt(getValues('amount') || '0', 10);

		if (validSubjectSelected) {
			if (selectedPlan?.amount) {
				const parsedSelectedPlan = parseInt(selectedPlan.amount, 10);
				const valueToUpdate = preInsertedAmount + parsedSelectedPlan - addedAmount.current;
				setValue('amount', valueToUpdate);
				addedAmount.current = parsedSelectedPlan;
			} else {
				const valueToUpdate = preInsertedAmount - addedAmount.current;
				if (valueToUpdate >= 0 && valueToUpdate !== preInsertedAmount) {
					setValue('amount', valueToUpdate);
					addedAmount.current = 0;
				}
			}
			return null;
		}
		const valueToUpdate = preInsertedAmount - addedAmount.current;
		if (valueToUpdate >= 0 && valueToUpdate !== preInsertedAmount) {
			setValue('amount', valueToUpdate);
			addedAmount.current = 0;
		}
	}, [selectedPlan, validSubjectSelected]);

	const isDisabledFields = !validSubjectSelected || isDisabled;

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
			<div style={{ minWidth: '20%' }}>
				<Checkbox disabled={isDisabled} name={`${profileId}.${subject}.isSelected`} label={subject} />
			</div>
			<Col sm="5">
				<PlanDropdown isDisabled={isDisabledFields} name={`${profileId}.${subject}.selectedPlanDuration`} />
			</Col>
			{selectedPlan?.value === 'custom' && (
				<Col sm="4">
					<InputNew
						name={`${profileId}.${subject}.planDurationInput`}
						min={1}
						step={1}
						type="number"
						disabled={isDisabledFields}
						label="Custom plan duration (in days)"
					/>
				</Col>
			)}
		</div>
	);
};

const ProfileSubjectRadioRow = ({ isDisabled, profileId, subject }: { isDisabled: boolean; profileId: string; subject: string }) => {
	const { watch, setValue, getValues } = useFormContext();
	const selectedPlan = watch(`${profileId}.${subject}.selectedPlanDuration`);
	const subjectSelected = watch(`${profileId}.selectedSubject`) === subject;

	const addedAmount = useRef<number>(0);

	useEffect(() => {
		const preInsertedAmount = parseInt(getValues('amount') || '0', 10);

		if (subjectSelected) {
			if (selectedPlan?.amount) {
				const parsedSelectedPlan = parseInt(selectedPlan.amount, 10);
				const valueToUpdate = preInsertedAmount + parsedSelectedPlan - addedAmount.current;
				setValue('amount', valueToUpdate);
				addedAmount.current = parsedSelectedPlan;
			} else {
				const valueToUpdate = preInsertedAmount - addedAmount.current;
				if (valueToUpdate >= 0 && valueToUpdate !== preInsertedAmount) {
					setValue('amount', valueToUpdate);
					addedAmount.current = 0;
				}
			}
			return null;
		}
		const valueToUpdate = preInsertedAmount - addedAmount.current;
		if (valueToUpdate >= 0 && valueToUpdate !== preInsertedAmount) {
			setValue('amount', valueToUpdate);
			addedAmount.current = 0;
		}
	}, [selectedPlan, subjectSelected]);

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
			<div style={{ minWidth: '20%' }}>
				<RadioNew disabled={isDisabled} name={`${profileId}.selectedSubject`} value={subject} label={subject} />
			</div>
		</div>
	);
};

const ProfileSubjectSelectionCardsWrapper = ({ children, isGroupedByRadio, label, value, isDisabled }) => {
	return (
		<div
			style={{
				padding: '1rem 1rem 0 1rem',
				width: '100%',
				borderRadius: '0.5rem',
				marginBottom: '1rem',
				backgroundColor: '#f5f5f5',
			}}
		>
			{isGroupedByRadio ? (
				<div>
					<RadioNew name="selectedProfile" label={label} value={value} disabled={isDisabled} />
					<div style={{ marginTop: 12 }}>{children}</div>
				</div>
			) : (
				<div>
					<p>{label}</p>
					{children}
				</div>
			)}
		</div>
	);
};

export const ProfileSubjectSelectionCards = ({
	profile,
	isDisabled,
	grades,
	preSelectedGrade,
	multiSelect = true,
	isGroupedByRadio = false,
}: {
	profile: Record<string, 'string'>;
	isDisabled: boolean;
	grades: Array<{ label: string; value: string }>;
	preSelectedGrade?: number | string;
	multiSelect?: boolean;
	isGroupedByRadio?: boolean;
}) => {
	const { watch, setValue } = useFormContext();
	const { selectedProfile } = watch(['selectedProfile', `${profile.id}.selectedSubject`]);
	const isAllFieldDisabled = isDisabled || (isGroupedByRadio && selectedProfile !== profile.id);

	useEffect(() => {
		if (isGroupedByRadio && !multiSelect && selectedProfile !== profile.id) {
			setValue(`${profile.id}.selectedSubject`, null);
		}
	}, [isGroupedByRadio, multiSelect, selectedProfile, profile]);

	return (
		<div
			style={{
				padding: '1rem 1rem 0 1rem',
				width: '100%',
				borderRadius: '0.5rem',
				marginBottom: '1rem',
				backgroundColor: '#f5f5f5',
			}}
		>
			<ProfileSubjectSelectionCardsWrapper
				isGroupedByRadio={isGroupedByRadio}
				label={profileLabel(profile)}
				value={profile.id}
				isDisabled={isDisabled}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{!Number.isInteger(profile.academicClass) &&
						(preSelectedGrade ? (
							<div style={{ width: '75%', fontWeight: 700 }}>
								This user hasn’t selected grade.
								<div>Grade {preSelectedGrade} will be set if subject(s) is/are selected for this profile.</div>
							</div>
						) : (
							<Col sm="5">
								<SelectFieldNew
									label="Select Grade"
									name={`${profile.id}.selectedGrade`}
									options={grades}
									isClearable
									isDisabled={isAllFieldDisabled}
									helpText="This user hasn’t selected grade."
								/>
							</Col>
						))}
				</div>
				{multiSelect ? (
					<div>
						<ProfileSubjectCheckboxRow
							key={`${profile.id}.ENGLISH`}
							isDisabled={isAllFieldDisabled}
							profileId={profile.id}
							subject="ENGLISH"
						/>
						<ProfileSubjectCheckboxRow key={`${profile.id}.MATH`} isDisabled={isAllFieldDisabled} profileId={profile.id} subject="MATH" />
						<ProfileSubjectCheckboxRow key={`${profile.id}.ART`} isDisabled={isAllFieldDisabled} profileId={profile.id} subject="ART" />
					</div>
				) : (
					<div>
						<ProfileSubjectRadioRow
							key={`${profile.id}.ENGLISH`}
							isDisabled={isAllFieldDisabled}
							profileId={profile.id}
							subject="ENGLISH"
						/>
						<ProfileSubjectRadioRow key={`${profile.id}.MATH`} isDisabled={isAllFieldDisabled} profileId={profile.id} subject="MATH" />
						<ProfileSubjectRadioRow key={`${profile.id}.ART`} isDisabled={isAllFieldDisabled} profileId={profile.id} subject="ART" />
					</div>
				)}
			</ProfileSubjectSelectionCardsWrapper>
		</div>
	);
};
