import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UserTable } from './components/UserTable';

const Users = () => {
	return (
		<div>
			<Switch>
				<Route path="/users/list" component={UserTable} />
			</Switch>
		</div>
	);
};

export default Users;
