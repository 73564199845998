import styled from 'styled-components';
import theme from 'utils/theme';

import { OPTIONS_STATE, ANSWER_STATUS } from './helpers';

export const StyledPoll = styled.div`
	margin-top: 0.8rem;
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const answerColors = {
	[ANSWER_STATUS.CORRECT]: theme.clrs.new.cGreen,
	[ANSWER_STATUS.INCORRECT]: theme.clrs.new.cRed,
	[ANSWER_STATUS.UNANSWERED]: theme.clrs.new.cYellow,
	[ANSWER_STATUS.FINAL]: theme.clrs.new.cYellow,
};

export const StyledResult = styled.div`
	font-size: 1.6rem;
	font-weight: 500;
	text-align: center;
	color: ${(props) => answerColors[props.answerStatus]};
	margin-bottom: 1.2rem;
`;

const OptionsColor = {
	[OPTIONS_STATE.CORRECT]: theme.clrs.new.cGreen,
	[OPTIONS_STATE.INCORRECT]: theme.clrs.new.cRedLight,
	[OPTIONS_STATE.SELECTED_BEFORE_RESULT]: theme.clrs.new.cYellow,
	[OPTIONS_STATE.SELECTED_AFTER_RESULT]: theme.clrs.new.cYellow,
	[OPTIONS_STATE.INITIAL]: theme.clrs.new.cBorder,
	[OPTIONS_STATE.FINAL]: theme.clrs.new.cBorder,
};

const OptionsFillColor = {
	[OPTIONS_STATE.CORRECT]: theme.clrs.new.cGreen,
	[OPTIONS_STATE.INCORRECT]: theme.clrs.new.cRedLight,
	[OPTIONS_STATE.SELECTED_AFTER_RESULT]: theme.clrs.new.cYellow,
	[OPTIONS_STATE.FINAL]: theme.clrs.new.cPrimaryText,
};

export const StyledOptionFeedback = styled.div`
	padding-top: 0.8rem;
	font-size: 1.4rem;
`;

export const StyledOptionInner = styled.div`
	padding: 1.4rem 1.6rem;
	display: flex;
	justify-content: space-between;
	font-size: 1.2rem;
	border-radius: 8px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width 0.5s ease-in-out;
		height: 4px;
		opacity: 0.5;
		border-radius: 0 0 8px 8px;
	}
`;

export const StyledOption = styled.div`
	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}

	${StyledOptionInner} {
		border: 1px solid ${(props) => OptionsColor[props.optionState]};
		color: ${(props) => props.theme.clrs.new.cPrimaryText};

		&::after {
			width: ${(props) => props.answerPercent || 0};
			background: ${(props) => OptionsFillColor[props.optionState]};
		}
	}

	${StyledOptionFeedback} {
		color: ${(props) => OptionsColor[props.optionState]};
	}
`;
