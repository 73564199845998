import { createSlice } from '@reduxjs/toolkit';

const diySlice = createSlice({
	name: 'diy',
	initialState: {
		loading: false,
		diys: [],
		diy: {},
		diyId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getDiys: () => {},
		getDiysSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.diys = action.payload.diys;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getDiy: () => {},
		getDiySuccess: (state, action) => {
			state.diy = action.payload.diy;
		},
		getDiyId: () => {},
		getDiyIdSuccess: (state, action) => {
			state.diyId = action.payload.diyId;
		},
		diyOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		setDiyStatus: () => {},
		setDiyStatusSuccess: (state, action) => {
			state.diys[action.payload.pos].status = action.payload.status;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getDiys,
	getDiysSuccess,
	getDiy,
	getDiySuccess,
	getDiyIdSuccess,
	getDiyId,
	diyOnSubmit,
	toggleIsSubmitting,
	setDiyStatus,
	setDiyStatusSuccess,
} = diySlice.actions;

export default diySlice.reducer;
