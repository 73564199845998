import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import theme from 'utils/theme';

const MatchColumnKeyValuePair = ({ control, fieldName = 'item' }) => {
	const keyValueItemFieldArray = useFieldArray({
		control,
		name: fieldName,
	});

	return (
		<Box>
			{keyValueItemFieldArray.fields.map((field, index) => {
				return (
					<Box key={field?.id} pos="relative">
						<h4 style={{ marginBottom: '1rem' }}>Pair {index + 1}</h4>
						<Row>
							<Col sm="6">
								<InputNew name={`${fieldName}[${index}].key`} label="Key" defaultValue={field?.key} />
								<InputNew name={`${fieldName}[${index}].value.text`} label="Text Value" defaultValue={field?.value?.text} />
							</Col>
							<Col sm="6">
								<DropzoneNew
									label="Image Value"
									name={`${fieldName}[${index}].value.image`}
									defaultValue={getPreviewImages(
										{ id: field?.value?.image?.mediaId, mediaType: 'image', url: field?.value?.image?.mediaPath },
										false
									)}
									height="300px"
									width="300px"
								/>
							</Col>
						</Row>
						<Box
							onClick={() => {
								keyValueItemFieldArray.remove(index);
							}}
							cursor="pointer"
							pos="absolute"
							t="0"
							r="0"
						>
							<span className="lnr lnr-cross" />
						</Box>
					</Box>
				);
			})}
			<Row>
				<Col sm="12">
					<Box d="flex" jc="center">
						<Box
							c={theme.clrs.cWhite}
							fs="1rem"
							bRad="6px"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => keyValueItemFieldArray.append({})}
							mb="1rem"
							p=".3rem"
						>
							+ Add New Item
						</Box>
					</Box>
				</Col>
			</Row>
		</Box>
	);
};

export { MatchColumnKeyValuePair };
