import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parseQueryParams, pickWrapper } from 'utils/commonHelpers';
import TestPolls from 'containers/Poll/TestPolls';
import { getClass, getPoll, getPolls } from '../classSlice';

interface ClassPollsProps {
	location: { search: { classId?: string } };
}

const ClassTestPolls = (props: ClassPollsProps) => {
	const dispatch = useDispatch();

	const { polls, poll, selectedClass } = useSelector((state) => ({
		classes: state.classes.classes,
		selectedClass: state.classes.class,
		polls: state.classes.polls,
		poll: state.classes.poll,
	}));

	// @ts-expect-error
	const getPollQuery = (pollId) => dispatch(getPoll({ id: pollId }));

	useEffect(() => {
		if (props?.location?.search) {
			const { classId } = pickWrapper(['classId'], parseQueryParams(props.location.search));
			// @ts-expect-error
			dispatch(getPolls({ classId, sortKey: 'triggerTime', sortOrder: 1 }));
			// @ts-expect-error
			dispatch(getClass({ id: classId }));
		}
	}, [props?.location?.search]);

	return <TestPolls videoUrl={selectedClass?.videoDetails?.videoUrl} getPoll={getPollQuery} polls={polls} poll={poll} />;
};

export default ClassTestPolls;
