import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { accessDeepObject, isNotEmptyArray, stringifyQueryParams } from 'utils/commonHelpers';
import If from 'reusableComponents/If';
import DataPaginationTable, { TableHeads } from 'reusableComponents/Tables/DataPaginationTable';
import Filter from 'reusableComponents/Filter';
import Box from 'reusableComponents/Box';
import { format } from 'date-fns';
import { deleteQuestion, fetchQuestions, updateQuestion } from './actions/question.action';
import { setApiParams } from './QuestionSlice';
import history from '../../App/history';

const dateTimeFormat = 'dd/MM/yyyy HH:mm';

const Actions = ({ question }) => {
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const { id: questionId, title: questionTitle, status } = question;

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const dispatch = useDispatch();
	const questionState = useSelector(({ questions }) => ({
		apiParams: questions.apiParams,
	}));

	const onDeleteQuestion = () => {
		dispatch(deleteQuestion({ questionId }));
	};

	const onStatusUpdate = () => {
		const newStatus = status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
		dispatch(updateQuestion({ status: newStatus, id: questionId }));
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => history.push(`/activity/question/add-edit${stringifyQueryParams({ questionId, questionTitle })}`)}
					disabled={false}
				>
					Edit Question
				</DropdownItem>
				<DropdownItem onClick={onDeleteQuestion} disabled={false}>
					Delete Question
				</DropdownItem>
				<DropdownItem onClick={onStatusUpdate} disabled={false}>
					mark as {status === 'ACTIVE' ? 'inactive' : 'active'}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const heads: TableHeads<any> = [
	{ accessor: 'title', Header: 'Title' },
	{ accessor: 'tags', Header: 'Tags' },
	{ accessor: 'questionType', Header: 'Question Type' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'earnableXp', Header: 'Earnable XP' },
	{ accessor: 'actions', Header: 'Actions' },
];

const createRows = ({ questions }) => {
	return questions.map((question) => {
		const { id, title, questionType, earnableXp, createdAt, tags, status } = question;
		return {
			questionId: id,
			backgroundColor: status === 'ACTIVE' ? 'white' : 'lightgrey',
			title,
			tags: `${tags.map((tag) => tag.label).join(' | ')}`,
			questionType,
			earnableXp,
			createdAt: format(new Date(createdAt), dateTimeFormat),
			actions: <Actions question={question} />,
		};
	});
};

const QuestionList = () => {
	const dispatch = useDispatch();

	const onSearch = (e) => {
		dispatch(setApiParams({ searchKey: searchTerm }));
		e?.preventDefault?.();
		dispatch(fetchQuestions({ ...questionState.apiParams, searchKey: searchTerm }));
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			const sortOrder = sortDirection === 'ASC' ? 1 : -1;
			const limit = questionState?.apiParams?.limit;
			const skip = questionState?.apiParams?.skip * (pageOfItems - 1);
			if (sortColumn === 'tags') sortColumn = 'tags.0.label';

			dispatch(setApiParams({ ...questionState.apiParams, sortKey: sortColumn, limit, skip, sortOrder }));
			dispatch(fetchQuestions({ ...questionState.apiParams, sortKey: sortColumn, limit, skip, sortOrder }));
		}
	};

	const [questions, setQuestions] = React.useState([]);

	const questionState = useSelector(({ questions }) => ({
		questions: questions.questions,
		loading: questions.loading,
		total: questions.total,
		pageNo: questions.pageNo,
		apiParams: questions.apiParams,
	}));

	const [searchTerm, setSearchTerm] = React.useState(questionState.apiParams.searchKey);
	const [pageOfItems, setPageOfItems] = React.useState(questionState.pageNo);

	React.useEffect(() => {
		dispatch(fetchQuestions({ ...questionState.apiParams }));
	}, []);

	React.useEffect(() => {
		setPageOfItems(questionState.pageNo);
	}, [questionState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(questionState.questions)) {
			const questions = createRows({ questions: questionState.questions });
			return setQuestions(questions);
		}
		setQuestions([]);
	}, [questionState.questions]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = questionState;
		if (itemsPage) {
			const skip = (itemsPage - 1) * apiParams.limit;
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip,
				})
			);
			dispatch(fetchQuestions({ ...apiParams, skip }));
		}
	};

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={7} className="mb-3">
						<h3>Questions Listing</h3>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search by question name" onSearch={onSearch} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
						<Button size="sm" color="primary" onClick={() => history.push('/activity/question/add-edit')}>
							Add
						</Button>
					</Col>
				</Row>
				<Box mt="2rem">
					<If
						condition={isNotEmptyArray(questions)}
						then={
							<>
								<DataPaginationTable heads={heads} rows={questions} onSort={onSort} />
								<Pagination
									itemsCount={questionState.total}
									itemsToShow={accessDeepObject('apiParams.limit', questionState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						}
						else={questionState.total === 0 && <div>No data</div>}
					/>
				</Box>
			</CardBody>
		</Card>
	);
};

export default QuestionList;
