import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { createBlogTagSuccess } from '../blogSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader } from '../../../utils/commonHelpers';

export function* createBlogTagSaga({ payload }) {
	const res = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/blog/tags`, { body: JSON.stringify(payload) });
	if (res.status === 200) {
		yield put(actionSpreader(createBlogTagSuccess.type, { blogTag: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
