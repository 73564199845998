import { isNotEmptyObject, showToast } from 'utils/commonHelpers';
import { setExerciseStatus } from '../actions/exercises.actions';
import { ContentDocumentStatusEnum, PlayUiTypeEnum } from './types';

export const changeExerciseStatus = ({
	exerciseId,
	status,
	dispatch,
	playUiType,
	questions,
	video,
}: {
	exerciseId: string;
	status: ContentDocumentStatusEnum;
	dispatch: any;
	playUiType?: PlayUiTypeEnum;
	questions: any;
	video?: { id: string; label: string; value: string };
}) => {
	// validate before setting exercise active
	if (status === ContentDocumentStatusEnum.ACTIVE) {
		// check if there are questions in exercise
		if (!questions || questions.length === 0) {
			return showToast('error', 'Exercise has no questions, please add questions and try again.');
		}

		// extract all active questions
		const activeQuestions = questions.filter(
			(question) => (!question.questionStatus || question.questionStatus === 'ACTIVE') && question.status === 'ACTIVE'
		);

		// validate if exercise has any active questions
		if (activeQuestions?.length === 0) {
			return showToast('error', 'There are no active questions in this exercise, set some questions active and try again');
		}

		// for listen type of exercise check if it has video or not
		if (playUiType === PlayUiTypeEnum.LISTEN) {
			if (!isNotEmptyObject(video)) {
				return showToast('error', 'There is no video in this exercise, please add video and try again');
			}
		}
	}

	dispatch(setExerciseStatus({ exerciseId, status }));
};
