/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { Field, FieldArray } from 'redux-form';
import { Col, Button, Row } from 'reactstrap';
import Select from 'react-select';

import { useSelector } from 'react-redux';
import { renderTextField, renderRadioButtonGroup } from 'reusableComponents/Form/formComponents';
import { renderSelectField } from 'reusableComponents/Form/Select';
import { required } from 'reusableComponents/Form/fieldValidators';
import { ArrayMaybe, getSanitizedImageUrl, isNotEmptyArray, showToast } from 'utils/commonHelpers';
import theme from 'utils/theme';

import Box from 'reusableComponents/Box';
import { getPlayerInstance } from 'reusableComponents/VideoPlayer/helpers';
import { convertTime, getSanitizedVideoUrl } from 'containers/Class/helpers';
import DropZoneField from 'reusableComponents/Form/DropZone';
import { validateAspectRatio } from 'utils/imageHelpers';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import useRefCallback from './hooks/useRefCallback';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
	borderRadius: '50%',
	width: '2rem',
	height: '2rem',
};

const btnStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const questionTypes = [
	{ label: 'Fun', value: 'FUN' },
	{ label: 'Feedback', value: 'FEEDBACK' },
	{ label: 'Assessment', value: 'ASSESSMENT' },
	{ label: 'Other', value: 'OTHER' },
];

const formatOptions = (badges) =>
	ArrayMaybe(badges).map((b) => ({ label: b.name, imageUrl: b.image?.url, createAt: b.createdAt, value: b.id }));
const validatePollAnswer = (value) => {
	return value < 0 || value === undefined ? 'Required' : undefined;
};

const CustomOption = ({ data, innerRef, innerProps }) => {
	return (
		<Box d="flex" ai="center" p="0.5rem" ref={innerRef} {...innerProps}>
			<Box w="2rem" h="2rem" mr="1rem">
				<img
					style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
					src={getSanitizedImageUrl(data.imageUrl)}
					alt={data.label}
				/>
			</Box>
			<Box>{data.label}</Box>
		</Box>
	);
};

export const renderBadgeSelect = (props) => {
	const { input, label, isRequired, meta, options, placeholder, className, onInputChange } = props;

	const selectFieldValue = typeof input.value === 'object' ? input.value : options.find((v) => v.value === input.value);

	return (
		<div className={`form__form-group-input-wrap ${className}`}>
			<span className="form__form-group-label">
				{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
			</span>
			<Select
				{...input}
				value={selectFieldValue}
				onFocus={input.onFocus}
				onInputChange={onInputChange}
				options={options}
				placeholder={placeholder}
				className="react-select"
				classNamePrefix="react-select"
				components={{ Option: CustomOption }}
			/>
			{meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
		</div>
	);
};

const onChangeImageValidation = async ({ changingFieldName, aspectRatio, file, changeHandler }) => {
	if (!isNotEmptyArray(file)) {
		changeHandler(changingFieldName, file);
		return;
	}
	const isValid = await validateAspectRatio({
		aspectRatio,
		file: file[0],
	});
	if (isValid) {
		changeHandler(changingFieldName, file);
		return;
	}

	showToast('error', 'Please insert a valid aspect ratio image');
};

export const renderPollOptions = ({ fields, poll, pollType, changeHandler, pollState }) => {
	return (
		<Row className="mb-8">
			<Col sm="6">
				{fields.map((pollOption, pollOptionIdx) => {
					const option = pollState.options[pollOptionIdx];
					return (
						<Box mb="1rem" pos="relative" key={pollOptionIdx.toString()}>
							<Field
								name={`${pollOption}.option`}
								type="text"
								component={renderTextField}
								label={`Option ${pollOptionIdx + 1}`}
								isRequired
								validate={required}
							/>
							<DropZoneField
								name={`${pollOption}.optionImage`}
								label={`Option ${pollOptionIdx + 1} image (Aspect ratio 3:2)`}
								onChange={async (val) => {
									await onChangeImageValidation({
										changingFieldName: `${pollOption}.optionImage`,
										aspectRatio: 1.5,
										file: val,
										changeHandler,
									});
								}}
								value={
									option.id && option.optionImage?.url
										? getPreviewImages({ id: option.id, url: option.optionImage?.url, mediaType: 'POLL_OPTION_IMAGE' })
										: option.optionImage
								}
								aspectRatio={2}
							/>
							<Box
								onClick={() => {
									if (fields.length > 2) {
										fields.remove(pollOptionIdx);
									} else {
										showToast('error', '✋ At least two options required!');
									}
								}}
								cursor="pointer"
								pos="absolute"
								t="0"
								r="0"
							>
								<span className="lnr lnr-cross" />
							</Box>
						</Box>
					);
				})}
				<Box d="flex" jc="center">
					<Box
						w="2rem"
						h="2rem"
						c={theme.clrs.cWhite}
						fs="1.4rem"
						bRad="50%"
						d="flex"
						jc="center"
						ai="center"
						bg={theme.clrs.cSkyBlue}
						cursor="pointer"
						onClick={() => fields.push({})}
					>
						+
					</Box>
				</Box>
			</Col>
			{/* Only show answer field when the question type is ASSESSMENT */}
			{pollType === 'ASSESSMENT' && (
				<Col sm="6">
					<Field
						label="Answer"
						labelName={`${poll}.answer`}
						name={`${poll}.answer`}
						fields={fields.map((_, fieldIdx) => ({
							label: `Option ${fieldIdx + 1}`,
							value: fieldIdx,
						}))}
						component={renderRadioButtonGroup}
						validate={validatePollAnswer}
						isRequired
					/>
				</Col>
			)}
		</Row>
	);
};

let player;

export const renderPolls = ({
	fields,
	meta: { error, submitFailed },
	fetchBadgesByQuery,
	badges,
	videoUrl,
	changeHandler,
	formSelector,
	isShortsPoll,
}) => {
	const { ref: videoRef, setRef: setVideoRef, isRefInitialized } = useRefCallback();
	const state = useSelector((state) => state);

	// TODO: Migrate to video.js
	useEffect(() => {
		if (videoRef.current) {
			player = getPlayerInstance({ videoTagId: 'my-player' });
			player.src({
				type: 'application/x-mpegURL',
				src: getSanitizedVideoUrl(videoUrl),
			});
		}

		return () => {
			if (player) {
				player.dispose();
			}
		};
	}, [isRefInitialized]);

	return (
		<Box pos="relative">
			<Box pos="fixed" t="1rem" r="1rem" w="30rem" h="20rem" zi="500">
				<video className="video-js" style={{ width: '30rem', height: 200 }} id="my-player" ref={setVideoRef} playsInline />
			</Box>
			<Row className="mb-3">
				<Col sm="12">
					{submitFailed && error && <span>{error}</span>}
					{fields.map((poll, index) => {
						const pollState = formSelector(state, `${poll}`);
						return (
							<Row key={index.toString()}>
								<Box d="flex" jc="space-between" ai="center" w="100%" m="1rem 0">
									<Col sm={10}>
										<h5 className="bold-text">Poll #{index + 1}</h5>
									</Col>
									<Button
										style={customStyles}
										color="danger"
										type="button"
										onClick={() => {
											if (fields.length > 1) {
												fields.remove(index);
											} else {
												showToast('error', '✋ At least one poll required!');
											}
										}}
									>
										<span className="lnr lnr-cross" />
									</Button>
								</Box>
								<Col sm="12">
									<Field
										name={`${poll}.question`}
										type="text"
										component={renderTextField}
										isRequired
										validate={required}
										label="Question"
									/>
								</Col>
								<Col sm="12">
									<Box mb="1rem">
										<DropZoneField
											name={`${poll}.questionImage`}
											label="Question image (Aspect ratio 2:1)"
											onChange={async (val) => {
												await onChangeImageValidation({
													changingFieldName: `${poll}.questionImage`,
													aspectRatio: 2,
													file: val,
													changeHandler,
												});
											}}
											value={
												pollState.id && pollState.questionImage?.url
													? getPreviewImages({ id: pollState.id, mediaType: 'POLL_QUESTION_IMAGE', url: pollState.questionImage?.url })
													: pollState?.questionImage
											}
										/>
									</Box>
								</Col>
								<Col sm="6">
									<Box mb="0.5rem">
										<Field
											name={`${poll}.questionType`}
											options={questionTypes}
											component={renderSelectField}
											label="Question Type"
											isRequired
											validate={required}
										/>
									</Box>
								</Col>
								{!isShortsPoll ? (
									<Col sm="6">
										<Box mb="0.5rem">
											<Field
												name={`${poll}.badgeId`}
												options={formatOptions(badges)}
												component={renderBadgeSelect}
												onInputChange={(query) => {
													if (!query) return;
													fetchBadgesByQuery(query);
												}}
												onFocus={() => fetchBadgesByQuery('')}
												onBlur={(e) => e.preventDefault()}
												label="Badge"
												isRequired
												validate={required}
												placeholder="Search badge by name"
											/>
										</Box>
									</Col>
								) : null}
								<Col sm="12" className="mt-1">
									<FieldArray
										name={`${poll}.options`}
										poll={poll}
										pollType={pollState.questionType}
										initialValue={[{}, {}]}
										component={renderPollOptions}
										changeHandler={changeHandler}
										pollState={pollState}
									/>
								</Col>
								<Col sm="6">
									<span className="form__form-group-label mb-3">
										Trigger Time <span className="form__form_group-label--required">&#42;</span>
									</span>
									<Row>
										<Col sm="3">
											<Field
												name={`${poll}.triggerTime.min`}
												type="number"
												component={renderTextField}
												label="Min"
												isRequired
												validate={required}
											/>
										</Col>
										:
										<Col sm="3">
											<Field
												name={`${poll}.triggerTime.sec`}
												type="number"
												component={renderTextField}
												label="Sec"
												isRequired
												validate={required}
											/>
										</Col>
										<Box d="flex" ai="center">
											<Button
												color="secondary"
												size="md"
												style={btnStyles}
												onClick={() => {
													const currTime = player.currentTime();
													const mins = Math.floor(currTime / 60);
													const secs = Math.floor(currTime % 60);
													changeHandler(`${poll}.triggerTime.min`, mins);
													changeHandler(`${poll}.triggerTime.sec`, secs);
												}}
											>
												Fill Current Time
											</Button>
										</Box>
									</Row>
								</Col>
								<Col sm="6">
									<Row>
										<Col sm="9">
											<Field
												name={`${poll}.duration`}
												type="number"
												min={3}
												component={renderTextField}
												label="Poll Duration(in secs)(Default Value: 30)"
											/>
										</Col>
										<Box d="flex" ai="center">
											<Button
												color="secondary"
												size="md"
												style={btnStyles}
												onClick={() => {
													const triggerTimeInSec = convertTime(pollState.triggerTime);
													const currentTime = player.currentTime();
													const duration = Math.floor(currentTime) - triggerTimeInSec;
													if (duration > 0) {
														changeHandler(`${poll}.duration`, duration);
													} else {
														showToast('error', '🚫️ Invalid Poll Duration');
													}
												}}
											>
												Fill Current Time
											</Button>
										</Box>
									</Row>
								</Col>
								{!isShortsPoll ? (
									<Col sm="6">
										<Row>
											<Col sm="9">
												<Field
													name={`${poll}.waitDuration`}
													type="number"
													min={3}
													component={renderTextField}
													label="Waiting for Results Duration(in secs)(Default Value: 10)"
												/>
											</Col>
											<Box d="flex" ai="center">
												<Button
													color="secondary"
													size="md"
													style={btnStyles}
													onClick={() => {
														const { triggerTime } = pollState;
														const pollDuration = pollState.duration;
														const triggerTimeInSec = convertTime(triggerTime);
														const currentTime = player.currentTime();
														const waitDuration = Math.floor(currentTime) - (triggerTimeInSec + pollDuration);
														if (waitDuration > 0) {
															changeHandler(`${poll}.waitDuration`, waitDuration);
														} else {
															showToast('error', '🚫️ Invalid Wait Duration');
														}
													}}
												>
													Fill Current Time
												</Button>
											</Box>
										</Row>
									</Col>
								) : null}
								<Col sm="6">
									<Row>
										<Col sm="9">
											<Field
												name={`${poll}.showResultDuration`}
												type="number"
												min={3}
												component={renderTextField}
												label="Show Results Duration(in secs)(Default Value: 10)"
											/>
										</Col>
										<Box d="flex" ai="center">
											<Button
												color="secondary"
												size="md"
												style={btnStyles}
												onClick={() => {
													const { triggerTime, waitDuration = 0 } = pollState;
													const pollDuration = pollState.duration;
													const triggerTimeInSec = convertTime(triggerTime);
													const currentTime = player.currentTime();
													const showResultDuration = Math.floor(currentTime) - (triggerTimeInSec + pollDuration + waitDuration);
													if (showResultDuration > 0) {
														changeHandler(`${poll}.showResultDuration`, showResultDuration);
													} else {
														showToast('error', '🚫️ Invalid Result Duration');
													}
												}}
											>
												Fill Current Time
											</Button>
										</Box>
									</Row>
								</Col>
							</Row>
						);
					})}
					{!isShortsPoll ? (
						<Box d="flex" jc="center">
							<Button
								style={{ textTransform: 'uppercase', fontWeight: '600' }}
								color="primary"
								type="button"
								onClick={() => fields.push({ options: [{}, {}] })}
							>
								<span className="lnr lnr-plus-circle" /> &nbsp;Add
							</Button>
						</Box>
					) : null}
				</Col>
			</Row>
		</Box>
	);
};
