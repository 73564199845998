import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LearningActivitiesList from './components/LearningActivitiesList';

const LearningActivities = () => (
	<Switch>
		<Route path="/learning-activities/list" component={LearningActivitiesList} />
		{/* <Route path="/learning-activities/edit" component={LearningActivitiesList} /> */}
	</Switch>
);

export default LearningActivities;
