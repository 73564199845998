import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams, pickWrapper } from 'utils/commonHelpers';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { SelectField } from 'reusableComponents/Form/Select';
import Filter from 'reusableComponents/Filter';
import { hlsStreamStatusValues } from 'utils/constants';
import history from '../../App/history';
import { setApiParams } from '../shortSlice';
import { getShorts, getShortVideoStatus, updateShortAndUpdateList } from '../actions/shorts.actions';

const statusOptions = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
];

const Actions = ({ id, status, isConvertComplete, videoUrl, dropboxPath, isRefreshing = {} }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/shorts/add-edit${stringifyQueryParams({ shortId: id })}`)}>Edit Short</DropdownItem>
				{!isConvertComplete && (
					<DropdownItem
						onClick={() => {
							dispatch(getShortVideoStatus({ dropboxPath, isShortsVideo: true }));
						}}
					>
						{isRefreshing[dropboxPath] ? 'Refreshing...' : 'Refresh'}
					</DropdownItem>
				)}
				<DropdownItem disabled={!isConvertComplete} onClick={() => history.push(`/shorts/polls?shortId=${id}&videoUrl=${videoUrl}`)}>
					Create/Manage Polls
				</DropdownItem>
				<DropdownItem
					disabled={!isConvertComplete}
					onClick={() =>
						dispatch(
							updateShortAndUpdateList({
								shortId: id,
								status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
							})
						)
					}
				>
					Mark {status === 'ACTIVE' ? 'Inactive' : 'Active'}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ shorts, isRefreshing = {} }) {
	return shorts.map(({ id, videoName, videoUrl, hlsStreamStatus, dropboxPath, status, createdAt, videoDesc }) => {
		const isConvertComplete = hlsStreamStatus === 4;
		return {
			id,
			backgroundColor: status === 'ACTIVE' ? 'white' : 'lightgrey',
			videoName,
			description: videoDesc,
			videoStatus: hlsStreamStatusValues[hlsStreamStatus],
			status,
			createdAt: format(new Date(createdAt), 'dd-MM-yyyy HH:mm'),
			actions: (
				<Actions
					{...{
						id,
						status,
						isConvertComplete,
						dropboxPath,
						isRefreshing,
						videoUrl,
					}}
				/>
			),
		};
	});
}

const heads = [
	{ accessor: 'videoName', Header: 'Caption' },
	{ accessor: 'description', Header: 'Description' },
	{ accessor: 'videoStatus', Header: 'Video Status' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const ShortsTable = () => {
	const shortsState = useSelector(({ shorts }) => ({
		loading: shorts.loading,
		shorts: shorts.shorts,
		short: shorts.short,
		pageNo: shorts.pageNo,
		total: shorts.total,
		apiParams: shorts.apiParams,
		isRefreshing: shorts.isRefreshing,
	}));

	const dispatch = useDispatch();

	const [shortRows, setShortRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState('');

	React.useEffect(() => {
		dispatch(getShorts(shortsState.apiParams));
	}, []);

	React.useEffect(() => {
		setPageOfItems(shortsState.pageNo);
	}, [shortsState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(shortsState.shorts)) {
			const structuredRows = createRows(pickWrapper(['shorts', 'isRefreshing'], shortsState));
			setShortRows(structuredRows);
		} else {
			setShortRows([]);
		}
	}, [shortsState.shorts, shortsState.isRefreshing]);

	React.useEffect(() => {
		dispatch(getShorts({ ...shortsState.apiParams }));
	}, [shortsState.apiParams]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = shortsState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					...apiParams,
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					...shortsState.apiParams,
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', shortsState),
					skip: accessDeepObject('apiParams.limit', shortsState) * (pageOfItems - 1),
				})
			);
		}
	};

	const descriptionAndCaptionSearchHandler = (event) => {
		event?.preventDefault?.();
		dispatch(setApiParams({ ...shortsState.apiParams, searchTerm }));
		dispatch(getShorts({ ...shortsState.apiParams, searchTerm }));
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={9} className="mb-3">
							<h3>Shorts Listing</h3>
						</Col>
						<Col sm={4}>
							<SelectField
								clearable
								placeholder="Status"
								name="status"
								options={statusOptions}
								onChange={(val) => dispatch(setApiParams({ ...shortsState.apiParams, status: val }))}
							/>
						</Col>

						<Col style={{ textAlign: 'left' }} sm={{ size: 3, offset: 1 }}>
							<Button
								style={{ marginBottom: 0 }}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/shorts/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					<Row style={{ marginBottom: '1rem' }}>
						<Col sm="6">
							<Filter
								placeholder="Search by description or Caption"
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								onSearch={descriptionAndCaptionSearchHandler}
							/>
						</Col>
					</Row>
					{isNotEmptyArray(shortRows) && (
						<>
							<DataPaginationTable heads={heads} rows={shortRows} onSort={onSort} />
							<Pagination
								itemsCount={shortsState.total}
								itemsToShow={accessDeepObject('apiParams.limit', shortsState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{shortsState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default ShortsTable;
