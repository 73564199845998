/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, isAllowedEmail } from 'utils/commonHelpers';

import { PostMedia } from 'components/PostMedia';
import { SelectField } from 'reusableComponents/Form/Select';
import Filter from 'reusableComponents/Filter';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { format } from 'date-fns';
import { setApiParams } from '../reportSlice';
import { updateReportAndUpdateList, getReports } from '../actions/report.actions';

const statusList = [
	{ label: 'Pending', value: 'PENDING' },
	{ label: 'Picked', value: 'PICKED' },
	{ label: 'Approved', value: 'REJECTED' },
	{ label: 'Rejected', value: 'APPROVED' },
];

const Actions = ({ reportId, index, status }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} disabled={status === 'PICKED'}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				{status === 'PENDING' ? (
					<>
						<DropdownItem
							onClick={() =>
								dispatch(
									updateReportAndUpdateList({
										reportId,
										reportIndex: index,
										status: 'REJECTED',
									})
								)
							}
						>
							Mark as Approved
						</DropdownItem>
						<DropdownItem
							onClick={() =>
								dispatch(
									updateReportAndUpdateList({
										reportId,
										reportIndex: index,
										status: 'APPROVED',
									})
								)
							}
						>
							Mark as Rejected
						</DropdownItem>
					</>
				) : (
					<DropdownItem
						onClick={() =>
							dispatch(
								updateReportAndUpdateList({
									reportId,
									reportIndex: index,
									status,
								})
							)
						}
					>
						{status === 'APPROVED' ? 'Mark as Rejected' : 'Mark as Approved'}
					</DropdownItem>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ reports }) {
	return reports.map(({ id, postData: { caption, postType, media }, createdAt, status, pickedByDetails }, index) => {
		/* eslint-disable no-nested-ternary */
		const reportStatus = status === 'APPROVED' ? 'REJECTED' : status === 'REJECTED' ? 'APPROVED' : status;

		return {
			id,
			media: <PostMedia {...{ media }} />,
			caption,
			postType,
			status: `${reportStatus} ${pickedByDetails ? `(${pickedByDetails.fullName})` : ''}`,
			createdAt: format(new Date(createdAt), 'dd-MM-yyyy HH:mm'),
			actions: <Actions {...{ reportId: id, status: reportStatus, index }} />,
		};
	});
}

export const ReportTable = () => {
	const reportState = useSelector(({ report }) => ({
		loading: report.loading,
		reports: report.reports,
		pageNo: report.pageNo,
		total: report.total,
		apiParams: report.apiParams,
	}));

	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	const dispatch = useDispatch();

	const [reportRows, setReportRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState(reportState.apiParams.query);
	const [profileSearchTerm, setProfileSearchTerm] = React.useState(reportState.apiParams.profileQuery);

	const heads = [
		{ accessor: 'media', Header: 'Post Media' },
		{ accessor: 'caption', Header: 'Post Caption' },
		{ accessor: 'postType', Header: 'Post Type' },
		{ accessor: 'status', Header: 'Status' },
		{ accessor: 'createdAt', Header: 'Created At' },
		...(process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' || isAllowedEmail('REACT_APP_REPORT_ADMINS', loggedInUser.email[0])
			? [{ accessor: 'actions', Header: 'Actions' }]
			: []),
	];

	React.useEffect(() => {
		dispatch(getReports(reportState.apiParams));
	}, []);

	React.useEffect(() => {
		setPageOfItems(reportState.pageNo);
	}, [reportState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(reportState.reports)) {
			const structuredRows = createRows({ reports: reportState.reports });
			setReportRows(structuredRows);
		} else {
			setReportRows([]);
		}
	}, [reportState.reports]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = reportState;
		if (itemsPage) {
			const newParams = {
				sortKey: apiParams.sortKey,
				sortOrder: apiParams.sortOrder,
				limit: apiParams.limit,
				skip: apiParams.limit * (itemsPage - 1),
				status: apiParams.status,
			};
			dispatch(setApiParams(newParams));
			dispatch(getReports(newParams));
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			const newParams = {
				sortKey: sortColumn,
				sortOrder,
				limit: accessDeepObject('apiParams.limit', reportState),
				skip: accessDeepObject('apiParams.limit', reportState) * (pageOfItems - 1),
				status: accessDeepObject('apiParams.status', reportState),
			};
			dispatch(setApiParams(newParams));
			dispatch(getReports(newParams));
		}
	};

	const onFilterChange = (filterName, val) => {
		const { apiParams } = reportState;
		const newParams = {
			sortKey: apiParams.sortKey,
			sortOrder: apiParams.sortOrder,
			limit: apiParams.limit,
			skip: 0,
			status: val,
			query: searchTerm?.trim(),
		};
		dispatch(setApiParams(newParams));
		dispatch(getReports(newParams));
	};

	const onSearch = (e) => {
		e?.preventDefault();
		const { apiParams } = reportState;
		const newParams = {
			profileQuery: profileSearchTerm?.trim(),
			query: searchTerm?.trim(),
			limit: 20,
			skip: 0,
			status: apiParams?.status,
		};
		dispatch(setApiParams(newParams));
		dispatch(getReports(newParams));
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col>
							<h3>Reports Listing</h3>
						</Col>
						<Col sm={12} style={{ marginTop: '1rem' }}>
							<Filter
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								onSearch={onSearch}
								placeholder="Search by post id, caption or profile name"
								helpText='Please enter the starting prefix of post caption. For ex: "John" will be matched by "Jo.."'
							/>
						</Col>
						<Col sm={6} style={{ marginTop: '1rem' }}>
							<Filter
								searchTerm={profileSearchTerm}
								setSearchTerm={setProfileSearchTerm}
								onSearch={onSearch}
								placeholder="Search by profile name"
								helpText="Please enter the starting prefix of name."
							/>
						</Col>
						<Col sm={6} style={{ marginTop: '1rem' }}>
							<SelectField
								clearable
								defaultValue={{}}
								placeholder="Status"
								name="status"
								options={statusList}
								onChange={(val) => onFilterChange('status', val)}
							/>
						</Col>
					</Row>
					{isNotEmptyArray(reportRows) && (
						<>
							<DataPaginationTable heads={heads} rows={reportRows} onSort={onSort} />
							<Pagination
								itemsCount={reportState.total}
								itemsToShow={accessDeepObject('apiParams.limit', reportState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{reportState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};
