import { createSlice } from '@reduxjs/toolkit';

const devSettingsSlice = createSlice({
	name: 'devSettings',
	initialState: {
		isSubmitting: false,
	},
	reducers: {
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const { toggleIsSubmitting } = devSettingsSlice.actions;

export default devSettingsSlice.reducer;
