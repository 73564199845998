import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { actionSpreader, isNotEmptyArray } from 'utils/commonHelpers';
import Filter from 'reusableComponents/Filter';
import theme from '../../../utils/theme';

import CustomModal from '../../../reusableComponents/CustomModal';
import Box from '../../../reusableComponents/Box';
import Loader from '../../../components/Loader';
import { clearDropboxFolders, getDropboxFolders, searchDropboxFile } from '../videoSlice';

const StyledModal = styled.div`
	max-height: 80vh;
	overflow-y: scroll;
	width: 30rem;
	padding: 1rem;
`;

const StyledFile = styled(Box)(
	(props) => `
	cursor: ${props.disabled ? 'default' : 'pointer'};
	background-color: ${props.disabled ? '#f2f2f2' : props.bg ?? '#ffffff'};
	color: ${props.disabled ? '#a2a2a2' : '#000000'};
	:hover {
		background-color: #f2f2f2;
	}
`
);

const btnStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

// List of accepted video file formats. Add more if needed.
const acceptedVideoFormats = ['mp4', 'm4a', 'ogg', 'wmv', 'webm', 'flv', 'avi'];

const DropboxModal = ({ handleSubmit, closeModal }) => {
	const [path, setPath] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	const dispatch = useDispatch();

	const state = useSelector(({ video }) => ({
		dbxData: video.dbxData,
		loading: video.loading,
		dbxFoldersLoading: video.dbxFoldersLoading,
	}));

	useEffect(() => {
		// @ts-ignore
		dispatch(getDropboxFolders({ path, shouldConcatenate: false }));
	}, [path]);

	useEffect(() => {
		return () => {
			dispatch(clearDropboxFolders());
		};
	}, []);

	const onSearch = () => {
		// implementing reset
		if (searchTerm === '') {
			dispatch(actionSpreader(getDropboxFolders.type, { path, shouldConcatenate: false }));
		}

		if (searchTerm.length <= 2) return;

		dispatch(
			actionSpreader(searchDropboxFile.type, {
				query: searchTerm,
				options: { max_results: 10, file_status: 'active', filename_only: true, file_categories: [{ '.tag': 'video' }] },
			})
		);
	};

	const onFileClick = (file) => {
		if (file.type === 'folder') setPath(file.path);
		else if (!(file.type === 'file' && !acceptedVideoFormats.includes(file.name.split('.').pop()))) setSelectedFile(file);
	};

	return (
		// @ts-ignore
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				{path && (
					<Box
						cursor="pointer"
						d="flex"
						ai="center"
						mb="10px"
						onClick={() =>
							setPath(
								path
									.split('/')
									.slice(0, path.split('/').length - 1)
									.join('/')
							)
						}
					>
						<span className="lnr lnr-arrow-left" />
						<p style={{ marginTop: 0, marginLeft: '5px' }}>Back</p>
					</Box>
				)}
				<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search file" onSearch={onSearch} />
				<Box>
					{/* eslint-disable-next-line */}
					{state.loading ? (
						<Loader />
					) : isNotEmptyArray(state.dbxData?.list) ? (
						state.dbxData.list.map((file) => (
							<StyledFile
								bg={selectedFile && selectedFile.name === file.name ? '#fff8e6' : 'transparent'}
								onClick={() => {
									if (!(!file.isAccessible || (file.type === 'file' && !acceptedVideoFormats.includes(file.name.split('.').pop()))))
										onFileClick(file);
								}}
								p="20px"
								bt="1px solid gray"
								d="flex"
								ai="center"
								disabled={!file.isAccessible || (file.type === 'file' && !acceptedVideoFormats.includes(file.name.split('.').pop()))}
							>
								{file.type === 'folder' ? (
									<svg
										style={{ width: '24px', height: '24px', color: 'lightskyblue' }}
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
										/>
									</svg>
								) : (
									<svg
										style={{ width: '24px', height: '24px', color: 'lightskyblue' }}
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
										/>
									</svg>
								)}
								<Box ml="10px">{file.name}</Box>
							</StyledFile>
						))
					) : (
						<Box as="p" tl="center" p="2rem">
							No files found
						</Box>
					)}

					{state.dbxFoldersLoading && <Loader />}

					{state.dbxData && state.dbxData.hasMore && (
						<Box
							tl="right"
							cursor="pointer"
							m="7px"
							onClick={() =>
								// @ts-ignore
								dispatch(getDropboxFolders({ path, cursor: state.dbxData.cursor, shouldConcatenate: true }))
							}
						>
							More
						</Box>
					)}
					<Box tl="right" w="100%" mt="10px">
						<Button color="secondary" size="md" style={btnStyles} onClick={closeModal}>
							Cancel
						</Button>
						<Button
							color="primary"
							size="md"
							style={btnStyles}
							onClick={() => handleSubmit(selectedFile.path)}
							type="button"
							disabled={!selectedFile}
						>
							Submit
						</Button>
					</Box>
				</Box>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(DropboxModal);
