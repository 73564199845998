import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import history from 'containers/App/history';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting } from '../coachSlice';

export const getCoaches = createAsyncThunk('coach/getCoaches', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const res = await fetchWrapper('/v1/admin/get-coaches', {
			method: 'POST',
			body: {
				...data,
				limit: data.limit + 1 || 10,
			},
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			return {
				coachList: res.data.coachProfiles.length > data.limit ? res.data.coachProfiles.slice(0, -1) : res.data.coachProfiles,
				total: res.data.coachProfiles.length,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const getCoachDetails = createAsyncThunk('coach/getCoach', async ({ onSuccess, ...data }: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const res = await fetchWrapper('/v1/get-coach', {
			method: 'POST',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			onSuccess(res.data);
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateCoachProfile = createAsyncThunk('coach/updateCoach', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const res = await fetchWrapper('/v1/update-coach-profile', {
			method: 'POST',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `Successfully updated`);
			history.push('/coach/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
