import React from 'react';
import { useLocation } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import Box from 'reusableComponents/Box';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import If from 'reusableComponents/If';
import { fetchVideos } from 'utils/fetchHelpers';
import { isNotEmptyObject } from 'utils/commonHelpers';
import { compose } from 'redux';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import DropzoneWithImageCrop from 'components/DropzoneWithImageCrop';
import { createMedia, getMedia, updateMedia } from './actions/media.action';

const quizMediaTypeOptions = [
	{ label: 'IMAGE', value: 'IMAGE' },
	{ label: 'VIDEO', value: 'VIDEO' },
];

const quizMediaStatus = [
	{ label: 'ACTIVE', value: 'ACTIVE' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
];

interface QuizMediaFormValues {
	type: { label: string; value: string };
	mediaTitle: string;
	status: any;
	quizImage?: File;
	quizVideo?: { label: string; value: string; id: string };
	quizAudio?: File;
}

const AddEditQuizMedia = () => {
	const hookFormMethods = useForm<QuizMediaFormValues>();
	const { watch, handleSubmit, reset, control } = hookFormMethods;
	const formValues = watch(['type']);

	const location = useLocation();
	const dispatch = useDispatch();

	const queryParam = new URLSearchParams(location.search);
	const mediaId = queryParam.get('mediaId');

	const [quizMedia, setQuizMedia] = React.useState<any>({});

	const onSubmit = (data: any) => {
		const type = data.type.value;
		const status = data.status.value;

		if (mediaId) {
			return dispatch(
				updateMedia({
					...data,
					url: quizMedia.url,
					id: mediaId,
					type,
					status,
				})
			);
		}

		dispatch(createMedia({ ...data, type, status }));
	};

	const quizMediaState = useSelector(({ quizMedia }) => ({
		quizMedia: quizMedia.quizMedia,
	}));

	React.useEffect(() => {
		if (mediaId) {
			dispatch(getMedia({ mediaId }));
		}
	}, []);

	React.useEffect(() => {
		const quizMedia = quizMediaState.quizMedia.find((media: any) => media?.id === mediaId);

		if (!isNotEmptyObject(quizMedia)) return;

		setQuizMedia(quizMedia);

		const { type, mediaTitle, status, url, id, title, videoId } = quizMedia;

		const isMediaAnImage = type === 'IMAGE';
		const isMediaVideo = type === 'VIDEO';

		reset({
			mediaTitle,
			status: { label: status, value: status },
			type: { label: type, value: type },
			...(isMediaAnImage ? { quizImage: getPreviewImages({ id, mediaType: 'QUIZ_IMAGE', url }, false) } : {}),
			...(isMediaVideo ? { quizVideo: { label: title, value: url, id: videoId } } : {}),
		});
	}, [quizMediaState.quizMedia.length]);

	return (
		<Card>
			<CardBody>
				<FormProvider {...hookFormMethods}>
					<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
						<Box w="100%">
							<Row>
								<Col md={6}>
									<h2 className="mb-4">Activity Media Edit</h2>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Input label="Title" name="mediaTitle" required />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<SelectField name="type" label="Type" options={quizMediaTypeOptions} required />
								</Col>
								<Col sm="6">
									<SelectField name="status" label="Status" options={quizMediaStatus} required />
								</Col>
							</Row>
							<If
								condition={formValues?.type?.value === 'IMAGE'}
								then={
									<Row>
										<Col sm="12">
											<DropzoneWithImageCrop name="quizImage" label="Image (2:1 Aspect Ratio)" aspectRatio={2} control={control} required />
										</Col>
									</Row>
								}
							/>
							<If
								condition={formValues?.type?.value === 'VIDEO'}
								then={
									<AsyncSelectField
										label="Video"
										name="quizVideo"
										fetchOptions={(params) => fetchVideos({ ...params, videoType: 'QUIZ' })}
										isClearable
										required
									/>
								}
							/>
							<If
								condition={formValues?.type?.value === 'AUDIO'}
								then={
									<Col sm="12" className="my-4">
										<DropzoneNew label="Quiz Audio" name="quizAudio" isMulti={false} required noOfFiles={1} accept="audio/*" />
									</Col>
								}
							/>
							<Button color="btn btn-primary">Submit</Button>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};
const withReduxForm = reduxForm({ form: 'add_edit_quiz_media', enableReinitialize: true });

const AddEditQuizMediaReduxForm = compose(withReduxForm)(AddEditQuizMedia);

export default AddEditQuizMediaReduxForm;
