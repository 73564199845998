import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getBanners, updateBannerAndUpdateList } from './actions/dashboard.actions';

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: {
		loading: false,
		banners: [],
		banner: {},
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'priority',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getBanners.fulfilled as any]: (state, action) => {
			const {
				payload: { banners, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.banners = banners;
			state.total = total;
			state.pageNo = page + 1;
		},
		[updateBannerAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { bannerId, bannerData },
			} = action;
			const { banners } = state;
			banners[state.banners.findIndex((banner) => banner.id === bannerId)] = bannerData;
			state.banners = [...banners];
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting } = dashboardSlice.actions;

export default dashboardSlice.reducer;
