import { takeLatest } from 'redux-saga/effects';

import { getFaq, getFaqs, getFaqTypes, createFaqType, getFaqId, faqOnSubmit } from './faqSlice';
import { getFaqSaga } from './sagas/getFaqSaga';
import { getFaqsSaga } from './sagas/getFaqsSaga';
import { getFaqTypesSaga } from './sagas/getFaqTypesSaga';
import { createFaqTypeSaga } from './sagas/createFaqTypeSaga';
import { getFaqIdSaga } from './sagas/getFaqIdSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';

export default function* faqSaga() {
	yield takeLatest(getFaq.type, getFaqSaga);
	yield takeLatest(getFaqs.type, getFaqsSaga);
	yield takeLatest(getFaqTypes.type, getFaqTypesSaga);
	yield takeLatest(createFaqType.type, createFaqTypeSaga);
	yield takeLatest(getFaqId.type, getFaqIdSaga);
	yield takeLatest(faqOnSubmit.type, onSubmitSaga);
}
