import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader } from '../../../utils/commonHelpers';
import { getClassImagesSuccess, toggleLoading } from '../gallerySlice';
import { setError } from '../../App/globalSlice';

export function* getClassImagesSaga() {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/gallery/class`);
	if (res.status === 200) {
		yield put(actionSpreader(getClassImagesSuccess.type, res.data));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
	yield put(actionSpreader(toggleLoading.type, { loading: false }));
}
