import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

export default class CustPag extends React.Component {
	static propTypes = {
		onChangePage: PropTypes.func.isRequired,
		itemsCount: PropTypes.number.isRequired,
		itemsToShow: PropTypes.number.isRequired,
		pageOfItems: PropTypes.number.isRequired,
	};

	setPage = (page) => {
		const { itemsCount, itemsToShow, onChangePage } = this.props;

		// calculate total pages
		const totalPages = Math.ceil(itemsCount / itemsToShow);

		if (page < 1 || page > totalPages) {
			return;
		}

		// call change page function in parent component
		onChangePage(page);
	};

	getPager = (totalItems, curPage, pSize) => {
		// default to first page
		const currentPage = curPage || 1;

		// default page size is 20
		const pageSize = pSize || 20;

		// calculate total pages
		const totalPages = Math.ceil(totalItems / pageSize);

		let startPage;
		let endPage;
		// less than pageSize
		if (totalPages <= pageSize) {
			startPage = 1;
			endPage = totalPages;
		} else if (currentPage <= Math.floor(pageSize / 2 + 1)) {
			// more than pageSize so calculate start and end pages
			startPage = 1;
			endPage = pageSize;
		} else if (currentPage + Math.floor(pageSize / 2 - 1) >= totalPages) {
			startPage = totalPages - (pageSize - 1);
			endPage = totalPages;
		} else {
			startPage = currentPage - Math.floor(pageSize / 2);
			endPage = currentPage + Math.floor(pageSize / 2 - 1);
		}

		// calculate start and end item indexes
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

		// create an array of pages to ng-repeat in the pager control
		const pages = range(startPage, endPage + 1);

		// return object with all pager properties required by the view
		return {
			totalItems,
			currentPage,
			pageSize,
			totalPages,
			startPage,
			endPage,
			startIndex,
			endIndex,
			pages,
		};
	};

	render() {
		const { itemsCount, itemsToShow, pageOfItems } = this.props;

		const pager = this.getPager(itemsCount, pageOfItems, itemsToShow);
		const { pages } = pager;

		const temp1 = pageOfItems - 1;
		const pageShowing = itemsToShow * temp1;

		return itemsCount ? (
			<div className="pagination__wrap">
				{itemsCount <= 1 ? (
					''
				) : (
					<Pagination className="pagination" dir="ltr">
						<PaginationItem className="pagination__item" disabled={pageOfItems === 1}>
							<PaginationLink className="pagination__link pagination__link--arrow" type="button" onClick={() => this.setPage(1)}>
								<ChevronLeftIcon className="pagination__link-icon" />
							</PaginationLink>
						</PaginationItem>
						{pages[0] > 1 && (
							<PaginationItem className="pagination__item">
								<PaginationLink className="pagination__link" type="button" onClick={() => this.setPage(pager.currentPage - 1)}>
									Prev
								</PaginationLink>
							</PaginationItem>
						)}
						{pages.map((page) => (
							<PaginationItem className="pagination__item" key={page} active={pageOfItems === page}>
								<PaginationLink className="pagination__link" type="button" onClick={() => this.setPage(page)}>
									{page}
								</PaginationLink>
							</PaginationItem>
						))}
						{pages[pages.length - 1] < pager.totalPages && (
							<PaginationItem className="pagination__item">
								<PaginationLink className="pagination__link" type="button" onClick={() => this.setPage(pager.currentPage + 1)}>
									Next
								</PaginationLink>
							</PaginationItem>
						)}
						<PaginationItem className="pagination__item" disabled={pageOfItems === pager.totalPages}>
							<PaginationLink
								className="pagination__link pagination__link--arrow"
								type="button"
								onClick={() => this.setPage(pager.totalPages)}
							>
								<ChevronRightIcon className="pagination__link-icon" />
							</PaginationLink>
						</PaginationItem>
					</Pagination>
				)}
				<div className="pagination-info">
					&nbsp;&nbsp;
					<span>
						Showing {pageShowing + 1}&nbsp;to {itemsToShow * pageOfItems > itemsCount ? itemsCount : itemsToShow * pageOfItems} of{' '}
						{itemsCount}
					</span>
				</div>
			</div>
		) : (
			<div />
		);
	}
}

/* eslint-enable */
