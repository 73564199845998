import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast, pickWrapper, omitWrapper, ObjectMaybe } from '../../../utils/commonHelpers';
import { setIsSubmitting, resetIsSubmitting } from '../blogSlice';
import { uploadImageSaga } from '../../../shared/saga';
import { mutateBlogSaga } from './mutateBlogSaga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(setIsSubmitting.type));
	let modifiedData = { ...omitWrapper(['isEdit'], payload) };
	if (payload.coverPicture && !ObjectMaybe(payload.coverPicture).id) {
		const coverPic = yield call(uploadImageSaga, {
			payload: { images: [payload.coverPicture], entityId: payload.id, entityType: 'BLOG' },
		});
		if (coverPic instanceof Error) {
			showToast('error', "OOPS!! CoverPicture can't be uploaded");
		} else {
			modifiedData = { ...modifiedData, coverPicture: pickWrapper(['id', 'url'], coverPic[0]) };
		}
	}
	const blogRes = yield call(mutateBlogSaga, { payload: modifiedData, isEdit: payload.isEdit });
	if (blogRes.status === 200) {
		showToast('success', `👍️ Blog ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(actionSpreader(resetIsSubmitting.type));
		history.push('/blogs/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: blogRes.data }));
		yield put(actionSpreader(resetIsSubmitting.type));
	}
}
