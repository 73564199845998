import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProgramAddEditForm } from './components/AddEdit/ProgramAddEditForm';

import { ProgramTable } from './components/ProgramTable/ProgramTable';

const Program = () => {
	return (
		<div>
			<Switch>
				<Route path="/program/list" component={ProgramTable} />
				<Route path="/program/add-edit" component={ProgramAddEditForm} />
			</Switch>
		</div>
	);
};

export default Program;
