import React from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';

import CKEditor from '../../containers/Blog/components/CKEditor';
import renderRadioButtonField from './RadioButton';
import { Checkbox } from './Checkbox';
import renderDatePickerField from './DatePicker';
import renderCheckBoxField from './CheckBoxOld';

export const renderTextField = ({
	input,
	isDisabled,
	placeholder,
	defaultValue,
	label,
	customStyle,
	autoComplete,
	type,
	min,
	max,
	isRequired,
	maxLength,
	meta: { touched, error, warning },
}) => (
	<div className="form__form-group">
		<span className="form__form-group-label">
			{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
		</span>
		<div className="form__form-group-input-wrap">
			<input
				{...input}
				disabled={isDisabled && input.value !== ''}
				placeholder={placeholder}
				type={type}
				{...(type === 'number' ? { min, max } : {})}
				error={touched && error}
				style={customStyle || {}}
				autoComplete={autoComplete}
				{...(defaultValue ? { value: input.value || defaultValue } : {})}
				{...(maxLength && { maxLength })}
			/>
			{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
		</div>
	</div>
);

export const renderRadioButtonGroup = ({ label, isRequired, labelName, fields, meta: { touched, error, warning } }) => {
	return (
		<div className="form__form-group">
			<span className="form__form-group-label mb-2">
				{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
			</span>
			<div className="form__form-group-field">
				<Row>
					{fields.map((field, i) => (
						<Col md="12" lg="12" key={i.toString()}>
							<Field
								key={`${i.toString()}-field`}
								name={labelName}
								component={renderRadioButtonField}
								label={field.label}
								radioValue={field.value}
							/>
						</Col>
					))}
				</Row>
			</div>
			{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export const renderCheckBoxButtonGroup = ({ label, isRequired, checkBoxFields, meta: { touched, error, warning }, input }) => {
	return (
		<div className="form__form-group">
			<span className="form__form-group-label mb-2">
				{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
			</span>
			<div className="form__form-group-field">
				<Row>
					{checkBoxFields.map((field, i) => (
						<Col sm="12" key={i.toString()}>
							<Field
								name={`${input.name}[${field.value}]`}
								defaultChecked={input.value[field.value]}
								component={Checkbox}
								label={field.label}
							/>
						</Col>
					))}
				</Row>
			</div>
			{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export const renderCheckBoxButton = ({ label, meta: { touched, error, warning }, input }) => {
	return (
		<div className="form__form-group">
			<div className="form__form-group-field">
				<Field name={input.name} defaultChecked={input.value} component={renderCheckBoxField} label={label} />
			</div>
			{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export const renderTextAreaField = ({ label, isRequired, input, placeholder, validate, meta: { touched, error, warning } }) => (
	<div className="form__form-group">
		<span className="form__form-group-label">
			{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
		</span>
		<div className="form__form-group-field">
			<Field name={input.name} placeholder={placeholder} component="textarea" validate={validate} />
		</div>
		{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
	</div>
);

export const renderDatePicker = ({ label, input, isRequired, timeParams, meta: { touched, error, warning } }) => {
	return (
		<div className="form__form-group">
			<span className="form__form-group-label">
				{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
			</span>
			<div className="form__form-group-field">
				<Field name={input.name} timeParams={timeParams} value={input.value} component={renderDatePickerField} />
			</div>
			{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export const renderCKEditor = ({ input, defaultValue, label, isRequired, entityId, entityType, meta: { touched, error, warning } }) => {
	return (
		<div className="form__form-group">
			<span className="form__form-group-label">
				{label} {isRequired && <span className="form__form_group-label--required">&#42;</span>}
			</span>
			<div className="form__form-group-field ck-content">
				<CKEditor
					entityId={entityId}
					entityType={entityType}
					data={input.value || defaultValue}
					onChange={(_, editor) => {
						if (editor.getData()) {
							input.onChange(editor.getData());
						} else {
							input.onChange('<p></p>');
						}
					}}
				/>
			</div>
			{touched && ((error && <span className="form__form-group-error">{error} </span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};
