import React from 'react';
import { Button, Card, CardBody, Col, Col as ReactStrapCol, Row } from 'reactstrap';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Box from 'reusableComponents/Box';
import { getCertificate } from '../actions/certificate.actions';

const ViewCertificate = () => {
	const { certificate, loading } = useSelector(({ certificate }) => ({
		loading: certificate.loading,
		certificate: certificate.certificate,
	}));

	const dispatch = useDispatch();
	// @ts-expect-error
	const { id } = useParams();

	React.useEffect(() => {
		dispatch(getCertificate({ id }));
	}, []);

	return (
		<LoadingOverlay isLoading={loading} msg="View certificate">
			<Card>
				<CardBody>
					<Box w="100%">
						<Row>
							<Col md="8">
								<img style={{ width: '100%' }} src={certificate?.imageUrl} alt="" />
							</Col>
							<Col md="4">
								<a target="_blank" rel="noopener noreferrer" href={certificate?.pdfUrl}>
									<Button>DOWNLOAD PDF</Button>
								</a>
							</Col>
						</Row>
					</Box>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default ViewCertificate;
