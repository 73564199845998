import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FaqTable from './components/FaqTable';
import FaqAddEditForm from './components/AddEditForm';

const Blogs = () => {
	return (
		<div>
			<Switch>
				<Route path="/faq/add-edit" component={FaqAddEditForm} />
				<Route path="/faq/list" component={FaqTable} />
			</Switch>
		</div>
	);
};

export default Blogs;
