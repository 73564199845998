import React, { useState, useRef, useEffect } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { isNotEmptyArray } from '../../utils/commonHelpers';

import ChatBubble from './ChatBubble';
import ChatContact from './ChatContact';
import ChatField from './ChatField';
import ChatTopbar from './ChatTopbar';

const getUserIdFromConversation = ({ conversationId, conversations }) => {
	const conversation = conversations.find((c) => c.mobile === conversationId) || {};
	return conversation?.userId;
};

const WhatsappChat = ({
	conversations,
	showLoadMoreConversations,
	loadMoreConversation,
	handleConversationItemClick,
	conversationId,
	messages,
	initialMessageLoad,
	showLoadMoreMoreMessages,
	onRefresh,
	loadMoreMessages,
	waitingForSentConfirmation,
	shouldShowAssignButtons,
}) => {
	const [openContacts, setOpenContacts] = useState(false);
	const scrollBarRef = useRef();

	const scrollToBottom = () => {
		if (scrollBarRef.current) {
			scrollBarRef.current.scrollbar.scrollTop = scrollBarRef.current.scrollbar.size.content.height;
		}
	};

	useEffect(() => {
		if (scrollBarRef.current) {
			if (initialMessageLoad) scrollToBottom();
		}
	}, [messages]);

	return (
		<div
			className={`chat${openContacts ? ' chat--open' : ''}`}
			onClick={openContacts ? () => setOpenContacts(!openContacts) : null}
			role="presentation"
		>
			<div className={`chat__contact-list${openContacts ? ' chat__contact-list--open' : ''}`} style={{ borderRight: '1px solid grey' }}>
				<div className="chat__contacts">
					<Scrollbar className="scroll chat__contacts-scroll" alwaysShowTracks>
						{!isNotEmptyArray(conversations) && (
							<div className="chat__dialog-select-message">
								<p>No Conversation</p>
							</div>
						)}
						{conversations.map((c) => (
							<button key={c.mobile} type="button" onClick={(e) => handleConversationItemClick(c, e)}>
								<ChatContact lastMessageText={c.text} lastMessageTime={c.lastMessageTime} active={c.id === 1} contact={c} />
							</button>
						))}
						{loadMoreConversation &&
							(showLoadMoreConversations ? (
								<Button size="sm" color="primary" onClick={loadMoreConversation}>
									Fetch more conversations
								</Button>
							) : (
								<div className="chat__contact-message">no more conversations</div>
							))}
					</Scrollbar>
				</div>
			</div>
			{!conversationId ? (
				<div className="chat__dialog">
					<ChatTopbar
						onClick={() => {
							setOpenContacts(!openContacts);
						}}
					/>
					<div className="chat__dialog-select-message">
						<p>Select a chat to start messaging</p>
					</div>
				</div>
			) : (
				<div className="chat__dialog">
					<ChatTopbar
						mobile={conversationId}
						onClick={() => {
							setOpenContacts(!openContacts);
						}}
						onRefresh={onRefresh}
					/>
					<Scrollbar ref={scrollBarRef} className="scroll chat__scroll" alwaysShowTracks>
						<div className="chat__dialog-messages-wrap">
							{!isNotEmptyArray(messages) ? (
								<div className="chat__dialog-messages">
									<div className="chat__dialog-messages-empty">
										<p>No messages</p>
									</div>
								</div>
							) : (
								<div className="chat__dialog-messages">
									<>
										{showLoadMoreMoreMessages ? (
											<Button size="sm" className="chat__bubble-button" type="button" onClick={loadMoreMessages}>
												load more messages
											</Button>
										) : (
											<div className="chat__dialog-messages-empty">
												<p>No more messages</p>
											</div>
										)}
										{messages.map((m) => (
											<ChatBubble
												key={m.id}
												sentBy={m.sentBy}
												fullName={m.sentBy === 'ADMIN' ? 'ADMIN' : conversationId}
												messageType={m.type}
												message={m.text}
												media={m.media}
												date={m.createdAt}
												active={m.userId}
												userWAMsgStatusHistory={m.userWAMsgStatusHistory}
												isCTA={m.isCTA}
												ctaUrl={m.ctaUrl}
											/>
										))}
									</>
								</div>
							)}
						</div>
					</Scrollbar>
					<ChatField
						waitingForSentConfirmation={waitingForSentConfirmation}
						userId={getUserIdFromConversation({ conversations, conversationId })}
						mobile={conversationId}
						shouldShowAssignButtons={shouldShowAssignButtons}
					/>
				</div>
			)}
		</div>
	);
};

export default connect()(WhatsappChat);
