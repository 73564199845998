import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { getWhatsappGroupIdSuccess } from '../whatsappSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getWhatsappGroupIdSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/whatsapp/id`);
	if (res.status === 200) {
		yield put(actionSpreader(getWhatsappGroupIdSuccess.type, { groupId: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
