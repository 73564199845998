import React from 'react';
import theme from 'utils/theme';
import { CountdownContainer, SVG, AnimatedSVG } from './styledComponents';

class Timer extends React.Component {
	constructor(props) {
		super(props);

		const { seconds, size } = this.props;

		this.milliseconds = seconds * 1000;
		this.radius = size / 2;
		this.circumference = size * Math.PI;

		this.state = {
			countdown: this.milliseconds,
			isPlaying: false,
		};
	}

	strokeDashoffset = () => {
		// eslint-disable-next-line no-mixed-operators
		return this.circumference - (this.state.countdown / this.milliseconds) * this.circumference;
	};

	componentDidMount = () => {
		this.startTimer();
	};

	startTimer = () => {
		const { stopTimer, onTimeout, isPollTimer, closeModal } = this.props;
		this.setState({ isPlaying: true });

		const interval = setInterval(() => {
			this.setState((state) => ({ countdown: state.countdown - 1000 }));

			// eslint-disable-next-line react/destructuring-assignment
			if (this.state.countdown === 0 || stopTimer) {
				clearInterval(interval);
				if (isPollTimer) onTimeout(true);
				else closeModal();
			}
		}, 1000);
	};

	render() {
		const { size, strokeBgColor, strokeWidth, stopTimer, seconds } = this.props;
		const { countdown, isPlaying } = this.state;

		const countdownSizeStyles = {
			height: size,
			width: size,
		};

		const textStyles = {
			color: theme.clrs.new.cPrimaryText,
			fontSize: '2.4rem',
			opacity: 0.8,
		};

		const countdownInSec = (countdown / 1000).toFixed();

		const elapsedPercentage = (countdownInSec / seconds) * 100;

		return (
			<div>
				<CountdownContainer style={countdownSizeStyles}>
					<p style={textStyles}>{countdownInSec < 10 ? `0${countdownInSec}` : countdownInSec.toString()}</p>
					<SVG>
						<circle cx={this.radius} cy={this.radius} r={this.radius} fill="none" stroke={strokeBgColor} strokeWidth={strokeWidth} />
					</SVG>
					<AnimatedSVG stopAnim={stopTimer} elapsedPercentage={elapsedPercentage}>
						<circle
							strokeDasharray={this.circumference}
							strokeDashoffset={isPlaying ? this.strokeDashoffset() : 0}
							r={this.radius}
							cx={this.radius}
							cy={this.radius}
							fill="none"
							strokeLinecap="round"
							strokeWidth={strokeWidth}
						/>
					</AnimatedSVG>
				</CountdownContainer>
			</div>
		);
	}
}

export default Timer;
