import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BlogTable from './components/BlogTable';
import BlogAddEditForm from './components/AddEditForm';

const Blogs = () => {
	return (
		<div>
			<Switch>
				<Route path="/blogs/add-edit" component={BlogAddEditForm} />
				<Route path="/blogs/list" component={BlogTable} />
			</Switch>
		</div>
	);
};

export default Blogs;
