import { takeLatest } from 'redux-saga/effects';
import { getUsers, getUser, getUserProfiles } from './userSlice';
import { getUsersSaga } from './sagas/getUsersSaga';
import { getUserSaga } from './sagas/getUserSaga';
import { getUserProfilesSaga } from './sagas/getUserProfilesSaga';

export default function* usersSaga() {
	yield takeLatest(getUsers.type, getUsersSaga);
	// @ts-ignore
	yield takeLatest(getUserProfiles.type, getUserProfilesSaga);
	// @ts-ignore
	yield takeLatest(getUser.type, getUserSaga);
}
