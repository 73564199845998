import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Cancellationform from './CancellationForm';
import MembershipList from './MembershipList';

const Membership = () => {
	return (
		<Switch>
			<Route path="/membership/cancellation-form" component={Cancellationform} />
			<Route path="/membership/list" component={MembershipList} />
		</Switch>
	);
};

export default Membership;
