/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import Papa from 'papaparse';
import {
	isNotEmptyArray,
	accessDeepObject,
	ArrayMaybe,
	isNotEmptyObject,
	copyToClipBoard,
	showToast,
	StringMaybe,
} from '../../../../utils/commonHelpers';
import history from '../../../App/history';

import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import { getWhatsappGroups, setApiParams, setWhatsappGroupStatus, whatsappCsvOnSubmit } from '../../whatsappSlice';
import { dateTimeConverter } from '../../../../utils/dateHelper';
import Box from '../../../../reusableComponents/Box';
import renderDatePickerField from '../../../../reusableComponents/Form/DatePicker';
import renderSelectField from '../../../../reusableComponents/Form/Select';
import { renderTextField } from '../../../../reusableComponents/Form/formComponents';
import CsvModal from '../CsvModal';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (groups, pageNo, rowsCount) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < groups.length; i += 1) {
		r.push({
			id: initialCount,
			name: groups[i].name,
			count: groups[i].count,
			maxCount: groups[i].maxCount,
			type: groups[i].type,
			status: <div className={`badge badge-${groups[i].status.color}`}>{groups[i].status.label}</div>,
			country: StringMaybe(groups[i].country).toUpperCase(),
			updatedAt: dateTimeConverter(groups[i].updatedAt, 'format', 'dd/MMM/yy, hh:mm'),
			order: groups[i].order,
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/whatsapp/add-edit?id=${groups[i].id}`);
						}}
					>
						Edit
					</Button>
					{/* <Button
						color='primary'
						size='md'
						style={customStyles}
						onClick={() => {
							submitHandler({ id: groups[i].id, isActive: !groups[i].isActive, pos: i });
						}}
					>
						Mark {groups[i].isActive ? 'Inactive' : 'Active'}
					</Button> */}
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							if (isNotEmptyObject(groups[i])) {
								copyToClipBoard(groups[i].link);
								showToast('success', '🎉 Whatsapp Group Link copied!');
							}
						}}
					>
						Copy Group Link
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const WhatsappFilter = (props) => {
	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%" mb="1.5rem">
				<Row style={{ alignItems: 'flex-end' }}>
					<Col md="3" sm="12">
						<Field label="Group Name/Link/Type" type="text" component={renderTextField} name="searchQuery" />
					</Col>
					<Col md="3" sm="12">
						<span className="form__form-group-label">Last Updated</span>
						<Field
							label="Last Updated"
							type="text"
							onChange={(val) => {
								const utcDate = new Date(val.setHours(5, 30, 0, 0)).toUTCString(); // JUGAAD
								console.log('v', val, utcDate);
								props.setFilters({ ...props.initialValues, updatedAt: new Date(utcDate) });
							}}
							timeParams={{ showTime: false }}
							disableFutureDate
							component={renderDatePickerField}
							name="updatedAt"
						/>
					</Col>
					<Col md="3" sm="12">
						<Field
							label="Status"
							name="status"
							value={props.initialValues.status}
							onChange={(val) => props.setFilters({ ...props.initialValues, status: val })}
							options={ArrayMaybe(props.statusOptions).map((status) => ({
								label: status.label,
								value: status.value,
							}))}
							component={renderSelectField}
						/>
					</Col>
					<Col md="3" sm="12">
						<Button style={{ marginBottom: 0 }} color="primary" size="sm" type="submit">
							Filter
						</Button>
						<Button
							style={{ marginBottom: 0 }}
							color="primary"
							size="sm"
							onClick={() => props.setFilters({ updatedAt: '', status: '', searchQuery: null })}
						>
							Clear
						</Button>
					</Col>
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'whatsapp_filters', enableReinitialize: true });
const WhatsappFilterForm = compose(withReduxForm)(WhatsappFilter);

const WhatsappTable = ({ history }) => {
	const whatsappState = useSelector(({ whatsapp }) => ({
		loading: whatsapp.loading,
		isSubmitting: whatsapp.isSubmitting,
		groups: whatsapp.groups,
		pageNo: whatsapp.pageNo,
		total: whatsapp.total,
		apiParams: whatsapp.apiParams,
	}));

	const dispatch = useDispatch();

	const [groupRows, setGroupRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [filters, setFilters] = React.useState({ updatedAt: '', status: '', searchQuery: null });
	const [showModal, setShowModal] = React.useState(false);

	// React.useEffect(() => {
	// 	console.log('init call');
	// 	dispatch(getWhatsappGroups());
	// }, []);

	React.useEffect(() => {
		setPageOfItems(whatsappState.pageNo);
	}, [whatsappState.pageNo]);

	React.useEffect(() => {
		if (!filters.updatedAt && !filters.status && !filters.searchQuery) dispatch(getWhatsappGroups());
	}, [filters]);

	const statusOptions = [
		{
			label: 'Filling',
			value: 'Filling',
		},
		{
			label: 'Filled Today',
			value: 'Filled Today',
		},
		{
			label: 'Future',
			value: 'Future',
		},
		{
			label: 'Inactive',
			value: 'Inactive',
		},
	];

	const heads = [
		{
			accessor: 'name',
			Header: 'Name',
		},
		{
			accessor: 'count',
			Header: 'Count',
		},
		{
			accessor: 'maxCount',
			Header: 'Max Count',
		},
		{
			accessor: 'updatedAt',
			Header: 'Last Updated',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'type',
			Header: 'Group Type',
		},
		{
			accessor: 'country',
			Header: 'Country',
		},
		{
			accessor: 'order',
			Header: 'Order',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			dispatch(setWhatsappGroupStatus(params));
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(whatsappState.groups)) {
			const structuredRows = createRows(
				whatsappState.groups,
				whatsappState.pageNo,
				accessDeepObject('apiParams.limit', whatsappState),
				submitHandler
			);
			setGroupRows(structuredRows);
		} else {
			setGroupRows([]);
		}
	}, [whatsappState.groups]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = whatsappState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getWhatsappGroups());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', whatsappState),
					skip: accessDeepObject('apiParams.limit', whatsappState) * (pageOfItems - 1),
				})
			);
			dispatch(getWhatsappGroups());
		}
	};

	const handleFilterSubmit = (params) => {
		dispatch(
			getWhatsappGroups({
				...(params.updatedAt && { updatedAt: new Date(params.updatedAt).toISOString() }),
				...(params.status && { status: params.status }),
				...(params.searchQuery && { searchQuery: params.searchQuery }),
			})
		);
	};

	const submitCsvFiles = (files) => {
		if (files.length) {
			const csvData = [];

			Promise.all(
				files.map(
					(file) =>
						new Promise((resolve, reject) =>
							Papa.parse(file, {
								header: true,
								dynamicTyping: true,
								skipEmptyLines: true,
								complete: async (result) => {
									const fileName = file.name.split(' ');
									resolve({
										group: fileName[fileName.length - 1].replace('.csv', ''),
										phoneArr: result.data.map((item) => item.Phone),
									});
								},
								error: reject,
							})
						)
				)
			)
				.then((results) => {
					results.forEach((result) => {
						csvData.push(result);
					});

					setShowModal(false);
					dispatch(whatsappCsvOnSubmit(csvData));
				})
				.catch((err) => {
					showToast('error', err);
				});
		}
	};

	return (
		<LoadingOverlay isLoading={whatsappState.isSubmitting} msg="Submitting CSV Files...">
			{showModal && (
				<CsvModal
					handleSubmit={submitCsvFiles}
					closeModal={() => {
						setShowModal(false);
					}}
				/>
			)}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={6}>
								<h3>Whatsapp Groups Listing</h3>
							</Col>
							<Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
								<Button
									style={customStylesMargin}
									size="sm"
									color="primary"
									onClick={() => {
										history.push('/whatsapp/add-edit');
									}}
								>
									Add
								</Button>
								<Button
									style={customStylesMargin}
									size="sm"
									color="primary"
									onClick={() => {
										setShowModal(true);
									}}
								>
									Upload CSV
								</Button>
								<Button style={customStylesMargin} size="sm" color="primary" onClick={() => history.push(`/whatsapp/dropbox-upload`)}>
									Upload from Dropbox
								</Button>
								<Button style={customStylesMargin} size="sm" color="primary" onClick={() => history.push('/whatsapp/analytics')}>
									See Analytics
								</Button>
							</Col>
						</Row>

						<WhatsappFilterForm
							initialValues={filters}
							setFilters={setFilters}
							statusOptions={statusOptions}
							onSubmit={handleFilterSubmit}
						/>

						{isNotEmptyArray(groupRows) && (
							<>
								<DataPaginationTable heads={heads} rows={groupRows} onSort={onSort} />
								<Pagination
									itemsCount={whatsappState.total}
									itemsToShow={accessDeepObject('apiParams.limit', whatsappState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{whatsappState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</LoadingOverlay>
	);
};

export default WhatsappTable;
