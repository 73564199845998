import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Button, Card, CardBody } from 'reactstrap';

import {
	parseQueryParams,
	pickWrapper,
	modifyBeforeSetTags,
	isNotEmptyArray,
	isNotEmptyObject,
	showToast,
	ObjectMaybe,
	getSanitizedImageUrl,
	stringToSlug,
	checkRequiredEntitiesPresence,
} from '../../../../utils/commonHelpers';
import { renderTextField, renderCKEditor, renderTextAreaField } from '../../../../reusableComponents/Form/formComponents';
import { required } from '../../../../reusableComponents/Form/fieldValidators';
import { getBlog, getBlogTags, createBlogTag, getBlogId, onSubmit, getBlogSuccess } from '../../blogSlice';
import { modifyBeforeSaveBlogDetails, modifyBeforeSetBlogDetails } from '../../helpers';
import { STATUS_ENTITY_PUBLISHED, STATUS_ENTITY_DRAFT } from '../../../../utils/constants';
import history from '../../../App/history';

import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import Box from '../../../../reusableComponents/Box';
import AsyncCreatableSelectField from '../../../../reusableComponents/Form/AsyncCreatableSelectField';
import DropZoneField from '../../../../reusableComponents/Form/DropZone';
import MetaTagsForm from '../../../../reusableComponents/Form/MetaTagsForm';

const BlogAddEdit = (props) => {
	const titleChangeHandler = (val) => {
		const slug = stringToSlug(val);
		props.change('slug', slug);
	};

	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} Blog</h3>
					</Col>
					<Col sm="12" md="3">
						<Button size="sm" color="primary" type="submit" onClick={() => props.setStatus(STATUS_ENTITY_PUBLISHED)}>
							Publish
						</Button>
						<Button size="sm" color="secondary" type="submit" onClick={() => props.setStatus(STATUS_ENTITY_DRAFT)}>
							Save
						</Button>
						<Button size="sm" color="success" type="button" onClick={() => history.push('/blogs/list')}>
							Close
						</Button>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="12">
						<Field
							label="Title"
							isRequired
							validate={required}
							type="text"
							onChange={(e, val) => (isNotEmptyObject(props.initialValues) ? null : titleChangeHandler(val))}
							component={renderTextField}
							name="title"
						/>
					</Col>
					<Col sm="12">
						<Field name="preview" label="Preview" component={renderTextAreaField} />
					</Col>
					<Col md="6" sm="12">
						<span className="form__form-group-label">Tags</span>
						<AsyncCreatableSelectField
							onCreateOption={props.createOptionHandler}
							defaultOptions={modifyBeforeSetTags(props.blogTags || [])}
							changeHandler={(args) => props.tagsChangeHandler(args)}
							value={props.selectedTags}
						/>
					</Col>
					<Col md="6" sm="12">
						<Field label="Slug" type="text" component={renderTextField} name="slug" />
					</Col>
					<Col sm="12" md="6" className="mt-3">
						<span className="form__form-group-label">Cover Picture</span>
						<DropZoneField
							value={props.coverPic}
							onChange={(v) => {
								props.setCoverPic(v);
							}}
						/>
					</Col>
					<Col sm="12" className="mt-3">
						<MetaTagsForm />
					</Col>
					<Field entityId={props.blogId} entityType="BLOG" type="text" component={renderCKEditor} name="content" />
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_blog', enableReinitialize: true });

const BlogReduxForm = compose(withReduxForm)(BlogAddEdit);

const BlogForm = (props) => {
	const [selectedTags, setSelectedTags] = useState([]);
	const [coverPic, setCoverPic] = useState(null);
	const [status, setStatus] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.search) {
			dispatch(getBlog(pickWrapper(['id'], parseQueryParams(props.location.search))));
		} else {
			dispatch(getBlogId());
		}
		dispatch(getBlogTags());
		return () => {
			dispatch(getBlogSuccess({}));
		};
	}, [props.location.search]);

	useEffect(() => {
		if (isNotEmptyObject(props.blog)) {
			setSelectedTags(modifyBeforeSetTags(props.blog.tags));
			if (isNotEmptyObject(props.blog.coverPicture))
				setCoverPic([
					{
						name: 'name',
						preview: getSanitizedImageUrl(ObjectMaybe(props.blog.coverPicture).url),
						id: ObjectMaybe(props.blog.coverPicture).id,
					},
				]);
		}
	}, [props.blog]);

	const handleSubmit = async (params) => {
		const processedValues = { ...modifyBeforeSaveBlogDetails(params) };

		if (isNotEmptyObject(processedValues)) {
			if (status === STATUS_ENTITY_PUBLISHED) {
				let dd = [];
				if (!isNotEmptyArray(coverPic)) {
					dd = [...dd, 'Cover Picture'];
				}
				if (!params.content || params.content === '<p></p>') {
					dd = [...dd, 'content'];
				}
				dd = [...dd, ...checkRequiredEntitiesPresence(processedValues, ['title', 'slug', 'preview'])];
				if (isNotEmptyArray(dd)) {
					showToast('error', `🚫️ Required Fields: ${dd.join(', ')}`);
					return;
				}
			}
			processedValues.status = status;
			if (isNotEmptyArray(coverPic)) {
				if (!coverPic[0].id) {
					[processedValues.coverPicture] = coverPic;
				} else {
					processedValues.coverPicture = props.blog.coverPicture;
				}
			} else {
				processedValues.coverPicture = null;
			}
			const tagIds = selectedTags.map((dd) => props.blogTags.find((ddd) => ddd.name === dd.value));
			processedValues.tags = tagIds;
			if (isNotEmptyObject(props.blog)) {
				processedValues.isEdit = true;
			} else {
				processedValues.isEdit = false;
				processedValues.id = props.blogId;
			}
			dispatch(onSubmit(processedValues));
		} else {
			showToast('error', '🚫️ Please fill at least one field!!');
		}
	};

	const tagsChangeHandler = (val) => {
		if (isNotEmptyArray(val)) {
			setSelectedTags(val);
		} else if (!val) {
			setSelectedTags([]);
		}
	};

	const createOptionHandler = (opt) => {
		dispatch(createBlogTag({ name: opt }));
		setSelectedTags([...selectedTags, { label: opt, value: opt }]);
	};

	return (
		<LoadingOverlay isLoading={props.isSubmitting} msg="Submitting Blog...">
			<Card>
				<CardBody>
					<BlogReduxForm
						initialValues={modifyBeforeSetBlogDetails({ ...props.blog })}
						onSubmit={(args) => handleSubmit(args)}
						coverPic={coverPic}
						setStatus={(args) => setStatus(args)}
						blogId={props.location.search ? parseQueryParams(props.location.search).id : props.blogId}
						setCoverPic={(args) => setCoverPic(args)}
						blogTags={props.blogTags}
						selectedTags={selectedTags}
						tagsChangeHandler={tagsChangeHandler}
						createOptionHandler={(args) => createOptionHandler(args)}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

const withConnect = connect((state) => ({
	blog: state.blog.blog,
	blogId: state.blog.blogId,
	blogTags: state.blog.blogTags,
	loading: state.blog.loading,
	isSubmitting: state.blog.isSubmitting,
}));

export default compose(withConnect)(BlogForm);
