import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams } from 'utils/commonHelpers';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { SelectField } from 'reusableComponents/Form/Select';
import history from '../../App/history';
import { setApiParams } from '../experimentSlice';
import { getExperiments, updateExperimentAndUpdateList } from '../actions/experiment.actions';

const statusList = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
	{ label: 'Draft', value: 'DRAFT' },
	{ label: 'Complete', value: 'COMPLETE' },
	{ label: 'Closed', value: 'CLOSED' },
];

export const experimentTypesList = [
	{ label: 'Deterministic', value: 'DETERMINISTIC' },
	{ label: 'Non-Deterministic', value: 'NON_DETERMINISTIC' },
];

const Actions = ({ id, status }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown disabled={status === 'CLOSED'} isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => history.push(`/experiment/add-edit${stringifyQueryParams({ id })}`)}>Edit Experiment</DropdownItem>
				{status !== 'COMPLETE' && (
					<DropdownItem
						onClick={() => {
							/* eslint-disable no-alert */
							const isConfirmed = window.confirm(
								`Are you sure you want to mark this experiment as ${status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
							);
							if (isConfirmed)
								dispatch(
									updateExperimentAndUpdateList({
										experimentId: id,
										status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
									})
								);
						}}
					>
						Mark {status === 'ACTIVE' ? 'Inactive' : 'Active'}
					</DropdownItem>
				)}
				{status === 'ACTIVE' && (
					<DropdownItem
						onClick={() => {
							/* eslint-disable no-alert */
							const isConfirmed = window.confirm(`Are you sure you want to mark this experiment as COMPLETE`);
							if (isConfirmed)
								dispatch(
									updateExperimentAndUpdateList({
										experimentId: id,
										status: 'COMPLETE',
									})
								);
						}}
					>
						Mark Completed
					</DropdownItem>
				)}
				{(status === 'COMPLETE' || status === 'INACTIVE') && (
					<DropdownItem
						onClick={() => {
							/* eslint-disable no-alert */
							const isConfirmed = window.confirm(`Are you sure you want to mark this experiment as CLOSED`);
							if (isConfirmed)
								dispatch(
									updateExperimentAndUpdateList({
										experimentId: id,
										status: 'CLOSED',
									})
								);
						}}
					>
						Mark Closed
					</DropdownItem>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ experiments }) {
	return experiments.map(({ id, status, name, experimentKey, experimentType, device }) => ({
		id,
		name,
		experimentKey,
		experimentType,
		device: isNotEmptyArray(device) ? device.join(', ') : '---',
		status,
		actions: (
			<Actions
				{...{
					id,
					status,
				}}
			/>
		),
	}));
}

const heads = [
	{ accessor: 'name', Header: 'Experiment Name' },
	{ accessor: 'experimentKey', Header: 'Experiment Key' },
	{ accessor: 'experimentType', Header: 'Experiment Type' },
	{ accessor: 'device', Header: 'Device' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const ExperimentTable = () => {
	const experimentState = useSelector(({ experiment }) => ({
		loading: experiment.loading,
		experiments: experiment.experiments,
		experiment: experiment.experiment,
		pageNo: experiment.pageNo,
		total: experiment.total,
		apiParams: experiment.apiParams,
	}));

	const dispatch = useDispatch();

	const [experimentRows, setExperimentRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	React.useEffect(() => {
		dispatch(getExperiments(experimentState.apiParams));
	}, []);

	React.useEffect(() => {
		setPageOfItems(experimentState.pageNo);
	}, [experimentState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(experimentState.experiments)) {
			const structuredRows = createRows({ experiments: experimentState.experiments });
			setExperimentRows(structuredRows);
		} else {
			setExperimentRows([]);
		}
	}, [experimentState.experiments]);

	React.useEffect(() => {
		dispatch(getExperiments({ ...experimentState.apiParams }));
	}, [experimentState.apiParams]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = experimentState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', experimentState),
					skip: accessDeepObject('apiParams.limit', experimentState) * (pageOfItems - 1),
				})
			);
		}
	};

	const onFilterChange = ({ filter, value }) => {
		const { apiParams } = experimentState;
		const newParams = {
			sortKey: apiParams.sortKey,
			sortOrder: apiParams.sortOrder,
			limit: apiParams.limit,
			skip: 0,
			[filter]: value,
		};
		dispatch(setApiParams(newParams));
		dispatch(getExperiments(newParams));
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={12} className="mb-3">
							<h3>Experiment Listing</h3>
						</Col>
						<Col sm={3}>
							<SelectField
								clearable
								placeholder="Status"
								name="status"
								options={statusList}
								onChange={(val) => onFilterChange({ filter: 'status', value: val })}
							/>
						</Col>
						<Col sm={3}>
							<SelectField
								clearable
								placeholder="Experiment Type"
								name="experimentType"
								options={experimentTypesList}
								onChange={(val) => onFilterChange({ filter: 'experimentType', value: val })}
							/>
						</Col>
						<Col style={{ textAlign: 'left' }} sm={{ size: 3, offset: 3 }}>
							<Button
								style={{ marginBottom: 0 }}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/experiment/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(experimentRows) && (
						<>
							<DataPaginationTable heads={heads} rows={experimentRows} onSort={onSort} />
							<Pagination
								itemsCount={experimentState.total}
								itemsToShow={accessDeepObject('apiParams.limit', experimentState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{experimentState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default ExperimentTable;
