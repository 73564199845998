import jwtDecode from 'jwt-decode';
import { call, takeLatest, put } from 'redux-saga/effects';

import history from '../App/history';
import { requestSaga } from '../../utils/requestSaga';
import { loginUser, registerUser, authSuccess, mutateLoading, forgotPassword, resetPassword } from './authSlice';
import { setError } from '../App/globalSlice';
import { actionSpreader, showToast } from '../../utils/commonHelpers';

export function* loginRequestSaga({ payload }) {
	const data = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/login`, {
		body: JSON.stringify(payload),
	});

	if (data.status === 200) {
		const {
			data: { token },
		} = data;
		localStorage.setItem('jwtToken', token);
		// Decode token to get user data
		const decoded = jwtDecode(token);
		history.push('/');
		yield put(actionSpreader(authSuccess.type, { user: decoded }));
	} else if (data.status === 400) {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.error }));
	} else {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.message }));
	}
}

export function* registerRequestSaga({ payload }) {
	const data = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/register`, {
		body: JSON.stringify(payload),
		credentials: 'same-origin',
	});

	if (data.status === 200) {
		const {
			data: { token },
		} = data;
		localStorage.setItem('jwtToken', token);
		// Decode token to get user data
		const decoded = jwtDecode(token);
		history.push('/');
		yield put(actionSpreader(authSuccess.type, { user: decoded }));
	} else if (data.status === 400) {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.error }));
	} else {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.message }));
	}
}

export function* forgotPasswordRequestSaga({ payload }) {
	const data = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/forgot-password`, {
		body: JSON.stringify(payload),
		credentials: 'same-origin',
	});

	if (data.status === 200) {
		showToast('success', data.data.message);
		yield put(actionSpreader(mutateLoading.type, false));
	} else if (data.status === 400) {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.error }));
	} else {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.message }));
	}
}

export function* resetPasswordRequestSaga({ payload }) {
	const data = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/reset-password`, {
		body: JSON.stringify(payload),
		credentials: 'same-origin',
	});

	if (data.status === 200) {
		showToast('success', data.data.message);
		yield put(actionSpreader(mutateLoading.type, false));
	} else if (data.status === 400) {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.error }));
	} else {
		yield put(actionSpreader(mutateLoading.type, false));
		yield put(actionSpreader(setError.type, { errors: data.message }));
	}
}

export default function* authSaga() {
	yield takeLatest(loginUser.type, loginRequestSaga);
	yield takeLatest(registerUser.type, registerRequestSaga);
	yield takeLatest(forgotPassword.type, forgotPasswordRequestSaga);
	yield takeLatest(resetPassword.type, resetPasswordRequestSaga);
}
