import history from 'containers/App/history';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { DropzoneNew as Dropzone } from 'reusableComponents/HookForm/Dropzone';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import Box from 'reusableComponents/Box';
import { getCFSanitizedImageUrl, getSanitizedImageUrl, getSanitizedVideoUrl, getSanitizedAudioUrl } from 'utils/commonHelpers';
import { createS3ImageUpload } from '../actions/actions';
import { userHasDevSettingAccess } from '../utils/helpers';

const S3FileUploadForm = () => {
	const dispatch = useDispatch();

	const { isSubmitting, loggedInUser } = useSelector((state) => ({
		isSubmitting: state.devSettings.isSubmitting,
		loggedInUser: state.auth.user,
	}));
	const [finalUrls, setFinalUrls] = useState<string[]>([]);

	const isUserS3Admin = userHasDevSettingAccess(loggedInUser.email[0]);

	const hookFormMethods = useForm();
	const { handleSubmit, setError } = hookFormMethods;

	const onSubmit = async (values) => {
		const fileUrls = [];
		let isValidated = true;

		const newImageList = values.imageList.map((file) => {
			let parsedCaption = file.caption || values.commonPath;

			if (!parsedCaption) {
				setError('commonPath', {
					type: 'required',
					message: 'Common path or image location is required',
					shouldFocus: true,
				});
				isValidated = false;
			}

			if (parsedCaption.startsWith('/')) {
				parsedCaption = parsedCaption.slice(1, parsedCaption.length);
			}
			if (parsedCaption.endsWith('/')) {
				parsedCaption = parsedCaption.slice(0, parsedCaption.length - 1);
			}

			const staticPrefix = isUserS3Admin ? '' : 'admin/';
			const finalPrefix = staticPrefix + parsedCaption;

			const isImage = file.photo.type.startsWith('image');
			const isVideo = file.photo.type.startsWith('video');
			const isAudio = file.photo.type.startsWith('audio');
			let sanitizedUrl;
			if (isImage) {
				sanitizedUrl = getSanitizedImageUrl(`${finalPrefix}/${file.photo.name}`);
			} else if (isVideo) {
				sanitizedUrl = getSanitizedVideoUrl(`${finalPrefix}/${file.photo.name}`);
			} else if (isAudio) {
				sanitizedUrl = getSanitizedAudioUrl(`${finalPrefix}/${file.photo.name}`);
			} else {
				sanitizedUrl = getCFSanitizedImageUrl(`${finalPrefix}/${file.photo.name}`);
			}
			fileUrls.push(sanitizedUrl);
			return {
				...file,
				caption: finalPrefix,
			};
		});
		if (isValidated) {
			setFinalUrls(fileUrls);
			const newValues = {
				imageList: newImageList,
			};
			dispatch(createS3ImageUpload(newValues));
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Uploading Files...">
			<FormProvider {...hookFormMethods}>
				<div>
					<Card>
						<CardBody>
							<Box w="100%">
								<Row>
									<Col sm="9">
										<h3>S3 File Upload</h3>
									</Col>
									<Col sm="3">{isUserS3Admin && <h4>Admin User</h4>}</Col>
									<Col sm="12" style={{ paddingTop: '10px' }}>
										<h5>Enter the s3 path (without trailing or leading slash) where the file has to be uploaded in the following format</h5>
										<code>assets/test</code>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<form onSubmit={handleSubmit(onSubmit)} className="form ltr-support">
									<Col sm="12">
										<Input
											label="Common Location"
											name="commonPath"
											placeholder="This will be used as fallback if custom path for each file is not added"
										/>
									</Col>
									<Col sm="12">
										<Dropzone
											label="Files"
											name="imageList"
											accept="image/jpeg, image/png, image/webp, image/svg+xml, application/pdf, text/csv, audio/mp3, image/gif, application/json, audio/mpeg, video/mp4, text/plain"
											isMulti
											withCaption
											noOfFiles={20}
											customCaptionLabel="location"
										/>
									</Col>
									{finalUrls.length > 0 && (
										<Col sm="12" style={{ marginBottom: 30 }}>
											<h3>Final Urls: </h3>
											{finalUrls.map((url) => (
												<>
													<a key={url} href={url}>
														<code>{url}</code>
													</a>
													<br />
												</>
											))}
										</Col>
									)}
									<Button type="submit" color="primary" disabled={isSubmitting}>
										Submit
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/video/list')}>
										Cancel
									</Button>
								</form>
							</Box>
						</CardBody>
					</Card>
				</div>
			</FormProvider>
		</LoadingOverlay>
	);
};

export default S3FileUploadForm;
