export type TCurriculum = {
	id: string;
	title: string;
};

export enum RankLevelEnum {
	BEGINNER = 'BEGINNER',
	INTERMEDIATE = 'INTERMEDIATE',
	ADVANCED = 'ADVANCED',
}

export type TModule = {
	displayName: string;
	moduleEnum: string;
	description?: string;
	status: DocumentStatusEnum;
	adminCode: string;
	rank?: number;
	ranks?: {
		[key in RankLevelEnum]?: {
			rank: number;
		};
	};
};

export type TSubtopic = {
	curriculum: TCurriculum;
	id: string;
	displayName: string;
	description?: string;
	video?: TEmbeddedMedia;
	adminCode: string;
	outcomeImages?: Array<TEmbeddedMedia>;
	status?: ContentDocumentStatusEnum;
	rank?: number;
	rankNumber?: number;
	rankDecimal?: number;
	coverImage?: TEmbeddedMedia;
	courses?: Array<TCourses>;
	iterator?: number;
};

export enum DocumentStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

enum SkipTypeEnum {
	SKIPPABLE = 'SKIPPABLE',
	NON_SKIPPABLE = 'NON_SKIPPABLE',
}

export enum ExerciseTypeEnum {
	MANDATORY = 'MANDATORY',
	BONUS = 'BONUS',
}

type TEmbeddedCurriculum = {
	id: string;
	displayName: string;
};

type TTopic = {
	displayName: string;
	status: DocumentStatusEnum;
	adminCode: string;
};

type TCleanTopic = TTopic & {
	id: string;
};

type TEmbeddedSubtopic = {
	id: string;
	displayName: string;
};

export enum MediaTypeEnum {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	PDF = 'PDF',
	AUDIO = 'AUDIO',
}

export enum PlayUiTypeEnum {
	LISTEN = 'LISTEN',
	SPEAK = 'SPEAK',
	PLAY = 'PLAY',
	PRACTICE = 'PRACTICE',
	ASSESS = 'ASSESS',
	WORDPLAY = 'WORDPLAY',
}

type TEmbeddedMedia = {
	mediaId?: string;
	mediaType: MediaTypeEnum;
	mediaPath: string;
	aspectRatio?: string;
};

type TInteractiveQuestion = {
	id: string;
	triggertime: {
		min: string;
		sec: string;
	};
	waitDuration: string;
};

export type TExercise = {
	id: string;
	title: string;
	aliasTitle?: string;
	adminCode: string;
	skipType: SkipTypeEnum;
	totalTimeInSeconds?: number;
	curriculum: TEmbeddedCurriculum;
	module?: TModule;
	topic?: TCleanTopic;
	subtopic?: TEmbeddedSubtopic;
	type: ExerciseTypeEnum;
	questions: Array<string>;
	supplementaryExercise?: TEmbeddedMedia;
	supplementaryExerciseSolution?: TEmbeddedMedia;
	certificateEnabled: boolean;
	leaderboardEnabled?: boolean;
	detailedResultsEnabled: boolean;
	status: string;
	playUiType?: PlayUiTypeEnum;
	coverImage?: TEmbeddedMedia;
	video?: { id: string; label: string; value: string };
	interactiveQuestions?: TInteractiveQuestion;
	rank?: number;
	rankNumber?: number;
	rankDecimal?: number;
};

export type TCourses = {
	id: string;
	name: string;
	description: string;
	need?: string;
};

export enum QuestionTypesEnum {
	ANAGRAM = 'ANAGRAM',
	TEXT = 'TEXT',
	MATCH_COLUMN = 'MATCH_COLUMN',
	MCQ = 'MCQ',
	READ_ALONG = 'READ_ALONG',
	CONTENT_ONLY = 'CONTENT_ONLY',
	MEDIA = 'MEDIA',
	CONVERSATION = 'CONVERSATION',
	TELEPROMPTER = 'TELEPROMPTER',
	CROSSWORD = 'CROSSWORD',
}

export enum ReadAlongContentTypeEnum {
	READ_ALONG_WITH_IMAGE = 'READ_ALONG_WITH_IMAGE',
	READ_ALONG_WITHOUT_IMAGE = 'READ_ALONG_WITHOUT_IMAGE',
	WORD_MEANING = 'WORD_MEANING',
}

enum TtsConversionStatusEnum {
	NOT_APPLICABLE = 'NOT_APPLICABLE',
	PENDING = 'PENDING',
	IN_PROGRESS = 'IN_PROGRESS',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAIL',
}

type TMcqOption = {
	text: string;
	media?: TEmbeddedMedia;
	isCorrectAnswer?: boolean;
};

export enum ContentDocumentStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DRAFT = 'DRAFT',
}
type TBaseExerciseQuestion = {
	id: string;
	type: QuestionTypesEnum;
	adminCode: string;
	passageText: string;
	passageTextAudio: string;
	passageTitle: string;
	passageTitleAudio: string;
	ttsDescriptionAudio?: TEmbeddedMedia;
	ttsConversionStatus: TtsConversionStatusEnum;
	solutionVideoUrl: string;
	title: string;
	titleAudio?: string;
	subtitle: string;
	subtitleAudio?: string;
	descriptionImage?: TEmbeddedMedia;
	exampleImage?: TEmbeddedMedia;
	descriptionVideo?: TEmbeddedMedia;
	status: ContentDocumentStatusEnum;
	curriculum?: TEmbeddedCurriculum;
	module?: TModule;
	topic?: TCleanTopic;
	subtopic?: TEmbeddedSubtopic;
	voiceId?: string;
	starsToGrant?: number;
	difficulty?: number;
};

type TMcqExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.MCQ;
	options: Array<TMcqOption>;
	questionMapping?: string;
};

type TSubPrompt = {
	roleName?: string;
	startingMsg?: string;
	msgCounter: number;
	prompt: string;
};

type TConversationExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.CONVERSATION;
	roleDescription: string;
	roleName: string;
	initialDialogue: string;
	initialDialogueAudio?: string;
	maxLength: number;
	subPrompts?: Array<TSubPrompt>;
	roleVoiceId?: string;
};

export enum AnagramTypesEnum {
	WORD = 'WORD',
	SENTENCE = 'SENTENCE',
}

export enum MatchColumnTypesEnum {
	MEMORY = 'MEMORY',
	STANDARD = 'STANDARD',
}
export enum QuestionMappingEnum {
	IMAGES_TO_IMAGES = 'IMAGES_TO_IMAGES',
	TEXT_TO_TEXT = 'TEXT_TO_TEXT',
	IMAGES_TO_TEXT = 'IMAGES_TO_TEXT',
	TEXT_TO_IMAGES = 'TEXT_TO_IMAGES',
	SOUND_TO_TEXT = 'SOUND_TO_TEXT',
	SOUND_TO_IMAGES = 'SOUND_TO_IMAGES',
}

export const MatchColumnMappingTypes = [
	{
		label: 'Text to Text',
		value: QuestionMappingEnum.TEXT_TO_TEXT,
	},
	{
		label: 'Text to Images',
		value: QuestionMappingEnum.TEXT_TO_IMAGES,
	},
	{
		label: 'Images to Text',
		value: QuestionMappingEnum.IMAGES_TO_TEXT,
	},
	{
		label: 'Images to Images',
		value: QuestionMappingEnum.IMAGES_TO_IMAGES,
	},
];

export const McqMappingTypes = [
	{
		label: 'Text to Text',
		value: QuestionMappingEnum.TEXT_TO_TEXT,
	},
	{
		label: 'Text to Images',
		value: QuestionMappingEnum.TEXT_TO_IMAGES,
	},
	{
		label: 'Images to Text',
		value: QuestionMappingEnum.IMAGES_TO_TEXT,
	},
	{
		label: 'Images to Images',
		value: QuestionMappingEnum.IMAGES_TO_IMAGES,
	},
	{
		label: 'Sound to Text',
		value: QuestionMappingEnum.SOUND_TO_TEXT,
	},
	{
		label: 'Sound to Images',
		value: QuestionMappingEnum.SOUND_TO_IMAGES,
	},
];

type TAnagramBlock = {
	text: string;
	showInOption?: boolean;
	isAnswer?: boolean;
};

type TAnagramExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.ANAGRAM;
	blocks: Array<TAnagramBlock>;
	anagramType: AnagramTypesEnum;
};

type TTextOrImage = {
	key?: string;
	text?: string;
	image?: TEmbeddedMedia;
};

type TMatchColumnItem = {
	column?: string;
	key: string;
	value: TTextOrImage;
};

type TMatchColumnExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.MATCH_COLUMN;
	items: Array<TMatchColumnItem>;
	numColumns: number;
	matchColumnType: MatchColumnTypesEnum;
	questionMapping?: QuestionMappingEnum;
	coverImage?: TEmbeddedMedia;
};

type TBottomSheet = {
	heading?: string;
	description?: string;
	audioText?: string;
	audioPath?: string;
	audioFileUrl?: string;
	image?: TEmbeddedMedia;
	buttons?: Array<any>;
};

type TClickAction = {
	type?: string;
	functionType?: string;
	url?: string;
	eventDescription?: string;
	bottomSheet?: TBottomSheet;
};

export type TContentOnlyExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.CONTENT_ONLY;
	roleName?: string;
	clickAction?: TClickAction;
	shouldPauseAutoNext?: boolean;
};

type TMediaExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.MEDIA;
	allowedMediaTypes: Array<MediaTypeEnum>;
};

export type Breadcrumb = {
	text: string;
	url?: string;
};

export type TReadAlongExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.READ_ALONG;
	roleName?: string;
	clickAction?: TClickAction;
	shouldPauseAutoNext?: boolean;
	contentType?: ReadAlongContentTypeEnum;
};

export type TTeleprompterExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.TELEPROMPTER;
	maxRecordingDuration: number;
};

export type TCrosswordsExerciseQuestion = TBaseExerciseQuestion & {
	type: QuestionTypesEnum.CROSSWORD;
};

export type TExerciseQuestion =
	| TMcqExerciseQuestion
	| TAnagramExerciseQuestion
	| TMatchColumnExerciseQuestion
	| TMediaExerciseQuestion
	| TContentOnlyExerciseQuestion
	| TConversationExerciseQuestion
	| TReadAlongExerciseQuestion
	| TTeleprompterExerciseQuestion
	| TCrosswordsExerciseQuestion;

export type TExercisesState = {
	curriculums?: Array<TCurriculum>;
	modules?: Array<TModule>;
	subtopics?: Array<TSubtopic>;
	exercises?: Array<TExercise>;
	questions?: Array<TExerciseQuestion>;
	courses?: Array<TCourses>;
	additionalData?: any;
	isLoading: boolean;
	error: string;
	page: number;
	total: number;
	isSubmitting: boolean;
	apiParams: {
		limit: number;
		skip: number;
		query?: string;
		sortKey: string;
		sortOrder: '-1' | '1';
		filters?: Record<string, string>;
	};
};
