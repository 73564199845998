import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { UploadCertificateForm } from './components/UploadCertificateForm';
import ViewCertificate from './components/ViewCertificate';

const Certificate = () => {
	return (
		<div>
			<Switch>
				<Route path="/certificates" exact component={UploadCertificateForm} />
				<Route path="/certificates/:id" component={ViewCertificate} />
			</Switch>
		</div>
	);
};

export default Certificate;
