import React from 'react';
import If from 'reusableComponents/If';
import Box from 'reusableComponents/Box';
import theme from 'utils/theme';
import { IPhotoDatabase } from 'types/Photo';
import { getSanitizedImageUrl, getVideoThumbnailUrl } from 'utils/commonHelpers';

export const TableCoverPicture = ({
	coverPicture,
	title,
	setIsVideoPreviewModalOpen,
}: {
	coverPicture: IPhotoDatabase;
	title: string;
	setIsVideoPreviewModalOpen?: any;
}) => (
	<If
		condition={!!coverPicture?.url}
		then={
			<If
				condition={coverPicture?.mediaType === 'VIDEO'}
				then={
					<Box
						h="3rem"
						w="3rem"
						bRad="50%"
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setIsVideoPreviewModalOpen({ isOpen: true, videoUrl: coverPicture?.url });
						}}
					>
						<img
							width="100%"
							height="100%"
							style={{ borderRadius: '50%', objectFit: 'cover' }}
							src={getSanitizedImageUrl(getVideoThumbnailUrl(), { w: 40 })}
							alt="cover-pic"
						/>
						<img alt="play" style={{ marginTop: '-140%', marginLeft: '35%', width: '40%', height: 'auto' }} src="/img/play-button.png" />
					</Box>
				}
				else={
					<Box h="3rem" w="3rem" bRad="50%">
						<a href={getSanitizedImageUrl(coverPicture?.url)} target="_blank" rel="noopener noreferrer">
							<img
								width="100%"
								height="100%"
								style={{ borderRadius: '50%', objectFit: 'cover' }}
								src={coverPicture && getSanitizedImageUrl(coverPicture?.url, { w: 40 })}
								alt="cover-pic"
							/>
						</a>
					</Box>
				}
			/>
		}
		else={
			<Box
				d="flex"
				jc="center"
				ai="center"
				fs="2rem"
				bg={theme.clrs.cGraphite}
				c={theme.clrs.cWhite}
				h="3rem"
				w="3rem"
				bRad="50%"
				tt="capitalize"
			>
				{title ? title[0] : null}
			</Box>
		}
	/>
);
