/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoJsPlayer from 'reusableComponents/VideoPlayer/VideoJsPlayer';
import { accessDeepObject } from '../../../utils/commonHelpers';
import { fetchVideo, clearVideo } from '../classSlice';

const WatchVideo = (props) => {
	const dispatch = useDispatch();
	const video = useSelector((state) => state.classes.video);
	const { videoId } = props.match.params;

	useEffect(() => {
		dispatch(fetchVideo({ videoId }));

		return () => {
			dispatch(clearVideo());
		};
	}, []);

	return <VideoJsPlayer videoName={accessDeepObject('videoName', video)} videoUrl={accessDeepObject('videoUrl', video)} />;
};

export default WatchVideo;
