import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper } from '../../../utils/commonHelpers';
import { toggleIsSubmitting, categoryOnSubmitSuccess } from '../categorySlice';
import { setError } from '../../App/globalSlice';
import { mutateSaga } from '../../../shared/saga';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit', 'pos'], payload) };
	const categoryRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: payload.isEdit,
		url: `${process.env.REACT_APP_API_URL}/v1/admin/category`,
	});
	if (categoryRes.status === 200) {
		showToast('success', `👍️ Category ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(
			actionSpreader(categoryOnSubmitSuccess.type, {
				category: categoryRes.data,
				isEdit: payload.isEdit,
			})
		);
		history.push('/category/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: categoryRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
