import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { getBlogTagsSuccess } from '../blogSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader } from '../../../utils/commonHelpers';

export function* getBlogTagsSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/blog/tags`);
	if (res.status === 200) {
		yield put(actionSpreader(getBlogTagsSuccess.type, { blogTags: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
