import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from 'reactstrap';
import isUndefined from 'lodash/isUndefined';
import Box from '../Box';
import { InputNew as Input } from './Input';
import If from '../If';

interface Props {
	name: string;
	label: string;
	required?: boolean;
	minLength?: number;
	maxLength?: number;
	disabled?: boolean;
	isClickDisabled?: boolean;
	miniumNumberOfFields?: number;
	maximumNumberOfFields?: number;
}
export const InputFieldArray = ({
	name,
	label,
	miniumNumberOfFields,
	maximumNumberOfFields,
	required,
	minLength,
	maxLength,
	disabled,
	isClickDisabled,
}: Props) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	useEffect(() => {
		if (miniumNumberOfFields && fields.length === 0) {
			for (let i = 0; i < miniumNumberOfFields; i += 1) {
				append({ value: '' });
			}
		}
	}, []);
	return (
		<Box>
			{fields.map((item, index) => {
				return (
					<Box key={item.id} pos="relative" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
						<Input
							name={`${name}.${index}.value`}
							label={` ${label} ${index + 1}`}
							defaultValue={item.value} // make sure to set up defaultValue
							{...{ required, minLength, maxLength, disabled }}
						/>
						<If
							condition={index + 1 > miniumNumberOfFields}
							then={
								<Box onClick={() => remove(index)} cursor="pointer" pos="absolute" t="0" r="0">
									<span className="lnr lnr-cross" />
								</Box>
							}
						/>
					</Box>
				);
			})}

			<If
				condition={fields.length < maximumNumberOfFields || isUndefined(maximumNumberOfFields)}
				then={
					<Button size="sm" onClick={() => append({ value: '' })}>
						Add {label}
					</Button>
				}
			/>
		</Box>
	);
};
