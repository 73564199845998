import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Button, Card, CardBody, ButtonToolbar } from 'reactstrap';

import {
	parseQueryParams,
	pickWrapper,
	isNotEmptyArray,
	isNotEmptyObject,
	showToast,
	stringToSlug,
	checkRequiredEntitiesPresence,
	ObjectMaybe,
	omitWrapper,
} from '../../../../utils/commonHelpers';
import { renderTextField, renderCKEditor } from '../../../../reusableComponents/Form/formComponents';
import { required } from '../../../../reusableComponents/Form/fieldValidators';
import { getFaq, getFaqTypes, createFaqType, getFaqId, faqOnSubmit, getFaqSuccess } from '../../faqSlice';
import { getPrograms } from '../../../Program/programSlice';
import { modifyBeforeSaveFaqDetails, modifyBeforeSetFaqDetails } from '../../helpers';
import { STATUS_ACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';

import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import Box from '../../../../reusableComponents/Box';
import AsyncCreatableSelectField from '../../../../reusableComponents/Form/AsyncCreatableSelectField';
// import MetaTagsForm from '../../../../reusableComponents/Form/MetaTagsForm';
import { SelectField } from '../../../../reusableComponents/Form/Select';

const modifyBeforeSetTypes = (data) => {
	return data.map((opt) => ({ label: opt.name, value: opt.slug }));
};

const FaqAddEdit = (props) => {
	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} FAQ</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="12">
						<Field label="Question" isRequired validate={required} type="text" component={renderTextField} name="question" />
					</Col>
					<Col md="6" sm="12">
						<span className="form__form-group-label">FAQ Type</span>
						<AsyncCreatableSelectField
							isMulti={false}
							onCreateOption={props.createTypeHandler}
							defaultOptions={modifyBeforeSetTypes(props.faqTypes || [])}
							changeHandler={(args) => props.typeChangeHandler(args)}
							value={props.faqType}
						/>
					</Col>
					<Col md="6" sm="12">
						{ObjectMaybe(props.faqType).value === 'program' && (
							<>
								<span className="form__form-group-label">Program</span>
								<SelectField
									name="programId"
									value={props.selectedProgram}
									options={props.programs.map((program) => ({
										label: program.title,
										value: program.id,
									}))}
									inputChangeHandler={(val) => props.programQueryHandler(val)}
									onChange={(val) => props.programChangeHandler(props.programs.find((dd) => dd.id === val))}
								/>
							</>
						)}
					</Col>
					{/* <Col sm='12' className='mt-3'>
						<MetaTagsForm />
					</Col> */}
					<Col sm="12" className="mt-3">
						<Field entityId={props.faqId} entityType="FAQ" type="text" component={renderCKEditor} name="answer" label="Answer" />
					</Col>
				</Row>
				<ButtonToolbar className="form__button-toolbar">
					<Button type="button" onClick={() => history.push('/faq/list')}>
						Cancel
					</Button>
					<Button color="primary" type="submit">
						Submit
					</Button>
				</ButtonToolbar>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_faq', enableReinitialize: true });

const FaqReduxForm = compose(withReduxForm)(FaqAddEdit);

const FaqForm = (props) => {
	const [faqType, setFaqType] = useState(null);
	const [program, setProgram] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.search) {
			dispatch(getFaq(pickWrapper(['id'], parseQueryParams(props.location.search))));
		} else {
			dispatch(getFaqId());
		}
		dispatch(getFaqTypes());
		dispatch(getPrograms());
		return () => {
			dispatch(getFaqSuccess({}));
		};
	}, [props.location.search]);

	useEffect(() => {
		if (isNotEmptyObject(props.faq)) {
			setFaqType({
				label: ObjectMaybe(props.faq.faqType).name,
				value: ObjectMaybe(props.faq.faqType).slug,
			});
			if (isNotEmptyObject(props.faq.program)) {
				setProgram({ label: props.faq.program.title, value: props.faq.program.id });
			}
		}
	}, [props.faq]);

	const handleSubmit = async (params) => {
		const processedValues = { ...modifyBeforeSaveFaqDetails(omitWrapper(['programId'], params)) };
		if (isNotEmptyObject(processedValues)) {
			let dd = [];
			if (!isNotEmptyObject(faqType)) {
				dd = [...dd, 'type'];
			}
			if (faqType.value === 'program' && !isNotEmptyObject(program)) {
				dd = [...dd, 'program'];
			}
			dd = [...dd, ...checkRequiredEntitiesPresence(processedValues, ['question', 'answer'])];
			if (isNotEmptyArray(dd)) {
				showToast('error', `🚫️ Required Fields: ${dd.join(', ')}`);
				return;
			}
			processedValues.faqType = faqType.value;
			if (faqType.value === 'program') {
				processedValues.programId = program.value;
			}
			if (isNotEmptyObject(props.faq)) {
				processedValues.isEdit = true;
				processedValues.status = props.faq.status;
			} else {
				processedValues.isEdit = false;
				processedValues.id = props.faqId;
				processedValues.status = STATUS_ACTIVE;
			}
			dispatch(faqOnSubmit(processedValues));
		} else {
			showToast('error', '🚫️ Please select at least one field!!');
		}
	};

	const typeChangeHandler = (val) => {
		if (val) {
			setFaqType(val);
		} else {
			setFaqType(null);
		}
	};

	const createTypeHandler = (opt) => {
		dispatch(createFaqType({ name: opt, slug: stringToSlug(opt) }));
		setFaqType({ label: opt, value: stringToSlug(opt) });
	};

	const programQueryHandler = (query) => {
		if (query) {
			dispatch(getPrograms({ query }));
		}
	};

	const programChangeHandler = (program) => {
		if (program) {
			setProgram({ label: program.title, value: program.id });
		}
	};

	return (
		<LoadingOverlay isLoading={props.isSubmitting} msg="Submitting Faq...">
			<Card>
				<CardBody>
					<FaqReduxForm
						initialValues={modifyBeforeSetFaqDetails({ ...props.faq })}
						onSubmit={(args) => handleSubmit(args)}
						faqId={props.location.search ? parseQueryParams(props.location.search).id : props.faqId}
						faqTypes={props.faqTypes}
						faqType={faqType}
						typeChangeHandler={typeChangeHandler}
						createTypeHandler={(args) => createTypeHandler(args)}
						programs={props.programs}
						selectedProgram={program}
						programQueryHandler={programQueryHandler}
						programChangeHandler={programChangeHandler}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

const withConnect = connect((state) => ({
	faq: state.faq.faq,
	faqId: state.faq.faqId,
	faqTypes: state.faq.faqTypes,
	loading: state.faq.loading,
	isSubmitting: state.faq.isSubmitting,
	programs: state.program.programs,
}));

export default compose(withConnect)(FaqForm);
