import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { deletePost, getPosts, updatePostAndUpdateList } from './actions/featuredPost.actions';

const featuredPostSlice = createSlice({
	name: 'featuredPost',
	initialState: {
		loading: false,
		posts: [],
		post: {},
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'priority',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			status: null,
			query: null,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.status && { status: action.payload.status }),
				...(action.payload.query && { query: action.payload.query }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getPosts.fulfilled as any]: (state, action) => {
			const {
				payload: { posts, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.posts = posts;
			state.total = total;
			state.pageNo = page + 1;
		},
		[updatePostAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { postId, postData },
			} = action;
			const { posts } = state;
			posts[state.posts.findIndex((post) => post.id === postId)] = postData;
			state.posts = [...posts];
		},
		[deletePost.fulfilled as any]: (state, action) => {
			const {
				payload: { postId },
			} = action;

			state.posts = state.posts.filter((post) => post.id !== postId);
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting } = featuredPostSlice.actions;

export default featuredPostSlice.reducer;
