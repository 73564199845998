import { accessDeepObject } from 'utils/commonHelpers';

export const d = null;

export const OPTIONS_STATE = {
	INITIAL: 'INITIAL', // initial state
	CORRECT: 'CORRECT', // selected option was correct
	INCORRECT: 'INCORRECT', // selected option was incorrect
	SELECTED_BEFORE_RESULT: 'SELECTED_BEFORE_RESULT', // option was selected (before results)
	SELECTED_AFTER_RESULT: 'SELECTED_AFTER_RESULT', // option was selected (after results)
	FINAL: 'FINAL', // selected option was neither correct nor incorrect
};

export const ANSWER_STATUS = {
	CORRECT: 'CORRECT',
	INCORRECT: 'INCORRECT',
	UNANSWERED: 'UNANSWERED',
	FINAL: 'FINAL',
};

export const RESULT_TEXT = {
	CORRECT: 'Correct Answer!',
	INCORRECT: 'Oops, Better luck next time!',
	UNANSWERED: 'Time ran out!',
	FINAL: 'Good Choice!',
};

export const OptionFeedbackText = {
	[OPTIONS_STATE.SELECTED_AFTER_RESULT]: 'Your answer',
	[OPTIONS_STATE.CORRECT]: 'Correct answer',
	[OPTIONS_STATE.INCORRECT]: 'Your answer',
};

export const getAnswerStatus = ({ answerId }) => {
	const userAnswerId = localStorage.getItem('testPollAnswer');
	if (!answerId && userAnswerId) return ANSWER_STATUS.FINAL;
	if (userAnswerId && userAnswerId === answerId) return ANSWER_STATUS.CORRECT;
	if (userAnswerId && userAnswerId !== answerId) return ANSWER_STATUS.INCORRECT;
	return ANSWER_STATUS.UNANSWERED;
};

export const getAnswerPercent = (options, index) => {
	// Condition for the case when there are no answers present i.e. answerCount for all options is 0
	const totalAnswerCount = options.reduce((total, val) => total + val.answerCount, 0) || 1;
	return `${Math.floor((options[index].answerCount / totalAnswerCount) * 100)}%`;
};

export const getOptionsState = ({ id, pollResult, selectedOption, selectedAnswerId, questionType }) => {
	if (pollResult && questionType !== 'ASSESSMENT') {
		if (id === selectedAnswerId) return OPTIONS_STATE.SELECTED_AFTER_RESULT;
		return OPTIONS_STATE.FINAL;
	}

	if (id === accessDeepObject('correctAnswerId', pollResult)) return OPTIONS_STATE.CORRECT;

	if (accessDeepObject('correctAnswerId', pollResult) && selectedAnswerId === id) return OPTIONS_STATE.INCORRECT;

	if (accessDeepObject('correctAnswerId', pollResult)) return OPTIONS_STATE.FINAL;

	if (id === selectedOption) return OPTIONS_STATE.SELECTED_BEFORE_RESULT;

	return OPTIONS_STATE.INITIAL;
};
