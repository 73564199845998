/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getMentors, setApiParams, setMentorFeatured, setMentorStatus } from '../../mentorSlice';
import { isNotEmptyArray, accessDeepObject, getSanitizedImageUrl, showToast, isNotEmptyObject } from '../../../../utils/commonHelpers';
import theme from '../../../../utils/theme';
import history from '../../../App/history';

import Box from '../../../../reusableComponents/Box';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';
import Filter from '../../../../reusableComponents/Filter';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (mentors, pageNo, rowsCount, submitHandler, submitFeaturedHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < mentors.length; i += 1) {
		r.push({
			id: initialCount,
			profilePic: accessDeepObject('profilePic.url', mentors[i]) ? (
				<Box h="3rem" w="3rem" bRad="50%">
					<img
						width="100%"
						height="100%"
						style={{ borderRadius: '50%', objectFit: 'cover' }}
						src={getSanitizedImageUrl(mentors[i].profilePic.url)}
						alt="profile-pic"
					/>
				</Box>
			) : (
				<Box
					d="flex"
					jc="center"
					ai="center"
					fs="2rem"
					bg={theme.clrs.cGraphite}
					c={theme.clrs.cWhite}
					h="3rem"
					w="3rem"
					bRad="50%"
					tt="capitalize"
				>
					{accessDeepObject('fullName.0', mentors[i])}
				</Box>
			),
			fullName: mentors[i].fullName,
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/mentor/add-edit?id=${mentors[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color={mentors[i].status === STATUS_ACTIVE ? 'primary' : 'danger'}
						size="md"
						style={customStyles}
						onClick={() => {
							if (mentors[i].status === STATUS_ACTIVE) {
								submitHandler({ id: mentors[i].id, status: STATUS_INACTIVE, pos: i });
							} else {
								submitHandler({ id: mentors[i].id, status: STATUS_ACTIVE, pos: i });
							}
						}}
					>
						Make {mentors[i].status === STATUS_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
					<Button
						color={mentors[i].isFeatured ? 'primary' : 'danger'}
						size="md"
						style={customStyles}
						onClick={() => {
							submitFeaturedHandler(mentors[i], i);
						}}
					>
						{mentors[i].isFeatured ? 'Remove as Featured' : 'Mark as Featured'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const MentorTable = ({ history }) => {
	const mentorState = useSelector(({ mentor }) => ({
		loading: mentor.loading,
		mentors: mentor.mentors,
		pageNo: mentor.pageNo,
		total: mentor.total,
		apiParams: mentor.apiParams,
	}));

	const dispatch = useDispatch();

	const [mentorRows, setMentorRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState(mentorState.apiParams.query);

	React.useEffect(() => {
		dispatch(getMentors());
	}, []);

	React.useEffect(() => {
		setPageOfItems(mentorState.pageNo);
	}, [mentorState.pageNo]);

	const heads = [
		{
			accessor: 'profilePic',
			Header: 'ProfilePic',
		},
		{
			accessor: 'fullName',
			Header: 'Name',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		console.log(params);
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			dispatch(setMentorStatus({ ...params, isEdit: true }));
		}
	};

	const submitFeaturedMentorHandler = (mentor, pos) => {
		const { isFeatured } = mentor;
		if ((!isNotEmptyObject(mentor.mobileCoverPic) || !isNotEmptyObject(mentor.webCoverPic)) && !isFeatured) {
			showToast('error', '🚫️ Mobile & Web Cover pic is required for a mentor to be featured');
			return;
		}
		const params = {
			id: mentor.id,
			isFeatured: !isFeatured,
			pos,
		};
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			dispatch(setMentorFeatured({ ...params, isEdit: true }));
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(mentorState.mentors)) {
			const structuredRows = createRows(
				mentorState.mentors,
				mentorState.pageNo,
				accessDeepObject('apiParams.limit', mentorState),
				submitHandler,
				submitFeaturedMentorHandler
			);
			setMentorRows(structuredRows);
		} else {
			setMentorRows([]);
		}
	}, [mentorState.mentors]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = mentorState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getMentors());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', mentorState),
					skip: accessDeepObject('apiParams.limit', mentorState) * (pageOfItems - 1),
				})
			);
			dispatch(getMentors());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getMentors());
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8} className="mb-3">
							<h3>Mentors Listing</h3>
						</Col>
						<Col sm={6}>
							<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch} placeholder="Search by Name" />
						</Col>
						<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/mentor/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(mentorRows) && (
						<>
							<DataPaginationTable heads={heads} rows={mentorRows} onSort={onSort} />
							<Pagination
								itemsCount={mentorState.total}
								itemsToShow={accessDeepObject('apiParams.limit', mentorState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{mentorState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default MentorTable;
