import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import history from '../App/history';

export const fetchMembershipData = createAsyncThunk<any, any>('/membership/fetchData', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper('/v1/admin/membership/get-membership-data', {
			method: 'POST',
			body: apiParams,
		});

		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}
		return data;
	} catch (error) {
		showToast('error', 'Something went wrong');
	}
});

export const fetchUserMembershipDetails = createAsyncThunk<any, any>(
	'/membership/fetch-user-membership-data',
	async (apiParams, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/membership/get-user-membership-details?orderId=${apiParams.orderId}`, {
				method: 'GET',
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			return data;
		} catch (error) {
			showToast('error', 'Something went wrong');
		}
	}
);

export const submitMembershipCancellationForm = createAsyncThunk<any, any>(
	'/membership/submit-cancellation-form',
	async (apiParams, { dispatch }) => {
		try {
			const response = await fetchWrapper('/v1/admin/membership/submit-membership-cancellation-form', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;

			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			if (data.status) {
				showToast('success', data.msg);
				history.push('/membership/list');
			} else {
				showToast('error', data.msg);
			}
			return data;
		} catch (error) {
			showToast('error', 'Something went wrong');
		}
	}
);
