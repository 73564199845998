/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import { getSanitizedVideoUrl } from 'containers/Class/helpers';
import { getPlayerInstance } from './helpers';
import useRefCallback from './hooks/useRefCallback';

interface VideoJsPlayerProps {
	videoName?: string;
	videoUrl?: string;
}

let player;

const initPlayer = ({ videoUrl }) => {
	player = getPlayerInstance({ videoTagId: 'my-player' });
	player.src({
		type: videoUrl.includes('.mp4') ? 'video/mp4' : 'application/x-mpegURL',
		src: getSanitizedVideoUrl(videoUrl),
	});
};

const VideoJsPlayer = ({ videoName, videoUrl }: VideoJsPlayerProps) => {
	const { ref: videoRef, setRef: setVideoRef, isRefInitialized } = useRefCallback();
	const isPlayerInitialized = useRef(false);
	const [isFullScreen, setIsFullScreen] = useState(false);

	useEffect(() => {
		document.addEventListener('fullscreenchange', (event) => {
			if (document.fullscreenElement) {
				setIsFullScreen(true);
			} else {
				setIsFullScreen(false);
			}
		});
		return () => {
			if (player) {
				player.dispose();
			}
		};
	}, []);

	useEffect(() => {
		if (videoUrl && videoRef.current && isPlayerInitialized.current === false) {
			initPlayer({ videoUrl });
			isPlayerInitialized.current = true;
		}
	}, [isRefInitialized]);

	useEffect(() => {
		if (videoUrl && videoRef.current && isPlayerInitialized.current === false) {
			initPlayer({ videoUrl });
			isPlayerInitialized.current = true;
		}
	}, [videoUrl]);

	if (!videoUrl) {
		return <Box>Loading...</Box>;
	}

	return (
		<Box d="flex" jc="center" ai="center" direction="column">
			<Box fs="2rem" mb="2rem">
				{videoName}
			</Box>

			<If
				condition={isFullScreen}
				then={<video ref={setVideoRef} className="video-js" style={{ width: '100%', height: '100%' }} id="my-player" playsInline />}
				else={<video ref={setVideoRef} className="video-js" style={{ width: '30rem', height: '20rem' }} id="my-player" playsInline />}
			/>
		</Box>
	);
};

export default VideoJsPlayer;
