import videojs from 'video.js';

/**
 *
 * @param videoTagId The id of the video tag element rendered in dom
 * @returns VideoJsPlayer instance
 */
export const getPlayerInstance = ({ videoTagId = 'my-player' }) =>
	videojs(videoTagId, {
		playbackRates: [0.5, 1, 1.5, 2],
		autoplay: false,
		controls: true,
		loop: false,
		preload: 'auto', // Starts downloading the video as soon as its ready
	});
