import React from 'react';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools';
import { Button, Card, CardBody } from 'reactstrap';

import Input from '../reusableComponents/HookForm/Input';
import SelectField from '../reusableComponents/HookForm/Select';
import Dropzone from '../reusableComponents/HookForm/Dropzone';

const FormTest = () => {
	const { register, handleSubmit, errors, control } = useForm();
	const onSubmit = (values) => console.log(values);

	const options = [
		{ label: 'hello', value: 0 },
		{ label: 'world', value: 1 },
	];

	return (
		<Card>
			<DevTool control={control} />
			<CardBody>
				<form onSubmit={handleSubmit(onSubmit)} className="form ltr-support">
					<Input label="Name" name="fullName" ref={register({ required: true })} error={errors.fullName} isRequired />

					<SelectField label="Type" options={options} name="type" placeholder="Type" isRequired control={control} error={errors.type} />

					<Dropzone label="Dropzone" isRequired name="dropzone" error={errors.dropzone} control={control} />

					<Dropzone
						label="Dropzone Multiple"
						isRequired
						name="dropzoneMultiple"
						error={errors.dropzoneMultiple}
						control={control}
						isMulti
						noOfFiles={4}
					/>

					<Button color="primary" type="submit">
						Submit
					</Button>
				</form>
			</CardBody>
		</Card>
	);
};

export default FormTest;
