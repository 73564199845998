/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { getSkills, setApiParams, setSkillStatus, skillOnSubmit } from '../../skillSlice';
import { isNotEmptyArray, accessDeepObject } from '../../../../utils/commonHelpers';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';

import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import SkillModal from '../SkillModal';
import Filter from '../../../../reusableComponents/Filter';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (skills, pageNo, rowsCount, submitHandler, setModalParams) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < skills.length; i += 1) {
		r.push({
			id: initialCount,
			title: skills[i].title,
			status:
				skills[i].status === STATUS_INACTIVE ? (
					<div className="badge badge-warning">Inactive</div>
				) : (
					<div className="badge badge-success">Active</div>
				),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							setModalParams({ isOpen: true, initialValue: skills[i] });
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							if (skills[i].status === STATUS_ACTIVE) {
								submitHandler({ id: skills[i].id, status: STATUS_INACTIVE, pos: i });
							} else {
								submitHandler({ id: skills[i].id, status: STATUS_ACTIVE, pos: i });
							}
						}}
					>
						Mark {skills[i].status === STATUS_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const CategoryTable = () => {
	const skillState = useSelector(({ skill }) => ({
		loading: skill.loading,
		skills: skill.skills,
		pageNo: skill.pageNo,
		total: skill.total,
		apiParams: skill.apiParams,
	}));

	const dispatch = useDispatch();

	const [skillRows, setSkillRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [modalParams, setModalParams] = useState({ isOpen: false, initialValue: null });
	const [searchTerm, setSearchTerm] = useState(skillState.apiParams.query);

	useEffect(() => {
		dispatch(getSkills());
	}, []);

	useEffect(() => {
		setPageOfItems(skillState.pageNo);
	}, [skillState.pageNo]);

	const heads = [
		{
			accessor: 'title',
			Header: 'Title',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			if (!isUndefined(params.pos)) {
				dispatch(setSkillStatus({ ...params }));
			} else {
				dispatch(skillOnSubmit(params));
				setModalParams({ isOpen: false, initialValue: null });
			}
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(skillState.skills)) {
			const structuredRows = createRows(
				skillState.skills,
				skillState.pageNo,
				accessDeepObject('apiParams.limit', skillState),
				submitHandler,
				setModalParams
			);
			setSkillRows(structuredRows);
		} else {
			setSkillRows([]);
		}
	}, [skillState.skills]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = skillState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getSkills());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', skillState),
					skip: accessDeepObject('apiParams.limit', skillState) * (pageOfItems - 1),
				})
			);
			dispatch(getSkills());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getSkills());
	};

	return (
		<>
			{modalParams.isOpen && (
				<SkillModal
					initialValue={modalParams.initialValue}
					handleSubmit={submitHandler}
					closeModal={() => {
						setModalParams({ isOpen: false, initialValue: null });
					}}
				/>
			)}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={8} className="mb-3">
								<h3>Skills Listing</h3>
							</Col>
							<Col sm={6}>
								<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch} placeholder="Search by Title" />
							</Col>
							<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
								<Button
									style={customStylesMargin}
									size="sm"
									color="primary"
									onClick={() => {
										setModalParams({ initialValue: null, isOpen: true });
									}}
								>
									Add
								</Button>
							</Col>
						</Row>
						{isNotEmptyArray(skillRows) && (
							<>
								<DataPaginationTable heads={heads} rows={skillRows} onSort={onSort} />
								<Pagination
									itemsCount={skillState.total}
									itemsToShow={accessDeepObject('apiParams.limit', skillState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{skillState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default CategoryTable;
