import { takeLatest } from 'redux-saga/effects';

import { getBlog, getBlogs, getBlogTags, createBlogTag, getBlogId, onSubmit } from './blogSlice';
import { getBlogSaga } from './sagas/getBlogSaga';
import { getBlogsSaga } from './sagas/getBlogsSaga';
import { createBlogTagSaga } from './sagas/createBlogTagSaga';
import { getBlogTagsSaga } from './sagas/getBlogTagsSaga';
import { getBlogIdSaga } from './sagas/getBlogIdSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';

export default function* blogSaga() {
	yield takeLatest(getBlog.type, getBlogSaga);
	yield takeLatest(getBlogs.type, getBlogsSaga);
	yield takeLatest(getBlogTags.type, getBlogTagsSaga);
	yield takeLatest(createBlogTag.type, createBlogTagSaga);
	yield takeLatest(getBlogId.type, getBlogIdSaga);
	yield takeLatest(onSubmit.type, onSubmitSaga);
}
