import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Membership from 'containers/Membership';
import LearningActivities from 'containers/LearningActivities';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Authentication/LogIn';
import Blogs from '../Blog';
import WebsiteBlog from '../WebsiteBlog';
import FAQ from '../FAQ';
import Category from '../Category';
import Class from '../Class';
import Program from '../Program';
import Experiment from '../Experiment';
import Batch from '../Batch';
import DIY from '../DIY';
import Mentor from '../Mentor';
import Register from '../Authentication/Register';
import ForgotPassword from '../Authentication/ForgotPassword';
import ResetPassword from '../Authentication/ResetPassword';
import NotFound from '../NotFound';
import Dashboard from '../Dashboard';
import Users from '../Users';
import Gallery from '../Gallery';
import Video from '../Video';
import Exercise from '../Exercise';
import Whatsapp from '../Whatsapp';
import Skill from '../Skill';
import WhatsappChats from '../WhatsappChats';
import Careers from '../Careers';
import FormTest from '../FormTest';
import SuccessStory from '../SuccessStory';
import School from '../School';
import About from '../About';
import BatchClass from '../BatchClass';
import UserPost from '../UserPost';
import FeaturedPost from '../FeaturedPost';
import ReferralCode from '../ReferralCode';
import Report from '../Report';
import Certificate from '../Certificate';
import Enrollments from '../Enrollment';
import Shorts from '../Shorts';
import Coach from '../Coach';
import Quiz from '../Quiz';
import ImageViewer from '../ImageViewer';
import DevSettings from '../DevSettings';
import history from './history';

const AuthenticatedRoutes = () => {
	const currentPath = history.location.pathname;
	return (
		<MainWrapper>
			<main>
				<div>
					{currentPath !== '/image-viewer' && <Layout />}
					<div className="container__wrap">
						<Switch>
							<Route path="/" exact component={Dashboard} />
							<Route path="/users" component={Users} />
							<Route path="/blogs" component={Blogs} />
							<Route path="/website-blog" component={WebsiteBlog} />
							<Route path="/faq" component={FAQ} />
							<Route path="/category" component={Category} />
							<Route path="/skill" component={Skill} />
							<Route path="/class" component={Class} />
							<Route path="/program" component={Program} />
							<Route path="/batch" component={Batch} />
							<Route path="/school" component={School} />
							<Route path="/diy" component={DIY} />
							<Route path="/mentor" component={Mentor} />
							<Route path="/gallery" component={Gallery} />
							<Route path="/video" component={Video} />
							<Route path="/exercise" component={Exercise} />
							<Route path="/shorts" component={Shorts} />
							<Route path="/whatsapp" component={Whatsapp} />
							<Route path="/whatsapp-chats" component={WhatsappChats} />
							<Route path="/careers" component={Careers} />
							<Route path="/success-stories" component={SuccessStory} />
							<Route path="/about-us" component={About} />
							<Route path="/batch-class" component={BatchClass} />
							<Route path="/user-post" component={UserPost} />
							<Route path="/featured-post" component={FeaturedPost} />
							<Route path="/ref-code" component={ReferralCode} />
							<Route path="/report" component={Report} />
							<Route path="/enrollments" component={Enrollments} />
							<Route path="/certificates" component={Certificate} />
							<Route path="/dashboard" component={Dashboard} />
							<Route path="/experiment" component={Experiment} />
							<Route path="/test" component={FormTest} />
							<Route path="/activity" component={Quiz} />
							<Route path="/membership" component={Membership} />
							<Route path="/image-viewer" component={ImageViewer} />
							<Route path="/learning-activities" component={LearningActivities} />
							<Route path="/dev-settings" component={DevSettings} />
							<Route exact path="/404" component={NotFound} />
							<Redirect to="/404" />
						</Switch>
					</div>
				</div>
			</main>
		</MainWrapper>
	);
};

const UnauthenticatedRoutes = () => (
	<MainWrapper>
		<main>
			<Switch>
				<Route exact path="/" component={LogIn} />
				<Route exact path="/login" component={LogIn} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/forgot-password" component={ForgotPassword} />
				<Route exact path="/reset-password/:token" component={ResetPassword} />
				<Redirect to="/login" />
			</Switch>
		</main>
	</MainWrapper>
);

const Router = (props) => {
	return props.user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

const mapDispatchToProps = () => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(Router);
