export const PlanToPriceItems = [
	{
		label: '30 days',
		value: 30,
		amount: 699,
	},
	{
		label: '90 days',
		value: 90,
		amount: 1800,
	},
	{
		label: '180 days',
		value: 180,
		amount: 3000,
	},
	{
		label: '365 days',
		value: 365,
		amount: 4800,
	},
	{
		label: 'Custom plan duration',
		value: 'custom',
	},
];

export const MAX_PROFILES_PER_USER = 4;
