import React, { useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import StarRatings from 'react-star-ratings';

import { isNotEmptyArray, accessDeepObject, showToast, omitWrapper } from 'utils/commonHelpers';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';

import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import If from 'reusableComponents/If';
import { TableCoverPicture } from 'components/TableCoverPicture';
import { format } from 'date-fns';
import Box from 'reusableComponents/Box';
import { setApiParams } from '../../programSlice';
import { fetchProgramFeedbacks, updateProgram } from '../../actions/program.actions';

const Actions = ({ feedback, selectedReviews, setSelectedReviews }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				{[...Array(5)].map((_, index) => (
					<DropdownItem
						disabled={!!selectedReviews[index]}
						onClick={() => {
							const newReviews = [...selectedReviews];
							newReviews[index] = feedback;
							setSelectedReviews(newReviews);
						}}
					>
						Mark {index + 1}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ programFeedbacks, selectedReviews, setSelectedReviews }) {
	return programFeedbacks.map((feedback) => {
		const { id, profilePic, rating, fullName, feedbackMsg, createdAt, mobile } = feedback;

		const isReviewSelected = selectedReviews.filter((review) => review?.id === id).length > 0;

		return {
			id,
			profilePic: <TableCoverPicture {...{ coverPicture: profilePic, title: fullName }} />,
			fullName: fullName || '---',
			mobile: mobile || '_',
			rating: rating || 0,
			feedbackMsg: feedbackMsg || '---',
			createdAt: createdAt ? format(new Date(createdAt), 'dd-MM-yyyy HH:mm') : '---',
			backgroundColor: isReviewSelected ? 'lightgrey' : 'white',
			actions: !isReviewSelected && (
				<Actions
					{...{
						feedback,
						selectedReviews,
						setSelectedReviews,
					}}
				/>
			),
		};
	});
}

const heads = [
	{ accessor: 'profilePic', Header: 'Profile Pic' },
	{ accessor: 'fullName', Header: 'Name' },
	{ accessor: 'mobile', Header: 'Mobile' },
	{ accessor: 'rating', Header: 'Rating' },
	{ accessor: 'feedbackMsg', Header: 'Feedback' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const ProgramReviewTable = ({ program, featuredFeedbacks }) => {
	const programState = useSelector(({ program, auth }) => ({
		loading: program.loading,
		programFeedbacks: program.programFeedbacks,
		pageNo: program.pageNo,
		total: program.total,
		apiParams: program.apiParams,
		isSubmitting: program.isSubmitting,
		loggedInUser: auth.user,
	}));

	const programId = program.id;

	const { programFeedbacks, isSubmitting } = programState;

	const dispatch = useDispatch();

	const [programFeedbackRows, setProgramFeedbackRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [selectedReviews, setSelectedReviews] = useState([...Array(5)]);

	React.useEffect(() => {
		if (featuredFeedbacks.length) setSelectedReviews(featuredFeedbacks);
		dispatch(setApiParams({ limit: 20, skip: 0, entityType: 'PROGRAM', entityId: programId, sort: { rating: -1, feedbackMsgLength: -1 } }));

		return () => {
			dispatch(setApiParams({ entityId: null }));
		};
	}, []);

	React.useEffect(() => {
		setPageOfItems(programState.pageNo);
	}, [programState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(programFeedbacks)) {
			const structuredRows = createRows({ programFeedbacks, selectedReviews, setSelectedReviews });
			setProgramFeedbackRows(structuredRows);
		} else {
			setProgramFeedbackRows([]);
		}
	}, [programFeedbacks, selectedReviews]);

	React.useEffect(() => {
		if (programState.apiParams.entityId) dispatch(fetchProgramFeedbacks({ ...programState.apiParams }));
	}, [programState.apiParams]);

	const onChangePage = (itemsPage) => {
		const {
			apiParams: { sort, limit, entityType, entityId },
		} = programState;
		const skip = limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setApiParams({ sort, limit, skip, entityId, entityType }));
		}
	};

	const submitFeedbacks = () => {
		if (selectedReviews.includes(undefined)) showToast('error', 'Please select 5 reviews!');
		else
			dispatch(
				updateProgram({
					...omitWrapper(['id', 'certificate'], program),
					certificateImage: program?.certificate?.image,
					isCertificateEnabled: program?.certificate?.isEnabled,
					_id: programId,
					updatedBy: programState.loggedInUser.id,
					featuredFeedbacks: selectedReviews.map((review) => ({ id: review.id })),
				})
			);
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Saving Selected Program Reviews...">
			<If
				condition={isNotEmptyArray(programFeedbackRows)}
				then={
					<Box d="flex" w="100%" mh="100px">
						{selectedReviews.map((review, index) => (
							<Box pos="relative" d="flex" ai="center" stretch="1" m="0 10px" p="0 5px" bor="1px solid grey" bRad="10px">
								{review && (
									<>
										<TableCoverPicture {...{ coverPicture: review.profilePic, title: review.fullName }} />
										<Box ml="5px">
											<p style={{ fontWeight: 'bold' }}>{review.fullName}</p>
											<StarRatings
												rating={review.rating}
												starRatedColor="orange"
												numberOfStars={5}
												starDimension="15px"
												starSpacing="3px"
											/>
											<p style={{ margin: 0 }}>{review.feedbackMsg || '- - -'}</p>
										</Box>
										<Box pos="absolute" r="0" t="0">
											<span
												className="lnr lnr-cross"
												style={{ cursor: 'pointer', marginBottom: '4px', display: 'inline-block', marginLeft: '10px' }}
												onClick={() => {
													selectedReviews[index] = undefined;
													setSelectedReviews([...selectedReviews]);
												}}
											/>
										</Box>
									</>
								)}
							</Box>
						))}
					</Box>
				}
			/>
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<If
							condition={isNotEmptyArray(programFeedbackRows)}
							then={
								<>
									<DataPaginationTable heads={heads} rows={programFeedbackRows} onSort={() => null} />
									<Pagination
										itemsCount={programState.total}
										itemsToShow={accessDeepObject('apiParams.limit', programState)}
										pageOfItems={pageOfItems}
										onChangePage={onChangePage}
									/>
								</>
							}
							else={<div className="">No data</div>}
						/>
					</CardBody>
				</Card>
			</Col>
			<If
				condition={isNotEmptyArray(programFeedbackRows)}
				then={
					<Col sm={{ size: 12, offset: 10 }} className="mt-4">
						<Button size="sm" color="primary" onClick={submitFeedbacks}>
							Save
						</Button>
					</Col>
				}
			/>
		</LoadingOverlay>
	);
};
