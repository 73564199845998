import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { compose } from 'redux';
import Box from 'reusableComponents/Box';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { fetchWrapper } from 'utils/fetchWrapper';
import theme from 'utils/theme';
import styled from 'styled-components';
import { showToast } from 'utils/commonHelpers';
import CustomModal from '../../../reusableComponents/CustomModal';

const customStyles = `
	width: 80vh;

	@media ${theme.device.md} {
		width: 80vh;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

const StyledModal = styled.div`
	width: 60vw;
	max-height: 90vh;

	padding: 1rem;

	input {
		border: 1px solid #dddddd;
	}
	overflow-y: scroll;
`;

const GradePromotionModal = ({ userId, loggedInUser, closeModal }) => {
	const [profiles, setProfiles] = useState([]);

	const hookFormMethods = useForm();

	const loadProfiles = useCallback(async () => {
		try {
			const profilesResponse = await fetchWrapper(`/v1/admin/profiles?parentId=${userId}`, { method: 'GET' });

			const profiles = profilesResponse.data;
			if (profiles) {
				setProfiles(profiles);
			}
		} catch (error) {
			showToast('error', 'Something went wrong');
		}
	}, [userId]);

	const validProfiles = useMemo(() => {
		return profiles.reduce((acc, profile) => {
			if (Number.isInteger(profile.academicClass)) {
				acc.push({
					label: `${profile.fullName ?? "Profile doesn't have a name"}: Grade - ${profile.academicClass}`,
					value: profile.id,
				});
			}
			return acc;
		}, []);
	}, [profiles]);

	useEffect(() => {
		loadProfiles();
	}, [userId]);

	const onSubmit = useCallback(async (data) => {
		const { profileId } = data;
		try {
			const promoteGradeResponse = await fetchWrapper('/v1/admin/promote-grade', {
				method: 'POST',
				body: {
					profileId: profileId.value,
					gradePromotedBy: loggedInUser.id,
				},
			});
			if (promoteGradeResponse.status === 200) {
				if (promoteGradeResponse.data.status) {
					return showToast('success', 'Grade promoted successfully!');
				}
				throw promoteGradeResponse.data.msg;
			}
			throw promoteGradeResponse.errors?.error;
		} catch (err) {
			const error = err ?? err?.message;
			console.log('err', err);
			if (typeof error === 'string') showToast('error', error);
			else showToast('error', 'Failed to Promote Grade. Please try again!');
		}
	}, []);

	return <Component closeModal={closeModal} hookFormMethods={hookFormMethods} onSubmit={onSubmit} validProfiles={validProfiles} />;
};

const Component = ({
	closeModal,
	hookFormMethods,
	onSubmit,
	validProfiles,
}: {
	closeModal: () => void;
	hookFormMethods: UseFormMethods;
	onSubmit: (data: any) => void;
	validProfiles: [{ key: string; value: string }];
}) => {
	return (
		// @ts-ignore
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Card>
					<CardBody>
						<Box d="flex" ai="center" jc="space-between">
							<Box as="h3" mb="1rem">
								Grade Promotion
							</Box>
							<Box
								mb="1rem"
								cursor="pointer"
								fs="bold"
								pl="0.6rem"
								pr="0.6rem"
								pt="0.4rem"
								pb="0.4rem"
								bor="1px solid grey"
								onClick={() => closeModal()}
							>
								X
							</Box>
						</Box>
						<FormProvider {...hookFormMethods}>
							<form onSubmit={hookFormMethods.handleSubmit(onSubmit)} className="form ltr-support">
								<Box w="100%" mt="1rem">
									<SelectFieldNew label="Select Profile" name="profileId" options={validProfiles} required />
								</Box>
								<Box w="100%" mt="1rem">
									<Button type="submit" size="sm" color="primary">
										Promote Grade
									</Button>
								</Box>
							</form>
						</FormProvider>
					</CardBody>
				</Card>
			</StyledModal>
		</CustomModal>
	);
};

const withConnect = connect();

export default compose(withConnect)(GradePromotionModal);
