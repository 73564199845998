import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { BatchAddEditForm } from './components/BatchAddEditForm';

import BatchTable from './components/BatchTable';

const Batch = () => {
	return (
		<div>
			<Switch>
				<Route path="/batch/list" component={BatchTable} />
				<Route path="/batch/add-edit" component={BatchAddEditForm} />
			</Switch>
		</div>
	);
};

export default Batch;
