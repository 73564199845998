import { call, put } from 'redux-saga/effects';

import { requestSaga } from 'utils/requestSaga';
import { actionSpreader, pickWrapper, stringifyQueryParams } from 'utils/commonHelpers';
import { setConversationsLoading, getAllWhatsappConversationsSuccess } from '../whatsappChatSlice';

export default function* getAllWhatsappConversationSaga({ payload }) {
	yield put(actionSpreader(setConversationsLoading.type, { isConversationsLoading: true }));
	const modifiedParams = {
		...pickWrapper(['limit', 'skip', 'updatedAt', 'threadStatus', 'conversationId', 'assignedTo'], payload),
	};

	const res = yield call(
		requestSaga,
		'GET',
		`${process.env.REACT_APP_API_URL}/v1/all-whatsapp-conversations${stringifyQueryParams(modifiedParams)}`
	);
	if (res.status === 200) {
		yield put(
			actionSpreader(getAllWhatsappConversationsSuccess.type, {
				conversations: res.data.users,
				totalConversations: res.data.count,
				isLoadMoreCall: payload.isLoadMoreCall,
			})
		);
	} else if (res.status === 204) {
		yield put(
			actionSpreader(getAllWhatsappConversationsSuccess.type, {
				conversations: [],
				totalConversations: 0,
			})
		);
	} else {
		// yield put(actionSpreader(setErrors.type, { errors: res.data }));
	}
}
