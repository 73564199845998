import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
	getPickedPostAnalytics,
	getPendingPost,
	getUserPostAnalytics,
	getUserPosts,
	updateUserPostAndUpdateList,
} from './actions/userPost.actions';

const userPostSlice = createSlice({
	name: 'userPost',
	initialState: {
		loading: false,
		posts: [],
		postAnalytics: {
			pickedPosts: [],
			approvedTodayCount: 0,
			totalPendingCount: 0,
			rejectedTodayCount: 0,
			createdTodayCount: 0,
			myPickedPostsCount: 0,
			videoFeedApprovedTodayCount: 0,
			videoFeedRejectedTodayCount: 0,
			videoFeedUndecidedTodayCount: 0,
			photoFeedApprovedTodayCount: 0,
			photoFeedRejectedTodayCount: 0,
			photoFeedUndecidedTodayCount: 0,
			videoFeedApprovedTotalCount: 0,
			videoFeedRejectedTotalCount: 0,
			videoFeedUndecidedTotalCount: 0,
			photoFeedApprovedTotalCount: 0,
			photoFeedRejectedTotalCount: 0,
			photoFeedUndecidedTotalCount: 0,
			totalImagePostsCreatedToday: 0,
			totalVideoPostsCreatedToday: 0,
			postsApprovedTodayByAdmin: 0,
			postsRejectedTodayByAdmin: 0,
			imagePostsWithShowOnFeedMarkedYesToday: 0,
			imagePostsWithShowOnFeedMarkedNoToday: 0,
			videoPostsWithShowOnFeedMarkedYesToday: 0,
			videoPostsWithShowOnFeedMarkedNoToday: 0,
		},
		pendingPost: {} as any,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 10,
			skip: 0,
			status: null,
			handledBy: null,
			query: null,
			userQuery: null,
			mediaType: null,
			source: null,
			showOnFeed: false,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		clearPosts: (state) => {
			state.posts = [];
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.userQuery !== undefined && { userQuery: action.payload.userQuery }),
				...(action.payload.status !== undefined && { status: action.payload.status }),
				...(action.payload.mediaType !== undefined && { mediaType: action.payload.mediaType }),
				...(action.payload.source !== undefined && { source: action.payload.source }),
				...(action.payload.handledBy && { handledBy: action.payload.handledBy }),
				...('showOnFeed' in action.payload && { showOnFeed: action.payload.showOnFeed }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getUserPosts.fulfilled as any]: (state, action) => {
			const {
				payload: { posts, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.posts = posts;
			state.total = total;
			state.pageNo = page + 1;
		},
		[getPendingPost.fulfilled as any]: (state, action) => {
			const {
				payload: { pendingPost },
			} = action;
			state.pendingPost = pendingPost;
		},
		[updateUserPostAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { postIndex, postData },
			} = action;
			state.posts[postIndex] = { ...state.posts[postIndex], ...postData };
		},
		[getUserPostAnalytics.fulfilled as any]: (state, action) => {
			const {
				payload: { postAnalytics },
			} = action;
			state.postAnalytics = postAnalytics;
		},
		[getPickedPostAnalytics.fulfilled as any]: (state, action) => {
			const {
				payload: { postAnalytics },
			} = action;
			state.postAnalytics = { ...state.postAnalytics, ...postAnalytics };
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting, clearPosts } = userPostSlice.actions;

export default userPostSlice.reducer;
