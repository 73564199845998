import { createSlice } from '@reduxjs/toolkit';
import { pickWrapper } from 'utils/commonHelpers';
// eslint-disable-next-line import/no-cycle
import { getShort, getShorts, getShortVideoStatus, updateShortAndUpdateList } from './actions/shorts.actions';

const shortSlice = createSlice({
	name: 'shorts',
	initialState: {
		loading: false,
		shorts: [],
		short: {} as any,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		isRefreshing: {},
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			status: undefined,
			searchTerm: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setIsRefreshing: (state, action) => {
			state.isRefreshing[action.payload.dropboxPath] = action.payload.value;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.status !== undefined && { status: action.payload.status }),
				...(action.payload.searchTerm !== undefined && { searchTerm: action.payload.searchTerm }),
			};
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getShorts.fulfilled as any]: (state, action) => {
			const {
				payload: { shorts, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.shorts = shorts;
			state.total = total;
			state.pageNo = page + 1;
		},
		[getShort.fulfilled as any]: (state, action) => {
			state.short = action.payload;
		},
		[updateShortAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { shortId, shortData },
			} = action;
			const index = state.shorts.findIndex((item) => item.id === shortId);
			state.shorts[index] = {
				...pickWrapper(['videoName', 'hlsStreamStatus', 'dropboxPath', 'videoDesc'], state.shorts[index]),
				...shortData,
			};
		},
		[getShortVideoStatus.fulfilled as any]: (state, action) => {
			const hlsStreamStatus = action.payload.hlsStreamStatus || action.payload.videoAttributes.hlsStreamStatus;
			const dropboxPath = action.payload.dropboxPath || action.payload.videoAttributes.dropboxPath;
			state.shorts = state.shorts.map((short) => {
				if (short.dropboxPath === dropboxPath) {
					return { ...short, hlsStreamStatus };
				}
				return short;
			});
		},
	},
});

export const { toggleLoading, setApiParams, toggleIsSubmitting, setIsRefreshing } = shortSlice.actions;

export default shortSlice.reducer;
