import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ReferralCodeTable } from './components/ReferralCodeTable';
import { ReferralCodeAddEditForm } from './components/ReferralCodeAddEditForm';

const ReferralCode = () => {
	return (
		<div>
			<Switch>
				<Route path="/ref-code/list" component={ReferralCodeTable} />
				<Route path="/ref-code/add-edit" component={ReferralCodeAddEditForm} />
			</Switch>
		</div>
	);
};

export default ReferralCode;
