/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import { PostMedia } from 'components/PostMedia';
import { format } from 'date-fns';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import Filter from 'reusableComponents/Filter';
import { SelectField } from 'reusableComponents/Form/Select';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';
import PaginationNew from 'reusableComponents/Pagination/PaginationNew';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import {
	accessDeepObject,
	isNotEmptyArray,
	ObjectMaybe,
	parseQueryParams,
	showToast,
	startsWithDigit,
	stringifyQueryParams,
} from 'utils/commonHelpers';
import { getPhoneNoRegex } from 'utils/constants';
import { getUserPosts, updateUserPostAndUpdateList } from '../actions/userPost.actions';
import { clearPosts, setApiParams } from '../userPostSlice';
import FeedbackModal from './FeedbackModal';
import PickedPostModal from './PickedPostModal';
import PostsCountModal from './PostsCountModal';
import VideoPreviewModel from './VideoPreviewModel';

const excludedSortColumns = ['media', 'caption', 'status', 'starRating', 'feedbackText', 'user', 'showOnFeed', 'actions'];

const statusList = [
	{ label: 'Pending', value: 'PENDING' },
	{ label: 'Picked', value: 'PICKED' },
	{ label: 'Approved', value: 'APPROVED' },
	{ label: 'Bot Approved', value: 'BOT_APPROVED' },
	{ label: 'Deleted', value: 'DELETED' },
	{ label: 'Rejected', value: 'REJECTED' },
];

const showOnFeedList = [
	{ label: 'Show On Feed', value: true },
	{ label: 'Hide From Feed', value: false },
	{ label: 'Not Decided', value: 'NOT_DECIDED' },
];

const showOnFeedOptions = [
	{ label: 'Yes', value: 'Yes' },
	{ label: 'No', value: 'No' },
];

const mediaTypeOptions = [
	{ label: 'IMAGE', value: 'IMAGE' },
	{ label: 'VIDEO', value: 'VIDEO' },
];

const sourceTypeOptions = [
	{ label: 'Quick Share', value: 'GAMIFICATION_POPUP' },
	{ label: 'Create Post', value: 'CREATE_POST' },
	{ label: 'None', value: 'NONE' },
];

const mapSourceKeyToValue = (source) => {
	switch (source) {
		case 'NOT_DECIDED':
			return 'NOT_DECIDED';
		case 'true':
			return true;
		case 'false':
			return false;
		default:
			return null;
	}
};

const Actions = ({ postId, index, status, feedbackText, starRating, childName, setFeedbackModalParams, loggedInUser }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				{status === 'PENDING' || status === 'PICKED' ? (
					<>
						<DropdownItem
							onClick={() =>
								dispatch(
									updateUserPostAndUpdateList({
										postId,
										postIndex: index,
										status: 'APPROVED',
										statusUpdatedAt: new Date().toISOString(),
										pickedBy: loggedInUser.id,
									})
								)
							}
						>
							Mark as Approved
						</DropdownItem>
						<DropdownItem
							onClick={() =>
								dispatch(
									updateUserPostAndUpdateList({
										postId,
										postIndex: index,
										status: 'REJECTED',
										statusUpdatedAt: new Date().toISOString(),
										pickedBy: loggedInUser.id,
									})
								)
							}
						>
							Mark as Rejected
						</DropdownItem>
					</>
				) : (
					<>
						<DropdownItem
							onClick={() =>
								dispatch(
									updateUserPostAndUpdateList({
										postId,
										postIndex: index,
										status: status === 'APPROVED' ? 'REJECTED' : 'APPROVED',
										statusUpdatedAt: new Date().toISOString(),
										pickedBy: loggedInUser.id,
									})
								)
							}
						>
							{status === 'APPROVED' ? 'Mark as Rejected' : 'Mark as Approved'}
						</DropdownItem>
						<DropdownItem
							onClick={() =>
								setFeedbackModalParams({ isOpen: true, initialValues: { feedbackText, starRating, postId, postIndex: index, childName } })
							}
							disabled={status !== 'APPROVED'}
						>
							Edit Feedback
						</DropdownItem>
					</>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

interface UserPostTableProps {
	location: { search: { page?: string } };
}

export const UserPostTable = (props: UserPostTableProps) => {
	const [feedbackModalParams, setFeedbackModalParams] = useState({ isOpen: false, initialValues: null });
	const [mentorModalParams, setMentorModalParams] = useState({ isOpen: false, initialValues: null });
	const [isPostsCountModalOpen, setIsPostsCountModalOpen] = useState(false);
	const [isVideoPreviewModalOpen, setIsVideoPreviewModalOpen] = useState({ isOpen: false, videoUrl: '' });

	const userPostState = useSelector(({ userPost }) => ({
		loading: userPost.loading,
		posts: userPost.posts,
		total: userPost.total,
		postAnalytics: userPost.postAnalytics,
		pageNo: userPost.pageNo,
		apiParams: userPost.apiParams,
	}));

	const { pickedPosts } = userPostState.postAnalytics;

	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	const dispatch = useDispatch();

	const [userPostRows, setUserPostRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [userSearchTerm, setUserSearchTerm] = React.useState(userPostState.apiParams.userQuery);

	useEffect(() => {
		const { page, mediaType, source, status, showOnFeed, userQuery } = ObjectMaybe(parseQueryParams(props.location.search));
		if (+page !== 1) {
			dispatch(
				setApiParams({
					...userPostState.apiParams,
					skip: (+page - 1) * userPostState.apiParams.limit,
					mediaType,
					source: source === '' ? null : source,
					status,
					userQuery,
					showOnFeed: mapSourceKeyToValue(showOnFeed),
				})
			);
		} else {
			dispatch(
				setApiParams({
					...userPostState.apiParams,
					mediaType,
					source: source === '' ? null : source,
					status,
					userQuery,
					showOnFeed: mapSourceKeyToValue(showOnFeed),
				})
			);
		}
		setUserSearchTerm(userQuery);
	}, []);

	const ShowFeedRadioButtons = ({ id, index, showOnFeed, handledBy }) => {
		const hookFormMethods = useForm();

		const { watch } = hookFormMethods;
		const showOnFeedValue = watch('showOnFeed');

		useEffect(() => {
			const valueToUpdate = showOnFeedValue === 'Yes';
			if (!isUndefined(showOnFeedValue) && valueToUpdate !== showOnFeed) {
				dispatch(
					updateUserPostAndUpdateList({
						postId: id,
						postIndex: index,
						showOnFeed: valueToUpdate,
						feedStatusUpdatedBy: loggedInUser.id,
						feedStatusUpdatedAt: new Date().toISOString(),
						handledBy,
					})
				);
			}
		}, [showOnFeedValue]);

		useEffect(() => {
			if (!isUndefined(showOnFeed)) hookFormMethods.reset({ showOnFeed: showOnFeed ? 'Yes' : 'No' });
		}, [showOnFeed]);

		return (
			<FormProvider {...hookFormMethods}>
				<form style={{ width: 100 }}>
					<RadioGroup label="" name="showOnFeed" fields={showOnFeedOptions} />
				</form>
			</FormProvider>
		);
	};

	function createRows({ posts, setFeedbackModalParams, setIsVideoPreviewModalOpen }) {
		return posts.map(
			({ id, caption, createdAt, media, status, user, starRating, showOnFeed, profiles, feedbackText, admin, handledBy }, index) => ({
				id,
				media: <PostMedia {...{ media, setIsVideoPreviewModalOpen, isUserPostListing: true }} />,
				caption: <Box w="5rem">{caption}</Box>,
				status: `${status} ${admin ? `(${admin.fullName})` : ''}`,
				starRating: starRating || '---',
				feedbackText: <div style={{ width: 100 }}>{feedbackText || '---'}</div>,
				user: user?.fullName,
				createdAt: format(new Date(createdAt), 'dd-MM-yyyy HH:mm'),
				showOnFeed: <ShowFeedRadioButtons id={id} index={index} showOnFeed={showOnFeed} handledBy={handledBy} />,
				actions: (
					<Actions
						{...{
							postId: id,
							status,
							index,
							feedbackText,
							starRating,
							childName: profiles?.[0]?.fullName,
							setFeedbackModalParams,
							loggedInUser,
						}}
					/>
				),
			})
		);
	}

	const heads = [
		{ accessor: 'media', Header: 'Post Media' },
		{ accessor: 'caption', Header: 'Post Caption' },
		{ accessor: 'status', Header: 'Status' },
		{ accessor: 'starRating', Header: 'Star Rating' },
		{ accessor: 'feedbackText', Header: 'Feedback' },
		{ accessor: 'user', Header: 'Posted By' },
		{ accessor: 'showOnFeed', Header: 'Show On Feed' },
		{ accessor: 'createdAt', Header: 'Created At' },
		{ accessor: 'actions', Header: 'Actions' },
	];

	React.useEffect(() => {
		if (
			userPostState.apiParams.userQuery ||
			userPostState.apiParams.status ||
			userPostState.apiParams.mediaType ||
			userPostState.apiParams.source ||
			'showOnFeed' in userPostState.apiParams
		) {
			dispatch(getUserPosts(userPostState.apiParams));
		}
		window.history.pushState(
			{},
			null,
			`${window.location.origin}${window.location.pathname}${stringifyQueryParams({
				page: userPostState.apiParams.skip / userPostState.apiParams.limit + 1,
				mediaType: userPostState.apiParams.mediaType,
				source: userPostState.apiParams.source === '' ? null : userPostState.apiParams.source,
				status: userPostState.apiParams.status,
				showOnFeed: userPostState.apiParams.showOnFeed,
				userQuery: userPostState.apiParams.userQuery,
			})}`
		);
	}, [userPostState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(userPostState.pageNo);
	}, [userPostState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(userPostState.posts)) {
			const structuredRows = createRows({ posts: userPostState.posts, setFeedbackModalParams, setIsVideoPreviewModalOpen });
			setUserPostRows(structuredRows);
		} else {
			setUserPostRows([]);
		}
	}, [userPostState.posts]);

	const submitHandler = (params) => {
		/* eslint-disable no-restricted-globals, no-alert */
		const isConfirmed = confirm('Are you sure?');
		if (isConfirmed) {
			dispatch(updateUserPostAndUpdateList({ ...params, adminUserId: loggedInUser.id }));
			setFeedbackModalParams({ isOpen: false, initialValues: null });
		}
	};

	const onChangePage = (skip) => {
		const { apiParams } = userPostState;

		const newParams = {
			...apiParams,
			sortKey: apiParams.sortKey,
			sortOrder: apiParams.sortOrder,
			limit: apiParams.limit,
			skip,
			status: apiParams.status,
		};

		dispatch(setApiParams(newParams));
	};

	const onSort = (sortColumn, sortDirection) => {
		if (!excludedSortColumns.includes(sortColumn) && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			const newParams = {
				...userPostState.apiParams,
				sortKey: sortColumn,
				sortOrder,
				limit: accessDeepObject('apiParams.limit', userPostState),
				skip: accessDeepObject('apiParams.limit', userPostState) * (pageOfItems - 1),
				status: accessDeepObject('apiParams.status', userPostState),
			};
			dispatch(setApiParams(newParams));
		}
	};

	const onFilterChange = (key, val) => {
		const { apiParams } = userPostState;
		let newParams: {
			sortKey: string;
			sortOrder: string;
			limit: number;
			skip: number;
			status?: string;
			userQuery: string;
			handledBy?: 'ADMIN' | 'BOT';
		} = {
			sortKey: apiParams.sortKey,
			sortOrder: apiParams.sortOrder,
			limit: apiParams.limit,
			skip: apiParams.skip,
			userQuery: userSearchTerm?.trim(),
		};
		if (key === 'status' && val === 'BOT_APPROVED') {
			newParams = {
				...newParams,
				status: 'APPROVED',
				handledBy: 'BOT',
			};
		} else {
			newParams = {
				...newParams,
				[key]: val,
			};
		}
		dispatch(setApiParams(newParams));
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();

		if (startsWithDigit(userSearchTerm))
			return showToast('error', 'Please enter the country code when searching by mobile number like +91-988..');
		if (!getPhoneNoRegex({ isHyphenMandatory: true }).test(userSearchTerm)) return showToast('error', 'Please enter complete phone number');

		const { apiParams } = userPostState;
		const query = userSearchTerm ? userSearchTerm.trim() : null;
		const newParams = {
			userQuery: query,
			limit: apiParams.limit,
			skip: apiParams.skip,
			status: apiParams.status,
			sortKey: 'createdAt',
			sortOrder: '-1',
		};
		dispatch(setApiParams(newParams));
		if (!query) dispatch(clearPosts());
	};

	return (
		<>
			{isVideoPreviewModalOpen.isOpen && (
				<VideoPreviewModel
					initialValues={isVideoPreviewModalOpen.videoUrl}
					closeModal={() => {
						setIsVideoPreviewModalOpen({ isOpen: false, videoUrl: null });
					}}
				/>
			)}
			{feedbackModalParams.isOpen && (
				<FeedbackModal
					initialValues={feedbackModalParams.initialValues}
					handleSubmit={submitHandler}
					closeModal={() => {
						setFeedbackModalParams({ isOpen: false, initialValues: null });
					}}
				/>
			)}
			{mentorModalParams.isOpen && (
				<PickedPostModal
					closeModal={() => {
						setMentorModalParams({ isOpen: false, initialValues: null });
					}}
				/>
			)}
			{isPostsCountModalOpen && <PostsCountModal closeModal={() => setIsPostsCountModalOpen(false)} />}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={7}>
								<h3>User Posts Listing</h3>
							</Col>
							<Col>
								<Box d="flex">
									<Button style={{ marginBottom: '0px' }} size="sm" color="primary" onClick={() => setIsPostsCountModalOpen(true)}>
										Posts Analysis
									</Button>
									<Button
										style={{ marginBottom: '0px' }}
										size="sm"
										color="primary"
										onClick={() => {
											setMentorModalParams({
												isOpen: true,
												initialValues: pickedPosts,
											});
										}}
									>
										Picked Post Count
									</Button>
								</Box>
							</Col>
						</Row>
						<Row className="mt-1 mb-4 rounded">
							<Col sm="4">
								<Filter
									searchTerm={userSearchTerm}
									setSearchTerm={setUserSearchTerm}
									onSearch={onSearch}
									placeholder="Search post by user phone number"
								/>
							</Col>
							<Col sm={4}>
								<SelectField
									clearable
									placeholder="Status"
									name="status"
									options={statusList}
									value={userPostState.apiParams.status}
									onChange={(val) => onFilterChange('status', val)}
								/>
							</Col>
							<Col sm={4}>
								<SelectField
									clearable
									placeholder="Feed Status"
									name="showOnFeed"
									options={showOnFeedList}
									value={userPostState.apiParams.showOnFeed}
									onChange={(val) =>
										// eslint-disable-next-line no-nested-ternary
										onFilterChange('showOnFeed', typeof val === 'string' ? (val === 'NOT_DECIDED' ? 'NOT_DECIDED' : null) : val)
									}
								/>
							</Col>
							<Col sm={4}>
								<SelectField
									clearable
									placeholder="Media Type"
									name="mediaType"
									options={mediaTypeOptions}
									value={userPostState.apiParams.mediaType}
									onChange={(val) => onFilterChange('mediaType', val)}
								/>
							</Col>
							<Col sm={4}>
								<SelectField
									clearable
									placeholder="Post Source"
									name="source"
									options={sourceTypeOptions}
									value={userPostState.apiParams.source}
									onChange={(val) => onFilterChange('source', val)}
								/>
							</Col>
						</Row>
						{isNotEmptyArray(userPostRows) && (
							<>
								<DataPaginationTable heads={heads} rows={userPostRows} onSort={onSort} excludedSortColumns={excludedSortColumns} />
								<PaginationNew
									showPrevious={userPostState.apiParams.skip}
									showNext={userPostState.total > userPostState.apiParams.limit}
									goBack={() => onChangePage(userPostState.apiParams.skip - userPostState.apiParams.limit)}
									goForward={() => onChangePage(userPostState.apiParams.skip + userPostState.apiParams.limit)}
								/>
							</>
						)}
						{userPostState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};
