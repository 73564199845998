import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { omitWrapper, showToast } from 'utils/commonHelpers';
import { fetchWrapper, uploadMedias } from 'utils/fetchWrapper';
import history from '../../../App/history';

export const fetchActivityInstances = createAsyncThunk<any, any>(
	'activityInstance/fetchActivityInstances',
	async (apiParams, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/quiz/get-activity-instances`, {
				method: 'POST',
				body: { ...apiParams, withTopicDetails: true },
			});

			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			return response.data;
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const fetchActivityInstanceDetails = createAsyncThunk<any, any>(
	'activityInstance/fetchActivityInstanceDetails',
	async ({ activityInstanceId }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/quiz/get-activity-instance-details?activityInstanceId=${activityInstanceId}`, {
				method: 'GET',
			});

			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}
			return response.data;
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const createActivityInstance = createAsyncThunk<any, any>(
	'activityInstance/createActivityInstnace',
	async (params, { dispatch }) => {
		const shouldUploadCoverPicture =
			params.coverImageUrl && Array.isArray(params.coverImageUrl) && typeof params.coverImageUrl[0].url !== 'string';

		const shouldUploadGameCardCoverPicture =
			params.gameCardCoverPictureUrl &&
			Array.isArray(params.gameCardCoverPictureUrl) &&
			typeof params.gameCardCoverPictureUrl[0] === 'object' &&
			typeof params.gameCardCoverPictureUrl[0].id !== 'string';
		let coverImageUrl: string;
		let gameCardCoverPictureUrl: string;

		if (shouldUploadCoverPicture || shouldUploadGameCardCoverPicture) {
			const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

			let [uploadedCoverImageResponse, uploadedGameCardCoverPicture] = await Promise.all([
				shouldUploadCoverPicture
					? uploadMedias({
							medias: [{ file: params.coverImageUrl[0] }],
							entityId,
							entityType: 'QUIZ_COVER_IMAGE',
					  })
					: null,
				shouldUploadGameCardCoverPicture
					? uploadMedias({
							medias: [{ file: params.gameCardCoverPictureUrl[0] }],
							entityId,
							entityType: 'QUIZ_COVER_IMAGE',
					  })
					: null,
			]);

			if (uploadedGameCardCoverPicture) [uploadedGameCardCoverPicture] = uploadedGameCardCoverPicture;
			if (uploadedCoverImageResponse) [uploadedCoverImageResponse] = uploadedCoverImageResponse;

			if (uploadedCoverImageResponse && uploadedCoverImageResponse?.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: uploadedCoverImageResponse?.data } });
			}

			if (uploadedGameCardCoverPicture && uploadedGameCardCoverPicture?.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: uploadedGameCardCoverPicture?.data } });
			}

			if (uploadedCoverImageResponse) {
				coverImageUrl = uploadedCoverImageResponse?.data?.url;
			}

			if (uploadedGameCardCoverPicture) {
				gameCardCoverPictureUrl = uploadedGameCardCoverPicture?.data?.url;
			}
		}

		try {
			const response = await fetchWrapper(`/v1/admin/quiz/create-activity-instance`, {
				method: 'POST',
				body: {
					...params,
					...(coverImageUrl ? { coverImageUrl } : { coverImageUrl: params.coverImageUrl[0].url }),
					...(gameCardCoverPictureUrl
						? { gameCardCoverPictureUrl }
						: { gameCardCoverPictureUrl: params?.gameCardCoverPictureUrl?.[0]?.url }),
				},
			});

			if (response.status !== 200 || !response.data.status) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			} else {
				history.push('/activity/activity-instance/list');
				showToast('success', `Activity instance created successfully`);
			}
			return {
				...omitWrapper(['msg', 'status'], response.data),
			};
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const updateActivityInstance = createAsyncThunk<any, any>(
	'activityInstance/updateActivityInstance',
	async (params, { dispatch }) => {
		const shouldUpdateCoverPicture =
			params.coverImageUrl && Array.isArray(params.coverImageUrl) && typeof params.coverImageUrl[0].id !== 'string';

		const shouldUpdateGameCardCoverPicture =
			params.gameCardCoverPictureUrl &&
			Array.isArray(params.gameCardCoverPictureUrl) &&
			typeof params.gameCardCoverPictureUrl[0] === 'object' &&
			typeof params.gameCardCoverPictureUrl[0].id !== 'string';
		let coverImageUrl: string = !shouldUpdateCoverPicture ? params.coverImageUrl[0].url : null;
		let gameCardCoverPictureUrl: string = !shouldUpdateGameCardCoverPicture ? params?.gameCardCoverPictureUrl?.[0]?.url : null;
		try {
			if (shouldUpdateCoverPicture || shouldUpdateGameCardCoverPicture) {
				const { data: entityId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

				let [uploadedCoverImageResponse, uploadedGameCardCoverPicture] = await Promise.all([
					shouldUpdateCoverPicture
						? uploadMedias({
								medias: [{ file: params.coverImageUrl[0] }],
								entityId,
								entityType: 'QUIZ_COVER_IMAGE',
						  })
						: null,
					shouldUpdateGameCardCoverPicture
						? uploadMedias({
								medias: [{ file: params.gameCardCoverPictureUrl[0] }],
								entityId,
								entityType: 'QUIZ_COVER_IMAGE',
						  })
						: null,
				]);

				if (uploadedGameCardCoverPicture) [uploadedGameCardCoverPicture] = uploadedGameCardCoverPicture;
				if (uploadedCoverImageResponse) [uploadedCoverImageResponse] = uploadedCoverImageResponse;

				if (uploadedCoverImageResponse && uploadedCoverImageResponse?.status !== 200) {
					dispatch({ type: setError.type, payload: { errors: uploadedCoverImageResponse?.data } });
				}

				if (uploadedGameCardCoverPicture && uploadedGameCardCoverPicture?.status !== 200) {
					dispatch({ type: setError.type, payload: { errors: uploadedGameCardCoverPicture?.data } });
				}

				if (uploadedCoverImageResponse) {
					coverImageUrl = uploadedCoverImageResponse?.data?.url;
				}

				if (uploadedGameCardCoverPicture) {
					gameCardCoverPictureUrl = uploadedGameCardCoverPicture?.data?.url;
				}
			}

			const response = await fetchWrapper(`/v1/admin/quiz/update-activity-instance`, {
				method: 'PUT',
				body: { ...params, coverImageUrl, gameCardCoverPictureUrl },
			});

			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			} else {
				showToast('success', `Quiz instance updated successfully`);
			}
			history.push('/activity/activity-instance/list');
			return response.data;
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const deleteActivityInstance = createAsyncThunk<any, any>(
	'activityInstance/deleteActivityInstance',
	async ({ activityInstanceId }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/v1/admin/quiz/delete-activity-instance?activityInstanceId=${activityInstanceId}`, {
				method: 'DELETE',
			});

			if (response.status !== 200 || !response.data.status) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			} else {
				history.push('/activity/activity-instance/list');
				showToast('success', `Quiz instance deleted successfully`);
				return response.data;
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const fetchTags = async () => {
	const response = await fetchWrapper('/v1/admin/quiz/get-question-tags', {
		method: 'GET',
	});

	if (response.status !== 200) {
		showToast('error', 'Error fetching tags');
	}

	return response.data;
};

export const fetchQuizes = async ({ query: searchTerm }: any) => {
	const response = await fetchWrapper('/v1/admin/quiz/get-quizes', {
		method: 'POST',
		body: { limit: 20, skip: 0, searchKey: searchTerm, sortKey: 'createdAt', sortOrder: -1 },
	});

	if (response.status !== 200) {
		showToast('error', 'Error fetching quizes');
	}

	return response.data.quizes.map((quiz: any) => ({
		value: quiz.id,
		label: quiz.title,
		quiz,
	}));
};

export const fetchQuestionsIds = async ({ query: searchTerm }: any) => {
	const response = await fetchWrapper('/v1/admin/quiz/get-quiz-questions', {
		method: 'POST',
		body: { limit: 20, skip: 0, searchKey: searchTerm, sortKey: 'createdAt', sortOrder: -1 },
	});

	if (response.status !== 200) {
		showToast('error', 'Error fetching questions');
	}

	return response.data.questions.map((question: any) => ({
		value: question.id,
		label: question.title,
		question,
	}));
};

export const fetchQuestionsTopics = async ({ query: searchTerm }: any) => {
	const response = await fetchWrapper('/v1/admin/quiz/get-quiz-question-topics', {
		method: 'POST',
		body: { limit: 20, searchKey: searchTerm },
	});

	if (response.status !== 200) {
		showToast('error', 'Error fetching questions');
	}

	return response.data.map((questionTopic: any) => ({
		value: questionTopic.id,
		label: questionTopic.name,
	}));
};
