import React, { FunctionComponent } from 'react';
import formatDate from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import Box from 'reusableComponents/Box';
import { required } from 'reusableComponents/Form/fieldValidators';
import { ArrayMaybe, isNotEmptyArray, isNotEmptyObject, showToast } from 'utils/commonHelpers';
import theme from 'utils/theme';
import { fetchWrapper } from 'utils/fetchWrapper';
import { useLocation } from 'react-router';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import { v4 as uuid } from 'uuid';
import DropzoneWithImageCrop from 'components/DropzoneWithImageCrop';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';
import If from 'reusableComponents/If';
import { fetchPlansAndPackages, parsePlanAndPacakgesOptions } from 'utils/fetchHelpers';
import {
	createActivityInstance,
	fetchActivityInstanceDetails,
	fetchQuestionsIds,
	fetchQuestionsTopics,
	fetchQuizes,
	fetchTags,
	updateActivityInstance,
} from './actions/activityInstance.action';

const quizTypeOptions = [{ label: 'Quiz', value: 'QUIZ' }];
const activityStatusOptions = [
	{ label: 'ACTIVE', value: 'ACTIVE' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
];

const RenderRandomeQuestionTopics = ({ fields, initialValues, randomQuestions }) => {
	React.useEffect(() => {
		initialValues.forEach((questionWithRankField: any) => {
			fields.push(questionWithRankField);
		});
	}, []);

	React.useEffect(() => {
		if (!isNotEmptyArray(randomQuestions)) return;
		fields.removeAll();

		randomQuestions.forEach((question: any) => {
			fields.push([
				{
					label: question?.name,
					value: question?.id,
				},
				{
					count: question?.count,
				},
			]);
		});
	}, [randomQuestions]);

	return (
		<>
			{fields.map((_: never, optionIndex: number) => {
				return (
					<Box pos="relative" key={fields.get(optionIndex).id || fields.get(optionIndex)[0].value}>
						<Col sm="12">
							<AsyncSelectField
								name={`randomQuestions[${optionIndex}].topic`}
								label={`Topic ${optionIndex + 1}`}
								defaultValue={fields.get(optionIndex)[0]}
								fetchOptions={fetchQuestionsTopics}
								required
							/>
							<Input
								label="Topic Question Count"
								name={`randomQuestions[${optionIndex}].count`}
								type="number"
								placeholder="0"
								defaultValue={fields.get(optionIndex)[1]?.count}
								validate={required}
							/>
							<Box
								onClick={() => {
									if (fields.length > 1) {
										fields.remove(optionIndex);
									} else {
										showToast('error', '✋ At least one question is required!');
									}
								}}
								cursor="pointer"
								pos="absolute"
								t="-3px"
								r="0"
							>
								<span className="lnr lnr-cross" />
							</Box>
						</Col>
					</Box>
				);
			})}
			<Box d="flex" jc="center" w="100%" className="mb-5 pt-3">
				<Box
					w="2rem"
					h="2rem"
					c={theme.clrs.cWhite}
					fs="1.4rem"
					bRad="50%"
					d="flex"
					jc="center"
					ai="center"
					bg={theme.clrs.cSkyBlue}
					cursor="pointer"
					onClick={() => fields.push({ id: uuid() })}
				>
					+
				</Box>
			</Box>
		</>
	);
};

const renderQuestionWithRank = ({ fields, initialValues, control, questionDetails, questionRanks }) => {
	const selectedQuestions = useWatch<any[]>({ control, name: 'questions' });

	React.useEffect(() => {
		initialValues.forEach((questionWithRankField: any) => {
			fields.push(questionWithRankField);
		});
	}, []);

	React.useEffect(() => {
		if (!isNotEmptyArray(questionRanks) && !isNotEmptyArray(questionDetails)) return;
		fields.removeAll();

		const questionDetailsMap = questionDetails.reduce((acc, question) => {
			return { ...acc, [question._id]: question };
		}, {});

		questionRanks.forEach((questionRank: any) => {
			fields.push([
				{
					label: questionDetailsMap?.[questionRank?.questionId]?.title,
					value: questionRank.questionId,
					question: questionDetailsMap?.[questionRank?.questionId],
				},
				{ rank: questionRank.rank },
			]);
		});
	}, [questionRanks, questionDetails]);

	const filterOption = ({ value }) => {
		if (!isNotEmptyArray(selectedQuestions)) {
			return true;
		}
		return !selectedQuestions.find((option) => option?.value === value);
	};

	return (
		<>
			{fields.map((_: never, optionIndex: number) => {
				return (
					<Box pos="relative" key={fields.get(optionIndex).id || fields.get(optionIndex)[0].value}>
						<Col sm="12">
							<AsyncSelectField
								name={`questions[${optionIndex}]`}
								label={`Question ${optionIndex + 1}`}
								defaultValue={fields.get(optionIndex)[0]}
								filterOption={filterOption}
								fetchOptions={fetchQuestionsIds}
								required
							/>
							<Box
								onClick={() => {
									if (fields.length > 1) {
										fields.remove(optionIndex);
									} else {
										showToast('error', '✋ At least one question is required!');
									}
								}}
								cursor="pointer"
								pos="absolute"
								t="-3px"
								r="0"
							>
								<span className="lnr lnr-cross" />
							</Box>
						</Col>
					</Box>
				);
			})}
			<Box d="flex" jc="center" w="100%" className="mb-5 pt-3">
				<Box
					w="2rem"
					h="2rem"
					c={theme.clrs.cWhite}
					fs="1.4rem"
					bRad="50%"
					d="flex"
					jc="center"
					ai="center"
					bg={theme.clrs.cSkyBlue}
					cursor="pointer"
					onClick={() => fields.push({ id: uuid() })}
				>
					+
				</Box>
			</Box>
		</>
	);
};

const QuizInstanceAddEditForm: FunctionComponent = () => {
	const hookFormMethods = useForm();
	const { handleSubmit, register, control, reset } = hookFormMethods;

	const { startTime, endTime, displayStartTime, questionSelectionType } = useWatch({
		control,
		name: ['startTime', 'endTime', 'displayStartTime', 'questionSelectionType'],
	});

	const checkIfDisplayEndTimeValid = (value: string) => {
		if (value !== '') {
			if (new Date(value) < new Date(displayStartTime)) {
				return 'Display End time must be after display start time';
			}
			if (new Date(value) > new Date(endTime)) {
				return 'Display end time must be before End time';
			}
		}
	};

	const checkIfDisplayStartTimeValid = (value: string) => {
		if (value !== '') {
			if (new Date(value) > new Date(startTime)) {
				return 'Display Start time must be before Start time';
			}
		}
	};

	const checkIfEndTimeValid = (value: string) => {
		if (value !== '') {
			if (new Date(value) < new Date(startTime)) {
				return 'End time must be after start time';
			}
		}
	};

	const [questionTags, setQuestionTags] = React.useState<any[]>([]);
	const [activityInstance, setActivityInstnace] = React.useState<any>({});
	const dispatch = useDispatch();

	const selectedQuestions = useWatch<any[]>({ name: 'questions', control });
	const selectedQuiz = useWatch<any>({ name: 'quizId', control });

	const { search } = useLocation();
	const parsedQueryParam = new URLSearchParams(search);
	const activityInstanceId = parsedQueryParam.get('activityInstanceId');

	const activityInstanceState = useSelector(({ activityInstances }) => ({
		activityInstances: activityInstances.activityInstances,
	}));

	React.useEffect(() => {
		if (activityInstanceId) {
			dispatch(fetchActivityInstanceDetails({ activityInstanceId }));
		}
	}, []);

	React.useEffect(() => {
		if (!isNotEmptyArray(activityInstanceState.activityInstances)) return;

		// find activityInstances with activityInstanceId
		const activityInstance = activityInstanceState.activityInstances.find(
			(activityInstance: any) => activityInstance?.id === activityInstanceId
		);

		if (isNotEmptyObject(activityInstance)) {
			setActivityInstnace(activityInstance);
			const {
				baseActivityId,
				baseActivityDetails,
				activityType,
				displayStartTime,
				displayEndTime,
				startTime,
				endTime,
				categoryTag,
				tags,
				status,
				agePreference,
				challengingCost,
				playingCost,
				playingReward,
				challengingReward,
				title,
				priority,
				coverImageUrl,
				gameCardCoverPictureUrl,
				totalActivityDuration,
				shouldShowAnswer,
				questionSelectionType,
				randomQuestions,
				plansAndPackages,
			} = activityInstance;

			const coverPicture = getPreviewImages({ id: activityInstanceId, mediaType: 'IMAGE', url: coverImageUrl }, false);
			const gameCardCoverPicture = getPreviewImages({ id: activityInstanceId, mediaType: 'IMAGE', url: gameCardCoverPictureUrl }, false);

			parsePlanAndPacakgesOptions(plansAndPackages).then((formatedPlansAndPackages) => {
				reset({
					startTime: formatDate(new Date(startTime), "yyyy-MM-dd'T'HH:mm"),
					endTime: formatDate(new Date(endTime), "yyyy-MM-dd'T'HH:mm"),
					displayStartTime: formatDate(new Date(displayStartTime), "yyyy-MM-dd'T'HH:mm"),
					displayEndTime: formatDate(new Date(displayEndTime), "yyyy-MM-dd'T'HH:mm"),
					quizId: { label: baseActivityDetails.title, value: baseActivityId, quiz: baseActivityDetails },
					quizType: { label: activityType, value: activityType },
					categoryTag,
					tags,
					status: { label: status, value: status },
					ageStartFrom: agePreference.min,
					ageEndFrom: agePreference.max,
					title,
					priority,
					challengingCostInXp: challengingCost.xp,
					challengingCostInBolt: challengingCost.bolts,
					playingCostInXp: playingCost.xp,
					playingCostInBolt: playingCost.bolts,
					playingRewardInXp: playingReward.xp,
					playingRewardInBolt: playingReward.bolts,
					challengingRewardInXp: challengingReward.xp,
					challengingRewardInBolt: challengingReward.bolts,
					coverImageUrl: coverPicture,
					...(gameCardCoverPictureUrl ? { gameCardCoverPictureUrl: gameCardCoverPicture } : {}),
					totalActivityDuration: (totalActivityDuration ?? 0) / 60,
					shouldShowAnswer,
					questionSelectionType,
					randomQuestions,
					plansAndPackages: formatedPlansAndPackages,
				});
			});
		}
	}, [activityInstanceState.activityInstances]);

	React.useEffect(() => {
		if (!isNotEmptyObject(selectedQuiz) || activityInstanceId) return;
		const {
			agePreference,
			challengingCost,
			playingCost,
			playingReward,
			challengingReward,
			title,
			priority,
			coverImageUrl,
			id: quizId,
			totalActivityDuration,
		} = selectedQuiz.quiz;

		control.setValue('ageStartFrom', agePreference.min);
		control.setValue('ageEndFrom', agePreference.max);
		control.setValue('title', title);
		control.setValue('priority', priority);

		control.setValue('challengingCostInXp', challengingCost.xp);
		control.setValue('challengingCostInBolt', challengingCost.bolts);

		control.setValue('playingCostInXp', playingCost.xp);
		control.setValue('playingCostInBolt', playingCost.bolts);

		control.setValue('playingRewardInXp', playingReward.xp);
		control.setValue('playingRewardInBolt', playingReward.bolts);

		control.setValue('challengingRewardInXp', challengingReward.xp);
		control.setValue('challengingRewardInBolt', challengingReward.bolts);

		control.setValue('totalActivityDuration', (totalActivityDuration ?? 0) / 60);

		const coverPicture = getPreviewImages({ id: quizId, mediaType: 'IMAGE', url: coverImageUrl }, false);
		control.setValue('coverImageUrl', coverPicture);
	}, [selectedQuiz]);

	React.useEffect(() => {
		if (!isNotEmptyArray(questionTags)) return;
		control.setValue('tags', questionTags);
	}, [questionTags.length]);

	const onSubmit = (data: any) => {
		const {
			ageStartFrom,
			ageEndFrom,
			playingCostInXp,
			playingCostInBolt,
			challengingCostInXp,
			challengingCostInBolt,
			playingRewardInXp,
			playingRewardInBolt,
			challengingRewardInXp,
			challengingRewardInBolt,
			quizType: quizTypeOption,
			quizId: quizIdOption,
			questionRanks,
			priority,
			questions,
			status,
			categoryTag,
			randomQuestions,
			plansAndPackages,
			...rest
		} = data;

		const agePreference = {
			min: Number(ageStartFrom),
			max: Number(ageEndFrom),
		};

		const playingCost = {
			xp: Number(playingCostInXp),
			bolts: Number(playingCostInBolt),
		};

		const challengingCost = {
			xp: Number(challengingCostInXp),
			bolts: Number(challengingCostInBolt),
		};
		const playingReward = {
			xp: Number(playingRewardInXp),
			bolts: Number(playingRewardInBolt),
		};

		const challengingReward = {
			xp: Number(challengingRewardInXp),
			bolts: Number(challengingRewardInBolt),
		};

		const quizId = quizIdOption.value;
		const quizType = quizTypeOption.value;

		const modifiedQuestionRanks = questions?.map(({ value }, index: number) => ({ questionId: value, rank: index + 1 }));
		const questionIds = questions?.map(({ value }) => value);

		const finalObject = {
			...rest,
			id: activityInstanceId,
			agePreference,
			playingCost,
			challengingCost,
			playingReward,
			challengingReward,
			baseActivityId: quizId,
			activityType: quizType,
			priority: Number(priority),
			questionRanks: modifiedQuestionRanks,
			questionIds,
			status: status.value,
			categoryTag,
			totalActivityDuration: rest.totalActivityDuration * 60, // minutes -> seconds
			randomSelectionConfig: randomQuestions?.map((questionDetails) => ({
				questionCount: questionDetails?.count,
				topic: questionDetails?.topic?.value,
			})),
			plansAndPackages: ArrayMaybe(plansAndPackages).map((planAndPackage) => planAndPackage.value),
		};

		if (activityInstanceId) {
			return dispatch(
				updateActivityInstance({
					...finalObject,
					id: activityInstanceId,
				})
			);
		}

		dispatch(createActivityInstance(finalObject));
	};

	const onChangeStartTime = (e: any) => {
		const { value } = e.target;
		const displayStartTime = formatDate(new Date(new Date(value).getTime() - 2 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm");
		control.setValue('displayStartTime', displayStartTime);
	};

	const onChangeEndTime = (e: any) => {
		const { value } = e.target;
		const displayEndTime = formatDate(new Date(value), "yyyy-MM-dd'T'HH:mm");
		control.setValue('displayEndTime', displayEndTime);
	};

	return (
		<Card>
			<CardBody>
				<FormProvider {...hookFormMethods}>
					<form className="form lts-support" onSubmit={handleSubmit(onSubmit)}>
						<Box w="100%">
							<Row>
								<Col sm="12">
									<h2 className="mb-4">Activity Instance Edit</h2>
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<AsyncSelectField name="quizId" label="Select Quiz" required fetchOptions={fetchQuizes} />
								</Col>
								<Col sm="6">
									<SelectField name="quizType" label="Quiz Type" options={quizTypeOptions} required placeholder="Select Quiz Type" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Input label="Title" name="title" type="text" placeholder="title" validate={required} />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<Input
										min={formatDate(new Date(), "yyyy-MM-dd'T'HH:mm")}
										type="datetime-local"
										name="displayStartTime"
										label="Display Start Time"
										{...(startTime && {
											defaultValue: formatDate(new Date(new Date(startTime).getTime() - 2 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm"),
										})}
										required
										validate={checkIfDisplayStartTimeValid}
									/>
								</Col>
								<Col sm="6">
									<Input
										min={formatDate(new Date(), "yyyy-MM-dd'T'HH:mm")}
										type="datetime-local"
										name="startTime"
										onChange={onChangeStartTime}
										label="Start Time"
										required
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<Input
										min={formatDate(new Date(), "yyyy-MM-dd'T'HH:mm")}
										type="datetime-local"
										name="displayEndTime"
										label="Display End Time"
										{...(endTime && {
											defaultValue: formatDate(new Date(endTime), "yyyy-MM-dd'T'HH:mm"),
										})}
										validate={checkIfDisplayEndTimeValid}
										required
									/>
								</Col>
								<Col sm="6">
									<Input
										min={formatDate(new Date(), "yyyy-MM-dd'T'HH:mm")}
										type="datetime-local"
										name="endTime"
										label="End time"
										onChange={onChangeEndTime}
										validate={checkIfEndTimeValid}
										required
									/>
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									<Input name="ageStartFrom" type="number" min="0" label="Age Start From" required validate={required} />
								</Col>
								<Col sm={6}>
									<Input name="ageEndFrom" type="number" min="0" label="Age End From" required validate={required} />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<SelectField name="status" label="Status" options={activityStatusOptions} required placeholder="Select Activity Status" />
								</Col>
								<Col sm="6" className="pt-2">
									<Input name="priority" label="Priority" min="0" type="number" required validate={required} />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<Input
										name="totalActivityDuration"
										label="Duration (in minutes) NOTE: Quiz will become Olympiad if duration is greater than 0. For zero duration, duration form individual question will be picked up"
										min="0"
										type="number"
										validate={required}
									/>
								</Col>
								<Col sm="6">
									<Checkbox label="Should show the answers with each question" name="shouldShowAnswer" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<AsyncSelectField isMulti label="Packages and Plans" name="plansAndPackages" fetchOptions={fetchPlansAndPackages} />
								</Col>
							</Row>
							<Row>
								<Col sm="6">
									<RadioGroup
										label="What kind of questions will be there?"
										name="questionSelectionType"
										fields={[
											{ label: 'Random', value: 'RANDOM' },
											{ label: 'Fixed', value: 'FIXED' },
										]}
										required
									/>
								</Col>
							</Row>
							<If
								condition={questionSelectionType === 'FIXED'}
								then={
									<Row>
										<Col sm="12">
											<h3 className="my-3">Questions</h3>
										</Col>
										<Col sm="12">
											<FieldArray
												name="Questions"
												component={renderQuestionWithRank}
												control={control}
												initialValues={!activityInstanceId ? [{ id: uuid() }] : []}
												questionRanks={activityInstance?.questionRanks}
												questionDetails={activityInstance?.questionDetails}
												register={register}
											/>
										</Col>
									</Row>
								}
							/>
							<If
								condition={questionSelectionType === 'RANDOM'}
								then={
									<>
										<Row>
											<Col sm="12">
												<h3 className="my-3">Topic wise Questions</h3>
											</Col>
										</Row>
										<Col sm="12">
											<FieldArray
												name="randomQuestions"
												component={RenderRandomeQuestionTopics}
												control={control}
												initialValues={!activityInstanceId ? [{ id: uuid() }] : []}
												randomQuestions={activityInstance?.randomQuestions}
												register={register}
											/>
										</Col>
									</>
								}
							/>
							<Row>
								<Col sm="12">
									<h4 className="f-3 my-2">Playing Cost</h4>
								</Col>
								<Col sm="6">
									<Input name="playingCostInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="playingCostInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<h4 className="f-3 my-2">Challenging Cost</h4>
								</Col>
								<Col sm="6">
									<Input name="challengingCostInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="challengingCostInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<h4 className="f-3 my-2">Playing Reward</h4>
								</Col>
								<Col sm="6">
									<Input name="playingRewardInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="playingRewardInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<h4 className="f-3 my-2">Challenging Reward</h4>
								</Col>
								<Col sm="6">
									<Input name="challengingRewardInXp" type="number" label="XP" defaultValue="0" min="0" />
								</Col>
								<Col sm="6">
									<Input name="challengingRewardInBolt" type="number" label="Bolt" defaultValue="0" min="0" />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<AsyncSelectField name="tags" label="Tags" isMulti isClearable fetchOptions={fetchTags} />
								</Col>
							</Row>
							<Row>
								<Col sm="6" className="pt-2">
									<Input name="categoryTag" label="Category Tag" required validate={required} />
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<DropzoneWithImageCrop
										label="Cover Picture (5:2 Aspect Ratio)"
										name="coverImageUrl"
										required
										aspectRatio={2.5}
										control={control}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<DropzoneWithImageCrop
										label="Cover Picture for Game Card(3:2 Aspect Ratio)"
										name="gameCardCoverPictureUrl"
										aspectRatio={1.5}
										control={control}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Button className="btn btn-primary">Submit</Button>
								</Col>
							</Row>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_activity_instance', enableReinitialize: true });

const QuizInstanceAddEditReduxForm = compose(withReduxForm)(QuizInstanceAddEditForm);

export default QuizInstanceAddEditReduxForm;
