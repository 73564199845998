import React, { useState, useEffect } from 'react';
import Box from 'reusableComponents/Box';
import { fetchWrapper } from 'utils/fetchWrapper';
import { IBatchToClass } from 'types/BatchToClass';
import { ClassCard } from 'components/ClassCard';
import { dateTimeConverter } from 'utils/dateHelper';

interface Props {
	batchId: string;
}

export const BatchClassList = ({ batchId }: Props) => {
	const [batchClasses, setBatchClasses] = useState<IBatchToClass[]>([]);

	useEffect(() => {
		// Fetch classes
		fetchWrapper(`/v1/admin/batchtoclasses?batchId=${batchId}`, { method: 'GET' }).then((res) => {
			setBatchClasses(res.data.classes as IBatchToClass[]);
		});
	}, []);

	return (
		<Box>
			<h4>Batch Classes</h4>
			<div className="table">
				<table>
					<thead>
						<tr>
							<th>Class</th>
							<th>dateTime</th>
						</tr>
					</thead>
					<tbody>
						{batchClasses.map((batchClass) => (
							<tr>
								<td>
									<Box ml="1rem">{batchClass.index}</Box>
								</td>
								<td>
									<ClassCard {...batchClass.classDetails} />
								</td>
								<td>
									<Box ml="1rem">{dateTimeConverter(batchClass.dateTime, 'format', 'dd MMM yyyy (k:mm)')}</Box>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Box>
	);
};
