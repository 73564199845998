import { createSlice } from '@reduxjs/toolkit';
import { fetchSchools, getSchoolDetailById, updateSchoolDetails } from './actions';

const schoolSlice = createSlice({
	name: 'school',
	initialState: {
		schools: [],
		schoolLoading: false,
		schoolError: null,
		page: 1,
		total: 10,
		apiParams: {
			limit: 20,
			searchKey: '',
			sortKey: 'totalBolts',
			sortOrder: -1,
			skip: 0,
			filterOption: '',
		},
	},
	reducers: {
		setSchoolLoading: (state) => {
			state.schoolLoading = true;
		},
		setSchoolLoaded: (state) => {
			state.schoolLoading = false;
		},
		setSchoolError: (state, action) => {
			state.schoolError = action?.payload;
		},
		setSchools: (state, action) => {
			state.schools = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ searchKey: action.payload.searchKey },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.filterOption !== undefined && { filterOption: action.payload.filterOption }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
	},
	extraReducers: {
		[fetchSchools.pending as any]: (state) => {
			state.schoolLoading = true;
		},
		[fetchSchools.fulfilled as any]: (state, { payload }) => {
			state.schools = payload?.schools;
			state.schoolLoading = false;
			state.total = payload?.total;
		},
		[getSchoolDetailById.fulfilled as any]: (state, { payload }) => {
			state.schools.push(payload);
			state.total += 1;
		},
		[updateSchoolDetails.pending as any]: (state) => {
			state.schoolLoading = true;
			state.apiParams.filterOption = '';
		},
		[updateSchoolDetails.fulfilled as any]: (state, { payload }) => {
			const index = state.schools.findIndex((school) => school.id === payload.updatedSchool.id);
			state.schools[index] = payload.updatedSchool;
			state.schoolLoading = false;
		},
	},
});

export const { setApiParams, setSchoolLoading, setPage } = schoolSlice.actions;

export default schoolSlice.reducer;
