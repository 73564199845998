import React from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../../App/history';
import ImageCard from '../ImageCard';
import { getSanitizedImageUrl, isNotEmptyArray } from '../../../../utils/commonHelpers';
import Box from '../../../../reusableComponents/Box';
import { getGalleryImages } from '../../gallerySlice';

const customStylesMargin = {
	marginBottom: '0px',
};

const GalleryList = () => {
	const galleryState = useSelector(({ gallery }) => ({
		loading: gallery.loading,
		galleryImages: gallery.galleryImages,
	}));
	const dispatch = useDispatch();

	const [images, setImages] = React.useState(null);

	React.useEffect(() => {
		dispatch(getGalleryImages());
	}, []);

	React.useEffect(() => {
		if (isNotEmptyArray(galleryState.galleryImages)) {
			setImages(galleryState.galleryImages);
		} else {
			setImages([]);
		}
	}, [galleryState.galleryImages]);

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={9}>
							<h3>Gallery Images</h3>
						</Col>
						<Col sm={{ size: 2, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/gallery/edit');
								}}
							>
								Edit
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(images) ? (
						<Box d="flex" wrap="wrap" ai="center">
							{images.map((image) => (
								<ImageCard src={getSanitizedImageUrl(image.url, { w: 200, crop: 'faces,top,left' })} alt="Class Gallery" />
							))}
						</Box>
					) : (
						'Empty Gallery!'
					)}
				</CardBody>
			</Card>
		</Col>
	);
};

export default GalleryList;
