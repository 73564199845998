import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import Box from '../../../../reusableComponents/Box';
import { required } from '../../../../reusableComponents/Form/fieldValidators';
import { renderCheckBoxButton, renderCKEditor, renderTextField } from '../../../../reusableComponents/Form/formComponents';
import renderSelectField from '../../../../reusableComponents/Form/Select';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import { isNotEmptyObject, omitWrapper, parseQueryParams, pickWrapper } from '../../../../utils/commonHelpers';
import { STATUS_CAREER_ACTIVE, STATUS_CAREER_INACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';
import { getOpening, getOpeningId, getOpeningSuccess, openingOnSubmit } from '../../careerSlice';

const careerDepartments = [
	'Engineering',
	'Marketing',
	'Product',
	'Design',
	'Program Development',
	'Mentor/Alliance Management',
	'Quality Analyst',
	'Community',
	'Growth/Digital Marketing',
];

const CareersAddEdit = (props) => {
	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} Opening</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="12">
						<Field label="Position Title" isRequired validate={required} type="text" component={renderTextField} name="title" />
					</Col>
					<Col sm="12">
						<Field label="Job URL" isRequired validate={required} type="text" component={renderTextField} name="jobUrl" />
					</Col>
					<Col sm="6">
						<Field
							label="Department"
							isRequired
							validate={required}
							component={renderSelectField}
							name="dept"
							options={careerDepartments.map((dept) => ({ value: dept, label: dept }))}
						/>
					</Col>
					<Col sm="6">
						<Field label="Location" isRequired validate={required} type="text" component={renderTextField} name="location" />
					</Col>
					<Col sm="3" className="mt-3">
						<Field label="Is Active?" name="isActive" component={renderCheckBoxButton} />
					</Col>
					<Col sm="12">
						<span className="form__form-group-label">
							Description <span className="form__form_group-label--required">&#42;</span>
						</span>
						<Field entityId={props.openingId} entityType="CLASS" type="text" component={renderCKEditor} name="description" />
					</Col>
					<Col sm="12" className="mt-4">
						<Button size="sm" color="primary" type="submit">
							{isNotEmptyObject(props.initialValues) ? 'Save' : 'Create'}
						</Button>
						<Button size="sm" color="success" type="button" onClick={() => history.push('/careers/list')}>
							Cancel
						</Button>
					</Col>
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_career', enableReinitialize: true });

const CareerReduxForm = compose(withReduxForm)(CareersAddEdit);

const CareerForm = (props) => {
	const careerState = useSelector(({ careers }) => ({
		loading: careers.loading,
		opening: careers.opening,
		isSubmitting: careers.isSubmitting,
		openingId: careers.openingId,
	}));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOpeningId());
		if (props.location.search) {
			dispatch(getOpening(pickWrapper(['id'], parseQueryParams(props.location.search))));
		}
		return () => {
			dispatch(getOpeningSuccess({}));
		};
	}, [props.location.search]);

	const handleSubmit = async (params) => {
		dispatch(
			openingOnSubmit(
				omitWrapper(['isActive'], {
					...params,
					status: params.isActive ? STATUS_CAREER_ACTIVE : STATUS_CAREER_INACTIVE,
					isEdit: !!props.location.search,
				})
			)
		);
	};

	if (careerState.loading) {
		return <div>Loading...</div>;
	}

	return (
		<LoadingOverlay isLoading={careerState.isSubmitting} msg="Submitting Opening...">
			<Card>
				<CardBody>
					<CareerReduxForm
						initialValues={
							isNotEmptyObject(careerState.opening)
								? {
										...careerState.opening,
										isActive: careerState.opening.status === STATUS_CAREER_ACTIVE,
								  }
								: null
						}
						onSubmit={handleSubmit}
						openingId={parseQueryParams(props.location.search).id || careerState.openingId}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default CareerForm;
