import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ShortsTable } from './components/ShortsTable';
import { ShortsAddEditForm } from './components/ShortsAddEditForm';
import { ShortsPoll } from './components/ShortsPoll';
import { ShortsTestPolls } from './components/ShortsTestPoll';

const Shorts = () => {
	return (
		<div>
			<Switch>
				<Route path="/shorts/list" component={ShortsTable} />
				<Route path="/shorts/add-edit" component={ShortsAddEditForm} />
				<Route path="/shorts/polls" exact component={ShortsPoll} />
				<Route path="/shorts/polls/test" exact component={ShortsTestPolls} />
			</Switch>
		</div>
	);
};

export default Shorts;
