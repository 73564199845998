/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { isNotEmptyObject } from '../../utils/commonHelpers';
import { ErrorMessage } from './ErrorMessage';

const TextArea = React.forwardRef((props: any, ref: any) => {
	return (
		<div className="form__form-group">
			{props.label && (
				<span className="form__form-group-label">
					{props.label} {props.isRequired && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<div className="form__form-group-field">
				<div className="form__form-group-input-wrap">
					<textarea ref={ref} {...props} />
					{isNotEmptyObject(props.error) && (
						<span className="form__form-group-error">{props.error.type === 'required' ? 'Required!' : props.error.message}</span>
					)}
				</div>
			</div>
		</div>
	);
});

interface Props {
	rows?: number;
	name: string;
	defaultValue?: string;
	label: string;
	helpText?: string;
	required?: boolean;
	isClickDisabled?: boolean;
	disabled?: boolean;
	minLength?: number;
	maxLength?: number;
	placeholder?: string;
}

export const TextAreaNew = ({
	name,
	rows,
	label,
	helpText,
	required,
	minLength,
	maxLength,
	disabled,
	isClickDisabled,
	defaultValue,
	placeholder,
}: Props) => {
	const { register, errors } = useFormContext();
	return (
		<div className="form__form-group" style={{ pointerEvents: isClickDisabled ? 'none' : 'all' }}>
			{label && (
				<span className="form__form-group-label">
					{label} {required && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<div className="form__form-group-field">
				<div className="form__form-group-input-wrap">
					<textarea ref={register({ required, minLength, maxLength })} {...{ name, label, disabled, rows, defaultValue, placeholder }} />
					{helpText && <span className="form__form-group-help-text">{helpText}</span>}
					<ErrorMessage
						{...{ errors, label, name, minLength, maxLength }}
						render={(message) => {
							return <span className="form__form-group-error">{message}</span>;
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default TextArea;
