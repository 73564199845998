import React from 'react';
import styled from 'styled-components';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import theme from 'utils/theme';
import CustomModal from 'reusableComponents/CustomModal';
import Box from 'reusableComponents/Box';

const coaches = JSON.parse(process.env.REACT_APP_COACHES || '[]');

const StyledModal = styled.div`
	height: auto;
	width: 30rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

interface CoachFormValues {
	coach: { label: string; value: string };
}

export type CoachValuesFromDatabase = {
	id: string;
	coachEmail: string;
};

export const modifyCoachBeforeInitialize = (values: CoachValuesFromDatabase): CoachFormValues => {
	const coach = coaches.find((coach) => coach.email === values.coachEmail);
	return {
		...values,
		coach: { label: coach.name, value: coach.email },
	};
};

export const modifyCoachBeforeSubmit = (values: CoachFormValues) => {
	return {
		...values,
		coach: { name: values.coach.label, email: values.coach.value },
	};
};

const CoachSelectModal = ({ initialValue, handleSubmit: submitHandler, handleCoachUpdate, closeModal }) => {
	const isEditForm = !!initialValue.coachEmail;

	const hookFormMethods = useForm<CoachFormValues>();

	const { handleSubmit } = hookFormMethods;

	React.useEffect(() => {
		if (isEditForm) {
			const modifiedValues = modifyCoachBeforeInitialize(initialValue as any);
			hookFormMethods.reset(modifiedValues);
		}
	}, []);

	const onSubmit = (values: CoachFormValues) => {
		if (isEditForm) {
			closeModal();
			handleCoachUpdate({ batchToClasses: [initialValue.id], coach: coaches.find((coach) => coach.email === values.coach.value) });
		} else submitHandler(modifyCoachBeforeSubmit(values).coach);
	};

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<Card>
					<CardBody>
						<FormProvider {...hookFormMethods}>
							<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
								<Box w="100%">
									<Row>
										<Col md="9">
											<h3>{isEditForm ? 'Edit' : 'Assign'} Coach</h3>
										</Col>
									</Row>
								</Box>
								<Box w="100%" mt="1rem">
									<Row>
										<Col sm="12">
											<SelectField
												label="Coach"
												name="coach"
												isClearable
												required
												options={coaches.map((coach) => ({ label: coach.name, value: coach.email }))}
											/>
										</Col>
									</Row>

									<Col sm="12" className="mt-4">
										<Button size="sm" color="primary" type="submit">
											{isEditForm ? 'Save' : 'Assign'}
										</Button>
										<Button size="sm" color="success" type="button" onClick={closeModal}>
											Cancel
										</Button>
									</Col>
								</Box>
							</form>
						</FormProvider>
					</CardBody>
				</Card>
			</StyledModal>
		</CustomModal>
	);
};

export default CoachSelectModal;
