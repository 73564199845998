import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { getUserSuccess, toggleLoading } from '../userSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader } from '../../../utils/commonHelpers';

export function* getUserSaga({ payload }) {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/user?userId=${payload.userId}`);
	if (res.status === 200) {
		yield put(actionSpreader(getUserSuccess.type, { userDetails: res.data }));
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
