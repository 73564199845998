import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { toggleLoading, getOpeningSuccess } from '../careerSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getOpeningSaga({ payload }) {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/career?id=${payload.id}`);
	if (res.status === 200) {
		yield put(actionSpreader(getOpeningSuccess.type, { opening: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
	yield put(actionSpreader(toggleLoading.type, { loading: false }));
}
