import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { find } from 'lodash';
import Dropzone from 'reusableComponents/HookForm/Dropzone';
import Box from '../../../../reusableComponents/Box';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import {
	ArrayMaybe,
	getSanitizedImageUrl,
	isNotEmptyObject,
	omitWrapper,
	parseQueryParams,
	pickWrapper,
	showToast,
} from '../../../../utils/commonHelpers';
import history from '../../../App/history';
import { getStory, getStoryId, getStorySuccess, storyOnSubmit } from '../../successStorySlice';
import Input from '../../../../reusableComponents/HookForm/Input';
import SelectField from '../../../../reusableComponents/HookForm/Select';
import { getPrograms } from '../../../Program/programSlice';
import { getUserProfiles, getUsers, setApiParams } from '../../../Users/userSlice';
import RadioGroupField from '../../../../reusableComponents/HookForm/RadioGroup';

const relation = ['MOTHER', 'FATHER', 'GUARDIAN'];

const SuccessStoryAddEdit = (props) => {
	const { register, handleSubmit, errors, control, watch, setValue, reset, getValues } = useForm({
		defaultValues: {
			...props.initialValues,
			userType: props.initialValues.userType || 'existingUser',
		},
	});

	const watchUser = watch(['userType', 'userId', 'profileId']);

	useEffect(() => {
		if (props.initialValues.userType !== watchUser.userType) {
			const values = getValues();
			reset(omitWrapper(['user'], values));
		}
	}, [watchUser.userType]);

	useEffect(() => {
		if (watchUser.userId && !props.initialValues.userId) {
			props.getUserProfiles(watchUser.userId.value);
			const user = find(props.users, (val) => val.id === watchUser.userId.value);
			setValue('user.fullName', user.fullName ? user.fullName : '');
			setValue('user.mobile', user.mobile ? user.mobile : '');
			setValue('user.relationship', { label: user.relationship, value: user.relationship });
			setValue('user.location', user.location ? user.location.address : '');
		}
	}, [watchUser.userId]);

	useEffect(() => {
		if (watchUser.profileId && !props.initialValues.profileId) {
			const profile = find(props.profiles, (val) => val.id === watchUser.profileId.value);
			setValue('user.childName', profile.fullName);
			setValue('user.childAge', profile.age);
		}
	}, [watchUser.profileId]);

	const dispatch = useDispatch();

	return (
		<form className="form ltr-support" onSubmit={handleSubmit(props.onSubmit)}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} Success Story</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="6">
						<SelectField
							label="Program"
							name="program"
							options={ArrayMaybe(props.programs).map((program) => ({
								label: `${program.title} ${
									isNotEmptyObject(program.ageRange) ? `(${program.ageRange.min}-${program.ageRange.max}yrs)` : ''
								}`,
								value: {
									programId: program.id,
									mentorId: program.mentorId,
								},
							}))}
							control={control}
						/>
					</Col>
					<Col sm="12">
						<RadioGroupField
							label="Success Story For.."
							labelName="userType"
							ref={register}
							fields={[
								{ label: 'Existing User', value: 'existingUser' },
								{ label: 'Custom User', value: 'customUser' },
							]}
						/>
					</Col>
					{watchUser.userType === 'existingUser' && (
						<>
							<Col sm="6">
								<SelectField
									label="Search User"
									placeholder="Enter Mobile Number"
									name="userId"
									options={ArrayMaybe(props.users).map((user) => ({
										label: user.mobile,
										value: user.id,
									}))}
									control={control}
									onInputChangeCallback={(val) => {
										dispatch(setApiParams({ limit: null, query: val.replaceAll('+', '') }));
										dispatch(getUsers());
									}}
								/>
							</Col>
							<Col sm="6">
								<SelectField
									label="Child Profile"
									name="profileId"
									options={ArrayMaybe(props.profiles).map((profile) => ({
										label: `${profile.fullName}(${profile.age} yrs)`,
										value: profile.id,
									}))}
									control={control}
								/>
							</Col>
						</>
					)}

					<p style={{ padding: '0 15px', marginBottom: '10px', width: '100%' }}>USER DETAILS</p>
					<Col sm="6">
						<Input
							label="Parent Name"
							name="user.fullName"
							ref={register({ required: true })}
							error={errors.user && errors.user.fullName}
							isRequired
						/>
					</Col>
					<Col sm="6">
						<Input label="Mobile Number" disabled={watchUser.userType === 'existingUser'} name="user.mobile" ref={register} />
					</Col>
					<Col sm="6">
						<SelectField
							label="Relationship"
							name="user.relationship"
							options={relation.map((val) => ({
								label: val,
								value: val,
							}))}
							control={control}
							isRequired
							error={errors.user && errors.user.relationship}
						/>
					</Col>
					<Col sm="6">
						<Input
							label="Child Name"
							name="user.childName"
							ref={register({ required: true })}
							error={errors.user && errors.user.childName}
							isRequired
						/>
					</Col>
					<Col sm="6">
						<Input
							label="Child Age"
							name="user.childAge"
							type="number"
							ref={register({ required: true })}
							error={errors.user && errors.user.childAge}
							isRequired
						/>
					</Col>
					<Col sm="6">
						<Input
							label="Location"
							name="user.location"
							ref={register({ required: true })}
							error={errors.user && errors.user.location}
							isRequired
						/>
					</Col>

					<Col sm="12">
						<Dropzone
							label="Screenshot"
							isRequired
							validate={(val) => (val && val.length) || 'Required'}
							name="screenshot"
							error={errors.screenshot}
							control={control}
							withCaption
						/>
					</Col>
					<Col sm="12">
						<Dropzone
							label="Supporting Photos"
							isRequired
							validate={(val) => (val && val.length) || 'Required'}
							name="photos"
							error={errors.photos}
							control={control}
							isMulti
							noOfFiles={3}
							withCaption
						/>
					</Col>
					<Col sm="12" className="mt-4">
						<Button size="sm" color="primary" type="submit">
							{isNotEmptyObject(props.initialValues) ? 'Save' : 'Create'}
						</Button>
						<Button size="sm" color="success" type="button" onClick={() => history.push('/success-stories/list')}>
							Cancel
						</Button>
					</Col>
				</Row>
			</Box>
		</form>
	);
};

const SuccessStoryForm = (props) => {
	const successStoryState = useSelector(({ successStory, program, mentor, user }) => ({
		loading: successStory.loading,
		story: successStory.story,
		isSubmitting: successStory.isSubmitting,
		storyId: successStory.storyId,
		programs: program.programs,
		mentors: mentor.mentors,
		users: user.users,
		profiles: user.profiles,
	}));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPrograms());
		dispatch(getStoryId());
		if (props.location.search) {
			dispatch(getStory(pickWrapper(['id'], parseQueryParams(props.location.search))));
		}
		return () => {
			dispatch(getStorySuccess({}));
		};
	}, [props.location.search]);

	const modifyParamsBeforeSubmit = (params) => {
		let modifiedParams = params;

		if (params.userType === 'existingUser') {
			modifiedParams.userId = modifiedParams.userId.value;
			modifiedParams.profileId = modifiedParams.profileId.value;
			modifiedParams = { ...modifiedParams, user: omitWrapper(['mobile'], modifiedParams.user) };
		}

		modifiedParams.user.childAge = +modifiedParams.user.childAge;
		modifiedParams.user.relationship = modifiedParams.user.relationship.value;

		if (!modifiedParams.program) modifiedParams = omitWrapper(['program'], modifiedParams);
		else {
			modifiedParams.programId = modifiedParams.program.value.programId;
			modifiedParams.mentorId = modifiedParams.program.value.mentorId;
			modifiedParams = omitWrapper(['program'], modifiedParams);
		}

		modifiedParams = omitWrapper(['userType'], modifiedParams);

		return modifiedParams;
	};

	const modifyBeforeSetStory = (params) => {
		const modifiedParams = {
			screenshot: [
				{
					photo: [{ id: params.screenshot.id, preview: getSanitizedImageUrl(params.screenshot.url) }],
					caption: params.screenshot.caption || '',
				},
			],
			photos: params.photos.map((photo) => ({
				photo: { id: photo.id, preview: getSanitizedImageUrl(photo.url) },
				caption: photo.caption || '',
			})),
		};
		if (isNotEmptyObject(params.program)) {
			modifiedParams.program = {
				label: params.program.title,
				value: {
					programId: params.program.id,
					mentor: params.program.mentorId,
				},
			};
		}

		if (isNotEmptyObject(params.mentor)) {
			modifiedParams.mentorId = {
				label: params.mentor.fullName,
				value: params.mentor.id,
			};
		}

		if (params.userId) {
			modifiedParams.userId = {
				label: params.existingUser.mobile,
				value: params.existingUser.id,
			};

			modifiedParams.profileId = {
				label: params.childProfile.fullName,
				value: params.childProfile.id,
			};

			modifiedParams.userType = 'existingUser';
		} else {
			modifiedParams.userType = 'customUser';
		}

		modifiedParams.user = {
			...params.user,
			relationship: {
				label: params.user.relationship,
				value: params.user.relationship,
			},
		};

		return modifiedParams;
	};

	const handleSubmit = async (params) => {
		let isValid = true;
		if (params.userType === 'existingUser' && !params.profileId) {
			isValid = false;
			showToast('error', 'Please select a profile!');
		}

		if (params.user.childAge < 0) {
			isValid = false;
			showToast('error', 'Age cannot be negative');
		}

		if (isValid) {
			const modifiedParams = modifyParamsBeforeSubmit(params);
			dispatch(
				storyOnSubmit({
					...modifiedParams,
					isEdit: !!props.location.search,
					id: props.location.search ? parseQueryParams(props.location.search).id : successStoryState.storyId,
				})
			);
		}
	};

	if (successStoryState.loading) {
		return <div>Loading...</div>;
	}

	return (
		<LoadingOverlay isLoading={successStoryState.isSubmitting} msg="Submitting Story...">
			<Card>
				<CardBody>
					<SuccessStoryAddEdit
						initialValues={isNotEmptyObject(successStoryState.story) ? modifyBeforeSetStory(successStoryState.story) : {}}
						onSubmit={handleSubmit}
						storyId={parseQueryParams(props.location.search).id || successStoryState.storyId}
						programs={successStoryState.programs}
						mentors={successStoryState.mentors}
						users={successStoryState.users}
						profiles={successStoryState.profiles}
						getUserProfiles={(userId) => dispatch(getUserProfiles({ userId }))}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

export default SuccessStoryForm;
