import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { getDiyIdSuccess } from '../diySlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getDiyIdSaga() {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/diy/id`);
	if (res.status === 200) {
		yield put(actionSpreader(getDiyIdSuccess.type, { diyId: res.data }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
