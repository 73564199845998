/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { getOpenings, openingOnSubmit, setApiParams, setOpeningStatus } from '../../careerSlice';
import { accessDeepObject, isNotEmptyArray } from '../../../../utils/commonHelpers';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import { STATUS_CAREER_ACTIVE, STATUS_CAREER_INACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';
import Filter from '../../../../reusableComponents/Filter';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (openings, pageNo, rowsCount, submitHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < openings.length; i += 1) {
		r.push({
			id: initialCount,
			title: openings[i].title,
			dept: openings[i].dept,
			location: openings[i].location,
			status:
				openings[i].status === STATUS_CAREER_INACTIVE ? (
					<div className="badge badge-warning">Inactive</div>
				) : (
					<div className="badge badge-success">Active</div>
				),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/careers/add-edit?id=${openings[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							if (openings[i].status === STATUS_CAREER_ACTIVE) {
								submitHandler({ id: openings[i].id, status: STATUS_CAREER_INACTIVE, pos: i });
							} else {
								submitHandler({ id: openings[i].id, status: STATUS_CAREER_ACTIVE, pos: i });
							}
						}}
					>
						Mark {openings[i].status === STATUS_CAREER_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const CareersTable = () => {
	const careerState = useSelector(({ careers }) => ({
		loading: careers.loading,
		openings: careers.openings,
		pageNo: careers.pageNo,
		total: careers.total,
		apiParams: careers.apiParams,
	}));

	const dispatch = useDispatch();

	const [openingRows, setOpeningRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [searchTerm, setSearchTerm] = useState(careerState.apiParams.query);

	useEffect(() => {
		dispatch(getOpenings());
	}, []);

	useEffect(() => {
		setPageOfItems(careerState.pageNo);
	}, [careerState.pageNo]);

	const heads = [
		{
			accessor: 'title',
			Header: 'Title',
		},
		{
			accessor: 'dept',
			Header: 'Department',
		},
		{
			accessor: 'location',
			Header: 'Location',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		const dd = window.confirm('Are you sure?');
		if (dd) {
			if (!isUndefined(params.pos)) {
				dispatch(setOpeningStatus({ ...params }));
			} else {
				dispatch(openingOnSubmit(params));
			}
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(careerState.openings)) {
			const structuredRows = createRows(
				careerState.openings,
				careerState.pageNo,
				accessDeepObject('apiParams.limit', careerState),
				submitHandler
			);
			setOpeningRows(structuredRows);
		} else {
			setOpeningRows([]);
		}
	}, [careerState.openings]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = careerState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getOpenings());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', careerState),
					skip: accessDeepObject('apiParams.limit', careerState) * (pageOfItems - 1),
				})
			);
			dispatch(getOpenings());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getOpenings());
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8} className="mb-3">
							<h3>Careers Listing</h3>
						</Col>
						<Col sm={6}>
							<Filter
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								onSearch={onSearch}
								placeholder="Search by Title or Department"
							/>
						</Col>
						<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push(`/careers/add-edit`);
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(openingRows) && (
						<>
							<DataPaginationTable heads={heads} rows={openingRows} onSort={onSort} />
							<Pagination
								itemsCount={careerState.total}
								itemsToShow={accessDeepObject('apiParams.limit', careerState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{careerState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default CareersTable;
