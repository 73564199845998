import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { logoutUser } from '../Authentication/authSlice';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

class Layout extends Component {
	changeSidebarVisibility = () => {
		const { dispatch } = this.props;
		dispatch(changeSidebarVisibility());
	};

	changeMobileSidebarVisibility = () => {
		const { dispatch } = this.props;
		dispatch(changeMobileSidebarVisibility());
	};

	logoutUser = () => {
		const { dispatch } = this.props;
		dispatch(logoutUser());
	};

	render() {
		const { sidebar, auth } = this.props;

		return (
			<div className={`layout${sidebar.collapse ? ' layout--collapse' : ''}`}>
				<Topbar
					changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					changeSidebarVisibility={this.changeSidebarVisibility}
					auth={auth}
					logout={this.logoutUser}
				/>
				<Sidebar sidebar={sidebar} changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} />
			</div>
		);
	}
}

export default withRouter(
	connect((state) => ({
		auth: state.auth,
		sidebar: state.sidebar,
	}))(Layout)
);

Layout.propTypes = {
	dispatch: PropTypes.func.isRequired,
	sidebar: SidebarProps.isRequired,
};
