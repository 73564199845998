import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pickWrapper, isNotEmptyObject, accessDeepObject } from '../../../utils/commonHelpers';
import { registerUser } from '../authSlice';

import RegisterForm from './components/RegisterForm';

const Register = () => {
	const authState = useSelector(({ auth }) => ({ isLoading: auth.loading, errors: auth.errors }));
	const formState = useSelector(({ form }) => ({ register: form.register_form }));

	const dispatch = useDispatch();

	const handleRegister = useCallback(() => {
		const values = accessDeepObject('register.values', formState);
		if (isNotEmptyObject(values)) {
			dispatch(registerUser(values));
		}
	}, [dispatch, formState]);

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<h3 className="account__title">
							Welcome to
							<span className="account__logo">
								{' '}
								Ivy
								<span className="account__logo-accent">Pods</span>
							</span>
						</h3>
						<h4 className="account__subhead subhead">Admin Panel</h4>
					</div>
					<RegisterForm onSubmit={handleRegister} {...pickWrapper(['errors', 'isLoading'], authState)} />
				</div>
			</div>
		</div>
	);
};

export default Register;
