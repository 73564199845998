/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { updateClassAndUpdateList } from './actions/class.actions';

const classSlice = createSlice({
	name: 'classes',
	initialState: {
		loading: false,
		classes: [],
		selectedClasses: [],
		class: {},
		classId: null,
		total: 0,
		pageNo: 1,
		polls: [],
		poll: null,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
		badges: [],
		videos: [], // For video Select
		video: null, // For Watch video
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query || '' },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				programId: action.payload.programId ? action.payload.programId : undefined,
			};
		},
		getClasses: () => {},
		getClassesSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.classes = action.payload.classes;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getClassId: () => {},
		getClassIdSuccess: (state, action) => {
			state.classId = action.payload.classId;
		},
		getClass: () => {},
		clearClasses: (state) => {
			state.classes = [];
		},
		getClassSuccess: (state, action) => {
			state.class = action.payload.class;
		},
		deleteClass: () => {},
		deleteClassSuccess: (state, action) => {
			state.classes.splice(action.payload.pos, 1);
		},
		classOnSubmit: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		getPolls: () => {},
		getPollsSuccess: (state, action) => {
			state.polls = action.payload.polls;
		},
		getPoll: () => {},
		getPollSuccess: (state, action) => {
			state.poll = action.payload;
		},
		submitPolls: () => {},
		deleteAllPolls: () => {},
		deleteAllPollsSuccess: (state) => {
			state.polls = [];
		},
		fetchBadges: () => {},
		fetchBadgesSuccess: (state, action) => {
			state.badges = action.payload.badges;
		},
		fetchVideos: () => {},
		fetchVideosSuccess: (state, action) => {
			state.videos = action.payload.videos;
		},
		fetchVideo: () => {},
		clearVideo: (state) => {
			state.video = null;
		},
		fetchVideoSuccess: (state, action) => {
			state.video = action.payload.video;
		},
	},
	extraReducers: {
		[updateClassAndUpdateList.fulfilled]: (state, action) => {
			const {
				payload: { classIndex, classData },
			} = action;
			state.classes[classIndex] = {
				...state.classes[classIndex], // preserve programDetails as it is not updated
				...classData,
			};
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getClasses,
	getClassesSuccess,
	getClassId,
	getClassIdSuccess,
	getClass,
	getClassSuccess,
	deleteClass,
	deleteClassSuccess,
	classOnSubmit,
	toggleIsSubmitting,
	getPolls,
	getPollsSuccess,
	getPoll,
	getPollSuccess,
	submitPolls,
	deleteAllPolls,
	clearClasses,
	deleteAllPollsSuccess,
	fetchBadges,
	fetchBadgesSuccess,
	fetchVideos,
	fetchVideosSuccess,
	fetchVideo,
	fetchVideoSuccess,
	clearVideo,
} = classSlice.actions;

export default classSlice.reducer;
