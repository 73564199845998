import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FeaturedPostAddEditForm } from './components/FeaturedPostAddEditForm';
import { FeaturedPostTable } from './components/FeaturedPostTable';

const FeaturedPost = () => {
	return (
		<div>
			<Switch>
				<Route path="/featured-post/list" component={FeaturedPostTable} />
				<Route path="/featured-post/add-edit" component={FeaturedPostAddEditForm} />
			</Switch>
		</div>
	);
};

export default FeaturedPost;
