import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting } from '../batchSlice';

export const createBatch = createAsyncThunk('batch/createBatch', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const { data: batchId } = await fetchWrapper('/v1/admin/batch/id', { method: 'GET' });

		const valuesToSubmit = {
			...data,
			_id: batchId,
		};

		const res = await fetchWrapper('/v1/admin/batch', {
			method: 'POST',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Batch created succcessfully!!`);
			history.push('/batch/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateBatch = createAsyncThunk('batch/updateBatch', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const valuesToSubmit = data;

	try {
		const res = await fetchWrapper('/v1/admin/batch', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Batch updated succcessfully!!`);
			history.push('/batch/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateBatchAndUpdateList = createAsyncThunk(
	'batch/updateBatchAndUpdateList',
	async ({ batchId, batchIndex, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			_id: batchId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/batch', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Batch updated succcessfully!!`);
				return {
					batchIndex,
					batchData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
