import React from 'react';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { PaginationLink } from 'reactstrap';
import Box from 'reusableComponents/Box';

const PaginationNew = ({ showNext, showPrevious, goBack, goForward }) => {
	return (
		<Box d="flex" jc="flex-end">
			{showPrevious ? (
				<PaginationLink className="pagination__link pagination__link--arrow mr-2" type="button" onClick={goBack}>
					<ChevronLeftIcon className="pagination__link-icon" />
				</PaginationLink>
			) : null}
			{showNext ? (
				<PaginationLink className="pagination__link pagination__link--arrow ml-2" type="button" onClick={goForward}>
					<ChevronRightIcon className="pagination__link-icon" />
				</PaginationLink>
			) : null}
		</Box>
	);
};
export default PaginationNew;
