import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader, stringifyQueryParams } from '../../../utils/commonHelpers';
import { fetchBadgesSuccess, toggleLoading } from '../classSlice';
import { setError } from '../../App/globalSlice';

export function* fetchBadgesSaga({ payload }) {
	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/badges${stringifyQueryParams(payload)}`);
	if (res.status === 200) {
		yield put(actionSpreader(fetchBadgesSuccess.type, { badges: res.data.badges }));
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
