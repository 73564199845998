import { call, put } from 'redux-saga/effects';
import { actionSpreader } from 'utils/commonHelpers';
import { setError } from 'containers/App/globalSlice';
import { toggleLoading, searchDropboxFileSuccess } from '../videoSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* searchVideoFileSaga({ payload }) {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));
	const { data, message, status } = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/dropbox/search`, {
		body: JSON.stringify(payload),
	});
	yield put(actionSpreader(toggleLoading.type, { loading: false }));
	if (status !== 200 || data.error) {
		yield put(actionSpreader(setError.type, { errors: `🚫️ ${message || data.error}` }));
	} else {
		yield put(actionSpreader(searchDropboxFileSuccess.type, { ...data }));
	}
}
