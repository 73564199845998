import { call } from 'redux-saga/effects';
import { requestSaga } from '../../../utils/requestSaga';

export function* mutateFaqSaga({ payload }) {
	const { isEdit } = payload;
	const res = yield call(requestSaga, isEdit ? 'PUT' : 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/faq`, {
		body: JSON.stringify(payload),
	});
	return res;
}
