import { call, put } from 'redux-saga/effects';

import { toggleLoading, setBatchStatusSuccess } from '../batchSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader, showToast, pickWrapper } from '../../../utils/commonHelpers';
import { requestSaga } from '../../../utils/requestSaga';

export function* setBatchStatusSaga({ payload }: ReturnType<() => { type: string; payload: { pos: number; status: string; id: string } }>) {
	const modifiedParams = pickWrapper(['id', 'status'], payload);
	const res = yield call(requestSaga, 'PUT', `${process.env.REACT_APP_API_URL}/v1/admin/batch/status`, {
		body: JSON.stringify(modifiedParams),
	});
	if (res.status === 200 && res.data) {
		yield put(actionSpreader(setBatchStatusSuccess.type, { pos: payload.pos, status: payload.status }));
		showToast('success', `👍️ Batch Status Successfully Changed!`);
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
