import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Filter from 'reusableComponents/Filter';
import Pagination from '../../../reusableComponents/Pagination/Pagination';
import DataPaginationTable from '../../../reusableComponents/Tables/DataPaginationTable';
import { isNotEmptyArray } from '../../../utils/commonHelpers';
import { fetchLearningActivities, syncLearningActivities } from '../actions/learningActivities.actions';
import { setApiParams, setPage } from '../LearningActivitiesSlice';
import { TLearningActivitiesState, TLearningActivity } from '../utils/types';

const heads = [
	{
		accessor: 'title',
		Header: 'Title',
	},
	{
		accessor: 'type',
		Header: 'Type',
	},
	{
		accessor: 'grade',
		Header: 'Grade',
	},
	{
		accessor: 'subject',
		Header: 'Subject',
	},
	{
		accessor: 'startAt',
		Header: 'Start At',
	},
	// {
	// 	accessor: 'actions',
	// 	Header: 'Actions',
	// },
];

const createNewRows = ({ activities }: { activities: TLearningActivity[] }) => {
	return activities.map((activity) => {
		const { id, title, type, grade, subject, startAt } = activity;

		return {
			id,
			title,
			type,
			grade,
			subject,
			startAt,
			// actions: <Actions leadId={id} status={salesStatus} hasPickedLead={hasPickedLead} />,
		};
	});
};

const LearningActivitiesList: FC = () => {
	const dispatch = useDispatch();

	const activitiesState: Pick<
		TLearningActivitiesState,
		'activities' | 'activitiesLoading' | 'isSubmitting' | 'apiParams' | 'total' | 'page'
	> = useSelector(({ learningActivities }) => ({
		activities: learningActivities.activities,
		activitiesLoading: learningActivities.activitiesLoading,
		isSubmitting: learningActivities.isSubmitting,
		apiParams: learningActivities.apiParams,
		total: learningActivities.total,
		page: learningActivities.page,
	}));

	const [activitiesRow, setActivitiesRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [syncStartRowIndex, setSyncStartRowIndex] = useState(3);
	const [syncEndRowIndex, setSyncEndRowIndex] = useState<number>();

	React.useEffect(() => {
		dispatch(fetchLearningActivities({ ...activitiesState.apiParams }));
	}, [activitiesState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(activitiesState.page);
	}, [activitiesState.page]);

	React.useEffect(() => {
		if (isNotEmptyArray(activitiesState.activities)) {
			const structuredRows = createNewRows({
				activities: activitiesState.activities,
			});
			setActivitiesRow(structuredRows);
		} else {
			setActivitiesRow([]);
		}
	}, [activitiesState.activities]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = activitiesState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={4}>
						<h3>Learning Activities Listing</h3>
					</Col>
					<Col sm={2}>
						<Filter
							hideSearchIcon
							searchTerm={syncStartRowIndex}
							setSearchTerm={setSyncStartRowIndex}
							placeholder="Start Row Number"
							helpText="Enter the row number to start syncing from"
							type="number"
							min={3}
							onSearch={() => null}
						/>
					</Col>
					<Col sm={3}>
						<Filter
							hideSearchIcon
							searchTerm={syncEndRowIndex}
							setSearchTerm={setSyncEndRowIndex}
							placeholder="End Row Number"
							helpText="Enter the row number to end syncing (BE CAUTIOUS)"
							type="number"
							min={3}
							onSearch={() => null}
						/>
					</Col>
					<Col sm={3}>
						<Button
							color="primary"
							disabled={activitiesState.isSubmitting}
							onClick={() =>
								dispatch(
									syncLearningActivities({
										startRowIndex: syncStartRowIndex || undefined,
										endRowIndex: syncEndRowIndex || undefined,
									})
								)
							}
						>
							{activitiesState.isSubmitting ? 'Wait for 2-5 mins, you can close or refresh page' : 'Sync Activities'}
						</Button>
					</Col>
				</Row>
				{isNotEmptyArray(activitiesRow) && (
					<>
						<DataPaginationTable heads={heads} rows={activitiesRow} />
						<Pagination
							itemsCount={activitiesState.total}
							itemsToShow={activitiesState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{activitiesState.total === 0 && <div className="">No data</div>}
			</CardBody>
		</Card>
	);
};

export default LearningActivitiesList;
