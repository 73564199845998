import { call } from 'redux-saga/effects';
import { ObjectMaybe } from './commonHelpers';

export const getAuthTokenFromLocalStorage = () => localStorage.getItem('jwtToken');

export function request(method, url, options) {
	return fetch(url, {
		method,
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAuthTokenFromLocalStorage()}`,
		},
		...options,
	})
		.then((res) => res.json())
		.then((res) => res)
		.catch((err) => err);
}

export const requestImage = (method, url, options) => {
	return fetch(url, {
		method,
		credentials: 'same-origin',
		headers: {
			Authorization: `Bearer ${getAuthTokenFromLocalStorage()}`,
		},
		...options,
	})
		.then((res) => res.json())
		.then((res) => res)
		.catch((err) => err);
};

// eslint-disable-next-line consistent-return
export function* requestSaga(method, url, options) {
	try {
		const data = yield call(request, method, url, options);
		const response = ObjectMaybe(data).response || data;
		return response;
	} catch (e) {
		console.log('requestSaga error', e, e.response);
		// NOTE: use if subsequent actions passed from outside
		// yield effects.all(subsequentActions.map(x => effects.put(x())));
	}
}

export function requestSagaImage(method, url, images) {
	try {
		const promises = [];
		images.forEach((i) => {
			const formData = new FormData();
			formData.append('file', i);
			promises.push(requestImage(method, url, { body: formData }));
		});
		return Promise.all(promises)
			.then((res) => res)
			.catch((err) => err);
	} catch (e) {
		console.log('requestSaga error', e, e.response);
		return e;
		// NOTE: use if subsequent actions passed from outside
		// yield effects.all(subsequentActions.map(x => effects.put(x())));
	}
}
