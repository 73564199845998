/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { getTeamMates, teamMateOnSubmit, setApiParams, setTeamMateStatus } from '../../aboutSlice';
import { accessDeepObject, isNotEmptyArray } from '../../../../utils/commonHelpers';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';
import Filter from '../../../../reusableComponents/Filter';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (teamMates, pageNo, rowsCount, submitHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < teamMates.length; i += 1) {
		r.push({
			id: initialCount,
			fullName: teamMates[i].fullName,
			designation: teamMates[i].designation,
			linkedInUrl: teamMates[i].linkedInUrl,
			status:
				teamMates[i].status === STATUS_INACTIVE ? (
					<div className="badge badge-warning">Inactive</div>
				) : (
					<div className="badge badge-success">Active</div>
				),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/about-us/add-edit?id=${teamMates[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							if (teamMates[i].status === STATUS_ACTIVE) {
								submitHandler({ id: teamMates[i].id, status: STATUS_INACTIVE, pos: i });
							} else {
								submitHandler({ id: teamMates[i].id, status: STATUS_ACTIVE, pos: i });
							}
						}}
					>
						Mark {teamMates[i].status === STATUS_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const AboutTable = () => {
	const aboutState = useSelector(({ about }) => ({
		loading: about.loading,
		teamMates: about.teamMates,
		pageNo: about.pageNo,
		total: about.total,
		apiParams: about.apiParams,
	}));

	const dispatch = useDispatch();

	const [teamMateRows, setTeamMateRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [searchTerm, setSearchTerm] = useState(aboutState.apiParams.query);

	useEffect(() => {
		dispatch(getTeamMates());
	}, []);

	useEffect(() => {
		setPageOfItems(aboutState.pageNo);
	}, [aboutState.pageNo]);

	const heads = [
		{
			accessor: 'fullName',
			Header: 'Name',
		},
		{
			accessor: 'designation',
			Header: 'Designation',
		},
		{
			accessor: 'linkedInUrl',
			Header: 'LinkedIn URL',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		const dd = window.confirm('Are you sure?');
		if (dd) {
			if (!isUndefined(params.pos)) {
				dispatch(setTeamMateStatus({ ...params }));
			} else {
				dispatch(teamMateOnSubmit(params));
			}
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(aboutState.teamMates)) {
			const structuredRows = createRows(
				aboutState.teamMates,
				aboutState.pageNo,
				accessDeepObject('apiParams.limit', aboutState),
				submitHandler
			);
			setTeamMateRows(structuredRows);
		} else {
			setTeamMateRows([]);
		}
	}, [aboutState.teamMates]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = aboutState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getTeamMates());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', aboutState),
					skip: accessDeepObject('apiParams.limit', aboutState) * (pageOfItems - 1),
				})
			);
			dispatch(getTeamMates());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getTeamMates());
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={8} className="mb-3">
							<h3>Team Mates Listing</h3>
						</Col>
						<Col sm={6}>
							<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={onSearch} placeholder="Search by Name" />
						</Col>
						<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push(`/about-us/add-edit`);
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(teamMateRows) && (
						<>
							<DataPaginationTable heads={heads} rows={teamMateRows} onSort={onSort} />
							<Pagination
								itemsCount={aboutState.total}
								itemsToShow={accessDeepObject('apiParams.limit', aboutState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{aboutState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default AboutTable;
