import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DiyTable from './components/DiyTable';
import AddEditDiy from './components/AddEdit';

const DIY = () => {
	return (
		<div>
			<Switch>
				<Route path="/diy/list" component={DiyTable} />
				<Route path="/diy/add-edit" component={AddEditDiy} />
			</Switch>
		</div>
	);
};

export default DIY;
