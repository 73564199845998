/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import { OpenModalAction } from 'connectors/ModalController/actions';
import { ModalTypes } from 'connectors/ModalController/types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import Filter from 'reusableComponents/Filter';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import {
	accessDeepObject,
	getSanitizedImageUrl,
	isAllowedEmail,
	isNotEmptyArray,
	ObjectMaybe,
	showToast,
	startsWithDigit,
} from 'utils/commonHelpers';
import { dateTimeConverter } from 'utils/dateHelper';
import theme from 'utils/theme';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getUsers, setApiParams } from '../userSlice';
import CertificatesModal from './CertificatesModal';

const Actions = ({ userDetails, loggedInUser, setCertificatesModalParams }) => {
	const { id: userId, packages, subscriptionDetails, email } = userDetails;

	const dispatch = useDispatch();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				{isAllowedEmail('REACT_APP_GRANT_SUBSCRIPTION_ADMINS', loggedInUser.email[0]) && (
					<DropdownItem
						onClick={() =>
							dispatch(OpenModalAction(ModalTypes.USER_SUBSCRIPTION_MODAL, { userId, packages, subscriptionDetails, loggedInUser }))
						}
					>
						Subscription Details
					</DropdownItem>
				)}
				{isAllowedEmail('REACT_APP_GRANT_SUBSCRIPTION_ADMINS', loggedInUser.email[0]) && (
					<DropdownItem onClick={() => dispatch(OpenModalAction(ModalTypes.CREATE_PAYMENT_LINK_MODAL, { userDetails, loggedInUser }))}>
						Create Payment Link
					</DropdownItem>
				)}
				{isAllowedEmail('REACT_APP_GRANT_SUBSCRIPTION_ADMINS', loggedInUser.email[0]) && (
					<DropdownItem onClick={() => dispatch(OpenModalAction(ModalTypes.GRANT_SUBSCRIPTION_MODAL, { userDetails, loggedInUser }))}>
						Grant Subscription
					</DropdownItem>
				)}
				<DropdownItem onClick={() => setCertificatesModalParams({ isOpen: true, userId })}>View Certificates</DropdownItem>
				<DropdownItem onClick={() => dispatch(OpenModalAction(ModalTypes.GRADE_PROMOTION_MODAL, { userId, loggedInUser }))}>
					Grade Promotion
				</DropdownItem>
				<DropdownItem onClick={() => dispatch(OpenModalAction(ModalTypes.VIEW_COURSES, { userId, loggedInUser }))}>
					View Courses
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

function createRows({ users, loggedInUser, setCertificatesModalParams }) {
	return users.map((userDetails) => {
		const {
			id,
			createdAt,
			profilePic,
			fullName,
			email,
			mobile,
			secondaryMobile,
			gender,
			totalReferralCount,
			processedReferralCount,
		} = userDetails;

		return {
			id,
			profilePic: ObjectMaybe(profilePic).url ? (
				<Box h="3rem" w="3rem" bRad="50%">
					<img
						width="100%"
						height="100%"
						style={{ borderRadius: '50%' }}
						src={getSanitizedImageUrl(ObjectMaybe(profilePic).url)}
						alt="profile-pic"
					/>
				</Box>
			) : (
				<Box
					d="flex"
					jc="center"
					ai="center"
					fs="2rem"
					bg={theme.clrs.cGraphite}
					c={theme.clrs.cWhite}
					h="3rem"
					w="3rem"
					bRad="50%"
					tt="capitalize"
				>
					{fullName ? fullName[0] : null}
				</Box>
			),
			fullName: fullName || '---',
			email: email && email.length ? email[0] : '---',
			mobile,
			secondaryMobile: secondaryMobile || '---',
			totalReferralCount,
			processedReferralCount,
			createdAt: dateTimeConverter(createdAt, 'format', 'dd MMM yyyy (k:mm)'),
			gender: gender || '---',
			actions: (
				<Actions
					{...{
						userDetails,
						loggedInUser,
						setCertificatesModalParams,
					}}
				/>
			),
		};
	});
}

export const UserTable = () => {
	const userState = useSelector(({ user }) => ({
		loading: user.loading,
		users: user.users,
		pageNo: user.pageNo,
		total: user.total,
		apiParams: user.apiParams,
	}));

	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	const dispatch = useDispatch();

	const [userRows, setUserRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState<string>(userState.apiParams.query);
	const [certificatesModalParams, setCertificatesModalParams] = useState({ isOpen: false, userId: null });

	const heads = [
		{ accessor: 'profilePic', Header: 'Profile Picture' },
		{ accessor: 'fullName', Header: 'Full Name' },
		{ accessor: 'email', Header: 'Email' },
		{ accessor: 'mobile', Header: 'Mobile No.' },
		{ accessor: 'secondaryMobile', Header: 'Whatsapp No.' },
		{ accessor: 'totalReferralCount', Header: 'Total Referral Count' },
		{ accessor: 'processedReferralCount', Header: 'Referral Processed Count' },
		{ accessor: 'createdAt', Header: 'Created At' },
		{ accessor: 'gender', Header: 'Gender' },
		{ accessor: 'actions', Header: 'Actions' },
	];

	React.useEffect(() => {
		setPageOfItems(userState.pageNo);
	}, [userState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(userState.users)) {
			const structuredRows = createRows({ users: userState.users, setCertificatesModalParams, loggedInUser });
			setUserRows(structuredRows);
		} else {
			setUserRows([]);
		}
	}, [userState.users]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = userState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getUsers());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();

		if (!searchTerm) {
			showToast('error', 'Please enter the valid mobile number');
		} else {
			dispatch(
				setApiParams({
					query: !searchTerm.startsWith('+91-') && startsWithDigit(searchTerm) ? `+91-${searchTerm}` : searchTerm,
					limit: 20,
					skip: 0,
				})
			);
			dispatch(getUsers());
		}
	};

	const [selectedNumber, setSelectedNumber] = useState('1');
	const [selectedUserType, setSelectedUserType] = useState('prePurchase');
	const [apiCallUnderProgress, setApiCallUnderProgress] = useState(false);
	const [matchingUserResult, setMatchingUserResult] = useState([]);

	return (
		<>
			{certificatesModalParams.isOpen && (
				<CertificatesModal
					userId={certificatesModalParams.userId}
					closeModal={() => {
						setCertificatesModalParams({ isOpen: false, userId: null });
					}}
				/>
			)}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={12}>
								<h3 className="mb-4">Users Search Panel</h3>
							</Col>
							<Col sm={6}>
								<Filter
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onSearch={onSearch}
									placeholder="Search by Name, Mobile or Whatsapp No."
									helpText='Please enter the starting prefix of search term. For ex: "John" will be matched by "Jo..".
									Enter full country code when searching by mobile number.'
								/>
							</Col>
						</Row>
						{isNotEmptyArray(userRows) ? (
							<>
								<DataPaginationTable heads={heads} rows={userRows} />
								<Pagination
									itemsCount={userState.total}
									itemsToShow={accessDeepObject('apiParams.limit', userState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						) : null}
						{userState.total === 0 && <div className="">No data</div>}
					</CardBody>
					<CardBody>
						<h3 className="mb-4">Search Test Mobile User for Bucket</h3>
						<Col sm={12}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'center',
									margin: '0.5rem 0',
									flexWrap: 'wrap',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										margin: '0.5rem 0',
										width: '30%',
									}}
								>
									<p
										style={{
											padding: '0 1rem 0 0',
										}}
									>
										Select bucket character:{' '}
									</p>
									<select
										id="bucketCharacter"
										value={selectedNumber}
										onChange={(e) => {
											setSelectedNumber(e.target.value);
										}}
										style={{
											padding: '0.5rem',
										}}
									>
										{'0123456789abcdef'.split('').map((op, index) => (
											<option key={op} value={op}>
												{op}
											</option>
										))}
									</select>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										margin: '0.5rem 0',
										width: '30%',
									}}
								>
									<p
										style={{
											padding: '0 1rem 0 0',
										}}
									>
										Select user type:{' '}
									</p>
									<select
										id="typeOfUser"
										value={selectedUserType}
										onChange={(e) => {
											setSelectedUserType(e.target.value);
										}}
										style={{
											padding: '0.5rem',
										}}
									>
										<option value="prePurchase">Pre Purchase</option>
										<option value="postPurchase">Post Purchase</option>
										<option value="expired">Expired</option>
									</select>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										margin: '0.5rem 0',
										width: '100%',
									}}
								>
									<Button
										disabled={apiCallUnderProgress}
										size="sm"
										color="primary"
										type="submit"
										onClick={async () => {
											setMatchingUserResult([]);
											setApiCallUnderProgress(true);

											let res = [];
											try {
												res = (
													await fetchWrapper('/admin/get-ideal-user', {
														method: 'POST',
														body: {
															bucketKey: selectedNumber,
															prePurchase: selectedUserType === 'prePurchase',
															postPurchase: selectedUserType === 'postPurchase',
														},
													})
												)?.data;

												console.log(res);

												if (res.length > 0) {
													showToast('success', 'Matching users found!');
												} else {
													showToast('info', 'No matching users found!');
												}
											} catch (e) {
												console.log(e);
												showToast('error', 'Something went wrong!');
											}

											setMatchingUserResult(res);
											setApiCallUnderProgress(false);
										}}
										style={{
											marginTop: '1rem',
										}}
									>
										Search
									</Button>
								</div>

								{apiCallUnderProgress && (
									<div>
										<img width="200" height="200" src={getSanitizedImageUrl('assets/loader/catLoader2.gif')} alt="loader" />

										<p>Please wait...</p>
									</div>
								)}
							</div>
							{matchingUserResult.length > 0 &&
								matchingUserResult.map((user) => {
									return (
										<div
											key={user.id}
											style={{
												border: '1px solid black',
												padding: '0.5rem',
												marginTop: '1rem',
												borderRadius: '5px',
												width: '50vw',
											}}
										>
											<p>user Id : {user._id}</p>
											<p>mobile : {user.mobile}</p>
										</div>
									);
								})}
						</Col>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};
