/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getFaqs, faqOnSubmit, getFaqTypes, setApiParams } from '../../faqSlice';
import { isNotEmptyArray, accessDeepObject } from '../../../../utils/commonHelpers';
import { dateTimeConverter } from '../../../../utils/dateHelper';
import { statusList, STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';
import history from '../../../App/history';

import { SelectField } from '../../../../reusableComponents/Form/Select';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import Box from '../../../../reusableComponents/Box';
import { getPrograms } from '../../../Program/programSlice';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (faqs, pageNo, rowsCount, submitHandler) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < faqs.length; i += 1) {
		r.push({
			id: initialCount,
			question: faqs[i].question,
			faqType: `${faqs[i].faqType.name} ${faqs[i].program ? `( ${faqs[i].program} )` : ''}`,
			status:
				faqs[i].status === STATUS_ACTIVE ? (
					<div className="badge badge-success">Active</div>
				) : (
					<div className="badge badge-warning">Inactive</div>
				),
			createdAt: dateTimeConverter(faqs[i].createdAt, 'format', 'dd MMM yyyy (k:mm)'),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							history.push(`/faq/add-edit?id=${faqs[i].id}`);
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							const status = faqs[i].status === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE;
							submitHandler({ ...faqs[i], faqType: faqs[i].faqType.slug, status, pos: i });
						}}
					>
						{faqs[i].status === STATUS_ACTIVE ? 'Mark Inactive' : 'Mark Active'}
					</Button>
					{/* <Button
						color='success'
						size='md'
						style={customStyles}
						onClick={() => {
							console.log('preview asked for ', faqs[i].id);
						}}
					>
						Preview
					</Button> */}
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const FaqTable = ({ history }) => {
	const faqState = useSelector(({ faq }) => ({
		loading: faq.loading,
		faqs: faq.faqs,
		pageNo: faq.pageNo,
		total: faq.total,
		apiParams: faq.apiParams,
		faqTypes: faq.faqTypes,
	}));
	const programState = useSelector(({ program }) => ({ programs: program.programs }));
	const [filter, setFilter] = useState({ status: null, faqType: null, programId: null });

	const dispatch = useDispatch();

	const [faqRows, setFaqRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);

	React.useEffect(() => {
		dispatch(getFaqs());
		dispatch(getFaqTypes());
	}, []);

	React.useEffect(() => {
		setPageOfItems(faqState.pageNo);
	}, [faqState.pageNo]);

	React.useEffect(() => {
		dispatch(getFaqs());
	}, [faqState.apiParams]);

	const heads = [
		{
			accessor: 'question',
			Header: 'Question',
		},
		{
			accessor: 'faqType',
			Header: 'Faq Type',
		},
		{
			accessor: 'createdAt',
			Header: 'Created At',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		dispatch(faqOnSubmit({ ...params, isEdit: true }));
	};

	React.useEffect(() => {
		if (isNotEmptyArray(faqState.faqs)) {
			const structuredRows = createRows(faqState.faqs, faqState.pageNo, accessDeepObject('apiParams.limit', faqState), submitHandler);
			setFaqRows(structuredRows);
		} else {
			setFaqRows([]);
		}
	}, [faqState.faqs]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = faqState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', faqState),
					skip: accessDeepObject('apiParams.limit', faqState) * (pageOfItems - 1),
				})
			);
		}
	};

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={2}>
							<h3>FAQs Listing</h3>
						</Col>
						<Col sm={6}>
							<Box d="flex" w="100%">
								<Box d="flex" ai="center" stretch="1" mr="1rem">
									<SelectField
										clearable
										placeholder="FAQ Type"
										options={faqState.faqTypes.map((dd) => ({ label: dd.name, value: dd.slug }))}
										onChange={(val) => setFilter({ ...filter, faqType: val })}
									/>
								</Box>
								<Box d={filter.faqType === 'program' ? 'flex' : 'none'} ai="center" stretch="1" mr="1rem">
									<SelectField
										clearable
										placeholder="Choose Program"
										options={programState.programs.map((dd) => ({ label: dd.title, value: dd.id }))}
										onChange={(val) => setFilter({ ...filter, programId: val })}
										inputChangeHandler={(val) => {
											dispatch(setApiParams({ query: val }));
											dispatch(getPrograms());
										}}
									/>
								</Box>
								<Box d="flex" ai="center" stretch="1" mr="1rem">
									<SelectField
										clearable
										placeholder="Status"
										name="status"
										options={statusList}
										onChange={(val) => setFilter({ ...filter, status: val })}
									/>
								</Box>
								<Button
									style={customStylesMargin}
									size="sm"
									color="primary"
									onClick={() => {
										dispatch(
											getFaqs({
												...(filter.faqType && { faqType: filter.faqType }),
												...(filter.status && { status: filter.status }),
												...(filter.programId && { programId: filter.programId }),
											})
										);
									}}
								>
									Filter
								</Button>
							</Box>
						</Col>
						<Col sm={{ size: 3, offset: 1 }}>
							<Button
								style={customStylesMargin}
								size="sm"
								color="primary"
								onClick={() => {
									history.push('/faq/add-edit');
								}}
							>
								Add
							</Button>
						</Col>
					</Row>
					{isNotEmptyArray(faqRows) && (
						<>
							<DataPaginationTable heads={heads} rows={faqRows} onSort={onSort} />
							<Pagination
								itemsCount={faqState.total}
								itemsToShow={accessDeepObject('apiParams.limit', faqState)}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{faqState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</Col>
	);
};

export default FaqTable;
