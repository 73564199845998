const localStorageKey = 'jwtToken';

interface FetchOptions {
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	body?: any;
	headers?: any;
	customTimeout?: number;
}

export async function fetchWithTimeout(endpoint: string, { body, customTimeout = 60000, ...customConfig }: FetchOptions) {
	const token = window.localStorage.getItem(localStorageKey);
	const headers: any = { 'content-type': 'application/json' };
	const controller = new AbortController();

	// timeout after one minute
	const timeout = setTimeout(() => {
		controller.abort();
	}, customTimeout);

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	const config: any = {
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
		signal: controller.signal,
	};

	if (body) {
		config.body = JSON.stringify(body);
	}

	const response = await window.fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, config);

	clearTimeout(timeout);

	const data = await response.json();
	return data;
}
