import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../experimentSlice';

export const getExperiments = createAsyncThunk(
	'experiment/getExperiments',
	async ({ sortKey, sortOrder, limit, skip, status, experimentType }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip, status, experimentType });
		const res = await fetchWrapper(`/v1/experiments${qp}`, { method: 'GET' });

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				return res.data;
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const createExperiment = createAsyncThunk('experiment/createExperiment', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const { data: experimentId } = await fetchWrapper('/v1/admin/create-mongo-id', { method: 'GET' });

		const valuesToSubmit = {
			...data,
			_id: experimentId,
		};

		const res = await fetchWrapper('/v1/experiment', {
			method: 'POST',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Experiment created succcessfully!!`);
			history.push('/experiment/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateExperiment = createAsyncThunk('dashboard/updateExperiment', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/experiment', {
			method: 'PUT',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Experiment updated succcessfully!!`);
			history.push('/experiment/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateExperimentAndUpdateList = createAsyncThunk(
	'experiment/updateExperimentAndUpdateList',
	async ({ experimentId, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: experimentId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/experiment', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Experiment updated successfully!!`);
				return {
					experimentId,
					experimentData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
