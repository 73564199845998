import React, { useState, useRef, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmptyArray, ObjectMaybe, parseQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import history from 'containers/App/history';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import DropboxModal from 'containers/Video/components/DropboxModal';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { fetchMentors } from 'utils/fetchHelpers';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew as Input, InputNew } from 'reusableComponents/HookForm/Input';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import { IPhoto } from 'types/Photo';

import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import If from 'reusableComponents/If';
import { SmartButtonCTAFieldArray } from 'components/SmartButtonCTAFieldArray';
import { toast } from 'react-toastify';
import { createShort, updateShort } from '../actions/shorts.actions';
import Box from '../../../reusableComponents/Box';
import { TextAreaNew as TextArea } from '../../../reusableComponents/HookForm/TextArea';
import { SubjectTypes } from '../utils/types';

const SubjectOptions = [
	{ label: 'Math', value: 'MATH' },
	{ label: 'English', value: 'ENGLISH' },
	{ label: 'Art', value: 'ART' },
];

// TODO: Shift this to Polls Form after refactor, and pick properties here
interface IPoll {
	id: string;
	answerType: 'MCQ';
	questionType: 'FUN' | 'ASSESSMENT' | 'FEEDBACK' | 'OTHER';
	question: string;
	options: { option: string }[];
	duration: number;
	showResultDuration: number;
	triggerTime: number;
	buttons: any;
}

interface ShortsFormValues {
	caption: string;
	description?: string;
	dropboxPath: string;
	customWeight: number;
	mentorId: { label: string; value: string };
	status: 'ACTIVE' | 'INACTIVE';
	polls: Omit<IPoll, 'id'>[];
	isStaticButtonEnabled: boolean;
	button: any;
	subject?: { label: string; value: SubjectTypes };
	minClass?: number;
	maxClass?: number;
	isDemoShort?: boolean;
}

type ShortsValuesFromDatabase = Omit<ShortsFormValues, 'polls' | 'mentorId'> & {
	id: string;
	polls: IPoll[];
	video: { is: string };
	videoDetails: {
		id: string;
		videoName: string;
		videoDesc: string;
		dropboxPath: string;
		videoUrl: string;
		hlsStreamStatus: number;
	};
	status: 'ACTIVE' | 'INACTIVE';
	postedBy: {
		id: string;
		fullName: string;
		profilePic: {
			id: string;
			url: string;
		};
	};
	createdBy: string;
	updatedBy: string;
	thumbnailImage?: IPhoto;
	buttons: any;
	customWeight: number;
	subject?: SubjectTypes;
	classRange?: {
		min: number;
		max: number;
	};
	isDemoShort?: boolean;
};

interface ModifyShortBeforeCreateOrUpdateArgs extends ShortsFormValues {
	_id?: string;
	createdBy: string;
	updatedBy: string;
}

interface ShortAddEditFormProps {
	location: { search: { shortId?: string } };
}

const modifyShortBeforeInitialize = (values: ShortsValuesFromDatabase) => {
	return {
		...values.videoDetails,
		customWeight: values.customWeight,
		caption: values.videoDetails.videoName,
		minClass: values.classRange ? values.classRange.min : null,
		maxClass: values.classRange ? values.classRange.max : null,
		subject: SubjectOptions.find((option) => option.value === values.subject),
		isDemoShort: values.isDemoShort ? values.isDemoShort : null,
		description: values.videoDetails.videoDesc,
		mentorId: { label: values.postedBy?.fullName, value: values.postedBy?.id },
		isStaticButtonEnabled: isNotEmptyArray(values?.buttons),
		thumbnailImage: getPreviewImages(
			{
				id: values.id,
				mediaType: 'IMAGE',
				url: values?.thumbnailImage?.url,
			},
			false
		),
	};
};

export const modifyShortBeforeCreateOrUpdate = (values: ModifyShortBeforeCreateOrUpdateArgs, databaseValues: ShortsValuesFromDatabase) => {
	let classRange = null;

	// format class range for api body
	if (values.minClass && values.maxClass) {
		classRange = {
			min: values.minClass,
			max: values.maxClass,
		};
	}
	return {
		...values,
		button: values.isStaticButtonEnabled ? values?.button : null,
		createdBy: ObjectMaybe(values?.mentorId).value,
		classRange,
	};
};

export const ShortsAddEditForm = (props: ShortAddEditFormProps) => {
	const [initialValues, setInitialValues] = useState<ShortsValuesFromDatabase>();
	const { isSubmitting, loggedInUser } = useSelector(({ shorts, auth }) => ({
		loggedInUser: auth.user,
		isSubmitting: shorts.isSubmitting,
	}));

	const dispatch = useDispatch();

	const { shortId } = ObjectMaybe(parseQueryParams(props.location.search));
	const isEditForm = !!shortId;

	const { register, handleSubmit, watch, errors, setValue, ...hookFormMethods } = useForm();
	const intervalId = useRef(null);
	const [showDbxModal, setShowDbxModal] = useState(false);

	const formValues = watch(['isStaticButtonEnabled']);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/short?id=${shortId}`, { method: 'GET' }).then((res) => {
				setInitialValues(res.data as ShortsValuesFromDatabase);
				hookFormMethods.reset(modifyShortBeforeInitialize(res.data as any));
			});
		}
		setValue('isRelaunchable', true);
		return clearInterval(intervalId.current);
	}, []);

	const onSubmit = async (values) => {
		// if minClass is provided then maxClass is also required
		if ((values.minClass && !values.maxClass) || (!values.minClass && values.maxClass)) {
			toast.error('Min class and max class both are required');
			return;
		}

		// validation for class range
		if (values.minClass > values.maxClass) {
			toast.error('Min class cannot be greater than max class');
			return;
		}

		const modifiedValues = modifyShortBeforeCreateOrUpdate(
			{
				...values,
				_id: isEditForm ? initialValues.id : undefined,
				shortVideoId: isEditForm ? initialValues.videoDetails.id : undefined,
				updatedBy: loggedInUser.id,
				subject: values.subject ? values.subject.value : null,
			},
			initialValues
		);

		if (isEditForm) dispatch(updateShort(modifiedValues));
		else dispatch(createShort(modifiedValues));
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Short...">
			<FormProvider {...{ register, watch, handleSubmit, errors, setValue, ...hookFormMethods }}>
				<div>
					{showDbxModal && (
						<DropboxModal
							handleSubmit={(dropboxPath) => {
								setValue('dropboxPath', dropboxPath, { shouldValidate: true });
								setShowDbxModal(false);
							}}
							closeModal={() => {
								setShowDbxModal(false);
							}}
						/>
					)}
					<Card>
						<CardBody>
							<Box w="100%">
								<Row>
									<Col md="9">
										<h3>{isEditForm ? 'Edit' : 'Create'} Short</h3>
									</Col>
								</Row>
							</Box>
							<Box w="100%" mt="1rem">
								<form onSubmit={handleSubmit(onSubmit)} className="form ltr-support">
									<Input label="Caption" name="caption" required />
									<TextArea rows={5} label="Description" name="description" />
									<Box d="flex" ai="center" w="100%">
										<Col sm="12" md="9" style={{ paddingLeft: 0 }}>
											<Input label="Dropbox Path" name="dropboxPath" disabled={isEditForm} required />
										</Col>
										{!isEditForm && (
											<Col sm="6" md="3" style={{ paddingRight: 0 }}>
												<Button size="sm" style={{ margin: 0 }} onClick={() => setShowDbxModal(true)}>
													Choose from Dropbox
												</Button>
											</Col>
										)}
									</Box>
									<Col sm="6" style={{ paddingLeft: 0 }}>
										<AsyncSelectField label="Mentor" name="mentorId" fetchOptions={fetchMentors} isClearable />
									</Col>
									<Col sm="6">
										<Input
											type="number"
											label="Custom Weight"
											name="customWeight"
											pattern={{ value: /[0-9]/, message: `Weight should be an integer` }}
											min={0}
											max={10000}
										/>
									</Col>
									<Col sm="6" style={{ paddingLeft: 0 }}>
										<SelectField isClearable label="Subject" name="subject" options={SubjectOptions} />
									</Col>
									<Col sm="3">
										<Input type="number" label="Min Class" name="minClass" min={1} max={5} />
									</Col>
									<Col sm="3">
										<Input type="number" label="Max Class" name="maxClass" min={1} max={5} />
									</Col>
									<Col sm="12" style={{ paddingLeft: 0 }}>
										<DropzoneNew name="thumbnailImage" label="Thumbnail Image" />
									</Col>
									<Col sm="12">
										<Checkbox label="Custom CTA" name="isStaticButtonEnabled" />
									</Col>
									<Col sm="12">
										<Checkbox label="Demo Short" name="isDemoShort" />
									</Col>
									<Col sm="12">
										<If
											condition={formValues.isStaticButtonEnabled}
											then={
												<Box>
													<h2 style={{ marginBottom: '1rem' }}>Buttons</h2>
													<SmartButtonCTAFieldArray
														{...hookFormMethods}
														register={register}
														watch={watch}
														setValue={setValue}
														errors={errors}
														buttonsData={initialValues?.buttons}
													/>
												</Box>
											}
										/>
									</Col>
									<Button size="sm" color="primary" type="submit">
										{isEditForm ? 'Save' : 'Create'}
									</Button>
									<Button size="sm" color="success" type="button" onClick={() => history.push('/shorts/list')}>
										Cancel
									</Button>
								</form>
							</Box>
						</CardBody>
					</Card>
				</div>
			</FormProvider>
		</LoadingOverlay>
	);
};
