import { call, put } from 'redux-saga/effects';

import { toggleLoading, deleteClassSuccess } from '../classSlice';
import { setError } from '../../App/globalSlice';
import { actionSpreader, showToast } from '../../../utils/commonHelpers';
import { requestSaga } from '../../../utils/requestSaga';

export function* deleteClassSaga({ payload }) {
	const res = yield call(requestSaga, 'DELETE', `${process.env.REACT_APP_API_URL}/v1/admin/class?id=${payload.id}`);
	if (res.status === 200 && res.data) {
		yield put(actionSpreader(deleteClassSuccess.type, { pos: payload.pos }));
		showToast('success', `👍️ Class Successfully Deleted!`);
	} else {
		yield put(actionSpreader(toggleLoading.type, { loading: false }));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
