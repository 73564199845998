/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { isNotEmptyObject } from '../../utils/commonHelpers';
import { ErrorMessage } from './ErrorMessage';

const Input = React.forwardRef((props: any, ref: any) => {
	return (
		<div className="form__form-group">
			{props.label && (
				<span className="form__form-group-label">
					{props.label} {props.isRequired && <span className="form__form_group-label--required">&#42;</span>}
				</span>
			)}
			<div className="form__form-group-field">
				<div className="form__form-group-input-wrap">
					<input ref={ref} {...props} />
					{isNotEmptyObject(props.error) ? (
						props.error.type === 'required' ? (
							<span className="form__form-group-error">Required</span>
						) : (
							<span className="form__form-group-error">{props.error.message}</span>
						)
					) : null}
				</div>
			</div>
		</div>
	);
});

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	helpText?: string;
	type?: 'text' | 'number' | 'date' | 'datetime-local' | 'file' | 'color' | 'email';
	accept?: string;
	required?: boolean;
	minLength?: number;
	maxLength?: number;
	min?: number | string;
	max?: number | string;
	multiple?: boolean;
	defaultValue?: string | number;
	disabled?: boolean;
	readOnly?: boolean;
	isClickDisabled?: boolean;
	pattern?: { value: RegExp; message: string };
	validate?: (val: any) => boolean | string;
	showIcon?: string;
	iconHandler?: any;
	onChange?: (e: any) => void;
	step?: number;
}

export const InputNew = ({
	type = 'text',
	accept,
	name,
	label,
	required,
	min,
	max,
	minLength,
	maxLength,
	multiple = false,
	pattern,
	validate,
	defaultValue,
	placeholder,
	helpText,
	disabled = false,
	readOnly = false,
	isClickDisabled = false,
	showIcon = '',
	iconHandler = null,
	onChange,
	step,
}: Props) => {
	const { register } = useFormContext();
	return (
		<div className="form__form-group">
			{label && (
				<div style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<span className="form__form-group-label">
						{label} {required && <span className="form__form_group-label--required">&#42;</span>}
					</span>
					{showIcon && (
						<span
							className={`lnr lnr-${showIcon}`}
							style={{ cursor: 'pointer', marginBottom: '4px', display: 'inline-block', marginLeft: '10px' }}
							onClick={iconHandler}
						/>
					)}
				</div>
			)}
			<div className="form__form-group-field">
				<div className="form__form-group-input-wrap">
					<input
						style={{ pointerEvents: isClickDisabled ? 'none' : 'all', backgroundColor: disabled ? '#f5f5f5' : '#fff' }}
						onChange={onChange}
						ref={register({
							required,
							minLength,
							maxLength,
							min,
							max,
							pattern,
							validate,
						})}
						{...{
							type,
							accept,
							name,
							label,
							defaultValue,
							disabled,
							readOnly,
							placeholder,
							multiple,
							min,
							max,
							step,
						}}
						// @ts-expect-error
						onWheel={(e) => e.target.blur()}
					/>
					{helpText && <span className="form__form-group-help-text">{helpText}</span>}
					<ErrorMessage
						{...{ label, name, minLength, maxLength, min, max }}
						render={(message) => {
							return <span className="form__form-group-error">{message}</span>;
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Input;
