import { isNotEmptyArray, showToast } from 'utils/commonHelpers';
import { dateTimeConverter } from 'utils/dateHelper';
import { fetchWrapper } from 'utils/fetchWrapper';
import { MAX_PROFILES_PER_USER } from './constants';

export const profileLabel = (profile: any) => {
	if (!profile) return null;
	return `${profile.fullName} ${'academicClass' in profile ? `-- Grade-${profile.academicClass}` : ''} -- ${dateTimeConverter(
		profile.createdAt,
		'format',
		'dd MMM yyyy (k:mm)'
	)}`;
};

export const getActiveProfiles = (profiles) => {
	return profiles?.reduce((acc, curr) => {
		if (curr.status !== 0) {
			acc.push(curr);
		}
		return acc;
	}, []);
};

export const getFilteredProfileDetails = (
	profiles: Array<any>,
	grades: Array<{ label: string; value: number }>,
	selectedGrade?: number,
	requiredProfilesForDropdown = true
) => {
	if (profiles && profiles.length > 0) {
		const existingGradesInProfiles = new Set();
		const activeProfiles = [];
		const selectedGradeProfiles = [];
		const profilesWithNoGrade = [];
		profiles?.forEach((profile) => {
			if (profile.status !== 0) {
				activeProfiles.push(profile);
				if (!Number.isInteger(profile.academicClass)) {
					existingGradesInProfiles.add(profile.academicClass);
				}
				if (requiredProfilesForDropdown) {
					if (!Number.isInteger(profile.academicClass)) {
						profilesWithNoGrade.push({
							label: profileLabel(profile),
							value: profile.id,
						});
					} else if (selectedGrade && profile.academicClass === selectedGrade) {
						selectedGradeProfiles.push({
							label: profileLabel(profile),
							value: profile.id,
						});
					}
				}
			}
		});
		const gradesToShowForCreateProfile = [];
		if (activeProfiles.length < MAX_PROFILES_PER_USER) {
			grades.forEach(({ value, label }) => {
				if (!existingGradesInProfiles.has(value)) {
					gradesToShowForCreateProfile.push({
						label,
						value,
					});
				}
			});
		}
		return {
			activeProfiles,
			gradesToShowForCreateProfile,
			activeProfilesForDropdown: requiredProfilesForDropdown
				? isNotEmptyArray(selectedGradeProfiles)
					? selectedGradeProfiles
					: isNotEmptyArray(profilesWithNoGrade)
					? profilesWithNoGrade
					: []
				: [],
		};
	}
	return { activeProfiles: [], gradesToShowForCreateProfile: grades, activeProfilesForDropdown: [] };
};

export const updateProfileForGrades = (values: any, parentId: string) => {
	try {
		const profilesUpdatePromise = [];
		Object.keys(values).forEach((profileId) => {
			if (values[profileId]?.selectedGrade?.value) {
				profilesUpdatePromise.push(
					fetchWrapper('/v1/admin/profile', {
						method: 'POST',
						body: {
							parentId,
							id: profileId,
							academicClass: values[profileId].selectedGrade.value,
							userProfiles: [],
						},
					})
				);
			}
		});
		return Promise.all(profilesUpdatePromise);
	} catch (error) {
		console.log('error', error);
		showToast('error', `Failed to update profiles. Please try again`);

		return null;
	}
};

export const createOrUpdateProfileHelper = async (fullName: string, academicClass: number, parentId: string, mobile: string) => {
	try {
		if (!fullName || !Number.isInteger(academicClass) || !parentId || !mobile) {
			throw new Error('create profile inputs are not sufficient');
		}
		const modifiedParams = {
			academicClass,
			fullName,
			parentId,
			gender: 'MALE',
			mobile,
			currentProfileData: {},
			userProfiles: [],
			...(!!fullName && { onboardingStatus: 202 }),
		};
		const res = await fetchWrapper('/v1/admin/profile', {
			method: 'POST',
			body: modifiedParams,
		});
		if (res.status === 200) {
			showToast('success', 'Profile Created');
			return res.data;
		}
		throw res.message ?? res.errors?.error;
	} catch (err) {
		const error = err ?? err?.message;
		console.log('err', err);
		if (typeof error === 'string') showToast('error', error);
		else showToast('error', `Failed to create profile for ${fullName}. Please try again`);
	}
};

export const getPackageToIdMap = (packages: Array<any>, packageKey?: string) => {
	if (packageKey === 'ENGLISH_PACKAGE_AI') {
		const AIPackage = packages.find((pac) => pac.label === 'ENGLISH_PACKAGE_AI');
		return AIPackage?.value?.entityId;
	}
	return packages.reduce((acc, curr) => {
		const [packageSubject, , , packageGrade] = curr.label.split('_');
		const key = `${packageSubject}_${packageGrade}`;
		acc[key] = curr?.value?.entityId;
		return acc;
	}, {});
};

export const getSelectedPackageAmount = (activeProfiles, values) => {
	let amount = 0;
	for (let i = 0; i < activeProfiles.length; i += 1) {
		const profile = activeProfiles[i];
		const profileId = profile.id;
		const profileData = values[profileId];
		const subjects = Object.keys(profileData);
		for (let j = 0; j < subjects.length; j += 1) {
			const subject = subjects[j];

			const data = profileData[subject];
			if (data) {
				const isSubjectSelected = typeof data.isSelected === 'object' ? data.isSelected[0] === 'on' : data.isSelected;

				if (isSubjectSelected && typeof data.selectedPlanDuration?.amount === 'number') {
					amount += data.selectedPlanDuration.amount;
				}
			}
		}
	}
	return amount;
};

export const getValidCartItems = (activeProfiles, values, packages, isRecurring = false) => {
	const validCartItems = [];
	const packageToIdMap = getPackageToIdMap(packages);
	for (let i = 0; i < activeProfiles.length; i += 1) {
		const profile = activeProfiles[i];
		const profileId = profile.id;
		const profileData = values[profileId];
		if (isRecurring) {
			const { selectedSubject } = profileData;
			if (selectedSubject) {
				const packageId = packageToIdMap[`${selectedSubject}_${profile.academicClass ?? profileData.selectedGrade?.value}`];
				validCartItems.push({ profileId, packageId });
			}
		} else {
			const subjects = Object.keys(profileData);
			for (let j = 0; j < subjects.length; j += 1) {
				const subject = subjects[j];

				const data = profileData[subject];
				if (data) {
					const isSubjectSelected = typeof data.isSelected === 'object' ? data.isSelected[0] === 'on' : data.isSelected;
					const profileDuration =
						(data.selectedPlanDuration && data.selectedPlanDuration?.value === 'custom'
							? data.planDurationInput
							: data.selectedPlanDuration?.value) ?? data.planDurationInput;
					if ((isSubjectSelected && !profileDuration) || (profileDuration && !isSubjectSelected)) {
						showToast('error', 'Please select valid combination(s) of package and profile');
						return;
					}
					if (isSubjectSelected && profileDuration) {
						let packageId;
						if (subject === 'ENGLISH') {
							const englishPackageAiId = getPackageToIdMap(packages, 'ENGLISH_PACKAGE_AI');
							packageId = englishPackageAiId;
							// This Package Id corresponds to ENGLISH_PACKAGE_AI
						} else {
							packageId = packageToIdMap[`${subject}_${profile.academicClass ?? profileData.selectedGrade?.value}`];
						}
						if (!packageId) {
							showToast('error', 'PackageId for some selected profile(s) and package not found');
							return;
						}

						validCartItems.push({ profileId, packageId, duration: profileDuration });
					}
				}
			}
		}
	}
	if (!isNotEmptyArray(validCartItems)) {
		showToast('error', 'Please select valid combination(s) of package and profile');
	}
	return validCartItems;
};

export const updateProfileInfo = async ({ profileId, ...params }) => {
	const modifiedParams = {
		id: profileId,
		...params,
	};
	const result = await fetchWrapper('/v1/admin/profile', {
		method: 'POST',
		body: modifiedParams,
	});
	return result;
};
