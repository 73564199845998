/* eslint-disable no-nested-ternary */
import { isUndefined } from 'lodash';
import { WEBSITE_DOMAIN, WEBSITE_URL } from 'utils/constants';
/* eslint-disable indent */
import { isNotEmptyArray, isNotEmptyObject, StringMaybe } from '../../utils/commonHelpers';

const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const WEBSITE_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

export const maxLength = (max) => (value) => value && (value.length > max ? `Must be ${max} characters or less` : undefined);

export const minLength = (min) => (value) => value && (value.length < min ? `Must be ${min} characters or more` : undefined);

export const required = (value) => (isUndefined(value) || value === '' ? 'Required' : undefined);

export const selectFieldRequired = (selectedOption) =>
	typeof selectedOption === 'object'
		? isNotEmptyObject(selectedOption) && selectedOption.value
			? undefined
			: 'Required'
		: isUndefined(selectedOption) || selectedOption === ''
		? 'Required'
		: undefined;

export const multipleSelectFieldRequired = (selectedOptions) => (isNotEmptyArray(selectedOptions) ? undefined : 'Required');

export const validateEmailArray = (emails) =>
	emails
		? isNotEmptyArray(
				StringMaybe(emails)
					.split(',')
					.filter((e) => !EMAIL_REGEX.test(e.trim()))
		  )
			? 'Please enter valid email addresses'
			: undefined
		: undefined;

export const validateWebsiteArray = (websites) =>
	websites
		? isNotEmptyArray(
				StringMaybe(websites)
					.split(',')
					.filter((e) => !WEBSITE_REGEX.test(e.trim()))
		  )
			? 'Please enter valid website urls'
			: undefined
		: undefined;

export const validateValCount = (l) => (data) => (data.split(',').length > l ? `Please enter atmax ${l} entries` : undefined);

export const exactLength = (length) => (value) => value && (value.length === length ? undefined : `Please enter ${length} characters`);

export const validatePhone10 = (value) => !maxLength(9)(value) && 'Please enter a valid phone number';

export const validateEmail = (value) => {
	return value && (EMAIL_REGEX.test(value) ? undefined : 'Please enter a valid email');
};

export const validateWebAddress = (value) => {
	return value && (WEBSITE_REGEX.test(value) ? undefined : 'Please enter a valid web address');
};

export const validateExternalWebAddress = (value) => {
	try {
		const parsedUrl = new URL(value);
		if (value.startsWith('tel:')) return undefined;
		if (value.startsWith('https://')) return validateWebAddressHTTPSOnly(value);
		return parsedUrl.hostname.includes(WEBSITE_DOMAIN) ? 'Please enter an external website link' : undefined;
	} catch (error) {
		return 'Please enter a valid web address';
	}
};

export const validateWebAddressHTTPSOnly = (value) => {
	const reg = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
	return value && (reg.test(value) ? undefined : 'Please enter a valid web address with https');
};

export const validateBaseAddress = (value) => {
	return value && value.startsWith(WEBSITE_URL) ? undefined : 'Please enter valid app link';
};

export const validatePhoneInd = (value) => {
	const reg = /^(\+|\d)[0-9]{7,16}$/;
	return value && (reg.test(value) ? undefined : 'Please enter a valid phone number');
};

export const validateInternationalPhone = (value) => {
	// const reg = /^(\+|\d)[0-9]{7,16}$/;
	const reg = /^\+(?:[0-9] ?){6,14}[0-9]$/;
	return value && (reg.test(value) ? undefined : 'Please enter a valid phone number with country code');
};

export const minValue = (min) => (value) => value && (value < min ? `Enter a number greater than ${min - 1}` : undefined);

export const maxValue = (max) => (value) => value && (value >= max ? `Must be less than ${max}` : undefined);

export const minValue30 = minValue(30);
export const minValue1 = minValue(1);

export const validateHexCode = (value) => {
	const reg = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
	if (value && value.includes('#')) {
		return reg.test(value) ? undefined : 'Please enter a valid hex code';
	}
	return undefined;
};

export const isMP4VideoURL = (value) => (value ? (value.includes('.mp4') ? undefined : 'Please enter a mp4 video link') : undefined);
export const isHLSVideoURL = (value) => (value ? (value.includes('.m3u8') ? undefined : 'Please enter a HLS video link') : undefined);
