import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { IProgram } from 'types/Program';
import { omitWrapper, showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting } from '../classSlice';

export const createClass = createAsyncThunk('class/createClass', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	const { data: classId } = await fetchWrapper('/v1/admin/class/id', { method: 'GET' });

	const entityType = 'CLASS';
	const entityId = classId;

	const imagesKeys = [
		{ key: 'coverPicture3By2', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'coverPicture4By3', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'photos', isMulti: true, withCaption: true, entityId, entityType: 'CLASS_GALLERY' },
	];

	const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

	const valuesToSubmit = {
		_id: classId,
		...omitWrapper(['coverPicture', 'coverPicture2By1', 'coverPicture3By2', 'coverPicture4By3', 'featuredCoverUrl'], data),
		...imagesUploadData,
	};

	try {
		const res = await fetchWrapper('/v1/admin/class', {
			method: 'POST',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Class created succcessfully!!`);
			history.push('/class/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateClass = createAsyncThunk('class/updateClass', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const entityType = 'CLASS';
	const entityId = data._id;

	const imagesKeys = [
		{ key: 'coverPicture3By2', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'coverPicture4By3', isMulti: false, withCaption: false, entityId, entityType },
		{ key: 'photos', isMulti: true, withCaption: true, entityId, entityType: 'CLASS_GALLERY' },
	];

	const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

	const valuesToSubmit = {
		...omitWrapper(['coverPicture', 'coverPicture2By1', 'coverPicture3By2', 'coverPicture4By3', 'featuredCoverUrl'], data),
		...imagesUploadData,
	};

	try {
		const res = await fetchWrapper('/v1/admin/class', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Class updated successfully!!`);
			history.push('/class/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateClassAndUpdateList = createAsyncThunk(
	'class/updateClassAndUpdateList',
	async ({ classId, classIndex, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			_id: classId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/class', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.data } });
			} else {
				showToast('success', `👍️ Class updated succcessfully!!`);
				return {
					classIndex,
					classData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);

export const cloneClassesToProgram = createAsyncThunk(
	'class/cloneClassesToProgram',
	async ({ classIds, program }: { classIds: string[]; program: IProgram }, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));
		const reqBody = { classIds, programId: program.id };

		try {
			const res = await fetchWrapper('/v1/admin/class/clone', { method: 'POST', body: reqBody });
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.data } });
			} else {
				showToast('success', `👍️ Classes cloned succcessfully!!`);
				history.push(`/class/list?programId=${program.id}&programTitle=${program.title}`);
				return res.data;
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
