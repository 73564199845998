import { takeLatest } from 'redux-saga/effects';

import { getVideos, getVideoStatus, getDropboxFolders, searchDropboxFile } from './videoSlice';
import { getVideosSaga } from './sagas/getVideosSaga';
import { getVideoStatusSaga } from './sagas/getVideosStatusSaga';
import { getDropboxFoldersSaga } from './sagas/getDropboxFoldersSaga';
import { searchVideoFileSaga } from './sagas/searchVideoFileSaga';

export default function* videoSaga() {
	yield takeLatest(getVideos.type, getVideosSaga);
	yield takeLatest(getVideoStatus.type, getVideoStatusSaga);
	yield takeLatest(getDropboxFolders.type, getDropboxFoldersSaga);
	// @ts-expect-error
	yield takeLatest(searchDropboxFile.type, searchVideoFileSaga);
}
