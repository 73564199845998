import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SchoolEditForm from './SchoolEditForm';
import SchoolList from './SchoolList';

const Schools = () => {
	return (
		<div>
			<Switch>
				<Route path="/school/list" component={SchoolList} />
				<Route path="/school/edit" component={SchoolEditForm} />
			</Switch>
		</div>
	);
};

export default Schools;
