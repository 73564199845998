import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting } from '../referralCodeSlice';

export const createRefCode = createAsyncThunk('refCode/createRefCode', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));
	try {
		const { data: refCodeId } = await fetchWrapper('/v1/admin/ref-code/id', { method: 'GET' });

		const valuesToSubmit = {
			...data,
			_id: refCodeId,
		};

		const res = await fetchWrapper('/v1/admin/ref-code', {
			method: 'POST',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Referral Code created succcessfully!!`);
			history.push('/ref-code/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateRefCode = createAsyncThunk('refCode/updateRefCode', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const valuesToSubmit = data;

	try {
		const res = await fetchWrapper('/v1/admin/ref-code', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Referral Code updated succcessfully!!`);
			history.push('/ref-code/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const deleteRefCode = createAsyncThunk('refCode/deleteRefCode', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper(`/v1/admin/ref-code?id=${data.id}`, {
			method: 'DELETE',
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Referral Code deleted succcessfully!!`);
			return {
				refCodeId: res.data.id,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateRefCodeAndUpdateList = createAsyncThunk(
	'refCode/updateRefCodeAndUpdateList',
	async ({ refCodeId, refCodeIndex, ...dataToUpdate }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		const valuesToSubmit = {
			id: refCodeId,
			...dataToUpdate,
		};

		try {
			const res = await fetchWrapper('/v1/admin/ref-code', {
				method: 'PUT',
				body: valuesToSubmit,
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.data } });
			} else {
				showToast('success', `👍️ Referral Code updated successfully!!`);
				return {
					refCodeIndex,
					refCodeData: res.data,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);
