import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleLoading, toggleIsSubmitting } from '../certificateSlice';

export const uploadCertificate = createAsyncThunk('certificate/uploadCertificate', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/certificates', {
			method: 'POST',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Request to upload certificates has been successfully received. Please check after some time.`);
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const getCertificate = createAsyncThunk('certificate/getCertificate', async ({ id }: any, { dispatch }) => {
	dispatch(toggleLoading({ loading: true }));

	const qp = stringifyQueryParams({ id });
	const res = await fetchWrapper(`/v1/admin/get-certificate${qp}`, { method: 'GET' });

	try {
		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return { certificate: res?.data };
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleLoading({ loading: false }));
	}
});
