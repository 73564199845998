import { createSlice } from '@reduxjs/toolkit';

const whatsappSlice = createSlice({
	name: 'whatsapp',
	initialState: {
		loading: false,
		groups: [],
		analyticsObj: null,
		analyticsByDate: {},
		dbxFiles: null,
		group: {},
		groupId: null,
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'updatedAt',
			sortOrder: '-1',
			limit: 200,
			skip: 0,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getWhatsappGroups: () => {},
		getWhatsappGroupsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.groups = action.payload.groups;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getWhatsappUserAnalytics: () => {},
		getWhatsappUserAnalyticsSuccess: (state, action) => {
			state.analyticsObj = action.payload;
		},
		getWhatsappUserAnalyticsByDate: () => {},
		getWhatsappUserAnalyticsByDateSuccess: (state, action) => {
			state.analyticsByDate = action.payload;
		},
		clearWhatsappUserAnalyticsByDate: (state) => {
			state.analyticsByDate = {};
		},
		getWhatsappGroup: () => {},
		getWhatsappGroupSuccess: (state, action) => {
			state.group = action.payload.group;
		},
		getWhatsappGroupId: () => {},
		getWhatsappGroupIdSuccess: (state, action) => {
			state.groupId = action.payload.groupId;
		},
		setWhatsappGroupStatus: () => {},
		setWhatsappGroupStatusSuccess: (state, action) => {
			state.groups[action.payload.pos].isActive = action.payload.isActive;
		},
		whatsappGroupOnSubmit: () => {},
		whatsappCsvOnSubmit: () => {},
		getDropboxFiles: () => {},
		dropboxFilesSuccess: (state, action) => {
			state.dbxFiles = action.payload;
		},
		clearDropboxFiles: (state) => {
			state.dbxFiles = null;
		},
		dropboxFilesOnUpload: () => {},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getWhatsappGroups,
	getWhatsappGroupsSuccess,
	getWhatsappUserAnalytics,
	getWhatsappUserAnalyticsSuccess,
	getWhatsappUserAnalyticsByDate,
	getWhatsappUserAnalyticsByDateSuccess,
	clearWhatsappUserAnalyticsByDate,
	getWhatsappGroup,
	getWhatsappGroupSuccess,
	getWhatsappGroupId,
	getWhatsappGroupIdSuccess,
	setWhatsappGroupStatus,
	setWhatsappGroupStatusSuccess,
	whatsappGroupOnSubmit,
	whatsappCsvOnSubmit,
	getDropboxFiles,
	dropboxFilesSuccess,
	clearDropboxFiles,
	dropboxFilesOnUpload,
	toggleIsSubmitting,
} = whatsappSlice.actions;

export default whatsappSlice.reducer;
