import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col as ReactStrapCol, Row } from 'reactstrap';
import { useForm, FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import { InputFieldArray } from 'reusableComponents/HookForm/InputFieldArray';
import Box from 'reusableComponents/Box';
import If from 'reusableComponents/If';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import {
	parseQueryParams,
	ObjectMaybe,
	isNotEmptyArray,
	showToast,
	ArrayMaybe,
	isNotEmptyObject,
	pickWrapper,
	omitWrapper,
} from 'utils/commonHelpers';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { AsyncSelectField, SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { DropzoneNew as Dropzone } from 'reusableComponents/HookForm/Dropzone';
import { RadioGroupNew as RadioGroup } from 'reusableComponents/HookForm/RadioGroup';

import { fetchWrapper } from 'utils/fetchWrapper';
import { getPreviewImages } from 'utils/hookFormImageHelpers';
import { IPhotoDatabase, IPhoto } from 'types/Photo';
import { IMetaTags } from 'types/MetaTags';
import { fetchMentors, fetchPlansAndPackages, fetchSkills, fetchVideos, parsePlanAndPacakgesOptions } from 'utils/fetchHelpers';
import { validateAspectRatio } from 'utils/imageHelpers';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { createProgram, updateProgram } from '../../actions/program.actions';
import { ModuleToClassMappingForm } from './ModuleToClassMappingForm';
import { canProgramBeMarkedActive } from '../../helpers';
import { ageRangeOptions, languageList } from '../../../../utils/constants';
import { ProgramReviewTable } from './ProgramReviewTable';

// const difficultyOptions = [
// 	{ label: 'Beginner', value: 'BEGINNER' },
// 	{ label: 'Intermediate', value: 'INTERMEDIATE' },
// 	{ label: 'Expert', value: 'EXPERT' },
// ];

const programStatusOptionsWithoutActive = [
	{ label: 'DRAFT', value: 'DRAFT' },
	{ label: 'INACTIVE', value: 'INACTIVE' },
	{ label: 'COMPLETED', value: 'COMPLETED' },
	{ label: 'DISCONTINUED', value: 'DISCONTINUED' },
];

const programStatusOptionsWithActive = [...programStatusOptionsWithoutActive, { label: 'ACTIVE', value: 'ACTIVE' }];

const programTypeOptions = [
	{ label: 'DIY', value: 'DIY' },
	{ label: 'Storytime', value: 'STORYTIME' },
	{ label: 'Regular', value: 'REGULAR' },
	{ label: 'Podcast', value: 'PODCAST' },
	{ label: 'Competition', value: 'COMPETITION' },
];

const progressionOptions = [
	{ label: 'Progressive', value: 'PROGRESSIVE' },
	{ label: 'Non Progressive', value: 'NON_PROGRESSIVE' },
];

const continuityOptions = [
	{ label: 'Fixed', value: 'FIXED' },
	{ label: 'Continuous', value: 'CONTINUOUS' },
];

const membershipTypeOptions = [
	{ label: 'Free', value: 'FREE' },
	{ label: 'Premium', value: 'PREMIUM' },
];
const subscriptionsTypeList = [
	{ label: 'Free', value: 'FREE' },
	{ label: 'Pro', value: 'PRO' },
	{ label: 'Pro Plus', value: 'PRO_PLUS' },
];

// const occurrenceOptions = [
// 	{ label: 'Recurring', value: 'RECURRING' },
// 	{ label: 'One Time', value: 'ONE_TIME' },
// ];

// This is done to give extra margin bottom
const Col = ({ children, ...props }) => (
	<ReactStrapCol {...props} style={{ marginBottom: '16px' }}>
		{children}
	</ReactStrapCol>
);

interface ModuleForm {
	index: number;
	title: string;
	noOfClasses: number;
}
interface ILearningOutcomeForm {
	title: string;
	description: string;
	photo: IPhoto[];
}

interface ModuleDatabase {
	index: number;
	title: string;
	noOfClasses: number;
	classes: Array<{ index: number; classId: string }>;
}
interface LearningOutcomeDatabase {
	title: string;
	description: string;
	photo: IPhotoDatabase;
}

interface ProgramReview {
	profilePic: IPhotoDatabase;
	fullName: string;
	rating: number;
	feedbackMsg: string;
	id: string;
	createdAt: string;
}

interface ProgramFormValues {
	title: string;
	slug: string;
	tagline: string;
	description: string;
	starsToEnroll?: number;
	ageRangeMin: { label: string; value: number };
	ageRangeMax: { label: string; value: number };
	language?: { label: string; value: string };
	mentorId: { label: string; value: string };
	secondaryMentors: { label: string; value: string }[];
	skills: { label: string; value: string }[];
	status: { label: string; value: string };
	programType: 'DIY' | 'STORYTIME' | 'REGULAR' | 'PODCAST' | 'COMPETITION';
	progression: 'PROGRESSIVE' | 'NON_PROGRESSIVE';
	continuity: 'FIXED' | 'CONTINUOUS';
	occurrence: 'RECURRING' | 'ONE_TIME';
	membershipType: 'FREE' | 'PREMIUM';
	modules: ModuleForm[];
	keywords: Array<{ value: string }>;
	materials?: Array<{ value: string }>;
	learningObjectives: Array<{ value: string }>;
	learningOutcomes?: ILearningOutcomeForm[];
	portraitPic?: IPhoto[];
	coverPicture?: IPhoto[];
	hlsVideoUrl?: { label: string; value: string };
	featuredFeedbacks?: { id: string }[];
	featuredCoverUrl?: [{ photo: IPhoto[] | File[]; caption: string }];
	mobileCoverPic?: [{ photo: IPhoto[] | File[]; caption: string }];
	photos?: [{ photo: IPhoto | File; caption: string }];
	certificate: { isEnabled: boolean; image: IPhoto[] | File[] };
	isFeatured: boolean;
	isHot: boolean;
	isMixedTrack: boolean;
	metaTags: IMetaTags;
	subscriptionsRequired?: { label: string; value: string }[];
}

type ProgramValuesFromDatabase = Omit<
	ProgramFormValues,
	'keywords' | 'materials' | 'learningObjectives' | 'modules' | 'secondaryMentors' | 'learningOutcomes' | 'skills' | 'subscriptionsRequired'
> & {
	id: string;
	status: string;
	language?: string;
	ageRange: { min: number; max: number };
	mentorId: string;
	keywords: string[];
	materials?: string[];
	learningObjectives: string[];
	learningOutcomes?: LearningOutcomeDatabase[];
	modules: ModuleDatabase[];
	coverPicture?: IPhotoDatabase;
	hlsVideoUrl?: string;
	video?: { videoName: string; videoUrl: string };
	featuredFeedbacks?: ProgramReview[];
	featuredCoverUrl?: IPhotoDatabase;
	mobileCoverPic?: IPhotoDatabase;
	portraitPic?: IPhotoDatabase;
	photos?: IPhotoDatabase[];
	certificate: { isEnabled: boolean; image: IPhotoDatabase };
	skills: { id: string; title: string }[];
	mentor: { id: string; fullName: string };
	secondaryMentors: { id: string; fullName: string }[];
	programEditStatus: 'NO_EDIT' | 'CLASS_EDIT' | 'ALL_EDIT';
	createdBy: string;
	updatedby: string;
	metaTags: IMetaTags;
	subscriptionsRequired?: string[];
};

interface ModifyProgramBeforeCreateOrUpdateArgs extends ProgramFormValues {
	_id?: string;
	createdBy: string;
	updatedBy: string;
}

function mergeModuleDetails(modulesFromDatabase: ModuleDatabase[], modulesFromForm: ModuleForm[]) {
	return modulesFromForm.map((currModule, i) => {
		const classesCount = currModule.noOfClasses;
		const existingClasses = ArrayMaybe(modulesFromDatabase[i]?.classes).slice(0, classesCount);
		return {
			...currModule,
			classes: existingClasses,
		};
	});
}

export const modifyProgramBeforeCreateOrUpdate = (
	values: ModifyProgramBeforeCreateOrUpdateArgs,
	databaseValues: ProgramValuesFromDatabase
) => {
	return {
		...omitWrapper(['ageRangeMin', 'ageRangeMax'], values),
		...(values.hlsVideoUrl && { hlsVideoUrl: values.hlsVideoUrl.value }),
		// NOTE: We need to preserve the modules data on edit.
		modules: isNotEmptyArray(databaseValues?.modules) ? mergeModuleDetails(databaseValues.modules, values.modules) : values.modules,
		ageRange: {
			min: values.ageRangeMin.value,
			max: values.ageRangeMax.value,
		},
		certificateImage: values.certificate?.image,
		isCertificateEnabled: values.certificate?.isEnabled,
		status: values.status.value,
		subscriptionsRequired: ArrayMaybe(values.subscriptionsRequired).map((option) => option.value),
		skills: ArrayMaybe(values.skills).map((skill) => ({ id: skill.value })),
		language: values.language?.value ?? null,
		mentorId: values.mentorId.value,
		secondaryMentors: values.secondaryMentors ? values.secondaryMentors.map((mentor) => ({ id: mentor.value })) : null,
		keywords: values.keywords.map((keyword) => keyword.value),
		materials: ArrayMaybe(values.materials).map((material) => material.value),
		learningObjectives: values.learningObjectives?.map((obj) => obj.value) || [],
	};
};

export const modifyProgramBeforeInitialize = async (values: ProgramValuesFromDatabase): Promise<ProgramFormValues> => {
	const formatedSubscriptionsRequired = await parsePlanAndPacakgesOptions(values.subscriptionsRequired);
	return {
		...values,
		ageRangeMin: ageRangeOptions.find(({ value }) => value === values.ageRange.min),
		ageRangeMax: ageRangeOptions.find(({ value }) => value === values.ageRange.max),
		status: programStatusOptionsWithActive.find((option) => option.value === values.status),
		skills: ArrayMaybe(values.skills).map((skill) => ({ label: skill.title, value: skill.id })),
		language: languageList.find((option) => option.value === values.language),
		hlsVideoUrl: isNotEmptyObject(values.video) ? { label: values.video.videoName, value: values.video.videoUrl } : undefined,
		mentorId: { label: values.mentor.fullName, value: values.mentor.id },
		secondaryMentors: (values.secondaryMentors || []).map((mentor) => ({ label: mentor.fullName, value: mentor.id })),
		keywords: values.keywords.map((keyword) => ({ value: keyword })),
		materials: ArrayMaybe(values.materials).map((material) => ({ value: material })),
		learningOutcomes: ArrayMaybe(values.learningOutcomes).map((outcome) => ({
			...outcome,
			photo: getPreviewImages(outcome.photo, false) as any,
		})),
		learningObjectives: values.learningObjectives.map((obj) => ({ value: obj })),
		coverPicture: values.coverPicture ? (getPreviewImages(values.coverPicture, false) as any) : undefined,
		portraitPic: values.portraitPic ? (getPreviewImages(values.portraitPic, false) as any) : undefined,
		featuredCoverUrl: values.featuredCoverUrl ? (getPreviewImages(values.featuredCoverUrl, true) as any) : undefined,
		mobileCoverPic: values.mobileCoverPic ? (getPreviewImages(values.mobileCoverPic, true) as any) : undefined,
		photos: values.photos ? (getPreviewImages(values.photos, true) as any) : undefined,
		certificate: {
			...values.certificate,
			image: values.certificate.image ? (getPreviewImages(values.certificate.image, false) as any) : undefined,
		},
		subscriptionsRequired: values.subscriptionsRequired ? formatedSubscriptionsRequired : [],
	};
};

interface ProgramAddEditFormProps {
	location: { search: { programId?: string } };
}

export const ProgramAddEditForm = (props: ProgramAddEditFormProps) => {
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<ProgramValuesFromDatabase>(null);
	const [currentView, setCurrentView] = useState<'PROGRAM_FORM' | 'CLASS_FORM' | 'PROGRAM_REVIEW_FORM'>('PROGRAM_FORM');
	const [isEditDisabled, setIsEditDisabled] = useState(false);

	const { loggedInUser, isSubmitting } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.program.isSubmitting,
	}));
	const hookFormMethods = useForm<ProgramFormValues>();
	const { programId } = ObjectMaybe(parseQueryParams(props.location.search));

	const isEditForm = !!programId;

	const { watch, handleSubmit } = hookFormMethods;

	const formValues = watch([
		'certificate',
		'mobileCoverPic',
		'portraitPic',
		'featuredCoverUrl',
		'continuity',
		'modules',
		'programType',
		'title',
		'ageRangeMin',
		'ageRangeMax',
		'subscriptionsRequired',
	]);

	const isRegularContinuous = formValues.continuity === 'CONTINUOUS' && formValues.programType === 'REGULAR';

	useEffect(() => {
		if (
			!(formValues.mobileCoverPic && formValues.mobileCoverPic.length && formValues.featuredCoverUrl && formValues.featuredCoverUrl.length)
		)
			hookFormMethods.setValue('isFeatured', false);

		if (!isRegularContinuous) hookFormMethods.setValue('isMixedTrack', false);
	}, [formValues]);

	useEffect(() => {
		if (!isEditForm && formValues.title) hookFormMethods.setValue('slug', formValues.title.toLowerCase().split(' ').join('-'));
	}, [formValues.title]);

	useEffect(() => {
		if (isEditForm) {
			fetchWrapper(`/v1/admin/program?id=${programId}`, { method: 'GET' }).then(async (res) => {
				setInitialValues(res.data as ProgramValuesFromDatabase);
				hookFormMethods.reset(await modifyProgramBeforeInitialize(res.data as any));
			});
		}
	}, []);

	useEffect(() => {
		if (['NO_EDIT', 'CLASS_EDIT'].includes(initialValues?.programEditStatus)) {
			setIsEditDisabled(true);
		}
	}, [initialValues]);

	const onSubmit = (values: ProgramFormValues) => {
		const { ageRangeMin, ageRangeMax } = values;
		if (ageRangeMax.value <= ageRangeMin.value) {
			return showToast('error', 'ageRangeMax should be greater than ageRangeMin');
		}
		const modifiedValues = modifyProgramBeforeCreateOrUpdate(
			{
				...(isEditForm ? values : omitWrapper(['learningObjectives'], values)),
				_id: isEditForm ? initialValues.id : undefined,
				createdBy: isEditForm ? initialValues.createdBy : loggedInUser.id, // Only update createBy while creation
				updatedBy: loggedInUser.id,
			},
			initialValues
		);
		if (
			!canProgramBeMarkedActive(modifiedValues.modules, values.programType, initialValues?.programEditStatus) &&
			values.status.value === 'ACTIVE'
		) {
			return showToast(
				'error',
				'The number of classes in module and the number of classes created do not match. Please mark the program as DRAFT or INACTIVE'
			);
		}

		if (isEditForm) {
			dispatch(updateProgram(modifiedValues));
		} else {
			dispatch(createProgram(modifiedValues));
		}
	};

	const toggleView = (viewName: 'PROGRAM_FORM' | 'CLASS_FORM' | 'PROGRAM_REVIEW_FORM') => setCurrentView(viewName);

	const getHeader = () => {
		switch (currentView) {
			case 'CLASS_FORM':
				return <h3>Module to Class Mapping</h3>;
			case 'PROGRAM_FORM':
				return <h3>{isEditForm ? 'Edit' : 'Create'} Program</h3>;
			case 'PROGRAM_REVIEW_FORM':
				return <h3>Select Program Reviews</h3>;
			default:
				return '';
		}
	};

	const getForm = () => {
		switch (currentView) {
			case 'CLASS_FORM':
				return (
					<ModuleToClassMappingForm
						{...{
							initialValues: isNotEmptyObject(initialValues)
								? pickWrapper(
										['coverPicture', 'portraitPic', 'photos', 'featuredCoverUrl', 'mobileCoverPic', 'certificate'],
										modifyProgramBeforeInitialize(initialValues)
								  )
								: {},
							programId,
							modules: initialValues?.modules as any,
							programEditStatus: initialValues?.programEditStatus,
							continuity: formValues.continuity,
						}}
					/>
				);
			case 'PROGRAM_FORM':
				return (
					<ProgramForm
						{...{ hookFormMethods, isEditForm, formValues, isRegularContinuous, onSubmit: handleSubmit(onSubmit), isEditDisabled }}
					/>
				);
			case 'PROGRAM_REVIEW_FORM':
				return (
					<ProgramReviewTable
						program={
							isNotEmptyObject(initialValues)
								? pickWrapper(
										['id', 'coverPicture', 'portraitPic', 'photos', 'featuredCoverUrl', 'mobileCoverPic', 'certificate'],
										modifyProgramBeforeInitialize(initialValues)
								  )
								: {}
						}
						featuredFeedbacks={initialValues.featuredFeedbacks || []}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<LoadingOverlay isLoading={isSubmitting} msg="Submitting Program...">
			<Card>
				<CardBody>
					<Box w="100%">
						<Row>
							<Col md="6">
								{/* eslint-disable no-nested-ternary */}
								{getHeader()}
							</Col>
							<Col md="6" className="text-right">
								<Button
									sm="medium"
									color="primary"
									onClick={() => toggleView(currentView === 'PROGRAM_FORM' ? 'CLASS_FORM' : 'PROGRAM_FORM')}
								>
									{currentView === 'PROGRAM_FORM' ? 'Module Structure' : 'Edit Program'}
								</Button>

								{currentView === 'PROGRAM_FORM' && isEditForm && (
									<Button sm="medium" color="primary" onClick={() => toggleView('PROGRAM_REVIEW_FORM')}>
										Select Program Reviews
									</Button>
								)}
							</Col>
						</Row>
					</Box>
					{getForm()}
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

function ProgramForm({ hookFormMethods, isRegularContinuous, isEditForm, onSubmit, formValues, isEditDisabled }) {
	return (
		<FormProvider {...hookFormMethods}>
			<form className="form ltr-support" id="program-form" onSubmit={onSubmit}>
				<fieldset disabled={isEditDisabled}>
					<Box w="100%" mt="1rem">
						<Row>
							<Col sm="6">
								<Input label="Title" name="title" required />
							</Col>
							<Col sm="6">
								<Input label="Slug" name="slug" required pattern={{ value: /^[a-z0-9-]*$/, message: 'Invalid Slug URL' }} />
							</Col>
							<Col sm="6">
								<Input label="Stars needed to enroll" name="starsToEnroll" type="number" min={0} />
							</Col>
							<Col sm="6">
								<SelectField
									label="Status"
									name="status"
									options={
										isEditForm && canProgramBeMarkedActive(formValues.modules, formValues.programType, true)
											? programStatusOptionsWithActive
											: programStatusOptionsWithoutActive
									}
									required
									isClickDisabled={isEditDisabled}
									helpText="Program can be marked ACTIVE from the table using ACTIONS after creation"
								/>
							</Col>
							<Col sm="6">
								<Input label="Tagline" name="tagline" required maxLength={80} />
							</Col>
							<Col sm="6">
								<Input
									type="number"
									label="Upvotes Count"
									name="upvotesCount"
									pattern={{ value: /[0-9]/, message: `Upvotes count should be an integer` }}
									min={1}
								/>
							</Col>
							<Col sm="12">
								<TextAreaNew label="Description" name="description" maxLength={400} required />
							</Col>
							<Col sm="6">
								<SelectField label="Age Range Min" name="ageRangeMin" options={ageRangeOptions} isClearable required />
								<SelectField
									label="Age Range Max"
									name="ageRangeMax"
									options={ageRangeOptions}
									isClearable
									required
									helpText="Age range max should be greater than age range min"
								/>
							</Col>
							<Col sm="6">
								<SelectField isClearable label="Language" name="language" options={languageList} />
							</Col>
							<Col sm="6">
								<AsyncSelectField isMulti label="Subscription type" name="subscriptionsRequired" fetchOptions={fetchPlansAndPackages} />
							</Col>
							<Col sm="6">
								<AsyncSelectField
									label="Skills"
									name="skills"
									fetchOptions={fetchSkills}
									isMulti
									isClearable
									required
									isClickDisabled={isEditDisabled}
								/>
							</Col>
							<Col sm="6">
								<AsyncSelectField
									label="Mentor"
									name="mentorId"
									fetchOptions={fetchMentors}
									isClearable
									required
									isClickDisabled={isEditDisabled}
								/>
							</Col>

							<Col sm="6">
								<AsyncSelectField isMulti label="Secondary Mentors" name="secondaryMentors" fetchOptions={fetchMentors} />
							</Col>
							<Col sm="6">
								<RadioGroup label="Program Type" name="programType" fields={programTypeOptions} required />
							</Col>

							<Col sm="4">
								<RadioGroup label="Progression" name="progression" fields={progressionOptions} required />
							</Col>
							<Col sm="4">
								<RadioGroup label="Continuity" name="continuity" fields={continuityOptions} required />
							</Col>
							{/* <Col sm="4">
								<RadioGroup label="Membership Type" name="membershipType" fields={membershipTypeOptions} required />
							</Col> */}
							<LearningOutcomeForm name="learningOutcomes" label="Learning Outcomes" />
							{/* <Col sm="6">
							<RadioGroup label="Occurrence" name="occurrence" fields={occurrenceOptions} required />
						</Col> */}

							<ModulesForm
								name="modules"
								label="Modules"
								miniumNumberOfFields={1}
								maximumNumberOfFields={formValues.continuity === 'CONTINUOUS' ? 1 : undefined}
								disabled={!formValues.continuity}
							/>

							<Col sm="6">
								<InputFieldArray name="keywords" label="Keywords" miniumNumberOfFields={2} maximumNumberOfFields={5} required />
							</Col>
							<Col sm="6">
								<InputFieldArray name="materials" label="Materials Required" miniumNumberOfFields={0} />
							</Col>
							{isEditForm && (
								<Col sm="6">
									<InputFieldArray
										disabled
										name="learningObjectives"
										label="Learning Objectives"
										miniumNumberOfFields={2}
										maximumNumberOfFields={5}
									/>
								</Col>
							)}

							<Col sm="12">
								<Dropzone label="Cover Picture" name="coverPicture" />
							</Col>
							<Col sm="12">
								<Dropzone
									label="Potrait Picture"
									name="portraitPic"
									validate={async (val) => {
										if (val && val.length && !(val[0] as any).id) {
											const isValid = await validateAspectRatio({ aspectRatio: 2 / 3, file: val[0] as Blob });
											return isValid ? true : 'Please upload photo with 2:3 Aspect Ratio';
										}
										return true;
									}}
								/>
							</Col>
							<Col sm="12">
								<Dropzone label="Photos" name="photos" withCaption isMulti noOfFiles={20} />
							</Col>
							<Col sm="12">
								<Dropzone label="Featured Banner" name="featuredCoverUrl" withCaption />
							</Col>
							<Col sm="12">
								<Dropzone label="Mobile Cover Picture" name="mobileCoverPic" withCaption />
							</Col>

							<Col sm="6">
								<Checkbox
									label="Is Featured?"
									name="isFeatured"
									disabled={
										!(
											formValues.mobileCoverPic &&
											formValues.mobileCoverPic.length &&
											formValues.featuredCoverUrl &&
											formValues.featuredCoverUrl.length
										)
									}
									helpText="Please upload mobile coverPic and featured banner to mark it as featured."
								/>
							</Col>
							<Col sm="2">
								<Checkbox label="Is Hot?" name="isHot" />
							</Col>
							<Col sm="2">
								<Checkbox
									label="Is Mixed Track?"
									disabled={!isRegularContinuous}
									name="isMixedTrack"
									helpText="Only Regular Continuous Program can be marked as Mixed Track Program"
								/>
							</Col>
							<Col sm="6">
								<AsyncSelectField
									label="HLS Video URL"
									name="hlsVideoUrl"
									fetchOptions={() => fetchVideos({ videoType: 'PROGRAM' })}
									isClearable
									isClickDisabled={isEditDisabled}
								/>
							</Col>

							<Box d="flex" ai="center" w="100%">
								<Col sm="4">
									<Checkbox label="Is Certificate Enabled?" name="certificate.isEnabled" />
								</Col>
								<Col sm="8">
									<Dropzone label="Certificate Image" name="certificate.image" required={formValues.certificate?.isEnabled} />
								</Col>
							</Box>

							<Box d="flex" ai="center" w="100%">
								<Col sm="6">
									<Input label="SEO Title" name="metaTags.title" />
								</Col>
								<Col sm="6">
									<TextAreaNew label="SEO Description" name="metaTags.description" />
								</Col>
							</Box>
							<Box d="flex" ai="center" w="100%">
								<Col sm="6">
									<Input label="SEO Image Alt Text" name="metaTags.imgAltText" />
								</Col>
							</Box>
						</Row>

						<Col sm="12" className="mt-4">
							<Button size="sm" color="primary" type="submit">
								{isEditForm ? 'Save' : 'Create'}
							</Button>
							<Button tag={Link} to="/program/list" size="sm" color="success" type="button" style={{ pointerEvents: 'auto' }}>
								Cancel
							</Button>
						</Col>
					</Box>
				</fieldset>
			</form>
		</FormProvider>
	);
}

const LearningOutcomeForm = ({ name, label, miniumNumberOfFields = 0, maximumNumberOfFields = 5 }) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	React.useEffect(() => {
		if (miniumNumberOfFields && fields.length === 0) {
			for (let i = 0; i < miniumNumberOfFields; i += 1) {
				append({ title: '', description: '', photo: [] });
			}
		}
	}, []);

	return (
		<Box w="100%" mt="2rem" mb="2rem">
			<h4 style={{ marginBottom: '20px' }}>Learning Outcomes</h4>

			{fields.map((item, index) => {
				return (
					<Box key={item.id} pos="relative" bor="1px solid grey" p="1rem" mb="1rem">
						<Row>
							<Col sm="6">
								<Input
									defaultValue={item.title}
									name={`${name}.${index}.title`}
									label={` ${label} ${index + 1} Title`}
									required
									maxLength={90}
								/>
							</Col>
							<Col sm="6">
								<TextAreaNew
									defaultValue={item.description}
									name={`${name}.${index}.description`}
									label={` ${label} ${index + 1} Description`}
									maxLength={500}
									required
								/>
							</Col>
							<Col sm="12">
								<Dropzone defaultValue={item.photo} name={`${name}.${index}.photo`} label={` ${label} ${index + 1} Photo`} />
							</Col>
						</Row>
						<If
							condition={index + 1 > miniumNumberOfFields}
							then={
								<Box onClick={() => remove(index)} cursor="pointer" pos="absolute" t="0" r="0">
									<span className="lnr lnr-cross" />
								</Box>
							}
						/>
					</Box>
				);
			})}

			<If
				condition={!maximumNumberOfFields || fields.length < maximumNumberOfFields}
				then={
					<Button size="sm" onClick={() => append({ title: '', description: '', photo: [] })}>
						Add Learning Outcome
					</Button>
				}
			/>
		</Box>
	);
};

function ModulesForm({ name, label, miniumNumberOfFields = 1, maximumNumberOfFields, disabled }) {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	React.useEffect(() => {
		if (miniumNumberOfFields && fields.length === 0) {
			for (let i = 0; i < miniumNumberOfFields; i += 1) {
				append({ index: '1', title: '', noOfClasses: '' });
			}
		}
	}, []);

	return (
		<Box w="100%" mb="3rem">
			<h4 style={{ marginBottom: '20px' }}>Module Details</h4>
			<Col sm="12">
				{fields.map((item, index) => {
					return (
						<Box key={item.id} pos="relative">
							<Row>
								<Col sm="4">
									<Input
										type="number"
										isClickDisabled
										pattern={{ value: /[0-9]/, message: `${label} ${index + 1} should be an integer` }}
										name={`${name}.${index}.index`}
										label={`${label} ${index + 1} Index`}
										defaultValue={item.index}
										required
									/>
								</Col>
								<Col sm="4">
									<Input
										name={`${name}.${index}.title`}
										label={` ${label} ${index + 1} Title`}
										defaultValue={item.title}
										required
										disabled={disabled}
									/>
								</Col>
								<Col sm="4">
									<Input
										type="number"
										min={1}
										pattern={{ value: /[0-9]/, message: `${label} ${index + 1} should be an integer` }}
										name={`${name}.${index}.noOfClasses`}
										label={` ${label} ${index + 1} Classes`}
										defaultValue={item.noOfClasses}
										required
										disabled={disabled}
									/>
								</Col>
							</Row>
							<If
								condition={index + 1 > miniumNumberOfFields}
								then={
									<Box onClick={() => remove(index)} cursor="pointer" pos="absolute" t="0" r="0">
										<span className="lnr lnr-cross" />
									</Box>
								}
							/>
							<span className="mb-2 form__form-group-help-text">Select Program Continuity first before modules</span>
						</Box>
					);
				})}

				<If
					condition={!maximumNumberOfFields || fields.length < maximumNumberOfFields}
					then={
						<Button size="sm" onClick={() => append({ index: fields.length + 1, title: '', noOfClasses: '' })}>
							Add Modules
						</Button>
					}
				/>
			</Col>
		</Box>
	);
}
