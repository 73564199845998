import React, { useEffect } from 'react';

import { useFieldArray } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import theme from 'utils/theme';

import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';

export const SubPromptFieldArray = ({ control, register, watch, promptsData, setValue, errors, fieldName = 'subPrompts' }) => {
	const promptsFieldArray = useFieldArray({
		control,
		name: fieldName,
	});

	useEffect(() => {
		if (promptsFieldArray.fields.length === 0) {
			promptsData?.forEach((pt, index) => {
				promptsFieldArray.append({
					...pt,
				});
			});
		}
	}, []);

	// const formValues = watch([fieldName]);
	return (
		<Box>
			{promptsFieldArray.fields.map((field, index) => {
				return (
					<Box key={field?.id} pos="relative">
						<Row>
							<Col sm="12">
								<h4 style={{ marginBottom: '1rem' }}>Prompt {index + 1}</h4>
								<Row>
									<Col sm="12">
										<InputNew name={`${fieldName}[${index}].roleName`} label="Role Name" defaultValue={field?.roleName} />
									</Col>
									<Col sm="12">
										<InputNew
											name={`${fieldName}[${index}].msgCounter`}
											label="Trigger Prompt On?"
											defaultValue={field?.msgCounter}
											type="number"
											required
										/>
									</Col>
									<Col sm="12">
										<InputNew name={`${fieldName}[${index}].startingMsg`} label="Starting Message" defaultValue={field?.startingMsg} />
									</Col>
									<Col sm="12">
										<TextAreaNew name={`${fieldName}[${index}].prompt`} label="Prompt" defaultValue={field?.prompt} required />
									</Col>
								</Row>
							</Col>
						</Row>
						<Box
							onClick={() => {
								promptsFieldArray.remove(index);
							}}
							cursor="pointer"
							pos="absolute"
							t="0"
							r="0"
						>
							<span className="lnr lnr-cross" />
						</Box>
					</Box>
				);
			})}
			<Row>
				<Col sm="12">
					<Box d="flex" jc="center">
						<Box
							c={theme.clrs.cWhite}
							fs="1rem"
							bRad="6px"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => promptsFieldArray.append({})}
							mb="1rem"
							p=".3rem"
						>
							+ Add New Prompt
						</Box>
					</Box>
				</Col>
			</Row>
		</Box>
	);
};
