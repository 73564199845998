import { createSlice } from '@reduxjs/toolkit';
import { isNotEmptyObject } from 'utils/commonHelpers';
// eslint-disable-next-line import/no-cycle
import { updateProgramAndUpdateList, fetchPrograms, fetchProgramFeedbacks } from './actions/program.actions';

const programSlice = createSlice({
	name: 'program',
	initialState: {
		loading: false,
		programs: [],
		programFeedbacks: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
			sort: null,
			entityType: null,
			entityId: null,
			status: null,
			programType: null,
			continuity: null,
			progression: null,
			language: null,
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...(isNotEmptyObject(action.payload.sort) && { sort: action.payload.sort }),
				...{ query: action.payload.query },
				...{ status: action.payload.status },
				...{ programType: action.payload.programType },
				...{ continuity: action.payload.continuity },
				...{ progression: action.payload.progression },
				...(action.payload.limit !== undefined && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.entityId !== undefined && { entityId: action.payload.entityId }),
				...(action.payload.entityType !== undefined && { entityType: action.payload.entityType }),
			};
		},
		getPrograms: () => {},
		getProgramsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.programs = action.payload.programs;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[fetchPrograms.fulfilled as any]: (state, action) => {
			const {
				payload: { programs, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.programs = programs;
			state.total = total;
			state.pageNo = page + 1;
		},
		[fetchProgramFeedbacks.fulfilled as any]: (state, action) => {
			const {
				payload: { programFeedbacks, total },
			} = action;
			const page = state.apiParams.skip / state.apiParams.limit;
			state.programFeedbacks = programFeedbacks;
			state.total = total;
			state.pageNo = page + 1;
		},
		[updateProgramAndUpdateList.fulfilled as any]: (state, action) => {
			const {
				payload: { programIndex, programData },
			} = action;
			state.programs[programIndex] = programData;
		},
	},
});

export const { toggleLoading, setApiParams, getPrograms, getProgramsSuccess, toggleIsSubmitting } = programSlice.actions;

export default programSlice.reducer;
