import { takeLatest } from 'redux-saga/effects';

import { getDiys, getDiy, getDiyId, diyOnSubmit, setDiyStatus } from './diySlice';
import { getDiysSaga } from './sagas/getDiysSaga';
import { getDiySaga } from './sagas/getDiySaga';
import { getDiyIdSaga } from './sagas/getDiyIdSaga';
import { setDiyStatusSaga } from './sagas/setDiyStatusSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';

export default function* diySaga() {
	yield takeLatest(getDiys.type, getDiysSaga);
	yield takeLatest(getDiy.type, getDiySaga);
	yield takeLatest(getDiyId.type, getDiyIdSaga);
	yield takeLatest(diyOnSubmit.type, onSubmitSaga);
	yield takeLatest(setDiyStatus.type, setDiyStatusSaga);
}
