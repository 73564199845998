import React from 'react';
import Box from 'reusableComponents/Box';
import history from '../../containers/App/history';

const Breadcrumbs = (props) => {
	const { breadcrumbs } = props;
	return (
		<Box d="flex">
			{breadcrumbs?.map((b, idx) => (
				<>
					<Box
						cursor={b.url ? 'pointer' : 'auto'}
						onClick={() => {
							if (b.url) {
								history.push(b.url);
							}
						}}
					>
						{b.text}
					</Box>
					&nbsp;{idx !== breadcrumbs.length - 1 ? <>/&nbsp;</> : null}
				</>
			))}
		</Box>
	);
};

export default Breadcrumbs;
