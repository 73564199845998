import { put, call } from 'redux-saga/effects';

import { actionSpreader } from '../../../utils/commonHelpers';
import { setLoading, resetLoading, getFaqSuccess } from '../faqSlice';
import { setError } from '../../App/globalSlice';
import { requestSaga } from '../../../utils/requestSaga';

export function* getFaqSaga({ payload }) {
	yield put(actionSpreader(setLoading.type));

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/faq?id=${payload.id}`);
	if (res.status === 200) {
		yield put(actionSpreader(getFaqSuccess.type, { faq: res.data }));
		yield put(actionSpreader(resetLoading.type));
	} else {
		yield put(actionSpreader(resetLoading.type));
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
