import { takeLatest } from 'redux-saga/effects';

import { getSkills, skillOnSubmit, setSkillStatus } from './skillSlice';
import { getSkillsSaga } from './sagas/getSkillsSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { setSkillStatusSaga } from './sagas/setSkillStatusSaga';

export default function* skillSaga() {
	yield takeLatest(getSkills.type, getSkillsSaga);
	yield takeLatest(skillOnSubmit.type, onSubmitSaga);
	yield takeLatest(setSkillStatus.type, setSkillStatusSaga);
}
