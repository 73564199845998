import React, { useEffect, useState } from 'react';
import { getSanitizedImageUrl } from 'utils/commonHelpers';

export const McqMobilePreview = ({ title, questionOptions, image }) => {
	const [questionTitle, setQuestionTitle] = useState(title);
	const [options, setOptions] = useState(questionOptions);
	const [descriptionImage, setDescriptionImage] = useState(image);

	useEffect(() => {
		setQuestionTitle(title);
		setOptions(questionOptions);
		setDescriptionImage(image);
	}, [title, questionOptions, image]);

	const RenderOptions = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
				{options &&
					options.map((option) => (
						<div
							key={option.id}
							style={{
								width: '45%',
								height: '100%',
								border: '1px solid #28C9A2',
								padding: '5px',
								margin: '5px',
								borderRadius: '5px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								boxShadow: '0px 3px 0px 1px #28C9A2',
								backgroundColor: '#28c9a214',
							}}
						>
							{option.media && option.media.length > 0 && (
								<img src={option.media[0].preview} alt={option.name} style={{ width: '50%', height: '50%' }} />
							)}
							<p
								style={{
									textAlign: 'center',
									fontWeight: 600,
									color: 'rgb(120 118 126)',
								}}
							>
								{option.value}
							</p>
						</div>
					))}
			</div>
		);
	};

	return (
		<>
			<div
				style={{
					width: '20rem',
					height: '33rem',
					border: '20px solid #fff',
					borderRadius: '10px',
					backgroundColor: 'rgb(26 221 255 / 9%)',
					boxShadow: 'inset 0px 0px 12px 2px #bababa66, 0px 0px 18px 2px #bababa99',
					position: 'sticky',
					top: '10rem',
					overflowY: 'scroll',
				}}
			>
				<img src={getSanitizedImageUrl('assets/admin/header.png')} alt="speakx icon" />
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'auto',
						marginTop: '.3rem',
					}}
				>
					{descriptionImage && descriptionImage.length > 0 && (
						<img
							src={descriptionImage[0].preview || (window.URL || window.webkitURL).createObjectURL(descriptionImage[0])}
							style={{ width: '90%', height: 'auto' }}
							alt="mcq preview"
						/>
					)}
				</div>
				<h3 style={{ textAlign: 'center' }}>{questionTitle}</h3>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						height: 'auto',
						margin: '.5rem 0',
					}}
				>
					<RenderOptions />
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<button
						type="button"
						style={{
							width: '90%',
							height: '40px',
							border: 'none',
							borderRadius: '100px',
							marginTop: 'auto',
							color: '#fff',
							backgroundColor: '#28C9A2',
							boxShadow: 'inset 0px 0px 12px 2px #bababa66, 0px 0px 18px 2px #bababa99',
						}}
					>
						Continue
					</button>
				</div>
			</div>
		</>
	);
};
