import { Col, Row } from 'reactstrap';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import theme from 'utils/theme';
import Box from 'reusableComponents/Box';
import { renderTextField } from 'reusableComponents/Form/formComponents';
import { maxLength, required } from 'reusableComponents/Form/fieldValidators';

const validationArr = [required, maxLength(90)];

export const renderFieldArray = ({ fields, buttonText, label }) => {
	useEffect(() => {
		if (!fields.length) fields.push('');
	}, [fields]);

	return (
		<Row className="mb-8">
			<Col sm="6">
				{fields.map((item, index) => (
					<Box pos="relative">
						<Field
							name={`${item}`}
							type="text"
							component={renderTextField}
							label={`${label} ${index + 1}`}
							placeholder="Max 90 characters allowed"
							isRequired
							validate={validationArr}
						/>
						<Box
							onClick={() => {
								fields.remove(index);
							}}
							cursor="pointer"
							pos="absolute"
							t="0"
							r="0"
						>
							<span className="lnr lnr-cross" />
						</Box>
					</Box>
				))}
				<Box d="flex" jc="center" ai="center" onClick={() => fields.push('')}>
					<Box
						w="2rem"
						h="2rem"
						mr="1rem"
						c={theme.clrs.cWhite}
						fs="1.4rem"
						bRad="50%"
						d="flex"
						jc="center"
						ai="center"
						bg={theme.clrs.cSkyBlue}
						cursor="pointer"
					>
						+
					</Box>
					<Box cursor="pointer">{buttonText}</Box>
				</Box>
			</Col>
		</Row>
	);
};

export function canProgramBeMarkedActive(modules = [], programType, editStatus) {
	if (editStatus !== 'ALL_EDIT') return false;

	if (programType === 'CONTINUOUS') return true;

	const { totalNumberOfClassesListed, totalNumberOfClassesCreated } = modules.reduce(
		(acc, curr) => {
			acc.totalNumberOfClassesListed += parseInt(curr.noOfClasses, 10);
			acc.totalNumberOfClassesCreated += curr?.classes?.length || 0;
			return acc;
		},
		{ totalNumberOfClassesListed: 0, totalNumberOfClassesCreated: 0 }
	);

	return totalNumberOfClassesListed === totalNumberOfClassesCreated;
}
