import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { BatchClassTable } from './components/BatchClassTable';
import { BatchClassEdit } from './components/BatchClassEdit';

const BatchClass = () => {
	return (
		<div>
			<Switch>
				<Route path="/batch-class/list" component={BatchClassTable} />
				<Route path="/batch-class/edit" component={BatchClassEdit} />
			</Switch>
		</div>
	);
};

export default BatchClass;
