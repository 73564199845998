import React from 'react';
import ClassicEditor from 'ckeditor5-build-classic-all-plugin';
import CKEditor from '@ckeditor/ckeditor5-react';
import styled from 'styled-components';

const CKEditorWrapper = (props) => {
	if (!props.entityId) {
		return <div>Loading...</div>;
	}
	return (
		<StyledContainer>
			<CKEditor
				editor={ClassicEditor}
				config={{
					simpleUpload: {
						uploadUrl: `${process.env.REACT_APP_API_URL}/v1/admin/upload-image?entityId=${props.entityId}&entityType=${props.entityType}&getAbsolutePath=true`,

						// Headers sent along with the XMLHttpRequest to the upload server.
						headers: {
							'X-CSRF-TOKEN': 'CSFR-Token',
							Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
						},
					},
				}}
				{...props}
			/>
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	svg {
		z-index: 0 !important;
	}
`;

export default CKEditorWrapper;
