import { showToast } from 'utils/commonHelpers';
import { fetchWithTimeout } from 'utils/fetchWithTimeout';
import { fetchWrapper } from 'utils/fetchWrapper';

interface blog {
	topic: string;
	sections: [];
}

export const generateBlog = async (payload: { topic: string; description: string }) => {
	try {
		const response = await fetchWithTimeout('/v1/admin/generate-blog', {
			method: 'POST',
			body: payload,
			customTimeout: 600000,
		});

		if (response.message !== 'Success') {
			throw new Error(response.message);
		} else {
			showToast('success', 'Blogs generated successfully');
			return response;
		}
	} catch (error) {
		showToast('error', error.message);
		return { error: true, message: error.message };
	}
};

export const uploadBlogs = async (payload: { topic: string; description: string; blog: blog }) => {
	try {
		const response = await fetchWrapper('/v1/admin/upload-blog', {
			method: 'POST',
			body: payload,
		});

		if (response.message !== 'Success') {
			throw new Error(response.message);
		}
		showToast('success', 'Blogs uploaded successfully');
	} catch (error) {
		showToast('error', error.message);
		return { error: true, message: error.message };
	}
};
