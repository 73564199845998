import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchLearningActivities, syncLearningActivities } from './actions/learningActivities.actions';
import { TLearningActivitiesState } from './utils/types';

type TActivityReducerType<T> = CaseReducer<TLearningActivitiesState, PayloadAction<T>>;

type TActivityReducers = {
	setActivitiesLoading: TActivityReducerType<void>;
	setActivitiesLoaded: TActivityReducerType<void>;
	setActivitiesError: TActivityReducerType<string>;
	setApiParams: TActivityReducerType<TLearningActivitiesState['apiParams']>;
	setPage: TActivityReducerType<number>;
	toggleIsSubmitting: TActivityReducerType<{ isSubmitting: boolean }>;
};

const learningActivitiesSlice = createSlice<TLearningActivitiesState, TActivityReducers>({
	name: 'learningActivities',
	initialState: {
		activities: [],
		activitiesLoading: false,
		activitiesError: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 20,
			skip: 0,
			query: '',
			sortKey: 'score',
			sortOrder: '-1',
		},
	},
	reducers: {
		setActivitiesLoading: (state) => {
			state.activitiesLoading = true;
		},
		setActivitiesLoaded: (state) => {
			state.activitiesLoading = false;
		},
		setActivitiesError: (state, action) => {
			state.activitiesError = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.query !== 'undefined' && { query: action.payload.query }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		// Fetch Learning Activities
		builder
			.addCase(fetchLearningActivities.pending, (state) => {
				state.activitiesLoading = true;
			})
			.addCase(fetchLearningActivities.fulfilled, (state, { payload }) => {
				state.activities = payload.activities;
				state.total = payload.total;
				state.activitiesLoading = false;
			});

		// Sync Learning Activities
		builder
			.addCase(syncLearningActivities.pending, (state) => {
				state.isSubmitting = true;
			})
			.addCase(syncLearningActivities.fulfilled, (state) => {
				state.isSubmitting = false;
			});
	},
});

export const { setApiParams, setActivitiesLoading, setActivitiesLoaded, setPage, toggleIsSubmitting } = learningActivitiesSlice.actions;

export default learningActivitiesSlice.reducer;
