/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { SelectField } from 'reusableComponents/Form/Select';
import { useSelector, useDispatch } from 'react-redux';
import Filter from 'reusableComponents/Filter';
import { setApiParams, setPage } from 'containers/School/SchoolSlice';
import { IPhoto } from 'types/Photo';
import { isNotEmptyArray, getSanitizedImageUrl } from '../../utils/commonHelpers';
import { fetchSchools } from './actions';
import DataPaginationTable from '../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../reusableComponents/Pagination/Pagination';
import Box from '../../reusableComponents/Box';
import history from '../App/history';

const defaultSchoolDisplayPicUrl = 'assets/school-contest/school-logo.svg';

const customStyles = {
	fontSize: '1rem',
	padding: '5px 10px',
	width: '5rem',
};

const FILTER_OPTIONS = [{ label: 'Incomplete School Details', value: 'INCOMPLETE_SCHOOL_DETAILS' }];

const excludedSortColumns = ['displayPicture', 'actions'];

interface ISchool {
	displayPicture: IPhoto;
	name: string;
	description: string;
	id: string;
	studentsCount: number;
	totalBolts: number;
}

const createNewRows = ({ schools, page, rowsCount }: { schools: ISchool[]; page: number; rowsCount: number }) => {
	let initialCount = rowsCount * (page - 1);
	initialCount += 1;
	return schools.map((school, schoolIndex) => {
		return {
			id: initialCount + schoolIndex,
			totalBolts: school.totalBolts,
			displayPicture: (
				<>
					<Box
						as="img"
						src={getSanitizedImageUrl(school.displayPicture?.url ? school.displayPicture?.url : defaultSchoolDisplayPicUrl)}
						w="4rem"
						h="4rem"
					/>
				</>
			),
			name: <h4 style={{ color: 'black' }}>{school.name}</h4>,
			studentsCount: school.studentsCount,
			actions: (
				<>
					<Button
						color="success"
						style={customStyles}
						onClick={() => {
							history.push(`/school/edit?schoolId=${school.id}`);
						}}
					>
						Edit
					</Button>
				</>
			),
		};
	});
};

const SchoolList = () => {
	const dispatch = useDispatch();

	const [schoolRows, setSchoolRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');

	const schoolState = useSelector(({ school }) => ({
		schools: school?.schools,
		apiParams: school.apiParams,
		total: school.total,
		page: school.page,
		loading: school.schoolLoading,
	}));

	React.useEffect(() => {
		dispatch(fetchSchools({ ...schoolState?.apiParams }));
	}, []);

	React.useEffect(() => {
		setPageOfItems(schoolState.page);
	}, [schoolState.page]);

	const heads = [
		{
			accessor: 'displayPicture',
			Header: 'Display Picture',
		},
		{
			accessor: 'name',
			Header: 'Name',
		},
		{
			accessor: 'studentsCount',
			Header: 'Total Student',
		},
		{
			accessor: 'totalBolts',
			Header: 'Total Bolts',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	React.useEffect(() => {
		if (isNotEmptyArray(schoolState.schools)) {
			const structuredRows = createNewRows({
				schools: schoolState.schools,
				page: schoolState.page,
				rowsCount: schoolState.apiParams.limit,
			});
			setSchoolRows(structuredRows);
		} else {
			setSchoolRows([]);
		}
	}, [schoolState.schools]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = schoolState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip,
					searchKey: apiParams.searchKey,
					filterOption: apiParams.filterOption,
				})
			);
			dispatch(fetchSchools({ ...schoolState?.apiParams, skip }));
		}
	};

	const onSearch = (event) => {
		dispatch(setApiParams({ ...schoolState.apiParams, searchKey: searchTerm }));
		event?.preventDefault?.();
		dispatch(fetchSchools({ ...schoolState.apiParams, searchKey: searchTerm }));
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = 1;
			} else {
				sortOrder = -1;
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: schoolState.apiParams.limit,
					skip: schoolState.apiParams.limit * (schoolState.page - 1),
					searchKey: schoolState.apiParams.searchKey,
					filterOption: schoolState.apiParams.filterOption,
				})
			);
			dispatch(
				fetchSchools({
					sortKey: sortColumn,
					sortOrder,
					limit: schoolState.apiParams.limit,
					skip: schoolState.apiParams.limit * (schoolState.page - 1),
					searchKey: schoolState.apiParams.searchKey,
					filterOption: schoolState.apiParams.filterOption,
				})
			);
		}
	};

	const onFilterChange = (val) => {
		dispatch(setApiParams({ ...schoolState.apiParams, filterOption: val }));
		dispatch(fetchSchools({ ...schoolState?.apiParams, filterOption: val }));
	};

	if (schoolState.loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={8}>
								<h3>Schools Listing</h3>
							</Col>
						</Row>
						<Row style={{ marginBottom: '1rem' }}>
							<Col sm={6}>
								<Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search by School Name" onSearch={onSearch} />
							</Col>
							<Col sm="6">
								<SelectField
									clearable
									placeholder="Filter"
									name="filter"
									options={FILTER_OPTIONS}
									onChange={(val) => onFilterChange(val)}
								/>
							</Col>
						</Row>
						{isNotEmptyArray(schoolRows) && (
							<>
								<DataPaginationTable excludedSortColumns={excludedSortColumns} heads={heads} rows={schoolRows} onSort={onSort} />
								<Pagination
									itemsCount={schoolState.total as number}
									itemsToShow={schoolState.apiParams.limit}
									pageOfItems={pageOfItems as number}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{schoolState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default SchoolList;
