import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Button, Card, CardBody, ButtonToolbar } from 'reactstrap';

import { renderTextField } from '../../../../reusableComponents/Form/formComponents';
import { required } from '../../../../reusableComponents/Form/fieldValidators';
import { getDiy, getDiyId, diyOnSubmit, getDiySuccess } from '../../diySlice';
import { STATUS_ACTIVE } from '../../../../utils/constants';
import {
	pickWrapper,
	parseQueryParams,
	isNotEmptyObject,
	showToast,
	isNotEmptyArray,
	getSanitizedImageUrl,
} from '../../../../utils/commonHelpers';
import { modifyBeforeSaveDiy } from '../../helpers';
import history from '../../../App/history';

import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import Box from '../../../../reusableComponents/Box';
import Range from '../../../../reusableComponents/Form/components/Range';
import DropZoneMultipleField from '../../../../reusableComponents/Form/DropZoneMultiple';

const DiyAddEdit = (props) => {
	return (
		<form className="form ltr-support" onSubmit={props.handleSubmit}>
			<Box w="100%">
				<Row>
					<Col md="9">
						<h3>{isNotEmptyObject(props.initialValues) ? 'Edit' : 'Create'} DIY</h3>
					</Col>
				</Row>
			</Box>
			<Box w="100%" mt="1rem">
				<Row>
					<Col sm="6">
						<Field label="Title" isRequired validate={required} type="text" component={renderTextField} name="title" />
					</Col>
					<Col sm="6" md="6" className="mb-4">
						<span className="form__form-group-label">Age Group</span>
						<Range changeHandler={(value) => props.setAgeGroup(value)} min={2} max={12} value={props.ageGroup} />
					</Col>
					<Col sm="12" className="mt-4">
						<span className="form__form-group-label">
							Photos <span className="form__form_group-label--required">&#42;</span>
						</span>
						<DropZoneMultipleField
							value={props.photos}
							onChange={(v) => {
								props.setPhotos(v);
							}}
						/>
					</Col>
					<ButtonToolbar className="form__button-toolbar">
						<Button type="button" onClick={() => history.push('/diy/list')}>
							Cancel
						</Button>
						<Button color="primary" type="submit">
							Submit
						</Button>
					</ButtonToolbar>
				</Row>
			</Box>
		</form>
	);
};

const withReduxForm = reduxForm({ form: 'add_edit_diy', enableReinitialize: true });

const DiyReduxForm = compose(withReduxForm)(DiyAddEdit);

const DiyForm = (props) => {
	const [photos, setPhotos] = useState([]);
	const [ageGroup, setAgeGroup] = useState([5, 8]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.search) {
			dispatch(getDiy(pickWrapper(['id'], parseQueryParams(props.location.search))));
		} else {
			dispatch(getDiyId());
		}
		return () => {
			dispatch(getDiySuccess({}));
		};
	}, [props.location.search]);

	useEffect(() => {
		if (isNotEmptyObject(props.diy)) {
			if (isNotEmptyArray(props.diy.photos)) {
				console.log('props.diy.photos', props.diy.photos);
				console.log(
					'photos',
					props.diy.photos.map((pic) => ({ id: pic.id, preview: getSanitizedImageUrl(pic.url) }))
				);
				setPhotos(
					props.diy.photos.map((pic) => ({
						name: pic.id,
						id: pic.id,
						preview: getSanitizedImageUrl(pic.url),
					}))
				);
			}
			if (isNotEmptyObject(props.diy.ageGroup)) {
				setAgeGroup([props.diy.ageGroup.min, props.diy.ageGroup.max]);
			}
		}
	}, [props.diy]);

	const handleSubmit = async (params) => {
		if (!isNotEmptyArray(photos)) {
			showToast('error', '🚫️ Photos is empty!');
		}
		const modifiedParams = { ...params, photos, ageGroup };
		const processedValues = modifyBeforeSaveDiy(modifiedParams, props.diy);
		if (isNotEmptyObject(processedValues)) {
			if (isNotEmptyObject(props.diy)) {
				processedValues.isEdit = true;
			} else {
				processedValues.isEdit = false;
				processedValues.id = props.diyId;
				processedValues.status = STATUS_ACTIVE;
			}
			console.log(processedValues);
			dispatch(diyOnSubmit(processedValues));
		} else {
			showToast('error', '🚫️ Please select at least one field!!');
		}
	};

	return (
		<LoadingOverlay isLoading={props.isSubmitting} msg="Submitting DIY Details...">
			<Card>
				<CardBody>
					<DiyReduxForm
						initialValues={props.diy}
						onSubmit={(args) => handleSubmit(args)}
						ageGroup={ageGroup}
						setAgeGroup={(val) => setAgeGroup(val)}
						diyId={props.location.search ? parseQueryParams(props.location.search).id : props.diyId}
						photos={photos}
						setPhotos={(pics) => setPhotos(pics)}
					/>
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};

const withConnect = connect((state) => ({
	diy: state.diy.diy,
	diyId: state.diy.diyId,
	loading: state.diy.loading,
	isSubmitting: state.diy.isSubmitting,
}));

export default compose(withConnect)(DiyForm);
