import { createSlice } from '@reduxjs/toolkit';

const skillSlice = createSlice({
	name: 'skill',
	initialState: {
		loading: false,
		skills: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			query: '',
		},
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ query: action.payload.query },
				...(action.payload.limit !== undefined && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getSkills: () => {},
		getSkillsSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.skills = action.payload.skills;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getSkill: () => {},
		getSkillSuccess: (state, action) => {
			state.skill = action.payload.skill;
		},
		skillOnSubmit: () => {},
		skillOnSubmitSuccess: (state, action) => {
			state.skills = !action.payload.isEdit
				? [action.payload.skill, ...state.skills]
				: state.skills.map((skill) => {
						if (skill.id === action.payload.skill.id) {
							return action.payload.skill;
						}
						return skill;
				  });
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		setSkillStatus: () => {},
		setSkillStatusSuccess: (state, action) => {
			state.skills[action.payload.pos].status = action.payload.status;
		},
	},
});

export const {
	toggleLoading,
	setApiParams,
	getSkills,
	getSkillsSuccess,
	getSkill,
	getSkillSuccess,
	skillOnSubmit,
	skillOnSubmitSuccess,
	toggleIsSubmitting,
	setSkillStatus,
	setSkillStatusSuccess,
} = skillSlice.actions;

export default skillSlice.reducer;
