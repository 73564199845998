import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Row, Col } from 'reactstrap';

import Box from 'reusableComponents/Box';
import { SelectFieldNew as SelectField } from 'reusableComponents/HookForm/Select';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import theme from 'utils/theme';
import { validateExternalWebAddress } from 'reusableComponents/Form/fieldValidators';
import If from 'reusableComponents/If';
import { InputNew as Input, InputNew } from 'reusableComponents/HookForm/Input';

import {
	clickActionOptions,
	CLICK_ACTION_OPTIONS,
	flexAlignmentOptions,
	textAlignOptions,
	textStyleOptions,
	WEBSITE_URL,
} from 'utils/constants';
import { ObjectMaybe } from 'utils/commonHelpers';

const ButtonColumns = ({ control, register, rowIndex, buttonIndex, buttonColumnData, watch, fieldName }) => {
	const buttonColumns = useFieldArray({
		control,
		name: `${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn`,
	});

	useEffect(() => {
		if (buttonColumns.fields.length === 0) {
			buttonColumnData?.forEach((column, index) => {
				buttonColumns.append({
					...column,
					tStyle: textStyleOptions.find((option) => option.value === column?.tStyle),
					textAlign: textAlignOptions.find((option) => option.value === column?.textAlign),
					prefixIconUrl: column?.prefixIcon?.url,
					suffixIconUrl: column?.suffixIcon?.url,
				});
			});
		} else {
			buttonColumnData?.forEach((column, index) => {
				control.setValue(
					`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].isStrikeThrough`,
					column?.strikeThrough
				);
			});
		}
	}, []);

	useEffect(() => {
		if (buttonColumns.fields.length !== 0) {
			buttonColumnData?.forEach((column, index) => {
				control.setValue(
					`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].isStrikeThrough`,
					column?.strikeThrough
				);
			});
		}
	}, [buttonColumnData, buttonColumns.fields.length]);

	return (
		<Box>
			<Box d="flex" jc="flex-start">
				{buttonColumns.fields.map((field, index) => {
					return (
						<Box key={field?.id} w="20rem" mr="2rem" pos="relative">
							<Row>
								<Col sm="12">
									<h4>Button Column {index + 1}</h4>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<InputNew
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].text`}
										label="Enter text"
										defaultValue={field?.text}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<SelectField
										label="text Style"
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].tStyle`}
										options={textStyleOptions}
										defaultValue={field?.tStyle || { label: 'B1_700', value: 'B1_700' }}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<InputNew
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].backgroundColor`}
										label="Background Color"
										defaultValue={field?.backgroundColor}
									/>
								</Col>
								<Col sm="12">
									<InputNew
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].textColor`}
										label="Text Color"
										defaultValue={field?.textColor || '#ffffff'}
									/>
								</Col>
								<Col sm="12">
									<InputNew
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].prefixIconUrl`}
										label="Prefix Icon Url"
										defaultValue={field?.prefixIconUrl}
									/>
								</Col>
								<Col sm="12">
									<InputNew
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].suffixIconUrl`}
										label="Suffix Icon Url"
										defaultValue={field?.suffixIconUrl}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<SelectField
										label="text Align"
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].textAlign`}
										options={textAlignOptions}
										defaultValue={field?.textAlign}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm="12">
									<Checkbox
										label="Strike Through"
										name={`${fieldName}[${buttonIndex}].buttonRow[${rowIndex}].buttonColumn[${index}].isStrikeThrough`}
									/>
								</Col>
							</Row>
							<Box onClick={() => buttonColumns.remove(index)} cursor="pointer" pos="absolute" t="0" r="0">
								<span className="lnr lnr-cross" />
							</Box>
						</Box>
					);
				})}
				<Box d="flex" jc="center">
					<Box
						w="2rem"
						h="2rem"
						c={theme.clrs.cWhite}
						fs="1.4rem"
						bRad="50%"
						d="flex"
						jc="center"
						ai="center"
						bg={theme.clrs.cSkyBlue}
						cursor="pointer"
						onClick={() => buttonColumns.append({ textStyle: textStyleOptions[0] })}
						mb="1rem"
						mt={buttonColumns.fields.length > 0 ? '15rem' : '0'}
					>
						+
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const ButtonRows = ({ control, register, indexButton, buttonRowsData, watch, fieldName }) => {
	const buttonRows = useFieldArray({
		control,
		name: `${fieldName}[${indexButton}].buttonRow`,
	});

	useEffect(() => {
		if (buttonRows?.fields?.length === 0) {
			buttonRowsData?.forEach((buttonRow, index) => {
				buttonRows.append({
					...buttonRow,
					flexAlignment: flexAlignmentOptions.find((option) => option.value === buttonRow.flexAlignment),
				});
			});
		}
	}, []);

	return (
		<Box>
			{buttonRows.fields.map((field, index) => {
				return (
					<Box key={field?.id} pos="relative">
						<Row>
							<Col sm="12">
								<h4>Button Row {index + 1}</h4>
								<SelectField
									label="Flex Alignment"
									name={`${fieldName}[${indexButton}].buttonRow[${index}].flexAlignment`}
									options={flexAlignmentOptions}
									defaultValue={field.flexAlignment || { label: 'CENTER', value: 'CENTER' }}
								/>
								<ButtonColumns
									{...{
										control,
										register,
										rowIndex: index,
										buttonIndex: indexButton,
										buttonColumnData: field?.buttonColumns,
										watch,
										fieldName,
									}}
								/>
							</Col>
							<Box
								onClick={() => {
									buttonRows.remove(index);
								}}
								cursor="pointer"
								pos="absolute"
								t="0"
								r="0"
							>
								<span className="lnr lnr-cross" />
							</Box>
						</Row>
					</Box>
				);
			})}
			<Row>
				<Col sm="12">
					<Box d="flex" jc="center">
						<Box
							c={theme.clrs.cWhite}
							fs="1rem"
							bRad="6px"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => buttonRows.append({})}
							mb="1rem"
							p=".3rem"
						>
							+ Add Button {indexButton + 1} Rows
						</Box>
					</Box>
				</Col>
			</Row>
		</Box>
	);
};

export const SmartButtonCTAFieldArray = ({ control, register, watch, buttonsData, setValue, errors, fieldName = 'button' }) => {
	const buttonsFieldArray = useFieldArray({
		control,
		name: fieldName,
	});

	useEffect(() => {
		if (buttonsFieldArray.fields.length === 0) {
			buttonsData?.forEach((btn, index) => {
				buttonsFieldArray.append({
					...btn,
					clickActionType: clickActionOptions.find((option) => option.value === btn.clickActionType),
				});
			});
		}
	}, []);

	const formValues = watch([fieldName]);
	return (
		<Box>
			{buttonsFieldArray.fields.map((field, index) => {
				return (
					<Box key={field?.id} pos="relative">
						<Row>
							<Col sm="12">
								<h3 style={{ marginBottom: '1rem' }}>Button {index + 1}</h3>
								<Row>
									<Box w="100%" d="flex" jc="space-between">
										<Col sm="6">
											<SelectField
												label="Click Action Type"
												name={`${fieldName}[${index}].clickActionType`}
												options={clickActionOptions}
												defaultValue={
													field?.clickActionType
														? clickActionOptions.find((x) => x.value === field?.clickActionType)
														: { label: 'App Link', value: CLICK_ACTION_OPTIONS.APP_LINK }
												}
											/>
										</Col>
										<Box w="100%" d="flex" ai="center" jc="flex-start">
											<If
												condition={formValues?.[fieldName]?.[index]?.clickActionType?.value === CLICK_ACTION_OPTIONS.APP_LINK}
												then={<p style={{ paddingLeft: '15px', marginBottom: '15px' }}>{WEBSITE_URL}/</p>}
											/>
											<If
												condition={formValues?.[fieldName]?.[index]?.clickActionType?.value !== CLICK_ACTION_OPTIONS.DISABLED}
												then={
													<Col sm="6">
														<Input
															label="Click Action URL"
															name={`${fieldName}[${index}].clickActionUrl`}
															validate={(val) => {
																if (formValues?.[fieldName]?.[index]?.clickActionType?.value === CLICK_ACTION_OPTIONS.EXTERNAL_LINK) {
																	return validateExternalWebAddress(val) || true;
																}
																return true;
															}}
															defaultValue={
																field?.clickActionUrl?.charAt(0) === '/' ? field.clickActionUrl.slice(1) : field?.clickActionUrl
															}
															helpText={errors?.[fieldName]?.[index]?.clickActionUrl?.message}
														/>
													</Col>
												}
											/>
										</Box>
									</Box>
								</Row>
								<Row>
									<Col sm="4">
										<InputNew
											name={`${fieldName}[${index}].buttonBackgroundColor`}
											label="Button Background Color"
											defaultValue={field?.backgroundColor || '#00bd90'}
										/>
									</Col>
									<Col sm="4">
										<InputNew name={`${fieldName}[${index}].key`} label="Key" defaultValue={field?.key} />
									</Col>
									<Col sm="4">
										<InputNew name={`${fieldName}[${index}].borderColor`} label="Border Color" defaultValue={field?.borderColor} />
									</Col>
								</Row>
								<Row>
									<Col sm="3">
										<InputNew
											type="number"
											name={`${fieldName}[${index}].flexFactor`}
											label="Flex Factor"
											defaultValue={field?.flexFactor || 1}
											min={1}
										/>
									</Col>
									<Col sm="3">
										<InputNew
											type="number"
											name={`${fieldName}[${index}].borderRadius`}
											label="Border Radius"
											defaultValue={field?.borderRadius === 0 || field?.borderRadius ? field?.borderRadius : 6}
											min={0}
										/>
									</Col>
									<Col sm="3">
										<InputNew
											type="number"
											name={`${fieldName}[${index}].padding.horizontal`}
											label="Horizontal Padding"
											defaultValue={field?.padding?.horizontal || 0}
											min={0}
										/>
									</Col>
									<Col sm="3">
										<InputNew
											type="number"
											name={`${fieldName}[${index}].padding.vertical`}
											label="Vertical Padding"
											defaultValue={field?.padding?.vertical || 0}
											min={0}
										/>
									</Col>
								</Row>
								<ButtonRows {...{ control, register, indexButton: index, buttonRowsData: field.buttonRows, watch, fieldName }} />
							</Col>
						</Row>
						<Box
							onClick={() => {
								buttonsFieldArray.remove(index);
							}}
							cursor="pointer"
							pos="absolute"
							t="0"
							r="0"
						>
							<span className="lnr lnr-cross" />
						</Box>
					</Box>
				);
			})}
			<Row>
				<Col sm="12">
					<Box d="flex" jc="center">
						<Box
							c={theme.clrs.cWhite}
							fs="1rem"
							bRad="6px"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => buttonsFieldArray.append({})}
							mb="1rem"
							p=".3rem"
						>
							+ Add Buttons
						</Box>
					</Box>
				</Col>
			</Row>
		</Box>
	);
};
