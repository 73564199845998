import React from 'react';
import styled from 'styled-components';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { FormProvider, useForm } from 'react-hook-form';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField } from 'reusableComponents/HookForm/Select';
import { fetchCategories } from 'utils/fetchHelpers';
import theme from '../../../../utils/theme';
import { isNotEmptyObject, omitWrapper } from '../../../../utils/commonHelpers';

import CustomModal from '../../../../reusableComponents/CustomModal';
import Box from '../../../../reusableComponents/Box';

const StyledModal = styled.div`
	height: auto;
	width: 30rem;
	padding: 1rem;
`;

const customStyles = `
	width: 30rem;

	@media ${theme.device.md} {
		width: 40rem;
		height: auto;
	}

	@media ${theme.device.sm} {
		width: 95vw;
		height: auto;
	}
`;

interface SkillFormValues {
	title: string;
	category: { label: string; value: string };
	textColor: string;
	backgroundColor: string;
}

export type SkillValuesFromDatabase = {
	title: string;
	categoryDetails: { id: string; title: string; displayTitle: string };
	textColor: string;
	backgroundColor: string;
};

interface ModifySkillBeforeCreateOrUpdateArgs extends SkillFormValues {
	id?: string;
	createdBy: string;
	updatedBy: string;
	isEdit: boolean;
}

export const modifySkillBeforeInitialize = (values: SkillValuesFromDatabase): SkillFormValues => {
	return {
		...values,
		category: { label: values.categoryDetails.title, value: values.categoryDetails.id },
	};
};

export const modifySkillBeforeCreateOrUpdate = (values: ModifySkillBeforeCreateOrUpdateArgs) => {
	return {
		...omitWrapper(['category'], values),
		categoryId: values.category.value,
	};
};

const SkillModal = ({ initialValue, handleSubmit: submitHandler, closeModal }) => {
	const { isSubmitting, loggedInUser } = useSelector(({ skill, auth }) => ({
		isSubmitting: skill.isSubmitting,
		loggedInUser: auth.user,
	}));

	const isEditForm = isNotEmptyObject(initialValue);

	const hookFormMethods = useForm<SkillFormValues>();

	const { handleSubmit } = hookFormMethods;

	React.useEffect(() => {
		if (isEditForm) {
			const modifiedValues = modifySkillBeforeInitialize(initialValue as any);
			hookFormMethods.reset(modifiedValues);
		}
	}, []);

	const onSubmit = (values: SkillFormValues) => {
		const modifiedValues = modifySkillBeforeCreateOrUpdate({
			...values,
			id: isEditForm ? initialValue.id : undefined,
			createdBy: isEditForm ? initialValue.createdBy : loggedInUser.id, // Only update createBy while creation
			updatedBy: loggedInUser.id,
			isEdit: isEditForm,
		});

		submitHandler(modifiedValues);
	};

	return (
		// @ts-expect-error
		<CustomModal customStyles={customStyles} closeModal={closeModal}>
			<StyledModal>
				<LoadingOverlay isLoading={isSubmitting} msg="Submitting Skill...">
					<Card>
						<CardBody>
							<FormProvider {...hookFormMethods}>
								<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
									<Box w="100%">
										<Row>
											<Col md="9">
												<h3>{isEditForm ? 'Edit' : 'Create'} Skill</h3>
											</Col>
										</Row>
									</Box>
									<Box w="100%" mt="1rem">
										<Row>
											<Col sm="12">
												<Input label="Title" name="title" required />
											</Col>
											<Col sm="6">
												<Input label="Background Color" name="backgroundColor" type="color" required />
											</Col>
											<Col sm="6">
												<Input label="Text Color" name="textColor" type="color" required />
											</Col>
											<Col sm="6">
												<AsyncSelectField label="Category" name="category" isClearable required fetchOptions={fetchCategories} />
											</Col>
										</Row>

										<Col sm="12" className="mt-4">
											<Button size="sm" color="primary" type="submit">
												{isEditForm ? 'Save' : 'Create'}
											</Button>
											<Button size="sm" color="success" type="button" onClick={closeModal}>
												Cancel
											</Button>
										</Col>
									</Box>
								</form>
							</FormProvider>
						</CardBody>
					</Card>
				</LoadingOverlay>
			</StyledModal>
		</CustomModal>
	);
};

export default SkillModal;
