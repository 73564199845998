// import NotFound from 'containers/NotFound';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserPostApproval } from './components/UserPostApproval';
import { UserPostTable } from './components/UserPostTable';

const UserPost = () => {
	return (
		<div>
			<Switch>
				<Route path="/user-post/list" component={UserPostTable} />
				<Route path="/user-post/approval" component={UserPostApproval} />
			</Switch>
		</div>
	);
};

export default UserPost;
