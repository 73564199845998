import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CurriculumList from './components/CurriculumList';

import ModuleList from './components/ModuleList';
import SubtopicList from './components/SubtopicList';
import ExerciseAddEditForm from './components/ExerciseAddEditForm';
import ExerciseList from './components/ExerciseList';
import ExerciseQuestionAddEditForm from './components/ExerciseQuestionAddEditForm';
import ExerciseQuestionList from './components/ExerciseQuestionList';
import SubtopicEditForm from './components/SubtopicEditForm';
import ModuleAddEditForm from './components/ModuleAddEditForm';
import CoursesList from './components/CoursesList';
import CourseAddEditForm from './components/CourseAddEditForm';

const Exercise = () => {
	return (
		<div>
			<Switch>
				<Route path="/exercise/curriculum/list" component={CurriculumList} />
				<Route path="/exercise/module/list" component={ModuleList} />
				<Route path="/exercise/module/add-edit" component={ModuleAddEditForm} />
				<Route path="/exercise/subtopic/list" component={SubtopicList} />
				<Route path="/exercise/subtopic/add-edit" component={SubtopicEditForm} />
				<Route path="/exercise/list" component={ExerciseList} />
				<Route path="/exercise/add-edit" component={ExerciseAddEditForm} />
				<Route path="/exercise/questions/list" component={ExerciseQuestionList} />
				<Route path="/exercise/questions/add-edit" component={ExerciseQuestionAddEditForm} />
				<Route path="/exercise/courses/list" component={CoursesList} />
				<Route path="/exercise/courses/add-edit" component={CourseAddEditForm} />
			</Switch>
		</div>
	);
};

export default Exercise;
