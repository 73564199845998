import NotFound from 'containers/NotFound';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReportApproval } from './components/ReportApproval';
import { ReportTable } from './components/ReportTable';

const Report = () => {
	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	return (
		<div>
			<Switch>
				<Route path="/report/list" component={ReportTable} />
				<Route
					path="/report/approval"
					component={process.env.REACT_APP_REPORT_ADMINS.split(' ').includes(loggedInUser.email[0]) ? ReportApproval : NotFound}
				/>
			</Switch>
		</div>
	);
};

export default Report;
