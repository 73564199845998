import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getCertificate } from './actions/certificate.actions';

const certificateSlice = createSlice({
	name: 'certificate',
	initialState: {
		loading: false,
		isSubmitting: false,
		certificate: null,
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: {
		[getCertificate.fulfilled as any]: (state, action) => {
			const { certificate } = action.payload;
			state.certificate = certificate;
		},
	},
});

export const { toggleLoading, toggleIsSubmitting } = certificateSlice.actions;

export default certificateSlice.reducer;
