import { call, put, select } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader, stringifyQueryParams } from '../../../utils/commonHelpers';
import { getWhatsappUserAnalyticsSuccess, toggleLoading } from '../whatsappSlice';
import { setError } from '../../App/globalSlice';

export function* getWhatsappUserAnalyticsSaga() {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));

	const apiParams = yield select((state) => state.whatsapp.apiParams);
	const qp = stringifyQueryParams({ ...apiParams });

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/whatsapp/analytics${qp}`);
	if (res.status === 200) {
		yield put(actionSpreader(getWhatsappUserAnalyticsSuccess.type, res.data));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
	yield put(actionSpreader(toggleLoading.type, { loading: false }));
}
