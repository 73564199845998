import { createSlice } from '@reduxjs/toolkit';

const videoSlice = createSlice({
	name: 'video',
	initialState: {
		loading: false,
		videos: [],
		total: 0,
		pageNo: 1,
		isSubmitting: false,
		apiParams: {
			sortKey: 'createdAt',
			sortOrder: '-1',
			limit: 20,
			skip: 0,
			videoName: '',
			videoType: null,
			language: null,
		},
		isRefreshing: {},
		dbxData: null,
		dbxFoldersLoading: false,
	},
	reducers: {
		toggleLoading: (state, action) => {
			state.loading = action.payload.loading;
		},
		toggleFoldersLoading: (state, action) => {
			state.dbxFoldersLoading = action.payload.loading;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ videoName: action.payload.videoName },
				...{ language: action.payload.language },
				...{ videoType: action.payload.videoType },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		getVideos: () => {},
		getVideosSuccess: (state, action) => {
			const page = state.apiParams.skip / state.apiParams.limit;
			state.videos = action.payload.videos;
			state.total = action.payload.total;
			state.pageNo = page + 1;
		},
		getVideoStatus: () => {},
		getVideoStatusSuccess: (state, action) => {
			state.videos = state.videos.map((video) => {
				if (video.dropboxPath === action.payload.dropboxPath) {
					return { ...video, ...action.payload };
				}
				return video;
			});
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
		getDropboxFolders: () => {},
		searchDropboxFile: () => {},
		searchDropboxFileSuccess: (state, action) => {
			state.dbxData = {
				...action.payload,
				list: action.payload.list ?? [],
			};
		},
		dropboxFoldersSuccess: (state, action) => {
			state.dbxData = {
				...action.payload,
				list: action.payload.shouldConcatenate ? [...state.dbxData.list, ...action.payload.list] : action.payload.list,
			};
		},
		clearDropboxFolders: (state) => {
			state.dbxData = null;
		},
		setIsRefreshing: (state, action) => {
			state.isRefreshing[action.payload.dropboxPath] = action.payload.value;
		},
	},
});

export const {
	toggleLoading,
	toggleFoldersLoading,
	setApiParams,
	getVideos,
	getVideosSuccess,
	getVideoStatus,
	getVideoStatusSuccess,
	toggleIsSubmitting,
	getDropboxFolders,
	dropboxFoldersSuccess,
	clearDropboxFolders,
	setIsRefreshing,
	searchDropboxFile,
	searchDropboxFileSuccess,
} = videoSlice.actions;

export default videoSlice.reducer;
