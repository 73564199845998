const clrs = {
	cOrange: '#f05a28',
	cOrangeLight: '#f26c40',
	cOrangeDark: '#ee4811',

	cBlue: '#353FDF',
	cBlueLight: '#3D4AFF',
	cBlueDark: '#1F2580',

	cPink: '#df359e',
	cSkyBlue: '#4B90E4',

	// Grays
	cBlack: '#000000',
	cGraphite: '#1d2337',
	cStone: '#494949',
	cSmoke: '#787E8F',
	cMix: '#1d23374d',
	cCloud: '#E5E6F6',
	cGoose: '#FAFAFA',
	cSilver: '#FFF8E7',
	cWhite: '#FFFFFF',
	cBgGray: '#f5f6f7',

	// Secondary accent colors
	cError: '#FC374B',
	cGrass: '#2be36d',
	cVerified: '#35df99',

	// Social colors
	cFacebook: '#3b5998',
	cFacebookLight: '#3b5998ef',
	cWhatsapp: '#25D366',

	// Background colors
	cGray: '#FBFBFB',

	new: {
		cYellow: '#ffaa00',
		cGreen: '#2aa838',
		cRed: '#e94b35',
		cRedLight: '#ed695f',
		cPrimaryText: '#212a39',
		cBorder: 'rgba(206, 206, 206, 0.5)',
		cBackground: '#f7f8fc',
	},
};

const fs = {
	t: '1.2rem',
	s: '1.4rem',
	r: '1.6rem',
	m: '2rem',
	l: '2.6rem',
	vl: '3rem',
	vvl: '5rem',
	vvvl: '6rem',
};

const size = {
	xxl: '1920px',
	xl: '1440px',
	lg: '1200px',
	md: '992px',
	sm: '768px',
	xs: '576px',
	xxs: '480px',
};

const device = {
	xxl: `screen and (max-width: ${size.xxl})`,
	xl: `screen and (max-width: ${size.xl})`,
	lg: `screen and (max-width: ${size.lg})`,
	md: `screen and (max-width: ${size.md})`,
	sm: `screen and (max-width: ${size.sm})`,
	xs: `screen and (max-width: ${size.xs})`,
	xxs: `screen and (max-width: ${size.xxs})`,
	minXxl: `screen and (min-width: ${size.xxl})`,
	minXl: `screen and (min-width: ${size.xl})`,
	minLg: `screen and (min-width: ${size.lg})`,
	minMd: `screen and (min-width: ${size.md})`,
	minSm: `screen and (min-width: ${size.sm})`,
	minXs: `screen and (min-width: ${size.xs})`,
	minXxs: `screen and (min-width: ${size.xxs})`,
};

export default {
	clrs,
	fs,
	size,
	device,
};
