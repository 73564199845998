import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CareersTable from './components/CareersTable';
import CareersAddEdit from './components/CareersAddEdit';

const Careers = () => {
	return (
		<div>
			<Switch>
				<Route path="/careers/list" component={CareersTable} />
				<Route path="/careers/add-edit" component={CareersAddEdit} />
			</Switch>
		</div>
	);
};

export default Careers;
