import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SkillTable from './components/SkillTable';

const Skill = () => {
	return (
		<div>
			<Switch>
				<Route path="/skill/list" component={SkillTable} />
			</Switch>
		</div>
	);
};

export default Skill;
