import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../enrollmentsSlice';

export const getEnrollments = createAsyncThunk(
	'enrollment/getEnrollments',
	async ({ sortKey, sortOrder, limit, skip, query, batchId, numClassAttended }: any, { dispatch }) => {
		dispatch(toggleLoading({ loading: true }));

		const qp = stringifyQueryParams({ sortKey, sortOrder, limit, skip, query, batchId, numClassAttended });
		const res = await fetchWrapper(`/v1/admin/enrollments${qp}`, { method: 'GET' });

		try {
			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				return {
					enrollments: res.data.enrollments,
					total: res.data.total,
				};
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleLoading({ loading: false }));
		}
	}
);

export const uploadCertificate = createAsyncThunk('enrollment/uploadCertificate', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/certificates', {
			method: 'POST',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `👍️ Request to create certificates has been successfull. Please check after some time.`);
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const deleteCertificate = createAsyncThunk('enrollment/deleteCertificate', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/delete-certificate', {
			method: 'DELETE',
			body: data,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			showToast('success', `Certificate deleted`);
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
