import { parseISO, formatDistance, format } from 'date-fns';

import { monthsList } from './constants';

export const parseDisjointDateToMongoFormat = (year, month, date) => new Date(year, month, date).toISOString();

export const parseMongoDateToDisjointFormat = (input) => {
	const dd = new Date(input);
	return {
		year: { label: dd.getFullYear(), value: dd.getFullYear() },
		month: monthsList.find((x) => x.value === dd.getMonth()),
		date: { label: dd.getDate(), value: dd.getDate() },
	};
};

export const dateToMongoDate = (input) => (input instanceof Date ? input.toISOString() : input);

export const mongoDateToDate = (input) => new Date(input);

export const dateTimeConverter = (timestamp, formatType, formatStr) => {
	try {
		const a = new Date(timestamp);
		let retFormat;
		if (a.toString() === 'Invalid Date') {
			return '';
		}

		switch (formatType) {
			case 'custom':
				retFormat = parseISO(timestamp, formatStr || 'dd MMM yyyy', new Date());
				return retFormat.toDateString();
			case 'formatDistance':
				retFormat = formatDistance(a, new Date());
				return retFormat;
			case 'format':
				return format(new Date(timestamp), formatStr);
			default:
				retFormat = '';
		}
		return retFormat;
	} catch (e) {
		console.log('dateTimeConverter Error', e); // NOTE: intentional log - DKA
		return '';
	}
};
