import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams, wait } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { addMessage, setMessagesLoading, resetMessages } from 'containers/WhatsappChats/whatsappChatSlice';

export const getAllWhatsappChatAssignees = createAsyncThunk('whatsappChat/getAssignees', async (data: any, { dispatch }) => {
	try {
		const res = await fetchWrapper(`/v1/whatsapp-chat-assignees`, { method: 'GET' });

		if (res.status !== 200) {
			return dispatch({ type: setError.type, payload: { errors: res.errors } });
		}
		if (res.status === 200) {
			return { assignees: res.data };
		}
		if (res.status === 204) {
			return { assignees: [] };
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	}
});

export const getMessages = createAsyncThunk(
	'whatsappChat/getMessages',
	async ({ mobile, limit, sortKey, sortOrder, lastMessageId, operator, initialMessageLoad = false }: any, { dispatch }) => {
		try {
			dispatch(setMessagesLoading({ isMessagesLoading: true }));

			const qp = stringifyQueryParams({ mobile, limit, sortKey, sortOrder, lastMessageId, operator });

			const res = await fetchWrapper(`/v1/whatsapp-messages${qp}`, { method: 'GET' });

			if (res.status !== 200) {
				return dispatch({ type: setError.type, payload: { errors: res.errors } });
			}
			if (res.status === 200) {
				return { messages: res.data, limit, initialMessageLoad };
			}
			if (res.status === 204) {
				return { messages: [], limit };
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const updateThreadStatus = createAsyncThunk(
	'whatsappChat/updateThreadStatus',
	async ({ mobile, status, assignedTo, lastUserMessageId, isParked, onSuccess }: any, { dispatch }) => {
		try {
			const res = await fetchWrapper('/v1/wa-conversation-status', {
				method: 'PUT',
				body: { mobile, status, lastUserMessageId, isParked, ...(assignedTo ? { assignedTo } : {}) },
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				const {
					data: { status, message, data: updatedConversation },
				} = res;

				if (status) {
					showToast('success', message);
					onSuccess();
					return updatedConversation;
				}
				showToast('error', message);
				dispatch(resetMessages());
				await wait(100);
				dispatch(getMessages({ mobile, limit: 20, sortKey: 'createdAt', sortOrder: -1 }));
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const updateThreadAssignee = createAsyncThunk(
	'whatsappChat/updateThreadAssignee',
	async ({ mobile, assignTo, onSuccess }: any, { dispatch }) => {
		try {
			const res = await fetchWrapper('/v1/wa-conversation-assignee', {
				method: 'PUT',
				body: { mobile, assignedTo: assignTo },
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				const {
					data: { message, data: updatedConversation },
				} = res;
				showToast('success', message);
				onSuccess();
				return updatedConversation;
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);

export const sendMessage = createAsyncThunk(
	'whatsappChat/sendMessage',
	async ({ userId, text, mobile, onSuccess, adminUserId }: any, { dispatch }) => {
		try {
			dispatch(addMessage());
			const res = await fetchWrapper('/v1/add-whatsapp-message', {
				method: 'POST',
				body: { userId, text, mobile, adminUserId },
			});

			if (res.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				onSuccess();
				return { message: res.data.data };
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		}
	}
);
