import { actionSpreader } from '../../utils/commonHelpers';
import { CLOSE_ALL_MODALS, CLOSE_MODAL, OPEN_MODAL } from './constants';
import { ModalTypes } from './types';

export const OpenModalAction = <T>(type: ModalTypes, props: T) =>
	actionSpreader(OPEN_MODAL, {
		type,
		props,
	});

export const CloseModalAction = (type: ModalTypes) => actionSpreader(CLOSE_MODAL, type);

export const CloseAllModalsAction = () => actionSpreader(CLOSE_ALL_MODALS);
