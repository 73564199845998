import { takeLatest } from 'redux-saga/effects';

import { getOpening, getOpeningId, getOpenings, openingOnSubmit, setOpeningStatus } from './careerSlice';
import { getOpeningsSaga } from './sagas/getOpeningsSaga';
import { getOpeningSaga } from './sagas/getOpeningSaga';
import { getOpeningIdSaga } from './sagas/getOpeningIdSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { setOpeningStatusSaga } from './sagas/setOpeningStatusSaga';

export default function* careerSaga() {
	yield takeLatest(getOpenings.type, getOpeningsSaga);
	yield takeLatest(getOpening.type, getOpeningSaga);
	yield takeLatest(getOpeningId.type, getOpeningIdSaga);
	yield takeLatest(openingOnSubmit.type, onSubmitSaga);
	yield takeLatest(setOpeningStatus.type, setOpeningStatusSaga);
}
