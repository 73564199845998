import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Card, CardBody } from 'reactstrap';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import ToggleButtonField from 'shared/components/form/ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyObject, showToast } from 'utils/commonHelpers';
import VideoJsPlayer from 'reusableComponents/VideoPlayer/VideoJsPlayer';
import { updateReport, getPendingReport } from '../actions/report.actions';

enum ReportStatusEnum {
	Removed = 'APPROVED',
	Restored = 'REJECTED',
}

// TIP: Update the keys here to change the keybindings
// enum KeyCodeEnum {
// 	Approved = 'Enter',
// 	Rejected = 'Backspace',
// }

export const ReportApproval = () => {
	const dispatch = useDispatch();

	const [keepFetching, setKeepFetching] = useState(true);
	const [isReportResolved, setIsReportResolved] = useState(null);
	const [isPendingReportFinished, setIsPendingReportFinished] = useState(false);
	const ref = useRef(null);

	const reportState = useSelector(({ report }) => ({
		loading: report.loading,
		pendingReport: report.pendingReport,
		reportedPost: report.reportedPost,
	}));
	const loggedInUser = useSelector(({ auth }) => auth.user);

	const getNextReport = () => {
		dispatch(getPendingReport({ adminUserId: loggedInUser.id }));
	};

	// const onKeyPress = (e: KeyboardEvent) => {
	//	 e.preventDefault();
	//	 if (e.code === KeyCodeEnum.Approved) onReportAction(ReportStatusEnum.Removed);
	//	 else if (e.code === KeyCodeEnum.Rejected) onReportAction(ReportStatusEnum.Restored);
	// };

	const onReportAction: (status: ReportStatusEnum) => void = (status: ReportStatusEnum) => {
		const { id, keepFetching, isReportResolved } = ref.current;

		if (isReportResolved === false)
			dispatch(
				updateReport({
					status,
					pickedBy: null,
					id,
					onSuccess: keepFetching
						? () => {
								showToast(
									status === ReportStatusEnum.Removed ? 'error' : 'success',
									`Successfully ${status === ReportStatusEnum.Removed ? 'Removed' : 'Restored'} the post!`
								);
								setIsReportResolved(true);
								getNextReport();
						  }
						: () => {
								showToast(
									status === ReportStatusEnum.Removed ? 'error' : 'success',
									`Successfully ${status === ReportStatusEnum.Removed ? 'Removed' : 'Restored'} the post!`
								);
								setIsReportResolved(true);
						  },
				})
			);
	};

	useEffect(() => {
		getNextReport();

		// NOTE: Uncomment these to activate keybinding
		// window.addEventListener('keydown', onKeyPress);
		// return () => {
		// 	window.removeEventListener('keydown', onKeyPress);
		// };
	}, []);

	useEffect(() => {
		if (!reportState.pendingReport) setIsPendingReportFinished(true);
		else if (isNotEmptyObject(reportState.pendingReport)) {
			setIsReportResolved(false);
			ref.current = { ...ref?.current, id: reportState.pendingReport.id };
		}
	}, [reportState.pendingReport]);

	useEffect(() => {
		ref.current = { ...ref?.current, keepFetching, isReportResolved };
	}, [keepFetching, isReportResolved]);

	return (
		<LoadingOverlay isLoading={reportState.loading} msg="Fetching Report...">
			<Card>
				<CardBody>
					<Box w="100%">
						<Box d="flex" ai="center" jc="space-between">
							<h3>Report Approval</h3>

							<Box d="flex" ai="center">
								<Box d="flex" ai="center" mr="0.4rem">
									Keep Fetching
								</Box>
								<Box d="flex" ai="center">
									<ToggleButtonField
										name="keepFetching"
										value={keepFetching}
										onChange={(val) => {
											setKeepFetching(val);
										}}
										defaultChecked
									/>
								</Box>
							</Box>
						</Box>
					</Box>

					{isPendingReportFinished ? (
						<Box mt="2rem" tl="center">
							All Pending Reports have been resolved for now. Please check later.
						</Box>
					) : (
						<Box w="100%" mt="2rem">
							{isReportResolved ? (
								'Please pick next report using the button below, or enable Keep Fetching to fetch more posts.'
							) : (
								<>
									<Box mh="200px">
										{isNotEmptyObject(reportState.pendingReport) && (
											<>
												<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Post Caption:</p>
												<p>{reportState.reportedPost.caption}</p>
												{reportState.reportedPost.blogContent && (
													<div>
														<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Blog Content : </p>
														<ReactMarkdown>{reportState.reportedPost.blogContent}</ReactMarkdown>
													</div>
												)}
												<p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Medias:</p>
												{reportState.reportedPost.media.length > 0 ? (
													<Box d="flex" wrap="wrap" ai="center">
														{reportState.reportedPost.media.map((media) =>
															media.mediaType === 'IMAGE' ? (
																<img style={{ width: 'calc(20% - 20px)', marginRight: '20px' }} src={media?.url} alt="Post Report" />
															) : (
																<VideoJsPlayer videoUrl={media?.url} />
															)
														)}
													</Box>
												) : (
													<p>---</p>
												)}
											</>
										)}
									</Box>
									<p style={{ fontWeight: 'bold', marginTop: '20px' }}>
										NOTE: You have picked the above report, so it won't be visible to other admins. Please resolve it before closing.
									</p>
								</>
							)}

							<Box mt="1rem" d="flex" ai="center" jc="center">
								<Button
									size="sm"
									color="primary"
									type="button"
									disabled={isReportResolved !== false}
									onClick={() => onReportAction(ReportStatusEnum.Restored)}
								>
									Mark as Approved
								</Button>
								<Button
									size="sm"
									color="danger"
									type="button"
									disabled={isReportResolved !== false}
									onClick={() => onReportAction(ReportStatusEnum.Removed)}
								>
									Mark as Rejected
								</Button>
								{isReportResolved && (
									<Button size="sm" color="primary" type="button" onClick={getNextReport}>
										Pick Next
									</Button>
								)}
							</Box>
							{/* <Box d="flex" ai="center" jc="center">
								Press ENTER to Approve OR BACKSPACE to Reject a Report.
							</Box> */}
						</Box>
					)}
				</CardBody>
			</Card>
		</LoadingOverlay>
	);
};
