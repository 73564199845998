import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting, toggleLoading } from '../userSlice';

export const getAdminMentors = createAsyncThunk('user/getAdminMentors', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/admins?isMentor=true', {
			method: 'GET',
		});

		if (res.status !== 200) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				mentors: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const getAdmins = createAsyncThunk('user/getAdmins', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const res = await fetchWrapper('/v1/admin/admins', {
			method: 'GET',
		});

		if (res.status !== 200) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				admins: res.data,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const grantUserSubscription = createAsyncThunk(
	'user/grantMembership',
	async ({ userId, adminUserId, numDays }: any, { dispatch }) => {
		dispatch(toggleIsSubmitting({ isSubmitting: true }));

		try {
			const payload = {
				refereeUserId: userId,
				adminUserId,
				numDays,
			};

			const res = await fetchWrapper('/v1/admin/user/grant-membership', {
				method: 'POST',
				body: payload,
			});

			if (res.status !== 200) {
				dispatch({ type: toggleLoading.type, payload: { loading: false } });
				dispatch({ type: setError.type, payload: { errors: res.errors } });
			} else {
				showToast('success', `👍️ Subscription days added succcessfully!!`);
			}
		} catch (error) {
			showToast('error', `Something went wrong`);
		} finally {
			dispatch(toggleIsSubmitting({ isSubmitting: false }));
		}
	}
);

export const getUserCertificates = createAsyncThunk('user/getCertificates', async ({ userId, skip, limit }: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	try {
		const valuesToSubmit = {
			userId,
			status: 'ACTIVE',
			skip,
			limit,
		};

		const res = await fetchWrapper(`/v1/admin/get-certificates`, { method: 'POST', body: valuesToSubmit });

		if (res.status !== 200) {
			dispatch({ type: toggleLoading.type, payload: { loading: false } });
			dispatch({ type: setError.type, payload: { errors: res.errors } });
		} else {
			return {
				certificates: res.data.certificates,
				total: res.data.count,
			};
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
