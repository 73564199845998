import { IMAGE_URL } from '../../utils/constants';

export const modifyBeforeSaveFaqDetails = (params) => {
	if (params.answer) {
		const srcRegEx = new RegExp(`${IMAGE_URL}/`, 'g');
		const dd = params.answer.replace(srcRegEx, '');
		const answer = dd.replaceAll('<a href', "<a target='_blank' href");
		return { ...params, answer };
	}
	return params;
};

export const modifyBeforeSetFaqDetails = (params) => {
	if (params.answer) {
		const srcRegEx = new RegExp(`${process.env.REACT_APP_IMG_FOLDER}/FAQ`, 'g');
		const answer = params.answer.replace(srcRegEx, `${IMAGE_URL}/${process.env.REACT_APP_IMG_FOLDER}/FAQ`);
		return { ...params, answer };
	}
	return params;
};
