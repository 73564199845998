import { showToast } from 'utils/commonHelpers';
import { fetchWithTimeout } from 'utils/fetchWithTimeout';

export const midjourneyImagine = async ({ prompt }: { prompt: string }) => {
	try {
		if (prompt.trim().length < 10) {
			showToast('success', 'Too short prompt');
			return;
		}

		const res = (
			await fetchWithTimeout('/v1/admin/mid-imagine', {
				method: 'POST',
				body: {
					prompt,
				},
				customTimeout: 180000,
			})
		)?.data;

		if (!res) {
			throw new Error('No response received from server');
		}

		if (res?.data?.progress !== 'done') {
			throw new Error('Something went wrong, try again');
		}

		showToast('success', `Image generated Successfully 👍️`);

		return {
			imagineResponse: res?.data,
		};
	} catch (error) {
		showToast('error', error.message);
	}
};

export const midjourneyUpscale = async ({ selectedOption = 1, imagineResponse }: { selectedOption: number; imagineResponse: string }) => {
	try {
		const res = (
			await fetchWithTimeout('/v1/admin/mid-upscale', {
				method: 'POST',
				body: {
					selectedOption,
					imagineResponse: JSON.stringify(imagineResponse),
				},
				customTimeout: 180000,
			})
		)?.data;

		if (!res) {
			throw new Error('No response received from server');
		}

		if (res?.data?.progress !== 'done') {
			throw new Error('Something went wrong, try again');
		}

		showToast('success', `Image upscaled Successfully 👍️`);

		return {
			upscaleResponse: res?.data,
		};
	} catch (error) {
		showToast('error', error.message);
	}
};
