import { takeLatest } from 'redux-saga/effects';

import { getReferralCode, getReferralCodes } from './referralCodeSlice';
import { getReferralCodesSaga } from './sagas/getReferralCodesSaga';
import { getReferralCodeSaga } from './sagas/getReferralCodeSaga';

export default function* referralCodeSaga() {
	yield takeLatest(getReferralCodes.type, getReferralCodesSaga);
	yield takeLatest(getReferralCode.type, getReferralCodeSaga);
}
