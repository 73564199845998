import React, { useEffect, useState } from 'react';
import { getSanitizedImageUrl } from 'utils/commonHelpers';
import { AnagramTypesEnum } from '../utils/types';

const AnagramMobileFrame = ({ anagramData, anagramItemType }) => {
	const mobileFrameStyle = {
		border: '2px solid #000',
		borderRadius: '10px',
		boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
		height: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};
	return (
		<div className="container mt-5">
			<div className="row">
				<div>
					<div style={mobileFrameStyle} className="flex-column">
						<img className="" src={getSanitizedImageUrl('assets/admin/header.png')} style={{ borderRadius: '10px' }} alt="speakx icon" />
						{anagramItemType === AnagramTypesEnum.SENTENCE ? (
							<h4 className="text-center mb-4">Rearrange the words to form a sentence</h4>
						) : (
							<h4 className="text-center mb-4">Rearrange the letters to form a word</h4>
						)}
						<div>
							<ul className=" d-flex flex-row justify-content-center align-items-center list-group mt-5 pt-5">
								{anagramData &&
									anagramData.map((word: any, index: any) => (
										<li key={index} className="list-group-item mb-2" style={{ border: '2px solid orange', borderRadius: '10px' }}>
											{word.text}
										</li>
									))}
							</ul>
						</div>
						<img
							className="pt-4"
							src={getSanitizedImageUrl('assets/admin/bottom.png')}
							style={{ borderRadius: '10px' }}
							alt="anagram bottom icon"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AnagramMobileFrame;
