import { select, call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader, stringifyQueryParams } from '../../../utils/commonHelpers';
import { getSkillsSuccess, toggleLoading } from '../skillSlice';
import { setError } from '../../App/globalSlice';

export function* getSkillsSaga({ payload }) {
	yield put(actionSpreader(toggleLoading.type, { loading: true }));

	const apiParams = yield select((state) => state.skill.apiParams);

	const qp = stringifyQueryParams({ ...payload, ...apiParams });

	const res = yield call(requestSaga, 'GET', `${process.env.REACT_APP_API_URL}/v1/admin/skills${qp}`);
	if (res.status === 200) {
		yield put(actionSpreader(getSkillsSuccess.type, { skills: res.data.skills, total: res.data.total }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
	yield put(actionSpreader(toggleLoading.type, { loading: false }));
}
