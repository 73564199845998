import { call, put } from 'redux-saga/effects';

import { requestSaga } from '../../../utils/requestSaga';
import { actionSpreader } from '../../../utils/commonHelpers';
import { getVideoStatusSuccess, setIsRefreshing } from '../videoSlice';
import { setError } from '../../App/globalSlice';

export function* getVideoStatusSaga({ payload }: ReturnType<() => { type: string; payload: { dropboxPath: string } }>) {
	yield put(actionSpreader(setIsRefreshing.type, { dropboxPath: payload.dropboxPath, value: true }));
	const res = yield call(requestSaga, 'POST', `${process.env.REACT_APP_API_URL}/v1/admin/get-video-status`, {
		body: JSON.stringify(payload),
	});

	if (res.status === 200) {
		yield put(actionSpreader(getVideoStatusSuccess.type, res.data));
		yield put(actionSpreader(setIsRefreshing.type, { dropboxPath: payload.dropboxPath, value: false }));
	} else {
		yield put(actionSpreader(setError.type, { errors: res.data }));
	}
}
