import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Box from '../../../../reusableComponents/Box';
import renderDatePickerField from '../../../../reusableComponents/Form/DatePicker';
import LoadingOverlay from '../../../../reusableComponents/LoadingOverlay';
import { isNotEmptyArray } from '../../../../utils/commonHelpers';
import { parseMongoDateToDisjointFormat } from '../../../../utils/dateHelper';
import { getDropboxFiles, dropboxFilesOnUpload } from '../../whatsappSlice';
import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import { Checkbox } from '../../../../reusableComponents/Form/Checkbox';

const createRows = (files, pageNo, rowsCount, selectedFiles, setCheckedFile) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < files.length; i += 1) {
		r.push({
			id: initialCount,
			isSelected: (
				<Checkbox
					name={files[i].name}
					checked={selectedFiles.includes(files[i].name)}
					disabled={files[i].status.label === 'Synced'}
					onChange={(e) => setCheckedFile(files[i].name, e.target ? e.target.checked : e)}
				/>
			),
			file: files[i].name,
			status: <div className={`badge badge-${files[i].status.color}`}>{files[i].status.label}</div>,
		});
		initialCount += 1;
	}
	return r;
};

const DateFilter = (props) => (
	<form className="form ltr-support" onSubmit={props.handleSubmit}>
		<Box d="flex" ai="center" w="100%">
			<Col md="3" sm="12">
				<span className="form__form-group-label">Date</span>
				<Field
					label="Date"
					type="text"
					onChange={(val) => {
						const dd = parseMongoDateToDisjointFormat(new Date(val));
						props.setDate(`${dd.date.label} ${dd.month.label} ${dd.year.label}`);
					}}
					timeParams={{ showTime: false }}
					disableFutureDate
					component={renderDatePickerField}
					name="date"
				/>
			</Col>
			<Button style={{ marginBottom: 0 }} color="primary" size="sm" type="submit">
				Submit
			</Button>
		</Box>
	</form>
);

const withReduxForm = reduxForm({ form: 'dbx_date_filter', enableReinitialize: true });
const DateFilterForm = compose(withReduxForm)(DateFilter);

const DropboxUpload = () => {
	const [date, setDate] = useState('');
	const [filesRow, setFilesRow] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const whatsappState = useSelector(({ whatsapp }) => ({
		loading: whatsapp.loading,
		isSubmitting: whatsapp.isSubmitting,
		dbxFiles: whatsapp.dbxFiles,
	}));

	const dispatch = useDispatch();

	const setCheckedFile = (val, checked) => {
		if (checked) setSelectedFiles((prevState) => [...prevState, val]);
		else setSelectedFiles((prevState) => prevState.filter((file) => file !== val));
	};

	React.useEffect(() => {
		if (isNotEmptyArray(whatsappState.dbxFiles)) {
			const structuredRows = createRows(whatsappState.dbxFiles, 1, whatsappState.dbxFiles.length, selectedFiles, setCheckedFile);
			setFilesRow(structuredRows);
		} else {
			setFilesRow([]);
		}
	}, [whatsappState.dbxFiles, selectedFiles]);

	React.useEffect(() => {
		if (isNotEmptyArray(whatsappState.dbxFiles)) {
			setSelectedFiles([]);
		}
	}, [whatsappState.dbxFiles]);

	const getFiles = () => {
		dispatch(
			getDropboxFiles({
				folder: date,
			})
		);
	};

	const uploadFiles = (uploadAllFiles) => {
		let files;
		if (uploadAllFiles)
			files = whatsappState.dbxFiles.map((file) => ({
				name: file.name,
				sync: file.status.label !== 'Synced',
				status: file.status.label,
			}));
		else
			files = whatsappState.dbxFiles.map((file) => ({
				name: file.name,
				sync: selectedFiles.includes(file.name),
				status: file.status.label,
			}));
		dispatch(
			dropboxFilesOnUpload({
				folder: date,
				files,
			})
		);
	};

	const heads = [
		{
			accessor: 'isSelected',
			Header: 'Select',
		},
		{
			accessor: 'file',
			Header: 'File',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
	];

	return (
		<LoadingOverlay isLoading={whatsappState.isSubmitting || whatsappState.loading}>
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={6}>
								<h3>Upload Files from Dropbox</h3>
							</Col>
						</Row>

						<DateFilterForm setDate={setDate} onSubmit={getFiles} />
						<Box p="15px" mt="1rem">
							{isNotEmptyArray(filesRow) ? (
								<>
									<p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>Files for {date}:</p>
									<DataPaginationTable heads={heads} rows={filesRow} />
									<Box>
										<Button style={{ marginBottom: 0 }} color="primary" size="sm" type="submit" onClick={() => uploadFiles(true)}>
											Sync All
										</Button>
										<Button style={{ marginBottom: 0 }} color="primary" size="sm" type="submit" onClick={() => uploadFiles(false)}>
											Sync Checked Files
										</Button>
									</Box>
								</>
							) : (
								<div className=""> No data</div>
							)}
						</Box>
					</CardBody>
				</Card>
			</Col>
		</LoadingOverlay>
	);
};

export default DropboxUpload;
