import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import history from 'containers/App/history';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
// eslint-disable-next-line import/no-cycle
import { toggleIsSubmitting } from '../batchClassSlice';

export const updateBatchToClass = createAsyncThunk('batch/updateBatchToClass', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const valuesToSubmit = data;

	try {
		const res = await fetchWrapper('/v1/admin/batchtoclass', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Batch Class updated succcessfully!!`);
			history.push('/batch-class/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const updateCoachBtc = createAsyncThunk('batch/updateCoachBtc', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const valuesToSubmit = data;

	try {
		const res = await fetchWrapper('/v1/admin/coach-batchtoclass', {
			method: 'PUT',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Successfully assigned coach to selected batch classes!!`);
			history.push('/batch-class/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});

export const removeCoachBtc = createAsyncThunk('batch/removeCoachBtc', async (data: any, { dispatch }) => {
	dispatch(toggleIsSubmitting({ isSubmitting: true }));

	const valuesToSubmit = data;

	try {
		const res = await fetchWrapper('/v1/admin/coach-batchtoclass', {
			method: 'DELETE',
			body: valuesToSubmit,
		});

		if (res.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: res.data } });
		} else {
			showToast('success', `👍️ Successfully removed the assigned coach!`);
			history.push('/batch-class/list');
		}
	} catch (error) {
		showToast('error', `Something went wrong`);
	} finally {
		dispatch(toggleIsSubmitting({ isSubmitting: false }));
	}
});
