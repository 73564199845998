import { takeLatest } from 'redux-saga/effects';

import { getTeamMate, getTeamMateId, getTeamMates, teamMateOnSubmit, setTeamMateStatus } from './aboutSlice';
import { getTeamMatesSaga } from './sagas/getTeamMatesSaga';
import { getTeamMateSaga } from './sagas/getTeamMateSaga';
import { getTeamMateIdSaga } from './sagas/getTeamMateIdSaga';
import { onSubmitSaga } from './sagas/onSubmitSaga';
import { setTeamMateStatusSaga } from './sagas/setTeamMateStatusSaga';

export default function* careerSaga() {
	yield takeLatest(getTeamMates.type, getTeamMatesSaga);
	yield takeLatest(getTeamMate.type, getTeamMateSaga);
	yield takeLatest(getTeamMateId.type, getTeamMateIdSaga);
	yield takeLatest(teamMateOnSubmit.type, onSubmitSaga);
	yield takeLatest(setTeamMateStatus.type, setTeamMateStatusSaga);
}
