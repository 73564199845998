/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { isNotEmptyArray, accessDeepObject, stringifyQueryParams } from 'utils/commonHelpers';
import { PostMedia } from 'components/PostMedia';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import Filter from 'reusableComponents/Filter';
import { SelectField } from 'reusableComponents/Form/Select';
import { format } from 'date-fns';
import history from '../../App/history';
import { setApiParams } from '../featuredPostSlice';
import { deletePost, getPosts, updatePostAndUpdateList } from '../actions/featuredPost.actions';
import VideoPreviewModal from '../../UserPost/components/VideoPreviewModel';

const Actions = ({ id, priority, postType }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					disabled={postType === 'ADMIN'}
					onClick={() =>
						dispatch(
							updatePostAndUpdateList({
								postId: id,
								priority: priority + 1,
							})
						)
					}
				>
					Move Up Priority
				</DropdownItem>
				<DropdownItem
					disabled={postType === 'ADMIN'}
					onClick={() =>
						dispatch(
							updatePostAndUpdateList({
								postId: id,
								priority: priority - 1,
							})
						)
					}
				>
					Move Down Priority
				</DropdownItem>
				<DropdownItem onClick={() => history.push(`/featured-post/add-edit${stringifyQueryParams({ id })}`)}>Edit Post</DropdownItem>
				<DropdownItem
					onClick={() => {
						// eslint-disable-next-line no-alert
						const shouldDeletePost = window.confirm('Are you sure you want to delete this post?');
						if (shouldDeletePost) {
							dispatch(
								deletePost({
									postId: id,
								})
							);
						}
					}}
				>
					Delete Post
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const statusList = [
	{ label: 'Approved', value: 'APPROVED' },
	{ label: 'Deleted', value: 'DELETED' },
];

function createRows({ posts, setIsVideoPreviewModalOpen }) {
	return posts.map(({ id, caption, status, media, createdAt, priority, postType }) => ({
		id,
		media: <PostMedia {...{ media, setIsVideoPreviewModalOpen }} />,
		caption,
		status,
		createdAt: format(new Date(createdAt), 'dd-MM-yyyy HH:mm'),
		priority: postType === 'ADMIN' ? '---' : priority,
		actions: (
			<Actions
				{...{
					id,
					priority,
					postType,
				}}
			/>
		),
	}));
}

const heads = [
	{ accessor: 'media', Header: 'Post Media', minWidth: 550, width: 550 },
	{ accessor: 'caption', Header: 'Post Caption' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'createdAt', Header: 'Created At' },
	{ accessor: 'priority', Header: 'Priority' },
	{ accessor: 'actions', Header: 'Actions' },
];

export const FeaturedPostTable = () => {
	const featuredPostState = useSelector(({ featuredPost }) => ({
		loading: featuredPost.loading,
		posts: featuredPost.posts,
		post: featuredPost.post,
		pageNo: featuredPost.pageNo,
		total: featuredPost.total,
		apiParams: featuredPost.apiParams,
	}));

	const dispatch = useDispatch();

	const [isVideoPreviewModalOpen, setIsVideoPreviewModalOpen] = useState({ isOpen: false, videoUrl: '' });
	const [postRows, setPostRows] = React.useState(null);
	const [pageOfItems, setPageOfItems] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState(featuredPostState.apiParams.query);

	React.useEffect(() => {
		dispatch(getPosts(featuredPostState.apiParams));
	}, []);

	React.useEffect(() => {
		setPageOfItems(featuredPostState.pageNo);
	}, [featuredPostState.pageNo]);

	React.useEffect(() => {
		if (isNotEmptyArray(featuredPostState.posts)) {
			const structuredRows = createRows({ posts: featuredPostState.posts, setIsVideoPreviewModalOpen });
			setPostRows(structuredRows);
		} else {
			setPostRows([]);
		}
	}, [featuredPostState.posts]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = featuredPostState;
		if (itemsPage) {
			const newParams = {
				...apiParams,
				skip: apiParams.limit * (itemsPage - 1),
			};
			dispatch(setApiParams(newParams));
			dispatch(getPosts(newParams));
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			const { apiParams } = featuredPostState;
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			const newParams = {
				...apiParams,
				sortKey: sortColumn,
				sortOrder,
			};
			dispatch(setApiParams(newParams));
			dispatch(getPosts(newParams));
		}
	};

	const onFilterChange = (val) => {
		const { apiParams } = featuredPostState;
		const newParams = {
			...apiParams,
			skip: 0,
			status: val,
		};
		dispatch(setApiParams(newParams));
		dispatch(getPosts(newParams));
	};

	const onSearch = (e) => {
		e?.preventDefault();
		const { apiParams } = featuredPostState;
		const newParams = {
			...apiParams,
			query: searchTerm?.trim(),
			skip: 0,
		};
		dispatch(setApiParams(newParams));
		dispatch(getPosts(newParams));
	};

	return (
		<>
			{isVideoPreviewModalOpen.isOpen && (
				<VideoPreviewModal
					initialValues={isVideoPreviewModalOpen.videoUrl}
					closeModal={() => {
						setIsVideoPreviewModalOpen({ isOpen: false, videoUrl: null });
					}}
				/>
			)}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={12} className="mb-3">
								<h3>Featured Posts Listing</h3>
							</Col>
							<Col>
								<Filter
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onSearch={onSearch}
									placeholder="Search by post by id or caption"
								/>
							</Col>
							<Col sm={3}>
								<SelectField
									defaultValue={statusList[0]}
									placeholder="Status"
									name="status"
									options={statusList}
									onChange={(val) => onFilterChange(val)}
								/>
							</Col>
							<Col style={{ textAlign: 'left' }} sm={{ size: 2, offset: 1 }}>
								<Button
									style={{ marginBottom: 0 }}
									size="sm"
									color="primary"
									onClick={() => {
										history.push('/featured-post/add-edit');
									}}
								>
									Add
								</Button>
							</Col>
						</Row>
						{isNotEmptyArray(postRows) && (
							<>
								<DataPaginationTable heads={heads} rows={postRows} onSort={onSort} />
								<Pagination
									itemsCount={featuredPostState.total}
									itemsToShow={accessDeepObject('apiParams.limit', featuredPostState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{featuredPostState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default FeaturedPostTable;
