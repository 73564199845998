import React, { useState } from 'react';
import { isNotEmptyArray, stringifyQueryParams } from '@bit/yellow_class.utils.common-helpers';
import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import { actionSpreader } from 'utils/commonHelpers';
import history from '../../App/history';
import { fetchCurriculums } from '../actions/exercises.actions';
import { setApiParams, setPage } from '../ExercisesSlice';

const heads = [
	{
		accessor: 'displayName',
		Header: 'Title',
	},
	{
		accessor: 'classRange',
		Header: 'Grade',
	},
	{
		accessor: 'academicSubject',
		Header: 'Subject',
	},
	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({ curriculumId }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => {
						history.push(`/exercise/module/list${stringifyQueryParams({ curriculumId })}`);
					}}
					disabled={false}
				>
					Show Modules
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						window.open(`/exercise/questions/add-edit${stringifyQueryParams({ curriculumId, mode: 'add' })}`, '_blank');
					}}
					disabled={false}
				>
					Add Hanging Exercise
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						window.open(`/exercise/list${stringifyQueryParams({ curriculumId, hanging: true })}`, '_blank');
					}}
					disabled={false}
				>
					View Hanging Exercises
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({ curriculums }) => {
	return curriculums.map((curr) => {
		const { id, displayName, adminCode, classRange, academicSubject } = curr;

		return {
			id,
			displayName,
			classRange: `Min: ${classRange.min}, Max: ${classRange.max}`,
			adminCode,
			academicSubject,
			actions: <Actions curriculumId={id} />,
		};
	});
};

const CurriculumList = (props) => {
	const dispatch = useDispatch();

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const curriculumsState = useSelector(({ exercises }) => ({
		curriculums: exercises.curriculums,
		curriculumsLoading: exercises.isLoading,
		isSubmitting: exercises.isSubmitting,
		apiParams: exercises.apiParams,
		total: exercises.total,
		page: exercises.page,
	}));

	const [curriculumsRow, setCurriculumsRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	React.useEffect(() => {
		dispatch(fetchCurriculums({ ...curriculumsState.apiParams }));
	}, [curriculumsState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(curriculumsState.page);
	}, [curriculumsState.page]);

	React.useEffect(() => {
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Curriculum',
						url: '/exercise/curriculum/list',
					},
				],
			})
		);
		if (isNotEmptyArray(curriculumsState.curriculums)) {
			const structuredRows = createNewRows({
				curriculums: curriculumsState.curriculums,
			});
			setCurriculumsRow(structuredRows);
		} else {
			setCurriculumsRow([]);
		}
	}, [curriculumsState.curriculums]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = curriculumsState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={8}>
						<h3>Curriculums Listing</h3>
					</Col>
				</Row>
				{isNotEmptyArray(curriculumsRow) && (
					<>
						<DataPaginationTable heads={heads} rows={curriculumsRow} />
						<Pagination
							itemsCount={curriculumsState.total}
							itemsToShow={curriculumsState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{curriculumsState.total === 0 && <div className="">No curriculums</div>}
			</CardBody>
		</Card>
	);
};

export default CurriculumList;
