import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import Box from 'reusableComponents/Box';
import 'react-image-lightbox/style.css';

import If from 'reusableComponents/If';
import { format } from 'date-fns';
import { getSanitizedImageUrl, isNotEmptyArray, showToast } from '../../utils/commonHelpers';
import { dateTimeConverter } from '../../utils/dateHelper';
import { getCloudFrontStaticPrefix } from '../../utils/constants';

const READ_ICON =
	'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAyMjYgMjI2Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwyMjZ2LTIyNmgyMjZ2MjI2eiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiMzNDk4ZGIiPjxwYXRoIGQ9Ik0yMTIuNjg0ODMsNDIuMzc1bC0xMDkuMTAxNSwxMDkuOTAxOTJsLTQzLjE3MDcxLC00My45ODUyNWwtMTMuMzI5MjksMTMuNDM3NThsNTYuNSw1Ny4xODc0MmwxMjIuNDE2NjcsLTEyMy4zOTZ6Ij48L3BhdGg+PHBhdGggZD0iTTE2NS42MDE1LDQyLjM3NWwtMTA5LjEwMTUsMTA5LjkwMTkybC00My4xNzA3MSwtNDMuOTg1MjVsLTEzLjMyOTI5LDEzLjQzNzU4bDU2LjUsNTcuMTg3NDJsMTIyLjQxNjY3LC0xMjMuMzk2eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+';
const DELIVERED_ICON =
	'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAyMjYgMjI2Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwyMjZ2LTIyNmgyMjZ2MjI2eiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiM5NWE1YTYiPjxwYXRoIGQ9Ik0yMTIuNjg0ODMsNDIuMzc1bC0xMDkuMTAxNSwxMDkuOTAxOTJsLTQzLjE3MDcxLC00My45ODUyNWwtMTMuMzI5MjksMTMuNDM3NThsNTYuNSw1Ny4xODc0MmwxMjIuNDE2NjcsLTEyMy4zOTZ6Ij48L3BhdGg+PHBhdGggZD0iTTE2NS42MDE1LDQyLjM3NWwtMTA5LjEwMTUsMTA5LjkwMTkybC00My4xNzA3MSwtNDMuOTg1MjVsLTEzLjMyOTI5LDEzLjQzNzU4bDU2LjUsNTcuMTg3NDJsMTIyLjQxNjY3LC0xMjMuMzk2eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+';
const SENT_ICON =
	'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiM5NWE1YTYiPjxwYXRoIGQ9Ik0xNDUuNDMyOTQsMzcuOTMyOTRsLTgwLjkzMjk0LDgwLjkzMjk1bC0zMC43NjYyOCwtMzAuNzY2MjhsLTEwLjEzNDExLDEwLjEzNDExbDQwLjkwMDM5LDQwLjkwMDM5bDkxLjA2NzA2LC05MS4wNjcwNXoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==';

const getDeliveryReportIcon = (src) => <img src={src} alt="icon" width="20" height="20" style={{ width: 'auto' }} />;

const downloadMedia = ({ url, fileName }) => {
	showToast('info', 'Downloading! Please wait!', 1000);
	fetch(url)
		.then((resp) => resp.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			// the filename you want
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		})
		.catch(() => showToast('error', 'Failed to download'));
};

const DownloadMediaButton = ({ messageType, url, fullName, date }) => {
	if (!['image', 'video'].includes(messageType)) return null;

	const fileExtension = url.split('.').pop();
	const fileName = `${fullName}-${date}.${fileExtension}`;

	return (
		<div className="chat__bubble-download-media" onClick={() => downloadMedia({ url, fileName })}>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
				/>
			</svg>
		</div>
	);
};

const showDeliveryReportStatus = ({ status }) => {
	switch (status) {
		case 'READ':
			return getDeliveryReportIcon(READ_ICON);
		case 'DELIVERED':
			return getDeliveryReportIcon(DELIVERED_ICON);
		case 'SENT':
			return getDeliveryReportIcon(SENT_ICON);
		default:
	}
};

const ChatBubble = ({ fullName, message, active, date, messageType, media, sentBy, userWAMsgStatusHistory, isCTA, ctaUrl }) => {
	const [isImageModalOpen, setIsImageModalOpen] = useState(false);
	const mediaUrl = messageType === 'video' ? `${getCloudFrontStaticPrefix()}/${media?.url}` : getSanitizedImageUrl(media?.url);

	return (
		<div className={`chat__bubble${active ? ' chat__bubble--active' : ''}`}>
			<>
				<div className="chat__avatar--default">{sentBy === 'USER' ? 'U' : 'A'}</div>
				<div className="chat__bubble-message-wrap">
					<div className="chat__bubble-message-header">
						<p className="chat__bubble-contact-name">{fullName}</p>
						<DownloadMediaButton messageType={messageType} url={mediaUrl} fullName={fullName} date={date} />
						<p className="chat__bubble-date">{dateTimeConverter(date, 'format', 'd MMM p')}</p>
					</div>

					{messageType === 'image' && (
						<>
							<div className="chat__bubble-image" onClick={() => setIsImageModalOpen(true)}>
								<img src={mediaUrl} alt="chat" />
								{message && <p className="caption">{message}</p>}
							</div>
							{isImageModalOpen && (
								<Lightbox mainSrc={mediaUrl} onCloseRequest={() => setIsImageModalOpen(false)} wrapperClassName="wa-img-modal" />
							)}
						</>
					)}

					{messageType === 'video' && (
						<div className="chat__bubble-video">
							{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
							<video src={mediaUrl} alt="chat" autoPlay={false} controls preload="false" />
							{message && <p className="caption">{message}</p>}
						</div>
					)}

					{(messageType === 'audio' || messageType === 'voice') && (
						<div className="chat__bubble-audio">
							{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
							<audio src={mediaUrl} alt="chat" autoPlay={false} controls preload="false" controlslist="nodownload" />
						</div>
					)}

					{messageType === 'text' && (
						<Box>
							<p className="chat__bubble-message">{message}</p>
							<If condition={isCTA && !!ctaUrl} then={<p style={{ marginBottom: '0.5rem' }}>ctaUrl: {ctaUrl}</p>} />
							{isNotEmptyArray(userWAMsgStatusHistory) && (
								<p
									title={userWAMsgStatusHistory
										.map(({ status, timestamp }) => `${status} - ${format(new Date(timestamp), 'dd LLL, yyyy hh:mm aaa')}\n`)
										.join('')}
									style={{ marginTop: 0, textAlign: 'right' }}
								>
									{showDeliveryReportStatus(userWAMsgStatusHistory[userWAMsgStatusHistory.length - 1])}
								</p>
							)}
						</Box>
					)}
				</div>
			</>
		</div>
	);
};

export default ChatBubble;
