import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const wrapperCustomStyles = {
	height: '100%',
	marginTop: '1rem',
};

const ImageCrop = (props) => (
	<ReactCrop
		ruleOfThirds
		keepSelection={props.keepSelection || false}
		src={props.imgSrc}
		onImageLoaded={props.imageLoadHandler}
		crop={props.crop}
		className="image-crop"
		style={wrapperCustomStyles}
		onChange={(newCrop) => props.setCropHandler(newCrop)}
	/>
);

export default ImageCrop;
