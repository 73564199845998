/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { getCategories, setApiParams, setCategoryStatus, categoryOnSubmit } from '../../categorySlice';
import { isNotEmptyArray, accessDeepObject } from '../../../../utils/commonHelpers';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../../utils/constants';

import DataPaginationTable from '../../../../reusableComponents/Tables/DataPaginationTable';
import Pagination from '../../../../reusableComponents/Pagination/Pagination';
import CategoryModal from '../CategoryModal';
import Filter from '../../../../reusableComponents/Filter';

const customStyles = {
	fontSize: '10px',
	padding: '5px 10px',
	margin: '0px 7px 0px 0px',
};

const customStylesMargin = {
	marginBottom: '0px',
};

const createRows = (categories, pageNo, rowsCount, submitHandler, setModalParams) => {
	const r = [];
	let initialCount = rowsCount * (pageNo - 1);
	initialCount += 1;
	for (let i = 0; i < categories.length; i += 1) {
		r.push({
			id: initialCount,
			title: categories[i].title,
			displayTitle: categories[i].displayTitle,
			status:
				categories[i].status === STATUS_INACTIVE ? (
					<div className="badge badge-warning">Inactive</div>
				) : (
					<div className="badge badge-success">Active</div>
				),
			actions: (
				<>
					<Button
						color="warning"
						size="md"
						style={customStyles}
						onClick={() => {
							setModalParams({ isOpen: true, initialValue: categories[i] });
						}}
					>
						Edit
					</Button>
					<Button
						color="primary"
						size="md"
						style={customStyles}
						onClick={() => {
							if (categories[i].status === STATUS_ACTIVE) {
								submitHandler({ id: categories[i].id, status: STATUS_INACTIVE, pos: i });
							} else {
								submitHandler({ id: categories[i].id, status: STATUS_ACTIVE, pos: i });
							}
						}}
					>
						Mark {categories[i].status === STATUS_ACTIVE ? 'Inactive' : 'Active'}
					</Button>
				</>
			),
		});
		initialCount += 1;
	}
	return r;
};

const CategoryTable = ({ history }) => {
	const categoryState = useSelector(({ category }) => ({
		loading: category.loading,
		categories: category.categories,
		pageNo: category.pageNo,
		total: category.total,
		apiParams: category.apiParams,
	}));

	const dispatch = useDispatch();

	const [categoryRows, setCategoryRows] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const [modalParams, setModalParams] = useState({ isOpen: false, initialValue: null });
	const [searchTerm, setSearchTerm] = useState(categoryState.apiParams.query);

	useEffect(() => {
		dispatch(getCategories());
	}, []);

	useEffect(() => {
		setPageOfItems(categoryState.pageNo);
	}, [categoryState.pageNo]);

	const heads = [
		{
			accessor: 'title',
			Header: 'Title',
		},
		{
			accessor: 'displayTitle',
			Header: 'Display Title',
		},
		{
			accessor: 'status',
			Header: 'Status',
		},
		{
			accessor: 'actions',
			Header: 'Actions',
		},
	];

	const submitHandler = (params) => {
		// eslint-disable-next-line no-restricted-globals, no-alert
		const dd = confirm('Are you sure?');
		if (dd) {
			if (!isUndefined(params.pos)) {
				dispatch(setCategoryStatus({ ...params }));
			} else {
				dispatch(categoryOnSubmit(params));
				setModalParams({ isOpen: false, initialValue: null });
			}
		}
	};

	React.useEffect(() => {
		if (isNotEmptyArray(categoryState.categories)) {
			const structuredRows = createRows(
				categoryState.categories,
				categoryState.pageNo,
				accessDeepObject('apiParams.limit', categoryState),
				submitHandler,
				setModalParams
			);
			setCategoryRows(structuredRows);
		} else {
			setCategoryRows([]);
		}
	}, [categoryState.categories]);

	const onChangePage = (itemsPage) => {
		const { apiParams } = categoryState;
		if (itemsPage) {
			dispatch(
				setApiParams({
					sortKey: apiParams.sortKey,
					sortOrder: apiParams.sortOrder,
					limit: apiParams.limit,
					skip: apiParams.limit * (itemsPage - 1),
				})
			);
			dispatch(getCategories());
		}
	};

	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			let sortOrder = null;
			if (sortDirection === 'ASC') {
				sortOrder = '1';
			} else {
				sortOrder = '-1';
			}
			dispatch(
				setApiParams({
					sortKey: sortColumn,
					sortOrder,
					limit: accessDeepObject('apiParams.limit', categoryState),
					skip: accessDeepObject('apiParams.limit', categoryState) * (pageOfItems - 1),
				})
			);
			dispatch(getCategories());
		}
	};

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();
		dispatch(
			setApiParams({
				query: searchTerm,
				limit: 20,
				skip: 0,
			})
		);
		dispatch(getCategories());
	};

	return (
		<>
			{modalParams.isOpen && (
				<CategoryModal
					initialValue={modalParams.initialValue}
					handleSubmit={submitHandler}
					closeModal={() => {
						setModalParams({ isOpen: false, initialValue: null });
					}}
				/>
			)}
			<Col md={12} lg={12}>
				<Card>
					<CardBody>
						<Row className="mt-1 mb-4 rounded">
							<Col sm={8} className="mb-3">
								<h3>Categories Listing</h3>
							</Col>
							<Col sm={6}>
								<Filter
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onSearch={onSearch}
									placeholder="Search by Title or Display Title"
								/>
							</Col>
							<Col style={{ textAlign: 'right' }} sm={{ size: 3, offset: 1 }}>
								<Button
									style={customStylesMargin}
									size="sm"
									color="primary"
									onClick={() => {
										setModalParams({ initialValue: null, isOpen: true });
									}}
								>
									Add
								</Button>
							</Col>
						</Row>
						{isNotEmptyArray(categoryRows) && (
							<>
								<DataPaginationTable heads={heads} rows={categoryRows} onSort={onSort} />
								<Pagination
									itemsCount={categoryState.total}
									itemsToShow={accessDeepObject('apiParams.limit', categoryState)}
									pageOfItems={pageOfItems}
									onChangePage={onChangePage}
								/>
							</>
						)}
						{categoryState.total === 0 && <div className="">No data</div>}
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default CategoryTable;
