import { createSlice } from '@reduxjs/toolkit';
import {
	createActivityInstance,
	deleteActivityInstance,
	fetchActivityInstanceDetails,
	fetchActivityInstances,
	updateActivityInstance,
} from './actions/activityInstance.action';

const activityInstanceSlice = createSlice({
	name: 'activityInstances',
	initialState: {
		activityInstances: [],
		activityInstanceLoading: false,
		page: 1,
		total: 0,
		apiParams: {
			skip: 0,
			limit: 20,
			sortKey: 'createdAt',
			sortOrder: -1,
			searchKey: '',
		},
	},
	reducers: {
		setActivityInstanceLoading: (state, action) => {
			state.activityInstanceLoading = action.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...state.apiParams,
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ searchKey: action.payload.searchKey },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
	},
	extraReducers: {
		[fetchActivityInstances.pending as any]: (state) => {
			state.activityInstanceLoading = true;
		},
		[updateActivityInstance.fulfilled as any]: (state, action) => {
			state.activityInstanceLoading = true;
			state.activityInstances = state.activityInstances.map((activityInstance) => {
				if (activityInstance.id === action.payload.id) {
					return action.payload;
				}
				return activityInstance;
			});
		},
		[fetchActivityInstances.fulfilled as any]: (state, action) => {
			state.activityInstanceLoading = false;
			state.activityInstances = action.payload.activityInstances;
			state.total = action.payload.total;
		},
		[fetchActivityInstanceDetails.fulfilled as any]: (state, action) => {
			state.activityInstanceLoading = false;
			state.activityInstances.push(action.payload);
		},
		[createActivityInstance.fulfilled as any]: (state, action) => {
			state.activityInstances.push(action.payload);
		},
		[deleteActivityInstance.fulfilled as any]: (state, action) => {
			const { activityInstnaceId } = action.meta.arg;
			state.activityInstances = [...state.activityInstances.filter((activityInstance) => activityInstance.id !== activityInstnaceId)];
		},
	},
});

export const { setApiParams } = activityInstanceSlice.actions;

export default activityInstanceSlice.reducer;
