import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parseQueryParams, pickWrapper } from 'utils/commonHelpers';
import TestPolls from 'containers/Poll/TestPolls';
import { getShort } from '../actions/shorts.actions';

interface ShortPollsProps {
	location: { search: { shortId?: string } };
}

export const ShortsTestPolls = (props: ShortPollsProps) => {
	const dispatch = useDispatch();

	const { selectedShort } = useSelector(({ shorts }) => ({
		selectedShort: shorts.short,
	}));

	useEffect(() => {
		if (props?.location?.search) {
			const { shortId } = pickWrapper(['shortId'], parseQueryParams(props.location.search));
			dispatch(getShort({ shortId }));
		}
	}, [props?.location?.search]);

	return (
		<TestPolls
			videoUrl={selectedShort?.videoDetails?.videoUrl}
			polls={selectedShort?.polls}
			poll={selectedShort?.polls?.[0]}
			isShortsPoll
		/>
	);
};
