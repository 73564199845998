import { watch } from 'fs';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { InputNew } from 'reusableComponents/HookForm/Input';
import theme from 'utils/theme';
import { AnagramTypesEnum } from '../utils/types';

const AnagramItem = ({ control, fieldName, anagramItemType, setAnagramData, reset }) => {
	const [initialRender, setInitialRender] = useState(true);
	const anagramItemFieldArray = useFieldArray({
		control,
		name: fieldName,
	});
	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
			return;
		}
		if (anagramItemFieldArray.fields.length > 0) {
			reset({
				blocks: [],
			});
		}
	}, [anagramItemType]);

	useEffect(() => {
		setAnagramData(anagramItemFieldArray.fields);
	}, [anagramItemFieldArray]);
	return (
		<Box>
			{anagramItemFieldArray.fields.map((field, index) => {
				return (
					<Box key={field?.id} pos="relative">
						<h4 style={{ marginBottom: '1rem' }}>
							{anagramItemType === AnagramTypesEnum.SENTENCE ? 'Word' : 'Alphabet'} {index + 1}
						</h4>
						<Row>
							<Col sm="6">
								<InputNew name={`${fieldName}[${index}].text`} defaultValue={field?.text} />
							</Col>

							<Col style={{ display: 'none' }}>
								<Checkbox label="show in option?" name={`${fieldName}[${index}].showInOption`} checked />
								<Checkbox label="is answer?" name={`${fieldName}[${index}].isAnswer`} checked />
							</Col>
						</Row>
						<Box
							onClick={() => {
								anagramItemFieldArray.remove(index);
							}}
							cursor="pointer"
							pos="absolute"
							t="0"
							r="0"
						>
							<span className="lnr lnr-cross" />
						</Box>
					</Box>
				);
			})}
			<Row>
				<Col sm="12">
					<Box d="flex" jc="center">
						<Button
							disabled={
								anagramItemType === AnagramTypesEnum.SENTENCE
									? anagramItemFieldArray.fields.length >= 5
									: anagramItemFieldArray.fields.length >= 8
							}
							c={theme.clrs.cWhite}
							fs="1rem"
							bRad="6px"
							d="flex"
							jc="center"
							ai="center"
							bg={theme.clrs.cSkyBlue}
							cursor="pointer"
							onClick={() => anagramItemFieldArray.append({})}
							mb="1rem"
							p=".3rem"
						>
							{anagramItemType === AnagramTypesEnum.SENTENCE ? '+ Add New Word' : '+ Add New Alphabet'}
						</Button>
					</Box>
				</Col>
			</Row>
		</Box>
	);
};

export { AnagramItem };
