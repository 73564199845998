import { put, call } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper } from '../../../utils/commonHelpers';
import { setIsSubmitting, resetIsSubmitting, faqOnSubmitSuccess } from '../faqSlice';
import { mutateFaqSaga } from './mutateFaqSaga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(setIsSubmitting.type));
	const modifiedData = { ...omitWrapper(['isEdit', 'pos'], payload) };
	const faqRes = yield call(mutateFaqSaga, { payload: modifiedData, isEdit: payload.isEdit });
	if (faqRes.status === 200) {
		showToast('success', `👍️ FAQ ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(actionSpreader(resetIsSubmitting.type));
		if (typeof payload.pos === 'number') {
			yield put(actionSpreader(faqOnSubmitSuccess.type, { faq: faqRes.data, pos: payload.pos }));
		}
		history.push('/faq/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: faqRes.data }));
		yield put(actionSpreader(resetIsSubmitting.type));
	}
}
