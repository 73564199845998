import { createSlice } from '@reduxjs/toolkit';
import { isNotEmptyObject } from 'utils/commonHelpers';
import { fetchMembershipData, fetchUserMembershipDetails } from './membership.actions';

const membershipSlice = createSlice({
	name: 'membership',
	initialState: {
		successData: [],
		rejectedData: [],
		pendingData: [],
		loading: false,
		page: 1,
		error: null,
		total: 10,
		status: 'PENDING',
		apiParams: {
			limit: 20,
			searchKey: '',
			sortKey: 'createdAt',
			sortOrder: 1,
			skip: 0,
		},
	},
	reducers: {
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.sortKey && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder && { sortOrder: action.payload.sortOrder }),
				...{ searchKey: action.payload.searchKey },
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		setMembershipStatus: (state, action) => {
			state.status = action.payload;
		},
	},
	extraReducers: {
		[fetchMembershipData.pending as any]: (state) => {
			state.loading = true;
		},
		[fetchMembershipData.fulfilled as any]: (state, { payload }) => {
			if (payload.status === 'PENDING') {
				state.pendingData = payload.data;
			} else if (payload.status === 'SUCCESS') {
				state.successData = payload.data;
			} else {
				state.rejectedData = payload.data;
			}
			state.total = payload.total;
			state.loading = true;
		},
		[fetchUserMembershipDetails.pending as any]: (state) => {
			state.loading = true;
		},
		[fetchUserMembershipDetails.fulfilled as any]: (state) => {
			state.loading = false;
		},
		[fetchUserMembershipDetails.pending as any]: (state) => {
			state.loading = true;
		},
		[fetchUserMembershipDetails.fulfilled as any]: (state, { payload }) => {
			if (isNotEmptyObject(payload)) {
				if (payload.cancellationStatus === 'REJECTED') state.rejectedData.push(payload);
				if (payload.cancellationStatus === 'SUCCESS') state.successData.push(payload);
				state.status = payload.cancellationStatus;
			}
			state.loading = false;
		},
	},
});

export const { setApiParams, setPage, setMembershipStatus } = membershipSlice.actions;
export default membershipSlice.reducer;
