import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { userHasDevSettingAccess } from 'containers/DevSettings/utils/helpers';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
	const hideSidebar = () => {
		onClick();
	};

	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	return (
		<div className="sidebar__content">
			<ul className="sidebar__block">
				{/* <SidebarLink
						title="Log In"
						icon="exit"
						route="/log_in"
						onClick={hideSidebar}
					/>
					<SidebarCategory title="Layout" icon="layers">
						<button
						type="button"
						className="sidebar__link"
						onClick={changeToLight}
						>
						<p className="sidebar__link-title">Light Theme</p>
						</button>
						<button
						type="button"
						className="sidebar__link"
						onClick={changeToDark}
						>
						<p className="sidebar__link-title">Dark Theme</p>
						</button>
					</SidebarCategory> */}
			</ul>
			<ul className="sidebar__block">
				<SidebarLink title="Website Blogs" route="/website-blog/generate" icon="paperclip" onClick={hideSidebar} />
				<SidebarLink title="Experiment" route="/experiment/list" icon="magnifier" onClick={hideSidebar} />
				<SidebarLink title="Users" route="/users/list" icon="users" onClick={hideSidebar} />
				<SidebarLink title="Blogs" route="/blogs/list" icon="paperclip" onClick={hideSidebar} />
				<SidebarLink title="FAQ" route="/faq/list" icon="question-circle" onClick={hideSidebar} />
				{/* <SidebarLink title="Schools" route="/school/list" icon="book" onClick={hideSidebar} /> */}
				<SidebarCategory title="Curriculum & Exercises" icon="book">
					<SidebarLink title="Curriculum" route="/exercise/curriculum/list" icon="menu" onClick={hideSidebar} />
					{/* <SidebarLink title="Demo Exercises" route="/exercise/list?type=DEMO" icon="menu" onClick={hideSidebar} /> */}
					<SidebarLink title="Questions" route="/exercise/questions/list" icon="menu" onClick={hideSidebar} />
					<SidebarLink title="Courses" route="/exercise/courses/list" icon="menu" onClick={hideSidebar} />
				</SidebarCategory>
				<SidebarCategory title="Program Features" icon="book">
					<SidebarLink title="Categories" route="/category/list" icon="menu" onClick={hideSidebar} />
					<SidebarLink title="Skills" route="/skill/list" icon="list" onClick={hideSidebar} />
					<SidebarLink title="Classes" route="/class/list" icon="pencil" onClick={hideSidebar} />
					<SidebarLink title="Programs" route="/program/list" icon="book" onClick={hideSidebar} />
					<SidebarLink title="Batches" route="/batch/list" icon="star" onClick={hideSidebar} />
					<SidebarLink title="Batch Classes" route="/batch-class/list" icon="calendar-full" onClick={hideSidebar} />
					<SidebarLink title="Enrollments" route="/enrollments" icon="graduation-hat" onClick={hideSidebar} />
					<SidebarLink title="Certificates" route="/certificates" icon="upload" onClick={hideSidebar} />
				</SidebarCategory>
				<SidebarLink title="Videos" route="/video/list" icon="camera-video" onClick={hideSidebar} />
				<SidebarLink title="Shorts" route="/shorts/list" icon="frame-expand" onClick={hideSidebar} />
				{/* <SidebarLink title="Mentors" route="/mentor/list" icon="mustache" onClick={hideSidebar} />
				<SidebarLink title="Gallery" route="/gallery/list" icon="picture" onClick={hideSidebar} /> */}
				<SidebarCategory title="Whatsapp" icon="bubble">
					<SidebarLink title="Whatsapp Groups" route="/whatsapp/list" icon="bubble" onClick={hideSidebar} />
					<SidebarLink title="Whatsapp Chats" route="/whatsapp-chats" icon="bubble" onClick={hideSidebar} />
				</SidebarCategory>
				<SidebarLink title="Success Stories" route="/success-stories/list" icon="rocket" onClick={hideSidebar} />
				<SidebarCategory title="About YC & Careers" icon="briefcase">
					<SidebarLink title="Careers" route="/careers/list" icon="briefcase" onClick={hideSidebar} />
					<SidebarLink title="About Us" route="/about-us/list" icon="users" onClick={hideSidebar} />
				</SidebarCategory>
				<SidebarLink title="Referral Codes" route="/ref-code/list" icon="gift" onClick={hideSidebar} />
				<SidebarLink title="Featured Posts" route="/featured-post/list" icon="file-empty" onClick={hideSidebar} />
				<SidebarCategory title="User Posts" icon="file-add">
					<SidebarLink title="List View" route="/user-post/list?page=1" onClick={hideSidebar} />
					<SidebarLink title="Approval View" route="/user-post/approval" onClick={hideSidebar} />
				</SidebarCategory>
				{/* <SidebarCategory title="Games" icon="smartphone">
					<SidebarLink title="Media" route="/activity/media/list" onClick={hideSidebar} />
					<SidebarLink title="Questions" route="/activity/question/list" onClick={hideSidebar} />
					<SidebarLink title="Activities" route="/activity/list" onClick={hideSidebar} />
					<SidebarLink title="Activity Instances" route="/activity/activity-instance/list" onClick={hideSidebar} />
				</SidebarCategory> */}
				<SidebarCategory title="Reports" icon="warning">
					<SidebarLink title="List View" route="/report/list" onClick={hideSidebar} />
					{process.env.REACT_APP_REPORT_ADMINS.split(' ').includes(loggedInUser.email[0]) && (
						<SidebarLink title="Approval View" route="/report/approval" onClick={hideSidebar} />
					)}
				</SidebarCategory>
				{/* <SidebarLink title="Dashboard Banners" route="/dashboard/list" icon="picture" onClick={hideSidebar} />
				<SidebarLink title="Landing Page List" route="/landing-page/list" icon="list" onClick={hideSidebar} />
				<SidebarLink title="Learning Activities" route="/learning-activities/list" icon="smartphone" onClick={hideSidebar} /> */}
				<SidebarLink title="Membership" route="/membership/list" icon="graduation-hat" onClick={hideSidebar} />
				{/* <SidebarLink title="Coach" route="/coach/list" icon="list" onClick={hideSidebar} /> */}
				<SidebarCategory title="Dev Settings" icon="picture">
					<SidebarLink title="S3 File Upload" route="/dev-settings/s3-file-upload" onClick={hideSidebar} />
					<SidebarLink title="Payment Services" route="/dev-settings/payment-services" onClick={hideSidebar} />
					{userHasDevSettingAccess(loggedInUser.email[0]) && (
						<>
							<SidebarLink title="Redis CLI" route="/dev-settings/redis-cli" onClick={hideSidebar} />
							<SidebarLink title="Cloudfront Cache Invalidate" route="/dev-settings/cf-cache-invalidate" onClick={hideSidebar} />
						</>
					)}
				</SidebarCategory>
			</ul>
		</div>
	);
};

export default SidebarContent;

SidebarContent.propTypes = {
	onClick: PropTypes.func.isRequired,
};
