import { put, call, all } from 'redux-saga/effects';
import { actionSpreader, showToast, pickWrapper, omitWrapper, isNotEmptyArray, isNotEmptyObject } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../mentorSlice';
import { uploadImageSaga, mutateSaga } from '../../../shared/saga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit'], payload) };
	const allImgs = [
		...(isNotEmptyObject(payload.profilePic) ? [payload.profilePic.photo[0]] : []),
		...(isNotEmptyObject(payload.cutoutPic) ? [payload.cutoutPic.photo[0]] : []),
		...(isNotEmptyObject(payload.mobileCoverPic) ? [payload.mobileCoverPic.photo[0]] : []),
		...(isNotEmptyObject(payload.webCoverPic) ? [payload.webCoverPic.photo[0]] : []),
		...(isNotEmptyObject(payload.videoCoverPicture) ? [payload.videoCoverPicture.photo[0]] : []),
	].filter((img) => !img.id);

	if (isNotEmptyArray(allImgs)) {
		let imgs = yield all(
			allImgs.map((img) =>
				call(uploadImageSaga, {
					payload: { images: [img], entityId: payload.id, entityType: 'MENTOR' },
				})
			)
		);
		if (imgs instanceof Error) {
			showToast('error', 'OOPS!! Something went wrong, please try again!');
		} else {
			imgs = imgs.map((img) => img[0]);

			if (modifiedData.profilePic && !modifiedData.profilePic.photo[0].id) {
				modifiedData.profilePic = { photo: [pickWrapper(['id', 'url'], imgs[0])] };
				imgs = imgs.slice(1);
			}

			if (modifiedData.cutoutPic && !modifiedData.cutoutPic.photo[0].id) {
				modifiedData.cutoutPic = { photo: [pickWrapper(['id', 'url'], imgs[0])] };
				imgs = imgs.slice(1);
			}

			if (modifiedData.mobileCoverPic && !modifiedData.mobileCoverPic.photo[0].id) {
				modifiedData.mobileCoverPic = { photo: [pickWrapper(['id', 'url'], imgs[0])] };
				imgs = imgs.slice(1);
			}

			if (modifiedData.webCoverPic && !modifiedData.webCoverPic.photo[0].id) {
				modifiedData.webCoverPic = { photo: [pickWrapper(['id', 'url'], imgs[0])] };
				imgs = imgs.slice(1);
			}

			if (modifiedData.videoCoverPicture && !modifiedData.videoCoverPicture.photo[0].id) {
				modifiedData.videoCoverPicture = { photo: [pickWrapper(['id', 'url'], imgs[0])] };
				imgs = imgs.slice(1);
			}
		}
	}
	modifiedData.videoCoverPicture = modifiedData.videoCoverPicture
		? { ...modifiedData.videoCoverPicture.photo[0], caption: payload.videoCoverPicture.caption }
		: null;
	modifiedData.profilePic = {
		...modifiedData.profilePic.photo[0],
		caption: payload.profilePic.caption,
	};
	modifiedData.cutoutPic = {
		...modifiedData.cutoutPic.photo[0],
		caption: payload.cutoutPic.caption,
	};
	modifiedData.mobileCoverPic = modifiedData.mobileCoverPic
		? { ...modifiedData.mobileCoverPic.photo[0], caption: payload.mobileCoverPic.caption }
		: null;
	modifiedData.webCoverPic = modifiedData.webCoverPic
		? { ...modifiedData.webCoverPic.photo[0], caption: payload.webCoverPic.caption }
		: null;

	const endpoint = `${process.env.REACT_APP_API_URL}/v1/admin/mentor/${payload.isEdit ? payload.id : ''}`;
	const mentorRes = yield call(mutateSaga, {
		payload: modifiedData,
		isEdit: payload.isEdit,
		url: endpoint,
	});
	if (mentorRes.status === 200) {
		showToast('success', `👍️ Mentor ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		history.push('/mentor/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: mentorRes.data }));
	}
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
}
