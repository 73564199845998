import CreatePaymentLinkModal from 'containers/Users/components/CreatePaymentLinkModal';
import GradePromotionModal from 'containers/Users/components/GradePromotionModal';
import GrantSubscriptionModal from 'containers/Users/components/GrantSubscriptionModal';
import SubscriptionModal from 'containers/Users/components/SubscriptionModal';
import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { compose } from 'redux';
import ViewCoursesModal from 'containers/Users/components/ViewCoursesModal';
import ImageCropModal from '../../components/DropzoneWithCrop/ImageCropModal';
import { actionSpreader } from '../../utils/commonHelpers';
import { CloseAllModalsAction, CloseModalAction } from './actions';
import { ModalTypes } from './types';

const getModal = (type: ModalTypes, modalProps, closeModal, i: number, closeAllModals) => {
	switch (type) {
		case ModalTypes.IMAGE_CROP_MODAL:
			return (
				<ImageCropModal
					{...modalProps}
					closeAllModals={closeAllModals}
					closeModal={(...args) => closeModal(ModalTypes.IMAGE_CROP_MODAL, ...args)}
					key={i}
					modalIdx={i}
				/>
			);
		case ModalTypes.USER_SUBSCRIPTION_MODAL:
			return (
				<SubscriptionModal
					{...modalProps}
					closeAllModals={closeAllModals}
					closeModal={(...args) => closeModal(ModalTypes.USER_SUBSCRIPTION_MODAL, ...args)}
					key={i}
					modalIdx={i}
				/>
			);
		case ModalTypes.CREATE_PAYMENT_LINK_MODAL:
			return (
				<CreatePaymentLinkModal
					{...modalProps}
					closeAllModals={closeAllModals}
					closeModal={(...args) => closeModal(ModalTypes.CREATE_PAYMENT_LINK_MODAL, ...args)}
					key={i}
					modalIdx={i}
				/>
			);
		case ModalTypes.GRANT_SUBSCRIPTION_MODAL:
			return (
				<GrantSubscriptionModal
					{...modalProps}
					closeAllModals={closeAllModals}
					closeModal={(...args) => closeModal(ModalTypes.GRANT_SUBSCRIPTION_MODAL, ...args)}
					key={i}
					modalIdx={i}
				/>
			);
		case ModalTypes.GRADE_PROMOTION_MODAL:
			return (
				<GradePromotionModal
					{...modalProps}
					closeAllModals={closeAllModals}
					closeModal={(...args) => closeModal(ModalTypes.GRADE_PROMOTION_MODAL, ...args)}
					key={i}
					modalIdx={i}
				/>
			);
		case ModalTypes.VIEW_COURSES:
			return (
				<ViewCoursesModal
					{...modalProps}
					closeAllModals={closeAllModals}
					closeModal={(...args) => closeModal(ModalTypes.VIEW_COURSES, ...args)}
					key={i}
					modalIdx={i}
				/>
			);
		default:
			return null;
	}
};

const ModalController = ({ currentModals, closeAllModals, closeModal }) => (
	<TransitionGroup component={null}>
		<div>{currentModals && currentModals.map((x, i) => getModal(x.type, x.props, closeModal, i, closeAllModals))}</div>
	</TransitionGroup>
);

const mapStateToProps = (state) => ({
	currentModals: state.modals.currentModals,
});

const mapDispatchToProps = (dispatch) => ({
	closeModal: (type: ModalTypes, customAction, customParameters) => {
		dispatch(CloseModalAction(type));
		if (customAction) {
			dispatch(actionSpreader(customAction, customParameters));
		}
	},
	closeAllModals: (customAction, customParameters) => {
		dispatch(CloseAllModalsAction);
		if (customAction || customParameters) {
			dispatch(actionSpreader(customAction, customParameters));
		}
	},
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ModalController);
