import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
	.overlay {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: fixed;
		background: rgba(33, 33, 33, 0.8);
		z-index: 1000;
	}

	.overlay__inner {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.overlay__content {
		display: flex;
		color: #fff;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		.msg {
			margin-top: 1rem;
			display: flex;
			flex-direction: column;
			line-height: 1.5rem;
			div {
				display: flex;
			}
			span {
				margin-left: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.lnr-sync {
				animation: ${rotate} 1s infinite linear;
			}
		}
	}

	.spinner {
		width: 75px;
		height: 75px;
		display: inline-block;
		border-width: 2px;
		border-color: rgba(255, 255, 255, 0.05);
		border-top-color: #fff;
		animation: ${rotate} 1s infinite linear;
		border-radius: 100%;
		border-style: solid;
	}
`;

const LoadingOverlay = (props) => {
	return (
		<Wrapper {...props}>
			{props.isLoading ? (
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content">
							<span className="spinner" />
							{props.msg ? <div className="msg">{props.msg}</div> : null}
						</div>
					</div>
				</div>
			) : null}
			{props.children}
		</Wrapper>
	);
};

LoadingOverlay.propTypes = {
	children: PropTypes.any,
	isLoading: PropTypes.bool,
	msg: PropTypes.any,
};

export default LoadingOverlay;
