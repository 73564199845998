import { put, call, all } from 'redux-saga/effects';
import { actionSpreader, showToast, omitWrapper, isNotEmptyArray, pickWrapper, isNotEmptyObject } from '../../../utils/commonHelpers';
import { toggleIsSubmitting } from '../classSlice';
import { mutateClassSaga } from './mutateClassSaga';
import { setError } from '../../App/globalSlice';
import history from '../../App/history';
import { uploadImageSaga, mutateSaga } from '../../../shared/saga';

export function* onSubmitSaga({ payload }) {
	yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: true }));
	const modifiedData = { ...omitWrapper(['isEdit'], payload) };
	const isCoverPicturePresent = isNotEmptyObject(modifiedData.coverPicture);
	// Take all images out in a seperate array which are to be uploaded i.e. not having an ID
	const allImgs = [...(isCoverPicturePresent ? [modifiedData.coverPicture] : []), ...modifiedData.photos].filter((img) => !img.id);
	if (isNotEmptyArray(allImgs)) {
		// Upload allImgs
		let imgs = yield all(
			allImgs.map((img) =>
				call(uploadImageSaga, {
					payload: { images: [img.file], entityId: payload.id, entityType: 'SESSION' },
				})
			)
		);
		if (imgs instanceof Error) {
			showToast('error', 'OOPS!! Something went wrong, please try again!');
		} else {
			// Take out coverPicture if it exists
			imgs = imgs.map((img) => img[0]);
			if (isCoverPicturePresent && !modifiedData.coverPicture.id) {
				modifiedData.coverPicture = pickWrapper(['id', 'url'], imgs[0]);
				imgs = imgs.slice(1);
			}
			// Take out id and url from uploaded images and concatenate it with the previously uploaded ones
			const uploadedPhotos = modifiedData.photos.filter((photo) => photo.id);
			const photosToBeAdded = modifiedData.photos.filter((photo) => !photo.id);
			modifiedData.photos = [
				...uploadedPhotos,
				...imgs.map((img, idx) => ({
					...pickWrapper(['id', 'url'], img),
					caption: photosToBeAdded[idx].caption,
				})),
			];
			modifiedData.photos = modifiedData.photos.map((photo) => ({
				...photo,
				entityType: 'SESSION_GALLERY',
			}));
		}
	}
	const classRes = yield call(mutateClassSaga, { payload: modifiedData, isEdit: payload.isEdit });
	if (classRes.status === 200) {
		showToast('success', `👍️ Class ${payload.isEdit ? 'updated' : 'created'} succcessfully!!`);
		yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
		history.push('/class/list');
	} else {
		yield put(actionSpreader(setError.type, { errors: classRes.data }));
		yield put(actionSpreader(toggleIsSubmitting.type, { isSubmitting: false }));
	}
}
